import React from 'react'
import PropTypes from 'prop-types'

import StarRatingComponent from 'react-star-rating-component'

import { StarIcon, RatingText } from '../../../blocks'

import { Container, TextBlock, RatingBlock, Circle, Title } from './styled'

const ExperienceItem = ({ name, level, expId, onChangeRating }) => {
  let levelText = ''
  switch (level) {
    case '1':
      levelText = 'Average'
      break
    case '2':
      levelText = 'Good'
      break
    case '3':
      levelText = 'PRO'
      break
    default:
      levelText = null
  }
  return (
    <Container>
      <TextBlock>
        <Circle />
        <Title>{name}</Title>
      </TextBlock>
      <RatingBlock>
        <StarRatingComponent
          editing={true}
          emptyStarColor={'#fff'}
          name={expId}
          renderStarIcon={() => <StarIcon />}
          starColor={'#00e99a'}
          starCount={3}
          value={level}
          onStarClick={onChangeRating}
        />
        <RatingText value={levelText} />
      </RatingBlock>
    </Container>
  )
}

ExperienceItem.propTypes = {
  expId: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChangeRating: PropTypes.func.isRequired
}

export { ExperienceItem }
