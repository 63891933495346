import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { mountFilter } from "../../../Helpers/mountFilter";
import { unmountFilter } from "../../../Helpers/unmountFilter";
import { Slider, Switch } from "antd";

import InputRange from "react-input-range";

import {
  SalaryOption,
  Button,
  CloseBtn,
  SalaryRangeContainer,
  FilterContainer,
  FilterTitle,
  Container,
  CloseBtnIcon,
  OkayBtnIcon,
  SalaryOptionIcon,
  SalaryRangeTitle,
} from "./styled";

export default class DropdownSalary extends PureComponent {
  componentDidMount() {
    mountFilter("Salary", true);
  }

  componentWillUnmount() {
    unmountFilter("Salary");
  }
  onChange = (newValue) => {
    console.log("newValue", newValue);
    salary.range = { min: newValue, max: 927000 };
  };
  onChangeSwitch = (checked) => {
    console.log(`switch to ${checked}`);
  };
  onChangeSlider = (value) => {
    const {
      onFilterClose,
      salary,
      onSalaryOptionClick,
      onSalaryRangeChange,
      onSalaryFilterOkBtnClick,
      isUnmount,
    } = this.props;
    console.log("onChange: ", value);
    salary.range = { min: value, max: 100000 };
  };

  render() {
    const {
      onFilterClose,
      salary,
      onSalaryOptionClick,
      onSalaryRangeChange,
      onSalaryFilterOkBtnClick,
      isUnmount,
    } = this.props;
    return ReactDOM.createPortal(
      <OutsideClickHandler onOutsideClick={onFilterClose}>
        <Container id="Salary" isUnmount={isUnmount}>
          <FilterContainer>
            <FilterTitle>SALARY RANGE</FilterTitle>
            <CloseBtn onClick={onFilterClose}>
              <CloseBtnIcon />
            </CloseBtn>
          </FilterContainer>
          <SalaryOption
            active={salary.withoutRange}
            name="withoutRange"
            onClick={(event) => onSalaryOptionClick(event, "withoutRange")}
          >
            <SalaryOptionIcon name="withoutRange" />
            <div>Show jobs without salary range</div>
          </SalaryOption>
          <SalaryOption
            active={salary.negotiable}
            name="negotiable"
            onClick={(event) => onSalaryOptionClick(event, "negotiable")}
          >
            <SalaryOptionIcon name="negotiable" />
            <div>Show jobs with negotiable salary</div>
          </SalaryOption>
          {/* <SalaryOption
            active={salary.byMin}
            isActive={salary.byMax}
            name="byMin"
            onClick={(event) => onSalaryOptionClick(event, "byMin")}
          >
            <SalaryOptionIcon name="byMin" />
            <div>By Min</div>
          </SalaryOption> */}
          {/* <SalaryOption
            active={salary.byMax}
            isActive={salary.byMin}
            name="byMax"
            onClick={(event) => onSalaryOptionClick(event, "byMax")}
          > */}
          <Switch
            checkedChildren="Min"
            unCheckedChildren="Max"
            defaultChecked
            onChange={this.onChangeSwitch}
          />
          <Slider
            onChange={this.onChangeSlider}
            min={5000}
            step={5000}
            max={500000}
            defaultValue={5000}
          />

          {/* </SalaryOption> */}
          {/* {!salary.withoutRange ? ( */}
          {/* <SalaryRangeTitle>
            Select the salary range you're expecting:
          </SalaryRangeTitle> */}
          {/* ) : null} */}
          {/* {salary.byMin ? (
            <SalaryRangeContainer>
              <Slider defaultValue={30} onChange={this.onChange}  value={salary.range.min}/>
              
            </SalaryRangeContainer>
          ) : ( */}
          {/* <SalaryRangeContainer>
            <InputRange
              // console.log("widthout range", salary);
              maxValue={2000000}
              minValue={0}
              step={1000}
              style={{ width: "90%", margin: "0 auto" }}
              value={salary.range}
              onChange={onSalaryRangeChange}
            />
          </SalaryRangeContainer> */}
          {/* )} */}
          <Button onClick={onSalaryFilterOkBtnClick}>
            <OkayBtnIcon /> Okay!
          </Button>
        </Container>
      </OutsideClickHandler>,
      document.getElementById("root-modal")
    );
  }
}
