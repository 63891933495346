import { createReducer } from 'Helpers/redux'

import { LOAD_CONTACTUS } from '../actions/contactUs'

const initialState = {
  contactUs: [],
  loadInfo: {
    success: false,
    loading: false,
    error: null
  }
}

const handlers = {
  [LOAD_CONTACTUS.REQUEST]: state => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: true,
        error: null
      }
    }
  },
  [LOAD_CONTACTUS.FAILURE]: (state, { payload: { data } }) => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: false,
        error: data
      }
    }
  },
  [LOAD_CONTACTUS.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      contactUs: data,
      loadInfo: {
        success: true,
        loading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
