import { createStore, applyMiddleware, compose } from 'redux'

// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

import thunk from 'redux-thunk'
import apiMiddlware from './middlewares/api'
import socketMiddlware from './middlewares/socket'

// // saga middleware
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'Redux/middlewares/sagas'

import rootReducer from './reducers'

export default () => {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [apiMiddlware, thunk, sagaMiddleware, socketMiddlware]
  // const persistConfig = {
  //   key: 'root',
  //   storage
  // }
  // const persistedReducer = persistReducer(persistConfig, rootReducer)

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const enhancer = composeEnhancers(applyMiddleware(...middlewares))

  const store = createStore(rootReducer, enhancer)
  // const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store }
}
