import React from 'react'
import PropTypes from 'prop-types'

import { Text } from './styled'

const CardSubTitle = ({ text }) => {
  return <Text>{text}</Text>
}

CardSubTitle.propTypes = {
  text: PropTypes.string.isRequired
}

export { CardSubTitle }
