import React, { Component } from 'react'
import { Window } from 'Components/Blocks'
import {
  Content,
  Icon,
  BigGreenText,
  SmallGreenText,
  Link,
  SmallTextContainer
} from './styles'
import modalRoutes from 'Constants/modal_routes'
import alreadyVerified from 'Assets/images/icons8-id-verified-100.png'

export default class SuccessWindow extends Component {
  componentDidMount() {
    const { isHiddenAuto = true } = this.props
    if (isHiddenAuto) {
      setTimeout(() => {
        this.onCloseModal()
      }, 15000)
    }
  }

  onCloseModal = () => {
    const { onClose, resetUserPassword, isResetPassword } = this.props
    onClose()
    if (isResetPassword) {
      resetUserPassword()
    }
    localStorage.removeItem('email')
  }

  onClickLink = () => {
    const {
      onSwitchModal,
      isAuthenticate = false,
      onClose
    } = this.props

    if(isAuthenticate){
      onClose()
    } else {
      onSwitchModal(modalRoutes.login)
    }
  }

  render() {
    const {
      onClose,
      bigText,
      smallText,
      link,
      source,
      smallTextInRow = false,
      isEmailConfirmed
    } = this.props

    if(isEmailConfirmed){
      return (
        <Window onClose={onClose}>
              <Content>
                <Icon src={alreadyVerified} />
                <BigGreenText>Email already verified</BigGreenText>
                <SmallTextContainer isRow={smallTextInRow}>
                  <SmallGreenText>{smallText}</SmallGreenText>
                    <Link onClick={this.onClickLink}>
                      {link}
                    </Link>
                </SmallTextContainer>
              </Content>
        </Window>
      )
    }
    return (
      <Window onClose={onClose}>
        <Content>
          <Icon src={source} />
          <BigGreenText>{bigText}</BigGreenText>
          <SmallTextContainer isRow={smallTextInRow}>
            <SmallGreenText>{smallText}</SmallGreenText>
            {link ? (
              <Link onClick={this.onClickLink}>
                {link}
              </Link>
            ) : null}
          </SmallTextContainer>
        </Content>
      </Window>
    )
  }
}
