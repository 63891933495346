function* linedinRedirect(action) {
  try {
    const { url } = action.payload.data

    window.open(url, '_self')

    yield null
  } catch (e) {
    console.error(e)
  }
}

export default linedinRedirect
