import styled from 'styled-components'

export const LoaderItem = styled.div`
  width: 314px;
  height: 303px;
  margin: 0 0 70px 30px;
  box-shadow: 0 0 15px 1px rgba(61, 61, 61, 0.42);
  border-radius: 5px;
  @media screen and (max-width: 500px) {
    margin: 0 10px 70px;
  }
`
export const LoaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 50px;
  max-width: 1400px;
  overflow: hidden;
  align-content: flex-start;
  @media screen and (max-width: 950px) {
    justify-content: center;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    flex-wrap: nowrap;
  }
`
