import Component from './CvWindow'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router'

import { setChoosenCategories } from 'Redux/actions/register'
import { register } from 'Redux/actions/auth'

import { getCompanies } from 'Redux/selectors/companies'
import { loadCompanies } from 'Redux/actions/companies'
import { getRegisterData } from 'Redux/selectors/register'

const selector = createStructuredSelector({
  companies: getCompanies,
  registerData: getRegisterData
  // cvParseState: getCVParseState
})

const actions = {
  completeCurrent: setChoosenCategories,
  loadCompanies,
  onRegister: register
  // onParseCv: parseCV
}

export default connect(
  selector,
  actions
)(withRouter(Component))
