import React, { Component } from "react";
import { Element, scroller } from "react-scroll";
import withSizes from "react-sizes";
import Waypoint from "react-waypoint";
import { Redirect } from "react-router-dom";

import { Layout, CareerNavigation } from "../../Blocks";
import { categoreis } from "./data";
import { initial } from "Constants/routes";
import { Helmet } from "react-helmet";

import {
  //SkillsIHaveLearnt,
  ImGoodAt,
  WhoAmI,
  MyPositions,
  UserInfo,
  WhatOthersSay,
  Activities,
  MyQualifications,
  TimeLine,
  MyReferee,
} from "./innerBlocks";

import { AddButton } from "./innerBlocks/blocks";

import {
  ContentWrapperStyled,
  Loader,
  Container,
  StatusNotification,
  VerifyTitle,
  VerifyButton,
} from "./styles";

class CareerTimeLine extends Component {
  constructor(props) {
    super();
    this.state = {
      activeHeaderCategory: {
        title: "career",
        id: 1,
      },
      activeCategory: "whoAmI",
      isScrollNavBarShow: true,
    };
    this.prevScrollpos = null;
  }

  //eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.onLoadProfile();
  }

  componentDidMount() {
    const { isMobile } = this.props;
    
    if (isMobile) {
      const nodeElement = document.getElementById("rootScrollContainer");
      nodeElement.addEventListener("scroll", this.setNavigationPos);
      this.prevScrollpos = nodeElement.scrollTop;
    }
  }

  componentWillUnmount() {
    const { isMobile } = this.props;
    if (isMobile) {
      const nodeElement = document.getElementById("rootScrollContainer");
      nodeElement.removeEventListener("scroll", this.setNavigationPos);
    }
  }

  setNavigationPos = () => {
    const nodeElement = document.getElementById("rootScrollContainer");
    const currentScrollPos = nodeElement.scrollTop;
    if (this.prevScrollpos > currentScrollPos) {
      this.setState(
        (prevState) =>
          !prevState.isScrollNavBarShow && { isScrollNavBarShow: true }
      );
    } else {
      this.setState(
        (prevState) =>
          prevState.isScrollNavBarShow && { isScrollNavBarShow: false }
      );
    }
    this.prevScrollpos = currentScrollPos;
  };

  setActiveCategory = (name) => {
    this.setState(
      (prevState) =>
        prevState.activeCategory !== name && { activeCategory: name }
    );
  };

  handleCategoryClick = (category) => {
    const { isMobile } = this.props;
    const containerId = isMobile
      ? "rootScrollContainer"
      : "contentScrollContainer";

    scroller.scrollTo(category, {
      smooth: "easeInOutQuint",
      containerId: containerId,
      duration: 1500,
      offset: isMobile ? -80 : -20,
    });
  };

  handleScrollToTop = () => {
    const { isMobile } = this.props;
    const containerId = isMobile
      ? "rootScrollContainer"
      : "contentScrollContainer";
    scroller.scrollTo(0, {
      smooth: "easeInOutQuint",
      containerId: containerId,
      duration: 1500,
    });
  };

  render() {
    const {
      activeCategory,
      activeHeaderCategory,
      isScrollNavBarShow,
    } = this.state;
    const {
      loadInfo,
      experience,
      academQualif,
      profQualif,
      activities,
      userFeedbacks,
      userAge,
      isAuthenticate,
      userStatus,
    } = this.props;

    const { isMobile } = this.props;
    const containerId = isMobile
      ? "rootScrollContainer"
      : "contentScrollContainer";
    const container = document.getElementById(containerId);
    const height = window.innerHeight - 120;
    if (!isAuthenticate && loadInfo.isLoaded && loadInfo.error) {
      return <Redirect to={initial} />;
    }
    return (
      <Layout
        activeHeaderCategory={activeHeaderCategory}
        header={categoreis}
        history={this.props.history}
        notScroll={true}
      >
        <Helmet>
          {/* <title>{meta.title} | Mr.JOBS</title> */}
          <title>{"Career Timeline"} | Mr.JOBS</title>
          {/* <meta content={} name={"description"} /> */}
          {/* <meta content={} name={"keywords"} /> */}
        </Helmet>
        {loadInfo.isLoading && <Loader />}
        {loadInfo.isLoaded && (
          <React.Fragment>
            <Container>
              <ContentWrapperStyled>
                {userStatus === "email_not_confirmed" ? (
                  <StatusNotification>
                    <VerifyTitle>you profile email not verified. </VerifyTitle>
                    <VerifyButton
                      onClick={() =>
                        this.props.resendConfirmEmail(
                          this.props.userData.id,
                          this.props.userEmail
                        )
                      }
                    >
                      Resend email verification
                    </VerifyButton>
                  </StatusNotification>
                ) : null}
                <CareerNavigation
                  activeCategory={activeCategory}
                  isShow={isScrollNavBarShow}
                  onCategoryClick={this.handleCategoryClick}
                  feedbacks={userFeedbacks}
                />
                <UserInfo />
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("whoAmI")}
                  bottomOffset={`${height}px`}
                />
                <Element name={"whoAmI"}>
                  <WhoAmI />
                </Element>
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("positions")}
                  bottomOffset={`${height}px`}
                />
                <Element name={"positions"}>
                  <MyPositions />
                </Element>
                {/*<Element name={'MyEducations'}>
                  <MyEduction />
                </Element>*/}
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("qualifications")}
                  bottomOffset={`${height}px`}
                />
                <Element name={"qualifications"}>
                  <MyQualifications />
                </Element>
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("imGoodAt")}
                  bottomOffset={`${height}px`}
                />
                <Element name={"imGoodAt"}>
                  <ImGoodAt />
                </Element>
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("feedbacks")}
                  bottomOffset={`${height}px`}
                />
                {userFeedbacks && userFeedbacks.length ? (
                  <Element name={"feedbacks"}>
                    <WhatOthersSay />
                  </Element>
                ) : null}
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("activities")}
                  bottomOffset={`${height}px`}
                />
                <Element name={"activities"}>
                  <Activities />
                </Element>
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("referee")}
                  bottomOffset={`${height}px`}
                />
                <Element name={"referee"}>
                  <MyReferee />
                </Element>
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory("timeLine")}
                  bottomOffset={`${height}px`}
                />
                <Element name={"timeLine"}>
                  <TimeLine
                    activities={activities}
                    onItemClick={this.handleCategoryClick}
                    positions={experience}
                    profQualif={profQualif}
                    academQualif={academQualif}
                    userAge={userAge}
                  />
                </Element>
                {/*<Element name={'SkillsIHaveLearnt'}>
                  <SkillsIHaveLearnt />
                </Element>*/}
              </ContentWrapperStyled>
            </Container>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500,
});

export default withSizes(mapSizeToProps)(CareerTimeLine);
