import React, { PureComponent } from 'react'
import { Container } from './styles'
import SkillsModal from '../SkillsModal'
import { WhoAmICard } from 'Components/Blocks/WhoAmICard'

export default class SkillsIHaveLearnt extends PureComponent {
  state = {
    isHighEdModalOpen: false,
    isAcademicQualfModalOpen: false,
    isProfQualfModalOpen: false
  }

  render() {
    return (
      <Container>
        <WhoAmICard
          title="High school educations"
          data={this.props.highSchoolEd}
          onRemoveItem={text => this.props.onRemoveHightSchoolEd(text)}
          onAddClick={() => this.setState({ isHighEdModalOpen: true })}
        />
        <WhoAmICard
          title="Academic qualifications"
          data={this.props.academicQualifications}
          onRemoveItem={text => this.props.onRemoveAcademicQualifications(text)}
          onAddClick={() => this.setState({ isAcademicQualfModalOpen: true })}
        />
        <WhoAmICard
          title="Professional qualifications"
          data={this.props.profQualf}
          onRemoveItem={text => this.props.onRemoveProfQualf(text)}
          onAddClick={() => this.setState({ isProfQualfModalOpen: true })}
        />
        <SkillsModal
          title="add high school education"
          subTitle="selected high school educations:"
          isOpen={this.state.isHighEdModalOpen}
          onRequestClose={() => this.setState({ isHighEdModalOpen: false })}
          allItems={this.props.predictionsHighSchoolEd}
          onLoad={this.props.onLoadPredictionsSchoolEd}
          userItems={this.props.highSchoolEd}
          onEditComplete={this.props.onEditHightSchoolEd}
          placeholder="Search For Educations"
        />
        <SkillsModal
          title="add academic qualifications"
          subTitle="selected academic qualifications:"
          isOpen={this.state.isAcademicQualfModalOpen}
          onRequestClose={() =>
            this.setState({ isAcademicQualfModalOpen: false })
          }
          allItems={this.props.predictionsAcademicQualifications}
          onLoad={this.props.onLoadPredictionsAcademicQualifications}
          userItems={this.props.academicQualifications}
          onEditComplete={this.props.onEditAcademicQualifications}
          placeholder="Search"
        />
        <SkillsModal
          title="add professional qualifications"
          subTitle="selected professional qualifications:"
          isOpen={this.state.isProfQualfModalOpen}
          onRequestClose={() => this.setState({ isProfQualfModalOpen: false })}
          allItems={this.props.predProfQualf}
          onLoad={this.props.onLoadProfQualf}
          userItems={this.props.profQualf}
          onEditComplete={this.props.onEditProfQualf}
          placeholder="Search"
        />
      </Container>
    )
  }
}
