/* eslint-disable max-len */

import _ from 'lodash'

export const ACCEPTED_MIME_TYPES = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx,
  'application/pdf' // .pdf
]

const inputAcceptedTypes = ['.doc', '.docx', 'application/pdf']

export const INPUT_ACCEPTED_FILE_TYPES = _.join(inputAcceptedTypes, ',')
