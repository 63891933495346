import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'

import {
  modalOverlayStyle,
  modalContentStyle,
  Container,
  CloseButton
} from './styles'

const EditableModal = ({
  isOpen,
  onClose,
  children,
  containerStyles,
  ...props
}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={{
        overlay: modalOverlayStyle,
        content: modalContentStyle
      }}
      onClose={onClose}
      {...props}
    >
      <Container style={containerStyles}>
        <CloseButton onClick={onClose} />
        {children}
      </Container>
    </Modal>
  )
}

export default EditableModal

EditableModal.propTypes = {
  containerStyles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

EditableModal.defaultProps = {
  containerStyles: {},
  isOpen: false,
  onClose: () => {}
}
