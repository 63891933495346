import Component from './ResetPassword'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { switchModal } from '../../../Redux/actions/utils'
import { checkPasswordLink } from '../../../Redux/actions/passwordReset'

import { getPasswordLinkStatus, getPasswordCheckedStatus } from 'Redux/selectors/passwordReset'

const actions = {
  onSwitchModal: switchModal,
  onCheckPasswordLink: checkPasswordLink,
}

const selector = createStructuredSelector({
  isExpiredLink: getPasswordLinkStatus,
  isChecked: getPasswordCheckedStatus
})

export default connect(
  selector,
  actions
)(Component)
