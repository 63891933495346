import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD = createAsyncAction('cities/LOAD')
export const loadCities = () => {
  return apiCall({
    endpoint: '/cities',
    types: LOAD
  })
}
