export default {
  day: {
    id: 0,
    title: 'Past 24 hours',
    subtractConfig: { day: 1 },
    calendar: false
  },
  week: {
    id: 1,
    title: 'Past week',
    subtractConfig: { day: 7 },
    calendar: false
  },
  month: {
    id: 2,
    title: 'Past month',
    subtractConfig: { month: 1 },
    calendar: false
  },
  // year: {
  //   id: 3,
  //   title: 'Past year',
  //   subtractConfig: { year: 1 },
  //   calendar: false
  // },
  // since: {
  //   id: 4,
  //   title: 'Since',
  //   subtractConfig: {},
  //   calendar: true
  // }
}
