import Component from './Login'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { switchModal } from '../../../Redux/actions/utils'

const actions = {
  onSwitchModal: switchModal
}

const selector = createStructuredSelector({})

export default connect(
  selector,
  actions
)(Component)
