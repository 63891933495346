import Component from './RedirectPage'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

const actions = {}

const selectors = createStructuredSelector({})

export default connect(
  selectors,
  actions
)(Component)
