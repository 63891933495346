import React, { PureComponent } from "react";

import {
  Window,
  RegisterGoogleLocationsAutocompleate,
} from "../../../../../Components/Blocks";
import { Description, Logo, BlueButton } from "../blocks";
import { distanceValue } from "../../../../../Constants/distanceValue";
import {
  Content,
  BottomBlock,
  LocationContainer,
  IconBlock,
  DistanceContainer,
  DistanceText,
  DistanceCounter,
  DistanceButton,
  SideText,
  BottomBlockBig,
  ContentMob,
} from "./styles";
import MediaQuery from "react-responsive";

export default class LocationWindow extends PureComponent {
  constructor(props) {
    super();
    const { location, kms, lat, lng } = props.registerData;
    const state = {
      text: "",
      kms: kms || 3,
      location,
      locationId: null,
      isEdited: false,
      lat,
      lng,
      disableNext: true,
    };

    if (location && kms && lat && lng) {
      state.disableNext = false;
    }

    this.state = state;
  }

  handleIncrKmsHandler = () => {
    const { kms } = this.state;
    const length = Object.keys(distanceValue).length - 1;
    if (kms === length) return;
    this.setState({
      kms: this.state.kms + 1,
    });
  };

  handleDcrKmsHandler = () => {
    const { kms } = this.state;
    if (kms === 1) return;
    this.setState({
      kms: this.state.kms - 1,
    });
  };

  handleDisableNextButton = () => {
    this.setState({ disableNext: true });
  };

  handleSaveLocation = (location) => {
    const { name, placeId, lat, lng } = location;
    this.setState({
      text: name,
      location: name,
      locationId: placeId,
      lat,
      lng,
      disableNext: false,
    });
  };

  handleComplete = () => {
    const { changeWindow, innerRoutes, onComplete, registerData } = this.props;
    const { location, kms, lat, lng, disableNext } = this.state;
    if (disableNext) {
      return;
    }

    if (registerData.location !== location) {
      onComplete({
        location,
        kms,
        lat,
        lng,
      });
      changeWindow(innerRoutes.cv);
    } else {
      changeWindow(innerRoutes.cv);
    }
  };

  render() {
    const { changeWindow, innerRoutes } = this.props;
    const { kms, location, disableNext } = this.state;

    return (
      <Window step={2} onClose={this.props.onClose}>
        <MediaQuery minDeviceWidth={1224} device={{ deviceWidth: 1600 }}>
          <Content>
            <Logo />
            <Description>I'm currently living at</Description>
            <LocationContainer>
              <IconBlock>
                {/* <i className="fas fa-location-arrow" /> */}
                <i className="fas fa-map-marker-alt" />
              </IconBlock>
              <RegisterGoogleLocationsAutocompleate
                location={location}
                onDisableNextButton={this.handleDisableNextButton}
                onSubmit={this.handleSaveLocation}
              />
            </LocationContainer>
            <div style={{ height: "160px" }}></div>
            {/* <Description>
              What distance is good for you between your home and workspace?
            </Description> */}
            {/* <DistanceContainer>
              <SideText>Within</SideText>
              <DistanceCounter>
                <DistanceButton
                  disabled={kms === 6}
                  onClick={this.handleIncrKmsHandler}
                >
                  <i className="fas fa-chevron-up" />
                </DistanceButton>
                <DistanceText color="#329fff">
                  {distanceValue[kms]}
                </DistanceText>
                <DistanceButton
                  disabled={this.state.kms === 1}
                  onClick={this.handleDcrKmsHandler}
                >
                  <i className="fas fa-chevron-down" />
                </DistanceButton>
              </DistanceCounter>
              <SideText>Kms</SideText>
            </DistanceContainer> */}
            <BottomBlockBig>
              <BlueButton onClick={() => changeWindow(innerRoutes.search)}>
                Back
              </BlueButton>
              <BlueButton onClick={this.handleComplete} disabled={disableNext}>
                Next
              </BlueButton>
            </BottomBlockBig>
          </Content>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <ContentMob>
            <Logo />
            <Description>I'm currently living at</Description>
            <LocationContainer>
              <IconBlock>
                <i className="fas fa-location-arrow" />
              </IconBlock>
              <RegisterGoogleLocationsAutocompleate
                location={location}
                onDisableNextButton={this.handleDisableNextButton}
                onSubmit={this.handleSaveLocation}
              />
            </LocationContainer>
            {/* <Description>
              What distance is good for you between your home and workspace?
            </Description> */}
            <DistanceContainer>
              <SideText>Within</SideText>
              <DistanceCounter>
                <DistanceButton
                  disabled={kms === 6}
                  onClick={this.handleIncrKmsHandler}
                >
                  d
                  <i className="fas fa-chevron-up" />
                </DistanceButton>
                <DistanceText color="#329fff">
                  {distanceValue[kms]}
                </DistanceText>
                <DistanceButton
                  disabled={this.state.kms === 1}
                  onClick={this.handleDcrKmsHandler}
                >
                  <i className="fas fa-chevron-down" />
                </DistanceButton>
              </DistanceCounter>
              <SideText>Kms</SideText>
            </DistanceContainer>
            <BottomBlock>
              <BlueButton onClick={() => changeWindow(innerRoutes.search)}>
                Back
              </BlueButton>
              <BlueButton onClick={this.handleComplete} disabled={disableNext}>
                Next
              </BlueButton>
            </BottomBlock>
          </ContentMob>
        </MediaQuery>
      </Window>
    );
  }
}
