import styled from "styled-components";
import * as colors from "Theme/colors";

export const Content = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;
export const ContentMob = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90vh;
`;
export const ItemText = styled.div`
  color: ${colors.lightGreyFont};
  font-size: 11px;
  text-align: center;
  margin: 11px 0 5px;
  max-width: 60px;
`;
export const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0 8px;
  position: fixed;
  bottom: 5%;
  width: 87vw;
`;
export const BottomBlockBig = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 8px;
`;
export const SpinerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
`;
