import React from 'react'
import PropTypes from 'prop-types'

import StarRatingComponent from 'react-star-rating-component'

import { StarIcon, RatingText } from '../../../blocks'

import {
  Container,
  Title,
  List,
  Item,
  RatingContainer,
  Circle,
  TextContainer,
  Text
} from './styled'

const UserLanguagesBlock = ({ languages }) => {
  const renderItems = () => {
    if (languages) {
      return languages.map(item => {
        let levelText = ''
        switch (item.level) {
          case 3:
            levelText = 'Native'
            break
          case 2:
            levelText = 'Fluent'
            break
          case 1:
            levelText = 'Average'
            break
          default:
            levelText = null
            break
        }
        return (
          <Item>
            <TextContainer>
              <Circle />
              <Text>{item.name}</Text>
            </TextContainer>
            <RatingContainer>
              <StarRatingComponent
                editing={false}
                emptyStarColor={'#fff'}
                name={item.lan_id}
                renderStarIcon={() => <StarIcon />}
                starColor={'#00e99a'}
                starCount={3}
                value={item.level}
              />
              <RatingText value={levelText} />
            </RatingContainer>
          </Item>
        )
      })
    }
  }

  return (
    <Container>
      <Title>Languages: </Title>
      <List>{renderItems()}</List>
    </Container>
  )
}

UserLanguagesBlock.propTypes = {
  languages: PropTypes.array,
  onStarClick: PropTypes.func.isRequired
}

export { UserLanguagesBlock }
