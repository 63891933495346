import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { Window, IconBlock, TextBox } from "Components/Blocks";
import addUser from "Assets/images/addUser.png";
import * as colors from "Theme/colors";
import { Description, Logo, BlueButton } from "../blocks";
import { distanceValue } from "../../../../../Constants/distanceValue";
import {
  Content,
  BottomBlock,
  AddAvatarContainer,
  GreyText,
  Form,
  InfoText,
  InfoLink,
  IconBlockContainer,
  FileInput,
  AvatarChooseContainer,
  Icon,
  GenderContainer,
  styles,
  ButtonFix,
  ContentMob,
  BottomBlockBig,
} from "./styles";
import MediaQuery from "react-responsive";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

import * as validation from "Constants/validation";
import base64Converter from "Helpers/base64Converter";

class DetailsWindow extends PureComponent {
  constructor(props) {
    super(props);

    const name = localStorage.getItem("fullName");
    const genderL = localStorage.getItem("gender");
    const emailL = localStorage.getItem("email");
    let avatarL = localStorage.getItem("avatar");

    //delete cached value
    // console.log(
    //   "typeof avatarL ===  && avatarL === null",
    //   typeof avatarL === "string" && avatarL === null
    // );
    if (typeof avatarL === "string" && avatarL === "null") {
      localStorage.removeItem("avatar");
      avatarL = null;
    }
    // console.log("avatarL===>", typeof avatarL);
    // console.log("avatarL===>", avatarL);
    this.state = {
      fullName: name ? name : "",
      gender: genderL ? genderL : "Male",
      email: emailL ? emailL : "",
      password: "",
      confirmPassword: "",
      avatar: avatarL ? avatarL : null,
      fNWarning: false,
      emailWarning: false,
      passwordWarning: false,
      cPasswordWarning: false,
    };
  }
  // componentDidUpdate() {
  //   const { isAuthenticate, onClose } = this.props
  //   if (isAuthenticate) onClose()
  // }

  handleChangeGender = ({ target }) => this.setState({ gender: target.value });

  isRegisterSuccess = (status) => {
    const { changeWindow, innerRoutes } = this.props;
    if (!status) {
      this.setState({
        emailWarning: true,
      });
    } else {
      changeWindow(innerRoutes.success);
      localStorage.removeItem("fullName");
      localStorage.removeItem("gender");
      localStorage.removeItem("email");
      localStorage.removeItem("avatar");
    }
  };

  handleAuth = () => {
    const { getRegisterData } = this.props;

    if (this.validate()) {
      console.error("form is invalid");
    } else {
      const {
        categories,
        jobTypes,
        companies,
        location,
        kms,
        lat,
        lng,
        cvFile,
        cvFileMimeType,
      } = getRegisterData;
      // console.log("this.state.avatar=====>", typeof this.state.avatar);
      const registObg = {
        categories,
        jobTypes,
        companies,
        location,
        kms: distanceValue[kms],
        lat,
        lng,
        cvFile,
        cvFileMimeType,
        email: this.state.email,
        password: this.state.password,
        name: this.state.fullName,
        avatar: this.state.avatar,
        gender: this.state.gender,
      };
      this.props.onRegister(registObg, this.isRegisterSuccess);
    }
    /*Promise.resolve(this.props.onRegister(registObg)).then(resp => {
        console.log('Response ', resp, resp.hasError)
        if (resp.hasError) {
          this.setState({
            emailWarning: true
          })
        } else {
          console.log('WWW ', resp)
          changeWindow(innerRoutes.success)
          localStorage.removeItem('fullName')
          localStorage.removeItem('gender')
          localStorage.removeItem('email')
          localStorage.removeItem('avatar')
        }
      })*/
  };

  validate = () => {
    if (!this.state.avatar) {
      toast.error("Add your profile image");
      return true;
    } else if (!this.state.fullName.length) {
      this.setState({ fNWarning: true });
      toast.error("Enter your name please");
      return true;
    } else if (!this.state.email.length) {
      this.setState({ fNWarning: false, emailWarning: true });
      toast.error("Enter your email please");
      return true;
    } else if (!validation.email.test(this.state.email)) {
      this.setState({ emailWarning: true });
      toast.error("Email is not valid");
      return true;
    }
    const { password, confirmPassword } = this.state;
    const passValidated = validation.passwordValidate(
      password,
      confirmPassword
    );

    let passwordWarning = false;
    let cPasswordWarning = false;

    switch (passValidated) {
      case "lengthError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, use a special character , upper & lower case alphabets, and numbers"
        );
        break;
      case "numberError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, use a special character , upper & lower case alphabets, and numbers"
        );
        break;
      case "loverCaseError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, use a special character , upper & lower case alphabets, and numbers"
        );
        break;
      case "upperCaseError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, use a special character , upper & lower case alphabets, and numbers"
        );
        break;
      case "specialCharactersError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, use a special character , upper & lower case alphabets, and numbers"
        );
        break;
      case "passwordsNoMatch":
        cPasswordWarning = true;
        toast.error("Passwords does not match");
        break;
      default:
        passwordWarning = false;
        cPasswordWarning = false;
    }
    this.setState({
      emailWarning: false,
      fNWarning: false,
      passwordWarning,
      cPasswordWarning,
    });
    return cPasswordWarning || passwordWarning;
  };

  handleFileChange = (e) => {
    const files = e.target.files;

    if (files.length) {
      const fsize = files[0].size;
      const file = Math.round(fsize / 1024);
      // console.log("size", file);
      if (file >= 4096) {
        toast.error("File too big, please select a file less than 4mb");
      } else {
        const file = files[0];
        base64Converter(file).then((res) => this.setState({ avatar: res }));
      }
    }
  };

  handleChangeWindow = () => {
    const { changeWindow, innerRoutes } = this.props;
    const { fullName, gender, email, avatar } = this.state;
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("gender", gender);
    localStorage.setItem("email", email);
    localStorage.setItem("avatar", avatar);
    changeWindow(innerRoutes.cv);
  };

  render() {
    const {
      changeWindow,
      innerRoutes,
      isRegisterLoading,
      classes,
    } = this.props;
    const { fullName, email, password, confirmPassword, gender } = this.state;

    const isNextDisabled =
      isRegisterLoading ||
      !(
        fullName.length &&
        email.length &&
        password.length &&
        confirmPassword.length
      );

    return (
      <Window onClose={this.props.onClose} step={4}>
        <MediaQuery minDeviceWidth={1224} device={{ deviceWidth: 1600 }}>
          <Content>
            <Logo />
            <Description>Just FOUR details from you & you're in...</Description>
            <AddAvatarContainer>
              <AvatarChooseContainer>
                <IconBlockContainer>
                  <IconBlock borderColor={colors.chatPurple}>
                    <Icon src={this.state.avatar || addUser} />
                  </IconBlock>
                </IconBlockContainer>
                <FileInput
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  onChange={this.handleFileChange}
                />
              </AvatarChooseContainer>
              <GreyText>
                Add a picture of yourself to increase your chance of getting the
                right job
              </GreyText>
            </AddAvatarContainer>
            <Form>
              <TextBox
                borderColor="transparent"
                name={"fullName"}
                onChange={(e) => this.setState({ fullName: e.target.value })}
                onEnterPress={this.handleAuth}
                placeholder="Your full name please"
                redStar
                value={this.state.fullName}
                warning={this.state.fNWarning}
              />
              <GenderContainer>
                <RadioGroup
                  className={classes.group}
                  row
                  value={gender}
                  onChange={this.handleChangeGender}
                >
                  <FormControlLabel
                    classes={{
                      label: classes.radioBtn,
                    }}
                    control={<Radio color="primary" />}
                    label={"Male"}
                    value={"Male"}
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.radioBtn,
                    }}
                    control={<Radio color="primary" />}
                    label={"Female"}
                    value={"Female"}
                  />
                </RadioGroup>
              </GenderContainer>
              <TextBox
                borderColor="black"
                placeholder="Your favorite email"
                redStar
                required
                type="email"
                value={this.state.email}
                warning={this.state.emailWarning}
                onChange={(e) => this.setState({ email: e.target.value })}
                onEnterPress={this.handleAuth}
              />
              <TextBox
                borderColor="transparent"
                placeholder="$tr0ng password please"
                redStar
                type="password"
                value={this.state.password}
                warning={this.state.passwordWarning}
                onChange={(e) => this.setState({ password: e.target.value })}
                onEnterPress={this.handleAuth}
              />
              <TextBox
                borderColor="transparent"
                placeholder="Confirm password please"
                redStar
                type="password"
                value={this.state.confirmPassword}
                warning={this.state.cPasswordWarning}
                onChange={(e) =>
                  this.setState({ confirmPassword: e.target.value })
                }
                onEnterPress={this.handleAuth}
              />
              <InfoText>
                Make your password strong - use a special character , upper &
                lower case alphabets, and numbers
              </InfoText>
              <InfoText>
                By clicking Sign Up, you agree to our{" "}
                <InfoLink
                  href="https://www.mrjobs.co/seeker/page/terms-and-conditions"
                  target="_blank"
                >
                  Terms
                </InfoLink>
                ,{" "}
                <InfoLink
                  href="https://www.mrjobs.co/seeker/page/privacy-policy"
                  target="_blank"
                >
                  Data Policy
                </InfoLink>{" "}
                and{" "}
                <InfoLink
                  href="https://www.mrjobs.co/seeker/page/cookies-policy"
                  target="_blank"
                >
                  Cookie Policy
                </InfoLink>
                . You may receive Email notifications from us and can opt out at
                any time.
              </InfoText>
            </Form>
            <BottomBlockBig>
              <BlueButton
                disabled={isRegisterLoading}
                onClick={this.handleChangeWindow}
              >
                <ButtonFix>Back</ButtonFix>
              </BlueButton>
              <BlueButton
                disabled={isNextDisabled}
                loading={isRegisterLoading}
                onClick={this.handleAuth}
              >
                <ButtonFix>Next</ButtonFix>
              </BlueButton>
            </BottomBlockBig>
          </Content>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <ContentMob>
            <Logo />
            <Description>Just FOUR details from you & you're in...</Description>
            <AddAvatarContainer>
              <AvatarChooseContainer>
                <IconBlockContainer>
                  <IconBlock borderColor={colors.chatPurple}>
                    <Icon src={this.state.avatar || addUser} />
                  </IconBlock>
                </IconBlockContainer>
                <FileInput
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  onChange={this.handleFileChange}
                />
              </AvatarChooseContainer>
              <GreyText>
                Add a picture of yourself to increase your chance of getting the
                right job
              </GreyText>
            </AddAvatarContainer>
            <Form>
              <TextBox
                borderColor="transparent"
                name={"fullName"}
                onChange={(e) => this.setState({ fullName: e.target.value })}
                onEnterPress={this.handleAuth}
                placeholder="Your full name please"
                redStar
                value={this.state.fullName}
                warning={this.state.fNWarning}
              />
              <GenderContainer>
                <RadioGroup
                  className={classes.group}
                  row
                  value={gender}
                  onChange={this.handleChangeGender}
                >
                  <FormControlLabel
                    classes={{
                      label: classes.radioBtn,
                    }}
                    control={<Radio color="primary" />}
                    label={"Male"}
                    value={"Male"}
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.radioBtn,
                    }}
                    control={<Radio color="primary" />}
                    label={"Female"}
                    value={"Female"}
                  />
                </RadioGroup>
              </GenderContainer>
              <TextBox
                borderColor="transparent"
                placeholder="Your favorite email"
                redStar
                required
                type="email"
                value={this.state.email}
                warning={this.state.emailWarning}
                onChange={(e) => this.setState({ email: e.target.value })}
                onEnterPress={this.handleAuth}
              />
              <TextBox
                borderColor="transparent"
                placeholder="$tr0ng password please"
                redStar
                type="password"
                value={this.state.password}
                warning={this.state.passwordWarning}
                onChange={(e) => this.setState({ password: e.target.value })}
                onEnterPress={this.handleAuth}
              />
              <TextBox
                borderColor="transparent"
                placeholder="Confirm password please"
                redStar
                type="password"
                value={this.state.confirmPassword}
                warning={this.state.cPasswordWarning}
                onChange={(e) =>
                  this.setState({ confirmPassword: e.target.value })
                }
                onEnterPress={this.handleAuth}
              />
              <InfoText>
                Make your password strong - use a special character , upper &
                lower case alphabets, and numbers
              </InfoText>
              <InfoText>
                By clicking Sign Up, you agree to our{" "}
                <InfoLink
                  href="https://www.mrjobs.co/seeker/page/terms-and-conditions"
                  target="_blank"
                >
                  Terms
                </InfoLink>
                ,{" "}
                <InfoLink
                  href="https://www.mrjobs.co/seeker/page/privacy-policy"
                  target="_blank"
                >
                  Data Policy
                </InfoLink>{" "}
                and{" "}
                <InfoLink
                  href="https://www.mrjobs.co/seeker/page/cookies-policy"
                  target="_blank"
                >
                  Cookie Policy
                </InfoLink>
                . You may receive Email notifications from us and can opt out at
                any time.
              </InfoText>
            </Form>
            <BottomBlock>
              <BlueButton
                disabled={isRegisterLoading}
                onClick={this.handleChangeWindow}
              >
                <ButtonFix>Back</ButtonFix>
              </BlueButton>
              <BlueButton
                disabled={isNextDisabled}
                loading={isRegisterLoading}
                onClick={this.handleAuth}
              >
                <ButtonFix>Next</ButtonFix>
              </BlueButton>
            </BottomBlock>
          </ContentMob>
        </MediaQuery>
      </Window>
    );
  }
}

export default withStyles(styles)(DetailsWindow);
