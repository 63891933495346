import styled from 'styled-components'

export const Select = styled.select`
  width: 100%;
  border: solid 1px ${props => (props.inputError ? 'red' : '#583ea1')};
`
export const SelectOption = styled.option``

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 47%;
`
export const SelectTitle = styled.p`
  margin-bottom: 5px;
`
