import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  SearchInputContainer,
  LocationList,
  Container,
  CityItem,
  Loader,
  ScrollbarContainer,
  LoaderContainer,
  InputSubText
} from './styled'

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

/*props:
* onSubmit = () => {location: name, placeId, lat, lng}
* onDisableNextButton
* location = string (locationName)
* */
export default class GooglePlacesAutocompleate extends Component {
  static propTypes = {
    location: PropTypes.string,
    onDisableNextButton: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  constructor(props) {
    super()
    this.state = {
      address: props.location || '',
      location: {
        name: '',
        placeId: '',
        lat: null,
        lng: null
      },
      geocodeLoading: false
    }

  }

  handleChangeSearch = address => {
    this.setState({
      address,
      location: {}
    })
  }

  handleSelect = async (address, placeId) => {
    try {
      const { onDisableNextButton } = this.props
      onDisableNextButton()
      this.setState({ geocodeLoading: true })
      const geocode = await geocodeByAddress(address)
      const latLng = await getLatLng(geocode[0])
      const location = {
        name: address,
        placeId: placeId,
        lat: latLng.lat,
        lng: latLng.lng
      }
      this.setState(
        {
          location,
          address,
          geocodeLoading: false
        },
        this.handleSubmit
      )
    } catch (error) {
      console.error('geocodeError', error)
    }
  }

  handleSubmit = () => {
    const { onSubmit } = this.props
    const { location } = this.state

    onSubmit(location)
  }

  renderContent = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading
  }) => {
    const { address, geocodeLoading } = this.state
    const { location: propsLocation } = this.props
    // console.log('=========>>>>>>>>>>>>>>>>>>>')
    return (
      <Fragment>
        <Container>
          <SearchInputContainer>
            <Input
              {...getInputProps({
                placeholder: 'Search your home location'
              })}
              disabled={geocodeLoading}    
            />
            {propsLocation && address !== propsLocation ? (
              <InputSubText
                title={propsLocation}
                onClick={() => this.setState({ address: propsLocation })}
              >
                Selected: {propsLocation}
              </InputSubText>
            ) : null}
            {/* <LoaderContainer>
              <Loader active={loading || geocodeLoading} />
            </LoaderContainer> */}
          </SearchInputContainer>
          {suggestions.length && !loading ? (
            <LocationList>
              <ScrollbarContainer>
                {suggestions.map((city, index) => {
                  return (
                    <CityItem
                      {...getSuggestionItemProps(city)}
                      key={city.placeId}
                      title={city.description}
                    >
                      {city.description}
                    </CityItem>
                  )
                })}
              </ScrollbarContainer>
            </LocationList>
          ) : null}
        </Container>
      </Fragment>
    )
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChangeSearch}
        onSelect={this.handleSelect}
      >
        {this.renderContent}
      </PlacesAutocomplete>
    )
  }
}
