import styled from 'styled-components'

import {
  Icon as icon,
  SocialLink as link,
  SocialBlock as container
} from '../../../CareerTimeLine/innerBlocks/UserInfo/style'

export const Link = styled(link)``

export const Icon = styled(icon)`
  font-size: 25px;
`

export const Container = styled(container)``
