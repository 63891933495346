export const headerMobileScroll = ({ parent, child }) => {
  let i = parent.scrollLeft
  let oldScrollLeftData = 0
  const revers = child.getBoundingClientRect().x < 0
  const scroll = setInterval(() => {
    oldScrollLeftData = parent.scrollLeft
    if (revers) {
      i -= 5
      parent.scrollTo(i, 0)
      if (
        child.getBoundingClientRect().x >= 10 ||
        parent.scrollLeft === oldScrollLeftData
      ) {
        clearInterval(scroll)
      }
    } else {
      i += 5
      parent.scrollTo(i, 0)
      if (
        child.getBoundingClientRect().x <= 10 ||
        parent.scrollLeft === oldScrollLeftData
      ) {
        clearInterval(scroll)
      }
    }
  }, 5)
}
