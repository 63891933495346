import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'

import { Card } from '../blocks'
import { Element } from 'react-scroll'

import { getProfQualifImage } from '../../../../../Helpers/getImages'

import { Container, CardsList } from './styled'

export default class MyProfessionalQualifications extends Component {
  constructor(props) {
    super()
    if (!props.profQualifList.length) props.onLoadProfQualifList()
  }

  renderCards = () => {
    const { userProfQualif, profQualifList } = this.props
    if (userProfQualif && userProfQualif.length) {
      return userProfQualif.map(item => {
        const profQualif = profQualifList.find(
          el => el.id === item.prof_qualf_id
        )
        return (
          <Element key={item.id} name={`academQualifId${item.id}`}>
            <Card
              description={item.description}
              endDate={item.end_date}
              faIcon={'fas fa-user-tie'}
              id={item.id}
              image={profQualif && getProfQualifImage(profQualif.image)}
              item={item}
              startDate={item.start_date}
              subTitle={item.sub_title}
              title={item.title}
              type={'professional'}
              currently={item.currently}
            />
          </Element>
        )
      })
    }
  }

  render() {
    return (
      <Container>
        <CardsList>{this.renderCards()}</CardsList>
      </Container>
    )
  }
}
