import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './SkillsIHaveLearnt'
import {
  loadHighSchoolEd,
  removeHighSchoolEd,
  editHighSchoolEd
} from 'Redux/actions/highSchoolEd'
import { getPredictionsHighSchoolEd } from 'Redux/selectors/highSchoolEd'
import {
  getUserHighSchoolEd,
  getUserAcademicQualifications,
  getUserProfQualf
} from 'Redux/selectors/user'
import {
  loadAcademicQualifications,
  removeAcademicQualifications,
  editAcademicQualifications
} from 'Redux/actions/academicQualifications'
import { getPredictionsAcademicQualifications } from 'Redux/selectors/academicQualifications'
import {
  loadProfQualf,
  removeProfQualf,
  editProfQualf
} from 'Redux/actions/professionalQualifications'
import { getPredProfQualf } from 'Redux/selectors/professionalQualifications'

const actions = {
  onLoadPredictionsSchoolEd: loadHighSchoolEd,
  onRemoveHightSchoolEd: removeHighSchoolEd,
  onEditHightSchoolEd: editHighSchoolEd,
  onLoadPredictionsAcademicQualifications: loadAcademicQualifications,
  onRemoveAcademicQualifications: removeAcademicQualifications,
  onEditAcademicQualifications: editAcademicQualifications,
  onLoadProfQualf: loadProfQualf,
  onRemoveProfQualf: removeProfQualf,
  onEditProfQualf: editProfQualf
}
const selector = createStructuredSelector({
  predictionsHighSchoolEd: getPredictionsHighSchoolEd,
  predictionsAcademicQualifications: getPredictionsAcademicQualifications,
  predProfQualf: getPredProfQualf,
  highSchoolEd: getUserHighSchoolEd,
  academicQualifications: getUserAcademicQualifications,
  profQualf: getUserProfQualf
})

export const SkillsIHaveLearnt = connect(
  selector,
  actions
)(Component)
