import React, { PureComponent } from "react";
import Modal from "react-modal";
import MediaQuery from "react-responsive";
import _ from "lodash";
import * as validation from "Constants/validation";
import {
  Content,
  Header,
  Title,
  CloseButton,
  TextBoxContainer,
  Icon,
  Input,
  ListTitle,
  List,
  ListItem,
  ItemTitle,
  ItemIcon,
  IconAdd,
  ListContainer,
  InputContainer,
  modalStyle,
  Button,
  IconSend,
  Loader,
  modalStyleMobile,
} from "./styles";

export default class EmailsModal extends PureComponent {
  state = {
    text: "",
    emails: [],
    warning: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invEmLoadInfo } = nextProps;
    if (invEmLoadInfo.isUploaded || invEmLoadInfo.error) {
      this.onClose();
    }
  }

  onTextChange = (e) => {
    this.setState({
      text: e.target.value,
      warning: false,
    });
  };

  onAddHandler = () => {
    const { text } = this.state;
    // const isSelected = true;
    const parsed = text.toLowerCase();
    // TonEmailCheck();
    if (validation.email.test(parsed)) {
      this.props.onAddSavedEmail(parsed);
      this.setState({ warning: false, text: "" });
    } else {
      this.setState({ warning: true });
    }
  };

  onAfterOpen = () => {
    const {
      onLoadEmails,
      onLoadInvEmails,
      onResetInvEmails,
      appId,
    } = this.props;
    onResetInvEmails();
    onLoadEmails();
    onLoadInvEmails(appId);
    this.setState({
      emails: [],
    });
  };

  onDeleteSavedEmail = (id, e) => {
    this.props.onDeleteSavedEmail(id);
    e.stopPropagation();
  };

  onEmailCheck = (email) => {
    const { invitedEmails } = this.props;
    let emails = this.state.emails.splice(0);
    const isSent = _.find(invitedEmails, (el) => el.email === email);
    if (isSent) return;

    const isExists = _.find(emails, (el) => el === email);

    if (isExists) {
      emails = _.filter(emails, (el) => el !== email);
    } else {
      emails.push(email);
    }

    this.setState({
      emails,
    });
  };

  onSendClick = () => {
    const { emails } = this.state;
    const {
      invitedEmails,
      onAddEmailInvite,
      appId,
      invEmLoadInfo,
    } = this.props;
    const toSend = [];

    if (invEmLoadInfo.isLoading) return;

    _.forEach(emails, (email) => {
      const isSent = _.find(invitedEmails, (el) => email === el.email);

      if (isSent) return;
      toSend.push(email);
    });

    if (toSend.length) {
      onAddEmailInvite(appId, toSend);
    }
  };

  onClose = () => {
    this.props.onResetInvEmails();
    this.props.onClose();
  };

  renderItems = () => {
    const { savedEmails, invitedEmails } = this.props;
    const { emails } = this.state;
    return savedEmails.map((el, i) => {
      const isSelected = _.find(emails, (email) => email === el.email);
      const isInvited = _.find(invitedEmails, (inv) => inv.email === el.email);
      return (
        <ListItem
          invited={isInvited}
          key={i}
          selected={isSelected}
          onClick={() => this.onEmailCheck(el.email)}
        >
          <ItemTitle>{el.email}</ItemTitle>
          <ItemIcon onClick={(e) => this.onDeleteSavedEmail(el.id, e)}>
            <i className="fas fa-trash-alt" />
          </ItemIcon>
        </ListItem>
      );
    });
  };
  render() {
    const { isOpen, invEmLoadInfo } = this.props;
    const { warning, emails, text } = this.state;
    return (
      <React.Fragment>
        <MediaQuery maxDeviceWidth={499}>
          <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            style={modalStyleMobile}
            onAfterOpen={this.onAfterOpen}
          >
            <Content>
              <Header>
                <Title>Get Friends Support</Title>
                <CloseButton onClick={this.onClose}>
                  <i className="fas fa-times" />
                </CloseButton>
              </Header>
              <TextBoxContainer>
                <InputContainer>
                  {invEmLoadInfo.isLoading ? (
                    <Loader />
                  ) : (
                    <Icon warning={warning}>
                      <i className="far fa-envelope" />
                    </Icon>
                  )}

                  <Input
                    placeholder="Type your friends email address"
                    value={text}
                    onChange={this.onTextChange}
                    type="email"
                  />
                </InputContainer>

                <IconAdd onClick={this.onAddHandler}>
                  <i className="fas fa-plus-circle" />
                </IconAdd>
              </TextBoxContainer>
              <ListTitle>Select emails to send: </ListTitle>
              <ListContainer>
                <List>{this.renderItems()}</List>
              </ListContainer>
              <Button
                disabled={!emails.length || invEmLoadInfo.isLoading}
                onClick={this.onSendClick}
              >
                <IconSend>
                  <i className="fab fa-telegram-plane" />
                </IconSend>
                Send!
              </Button>
            </Content>
          </Modal>
        </MediaQuery>
        <MediaQuery minDeviceWidth={500}>
          <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            style={modalStyle}
            onAfterOpen={this.onAfterOpen}
          >
            <Content>
              <Header>
                <Title>Get Friends Support</Title>
                <CloseButton onClick={this.onClose}>
                  <i className="fas fa-times" />
                </CloseButton>
              </Header>
              <TextBoxContainer>
                <InputContainer>
                  {invEmLoadInfo.isLoading ? (
                    <Loader />
                  ) : (
                    <Icon warning={warning}>
                      <i className="far fa-envelope" />
                    </Icon>
                  )}

                  <Input
                    placeholder="Type your friends email address"
                    value={text}
                    onChange={this.onTextChange}
                  />
                </InputContainer>

                <IconAdd onClick={this.onAddHandler}>
                  <i className="fas fa-plus-circle" />
                </IconAdd>
              </TextBoxContainer>
              <ListTitle>Select emails to send: </ListTitle>
              <ListContainer>
                <List>{this.renderItems()}</List>
              </ListContainer>
              <Button
                disabled={!emails.length || invEmLoadInfo.isLoading}
                onClick={this.onSendClick}
              >
                <IconSend>
                  <i className="fab fa-telegram-plane" />
                </IconSend>
                Send!
              </Button>
            </Content>
          </Modal>
        </MediaQuery>
      </React.Fragment>
    );
  }
}
