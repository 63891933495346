import styled from 'styled-components'

import StackGrid from 'react-stack-grid'

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  font-family: Nunito;
  margin: 20px 0 0;
`
export const CardsList = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`
