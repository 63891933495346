import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_SUB_EXPERTISES = createAsyncAction('subEpxertises/LOAD')
export const loadSubExpertises = ({ name, expId }) => {
  return apiCall({
    endpoint: '/subexpertises',
    types: LOAD_SUB_EXPERTISES,
    method: 'GET',
    query: {
      name,
      expId
    }
  })
}
export const resetPredSubExperties = () => ({
  type: LOAD_SUB_EXPERTISES.SUCCESS,
  payload: {
    data: []
  }
})
export const REMOVE_SUB_EXPERTISES = createAsyncAction('subExpertises/REMOVE')
export const removeSubExpertises = id => {
  return apiCall({
    endpoint: '/subexpertises/id',
    types: REMOVE_SUB_EXPERTISES,
    method: 'DELETE',
    query: {
      id: [id]
    }
  })
}
export const EDIT_SUB_EXPERTISES = createAsyncAction('subEpxertises/EDIT')
export const editSubExpertises = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/subexpertises',
    types: EDIT_SUB_EXPERTISES,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}
export const UPDATE_SUB_EXPERTISES = createAsyncAction('subEpxertises/UPDATE')
export const updateSubExpertises = ({ subExpId, level }) => {
  return apiCall({
    endpoint: '/subexpertises',
    types: UPDATE_SUB_EXPERTISES,
    method: 'PUT',
    query: {
      subExpId,
      level
    }
  })
}
