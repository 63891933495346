import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_NOTIFICATIONS = createAsyncAction(
  'notifications/LOAD_NOTIFICATIONS'
)
export const loadNotifications = () => {
  return apiCall({
    endpoint: '/notifications',
    types: LOAD_NOTIFICATIONS
  })
}
