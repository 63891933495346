import React, { PureComponent } from "react";
import _ from "lodash";

import { Helmet } from "react-helmet";
import { getCurrentPosition } from "Helpers/geo";
import { CATEGORY_ALL, CATEGORY_SAVED } from "Constants/jobTabs";
import { jobs } from "Constants/routes";
import Layout from "../../Blocks/LayOut";
import Cards from "../../Blocks/Cards/Cards";
import withSizes from "react-sizes";
import { Modal } from "Components/Blocks";

import {
  ButtonContainer,
  Loader,
  LoaderContainer,
  ModalButton,
  ModalHeader,
} from "./styled";

import queryString from "query-string";

class Jobs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeCategory: CATEGORY_ALL,
      renderCardsCounter: 12,
      isFollowModalShown: false,
      paginLoadInfo: {
        isLoading: false,
        end: false,
      },
      meta: props.metaTags || {},
      industriesArray: [],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      location,
      onFilterUpdate,
      onUserJobsLoad,
      filter,
      history,
    } = this.props;
    console.log(
      "🚀 ~ file: Jobs.js ~ line 49 ~ Jobs ~ UNSAFE_componentWillMount ~ location",
      location
    );
    const params = queryString.parse(location.search);
    //decoding
    const decodedParams = {};
    Object.keys(params).forEach((item) => {
      decodedParams[item] = JSON.parse(atob(params[item]));
    });
    if (Object.keys(decodedParams).length) {
      onFilterUpdate(decodedParams);
      onUserJobsLoad(decodedParams);
    } else {
      this.parseUrl(filter);
    }

    // if (!localStorage.getItem('candidate_id')) {
    //   history.push(`${jobs}/${CATEGORY_ALL.route}${location.search}`)
    // }
  }

  componentDidMount() {
    const {
      onUserJobsLoad,
      onCompaniesLoad,
      onJobTypesLoad,
      onOptionsLoad,
      onExpertisesLoad,
      onSavedVacanciesLoad,
      isAuthenticate,
      filter,
      userJobs,
      salaryRange,
      onSalaryRangeLoad,
      location,
      onGetIpGeolocation,
      savedVacancies,
      onLoadIndustries,
      onLoadMeta,
      metaTags,
    } = this.props;
    // onGetIpGeolocation();
    const params = queryString.parse(location.search);
    // if (_.isArray(savedVacancies) && savedVacancies.length === 0) {
    //   console.log(savedVacancies.length, "savedVacancies");
    //   this.props.history.push(`${jobs}/${CATEGORY_ALL.route}`);
    // }

    //decoding
    const decodedParams = {};
    Object.keys(params).forEach((item) => {
      decodedParams[item] = JSON.parse(atob(params[item]));
    });

    if (
      !(userJobs.All && userJobs.All.length) &&
      !Object.keys(decodedParams).length
    ) {
      // getCurrentPosition()
      //   .then((userLocation) => {
      //     onUserJobsLoad({
      //       ...filter,
      //       location: {
      //         userLocation,
      //       },
      //     });
      //   })
      //   .catch((err) => {
      //     onUserJobsLoad(filter);
      //   });
    }

    onCompaniesLoad();
    onJobTypesLoad();
    onOptionsLoad();
    onExpertisesLoad();
    onLoadIndustries();
    _.isEmpty(metaTags) && onLoadMeta();
    isAuthenticate && onSavedVacanciesLoad();
    !salaryRange && onSalaryRangeLoad();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      onFollowIndustry,
      getIndustries,
      match,
      followedIndustries,
      history,
      metaTags,
    } = nextProps;
    if (!_.isEmpty(metaTags) && _.isEmpty(this.props.metaTags)) {
      this.setState({
        meta: metaTags,
      });
    }
    if (
      getIndustries !== this.props.getIndustries &&
      CATEGORY_ALL.route !== match.params.job
    ) {
      this.setState({ industriesArray: getIndustries }, () => {
        this.handleFollowIndustry(true);
      });
      //   if (!localStorage.getItem("candidate_id") && getIndustries.length) {
      //     this.setState({ industriesArray: getIndustries }, () => {
      //       this.handleFollowIndustry(true);
      //     });
      //   }
      //   else {
      //     //TODO modal window should be shown
      //     const isFollowed = this.getIndustriesList(followedIndustries).find(
      //       (el) => el.route === match.params.job
      //     );
      //     if (
      //       !isFollowed &&
      //       match.params.job !== "all" &&
      //       match.params.job !== "all_industries" &&
      //       match.params.job !== "saved"
      //     ) {
      //       this.setState({
      //         isFollowModalShown: true,
      //       });
      //     }
      //   }
    }
  }

  getTab = () => {
    const { match, savedVacancies } = this.props;
    const { industriesArray } = this.state;

    const tabs = industriesArray.map((el) => {
      return {
        id: el.id,
        title: el.name,
        route: el.name
          .split(/[\s/]+/)
          .join("_")
          .toLowerCase(),
      };
    });
    // console.log("call tabs", savedVacancies);
    // console.log("call tabs", match.params.job);
    // if (match.params.job == "saved") {
    //   this.props;
    // }
    return tabs.find((el) => {
      return el.route === match.params.job;
    });
  };
  getTabFollow = (getIndustries) => {
    const { match } = this.props;
    const { industriesArray } = this.state;

    const tabs = getIndustries.map((el) => {
      return {
        id: el.id,
        title: el.name,
        route: el.name
          .split(/[\s/]+/)
          .join("_")
          .toLowerCase(),
      };
    });
    return tabs.find((el) => {
      return el.route === match.params.job;
    });
  };

  handleFollowIndustry = (isLocal = false) => {
    const { onFollowIndustry, getIndustries } = this.props;
    const { industriesArray } = this.state;

    console.log("industriesArray", getIndustries);
    onFollowIndustry(this.getTabFollow(getIndustries), isLocal);
    this.setState({
      isFollowModalShown: false,
    });
  };

  handleCloseFollowModal = () => {
    this.setState({
      isFollowModalShown: false,
    });
    this.props.history.push(`${jobs}/${CATEGORY_ALL.route}`);
  };

  getIndustriesList = (industriesArray) => {
    const { isAuthenticate, onIndustryUnfollow } = this.props;
    if (!_.isArray(industriesArray)) return [];
    const list = industriesArray.map((ind) => {
      return {
        id: ind.industry_id,
        title: ind.industry_name,
        withDelete: !isAuthenticate,
        route: ind.industry_name
          .split(/[\s/]+/)
          .join("_")
          .toLowerCase(),
        type: "link",
        onDelete: (item) => onIndustryUnfollow(item, true),
      };
    });
    const fullList = [CATEGORY_ALL, ...list];

    const { savedVacancies } = this.props;

    // if (_.isArray(savedVacancies) && savedVacancies.length > 0) {
    //   fullList.splice(0, 0, CATEGORY_SAVED);
    // }

    fullList.splice(0, 0, CATEGORY_SAVED);

    return fullList;
  };

  // getIndustriesListNew = (industriesArray) => {
  //   const { isAuthenticate, onIndustryUnfollow } = this.props;
  //   const newIn = [
  //     {
  //       industry_id: 62,
  //       industry_name: "Management",
  //     },
  //     {
  //       industry_id: 61,
  //       industry_name: "Computer & IT",
  //     },
  //   ];
  //   if (!_.isArray(newIn)) return [];
  //   console.log("hello from get 🙍‍♀️:", newIn);

  //   const list = newIn.map((ind) => {
  //     return {
  //       id: ind.industry_id,
  //       title: ind.industry_name,
  //       withDelete: !isAuthenticate,
  //       route: ind.industry_name
  //         .split(/[\s/]+/)
  //         .join("_")
  //         .toLowerCase(),
  //       type: "link",
  //       onDelete: (item) => onIndustryUnfollow(item, true),
  //     };
  //   });
  //   const fullList = [CATEGORY_ALL, ...list];

  //   const { savedVacancies } = this.props;

  //   if (_.isArray(savedVacancies) && savedVacancies.length > 0) {
  //     fullList.splice(0, 0, CATEGORY_SAVED);
  //   }

  //   return fullList;
  // };

  handleHeaderCategoryClick = (category) => {
    const { match, history, location } = this.props;
    // this.setState({ activeCategory: category })
    // console.log(category);
    if (category.route !== match.params.job) {
      history.push(`${jobs}/${category.route}${location.search}`);
    }
    this.props.onChangeJobTab(category);
  };

  /*
   * encoding filter params and push in url
   * */
  parseUrl = (nextFilter) => {
    const { history, match } = this.props;
    const finalObg = {};
    if (nextFilter.location) {
      finalObg.location = window.btoa(JSON.stringify(nextFilter.location));
    }
    if (nextFilter.jobTypes) {
      finalObg.jobTypes = window.btoa(JSON.stringify(nextFilter.jobTypes));
    }
    if (nextFilter.companies) {
      finalObg.companies = window.btoa(JSON.stringify(nextFilter.companies));
    }
    // if (nextFilter.distance) {
    //   finalObg.distance = window.btoa(JSON.stringify(nextFilter.distance));
    // }
    if (nextFilter.salary) {
      finalObg.salary = window.btoa(JSON.stringify(nextFilter.salary));
    }
    if (nextFilter.options) {
      finalObg.options = window.btoa(JSON.stringify(nextFilter.options));
    }
    if (nextFilter.expertises) {
      finalObg.expertises = window.btoa(JSON.stringify(nextFilter.expertises));
    }
    if (nextFilter.activeDateOption) {
      finalObg.activeDateOption = window.btoa(
        JSON.stringify(nextFilter.activeDateOption)
      );
      finalObg.startDate = window.btoa(JSON.stringify(nextFilter.startDate));
    }
    const codingUrl = queryString.stringify(finalObg);
    history.push(`${match.url}?${codingUrl}`);
  };

  handleChangeFilter = (nextFilter) => {
    const { onFilterUpdate, onUserJobsLoad } = this.props;
    this.parseUrl(nextFilter);
    this.setState({ renderCardsCounter: 12 });
    onFilterUpdate(nextFilter);
    onUserJobsLoad(nextFilter);
  };

  handleLoadCards = () => {
    const { paginLoadInfo } = this.state;
    const { jobsLoadInfo, tab } = this.props;

    if (
      !paginLoadInfo.end &&
      !paginLoadInfo.isLoading &&
      jobsLoadInfo.tab !== tab
    ) {
      this.setState((prevState) => ({
        paginLoadInfo: {
          ...prevState.paginLoadInfo,
          isLoading: true,
        },
      }));
      // -- todo реализовать пагинацию с сервера

      setTimeout(() => {
        this.setState(
          (prevState) => ({
            renderCardsCounter: prevState.renderCardsCounter + 5,
            paginLoadInfo: {
              ...prevState.paginLoadInfo,
              isLoading: false,
            },
          }),
          this.scrollbarUpdate
        );
      }, 2000);
    }
  };

  scrollbarUpdate = () => {
    const { isMobile } = this.props;
    if (!isMobile) {
      const container = document.getElementById("contentScrollContainer");
      if (container) container.scrollTop += 1;
    } else {
      const container = document.getElementById("Push");
      if (container) container.scrollTop += 1;
    }
  };

  getMeta = (tab = {}) => {
    const { meta } = this.state;
    if (_.isEmpty(meta)) {
      return { description: "", keywords: "" };
    } else {
      switch (tab.route) {
        case "all":
          return meta.find((el) => el.type === "all_jobs");
        case "saved":
          return meta.find((el) => el.type === "saved_jobs");
        default:
          return {
            ...meta.find((el) => el.type === "industry"),
            title: tab.title,
          };
      }
    }
  };

  getCards = () => {
    const { userJobs, savedVacancies, match, followedIndustries } = this.props;

    const { renderCardsCounter } = this.state;
    const currTab = this.getIndustriesList(followedIndustries).find(
      (el) => el.route === match.params.job
    );
    if (currTab && currTab.id === -1) {
      return savedVacancies.filter((item, i) => i < renderCardsCounter);
    } else if (currTab && _.isArray(userJobs[currTab.title])) {
      const cards = userJobs[currTab.title];
      return cards.filter((item, i) => i < renderCardsCounter);
    } else {
      const cards = (userJobs[CATEGORY_ALL.title] || []).filter((job) => {
        return job.industry_id === currTab.id;
      });
      return cards.filter((item, i) => i < renderCardsCounter);
    }
  };

  renderLoader = () => {
    const { paginLoadInfo } = this.state;

    if (paginLoadInfo.isLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }
  };

  render() {
    const { followedIndustries, filter, match } = this.props;
    console.log(
      "🚀 ~ file: Jobs.js ~ line 449 ~ Jobs ~ render ~ filter",
      filter,
      match
    );
    const { isFollowModalShown } = this.state;
    const categories = this.getIndustriesList(followedIndustries);
    const currTab = categories.find((el) => el.route === match.params.job);
    const cards = currTab ? this.getCards() : [];

    const meta = this.getMeta(currTab);
    return (
      <Layout
        DeleteHeaderCat={this.props.onDeleteHeaderCat}
        activeHeaderCategory={currTab}
        header={currTab ? categories : []}
        history={this.props.history}
        withHeaderNavigate={true}
        onHeaderCategoryClick={this.handleHeaderCategoryClick}
        notScroll={true}
      >
        <Helmet>
          {/* <title>{meta.title} | Mr.JOBS</title> */}
          <title>{meta.title || "JOBS"} | Mr.JOBS</title>
          <meta content={meta.description} name={"description"} />
          <meta content={meta.keywords} name={"keywords"} />
        </Helmet>
        <Cards
          cards={cards}
          filter={filter}
          onChangeFilter={this.handleChangeFilter}
          onLoadCards={this.handleLoadCards}
          meta={meta}
          {...this.props}
        />
        {this.renderLoader()}
        <Modal
          containerStyles={{ width: 400, margin: 15 }}
          isOpen={isFollowModalShown}
          onClose={this.handleCloseFollowModal}
        >
          <React.Fragment>
            <ModalHeader>
              <span>
                Do you want follow {this.getTab() && this.getTab().title} ?
              </span>
            </ModalHeader>
            <ButtonContainer>
              <ModalButton onClick={() => this.handleFollowIndustry(false)}>
                <span>Follow</span>
              </ModalButton>
            </ButtonContainer>
          </React.Fragment>
        </Modal>
      </Layout>
    );
  }
}

const mapSizeToProps = ({ width }) => ({ isMobile: width < 500 });

export default withSizes(mapSizeToProps)(Jobs);
