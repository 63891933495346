import { getFilterPosition } from './getFilterPosition'

let filterIdGlobal;

export const calcPosition = () => {
  getFilterPosition(filterIdGlobal)
}
/*
* set filter position
* calc filter position when scroll
* */
export const mountFilter = filterId => {
 
  filterIdGlobal= filterId

  calcPosition()
  const desktopYScrollContainer = document.getElementById(
    'contentScrollContainer'
  )
  const mobileYScrollContainer = document.getElementById('Push')
  const scrollXContainer = document.getElementsByClassName(
    'filtersContainer'
  )[0]
  scrollXContainer.addEventListener('ps-scroll-x', calcPosition)
  if (desktopYScrollContainer) {
    desktopYScrollContainer.addEventListener('ps-scroll-y', calcPosition)
  }
  if (mobileYScrollContainer) {
    mobileYScrollContainer.addEventListener('ps-scroll-y', calcPosition)
  }
}
