import { createReducer } from 'Helpers/redux'

import { LOAD } from 'Redux/actions/industries'
import { LOAD_INDUSTRIES_TAG } from 'Redux/actions/metaTags'

const initialState = {
  industries: [],
  industriesMetaTags: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const handlers = {
  [LOAD.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: payload.data
      }
    }
  },
  [LOAD.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      industries: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  },
  [LOAD_INDUSTRIES_TAG.REQUEST]: state => {
    return {
      ...state
    }
  },
  [LOAD_INDUSTRIES_TAG.FAILURE]: (state, { payload: { data } }) => {
    return {
      ...state
    }
  },
  [LOAD_INDUSTRIES_TAG.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      industriesMetaTags: data
    }
  }
}

export default createReducer(initialState, handlers)
