export const {
  NODE_ENV,
  REACT_APP_SERVER_URL,
  REACT_APP_POST_JOB_LIST,
} = process.env;

export const appearance = {
  theme: "main",
};
console.log(NODE_ENV);
export const mrJobsServerUrl = REACT_APP_SERVER_URL;

export const api = {
  url: mrJobsServerUrl,
};
export const chatApi = {
  url: mrJobsServerUrl,
};

export const imagesDinamicDir = {
  url: `${api.url}/images`,
};

export const postJobLink = {
  url: REACT_APP_POST_JOB_LIST,
};
