import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import { Element } from 'react-scroll'

import {
  CardImage,
  CardTitle,
  CardTimeComponent,
  CardSubTitle
} from '../../blocks'

import { Container, CardContent, CardDescription } from './styled'

class ActivitiesCard extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    endDate: PropTypes.string,
    faIcon: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    startDate: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onModalOpen: PropTypes.func.isRequired
  }

  render() {
    const {
      title,
      subTitle,
      id,
      startDate,
      endDate,
      description,
      type,
      faIcon,
      image
    } = this.props

    const link = `${type}${id}`

    return (
      <Element name={link}>
        <Container>
          <CardImage faIcon={faIcon} />
          <CardContent>
            <CardTimeComponent endDate={endDate} startDate={startDate} />
            <CardTitle text={title} />
            <CardSubTitle text={subTitle} />
            <CardDescription>{ReactHtmlParser(description)}</CardDescription>
          </CardContent>
        </Container>
      </Element>
    )
  }
}

export { ActivitiesCard }
