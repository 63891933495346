import { Emoji, emojiIndex } from 'emoji-mart'

const REGEX = /:([a-zA-Z0-9_\-\+]+):/g //eslint-disable-line

const formatEmoji = emoji => {
  const getEmoji = Emoji({
    html: true,
    emoji: emoji,
    // native: true, // change to set property to get another set of emoji
    size: 24
  })

  return `<span
    dangerouslySetInnerHTML={{
      __html: ${getEmoji}`
}

const emojify = message => {
  const messageWithEmoji = message
    .split(REGEX)
    .map((str, index) => {
      if (index % 2 === 0) return str

      if (emojiIndex.search(str).length > 0) return formatEmoji(str)
      else return str
    })
    .join('')

  return messageWithEmoji
}

export default emojify
