import { createSelector } from 'reselect'

const publicUserData = store => store.publicUser

export const getUser = createSelector(
  publicUserData,
  data => data.user
)
export const getUserEducation = createSelector(
  publicUserData,
  data => data.education
)
export const getUserExperience = createSelector(
  publicUserData,
  data => data.experience
)
export const getFollowedIndustries = createSelector(
  publicUserData,
  data => data.followedIndustries
)
export const getPersonalities = createSelector(
  publicUserData,
  data => data.personalities
)
export const getExpectations = createSelector(
  publicUserData,
  data => data.expectations
)
export const getUserAge = createSelector(
  getUser,
  data => data.age
)
export const getUserInfo = createSelector(
  getUser,
  data => data.info
)
export const getUserHighSchoolEd = createSelector(
  publicUserData,
  data => data.highSchoolEd
)
export const getUserAcademicQualifications = createSelector(
  publicUserData,
  data => data.academicQualifications
)
export const getUserProfQualf = createSelector(
  publicUserData,
  data => data.profQualf
)
export const getUserExpertises = createSelector(
  publicUserData,
  data => data.expertises
)
export const getUserSubExpertises = createSelector(
  publicUserData,
  data => data.subExpertises
)
export const getUserJobTypes = createSelector(
  publicUserData,
  data => data.jobTypes
)
export const getUserCompanies = createSelector(
  publicUserData,
  data => data.companies
)
export const getFetchUserLoadInfo = createSelector(
  publicUserData,
  data => data.fetchUserLoadInfo
)
export const getUserLanguages = createSelector(
  publicUserData,
  data => data.languages
)
export const getUserFeedbacks = createSelector(
  publicUserData,
  data => data.feedbacks
)
export const getUserActivities = createSelector(
  publicUserData,
  data => data.activities
)
