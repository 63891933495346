import styled from "styled-components";
import * as colors from "Theme/colors";

const uploadContainer = { width: 150, height: 50 };

export const Content = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const ContentMob = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90vh;
`;
export const ItemText = styled.div`
  color: grey;
  font-size: 11px;
  text-align: center;
  margin: 11px 0;
  max-width: 60px;
`;
export const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0 8px;
  position: fixed;
  bottom: 5%;
  width: 87vw;
`;
export const BottomBlockBig = styled.div`
 display: flex;
  justify-content: space-between;
  margin: 10px 0 8px;
`;
export const CvContainer = styled.div`
  color: purple;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const CvIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  cursor: pointer;
  width: ${uploadContainer.width + "px"};
  height: ${uploadContainer.height + "px"};
  border: 1px solid grey;
  border-radius: 1rem;
`;
export const UploadedPath = styled.div`
  color: ${(props) => (props.error ? colors.notificationRed : colors.mainBlue)};
  margin: 14px 8px 24px;
  height: 21px;
`;
export const FileInput = styled.input`
  opacity: 0;
  width: ${uploadContainer.width + "px"};
  height: ${uploadContainer.height + "px"};
`;
export const SpinerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
`;
export const CvBlock = styled.div`
  @media screen and (max-width: 500px) {
    opacity: 0;
  }
`;
