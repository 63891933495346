export const inSocketTypes = {
  disconnect: 'disconnect',
  reconnect: 'reconnect',
  // send message types
  sendMessageSuccess: 'send_message.SUCCESS',
  sendMessageFailure: 'send_message.FAILURE',
  // change message status
  changeMessageStatusSuccess: 'change_message_status.SUCCESS',
  changeMessageStatusFailure: 'change_message_status.FAILURE',

  receiveMessage: 'receive_message',
  roomConnectSuccess: 'room_connect_success',
  userJoinRoom: 'user_join_room',
  userLeaveRoom: 'user_leave_room',
  getUsersOnline: 'get_users_online',
  updateUsersOnlineList: 'user_online_list_update',
  notification: 'notification',
  sendMessageError: 'send_message_error',
  recieveChatsData: 'recieve_chats_data',
  recieveMessages: 'revieve_messages',
  newChat: 'new_chat'
}

export const outSocketTypes = {
  sendMessage: 'send_message',
  roomConnect: 'connect_room',
  leaveRoom: 'leave_room',
  getMessages: 'get_messages',
  changeMessageStatus: 'change_message_status',
  createChat: 'create_chat'
}

export const notificationType = 'socket/NOTIFICATION'

export default {
  inSocketTypes,
  outSocketTypes
}
