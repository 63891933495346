import React from 'react'
import { LoaderItem, LoaderContainer } from './styled'
import ContentLoader from 'react-content-loader'

export const FiltersLoader = () => {
  const renderItems = length => {
    const result = []
    for (let i = 0; i < length; i++) {
      result.push(
        <LoaderItem key={i} title="loading...">
          <ContentLoader
            height={303}
            width={314}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="0" rx="5" ry="5" width="314" height="130" />
            <rect x="230" y="112" rx="100" ry="100" width="35" height="35" />
            <rect x="270" y="112" rx="100" ry="100" width="35" height="35" />
            <rect x="5" y="140" rx="5" ry="5" width="190" height="25" />
            <rect x="5" y="180" rx="5" ry="5" width="80" height="13" />
            <rect x="200" y="180" rx="5" ry="5" width="110" height="13" />
            <rect x="25" y="210" rx="100" ry="100" width="50" height="50" />
            <rect x="95" y="210" rx="10" ry="10" width="50" height="50" />
            <rect x="165" y="210" rx="10" ry="10" width="50" height="50" />
            <rect x="235" y="210" rx="10" ry="10" width="50" height="50" />
            <rect x="25" y="280" rx="5" ry="5" width="50" height="10" />
            <rect x="95" y="280" rx="5" ry="5" width="50" height="10" />
            <rect x="165" y="280" rx="5" ry="5" width="50" height="10" />
            <rect x="235" y="280" rx="5" ry="5" width="50" height="10" />
          </ContentLoader>
        </LoaderItem>
      )
    }
    return result
  }
  return <LoaderContainer>{renderItems(6)}</LoaderContainer>
}
