import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

import { UserEditInfoModal } from '../../../../../../Blocks'

import { InterestsModalContainer, TextEditor } from './styled'

class InterestsModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func
  }

  constructor(props) {
    super()

    const value = props.value
      ? RichTextEditor.createValueFromString(props.value, 'html')
      : RichTextEditor.createEmptyValue()

    this.state = {
      value
    }
  }

  handleSaveClick = () => {
    const { onSave } = this.props
    const { value } = this.state

    onSave(value.toString('html'))
  }

  handleChange = value => this.setState({ value })

  render() {
    const { onClose } = this.props
    const { value } = this.state
    return (
      <UserEditInfoModal
        title={'Add My Interests'}
        width={600}
        onCloseModal={onClose}
        onSaveClick={this.handleSaveClick}
      >
        <InterestsModalContainer>
          <TextEditor value={value} onChange={this.handleChange} />
        </InterestsModalContainer>
      </UserEditInfoModal>
    )
  }
}

export { InterestsModal }
