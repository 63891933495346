import React, { Component } from "react";

import "react-input-range/lib/css/index.css";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import { Tooltip } from "antd";

import _ from "lodash";
import map from "lodash/map";
import moment from "moment";
import { getCurrentPosition } from "Helpers/geo";
import { getCoordsByLatLon } from "../../../Api/googlePlaces";

import { createLink } from "Helpers/links";
import { getCompanyBackground } from "Helpers/companyBackground";
import modalRoutes from "Constants/modal_routes";
import { detail, initial, jobs, redirectPage } from "../../../Constants/routes";
import { CATEGORY_ALL, CATEGORY_SAVED } from "../../../Constants/jobTabs";
import { distanceValue } from "../../../Constants/distanceValue";

import DropdownLocation from "../DropdownFilters/DropdownLocation";
import DropdownDistance from "../DropdownFilters/DropdownDistance";
import DropdownJobType from "../DropdownFilters/DropdownJobtype";
import DropdownCompanies from "../DropdownFilters/DropdownCompanies";
import DropdownSalary from "../DropdownFilters/DropdownSalary";
import DropdownOptions from "../DropdownFilters/DropdownOptions";
import DropdownExpertises from "../DropdownFilters/DropdownExpertises";
import DropdownDate from "../DropdownFilters/DropdownDate";
import { FilterPreview, FiltersLoader, ShareModal } from "../../Blocks";
import { getIndustryImageLink } from "Helpers/getImageLink";

import location from "../../../images/location.png";
import type from "../../../images/type.png";
import folder from "../../../images/type.png";
import optionsImg from "../../../images/options.png";
import * as filterIcons from "../../../Assets/images/Filters";
import nodata from "images/header/mrJobs_noData.png";

import topImg from "../../../images/OppDoor/finger.png";
import { tz } from "moment-timezone";

import {
  MainContainer,
  SocialMediaContainer,
  SocialMediaIcons,
  SocialIcon,
  DescriptionContainer,
  Title,
  Company,
  IconsContainer,
  Icon,
  Container,
  CardImage,
  Filter,
  FilterTextOverflow,
  FiltContainer,
  FiltImg,
  IconText,
  IconImage,
  percentStyles,
  StartYourSearchWrapper,
  StartYourSearchIcon,
  StartYourSearchText,
  CardContainer,
  CardBlock,
  FilterResetText,
  DropdownIcon,
  SubInfo,
  CreateDate,
  FiltersIcon,
  SocialIconItem,
  TimeIcon,
  ShareBtn,
  ShareBtnContainer,
  LittleLogo,
  MatchRateContainer,
} from "./style";

import "./style.css";

import POSTED_OPTIONS from "./postedOptions";

class Cards extends Component {
  constructor(props) {
    super(props);

    const {
      jobTypes,
      jobTypesLoadInfo,
      companies,
      companiesLoadInfo,
      options,
      optionsLoadInfo,
      expertises,
      expertisesLoadInfo,
      filter,
      salaryRange,
    } = props;

    const state = {
      isAuthenticate: false,
      jobTypes: [],
      companies: [],
      options: [],
      locations: null,
      expertises: [],
      initModalWarning: false,
      openedFilterId: null,
      distanceFromLocation: 3,
      salary: {
        range: null,
        acceptedRange: null,
        negotiable: false,
        withoutRange: false,
        byMin: false, 
        byMax: false,
      },
      activeDateOption: false,
      isCalendarOpen: false,
      dateSince: new Date(),
      isDateSincePrint: false,
      companyNameFilter: "",
      expertiseNameFilter: "",
      optionNameFilter: "",
      isLocationsLoading: false,
      filterLoadInfo: {
        isLoading: true,
        isLoaded: false,
      },
      filterUnmount: false,
      shareModalOpenId: false,
      disabledOutsideFilterClick: false,
      firstLoadCards: false,
      disableLocation: true, //location
      reset: false,
    };
    if (
      salaryRange &&
      jobTypesLoadInfo.isLoaded &&
      companiesLoadInfo.isLoaded &&
      optionsLoadInfo.isLoaded &&
      expertisesLoadInfo.isLoaded
    ) {
      state.filterLoadInfo.isLoaded = true;
      state.filterLoadInfo.isLoading = false;
    } else {
      state.filterLoadInfo.isLoaded = false;
      state.filterLoadInfo.isLoading = true;
    }
    if (filter.location) {
      state.locations = filter.location.locations;
    }
    if (filter.distance && filter.distance.kms) {
      const distanceFromLocationKey = _.findKey(
        distanceValue,
        (e) => e === filter.distance.kms
      );
      state.distanceFromLocation = distanceFromLocationKey;
    }
    if (filter.salary) {
      state.salary = filter.salary;
    } else if (salaryRange) {
      state.salary.range = salaryRange;
      state.acceptedRange = salaryRange;
    }

    if (jobTypesLoadInfo.isLoaded) {
      state.jobTypes = jobTypes.map((type) => {
        let active = false;
        if (filter.jobTypes) {
          active = filter.jobTypes.indexOf(type.id) > -1;
        }

        return { ...type, text: type.name, active };
      });
    }
    if (companiesLoadInfo.isLoaded) {
      state.companies = companies.map((company) => {
        let active = false;
        if (filter.companies) {
          active = filter.companies.indexOf(company.id) > -1;
        }

        return { ...company, text: company.name, active };
      });
    }
    if (optionsLoadInfo.isLoaded) {
      state.options = options.map((option) => {
        let active = false;

        if (filter.options) {
          active = filter.options.indexOf(option.id) > -1;
        }

        return { ...option, text: option.name, active };
      });
    }
    if (expertisesLoadInfo.isLoaded) {
      state.expertises = expertises.map((expertise) => {
        let active = false;

        if (filter.expertises) {
          active = filter.expertises.indexOf(expertise.id) > -1;
        }

        return { ...expertise, text: expertise.name, active };
      });
    }

    if (filter.activeDateOption) {
      state.activeDateOption = filter.activeDateOption;
      state.isCalendarOpen = filter.activeDateOption === "since";
      state.dateSince =
        filter.activeDateOption === "since" ? filter.startDate : new Date();
    }

    this.state = state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    const {
      isAuthenticate,
      locations,
      jobTypes,
      jobTypesLoadInfo,
      companies,
      companiesLoadInfo,
      optionsLoadInfo,
      options,
      expertises,
      expertisesLoadInfo,
      filter,
      salaryRange,
    } = nextProps;
    if (filter.location && filter.location.locations) {
      nextState.locations = filter.location.locations;
    }
    if (
      salaryRange &&
      jobTypesLoadInfo.isLoaded &&
      companiesLoadInfo.isLoaded &&
      optionsLoadInfo.isLoaded &&
      expertisesLoadInfo.isLoaded
    ) {
      nextState.filterLoadInfo.isLoaded = true;
      nextState.filterLoadInfo.isLoading = false;
    } else {
      nextState.filterLoadInfo.isLoaded = false;
      nextState.filterLoadInfo.isLoading = true;
    }

    if (salaryRange && !nextState.salary.acceptedRange) {
      nextState.salary.range = salaryRange;
      nextState.salary.acceptedRange = salaryRange;
    }

    if (
      jobTypesLoadInfo.isLoaded &&
      jobTypes.length !== prevState.jobTypes.length
    ) {
      nextState.jobTypes = jobTypes.map((type) => {
        let active = false;

        if (filter.jobTypes) {
          active = filter.jobTypes.indexOf(type.id) > -1;
        }

        return { ...type, text: type.name, active };
      });
    }
    if (
      companiesLoadInfo.isLoaded &&
      companies.length !== prevState.companies.length
    ) {
      nextState.companies = companies.map((company) => {
        let active = false;

        if (filter.companies) {
          active = filter.companies.indexOf(type.id) > -1;
        }

        return { ...company, text: company.name, active };
      });
    }
    if (
      optionsLoadInfo.isLoaded &&
      options.length !== prevState.options.length
    ) {
      nextState.options = options.map((option) => {
        let active = false;

        if (filter.options) {
          active = filter.options.indexOf(option.id) > -1;
        }

        return { ...option, text: option.name, active };
      });
    }

    if (
      expertisesLoadInfo.isLoaded &&
      expertises.length !== prevState.expertises.length
    ) {
      nextState.expertises = expertises.map((expertise) => {
        let active = false;

        if (filter.expertises) {
          active = filter.expertises.indexOf(expertise.id) > -1;
        }

        return { ...expertise, text: expertise.name, active };
      });
    }

    nextState.isAuthenticate = isAuthenticate;

    return nextState;
  }

  componentDidMount() {
    const {
      onChangeFilter,
      jobsLoadInfo,
      savedVacancies,
      history,
    } = this.props;
    moment.tz.setDefault("Asia/Colombo");
    if (savedVacancies.length < 1) {
      // setTimeout(() => {
      //   history.push(`${jobs}/all`);
      // }, 1000);
    }
    onChangeFilter({});
    if (jobsLoadInfo.error) {
      swal({
        icon: "error",
        text: "Network error!",
        button: true,
      });
    } else {
      this.setState({
        jobTypes: [],
        companies: [],
        options: [],
        expertises: [],
        salary: {
          range: this.props.salaryRange,
          acceptedRange: this.props.salaryRange,
          negotiable: false,
          withoutRange: false,
        },
        distanceFromLocation: 3,
        dateSince: new Date(),
        companyNameFilter: "",
        expertiseNameFilter: "",
        optionNameFilter: "",
        activeDateOption: false,
        isDateSincePrint: false,
        isCalendarOpen: false,
        locations: null,
        reset: false,
      });
    }
    const desktopContainer = document.getElementById("contentScrollContainer");
    const mobileContainer = document.getElementById("Push");
    if (desktopContainer) {
      desktopContainer.addEventListener(
        "ps-y-reach-end",
        this.props.onLoadCards
      );
    }
    if (mobileContainer) {
      mobileContainer.addEventListener(
        "ps-y-reach-end",
        this.props.onLoadCards
      );
    }
  }

  componentWillUnmount() {
    const desktopContainer = document.getElementById("contentScrollContainer");
    const mobileContainer = document.getElementById("Push");
    if (desktopContainer) {
      desktopContainer.removeEventListener(
        "ps-y-reach-end",
        this.props.onLoadCards
      );
    }
    if (mobileContainer) {
      mobileContainer.removeEventListener(
        "ps-y-reach-end",
        this.props.onLoadCards
      );
    }
  }

  componentDidCatch(error, info) {
    return <Redirect to={initial} />;
  }

  handleCloseFilter = () => {
    this.setState({ openedFilterId: null });
  };

  toggleOutsideFilterClickDisabled = () => {
    this.setState((prevState) => ({
      disabledOutsideFilterClick: !prevState.disabledOutsideFilterClick,
    }));
  };

  filterComapnies = (companiesArray, filter) => {
    if (!_.isString(filter)) return companiesArray;

    const filterTrimmed = _.trim(filter);

    if (filterTrimmed.length === 0) return companiesArray;

    const loweredCaseFilter = filterTrimmed.toLowerCase();

    return companiesArray.filter((company) => {
      const { name } = company;
      if (!_.isString(name)) return false;

      return name.toLowerCase().includes(loweredCaseFilter);
    });
  };

  filterOptions = (optionsArray, filter) => {
    if (!_.isString(filter)) return optionsArray;

    const filterTrimmed = _.trim(filter);

    if (filterTrimmed.length === 0) return optionsArray;

    const loweredCaseFilter = filterTrimmed.toLowerCase();
    return optionsArray.filter((option) => {
      const { name } = option;
      if (!_.isString(name)) return false;

      return name.toLowerCase().includes(loweredCaseFilter);
    });
  };

  filterExptertises = (exptertisesArray, filter) => {
    if (!_.isString(filter)) return exptertisesArray;

    const filterTrimmed = _.trim(filter);

    if (filterTrimmed.length === 0) return exptertisesArray;

    const loweredCaseFilter = filterTrimmed.toLowerCase();

    return exptertisesArray.filter((exptertise) => {
      const { name } = exptertise;

      if (!_.isString(name)) return false;

      return name.toLowerCase().includes(loweredCaseFilter);
    });
  };

  checkFilterItems = (filterId) => {
    const { filter, user, getIpLocation } = this.props;
    const nextState = {};

    switch (filterId) {
      case "Location":
        if (
          filter.location &&
          filter.location.locations &&
          filter.location.locations.length
        ) {
          const distanceFromLocationKey = _.findKey(
            distanceValue,
            (e) => e === filter.distance.kms
          );
          const filterDistanceValue =
            distanceFromLocationKey === "disable" ? 3 : distanceFromLocationKey;
          this.setState({
            locations: filter.location.locations,
            openedFilterId: "Location",
            distanceFromLocation: filterDistanceValue,
            disableLocation: distanceFromLocationKey === "disable",
          });
        } else if (filter.distance) {
          const distanceFromLocationKey = _.findKey(
            distanceValue,
            (e) => e === filter.distance.kms
          );
          const filterDistanceValue =
            distanceFromLocationKey === "disable" ? 3 : distanceFromLocationKey;
          this.setState({
            openedFilterId: "Location",
            locations: [],
            distanceFromLocation: filterDistanceValue,
            disableLocation: distanceFromLocationKey === "disable",
          });
        } else if (
          !(
            filter.location &&
            filter.location.locations &&
            filter.location.locations.length
          ) &&
          !filter.distance &&
          user.id
        ) {
          this.setState({
            locations: [
              {
                lat: user.lat,
                lng: user.lon,
                name: user.location,
              },
            ],
            openedFilterId: "Location",
          });
        } else {
          this.setState({
            locations: [],
            openedFilterId: "Location",
          });
          // getCurrentPosition()
          //   .then((userLocation) => {
          //     if (userLocation) {
          //       getCoordsByLatLon(userLocation.lat, userLocation.lng).then(
          //         (city) => {
          //           this.setState({
          //             locations: [city],
          //             openedFilterId: "Location",
          //           });
          //         }
          //       );
          //     }
          //   })
          //   .catch((err) => {
          //     if (!user.id && getIpLocation) {
          //       getCoordsByLatLon(getIpLocation.lat, getIpLocation.lng).then(
          //         (city) => {
          //           this.setState({
          //             locations: [city],
          //             openedFilterId: "Location",
          //           });
          //         }
          //       );
          //     } else {
          //       this.setState({
          //         locations: [],
          //         disableLocation: true,
          //         openedFilterId: "Location",
          //       });
          //     }
          // });
        }
        break;
      case "Distance":
        nextState.distanceFromLocation = 20;
        if (filter.distance) {
          if (filter.distance.kms) {
            nextState.distanceFromLocation = filter.distance.kms;
          }
        }
        this.setState({ distanceFromLocation: nextState.distanceFromLocation });
        break;
      case "JobType":
        const { jobTypes } = this.state;
        nextState.jobTypes = [];
        if (filter.jobTypes) {
          nextState.jobTypes = jobTypes.map((item) => {
            if (filter.jobTypes.indexOf(item.id) > -1) {
              return {
                ...item,
                active: true,
              };
            } else {
              return {
                ...item,
                active: false,
              };
            }
          });
        } else {
          nextState.jobTypes = jobTypes.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.setState({ jobTypes: nextState.jobTypes });
        break;
      case "Companies":
        const { companies } = this.state;
        nextState.companies = [];
        if (filter.companies) {
          nextState.companies = companies.map((item) => {
            if (filter.companies.indexOf(item.id) > -1) {
              return {
                ...item,
                active: true,
              };
            } else {
              return {
                ...item,
                active: false,
              };
            }
          });
        } else {
          nextState.companies = companies.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.setState({ companies: nextState.companies });
        break;
      case "Options":
        const { options } = this.state;
        nextState.options = [];
        if (filter.options) {
          nextState.options = options.map((item) => {
            if (filter.options.indexOf(item.id) > -1) {
              return {
                ...item,
                active: true,
              };
            } else {
              return {
                ...item,
                active: false,
              };
            }
          });
        } else {
          nextState.options = options.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.setState({ options: nextState.options });
        break;
      case "Expertises":
        const { expertises } = this.state;
        nextState.expertises = [];
        if (filter.expertises) {
          nextState.expertises = expertises.map((item) => {
            if (filter.expertises.indexOf(item.id) > -1) {
              return {
                ...item,
                active: true,
              };
            } else {
              return {
                ...item,
                active: false,
              };
            }
          });
        } else {
          nextState.expertises = expertises.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.setState({ expertises: nextState.expertises });
        break;
      case "Salary":
        if (filter.salary) {
          this.setState({ salary: filter.salary });
        } else {
          this.setState({
            salary: {
              range: this.props.salaryRange,
              acceptedRange: this.props.salaryRange,
              negotiable: false,
              withoutRange: false,
            },
          });
        }
        break;
      case "Date":
        nextState.activeDateOption = false;
        nextState.isCalendarOpen = false;
        nextState.isDateSincePrint = false;
        nextState.dateSince = new Date();

        if (filter.activeDateOption) {
          nextState.activeDateOption = filter.activeDateOption;
          if (filter.activeDateOption === "since") {
            nextState.isCalendarOpen = true;
            nextState.dateSince = filter.startDate;
            nextState.isDateSincePrint = true;
          }
        }
        this.setState({
          activeDateOption: nextState.activeDateOption,
          isCalendarOpen: nextState.isCalendarOpen,
          dateSince: nextState.dateSince,
          isDateSincePrint: nextState.isDateSincePrint,
        });
        break;
      default:
        console.error("invalid filter id");
    }
  };

  handleFilterClick(id) {
    const { openedFilterId } = this.state;
    if (openedFilterId === id) {
      this.handleCloseFilter();
      return false;
    } else if (openedFilterId !== null) {
      this.handleCloseFilter();
    } else {
      this.checkFilterItems(id);
      this.setState({ openedFilterId: id });
    }
  }

  handleCardClick = (card) => {
    const { history, onSetActiveVacancy } = this.props;

    if (card.type === "post") {
      onSetActiveVacancy(card);
      // console.log(
      //   "`${detail}/${card.associated_link}`>>>",
      //   `${detail}/${card.associated_link}`
      // );
      // history.push(`${detail}/${card.associated_link}`)
      window.open(`${detail}/${card.associated_link}`, "_blank");
    }
    if (card.type === "link") {
      const redirectData = {
        name: card.name,
        image: card.image,
        id: card.id,
        link: card.link,
      };
      sessionStorage.setItem("redirect_data", JSON.stringify(redirectData));
      window.open(`${redirectPage}/${card.associated_link}`, "_blank");
    }
  };

  handleSaveVacancy = (event, vacancy) => {
    event.stopPropagation();
    this.props.onSaveVacancy(vacancy.id, vacancy.type);
  };

  handleDeleteFromSaveVacancy = (event, vacancy) => {
    event.stopPropagation();
    this.props.onDeteFromSavedVacancy(vacancy.id);
    const { savedVacancies, history } = this.props;
    console.log(savedVacancies.length);
    if (savedVacancies.length === 1) {
      // console.log(savedVacancies.length);
      history.push(`${jobs}/all`);
    }
  };

  handleJobTypeFilterItemClick = (item, index) => {
    const { jobTypes } = this.state;

    const nextJobTypes = jobTypes.map((opt) => {
      return opt.id === item.id
        ? {
            ...item,
            active: !item.active,
          }
        : opt;
    });

    this.setState({ jobTypes: nextJobTypes });
  };

  handleCompaniesFilterItemClick = (item, index) => {
    const { companies } = this.state;

    const nextCompanies = companies.map((opt) => {
      return opt.id === item.id
        ? {
            ...item,
            active: !item.active,
          }
        : opt;
    });

    this.setState({ companies: nextCompanies });
  };

  handleJobTypesFilterOkBtnClick = () => {
    const { jobTypes } = this.state;
    const { filter, onChangeFilter } = this.props;

    const filterArray = [];

    jobTypes.forEach((type) => {
      if (type.active) filterArray.push(type.id);
    });

    const nextFilter = { ...filter, jobTypes: filterArray };

    onChangeFilter(nextFilter);

    this.handleCloseFilter();
  };

  handleCompaniesFilterOkBtnClick = (event) => {
    const { companies } = this.state;
    const { filter, onChangeFilter } = this.props;

    const filterArray = [];

    companies.forEach((company) => {
      if (company.active) filterArray.push(company.id);
    });

    const nextFilter = { ...filter, companies: filterArray };
    onChangeFilter(nextFilter);

    const createCategoryName = this.state.companies.filter((opt) => {
      return opt.active;
    });
    if (!createCategoryName.length) {
      this.setState({ companiesFilterTitle: "" });
    } else if (createCategoryName.length === 1) {
      this.setState({ companiesFilterTitle: createCategoryName[0].text });
    } else {
      const newFilterName = `(${createCategoryName.length}) Companies`;
      this.setState({ companiesFilterTitle: newFilterName });
    }

    this.handleCloseFilter();
  };

  handleCompanyNameFilterTextChange = ({ target }) => {
    this.setState({ companyNameFilter: target.value });
  };

  handleDistanceIncrementBtnClick = () => {
    const { distanceFromLocation, disableLocation } = this.state;
    const length = Object.keys(distanceValue).length - 1;
    if (distanceFromLocation >= length || disableLocation) return;
    this.setState({
      distanceFromLocation: +this.state.distanceFromLocation + 1,
    });
  };

  handleDistanceDecrementBtnClick = () => {
    const { distanceFromLocation, disableLocation } = this.state;
    if (distanceFromLocation === 1 || disableLocation) return;
    this.setState({
      distanceFromLocation: +this.state.distanceFromLocation - 1,
    });
  };

  handleSalaryRangeChange = (range) => {
    const { salary } = this.state;

    //// console.log(range)

    this.setState({
      salary: {
        ...salary,
        range,
      },
    });
  };

  handleSalaryOptionClick = (event, option) => {
    const { salary } = this.state;

    this.setState({
      salary: {
        ...salary,
        [option]: !salary[option],
      },
    });
  };

  handleSalaryFilterOkBtnClick = () => {
    const { salary } = this.state;
    const { filter, onChangeFilter } = this.props;
    console.log("sal sal", salary);
    console.log("sal filterfilter", filter);
  

    if (salary.withoutRange) {
      // delete salary.range;
      salary.range = { min: 0, max: 927000 };
      // console.log("widthout range", salary);
      const nextFilter = { ...filter, salary };

      onChangeFilter(nextFilter);

      this.handleCloseFilter();
    }
    if (salary.byMin) {
      // delete salary.range;
      salary.range = { min:salary.range.min , max: 1927000 };
      // console.log("widthout range", salary);
      const nextFilter = { ...filter, salary };

      onChangeFilter(nextFilter);

      this.handleCloseFilter();
    }
    if (salary.byMax) {
      // delete salary.range;
      salary.range = { max:salary.range.max , min: 1 };
      // console.log("widthout range", salary);
      const nextFilter = { ...filter, salary };

      onChangeFilter(nextFilter);

      this.handleCloseFilter();
    }
    if (!salary.withoutRange) {
      const nextFilter = { ...filter, salary };

      onChangeFilter(nextFilter);

      this.handleCloseFilter();
    }
    // console.log("false");
    // const nextFilter = { ...filter, salary };

    // onChangeFilter(nextFilter);

    // this.handleCloseFilter();
  };

  handleDateOptionClick = (key) => {
    const option = POSTED_OPTIONS[key];

    this.setState({
      activeDateOption: key,
      isCalendarOpen: option.calendar,
      isDateSincePrint: key === "since",
    });
  };

  handleCalendarChange = (date) => {
    this.setState({ dateSince: date, isDateSincePrint: true });
  };

  handleDateOkBtnClick = () => {
    const { dateSince, activeDateOption } = this.state;
    const { filter, onChangeFilter } = this.props;

    const nextFilter = filter;
    const activeOption = POSTED_OPTIONS[activeDateOption];
    // console.log('activeOption.id === POSTED_OPTIONS.since.id', activeOption, POSTED_OPTIONS.since)
    if (POSTED_OPTIONS.since && activeOption.id === POSTED_OPTIONS.since.id) {
      nextFilter.startDate = dateSince;
      nextFilter.activeDateOption = activeDateOption;
    } else {
      if (activeOption.subtractConfig) {
        const startDate = moment().subtract(activeOption.subtractConfig);

        nextFilter.startDate = startDate;
        nextFilter.activeDateOption = activeDateOption;
      }
    }
    this.handleCloseFilter();
    onChangeFilter(nextFilter);
  };

  handleCitiesFilterOkBtnClick = (locations) => {
    const { distanceFromLocation, disableLocation } = this.state;
    const { filter, onChangeFilter } = this.props;
    let userLocation = null;
    // console.log('locations', locations)
    // getCurrentPosition()
    //   .then((location) => {
    //     userLocation = location;
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
    //distanceIssue
    let nextFilter;
    // if (disableLocation || !locations.length) {
    //   delete filter.location;
    //   nextFilter = {
    //     ...filter,
    //     distance: { kms: distanceValue.disable },
    //   };
    // } else {
      nextFilter = {
        ...filter,
        // distance: { kms: distanceValue[distanceFromLocation] },
        location: {
          locations: locations,
          userLocation,
        },
      // };
    }

    onChangeFilter(nextFilter);
    this.handleCloseFilter();
  };

  handleOptionsFilterItemClick = (item, index) => {
    const { options } = this.state;

    const nextOptions = options.map((opt) => {
      return opt.id === item.id
        ? {
            ...item,
            active: !item.active,
          }
        : opt;
    });

    this.setState({ options: nextOptions });
  };

  handleOptionsFilterOkBtnClick = () => {
    const { options } = this.state;
    const { filter, onChangeFilter } = this.props;

    const filterArray = [];

    options.forEach((company) => {
      if (company.active) filterArray.push(company.id);
    });

    const nextFilter = { ...filter, options: filterArray };

    onChangeFilter(nextFilter);

    const createCategoryName = this.state.options.filter((opt) => {
      return opt.active;
    });
    if (!createCategoryName.length) {
      this.setState({ optionsFilterTitle: "" });
    } else if (createCategoryName.length === 1) {
      this.setState({ optionsFilterTitle: createCategoryName[0].text });
    } else {
      const newFilterName = `(${createCategoryName.length}) Options`;
      this.setState({ optionsFilterTitle: newFilterName });
    }

    this.handleCloseFilter();
  };

  handleOptionNameFilterTextChange = ({ target }) => {
    this.setState({ optionNameFilter: target.value });
  };

  handleExperisesFilterItemClick = (item, index) => {
    const { expertises } = this.state;

    const nextOptions = expertises.map((opt) => {
      return opt.id === item.id
        ? {
            ...item,
            active: !item.active,
          }
        : opt;
    });

    this.setState({ expertises: nextOptions });
  };

  handleExperisesFilterOkBtnClick = () => {
    const { expertises } = this.state;
    const { filter, onChangeFilter } = this.props;

    const filterArray = [];

    expertises.forEach((expertise) => {
      if (expertise.active) filterArray.push(expertise.id);
    });

    const nextFilter = { ...filter, expertises: filterArray };

    onChangeFilter(nextFilter);

    const createExperisesName = this.state.expertises.filter((opt) => {
      return opt.active;
    });
    if (!createExperisesName.length) {
      this.setState({ expertisesFilterTitle: "" });
    } else if (createExperisesName.length === 1) {
      this.setState({ expertisesFilterTitle: createExperisesName[0].text });
    } else {
      const newFilterName = `(${createExperisesName.length}) Job Type`;
      this.setState({ expertisesFilterTitle: newFilterName });
    }

    this.handleCloseFilter();
  };

  handleExperisesNameFilterTextChange = ({ target }) => {
    this.setState({ expertiseNameFilter: target.value });
  };

  handleOpenShareModal = (card) => {
    this.setState({ shareModalOpenId: card.id });

    const { history, onSetActiveVacancy } = this.props;
    console.log(
      "`${detail}/${card.associated_link}`>>>",
      `${detail}/${card.associated_link}`
    );
    const urlForShare = `${detail}/${card.associated_link}`;
    sessionStorage.setItem("redirect_data", urlForShare);
  };

  handleCloseShareModal = () => this.setState({ shareModalOpenId: null });

  handeleDisableLocation = (value) => {
    this.setState({ disableLocation: value });
  };

  handleClickFilterReset = () => {
    this.setState({ reset: true });
    swal({
      icon: "warning",
      text: "Are you sure you want reset filters?",
      buttons: {
        cancel: {
          text: "Cancel",
          value: false,
          visible: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) {
        const { onChangeFilter, jobsLoadInfo } = this.props;
        onChangeFilter({});
        if (jobsLoadInfo.error) {
          swal({
            icon: "error",
            text: "Network error!",
            button: true,
          });
        } else {
          this.setState(
            {
              jobTypes: [],
              companies: [],
              options: [],
              expertises: [],
              salary: {
                range: this.props.salaryRange,
                acceptedRange: this.props.salaryRange,
                negotiable: false,
                withoutRange: false,
              },
              distanceFromLocation: 3,
              dateSince: new Date(),
              companyNameFilter: "",
              expertiseNameFilter: "",
              optionNameFilter: "",
              activeDateOption: false,
              isDateSincePrint: false,
              isCalendarOpen: false,
              locations: null,
              reset: false,
            }
            // () =>
            //   swal({
            //     icon: 'success',
            //     text: 'Success!',
            //     button: true,
            //     timer: 2000
            //   })
          );
        }
      } else {
        this.setState({ reset: false });
      }
    });
  };

  handleCheckAuth = (event, value) => {
    event && event.stopPropagation();
    const { onOpenModal, isAuthenticate } = this.props;
    if (!isAuthenticate) {
      onOpenModal(modalRoutes.login);
    } else if (value) {
      this.handleCardClick(value);
    }
  };

  renderFiltersName = (type) => {
    const { filter, user } = this.props;
    const { jobTypes, companies, options, expertises, locations } = this.state;
    switch (type) {
      case "locations":
        if (filter.location && locations.length) {
          if (filter.location.locations.length === 1) {
            return filter.location.locations[0].name;
          } else if (filter.location.locations.length > 1) {
            return `(${filter.location.locations.length})Locations`;
          }
        } 
        // else if (user.location) {
        //   return 'user.location';
        // }
        return "Location";
      case "jobType":
        if (filter.jobTypes && jobTypes.length) {
          if (filter.jobTypes.length === 1) {
            return jobTypes.find((item) => item.id === filter.jobTypes[0]).name;
          } else if (filter.jobTypes.length > 1) {
            return `(${filter.jobTypes.length})Job Types`;
            // return `(${filter.jobTypes.length})Job Types`;
          }
        }
        return "Job Type";
      case "companies":
        if (filter.companies && companies.length) {
          if (filter.companies.length === 1) {
            return companies.find((item) => item.id === filter.companies[0])
              .name;
          } else if (filter.companies.length > 1) {
            return `(${filter.companies.length})Companies`;
          }
        }
        return "Companies";
      case "options":
        if (filter.options && options.length) {
          if (filter.options.length === 1) {
            return options.find((item) => item.id === filter.options[0]).name;
          } else if (filter.options.length > 1) {
            return `(${filter.options.length})Options`;
          }
        }
        return "Options";
      case "expertises":
        if (filter.expertises && expertises.length) {
          if (filter.expertises.length === 1) {
            return expertises.find((item) => item.id === filter.expertises[0])
              .name;
          } else if (filter.expertises.length > 1) {
            return `(${filter.expertises.length})Expertises`;
          }
        }
        return "Expertises";
      case "date":
        if (filter.activeDateOption) {
          return `Past ${filter.activeDateOption}`;
        }
        // console.log('filter====>', filter)
        return "Date";
      default:
        console.error(`${type} is not valid filter name`);
    }
  };

  renderFilters() {
    const {
      jobTypes,
      companies,
      companyNameFilter,
      distanceFromLocation,
      salary,
      locations,
      activeDateOption,
      isCalendarOpen,
      dateSince,
      options,
      optionNameFilter,
      expertiseNameFilter,
      expertises,
      isDateSincePrint,
      filterLoadInfo,
      openedFilterId,
      filterUnmount,
      disableLocation,
      reset,
    } = this.state;
    console.log(
      "🚀 ~ file: Cards.js ~ line 1254 ~ Cards ~ renderFilters ~ distanceFromLocation",
      this.state
    );

    const { filter, isAuthenticate } = this.props;

    const companiesToRender = this.filterComapnies(
      companies,
      companyNameFilter
    );
    const optionsToRender = this.filterOptions(options, optionNameFilter);
    const expertisesToRender = this.filterExptertises(
      expertises,
      expertiseNameFilter
    );

    if (filterLoadInfo.isLoaded || !isAuthenticate) {
      return (
        <FiltContainer>
          {/* <FiltContainer disabled={openedFilterId}> */}
          {/* <FiltersIcon /> */}
          <Filter
            id="LocationBtn"
            smallReset
            onClick={this.handleClickFilterReset}
            reset={reset}
          >
            <FiltImg alt="reset filter" src={filterIcons.reset} />
            <FilterResetText reset={reset}>Reset</FilterResetText>
          </Filter>
          {/* <Filter
            className={openedFilterId === "Location" ? "hello" : null}
            isActive={filter.location && filter.location.locations.length}
            // active={openedFilterId === 'Location'}
            onClick={() => this.handleFilterClick("Location")}
          >
            <FiltImg alt="location filter" src={location} />
            <FilterTextOverflow
              isActive={filter.location && filter.location.locations.length}
            >
              {this.renderFiltersName("locations")}
            </FilterTextOverflow>
            <DropdownIcon
              isActive={filter.location && filter.location.locations.length}
            />
          </Filter> */}
          {openedFilterId === "Location" && this.state.locations ? (
            <DropdownLocation
              disableLocation={disableLocation}
              distanceFromLocation={distanceFromLocation}
              handeleDisableLocation={this.handeleDisableLocation}
              isUnmount={filterUnmount}
              locations={locations}
              onDistanceDecrementBtnClick={this.handleDistanceDecrementBtnClick}
              onDistanceIncrementBtnClick={this.handleDistanceIncrementBtnClick}
              onFilterClose={this.handleCloseFilter}
              onSubmit={this.handleCitiesFilterOkBtnClick}
              toggleDisableOutsideClick={this.toggleOutsideFilterClickDisabled}
            />
          ) : null}

          <Filter
            className={openedFilterId === "JobType" ? "hello" : null}
            isActive={filter.jobTypes && filter.jobTypes.length}
            // active={openedFilterId === 'JobType'}
            id="JobTypeBtn"
            onClick={() => this.handleFilterClick("JobType")}
          >
            <FiltImg alt="Job Type filter" src={type} />
            <FilterTextOverflow
              isActive={filter.jobTypes && filter.jobTypes.length}
            >
              {this.renderFiltersName("jobType")}
            </FilterTextOverflow>
            <DropdownIcon
              isActive={filter.jobTypes && filter.jobTypes.length}
            />
          </Filter>
          {openedFilterId === "JobType" ? (
            <DropdownJobType
              isUnmount={filterUnmount}
              jobTypes={jobTypes}
              onFilterClose={this.handleCloseFilter}
              onJobTypeFilterItemClick={this.handleJobTypeFilterItemClick}
              onJobTypesFilterOkBtnClick={this.handleJobTypesFilterOkBtnClick}
            />
          ) : null}

          <Filter
            className={openedFilterId === "Companies" ? "hello" : null}
            isActive={filter.companies && filter.companies.length}
            // active={openedFilterId === 'Companies'}
            id="CompaniesBtn"
            onClick={() => this.handleFilterClick("Companies")}
          >
            <FiltImg alt="Companies filter" src={filterIcons.company} />
            <FilterTextOverflow
              isActive={filter.companies && filter.companies.length}
            >
              {this.renderFiltersName("companies")}
            </FilterTextOverflow>
            <DropdownIcon
              isActive={filter.companies && filter.companies.length}
            />
          </Filter>
          {openedFilterId === "Companies" ? (
            <DropdownCompanies
              companiesToRender={companiesToRender}
              companyNameFilter={companyNameFilter}
              isUnmount={filterUnmount}
              onCompaniesFilterItemClick={this.handleCompaniesFilterItemClick}
              onCompaniesFilterOkBtnClick={this.handleCompaniesFilterOkBtnClick}
              onCompanyNameFilterTextChange={
                this.handleCompanyNameFilterTextChange
              }
              onFilterClose={this.handleCloseFilter}
            />
          ) : null}

          <Filter
            className={openedFilterId === "Salary" ? "hello" : null}
            isActive={filter.salary}
            // active={openedFilterId === 'Salary'}
            id="SalaryBtn"
            onClick={() => this.handleFilterClick("Salary")}
            small
          >
            <FiltImg alt="salary filter" src={filterIcons.salary} />
            <FilterTextOverflow isActive={filter.salary}>
              Salary
            </FilterTextOverflow>
            <DropdownIcon isActive={filter.salary} />
          </Filter>
          {openedFilterId === "Salary" ? (
            <DropdownSalary
              isUnmount={filterUnmount}
              salary={salary}
              onFilterClose={this.handleCloseFilter}
              onSalaryFilterOkBtnClick={this.handleSalaryFilterOkBtnClick}
              onSalaryOptionClick={this.handleSalaryOptionClick}
              onSalaryRangeChange={this.handleSalaryRangeChange}
            />
          ) : null}

          <Filter
            className={openedFilterId === "Options" ? "hello" : null}
            isActive={filter.options && filter.options.length}
            // active={openedFilterId === 'Options'}
            id="OptionsBtn"
            onClick={() => this.handleFilterClick("Options")}
          >
            <FiltImg alt="Options filter" src={optionsImg} />
            <FilterTextOverflow
              isActive={filter.options && filter.options.length}
            >
              {this.renderFiltersName("options")}
            </FilterTextOverflow>
            <DropdownIcon isActive={filter.options && filter.options.length} />
          </Filter>
          {openedFilterId === "Options" ? (
            <DropdownOptions
              isUnmount={filterUnmount}
              optionNameFilter={optionNameFilter}
              optionsToRender={optionsToRender}
              onFilterClose={this.handleCloseFilter}
              onOptionNameFilterTextChange={
                this.handleOptionNameFilterTextChange
              }
              onOptionsFilterItemClick={this.handleOptionsFilterItemClick}
              onOptionsFilterOkBtnClick={this.handleOptionsFilterOkBtnClick}
            />
          ) : null}

          <Filter
            className={openedFilterId === "Expertises" ? "hello" : null}
            isActive={filter.expertises && filter.expertises.length}
            // active={openedFilterId === 'Expertises'}
            id="ExpertisesBtn"
            onClick={() => this.handleFilterClick("Expertises")}
          >
            <FiltImg alt="Expertises filter" src={filterIcons.expertise} />
            <FilterTextOverflow
              isActive={filter.expertises && filter.expertises.length}
            >
              {this.renderFiltersName("expertises")}
            </FilterTextOverflow>
            <DropdownIcon
              isActive={filter.expertises && filter.expertises.length}
            />
          </Filter>
          {openedFilterId === "Expertises" ? (
            <DropdownExpertises
              expertiseNameFilter={expertiseNameFilter}
              expertisesToRender={expertisesToRender}
              isUnmount={filterUnmount}
              onExperisesFilterItemClick={this.handleExperisesFilterItemClick}
              onExperisesFilterOkBtnClick={this.handleExperisesFilterOkBtnClick}
              onExperisesNameFilterTextChange={
                this.handleExperisesNameFilterTextChange
              }
              onFilterClose={this.handleCloseFilter}
            />
          ) : null}

          <Filter
            className={openedFilterId === "Date" ? "hello" : null}
            isActive={filter.startDate}
            // active={openedFilterId === 'Date'}
            id="DateBtn"
            small
            onClick={() => this.handleFilterClick("Date")}
          >
            <FiltImg alt="Date filter" src={filterIcons.date} />
            <FilterTextOverflow isActive={filter.startDate}>
              {this.renderFiltersName("date")}
            </FilterTextOverflow>
            <DropdownIcon isActive={filter.startDate} />
          </Filter>
          {openedFilterId === "Date" ? (
            <DropdownDate
              activeDateOption={activeDateOption}
              dateSince={dateSince}
              isCalendarOpen={isCalendarOpen}
              isDateSincePrint={isDateSincePrint}
              isUnmount={filterUnmount}
              onCalendarChange={this.handleCalendarChange}
              onDateOkBtnClick={this.handleDateOkBtnClick}
              onDateOptionClick={this.handleDateOptionClick}
              onFilterClose={this.handleCloseFilter}
              POSTED_OPTIONS={POSTED_OPTIONS}
            />
          ) : null}
        </FiltContainer>
      );
    }
  }

  renderShare = (card) => {
    const { savedVacancies, onOpenModal, isAuthenticate } = this.props;
    if (_.find(savedVacancies, (vac) => vac.id === card.id) === undefined) {
      return (
        <SocialIcon
          onClick={(event) =>
            isAuthenticate
              ? this.handleSaveVacancy(event, card)
              : onOpenModal(modalRoutes.login)
          }
        >
          <SocialIconItem />
        </SocialIcon>
      );
    } else {
      return (
        <SocialIcon
          onClick={(event) =>
            isAuthenticate
              ? this.handleDeleteFromSaveVacancy(event, card)
              : onOpenModal(modalRoutes.login)
          }
        >
          <SocialIconItem active />
        </SocialIcon>
      );
    }
  };

  generateCard() {
    const { cards, jobsLoadInfo, isAuthenticate } = this.props;
    const { shareModalOpenId, firstLoadCards } = this.state;
    // console.log('cards=====>', cards);
    if (!firstLoadCards) {
      return this.setState({ firstLoadCards: true });
    }

    if (!cards.length && !jobsLoadInfo.isLoading) {
      return (
        <StartYourSearchWrapper>
          {/* <StartYourSearchIcon /> */}
          <LittleLogo alt="Mr.JOBS No data" src={nodata} />
          <StartYourSearchText>
            No results for your filter option
          </StartYourSearchText>
        </StartYourSearchWrapper>
      );
    }

    return map(cards, (value, key) => {
      const handleClick = () => this.handleCardClick(value);
      const background = getCompanyBackground(value.image);
      const isLastCard = !cards[key + 1];
      // const end_date_post = tz(value.end_date).format("YYYY-MM-DD");
      const start_date_greenwich = tz(value.start_date, "Asia/Taipei").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const end_date_greenwich = tz(value.end_date, "Asia/Colombo").format(
        "YYYY-MM-DD"
      );
      console.log(
        "🚀 ~ file: Cards.js ~ line 1554 ~ Cards ~ returnmap ~ end_date_greenwich",
        end_date_greenwich
      );
      return (
        <CardContainer key={key}>
          <Container last={isLastCard}>
            <SocialMediaContainer onClick={handleClick}>
              <CardImage alt="company logo" src={background} />
              <SocialMediaIcons onClick={(e) => e.stopPropagation()}>
                <Tooltip title={`Save `}>{this.renderShare(value)}</Tooltip>
                <ShareBtnContainer
                  onClick={() => this.handleOpenShareModal(value)}
                >
                  <Tooltip title={`Share `}>
                    <ShareBtn />
                  </Tooltip>
                  {shareModalOpenId === value.id ? (
                    <ShareModal
                      id={value.id}
                      onCloseModal={this.handleCloseShareModal}
                    />
                  ) : null}
                </ShareBtnContainer>
              </SocialMediaIcons>
            </SocialMediaContainer>
            <DescriptionContainer>
              <Title onClick={handleClick} title={value.title}>
                {value.title}
              </Title>
              <SubInfo onClick={handleClick}>
                <Company>
                  {value.type === "link"
                    ? value.link_company_name
                    : value.company_name}
                </Company>
                <CreateDate>
                  <TimeIcon />
                  closes {end_date_greenwich}
                  {/* {moment(start_date_greenwich).fromNow()} */}
                </CreateDate>
              </SubInfo>
              {/* <IconsContainer>
                {value.job_types.map((header) => (
                  <p
                    style={{
                      backgroundColor: "#5a40a2",
                      borderRadius: "30px",
                      height: "25px",
                      padding: "2px",
                      paddingInline: "8px",
                      color: "#fff",
                      fontSize: "13px"
                    }}
                  >
                    {header.name}
                  </p>
                ))}
              </IconsContainer> */}
              <IconsContainer>
                {value.type === "post" && (
                  <MatchRateContainer
                    isAuth={isAuthenticate}
                    onClick={(e) => this.handleCheckAuth(e, value)}
                  >
                    <CircularProgressbar
                      percentage={isAuthenticate ? value.matchRate || 0 : 0}
                      styles={percentStyles}
                      text={`${isAuthenticate ? value.matchRate || 0 : 0}%`}
                    />
                    {isAuthenticate ? (
                      <div
                        style={{
                          color: "#757575",
                          fontSize: "13px",
                          width: "50px",
                          textAlign: "center",
                        }}
                      >
                        Suitable To You
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "#757575",
                          fontSize: "13px",
                        }}
                      >
                        See Your
                        <br />
                        Match
                      </div>
                    )}
                  </MatchRateContainer>
                )}
                {value.type === "post" && (
                  <Icon onClick={handleClick}>
                    <IconImage alt="job type" src={folder} />
                    <IconText
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "50px",
                      }}
                    >
                      {value.job_types.length ? value.job_types[0].name : ""}
                    </IconText>
                  </Icon>
                )}
                {value.type === "post" && (
                  <Icon
                    onClick={handleClick}
                    title={
                      value.address.length
                        ? value.address
                        : value.company_address
                    }
                  >
                    <IconImage alt="location" src={location} />
                    <IconText
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "50px",
                      }}
                    >
                      {value.address.length
                        ? value.address
                        : value.company_address}
                    </IconText>
                  </Icon>
                )}
                {/* <a
                  href={
                    value.type === "link" ? value.link : value.company_website
                  }
                  target="_blank"
                > */}
                <Icon
                  link={value.type === "link"}
                  onClick={(e) => this.handleCardClick(value)}
                >
                  <IconImage
                    alt={"View detail info icon"}
                    src={getIndustryImageLink(value.industry_icon)}
                  />
                  <IconText
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "50px",
                    }}
                  >
                    {value.industry_name}
                  </IconText>
                </Icon>
                {/* </a> */}
              </IconsContainer>
            </DescriptionContainer>
          </Container>
        </CardContainer>
      );
    });
  }

  renderFiltersPreview = () => {
    const {
      filter,
      tab,
      isAuthenticate,
      meta,
      onIndustryUnfollow,
      followedIndustries,
      onFollowIndustry,
    } = this.props;
    const {
      jobTypes,
      companies,
      options,
      expertises,
      dateSince,
      filterLoadInfo,
    } = this.state;

    let locations = [];
    let activeJobTypes = [];
    let activeCompanies = [];
    let activeOptions = [];
    let activeExpertises = [];
    let distanceFromLocation = null;
    let activeSalary = false;
    let nextActiveDateOption = false;
    if (filter.location && filter.location.locations) {
      locations = filter.location.locations;
    }
    if (filter.distance && filter.distance.kms) {
      distanceFromLocation = filter.distance.kms;
    }
    if (filter.jobTypes) {
      activeJobTypes = jobTypes.filter(
        (item) => filter.jobTypes.indexOf(item.id) > -1
      );
    }
    if (filter.companies) {
      activeCompanies = companies.filter(
        (item) => filter.companies.indexOf(item.id) > -1
      );
    }
    if (filter.options) {
      activeOptions = options.filter(
        (item) => filter.options.indexOf(item.id) > -1
      );
    }
    if (filter.expertises) {
      activeExpertises = expertises.filter(
        (item) => filter.expertises.indexOf(item.id) > -1
      );
    }
    if (filter.salary) {
      activeSalary = filter.salary;
    }
    if (filter.activeDateOption) {
      nextActiveDateOption = filter.activeDateOption;
    }

    if (filterLoadInfo.isLoaded || !isAuthenticate) {
      return (
        <FilterPreview
          onFollowIndustry={onFollowIndustry}
          followedIndustries={followedIndustries}
          onIndustryUnfollow={onIndustryUnfollow}
          cities={locations}
          companies={activeCompanies}
          dateOption={nextActiveDateOption}
          dateSince={dateSince}
          distanceFromLocation={distanceFromLocation}
          expertises={activeExpertises}
          isAuthenticate={isAuthenticate}
          jobCategory={meta}
          jobTypes={activeJobTypes}
          options={activeOptions}
          salary={activeSalary}
        />
      );
    }
  };

  render() {
    const { firstLoadCards } = this.state;
    const { jobsLoadInfo, tab, user } = this.props;
    console.log(
      "🚀 ~ file: Cards.js ~ line 1792 ~ Cards ~ render ~ this.props",
      this.props
    );
    return (
      <CardBlock>
        {firstLoadCards ? (
          <React.Fragment>
            {this.renderFilters()}
            {this.renderFiltersPreview()}
          </React.Fragment>
        ) : null}
        {jobsLoadInfo.tab === tab ? (
          <FiltersLoader />
        ) : (
          <MainContainer>{this.generateCard()}</MainContainer>
        )}
      </CardBlock>
    );
  }
}

export default Cards;
