import { createReducer } from 'Helpers/redux'
import { LOG_IN, LOG_OUT, REGISTER, REHYDRATE_TOKEN } from 'Redux/actions/auth'
import { FETCH_USER } from 'Redux/actions/user'

const initialState = {
  isAuthenticate: false,
  token: null,
  isLoading: false,
  error: false,
  isLoaded: false
}

const handlers = {
  [LOG_IN.REQUEST]: (state, action) => {
    // return state.set('isLoading', true)
    //   .set('error', false)
    return Object.assign({}, state, {
      error: false,
      isLoading: true
    })
  },
  [LOG_IN.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isAuthenticate: true,
      isLoaded: true,
      isLoading: false,
      error: false,
      token: payload.data.token
    }
  },
  [LOG_IN.FAILURE]: (state, action) => {
    return {
      isAuthenticate: false,
      isLoaded: true,
      isLoading: false,
      error: true
    }
  },
  [LOG_OUT.SUCCESS]: (state, action) => {
    return initialState
  },
  [REGISTER.REQUEST]: (state, action) => {
    return Object.assign({}, state, {
      isAuthenticate: false,
      isLoaded: false,
      isLoading: true,
      error: false
    })
  },
  [REGISTER.SUCCESS]: (state, { payload }) => {
    return {
      isAuthenticate: true,
      isLoaded: true,
      isLoading: false,
      error: false,
      token: payload.data.token
    }
  },
  [REGISTER.FAILURE]: (state, action) => {
    return {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: true
    }
  },
  [REHYDRATE_TOKEN.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      token: payload.data
    }
  },
  [REHYDRATE_TOKEN.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      isLoaded: true
    }
  },
  [FETCH_USER.SUCCESS]: state => {
    return {
      ...state,
      isAuthenticate: true,
      isLoading: false,
      error: false,
      isLoaded: true
    }
  }
}

export default createReducer(initialState, handlers)
