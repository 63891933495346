import styled from 'styled-components'
import * as colors from '../../../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85px;
`
export const Icon = styled.div``
export const DefaultIcon = styled.i`
  color: ${colors.chatPurple};
`
export const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid 1px ${colors.chatPurple};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  @media screen and (max-width: 700px) {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
`
export const Text = styled.p`
  margin: 5px 0 0;
  text-align: center;
  font-size: 12px;
  line-height: 13px;
`
