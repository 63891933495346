import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'

// const selectedColor = '#583ea1'
export const MobileFontSize = '20px'

export const modalStyle = {
  content: {
    border: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0
  },
  overlay: {
    borderRadius: 0
  }
}
export const Container = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
`
export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 101, 255, 0.7);
  background: linear-gradient(
    to top,
    rgba(0, 101, 255, 0.7),
    rgba(0, 101, 255, 0.7) 35%,
    rgba(95, 12, 211, 0.7)
  );
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
  }
`
export const SearchBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
  padding-right: 10px;
  @media screen and (max-width: 900px) {
    font-size: 13px;
  }
  @media screen and (max-width: 750px) {
    font-size: 10px;
    flex-basis: 30%;
    padding: 0 60px 0 20px;
  }
  @media screen and (max-width: 500px) {
    flex-basis: initial;
  }
`
export const ResultBlock = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
  width: 100%;
  @media screen and (max-width: 750px) {
    width: 95%;
    margin: 0 auto 20px;
    flex-basis: 70%;
    padding-right: 0;
    overflow: hidden;
    flex-basis: initial;
  }
`
export const ResultList = styled(PerfectScrollbar)`
  max-height: 90%;
  width: 100%;
  height: initial !important;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-right: 10px;
  @media screen and (max-width: 750px) {
    margin: 0;
  }
  @media screen and (max-width: 500px) {
  }
`
export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
`
export const TextBoxContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.stepGrey};
  align-items: center;
  border-radius: 15px;
  height: 40px;
  margin: 0 27px;
  color: ${colors.lightGreyFont};
`
export const Icon = styled.div`
  margin: 10px;
  font-size: 20px;
`
export const OkeyButton = styled.div`
  display: flex;
  height: 100%;
  border-radius: 0 15px 15px 0;
  justify-content: center;
  align-items: center;
  background-color: ${colors.stepGreen};
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 0 7px;
`
export const SearchTextContainer = styled.div`
  color: ${colors.lightGreyFont};
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 700px) {
    font-size: ${MobileFontSize};
    width: 100%;
  }
`
export const SearchText = styled.span`
  top: 0.6em;
  font-size: 2em;
  align-self: center;
  margin: 5px;
  border-bottom: 1px solid
    ${props => (props.line ? colors.lightGreyFont : 'transparent')};
  cursor: ${props => (props.line ? 'pointer' : 'inherit')};
  white-space: nowrap;
  position: relative;
  color: white;
  @media screen and (max-width: 700px) {
    font-size: ${MobileFontSize};
    top: 0.5em;
  }
  @media screen and (max-width: 500px) {
    top: 0.4em;
  }
`
export const Button = styled.div`
  background-color: ${props => (props.disabled ? colors.stepGrey : '#43bad8')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  align-self: center;
  padding: 5px 15px 5px 5px;
  border-radius: 15px;
  margin: 15px 0 5px 0;
  cursor: pointer;
  @media screen and (max-width: 750px) {
    font-size: 1.5em;
  }
  @media screen and (max-width: 500px) {
    width: initial;
    padding: 5px 10px 5px 0;
    font-size: 1em;
  }
`
export const IconButton = styled.div`
  margin: 0 10px;
`
export const KmsInput = styled.input`
  border: none;
  border-bottom: 1px solid ${colors.lightGreyFont};
  outline: none;
  font-size: 3em;
  color: white;
  text-align: center;
  width: 50px;
  background: none;
  @media screen and (max-width: 700px) {
    font-size: ${MobileFontSize};
    width: 65px;
  }
`

export const kmsStyle = {
  width: 30,
  padding: 2,
  textAlign: 'center',
  border: 'none',
  borderBottom: `1px solid ${colors.lightGreyFont}`,
  borderRadius: 0,
  outline: 'none'
}
export const ListItem = styled.div`
  width: 100%;
  cursor: pointer;
  min-height: 50px;
  background: whitesmoke;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  &:hover {
    background: #efeeee;
  }
`
export const ResultItemTitle = styled.p`
  color: #395eff;
  font-size: 1.5em;
  margin: 0;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 50%;
  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`
export const ResultItemLocation = styled.p`
  color: #3c3c3c;
  font-size: 1.5em;
  font-weight: normal;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 50%;
  position: relative;
  padding-right: 7px;
  &::after {
    content: ')';
    display: block;
    position: absolute;
    top: 48%;
    right: -2px;
    transform: translateY(-50%);
  }
  &::before {
    content: '(';
    display: inline-block;
  }
  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`
export const ResultItemButton = styled.div`
  font-size: ${MobileFontSize};
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`
export const ResultTextContainer = styled.div`
  width: 75%;
  display: flex;
`
export const ProgressbarContainer = styled.div`
  margin-right: 10px;
  width: 48px;
  display: flex;
  align-items: center;
`
export const percentStyles = {
  root: {
    width: '80px',
    height: '80px'
  },
  path: {
    stroke: '#583ea1'
  },
  text: {
    fill: '#857575',
    fontSize: '25px'
  }
}

export const ResultListEmptyText = styled.p`
  text-align: center;
  font-size: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`
export const SearchIsEmpty = styled.i.attrs({
  className: 'fas fa-search'
})`
  font-size: 30px;
  margin-right: 5px;
  color: #fff;
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
`
export const StartSearchContainer = styled.p`
  text-align: center;
  font-size: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`
export const StartSearchIcon = styled.i.attrs({
  className: 'fas fa-search-plus'
})`
  margin-right: 5px;
  font-size: 30px;
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
`
const animation = keyframes`
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(360deg);
 }
`
export const IconButtonItemIcon = styled.i`
  ${props =>
    props.isLoading && `animation: 2s ${animation} infinite ease-in-out`};
`
