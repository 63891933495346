import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Rate from 'antd/lib/rate'
import Switch from '@material-ui/core/Switch'

import { SkillItem } from '../../../blocks'
import { withStyles } from '@material-ui/core/styles'

import {
  Container,
  FeedbackUserName,
  Content,
  Header,
  TitleBlock,
  Title,
  Position,
  Company,
  Feedback,
  FeedbackMoreButton,
  PersonalityBlock,
  PersonalityMoreButton,
  FeedbackUserNameBlock,
  IsActiveIcon,
  switchStyle,
} from './styled'

class FeedbackItem extends PureComponent {
  static propTypes = {
    company: PropTypes.string,
    feedback: PropTypes.string,
    firstName: PropTypes.string,
    image: PropTypes.string,
    isActive: PropTypes.number,
    itemId: PropTypes.number,
    lastName: PropTypes.string,
    personality: PropTypes.array,
    position: PropTypes.string,
    rate: PropTypes.number,
    onUpdateFeedbackState: PropTypes.func.isRequired
  }

  static defaultProps = {
    firstName: 'FirstName',
    lastName: 'LastName',
    position: 'User position is empty',
    company: 'User company is empty',
    rate: 6,
    feedback: 'Feedback text is empty',
    personality: [],
    image: null,
    isActive: null
  }

  state = {
    allFeedbackShow: false,
    allPersonalityShow: false
  }

  renderPersonalitiesItems = () => {
    const { personality } = this.props
    const { allPersonalityShow } = this.state
    if (allPersonalityShow) {
      return personality.map(item => (
        <SkillItem faIcon={'fas fa-check-circle'} name={item.name} />
      ))
    } else {
      const renderPerssonality = personality.filter((item, i) => i < 3)
      return (
        <Fragment>
          {renderPerssonality.map(item => (
            <SkillItem faIcon={'fas fa-check-circle'} name={item.name} />
          ))}
          {personality.length > 3 && !allPersonalityShow ? (
            <PersonalityMoreButton
              onClick={() => this.onClickMoreButton('personality')}
            />
          ) : null}
        </Fragment>
      )
    }
  }

  onClickMoreButton = type => {
    switch (type) {
      case 'feedback':
        this.setState({ allFeedbackShow: true })
        break
      case 'personality':
        this.setState({ allPersonalityShow: true })
        break
      default:
        console.warn('more button type is not valid! type: ', type)
    }
  }

  renderFeedback = () => {
    const { feedback } = this.props
    const { allFeedbackShow } = this.state

    if (allFeedbackShow) {
      return feedback
    } else {
      const showFeedback = feedback.split(' ')

      return (
        <Fragment>
          {showFeedback.slice(0, 20).join(' ')}{' '}
          {showFeedback.length > 20 ? (
            <FeedbackMoreButton
              onClick={() => this.onClickMoreButton('feedback')}
            >
              more...
            </FeedbackMoreButton>
          ) : null}
        </Fragment>
      )
    }
  }

  render() {
    const {
      firstName,
      lastName,
      position,
      company,
      image,
      rate,
      isActive
    } = this.props
    const fullName = `${firstName} ${lastName}`
    const feedbackUserName = `${firstName[0]}${lastName[0]}`
    return (
      <Container
        onClick={this.handleClickCard}
        onMouseOut={this.handleHideEditLabel}
        onMouseOver={this.handleShowEditLabel}
      >
        <FeedbackUserNameBlock>
          <FeedbackUserName>{feedbackUserName}</FeedbackUserName>
          {isActive === null ? null : <IsActiveIcon isActive={isActive} />}
        </FeedbackUserNameBlock>
        <Content>
          <Header>
            <TitleBlock>
              <Title>{fullName}</Title>
              <Position>{position}</Position>
              <Company>{company}</Company>
            </TitleBlock>
          </Header>
          <Feedback>{this.renderFeedback()}</Feedback>
          <PersonalityBlock>{this.renderPersonalitiesItems()}</PersonalityBlock>
        </Content>
      </Container>
    )
  }
}

export default withStyles(switchStyle)(FeedbackItem)
