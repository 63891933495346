import { createReducer } from 'Helpers/redux'

import { LOAD_TIMELINE } from 'Redux/actions/timeline'

const initialState = {
  experience: [],
  loadInfo: {
    isLoading: false,
    isLoaded: false,
    error: null
  }
}

const handlers = {
  [LOAD_TIMELINE.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoaded: false,
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD_TIMELINE.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoaded: false,
        isLoading: false,
        error: payload.data
      }
    }
  },
  [LOAD_TIMELINE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
