import styled from "styled-components";
import RichTextEditor from "react-rte";

import * as colors from "../../../../../../Theme/colors";

export const Container = styled.div`
  display: flex;
  padding: 10px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const Image = styled.div`
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  background: #757575;
  margin: 10px 0 0 0;
`;
export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
  @media screen and (max-width: 500px) {
    margin: 10px 0;
  }
`;
export const Input = styled.input.attrs({ type: "text" })`
  background: none;
  border: solid 1px ${(props) => (props.error ? "red" : colors.chatPurple)};
  border-radius: 10px;
  margin: 5px 0;
  padding: 5px 15px;
  width: 100%;
`;
export const TextEditor = styled(RichTextEditor)`
  height: 250px;
  overflow-y: auto;
  width: 450px;
  font-family: Nunito !important;
  border-color: ${colors.chatPurple}!important;
  button[title="Image"],
  button[title="Link"],
  button[title="Remove Link"],
  span[title="Normal"] {
    display: none;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  & .DraftEditor-root {
    height: 194px;
  }
`;
export const AutocompleteContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  background-color: #fff;
  border-radius: 10px;
  z-index: 11;
`;
export const WithAutocompleate = styled.div`
  position: relative;
`;

export const SelectDateBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;
