import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { mountFilter } from "../../../Helpers/mountFilter";
import { unmountFilter } from "../../../Helpers/unmountFilter";

import { FilterItem } from "../../Blocks/FilterItem/FilterItem";

import {
  Search,
  SearchInput,
  Button,
  CloseBtn,
  FilterContainer,
  FilterTitle,
  JobForYouFilterTitle,
  SearchIcon,
  Container,
} from "./styled";

export default class DropdownOptions extends PureComponent {
  componentDidMount() {
    mountFilter("Options", true);
  }

  componentWillUnmount() {
    unmountFilter("Options");
  }

  render() {
    const {
      onFilterClose,
      optionNameFilter,
      onOptionNameFilterTextChange,
      optionsToRender,
      onOptionsFilterItemClick,
      onOptionsFilterOkBtnClick,
      isUnmount,
    } = this.props;
    return ReactDOM.createPortal(
      <OutsideClickHandler onOutsideClick={onFilterClose}>
        <Container
          id="Options"
          isUnmount={isUnmount}
          style={{ height: "300px" }}
        >
          <FilterContainer>
            <FilterTitle>OPTIONS</FilterTitle>
            <CloseBtn onClick={onFilterClose}>
              <i className="fas fa-times" />
            </CloseBtn>
          </FilterContainer>
          <Search>
            <SearchIcon className="fas fa-search" />
            <SearchInput
              placeholder="Search for Options"
              value={optionNameFilter}
              onChange={onOptionNameFilterTextChange}
            />
          </Search>
          <JobForYouFilterTitle>Selected Options: </JobForYouFilterTitle>
          <FilterItem
            items={optionsToRender}
            onItemClick={onOptionsFilterItemClick}
          />
          <Button onClick={onOptionsFilterOkBtnClick}>
            <i className="fas fa-check-circle" /> Okay!
          </Button>
        </Container>
      </OutsideClickHandler>,
      document.getElementById("root-modal")
    );
  }
}
