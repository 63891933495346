import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const CHECK_PASSWORD = createAsyncAction('password_reset/CHECK_PASSWORD')
export const checkPasswordLink = identity => {
  return apiCall({
    endpoint: '/utils/password-reset',
    method: 'GET',
    types: CHECK_PASSWORD,
    query: {
      identity
    }
  })
}

export const CHECK_PASSWORD_CLEAR_DATA = 'password_reset/CHECK_PASSWORD_CLEAR_DATA'
export const checkPassClearData = () => {
  return {
    type: CHECK_PASSWORD_CLEAR_DATA,
  }
}

export const SEND = createAsyncAction('password_reset/SEND')
export const sendPasswordReset = email => {
  return apiCall({
    endpoint: '/utils/password-reset',
    types: SEND,
    method: 'PUT',
    query: {
      email
    }
  })
}

export const GET_USER_ID = createAsyncAction('password_reset/GET_ID')
export const getUserId = identity => {
  return apiCall({
    endpoint: '/utils/password-reset',
    types: GET_USER_ID,
    query: {
      identity
    }
  })
}

export const CHANGE_USER_PASSWORD = createAsyncAction(
  'password_reset/CHANGE_PASSWORD'
)
export const changeUserPassword = ({ password, userIdentity, identity }) => {
  return apiCall({
    endpoint: '/utils/password-reset',
    types: CHANGE_USER_PASSWORD,
    method: 'POST',
    query: {
      password,
      userIdentity,
      identity
    }
  })
}

export const FORGOT_USER_PASSWORD = 'password_reset/FORGOT_USER_PASSWORD'

export const resetUserPassword = () => ({
    type: FORGOT_USER_PASSWORD
  })

