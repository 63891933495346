import React from 'react'
import { LoaderItem, LoaderContainer } from './styled'
import ContentLoader from 'react-content-loader'
import MediaQuery from 'react-responsive'

export const CategoriesLoader = () => {
  const renderItems = length => {
    const result = []
    for (let i = 0; i < length; i++) {
      result.push(
        <React.Fragment>
          <MediaQuery minDeviceWidth={500}>
            <LoaderItem key={i} title="loading...">
              <ContentLoader
                height={206}
                width={180}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="60" y="20" rx="5" ry="5" width="60" height="75" />
                <rect x="30" y="105" rx="5" ry="5" width="120" height="25" />
                <rect x="60" y="151" rx="5" ry="5" width="60" height="11" />
                <rect x="50" y="170" rx="5" ry="5" width="80" height="23" />
              </ContentLoader>
            </LoaderItem>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={499}>
            <LoaderItem key={i} title="loading...">
              <ContentLoader
                height={240}
                width={180}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="60" y="20" rx="5" ry="5" width="60" height="75" />
                <rect x="30" y="105" rx="5" ry="5" width="120" height="25" />
                <rect x="60" y="181" rx="5" ry="5" width="60" height="11" />
                <rect x="50" y="200" rx="5" ry="5" width="80" height="23" />
              </ContentLoader>
            </LoaderItem>
          </MediaQuery>
        </React.Fragment>
      )
    }
    return result
  }
  return <LoaderContainer>{renderItems(12)}</LoaderContainer>
}
