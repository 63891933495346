import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import moment from 'moment'

import { getAvatarSrc } from 'Helpers/avatar'
import withSizes from 'react-sizes'

import {
  AddressContainer,
  Avatar,
  AvatarContainer,
  Calendar,
  Circle,
  Container,
  Content,
  DownloadProfileButton,
  DownloadProfileButtonContainer,
  EditableField,
  FileInput,
  HR,
  Icon,
  IconContainer,
  InfoText,
  InfoUserAge,
  Item,
  Items,
  LocationIcon,
  LocationIconContainer,
  PrimaryText,
  SocialBlock,
  SocialEditBlock,
  SocialEditContainer,
  SocialEditField,
  SocialEditIcon,
  SocialIconContainer,
  SocialLink,
  UserInfoBlock,
  UserName,
  InfoUserGender,
  ChangeModalInput,
  EditIcon
} from './style'

class UserInfo extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    const { location, info, dob } = props.user

    const state = {
      avatar: null,
      userAge: moment().year() - moment(props.user.dob).year(),
      dob,
      years: null,
      locationValue: location,
      location,
      info,
      isModalOpen: false,
      changeModalType: null
    }

    if (dob) {
      const now = moment(moment.now(), 'x').year()
      const born = moment(dob).year()
      state.years = now - born
    }
    this.state = state
  }

  renderAvatar = () => {
    const { user } = this.props
    const src = getAvatarSrc(user.avatar)
    return (
      <AvatarContainer>
        <Avatar src={src} />
      </AvatarContainer>
    )
  }

  renderLocation = () => {
    const { user } = this.props
    return user.location
      .split(', ')
      .slice(-2)
      .join(', ')
  }

  renderContent = () => {
    const { user, isBigScreen } = this.props

    const now = moment(moment.now(), 'x').year()
    const born = moment(user.dob).year()
    const years = now - born

    return (
      <Container isBigScreen={isBigScreen}>
        <Content>
          {this.renderAvatar()}
          <UserName> </UserName>
          <UserInfoBlock>
            <InfoUserAge>Age: {years}</InfoUserAge>
            <InfoUserGender>
              Gender: {user.gender[0].toUpperCase() + user.gender.slice(1)}
            </InfoUserGender>
          </UserInfoBlock>
          <AddressContainer>
            <InfoText>{this.renderLocation()}</InfoText>
          </AddressContainer>
          <HR />
        </Content>
      </Container>
    )
  }

  render() {
    const { isBigScreen } = this.props

    if (isBigScreen) {
      return ReactDOM.createPortal(
        this.renderContent(),
        document.getElementById('sidebarContainer')
      )
    } else {
      return this.renderContent()
    }
  }
}

const mapSizeToProps = ({ width }) => ({
  isBigScreen: width >= 1600
})

export default withSizes(mapSizeToProps)(UserInfo)
