import { SET_HEADER_CAT, DELETE_HEADER_CAT } from '../actions/main'
import { FOLLOW, UNFOLLOW } from 'Redux/actions/industries'

import { createReducer } from 'Helpers/redux'

const initialState = {
  cats: []
}

const reducer = {
  [SET_HEADER_CAT]: (state = initialState, action) => {
    return [...state, action.payload]
  },
  [DELETE_HEADER_CAT]: (state = initialState, action) => {
    const catsId = action.payload
    return state.filter(cat => cat !== catsId)
  },
  [FOLLOW]: (state, { payload }) => {
    return {
      ...state,
      industries: payload.data
    }
  },
  [UNFOLLOW]: (state, { payload }) => {
    return {
      ...state,
      industries: payload.data
    }
  }
}

export default createReducer(initialState, reducer)
