import styled from 'styled-components'
import * as colors from '../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  flex-basis: 25%;
  align-items: center;
`
export const Title = styled.h3`
  font-weight: normal;
  font-size: 17px;
  color: ${colors.chatPurple};
`
export const Input = styled.input.attrs({ type: 'text' })`
  border: solid 1px
    ${p => (p.error ? colors.notificationRed : colors.chatPurple)};
  border-radius: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  color: #757575;
  text-transform: capitalize;
`
