import styled from 'styled-components'

import StackGrid from 'react-stack-grid'

export const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
`
