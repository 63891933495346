import { createSelector } from 'reselect'

const jobsData = store => store.jobs

export const getUserJobs = createSelector(jobsData, data => data.jobs)

export const getJobsLoadInfo = createSelector(jobsData, data => data.loadInfo)

export const getActiveVacancy = createSelector(
  jobsData,
  data => data.activeVacancy
)

export const getActiveVacLoadInfo = createSelector(
  jobsData,
  data => data.activeVacLoadInfo
)

export const getJobsFilter = createSelector(jobsData, data => data.filter)

export const getTab = createSelector(jobsData, data => data.tab)

export const getSalaryRange = createSelector(jobsData, data => data.salaryRange)

export const getIpLocation = createSelector(jobsData, data => data.ipLocation)
