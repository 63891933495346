import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const ADD_ACTIVITIES = createAsyncAction('activities/ADD')
export const addActivities = toAdd => {
  return apiCall({
    endpoint: '/activities',
    types: ADD_ACTIVITIES,
    method: 'POST',
    query: {
      toAdd: toAdd
    }
  })
}
export const EDIT_ACTIVITIES = createAsyncAction('activities/EDIT')
export const editActivities = ({
  id,
  title,
  subTitle,
  description,
  startDate,
  endDate
}) => {
  return apiCall({
    endpoint: '/activities',
    types: EDIT_ACTIVITIES,
    method: 'PUT',
    query: {
      id,
      title,
      subTitle,
      description,
      startDate,
      endDate
    }
  })
}
export const REMOVE_ACTIVITIES = createAsyncAction('activities/REMOVE')
export const removeActivities = id => {
  return apiCall({
    endpoint: '/activities',
    types: REMOVE_ACTIVITIES,
    method: 'DELETE',
    query: {
      id
    }
  })
}
