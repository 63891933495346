import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
  ModalBackground,
  Container,
  Header,
  Title,
  Content,
  ButtonsBlock,
  YesButton,
  NoButton
} from './styled'

export default class WarningModal extends PureComponent {
  renderContent = () => {
    const { onCloseMainModal, onCloseWarnMobal } = this.props
    return (
      <ModalBackground>
        <Container onClick={e => e.stopPropagation()}>
          <Header>
            <Title>Are you sure you want to discard changes to this ?</Title>
          </Header>
          <Content>
            <ButtonsBlock>
              <NoButton onClick={onCloseWarnMobal} />
              <YesButton onClick={onCloseMainModal} />
            </ButtonsBlock>
          </Content>
        </Container>
      </ModalBackground>
    )
  }

  render() {
    return ReactDOM.createPortal(
      this.renderContent(),
      document.getElementById('root-modal')
    )
  }
}
