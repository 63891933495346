import { createSelector } from 'reselect'

const passwordReset = store => store.passwordReset

export const getSendMailPasswordResetInfo = createSelector(
  passwordReset,
  data => data.sendMailLoadInfo
)

export const getPasswordResetUserInfo = createSelector(
  passwordReset,
  data => data.getUserIdLoadInfo
)

export const getPasswordResetUserId = createSelector(
  passwordReset,
  data => data.userId
)

export const getPasswordChangeInfo = createSelector(
  passwordReset,
  data => data.passwordChangeLoadInfo
)

export const getPasswordLinkStatus = createSelector(
  passwordReset,
  data => data.isExpiredLink
)

export const getPasswordCheckedStatus = createSelector(
  passwordReset,
  data => data.isChecked
)

export const getPasswordUserData = createSelector(
  passwordReset,
  data => data.resendEmailUserData
)