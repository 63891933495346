import React, { PureComponent, Fragment } from 'react'
import ReactDOM from 'react-dom'
import swal from 'sweetalert'
import OutsideClickHandler from 'react-outside-click-handler'
import { mountFilter } from '../../../Helpers/mountFilter'
import { unmountFilter } from '../../../Helpers/unmountFilter'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

import {
  LocationIcon,
  LocationInput,
  DistanceFilter,
  Button,
  Kms,
  CloseBtn,
  KmsButtonContainer,
  KmsButtonTitle,
  DistanceIcon,
  ButtonOkIcon,
  DistanceHeader,
  HeaderTitle,
  HeaderIcon,
  SearchContainer,
  DistanceQuestion,
  Container,
  CityOption,
  LocationList,
  Search,
  SearchInput,
  Loader,
  CheckedCitiesIcon,
  SearchIcon
} from './styled'

export default class DropdownDistance extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      address: '',
      locations: props.locations
    }
  }

  componentDidMount() {
    mountFilter('Distance')
  }

  componentWillUnmount() {
    unmountFilter('Distance')
  }

  handleChangeSearch = address => {
    this.setState({ address })
  }

  handleDeleteLocation = placeId => {
    const { locations: prevLocations } = this.state
    const locations = prevLocations.filter(item => item.placeId !== placeId)
    this.setState({ locations })
  }

  handleSelect = (address, placeId) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(coords => {
        const locations = this.state.locations.concat({
          name: address,
          placeId: placeId,
          lat: coords.lat,
          lng: coords.lng
        })
        this.setState({ locations, address: '' })
      })
      .catch(error => console.error('Error', error))
  }

  renderSearch = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading
  }) => {
    const { locations } = this.state
    return (
      <Fragment>
        <Search>
          {loading ? <Loader /> : <SearchIcon />}
          <SearchInput
            {...getInputProps({
              placeholder: 'Search for Jobs Location'
            })}
          />
        </Search>
        {(suggestions.length && !loading) || locations.length ? (
          <LocationList>
            <ul style={{ padding: 5 }}>
              {locations.length
                ? locations.map(city => {
                    return (
                      <CityOption
                        active
                        key={city.placeId}
                        title={city.name}
                        onClick={() => this.handleDeleteLocation(city.placeId)}
                      >
                        <CheckedCitiesIcon />
                        {city.name}
                      </CityOption>
                    )
                  })
                : null}
              {suggestions.map((city, index) => {
                return (
                  <CityOption
                    {...getSuggestionItemProps(city)}
                    key={city.placeId}
                    title={city.description}
                  >
                    <CheckedCitiesIcon />
                    {city.description}
                  </CityOption>
                )
              })}
            </ul>
          </LocationList>
        ) : null}
      </Fragment>
    )
  }

  render() {
    const {
      onFilterClose,
      onDistanceIncrementBtnClick,
      distanceFromHome,
      onDistanceDecrementBtnClick,
      onDistanceOkBtnClick,
      isUnmount
    } = this.props
    const { locations } = this.state
    return ReactDOM.createPortal(
      <OutsideClickHandler onOutsideClick={onFilterClose}>
        <Container id="Distance" isUnmount={isUnmount}>
          <DistanceHeader>
            <HeaderTitle>DISTANCE FROM HOME</HeaderTitle>
            <CloseBtn onClick={onFilterClose}>
              <HeaderIcon className="fas fa-times" />
            </CloseBtn>
          </DistanceHeader>
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChangeSearch}
            onSelect={this.handleSelect}
          >
            {this.renderSearch}
          </PlacesAutocomplete>
          <DistanceQuestion top={locations.length}>
            What distance is good for you beetwen your home and workspace?
          </DistanceQuestion>
          <DistanceFilter>
            <DistanceIcon
              className="fas fa-chevron-circle-up"
              onClick={onDistanceIncrementBtnClick}
            />
            <KmsButtonContainer>
              <KmsButtonTitle className="dist_p">Within</KmsButtonTitle>
              <Kms>{distanceFromHome}</Kms> <KmsButtonTitle>Kms</KmsButtonTitle>
            </KmsButtonContainer>
            <DistanceIcon
              className="fas fa-chevron-circle-down"
              onClick={onDistanceDecrementBtnClick}
            />
          </DistanceFilter>
          <Button onClick={() => onDistanceOkBtnClick(locations)}>
            <ButtonOkIcon className="fas fa-check-circle" /> Okay!
          </Button>
        </Container>
      </OutsideClickHandler>,
      document.getElementById('root-modal')
    )
  }
}
