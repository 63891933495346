import styled from 'styled-components'
import * as colors from '../../../Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { GridLoader } from 'react-spinners'

export const CandidateFeedbackPageContainer = styled.div`
  width: 100%;
  transition: all 0.2s ease-in-out;
  height: initial !important;
`

export const TimeLineContainer = styled.div`
  padding: 20px 15px;
`

export const Row = styled.div`
  justify-content: space-around;
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
`

export const Block = styled.div`
  height: 600px;
  @media (max-width: 500px) {
    height: auto;
    margin: 10px 0;
  }
  max-width: 95%;
  width: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 24px 1px rgba(61, 61, 61, 0.42);
  -moz-box-shadow: 0px 0px 24px 1px rgba(61, 61, 61, 0.42);
  box-shadow: 0px 0px 24px 1px rgba(61, 61, 61, 0.42);
  ${p => p.error && `border: solid 1px ${colors.notificationRed}`};
`
export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 10px;
  border-radius: 15px;
  background-color: rgb(253, 253, 253);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  max-width: 100%;
`
export const AvatarContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 0 25px;
`
export const Avatar = styled.div`
  width: 115px;
  height: 115px;
  background: url("${p => p.image}") no-repeat center;
  background-size: 100%;
`
export const EmptyAvatar = styled.div`
  width: 115px;
  height: 115px;
  background-color: ${colors.lightGreyFont};
`
export const Name = styled.p`
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(35, 185, 254);
  line-height: 1.045;
  text-align: center;
`
export const Info = styled.div`
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(112, 112, 112);
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 0 20px;
`
export const InfoText = styled.span`
  margin: 0 3px;
`
export const MainInfo = styled(PerfectScrollbar).attrs({ component: 'p' })`
  font-size: 14px;
  font-family: 'Nunito';
  color: #757575;
  margin: 20px 0;
  max-height: 210px;
`
export const NotificationContainer = styled.div`
  color: white;
  border-radius: 15px;
  font-size: 22px;
  background: linear-gradient(
    to right,
    rgb(0, 101, 255, 0.7),
    rgb(0, 101, 255, 0.7) 35%,
    rgba(95, 12, 211, 0.7)
  );
  margin: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
`
export const Notification = styled.div`
  flex: 1;
`
export const IconButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  margin: 0 5px;
`
export const UserInfoContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  border-radius: 10px;
  box-shadow: 0 0 24px 1px rgba(61, 61, 61, 0.42);
  background: #fff;
  margin: 15px auto;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Loader = styled(GridLoader).attrs({
  size: 30,
  color: colors.careerBlue
})``
