import React, { PureComponent } from 'react'
import _ from 'lodash'
import withSizes from 'react-sizes'
import htmlParser from 'react-html-parser'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import CircularProgressbar from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { initial } from '../../../Constants/routes'
import { Layout } from '../../Blocks'

import { Container, LoaderContainer, Loader, Text, Title } from './styled'

class Settings extends PureComponent {
  componentDidMount() {
    const { settingsList, onLoadSettings } = this.props
    if (!settingsList.length) onLoadSettings()
  }

  elList = () => {
    const { settingsList } = this.props
    const parse = window.location.pathname.split('/')
    const length = parse.length - 1
    const path = parse[length]
    const list = settingsList.filter(el => {
      const parseUrl = el.url.split('/')
      const parseLenght = parseUrl.length - 1
      const pathUrl = parseUrl[parseLenght]
      if (pathUrl === path) return el
    })
    return list
  }

  renderText = () => {
    const list = this.elList()
    let text = ''
    if (list.length) {
      text = htmlParser(list[0].information)
      return text
    }
    return text
  }

  headerTitle = () => {
    const list = this.elList()
    let title = ''
    title = list.length ? list[0].sub_title : 'Settings'
    return title
  }

  render() {
    const { settingsLoadInfo } = this.props
    const title = this.headerTitle()

    const list = this.elList()
    if (settingsLoadInfo.success && !list.length) {
      return <Redirect to={initial} />
    }
    const description = list && list.length ? list[0].description : 'policy'
    const keywords = list && list.length ? list[0].keywords : 'policy'
    return (
      <Layout header={title} history={this.props.history} notScroll={true}>
        <Helmet>
          <meta content="MrJOBS" property="og:title" />
          <meta content="article" property="og:type" />
          <meta content={title} property="og:description" />
          <title>{title} | Mr.JOBS</title>
          <meta content={keywords} name="keywords" />
          <meta content={description} name="description" />
        </Helmet>
        {settingsLoadInfo.success && (
          <Container>
            <Title>{title}</Title>
            <Text>{this.renderText()}</Text>
          </Container>
        )}
        {settingsLoadInfo.loading && <Loader />}
      </Layout>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 600
})

export default withSizes(mapSizeToProps)(Settings)
