import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
        background-color: rgba(165, 165, 165, 0.2);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.2);
    }
`
export const Placeholder = styled.div`
  width: ${({ width }) => width || 50}px;
  height: ${({ height }) => height || 14}px;
  animation: ${pulse} 1s infinite ease-in-out;
  margin: 0 5px;
`
