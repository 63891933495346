import React, { PureComponent } from "react";

import { AddButton, ChangeModal, Card } from "../blocks";
import { CareerTimeLineBlock } from "../../../../Blocks";

import { Element } from "react-scroll";

import { Container, ContainerMod, CardsList } from "./styles";
import { getCompanyImage } from "../../../../../Helpers/getImages";
import ConfirmModal from "antd/es/modal";
import { Modal, Button } from "antd";
import swal from "sweetalert";

export default class MyPositions extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      isModalOpen: false,
      changeItemId: null,
      isDraft: false,
    };

    if (!props.companies.length) props.onLoadCompanies();
  }

  handleOpenModal = ({ id }) => {
    this.setState({
      isModalOpen: true,
      changeItemId: id || null,
    });
  };

  handleCloseModal = () => {
    const { isDraft } = this.state;

    // if (!isDraft) {
    //   this.confirmModal();
    // } else {
    this.setState({
      isModalOpen: false,
      isDraft: false,
      changeItemId: null,
    });
    // iuy
  };
  closeMainModal = () => {
    this.setState({
      isModalOpen: false,
      changeItemId: null,
    });
  };

  handleSaveClick = (result) => {
    const { onEditExperience, onAddExperience } = this.props;
    const { changeItemId } = this.state;

    const {
      title,
      subTitle,
      description,
      startDate,
      endDate,
      image,
      currently,
    } = result;

    const reqObj = {
      position: title,
      company: subTitle,
      description,
      startDate,
      endDate,
      image,
      expId: changeItemId,
      currently,
    };

    if (changeItemId !== null) {
      onEditExperience({ ...reqObj });
    } else {
      onAddExperience([reqObj]);
    }

    this.handleCloseModal();
  };

  handleDeleteClick = (id) => {
    const { onRemoveExperience } = this.props;
    onRemoveExperience(id);
  };

  handleLoadCompanyImage = (imageSrc) => {
    return getCompanyImage(imageSrc);
  };

  renderChangeModal = () => {
    const { experience, companies } = this.props;
    const { changeItemId } = this.state;

    const modalTitle =
      changeItemId === null ? "Add Experience" : "Edit Experience";
    const changedItem = experience.find((item) => item.id === changeItemId);

    const itemCompany =
      changedItem && companies.find((el) => el.name === changedItem.company);

    let parsedItem = undefined;
    if (changeItemId !== null) {
      parsedItem = {
        title: changedItem.position,
        subTitle: changedItem.company,
        description: changedItem.description,
        startDate: changedItem.start_date,
        endDate: changedItem.end_date,
        image: itemCompany ? getCompanyImage(itemCompany.logo) : null,
        currently: changedItem.currently,
      };
    }
    // console.log(companies);
    return (
      <ChangeModal
        autocompleateItems={companies.map((el) => ({ ...el, image: el.logo }))}
        withMonthDatePicker={true}
        checkBoxTitle={" I currently work on this role"}
        faIcon={"fas fa-building"}
        item={parsedItem}
        modalTitle={modalTitle}
        titleInputPlaceholder={"Title Ex: Web Programmer or Business Manager"}
        subTitleInputPlaceholder={"Company Ex: Microsoft"}
        withAutocompleate={"subTitle"}
        onClose={this.handleCloseModal}
        onLoadAutocompleateItemImage={this.handleLoadCompanyImage}
        onSave={this.handleSaveClick}
        changeItemId={changeItemId}
        isDelete={true}
        onDelete={() => this.handleDeleteClick(this.state.changeItemId)}
        isBigModalWindow={false}
      />
    );
  };
  confirmModal = () => {
    swal({
      icon: "warning",
      text: "Are you sure you want to discard changes to this ?",
      buttons: {
        confirm: {
          text: "Discard",
          value: true,
          visible: true,
        },
        cancel: {
          text: "No Thanks",
          value: false,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) this.closeMainModal();
    });
  };
  renderCards = () => {
    const { experience, companies } = this.props;
    if (experience && experience.length) {
      return experience.map((item) => {
        const company = companies.find((el) => el.name === item.company);
        return (
          <Element key={item.id} name={`experience${item.id}`}>
            <Card
              description={item.description}
              endDate={item.end_date}
              faIcon={"fas fa-building"}
              id={item.id}
              image={company ? getCompanyImage(company.logo) : null}
              item={item}
              startDate={item.start_date}
              subTitle={item.company}
              title={item.position}
              type={"experiences"}
              onDelete={() => this.handleDeleteClick(item.id)}
              onModalOpen={() => this.handleOpenModal({ id: item.id })}
              currently={item.currently}
            />
          </Element>
        );
      });
    }
  };

  render() {
    const { isModalOpen, isDraft } = this.state;
    const companyImage = null;

    return (
      <CareerTimeLineBlock title={"My Experience"} type={"experience"}>
        {isDraft ? (
          <ContainerMod>
            <Modal
              title="Basic Modal"
              visible={isDraft}
              // onOk={handleOk}
              // onCancel={handleCancel}
            >
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </Modal>
          </ContainerMod>
        ) : null}
        <Container>
          <AddButton
            value={"Add Experience"}
            onClickAdd={this.handleOpenModal}
          />
          <CardsList>{this.renderCards()}</CardsList>
          {isModalOpen ? this.renderChangeModal() : null}
          {/* {isModalOpen ? this.confirmModal() : null} */}
        </Container>
      </CareerTimeLineBlock>
    );
  }
}
