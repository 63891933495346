import Component from './MyAcademicQualifications'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadAcademicQualifications } from 'Redux/actions/academicQualifications'

import { getUserAcademicQualifications } from 'Redux/selectors/publicUser'

import { getPredictionsAcademicQualifications } from 'Redux/selectors/academicQualifications'

const selector = createStructuredSelector({
  userAcademQualif: getUserAcademicQualifications,
  academQulifList: getPredictionsAcademicQualifications
})

const actions = {
  onLoadAcademQualifList: loadAcademicQualifications
}

export const MyAcademicQualifications = connect(
  selector,
  actions
)(Component)
