import { createSelector } from "reselect";

const citiesData = store => store.cities;

export const getCities = createSelector(citiesData, data => data.cities);

export const getCitiesLoadInfo = createSelector(
  citiesData,
  data => data.loadInfo
);
