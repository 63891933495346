import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'

import Image from '../../../../Blocks/Image'

export const Content = styled.div`
  margin: 24px 16px 16px;
`
export const List = styled(PerfectScrollbar).attrs({ component: 'ul' })`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: initial !important;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 10px 0;
`
export const BlankList = styled.div`
  height: 85px;
`
export const ListItem = styled.li`
  margin-right: 24px;
`
export const Form = styled.div``
export const InputContainer = styled.div`
  margin-top: 24px;
`
export const BottomBlock = styled.div`
  display: flex;
  margin: 16px 0 24px;
  align-items: center;
`
export const Link = styled.a`
  color: ${colors.mainBlue};
  margin-left: ${props => props.marginLeft};
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 550;
  &:hover {
    color: ${colors.mainBlue};
  }
`
export const Icon = styled.img`
  width: 80%;
  height: 80%;
`
