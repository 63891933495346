import React, { Component } from 'react'
import {
  // ContainerRight,
  ContainerLeft,
  Company,
  Attainment,
  Description,
  Wall,
  Time,
  TimeText,
  ContainerAll,
  Image
} from './styled'

import map from 'lodash/map'

import * as date from 'Helpers/date'

import star from '../../../../../images/Notification/star.png'
import company from '../../../../../images/Notification/company.png'

class LeftBlock extends Component {
  render() {
    // console.log(this.props.left)
    return map(this.props.left, (value, key) => {
      const LeftId = value.id
      return (
        <ContainerAll first={key === 0} key={LeftId}>
          <Time content={'flex-end'}>
            <i className="fas fa-check-circle" />
            <TimeText>{date.formatForNotification(value.time)}</TimeText>
          </Time>
          <ContainerLeft>
            <Company>
              <img alt="" src={company} />
            </Company>
            <Wall />
            <Description>
              <p>{value.text}</p>
            </Description>
            <Attainment>
              <Image active={value.type === 'accepted'} src={star} />
            </Attainment>
          </ContainerLeft>
        </ContainerAll>
      )
    })
  }
}

export { LeftBlock }
