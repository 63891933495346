import styled from 'styled-components'
import { notificationRed } from '../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 50px;
  justify-content: flex-start;
  max-width: 1400px;
  @media screen and (max-width: 500px) {
    justify-content: center;
    margin: 30px 0 60px;
  }
`

export const IconImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const CategoryImg = styled.img`
  height: 75px;
`

export const TitleContainer = styled.div`
  margin-top: 10px;
`
export const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  color: #545454;
`
export const Jobs = styled.h3`
  font-size: 10px;
  color: #545454;
  margin-top: 10px !important;
`
export const Wraper = styled.div`
  width: 180px;
  display: flex;
  justify-content: space-between;
  color: #545454;
  flex-direction: column;
  text-align: center;
  user-select: none;
  // cursor: pointer;
  border-radius: 18px;
  margin: 0 10px 10px 10px;
  padding: 10px;
  box-shadow: 0 0 6px 1px rgba(61, 61, 61, 0.42);
  :hover {
    color: #395eff;
  }

  @media only screen and (min-width: 1110px) {
    margin-right: 0;
  }
  @media screen and (max-width: 500px) {
    width: 150px;
  }
`
export const Follow = styled.button`
  min-width: 70px;
  min-height: 20px;
  color: white;
  background: ${props => (props.follow ? notificationRed : '#32e09c')};
  margin: 10px auto 0;
  border: none;
  border-radius: 13px;
  outline: none;
  @media only screen and (max-width: 500px) {
    height: 30px;
  }
`
export const BlankFollow = styled.div`
  height: 20px;
  margin-top: 10px;
`
export const EmptyCard = styled.div`
  width: 150px;
  height: 230px;
  margin: 0 10px 10px 10px;
`
