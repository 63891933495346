export const statuses = {
  pitched: 'Pitched',
  inProgress: 'In progress',
  accepted: 'Accepted',
  declined: 'Declined',
  reconsider: 'Reconsider',
  shortListed: 'Short listed'
}

export const appliers = {
  user: 'user',
  company: 'company'
}
