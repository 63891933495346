import styled from "styled-components";
import * as colors from "Theme/colors";

export const textBoxStyles = {
  border: "none",
  backgroundColor: "transparent",
  padding: "5px 0",
  flex: 1,
  outline: "none",
  fontSize: "18px",
  marginLeft: "10px",
};
export const Container = styled.div`
  display: flex;
  align-items: center;
`;
export const TextBlockContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  // box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
  margin-top: 5px;
  padding-top: 12px;
  border-bottom: 0.4px solid;

`;
export const TextBlock = styled.input`
  border: 0.4px solid;
  background-color: red;
  padding: 12px 0;
  flex: 1;
  outline: none;
  font-size: 18px;
`;
export const Placeholder = styled.div`
  position: absolute;
  background: transparent;
  top: ${(props) => (props.active ? "-10px" : `${props.top}px`)};
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: text;
  transition: 0.2s all linear;
  margin-left: 10px;
`;
export const PlaceholderText = styled.div`
  transition: 0.2s all linear;
  padding-top: 12px;
  font-size: ${(props) => (props.active ? "12px" : "15px")};
`;
export const RightIcon = styled.div`
  font-size: 17px;
  color: ${colors.notificationRed};
  visibility: ${(props) => (props.warning ? "visible" : "hidden")};
  align-items: center;
  display: flex;
`;
export const RedStarBox = styled.span`
  color: red;
`;
