import styled from 'styled-components'

export const Icon = styled.i.attrs({ className: 'fas fa-circle' })`
  border-radius: 50%;
  border: solid 1px #00e99a;
  font-size: 12px;
  width: 13px;
  height: 13px;
  margin: 0 10px;
  @media screen and (max-width: 400px) {
    margin: 0 5px;
  }
`
