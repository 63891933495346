import { createAsyncAction } from '../../Helpers/redux'
import { apiCall } from './api'

export const SWITCH_MODAL = 'SWITCH_MODAL'
export const switchModal = window => ({
  type: SWITCH_MODAL,
  window
})

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const closeModal = () => ({
  type: CLOSE_MODAL
})

export const UPDATE_FEEDBACK_STATE = createAsyncAction(
  'candidates-feedback/UPDATE_STATE'
)
export const updateFeedbackState = ({ feedbackId, value }) => {
  return apiCall({
    endpoint: '/candidates/update-feedback-state',
    types: UPDATE_FEEDBACK_STATE,
    method: 'PUT',
    query: {
      id: feedbackId,
      value
    }
  })
}
