import styled from 'styled-components'
import * as colors from '../../../Theme/colors'

export const ButtonBlock = styled.div`
  display: flex;
  justify-content: ${p => (p.changeItemId ? 'space-between' : 'center')};
  @media screen and (max-width: 500px) {
    padding: ${p => (p.changeItemId && !p.isMyReferee ? '0 10px' : 0)};
  }
`

const button = `
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  font-family: "Nunito";
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  margin: 0 10px;
  &:focus {
    outline: none;
  }
`
export const SaveButton = styled.input.attrs({ type: 'button', value: 'Save' })`
  ${button};
  background: ${colors.buttonOkayGreen};
`

export const DeleteButton = styled.input.attrs({
  type: 'button',
  value: 'Delete'
})`
  ${button};
  background: ${colors.notificationRed};
`

export const CancelButton = styled.input.attrs({
  type: 'button',
  value: 'cancel'
})`
  ${button};
  background: ${colors.notificationRed};
`
export const Wrap = styled.div`
  position: relative;
`
export const CloseButton = styled.i.attrs({ className: 'fas fa-times' })`
  position: absolute;
  width: 30px;
  height: 30px;
  top: ${p => (p.isBigModalWindow ? '15px' : '-15px')};
  right: ${p => (p.isBigModalWindow ? '15px' : '-15px')};
  border-radius: 15px;
  background-color: #552a7a;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    top: ${p => (p.isBigModalWindow || p.isMyReferee ? '15px' : '-15px')};
    right: ${p => (p.isBigModalWindow || p.isMyReferee ? '15px' : '-15px')};
  }
`

/*export const CloseButton = styled.i.attrs({ className: 'fas fa-times' })`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #757575;
  cursor: pointer;
`*/

export const ModalEditInfoContainerBackground = styled.div`
  background: ${colors.whiteBackgroundColor};
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1101;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`

export const ModalEditInfoContent = styled.div`
  position: relative;
  font-family: Nunito;
`

export const ModalEditInfoTitle = styled.h1`
  ${p => p.isCenteredTitle && 'text-align: center'};
  color: ${colors.careerBlue};
  font-size: 16px;
  font-weight: bold;
  ${'' /* text-transform: uppercase; */}
`

export const ModalEditInfoContainer = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  width: ${p => p.width || 300}px;
  overflow-y: auto;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-height: 500px) {
    height: 100%;
  }
`
