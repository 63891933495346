import React from 'react'
import PropTypes from 'prop-types'

import { Container, Icon, Title, Text, ContactBlock, EditIcon } from './styled'

const UserContactBlock = ({
  email,
  phone,
  address,
  onOpenAddressModal,
  onOpenPhoneModal
}) => {
  return (
    <Container>
      <Title>Contact Info: </Title>
      <ContactBlock disable>
        <Icon className={'fas fa-envelope'} />
        <Text>{email}</Text>
      </ContactBlock>
      <ContactBlock onClick={onOpenPhoneModal}>
        <Icon className={'fas fa-phone'} />
        <Text>{phone}</Text>
        <EditIcon className={'fas fa-edit'} />
      </ContactBlock>
      <ContactBlock onClick={onOpenAddressModal}>
        <Icon className={'fas fa-map-marker-alt'} />
        <Text>{address}</Text>
        <EditIcon className={'fas fa-edit'} />
      </ContactBlock>
    </Container>
  )
}

UserContactBlock.propTypes = {
  address: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  onOpenAddressModal: PropTypes.func.isRequired,
  onOpenPhoneModal: PropTypes.func.isRequired
}

export { UserContactBlock }
