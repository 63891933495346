import React, { PureComponent } from 'react'
import { Container } from './styles'
import SkillsModal from '../SkillsModal'
import { WhoAmICard } from 'Components/Blocks/WhoAmICard'

export default class SearchOptions extends PureComponent {
  state = {
    isJobTypesModalOpen: false,
    isCompaniesModalOpem: false,
  }
  render() {
    return (
      <Container>
        <WhoAmICard
          title="Job types"
          data={this.props.jobTypes}
          onRemoveItem={this.props.onRemoveJobType}
          onAddClick={() => this.setState({ isJobTypesModalOpen: true })}
        />
        <WhoAmICard
          title="Prefered companies"
          data={this.props.companies}
          onRemoveItem={this.props.onRemoveCompany}
          onAddClick={() => this.setState({ isCompaniesModalOpem: true })}
        />
        <SkillsModal
          title="add job types"
          subTitle="selected job types:"
          isOpen={this.state.isJobTypesModalOpen}
          onRequestClose={() => this.setState({ isJobTypesModalOpen: false })}
          allItems={this.props.predJobTypes}
          onLoad={this.props.onLoadJobTypes}
          userItems={this.props.jobTypes}
          onEditComplete={this.props.onEditJobTypes}
          placeholder="Search For Job Types"
        />
        <SkillsModal
          title="add companies"
          subTitle="selected companies:"
          isOpen={this.state.isCompaniesModalOpem}
          onRequestClose={() =>
            this.setState({ isCompaniesModalOpem: false })
          }
          allItems={this.props.predCompanies}
          onLoad={this.props.onLoadCompanies}
          userItems={this.props.companies}
          onEditComplete={this.props.onEditCompanies}
          placeholder="Search For Companies"
        />
      </Container>
    )
  }
}
