import React, { PureComponent } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";

import { getIndustryImageLink } from "Helpers/getImageLink";

import { Title } from "./style";

import Profession from "../../Blocks/Professions/Profession";

import { Layout, CategoriesLoader } from "../../Blocks";
import { LOAD_USER_JOBS } from "Redux/actions/jobs";
import { UNFOLLOW } from "Redux/actions/industries";
import { Select } from 'antd';
class Categories extends PureComponent {
  //eslint-disable-next-line
  UNSAFE_componentWillMount() {
    const { onIndustriesLoad, onLoadIndustriesMetaTags } = this.props;

    onIndustriesLoad();
    onLoadIndustriesMetaTags();
  }

  componentWillUnmount() {
    document.getElementById("meta-keywords").remove();
    document.getElementById("meta-description").remove();
  }

  getIndustriesList = (indArray) => {
    if (!_.isArray(indArray)) return [];

    return indArray.map((ind) => {
      return {
        icon: getIndustryImageLink(ind.icon),
        title: ind.name,
        jobs: `${ind.vacanciesCount || 0} Jobs Avaliable`, //ind.vacanciesCount check expire
        id: ind.id,
      };
    });
  };

  render() {
    const { loadInfo, industries, industriesMetaTags } = this.props;

    const industriesToRender = this.getIndustriesList(industries);

    const title = industriesMetaTags.length ? industriesMetaTags[0].title : "";
    const keywords = industriesMetaTags.length
      ? industriesMetaTags[0].keywords
      : "";
    const description = industriesMetaTags.length
      ? industriesMetaTags[0].description
      : "";

    return (
      <Layout
        header={"JOB INDUSTRIES"}
        history={this.props.history}
        isLoadingCategories={loadInfo.isLoading}
        notScroll={true}
      >
        <Helmet>
          <title id="meta-title">{title} | Mr.JOBS</title>
          <meta
            name="description"
            id="meta-description"
            content={description}
          />
          <meta name="keywords" id="meta-keywords" content={keywords} />
        </Helmet>
        <Title>Please Select Your Industry</Title>
        {loadInfo.isLoaded && (
          <Profession
            deleteHeaderCat={this.props.onDeleteHeaderCat}
            profession={industriesToRender}
            setHeaderCat={this.props.onSetHeaderCat}
            {...this.props}
          />
        )}
        {loadInfo.isLoading && <CategoriesLoader />}
      </Layout>
    );
  }
}

export default Categories;
