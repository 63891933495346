import styled from 'styled-components'
import * as colors from '../../../../../../Theme/colors'

export const CardTime = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: ${colors.chatPurple};
  padding: 0 0 5px 0;
  border-bottom: solid 2px ${colors.chatPurple};
  width: max-content;
`
