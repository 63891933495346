import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding-top: 5px;
`
export const Image = styled.img`
    max-height: 55px;
    margin-left: -10px;
`
export const BackButton = styled.div`
    font-size: 16px;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    margin-left: -15px;
`