import styled, { keyframes } from 'styled-components'
import { notificationRed } from '../../../Theme/colors'
import { rubberBand } from 'react-animations'

import { PropagateLoader } from 'react-spinners'

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`
export const Loader = styled(PropagateLoader).attrs({ color: 'silver' })``


export const percentStyles = {
  root: {
    width: '60px',
    height: '60px'
  },
  path: {
    stroke: '#583ea1'
  },
  text: {
    fill: '#857575',
    fontSize: '25px'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 1400px;
  background-color: #fff;
  width: 100%;
  margin-bottom: 50px;
  @media screen and (max-width: 500px) {
    padding-right: 0;
  }
`
export const Text = styled.p``
