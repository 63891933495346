import styled from 'styled-components'
import { mt } from 'styled-system'

export const Container = styled.div`
  height: calc(100vh - 135px);
  overflow: hidden;
  @media screen and (max-width: 899px) {
    height: initial;
  }
`
export const UserContainer = styled.div`
  display: flex;
  height: 60px;
  font-family: 'Nunito';
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
  padding: 7px;
  min-width: 335px;
  :hover {
    background: #00b7fe;
    color: white;
  }
  background: ${props => props.selected && '#00B7FE'};
  color: ${props => props.selected && 'white'};
`

export const Photo = styled.div``

export const Nickname = styled.div`
  font-size: 16px;
  margin-left: 20px;
`

export const Date = styled.div`
  font-size: 12px;
`

export const Nick = styled.p`
  width: 150px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PhotoImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 40px;
  background: #e3e3e3;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  ${mt};
`
