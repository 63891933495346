import styled from 'styled-components'
import * as colors from '../../../../../../Theme/colors'

export const ListItemContainer = styled.li`
  margin: 0 5px 10px 0;
  display: flex;
  align-items: center;
  border: solid 1px ${colors.chatPurple};
  padding: 4px 10px;
  border-radius: 20px;
  height: max-content;
  color: ${colors.chatPurple};
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: ${colors.chatPurple};
    color: #fff;
  }
`
export const ListItemIcon = styled.i.attrs({ className: 'fas fa-times' })`
  margin: 0 5px 0 0;
  font-size: 15px;
`
export const ListItemText = styled.span`
  font-size: 14px;
`
export const Icon = styled.i`
  margin: 0 5px 0 0;
`
