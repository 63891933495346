import React, { Component } from 'react'

import ReactHtmlParser from 'react-html-parser'
import PerfectScrollbar from 'perfect-scrollbar'

import emojify from 'Helpers/emoji'
import { getChatMessageDate } from 'Helpers/date'
import { getAvatarSrc } from 'Helpers/avatar'
import map from 'lodash/map'

import {
  UserContainer,
  Photo,
  Nickname,
  Date,
  Nick,
  PhotoImg,
  Row,
  Container
} from './styled'
import { notificationRed } from '../../../../../Theme/colors'

class ChatList extends Component {
  componentDidMount() {
    new PerfectScrollbar('#chatListScrollContainer')
  }
  renderUsers = () => {
    const { chats, messages, activeChat, onChatClick } = this.props

    return map(chats, value => {
      const avatarSrc = getAvatarSrc(value.companion_avatar)

      const isActive = activeChat ? value.id === activeChat.id : false

      const chatMessages = messages[value.id] || []
      const lastMessage = chatMessages[chatMessages.length - 1] || null

      const lastMessageDate = lastMessage
        ? getChatMessageDate(lastMessage.created_at)
        : ''

      const unread = chatMessages.filter(
        msg => msg.writter === 'contact_person' && msg.status !== 'Seen'
      )

      return (
        <UserContainer
          key={value.id}
          selected={isActive}
          onClick={() => onChatClick(value)}
        >
          <Photo>
            <PhotoImg src={avatarSrc} />
          </Photo>
          <div>
            <Row mt="10px">
              <Nickname>
                <Nick>{value.companion_name}</Nick>
              </Nickname>
              <Date>
                <p style={{fontSize: '14px'}}>{lastMessageDate}</p>
              </Date>
            </Row>
            <Row>
              <Nickname>
                <Nick>
                  {lastMessage &&
                    ReactHtmlParser(emojify(unescape(lastMessage.message)))}
                </Nick>
              </Nickname>
              <Date>
                {unread.length > 0 && (
                  <p
                    style={{
                      maxWidth: 25,
                      maxHeight: 25,
                      minHeight: 25,
                      minWidth: 25,
                      width: '100%',
                      height: '100%',
                      padding: '5px',
                      borderRadius: '100px',
                      background: notificationRed,
                      color: '#fff',
                      display: 'flex',
                      paddingLeft: 8
                    }}
                  >
                    {unread.length}
                  </p>
                )}
              </Date>
            </Row>
          </div>
        </UserContainer>
      )
    })
  }

  render() {
    return <Container id="chatListScrollContainer">
        {this.renderUsers()}
      </Container>
  }
}

export default ChatList
