import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadProfile, uploadFeedback } from 'Redux/actions/friendSupport'
import { loadPersonalities } from 'Redux/actions/personalities'

import {
  getProfile,
  getLoadInfo,
  getUploadLoadInfo,
  getTimeLineElements
} from 'Redux/selectors/friendSupport'
import {
  getPredictionsPersonalities,
  getPersonalitiesPredictionsLoadInfo
} from 'Redux/selectors/personalities'

import Component from './FeedbackOnCandidate'

const actions = {
  onProfileLoad: loadProfile,
  onFeedbackUpload: uploadFeedback,
  onPersonalitiesLoad: loadPersonalities
}

const selector = createStructuredSelector({
  profile: getProfile,
  timeLine: getTimeLineElements,
  profileLoadInfo: getLoadInfo,
  personalities: getPredictionsPersonalities,
  personalitiesLoadInfo: getPersonalitiesPredictionsLoadInfo,
  uploadLoadInfo: getUploadLoadInfo
})

export default connect(
  selector,
  actions
)(Component)
