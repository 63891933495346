import styled from 'styled-components'

export const Wrapper = styled.div`
  height: ${props => (props.height ? props.height : '75%')};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
`

export const Text = styled.p`
  font-size: 18px;
`
