import React, { PureComponent, Fragment } from 'react'

import { innerRoutes } from '../../../Constants/modalRoutes'

export default class Login extends PureComponent {
  constructor(props) {
    super()
    props.onSwitchModal(innerRoutes.login)
  }

  render() {
    return <Fragment />
  }
}
