import styled from "styled-components";
import { isMobile } from "react-device-detect";
import * as colors from "../../../../../Theme/colors";

import CalendarComponent from "react-calendar";

export const Container = styled.div`
  position: ${(p) => (p.isBigScreen ? "absolute" : "fixed")};
  width: 280px;
  background-color: #fff;
  top: 90px;
  left: 180px;
  border-radius: 20px;
  box-shadow: 0 0 5px #757575;
  font-family: Nunito;
  @media screen and (max-width: 1000px) {
    position: static;
    width: 100%;
    margin: 0 0 40px 0;
  }
  @media screen and (max-width: 350px) {
    border-radius: 0;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
export const Avatar = styled.img`
  background: gray;
  width: 115px;
  height: 115px;
  border-radius: 60px;
`;
export const UserName = styled.h1`
  margin: 10px 0 20px;
  color: ${colors.careerBlue};
  text-align: center;
  font-size: 30px;
  cursor: pointer;
`;
export const UserInfoBlock = styled.div`
  backgroundcolor: "red";
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const InfoText = styled.p`
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
export const EditableField = styled.input`
  text-align: center;
  border: none;
  outline: none;
  line-height: 1.438;
  display: inline-block;
  font-size: 14px;
  border-bottom: 1px solid;
  width: 100%;
  margin: 10px 0;
`;
export const Items = styled.ul`
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  padding: 8px 0;
  border-radius: ${(props) => props.borderRadius || "9.6px"};
  list-style: none;
  margin: 3.2px 0;
  background-color: white;
  border: 1px solid ${colors.stepGrey};
  max-height: 300px;
`;
export const Item = styled.li`
  display: flex;
  padding: 3.2px 13px;
  align-items: center;
  cursor: pointer;
`;
export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
`;
export const PrimaryText = styled.span`
  color: black;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const HR = styled.div`
  width: 100%;
  height: 1px;
  background-color: #757575;
  margin: 15px 0;
`;
export const SocialBlock = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  width: 100%;
`;
export const SocialLink = styled.a`
  margin: 0 10px;
  text-align: center;
`;
export const SocialIconContainer = styled.div`
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
`;
export const Icon = styled.i`
  color: ${(p) => (p.disabled ? "#757575" : colors.chatPurple)};
`;
export const InfoUserAge = styled.div`
  margin: 0;
  cursor: pointer;
  min-width: 56px;
  display: flex;
`;
export const FileInput = styled.input.attrs({
  type: "file",
  accept: ".png, .jpg, .jpeg",
})`
  width: 115px;
  height: 115px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
`;
export const AvatarContainer = styled.div`
  position: relative;
`;

export const LocationIconContainer = styled.div`
  height: 170px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 110px;
  color: #757575;
  margin: 0 0 20px;
`;
export const LocationIcon = styled.i.attrs({ className: "fas fa-globe" })``;

export const Calendar = styled(CalendarComponent)`
  margin: 0 0 20px 0;
`;

export const DownloadProfileButtonContainer = styled.div`
  width: 140px;
  height: 30px;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0 0;
  background: linear-gradient(
    to right,
    rgba(88, 56, 175, 0.7),
    rgba(0, 177, 255, 0.7)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DownloadProfileButton = styled.input.attrs({
  type: "button",
})`
  background: none;
  border: none;
  background-color: #fff;
  padding: 0;
  border-radius: 8px;
  width: 136px;
  height: 26px;
  color: #757575;
  &:focus {
    outline: none;
  }
`;
export const Circle = styled.div`
  width: 5px;
  height: 5px;
  min-width: 5px;
  min-height: 5px;
  border-radius: 50%;
  background: #757575;
  margin: 0 5px 0 0;
`;
export const AddressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  margin: 10px 0 0;
`;
export const SocialEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
`;
export const SocialEditBlock = styled.div`
  display: flex;
  margin: 5px 0;
  border: solid 1px ${colors.chatPurple};
  border-radius: 5px;
  height: 35px;
`;
export const SocialEditIcon = styled.i`
  min-width: 40px;
  width: 40px;
  height: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px ${colors.chatPurple};
  font-size: 22px;
  color: ${(p) => (p.active ? colors.careerBlue : "#757575")};
`;
export const SocialEditField = styled.input.attrs({
  type: "text",
  maxLength: "100",
})`
  border: none;
  width: 100%;
  height: 100%;
  padding: 2px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  outline: none;
`;
export const InfoUserGender = styled.span``;
export const ChangeModalInput = styled.input.attrs({ type: "text" })`
  outline: none;
  border: solid 1px ${colors.chatPurple};
  border-radius: 10px;
  width: 100%;
  padding: 5px 0 5px 20px;
  margin: 10px 0 20px;
`;
export const EditIcon = styled.i`
  font-size: 15px;
  color: #fff;
  margin-left: 10px;
  color: ${isMobile ? "#999999" : 0};
  ${InfoUserAge}:hover & {
    color: ${"#999999"};
  }
  ${AddressContainer}:hover & {
    color: ${"#999999"};
  }
`;
