import { createReducer } from "Helpers/redux";

import { LOAD_PROFESSIONAL_QUALIFICATIONS } from "Redux/actions/professionalQualifications";

const initialState = {
  profQualf: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
};

const handlers = {
  [LOAD_PROFESSIONAL_QUALIFICATIONS.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        error: null
      }
    };
  },
  [LOAD_PROFESSIONAL_QUALIFICATIONS.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: payload.data
      }
    };
  },
  [LOAD_PROFESSIONAL_QUALIFICATIONS.SUCCESS]: (state, { payload }) => {
    return {
      profQualf: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    };
  }
};

export default createReducer(initialState, handlers);
