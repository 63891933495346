import styled from 'styled-components'

export const LoaderItem = styled.div`
  height: 50px;
  background-color: white;
  margin-bottom: 5px;
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: flex-start;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: 950px) {
  }
  @media screen and (max-width: 500px) {
  }
`
