import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import SkillsModal from '../SkillsModal'
import { CareerTimeLineBlock } from '../../../../Blocks/CareerTimeLineBlock'
import { AddButton } from '../blocks'
import { ExpertiseBlock } from './blocks'

import { Container, Content } from './styles'

class ImGoodAt extends PureComponent {
  static propTypes = {
    expertises: PropTypes.array,
    subExpertises: PropTypes.array,
    userExpertises: PropTypes.array,
    userSubExpertises: PropTypes.array
  }

  state = {
    isModalOpen: false,
    openedModalType: null,
    changedExpertiseId: null
  }

  handleOpenModal = ({ type, expId }) => {
    const { changedExpertiseId, onLoadSubExpertisesList } = this.props
    if (
      type === 'subExpertises' &&
      Number(expId) !== Number(changedExpertiseId)
    ) {
      onLoadSubExpertisesList({ expId })
    }
    this.setState({
      isModalOpen: true,
      openedModalType: type,
      changedExpertiseId: expId || null
    })
  }

  renderModal = () => {
    const { openedModalType } = this.state
    switch (openedModalType) {
      case 'expertises':
        const { expertises, userExpertises, onLoadExpertisesList } = this.props
        return (
          <SkillsModal
            allItems={expertises}
            isOpen={true}
            placeholder="Search For Expertises"
            subTitle="selected expertises"
            title="Edit expertises"
            userItems={userExpertises}
            onEditComplete={({ toAdd, toDelete }) =>
              this.handleSaveClick({ type: 'expertises', toAdd, toDelete })
            }
            onLoad={onLoadExpertisesList}
            onRequestClose={this.handleCloseModal}
          />
        )
      case 'subExpertises':
        const { subExpertises, userSubExpertises } = this.props
        const { changedExpertiseId } = this.state

        const userSubExpKey = Object.keys(userSubExpertises).find(
          key => Number(key) === Number(changedExpertiseId)
        )

        const userSubExp = userSubExpertises[userSubExpKey]

        return (
          <SkillsModal
            allItems={subExpertises}
            isOpen={true}
            placeholder="Search For SubExpertises"
            subTitle="selected subExpertises"
            title="Edit subExpertises"
            userItems={userSubExp}
            onEditComplete={({ toAdd, toDelete }) =>
              this.handleSaveClick({ type: 'subExpertises', toAdd, toDelete })
            }
            onRequestClose={this.handleCloseModal}
          />
        )
      default:
        console.warn('modal type is not valid! type: ', openedModalType)
    }
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
      openedModalType: null,
      changedExpertiseId: null
    })
  }

  handleSaveClick = ({ type, toAdd, toDelete, expId }) => {
    switch (type) {
      case 'expertises':
        const { onEditExpertises } = this.props
        onEditExpertises({ toAdd, toDelete })
        break
      case 'subExpertises':
        const { onEditSubExpertises } = this.props
        const { changedExpertiseId } = this.state
        onEditSubExpertises({ toAdd, toDelete, expId: changedExpertiseId })
        break
      default:
        // console.log(toAdd, toDelete, expId)
        console.warn('save type is not valid! type: ', type)
        break
    }
  }

  handleRatingClick = (nextValue, prevValue, id) => {
    const { onUpdateSubExpertises } = this.props
    onUpdateSubExpertises({ subExpId: id, level: nextValue })
  }

  renderSections = () => {
    const { userExpertises, userSubExpertises } = this.props

    return userExpertises.map(item => {
      const itemSubExp = Object.keys(userSubExpertises).find(
        key => Number(key) === Number(item.id)
      )
      return (
        <ExpertiseBlock
          items={userSubExpertises[itemSubExp]}
          key={item.id}
          title={item.name}
          onClickAdd={() =>
            this.handleOpenModal({
              type: 'subExpertises',
              expId: item.id
            })
          }
          onRatingClick={this.handleRatingClick}
        />
      )
    })
  }

  render() {
    const { isModalOpen } = this.state
    return (
      <CareerTimeLineBlock title={"i'm good at"}>
        <Container>
          <AddButton
            value={'Add New Expertise'}
            onClickAdd={() => this.handleOpenModal({ type: 'expertises' })}
          />
          <Content>{this.renderSections()}</Content>
        </Container>
        {isModalOpen ? this.renderModal() : null}
      </CareerTimeLineBlock>
    )
  }
}

export default ImGoodAt
