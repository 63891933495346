import { createReducer } from 'Helpers/redux'

import { CHANGE_DATA } from 'Redux/actions/searchData'

const initialState = {
  searchStr: '',
  locStr: '',
  kms: '',
  industry: null
}

const handlers = {
  [CHANGE_DATA]: (state, action) => {
    return {
      ...state,
      searchStr: action.searchStr,
      locStr: action.locStr,
      kms: action.kms,
      industry: action.kms
    }
  }
}

export default createReducer(initialState, handlers)
