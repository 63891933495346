export const perfectScrollbarHideAll = (el, isReactComponent) => {
  if (isReactComponent) {
    el.children[0].children[el.children[0].children.length - 2].style.display =
      'none'
    el.children[0].children[
      el.children[0].children.length - 2
    ].children[0].style.display =
      'none'
    el.children[0].children[el.children[0].children.length - 1].style.display =
      'none'
    el.children[0].children[
      el.children[0].children.length - 1
    ].children[0].style.display =
      'none'
  } else {
    el.children[el.children.length - 2].style.display = 'none'
    el.children[el.children.length - 2].children[0].style.display = 'none'
    el.children[el.children.length - 1].style.display = 'none'
    el.children[el.children.length - 1].children[0].style.display = 'none'
  }
}
