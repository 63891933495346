import { connect } from 'react-redux'
import Component from './PublicCareerTimeLine'
import { createStructuredSelector } from 'reselect'

import { fetchUserById } from 'Redux/actions/user'

import {
  getUserAge,
  getUserInfo,
  getUserExperience,
  // time line
  getUserAcademicQualifications,
  getUserProfQualf,
  getUserActivities,
  getUserExpertises,
  // --
  getFetchUserLoadInfo,
  getUserEducation,
  getUserFeedbacks
} from 'Redux/selectors/publicUser'

const selector = createStructuredSelector({
  userAge: getUserAge,
  userInfor: getUserInfo,
  userEducation: getUserEducation,
  experience: getUserExperience,
  academQualif: getUserAcademicQualifications,
  profQualif: getUserProfQualf,
  activities: getUserActivities,
  loadInfo: getFetchUserLoadInfo,
  userFeedbacks: getUserFeedbacks,
  userExpertises: getUserExpertises,
})

const actionCreators = {
  fetchUserById
}

export default connect(
  selector,
  actionCreators
)(Component)
