import styled, { keyframes } from 'styled-components'
import * as colors from '../../../Theme/colors'
import { headShake, fadeIn, bounceIn } from 'react-animations'

const backgroundAnim = keyframes`${fadeIn}`
export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999 !important;
  background: ${colors.bluePurpleGradient};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 0.3s ${backgroundAnim};
`
const containerAnim = keyframes`${bounceIn}`
export const Container = styled.div`
  width: 400px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 40px 0 40px 0;
  font-size: 17px;
  align-items: center;
  border-radius: 4px;
  animation: 0.3s ${containerAnim};
  @media screen and (max-width: 500px) {
    width: 95%;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  font-size: 1em;
  color: #757575;
  position: relative;
  margin: 0 0 30px;
  align-self: stretch;
`
export const Title = styled.p`
  font-size: 0.9em;
  font-weight: normal;
  margin: 0;
  color: #757575;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`
export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`
const button = `
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 90px;
  height: 30px;
  font-family: "Nunito";
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  margin: 0 10px;
  &:focus {
    outline: none;
  }
`
export const YesButton = styled.input.attrs({ type: 'button', value: 'yes' })`
  ${button};
  background: ${colors.mainBlue};
`
export const NoButton = styled.input.attrs({ type: 'button', value: 'no' })`
  ${button};
  background: ${colors.buttonGreen};
`
