import React from 'react'
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom'
import * as routes from '../../../Constants/routes'

import { Button } from 'antd'

import {
  Container,
  SiteLogo,
  ButtonContainer,
  LinkContainer,
  Image,
  LogoContainer,
  AnimationBlock,
  ArrowSpan,
  TextContainer
} from './styled'

import logo from 'images/header/MrJobsLogo.png'
import { getCompanyBackground } from 'Helpers/companyBackground'

const RedirectPage = () => {
  const activeCard = JSON.parse(sessionStorage.getItem('redirect_data'))
  if (!activeCard) {
    return <Redirect to={routes.initial} />
  }

  const redirect = () => {
    sessionStorage.removeItem('redirect_data')
    window.open(activeCard.link, '_self')
  }
  setTimeout(() => {
    redirect()
  }, 5000)

  return (
    <Container>
      <LogoContainer>
        <LinkContainer>
          <Link to={routes.initial}>
            <SiteLogo>
              <img alt="" src={logo} />
            </SiteLogo>
          </Link>
        </LinkContainer>
        <AnimationBlock>
          <ArrowSpan delay={'-1s'} />
          <ArrowSpan delay={'-1.7s'} />
          <ArrowSpan delay={'-2.5s'} />
        </AnimationBlock>
        <LinkContainer>
          <Image src={getCompanyBackground(activeCard.image)} />
        </LinkContainer>
      </LogoContainer>
      <TextContainer>Redirect text</TextContainer>
    </Container>
  )
}

RedirectPage.propTypes = {
  activeCard: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default RedirectPage
