import styled from 'styled-components'
import * as colors from '../../../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  max-width: 400px;
  padding: 0 20px 0 0;
  @media screen and (max-width: 1200px) {
    max-width: initial;
    width: 100%;
    padding: 0;
  }
`

export const Title = styled.h2`
  font-size: 25px;
  color: ${colors.careerBlue};
`

export const ContactBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 0 0 10px;
  border: solid 1px ${colors.chatPurple};
  border-radius: 10px;
  cursor: ${p => (p.disable ? 'default' : 'pointer')};
  position: relative;
`

export const Icon = styled.i`
  color: ${colors.chatPurple};
`

export const Text = styled.p`
  margin: 0;
  padding: 0 0 0 15px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
