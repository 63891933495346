import React, { PureComponent } from 'react'
import moment from 'moment'
import { RangePicker } from 'antd/lib/date-picker'
import 'antd/lib/date-picker/style/css'
import { TextBox } from 'Components/Blocks'
import * as colors from 'Theme/colors'
import {
  Container,
  TextInfo,
  RangepickerContainer,
  TextBoxContainer,
  CloseBtn
} from './styles'

export default class EducationCard extends PureComponent {
  render() {
    const {
      card,
      onSchoolChange,
      onRangeChange,
      onInfoChange,
      onDegreeChange,
      onDelete
    } = this.props

    const now = moment(moment.now(), 'x')
    return (
      <Container>
        <CloseBtn onClick={onDelete}>
          <i className="fas fa-times" />
        </CloseBtn>
        <TextBoxContainer>
          <TextBox
            borderColor={colors.stepGrey}
            placeholder="school"
            value={card.school}
            warning={card.schoolWarning}
            onChange={onSchoolChange}
          />
        </TextBoxContainer>
        <TextBoxContainer>
          <TextBox
            borderColor={colors.stepGrey}
            placeholder="degree"
            value={card.degree}
            warning={card.degreeWarning}
            onChange={onDegreeChange}
          />
        </TextBoxContainer>
        <RangepickerContainer>
          <RangePicker
            disabledDate={date => date > now}
            popupStyle={{ zIndex: 99999 }}
            value={[card.startDate, card.endDate]}
            onChange={onRangeChange}
          />
        </RangepickerContainer>
        <TextInfo
          placeholder="description"
          value={card.info}
          onChange={onInfoChange}
        />
      </Container>
    )
  }
}
