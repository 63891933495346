import React from 'react'
import PropTypes from 'prop-types'
import { ListItemContainer, ListItemIcon, ListItemText, Icon } from './styled'

const SkillItem = ({ name, onDelete, faIcon }) => {
  return (
    <ListItemContainer>
      {onDelete ? (
        <ListItemIcon onClick={onDelete} />
      ) : (
        <Icon className={faIcon} />
      )}
      <ListItemText>{name}</ListItemText>
    </ListItemContainer>
  )
}

SkillItem.propTypes = {
  faIcon: PropTypes.string,
  name: PropTypes.string,
  onDelete: PropTypes.func
}

export { SkillItem }
