import styled from "styled-components";

export const Content = styled.div`
  margin: 24px 16px 15px;
`;
export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 13px 0 0 0;
  cursor: pointer;
`;
export const ListText = styled.div`
  color: #10101;
  font-size: 19.6px;
  margin-left: 24px;
  padding: 5px;
`;
