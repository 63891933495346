import React, { PureComponent } from 'react'
import find from 'lodash/find'
import {
  Container,
  Header,
  Title,
  CloseButton,
  TextBoxContainer,
  Icon,
  Input,
  ListTitle,
  List,
  ListItem,
  ItemTitle,
  ItemIcon,
  ListContainer
} from './styles'

export class Personalities extends PureComponent {
  renderItems = () => {
    const { items, selected } = this.props
    return items.map((el, i) => {
      const isChecked = find(selected, item => item === el.id)
      return (
        <ListItem
          selected={isChecked !== undefined}
          onClick={() => this.props.onItemClick(el.id)}
          key={i}
        >
          <ItemTitle>{el.name}</ItemTitle>
          <ItemIcon>
            <i
              className={
                isChecked ? 'fas fa-check-circle' : 'fas fa-plus-circle'
              }
            />
          </ItemIcon>
        </ListItem>
      )
    })
  }

  render() {
    return (
      <Container>
        <Header>
          <Title>PERSONALITIES</Title>
        </Header>
        <TextBoxContainer>
          <Icon>
            <i className="fas fa-search" />
          </Icon>
          <Input
            placeholder="Search For Traits"
            value={this.props.persText}
            onChange={this.props.onTextChange}
          />
        </TextBoxContainer>
        <ListTitle>Selected Traits:</ListTitle>
        <ListContainer>
          <List>{this.renderItems()}</List>
        </ListContainer>
      </Container>
    )
  }
}
