import styled, { keyframes, injectGlobal } from "styled-components";
import * as colors from "Theme/colors";
import { zoomIn, zoomOut } from "react-animations";
import CalendarComponent from "react-calendar";

injectGlobal`
  .input-range__label--max {
    display: none;
  }

  .input-range__label--min {
    display: none;
  }

  .input-range__label {
    font-family: 'Nunito'!important;
    font-size: 12px!important;
    transform: translateY(-10px)!important
  }
`;

export const Search = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  height: 30px;
  border-radius: 30px;
  margin-bottom: 10px;
`;
export const SearchInput = styled.input`
  border: none;
  outline: none;
  color: black;
`;
export const LocationList = styled.div`
  position: relative;
  border: 1px solid lightgray;
  border-radius: 6px;
  background: #fff;
  max-height: 170px;
  overflow-y: auto;
  ${"" /* ::-webkit-scrollbar {
    width: 5px;
  } */}
  z-index: 10;
  ${(props) => props.maxHeight && "max-height: 80px;"};
`;
export const CityOption = styled.li`
  color: ${(props) => (props.active ? "rgb(90, 64, 162)" : "#545454")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    color: rgb(90, 64, 162);
  }
  cursor: pointer;
`;
/*export const Button = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    width: 90px;
    height: 30px;
    font-family: "Nunito";
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    margin: 0 10px;
    background: rgb(52,224,156);
`*/

export const Button = styled.button`
  background: #32e09c;
  width: 150px;
  height: 35px;
  border-radius: 15px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  outline: none;
  border: none;
`;

export const CloseBtn = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  position: relative;
  top: ${(props) => props.top};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  cursor: pointer;
  border-radius: 10px;
`;
export const LocationIcon = styled.div`
  background: #32e09c;
  width: 35px;
  height: 35px;
  border-radius: 7px;
  padding: 9px;
  color: white;
  -webkit-box-shadow: -2px 2px 35px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 2px 35px -6px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 2px 20px -6px rgba(0, 0, 0, 0.75);
`;
export const LocationInput = styled.input`
  border-radius: 30px;
  font-size: 13px;
  border: 1px solid lightgray;
  padding: 7px;
  outline: none;
  flex: 1;
  margin-left: 5px;
`;
export const DistanceFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 103px;
  z-index: 1;
`;
export const Kms = styled.div`
  color: #00b7fe;
  font-size: 26px;
  margin-right: 6px;
  margin-left: 6px;
`;
export const SalaryOption = styled.p`
  color: ${(props) => (props.active ? "rgb(90, 64, 162)" : "#545454")};
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (!props.isActive ? 'pointer' : 'not-allowed')};

  :hover {
    color: rgb(90, 64, 162);
  }
`;
export const SalaryRangeTitle = styled.p`
  color: #545454;
  margin: 25px 0 35px;
  font-size: 16px;
`;
const anim = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)
  }
`;
export const Loader = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid transparent;
  border-bottom-color: ${colors.mainBlue};
  border-right-color: ${colors.mainBlue};
  animation: 2s infinite ease-in-out ${anim};
  border-radius: 50%;
  display: inline-block;
  padding-top: 6px;
  padding-left: 6px;
  padding-right: 3px;
  align-self: center;
  margin: 0 3px;
`;
export const KmsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  right: 7px;
`;
export const KmsButtonTitle = styled.p`
color:#545454
  margin: 0;
  font-weight: bold;
  padding-top: 8px;
`;
export const DistanceIcon = styled.i`
  font-size: 25px;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    ${(props) => (props.disabled ? "" : "color: #00b7fe;")}
  }
`;
export const ButtonOkIcon = styled.i``;
export const DistanceHeader = styled.div`
  display: flex;
  justify-content: center;
`;
export const HeaderTitle = styled.h3`
  color: #00b7fe;
`;
export const HeaderIcon = styled.i``;
export const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 3px;
`;
export const DistanceQuestion = styled.p`
  font-size: ${(props) => (props.top ? "14px" : "16px")};
  margin-top: ${(props) => (props.top ? "5px" : "30px")};
`;
export const DateSinceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SalaryRangeContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;
export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;
export const FilterTitle = styled.h3`
  color: #00b7fe;
  margin-bottom: 0;
`;
export const SearchIcon = styled.i.attrs({ className: "fas fa-search" })`
  align-self: center;
  padding: 0 3px 0 6px;
`;
export const CheckedCitiesIcon = styled.i.attrs({
  className: "fas fa-check-circle",
})``;

const zoomInAnimation = keyframes`${zoomIn}`;
const zoomOutAnimation = keyframes`${zoomOut}`;

export const Container = styled.div`
  position: fixed;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 9;
  width: ${(props) => (props.location ? 287 : 280)}px;
  ${(p) => p.location && `transform: translateX(42%)`};
  min-height: ${(p) => (p.withScroll ? "initial" : "420px")};
  border-radius: 20px;
  padding: 10px ${(p) => (p.date ? 5 : 10)}px 10px 10px;
  font-family: 'Nunito';
  font-size: 15px;
  color: lightgrey;
  cursor: default;
  transition: opasity 0.3s;
  /*animation: 0.5s ${(p) =>
    p.isUnmount ? zoomOutAnimation : zoomInAnimation};*/
`;
export const SalaryIcon = styled.i.attrs({ className: "fas fa-check-circle" })`
  margin-right: 5px;
`;
export const SalaryOptionIcon = styled.i.attrs({
  className: "fas fa-check-circle",
})`
  margin-right: 5px;
`;
export const CloseBtnIcon = styled.i.attrs({ className: "fas fa-times" })``;
export const OkayBtnIcon = styled.i.attrs({
  className: "fas fa-check-circle",
})``;
export const DateContentContainer = styled.div`
  position: relative;
  padding-right: 5px;
  height: 100%;
`;
export const Calendar = styled(CalendarComponent)`
  margin-bottom: 10px;
`;
export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
`;
export const ButtonDisableLocation = styled.div`
  width: 250px;
  height: 35px;
  outline: none;
  border: none;
  display: flex;
  align-items: flex-start;
  position: absolute;
  left: 12px;
  bottom: 50px;
`;
export const DisableLocationText = styled.p`
  color: #656565;
  text-align: center;
  margin-left: 12px;
`;
export const JobForYouFilterTitle = styled.p`
  color: #545454;
  font-size: 15px;
  font-weight: bold;
`;
