import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { toast } from "react-toastify";
import { Input } from "antd";
import _ from "lodash";

import { api } from "Config/App";
import { getAvatarSrc } from "Helpers/avatar";
import base64Converter from "Helpers/base64Converter";
import withSizes from "react-sizes";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { passwordValidate } from "Constants/validation";
import * as colors from "Theme/colors";

import { UserEditInfoModal } from "../../../../Blocks";

import {
  AddressContainer,
  Avatar,
  AvatarContainer,
  Calendar,
  Circle,
  Container,
  Content,
  DownloadProfileButton,
  DownloadProfileButtonContainer,
  EditableField,
  FileInput,
  HR,
  Icon,
  IconContainer,
  InfoText,
  InfoUserAge,
  Item,
  Items,
  LocationIcon,
  LocationIconContainer,
  PrimaryText,
  SocialBlock,
  SocialEditBlock,
  SocialEditContainer,
  SocialEditField,
  SocialEditIcon,
  SocialIconContainer,
  SocialLink,
  UserInfoBlock,
  UserName,
  InfoUserGender,
  ChangeModalInput,
  EditIcon,
  DobBlock,
  DobBlockForm,
  InputAge,
  SelectMonth,
  InputYear,
  ErrorMessage,
  Preferences,
  PasswordCheckField,
  PasswordEditField,
  PasswordEditBlock,
} from "./style";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

class UserInfo extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      name,
      location,
      info,
      linkedin,
      lat,
      lng,
      twitter,
      facebook,
      dob,
      avatar: userAvatar,
    } = props.user;
    const check = dob && moment(dob, "YYYY/MM/DD");

    const state = {
      avatar: null,
      userAge: moment().year() - moment(props.user.dob).year(),
      dob,
      years: null,
      locationValue: location,
      location,
      lat,
      lng,
      name,
      info,
      day: dob && check.format("D"),
      month: dob && check.format("MM"),
      year: dob && check.format("YYYY"),
      socialLink: {
        facebook: facebook || null,
        twitter: twitter || null,
        linkedin: linkedin || null,
      },
      newAvatar: null,
      isModalOpen: false,
      changeModalType: null,
      isDay: false,
      isMonth: false,
      isYear: false,
      errorMessage: "",
      gender: "Male",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      passwordWarning: false,
      cPasswordWarning: false,
    };

    if (dob) {
      const now = moment(moment.now(), "x").year();
      const born = moment(dob).year();
      state.years = now - born;
    }

    if (userAvatar) {
      state.avatar = `${api.url}/images/avatars/${userAvatar}`;
    }

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      const {
        name,
        location,
        info,
        linkedin,
        lat,
        lng,
        twitter,
        facebook,
        dob,
        avatar: userAvatar,
      } = this.props.user;

      const nextState = {
        avatar: null,
        userAge: moment().year() - moment(dob).year(),
        dob,
        years: null,
        locationValue: location,
        location,
        lat,
        lng,
        name,
        info,
        day: undefined,
        month: undefined,
        year: undefined,
        socialLink: {
          facebook: facebook || null,
          twitter: twitter || null,
          linkedin: linkedin || null,
        },
      };

      if (dob) {
        const now = moment(moment.now(), "x").year();
        const born = moment(dob).year();
        const check = moment(dob, "YYYY/MM/DD");
        nextState.years = now - born;
        nextState.day = check.format("D");
        nextState.month = check.format("MM");
        nextState.year = check.format("YYYY");
      }

      if (userAvatar) {
        nextState.avatar = `${api.url}/images/avatars/${userAvatar}`;
      }

      this.setState({ ...nextState });

      // update local storage
      if (prevProps.user.avatar !== this.props.user.avatar) {
        const emails = localStorage.getItem("saved_emails");
        const parsedEmail = emails ? emails.split(", ") : [];
        const avatars = localStorage.getItem("saved_avatars");
        const parsedAvatars = avatars ? avatars.split(", ") : [];
        const users = parsedEmail.map((email, i) => {
          return {
            email,
            avatar: parsedAvatars[i] ? parsedAvatars[i] : null,
          };
        });
        const updateUsers = users.map((el) =>
          el.email === this.props.user.email
            ? { ...el, avatar: this.props.user.avatar }
            : el
        );
        const savedEmails = updateUsers.map((el) => el.email);
        const savedAvatars = updateUsers.map((el) => el.avatar);
        localStorage.removeItem("saved_emails");
        localStorage.removeItem("saved_avatars");
        localStorage.setItem("saved_emails", savedEmails.join(", "));
        localStorage.setItem("saved_avatars", savedAvatars.join(", "));
      }
    }
  }

  handleOpenEditModal = (type) => {
    this.setState({
      isModalOpen: true,
      changeModalType: type || null,
    });
  };

  handleCloseModal = (type) => {
    const { user } = this.props;

    switch (type) {
      case "password":
        this.setState({
          isModalOpen: false,
          changeModalType: null,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        break;
      case "address":
        this.setState({
          isModalOpen: false,
          changeModalType: null,
          location: user.location,
          lat: user.lat,
          lng: user.lon,
          locationValue: user.location,
        });
        break;
      case "age":
        const check = user.dob && moment(user.dob, "YYYY/MM/DD");
        this.setState({
          isModalOpen: false,
          changeModalType: null,
          userAge: moment().year() - moment(user.dob).year(),
          dob: user.dob,
          errorMessage: "",
          isDay: false,
          isMonth: false,
          isYear: false,
          day: user.dob && check.format("D"),
          month: user.dob && check.format("MM"),
          year: user.dob && check.format("YYYY"),
        });
        break;
      case "gender":
        this.setState({
          isModalOpen: false,
          changeModalType: null,
        });
        break;
      case "social":
        this.setState((state) => ({
          socialLink: {
            ...state.socialLink,
            facebook: user.facebook,
            linkedin: user.linkedin,
            twitter: user.twitter,
          },
          isModalOpen: false,
          changeModalType: null,
        }));
        break;
      case "name":
        this.setState({
          isModalOpen: false,
          changeModalType: null,
          name: user.name,
        });
        break;
      default:
        this.setState({
          isModalOpen: false,
          changeModalType: null,
        });
    }
  };

  validationForm = () => {
    const { day, month, year } = this.state;
    const maxNumberDays = this.daysInMonth(month, year);
    const maxValidYear = new Date().getFullYear() - 10;
    const minValidYear = new Date().getFullYear() - 110;
    let errorMessage = "";
    let { isDay, isMonth, isYear } = false;

    if (!day || !month || !year) {
      errorMessage = `The field of the
    ${!day ? '"Day" ' : ""}   
    ${!month ? '"Month" ' : ""} 
    ${!year ? '"Year" ' : ""}
    can not be empty`;
      isDay = !day;
      isMonth = !month;
      isYear = !year;
    } else if (day > maxNumberDays) {
      isDay = true;
      errorMessage = "There are not so many days in this month";
    } else if (year < minValidYear || year > maxValidYear) {
      isYear = true;
      errorMessage = `year not valid (time range must be ${new Date().getFullYear() -
        110} - ${new Date().getFullYear() - 10})`;
    } else {
      errorMessage = "";
      isDay = false;
      isMonth = false;
      isYear = false;
    }
    this.setState({ errorMessage, isDay, isMonth, isYear });
    return errorMessage;
  };

  validationPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    const passValidated = passwordValidate(newPassword, confirmPassword);
    let passwordWarning = false;
    let cPasswordWarning = false;

    switch (passValidated) {
      case "lengthError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, include Uppercase and lowercase letters, numbers and '@' sign"
        );
        break;
      case "numberError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, include Uppercase and lowercase letters, numbers and '@' sign"
        );
        break;
      case "loverCaseError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, include Uppercase and lowercase letters, numbers and '@' sign"
        );
        break;
      case "upperCaseError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, include Uppercase and lowercase letters, numbers and '@' sign"
        );
        break;
      case "specialCharactersError":
        passwordWarning = true;
        toast.error(
          "Password should contain 8 characters, include Uppercase and lowercase letters, numbers and '@' sign"
        );
        break;
      case "passwordsNoMatch":
        cPasswordWarning = true;
        toast.error("Passwords does not match");
        break;
      default:
        passwordWarning = false;
        cPasswordWarning = false;
    }
    this.setState({
      passwordWarning,
      cPasswordWarning,
    });
    return cPasswordWarning || passwordWarning;
  };

  handleSaveClick = (type) => {
    const { onUpdateCandidate, onUpdateCandidatePassword, user } = this.props;
    const {
      name,
      dob,
      location,
      newAvatar,
      lat,
      lng,
      day,
      month,
      year,
      gender,
      passwordWarning,
      cPasswordWarning,
    } = this.state;
    switch (type) {
      case "name":
        onUpdateCandidate({ name });
        break;
      case "avatar":
        onUpdateCandidate({ avatar: newAvatar });
        break;
      case "address":
        onUpdateCandidate({
          location: location,
          lat: lat,
          lon: lng,
        });
        break;
      case "age":
        const formatedDate = moment(new Date(year, month - 1, day)).format(
          "YYYY-MM-DD"
        );
        const error = this.validationForm();
        if (!error) {
          onUpdateCandidate({ dob: formatedDate });
          this.handleCloseModal();
        }
        break;
      case "gender":
        onUpdateCandidate({ gender });
        break;
      case "social":
        const { socialLink } = this.state;
        const reqObj = {};

        if (user.linkedin !== socialLink.linkedin) {
          if (socialLink.linkedin.length) {
            reqObj.linkedin = socialLink.linkedin;
            const validLinkedin = reqObj.linkedin.match(
              /https:\/\/(?:www\.linkedin\.com)*/gi
            );

            if (
              !validLinkedin ||
              validLinkedin[0] !== "https://www.linkedin.com"
            ) {
              toast.error("Format must be https://www.linkedin.com...");

              return;
            }
          } else {
            reqObj.linkedin = "null";
          }
        }
        if (user.facebook !== socialLink.facebook) {
          if (socialLink.facebook.length) {
            reqObj.facebook = socialLink.facebook;

            const validFacebook = reqObj.facebook.match(
              /https:\/\/(?:www\.facebook\.com)*/gi
            );

            if (
              !validFacebook ||
              validFacebook[0] !== "https://www.facebook.com"
            ) {
              toast.error("Format must be https://www.facebook.com...");

              return;
            }
          } else {
            reqObj.facebook = "null";
          }
        }
        if (user.twitter !== socialLink.twitter) {
          if (socialLink.twitter.length) {
            reqObj.twitter = socialLink.twitter;
            const validTwitter = reqObj.twitter.match(
              /https:\/\/(?:www\.twitter\.com)*/gi
            );
            if (!validTwitter || validTwitter[0] !== "https://www.twitter.com") {
              toast.error("Format must be https://www.twitter.com...");

              return;
            }
          } else {
            reqObj.twitter = "null";
          }
        }
        if (Object.keys(reqObj).length) onUpdateCandidate({ ...reqObj });
        break;
      default:
        console.warn("type is not valid! type: ", type);
        break;
      case "password":
        const oldPass = this.state.oldPassword;
        const newPass = this.state.confirmPassword;
        if (this.validationPassword()) {
          console.error("form is invalid");
        } else {
          onUpdateCandidatePassword({ oldPass, newPass });
          this.handleCloseModal("password");
        }
        break;
    }
    // all update
    // this.props.onUpdateCandidate({
    //   name: name,
    //   info: info,
    //   dob: dob,
    //   location: location,
    //   lat: lat,
    //   lon: lng,
    //   linkedin: socialLink.linkedin,
    //   avatar: user.avatar && !newAvatar ? undefined : newAvatar
    // })

    if (type !== "age") {
      this.handleCloseModal();
    }
  };

  handleLocationTextChange = (address) => {
    this.setState({ locationValue: address });
  };

  handleChangeDateBorn = (date) => {
    const now = moment(moment.now(), "x").year();
    const dob = moment(date).year();
    const years = now - dob;
    const formatedDate = moment(date).format("YYYY-MM-DD");
    this.setState({
      userAge: years,
      dob: formatedDate,
    });
  };

  handleLocationSelect = async (address) => {
    const { location: prevLocation } = this.state;
    if (prevLocation !== address) {
      const geocode = await geocodeByAddress(address);
      const latLng = await getLatLng(geocode[0]);
      const { lat, lng } = latLng;
      this.setState({ locationValue: address, location: address, lat, lng });
    }
  };

  handleChangeAvatar = async (e) => {
    const files = e.target.files;
    const isLt2M = files[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("Image must smaller than 2MB!");
      // tost.error("");
    } else {
      if (files.length) {
        const file = files[0];
        const convertRes = await base64Converter(file);
        this.setState({ newAvatar: convertRes }, () =>
          this.handleSaveClick("avatar")
        );
      }
    }
  };

  handleChangeSocialLinks = ({ target }) => {
    const { name, value } = target;
    this.setState((prevState) => ({
      socialLink: {
        ...prevState.socialLink,
        [name]: value,
      },
    }));
  };

  handleChangeName = (name) => this.setState({ name });

  handleChangeMonth = (e) => {
    this.setState({ month: e.target.value });
  };

  daysInMonth = (month, year) => {
    const monthNum = new Date(Date.parse(month + " 1," + year)).getMonth() + 1;
    return new Date(year, monthNum, 0).getDate();
  };

  handleChangeDay = (e) => {
    // this.setState({day: e.target.value.replace(/[^-0-9]/gim, '')})
    this.setState({ day: e.target.value.replace(/^0|[^\d]/g, "") });
  };

  handleChangeYear = (e) => {
    this.setState({ year: e.target.value.replace(/[^-0-9]/gim, "") });
  };

  handleChangeGender = ({ target }) => this.setState({ gender: target.value });

  renderChangeModal = () => {
    const { changeModalType, gender } = this.state;
    switch (changeModalType) {
      case "name":
        const { name } = this.state;
        return (
          <UserEditInfoModal
            title={"Edit Your Name"}
            width={300}
            onCloseModal={() => this.handleCloseModal("name")}
            onSaveClick={() => this.handleSaveClick("name")}
            isName
            changeItemId
            dataState={this.state.name}
            dataProps={this.props.user.name}
          >
            <ChangeModalInput
              maxLength={50}
              value={name}
              onChange={(e) => this.handleChangeName(e.target.value)}
            />
          </UserEditInfoModal>
        );
      case "address":
        const { locationValue } = this.state;
        return (
          <UserEditInfoModal
            title={"Edit Location"}
            type={"address"}
            width={300}
            onCloseModal={() => this.handleCloseModal("address")}
            onSaveClick={() => this.handleSaveClick("address")}
          >
            <PlacesAutocomplete
              value={locationValue}
              onChange={this.handleLocationTextChange}
              onSelect={this.handleLocationSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <React.Fragment>
                  <EditableField
                    {...getInputProps({
                      placeholder: "Enter address",
                    })}
                  />
                  <LocationIconContainer>
                    <LocationIcon />
                  </LocationIconContainer>
                  {suggestions.length ? (
                    <Items>
                      {suggestions.map((location) => {
                        return (
                          <Item
                            {...getSuggestionItemProps(location, {
                              key: location.placeId,
                              title: location.description,
                            })}
                          >
                            <IconContainer>
                              <i className="fas fa-map-marker-alt" />
                            </IconContainer>
                            <PrimaryText>{location.description}</PrimaryText>
                          </Item>
                        );
                      })}
                    </Items>
                  ) : null}
                </React.Fragment>
              )}
            </PlacesAutocomplete>
          </UserEditInfoModal>
        );
      case "age":
        return (
          <UserEditInfoModal
            title={"Edit User Age"}
            type={"age"}
            width={400}
            onCloseModal={() => this.handleCloseModal("age")}
            onSaveClick={() => this.handleSaveClick("age")}
            isUserInfo
            changeItemId
            dataState={this.state}
            dataProps={this.props.user}
          >
            {/* <Calendar
              maxDate={new Date()}
              view="decade"
              onChange={this.handleChangeDateBorn}
            /> */}
            <DobBlock>
              <DobBlockForm>
                <InputAge
                  maxlength="2"
                  placeholder="Day"
                  style={{
                    borderColor: this.state.isDay ? "red" : colors.chatPurple,
                  }}
                  value={this.state.day}
                  onChange={this.handleChangeDay}
                />
                <SelectMonth
                  placeholder="Month"
                  style={{
                    borderColor: this.state.isMonth ? "red" : colors.chatPurple,
                  }}
                  value={this.state.month}
                  onChange={this.handleChangeMonth}
                >
                  <option disabled hidden selected value="">
                    Month
                  </option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </SelectMonth>

                <InputYear
                  maxlength="4"
                  placeholder="Year"
                  style={{
                    borderColor: this.state.isYear ? "red" : colors.chatPurple,
                  }}
                  value={this.state.year}
                  onChange={this.handleChangeYear}
                />
              </DobBlockForm>
              {this.state.errorMessage && (
                <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
              )}
            </DobBlock>
          </UserEditInfoModal>
        );
      case "gender":
        return (
          <UserEditInfoModal
            title={"Edit User Gender"}
            type={"gender"}
            width={400}
            isUserInfo
            onCloseModal={() => this.handleCloseModal("gender")}
            onSaveClick={() => this.handleSaveClick("gender")}
            dataState={this.state}
            dataProps={this.props.user}
          >
            <RadioGroup
              className="group"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
              row
              value={gender}
              onChange={this.handleChangeGender}
            >
              <FormControlLabel
                classes={{
                  label: "radioBtn",
                }}
                control={<Radio color="primary" />}
                label={<Typography style={{ fontSize: 12 }}>Male</Typography>}
                value={"Male"}
              />
              <FormControlLabel
                classes={{
                  label: "radioBtn",
                }}
                control={<Radio />}
                label={<Typography style={{ fontSize: 12 }}>Female</Typography>}
                value={"Female"}
              />
            </RadioGroup>
          </UserEditInfoModal>
        );
      case "social":
        const { socialLink } = this.state;
        const { facebook, linkedin, twitter } = this.props.user;
        return (
          <UserEditInfoModal
            title={"Edit Social Links"}
            type={"social"}
            width={400}
            onCloseModal={() => this.handleCloseModal("social")}
            onSaveClick={() => this.handleSaveClick("social")}
            idSocial
            changeItemId
            dataState={socialLink}
            dataProps={{ facebook, twitter, linkedin }}
          >
            <SocialEditContainer>
              <SocialEditBlock>
                <SocialEditIcon
                  active={
                    socialLink.linkedin &&
                    socialLink.linkedin.length &&
                    socialLink.linkedin !== "null"
                  }
                  className={"fab fa-linkedin"}
                />
                <SocialEditField
                  name={"linkedin"}
                  value={
                    socialLink.linkedin !== "null" ? socialLink.linkedin : ""
                  }
                  onChange={this.handleChangeSocialLinks}
                />
              </SocialEditBlock>
              <SocialEditBlock>
                <SocialEditIcon
                  active={
                    socialLink.facebook &&
                    socialLink.facebook.length &&
                    socialLink.facebook !== "null"
                  }
                  className={"fab fa-facebook"}
                />
                <SocialEditField
                  name={"facebook"}
                  value={
                    socialLink.facebook !== "null" ? socialLink.facebook : ""
                  }
                  onChange={this.handleChangeSocialLinks}
                />
              </SocialEditBlock>
              <SocialEditBlock>
                <SocialEditIcon
                  active={
                    socialLink.twitter &&
                    socialLink.twitter.length &&
                    socialLink.twitter !== "null"
                  }
                  className={"fab fa-twitter-square"}
                />
                <SocialEditField
                  name={"twitter"}
                  value={
                    socialLink.twitter !== "null" ? socialLink.twitter : ""
                  }
                  onChange={this.handleChangeSocialLinks}
                />
              </SocialEditBlock>
            </SocialEditContainer>
          </UserEditInfoModal>
        );
      case "password":
        return (
          <UserEditInfoModal
            title={"Change Password"}
            type={"password"}
            width={400}
            onCloseModal={() => this.handleCloseModal("password")}
            onSaveClick={() => this.handleSaveClick("password")}
            isPassword
          >
            <PasswordEditBlock>
              <PasswordCheckField
                placeholder="Current password"
                value={this.state.oldPassword}
                onChange={(e) => this.setState({ oldPassword: e.target.value })}
              />
            </PasswordEditBlock>
            <PasswordEditBlock>
              <PasswordEditField
                placeholder="New password"
                value={this.state.newPassword}
                warning={this.state.passwordWarning}
                onChange={(e) => this.setState({ newPassword: e.target.value })}
              />
            </PasswordEditBlock>
            <PasswordEditBlock>
              <PasswordEditField
                placeholder="Confirm new password"
                value={this.state.confirmPassword}
                warning={this.state.cPasswordWarning}
                onChange={(e) =>
                  this.setState({ confirmPassword: e.target.value })
                }
              />
            </PasswordEditBlock>
          </UserEditInfoModal>
        );
      default:
        console.warn("change modal not opened!");
        break;
    }
  };

  renderSocialIcons = () => {
    const { user } = this.props;

    if (user.role === "company") {
      return (
        <SocialBlock>
          {user.facebook ? (
            <SocialLink href={user.facebook}>
              <Icon className={"fab fa-facebook"} />
            </SocialLink>
          ) : null}
          {user.linkedin ? (
            <SocialLink href={user.linkedin}>
              <Icon className={"fab fa-linkedin"} />
            </SocialLink>
          ) : null}
          {user.twitter ? (
            <SocialLink href={user.twitter}>
              <Icon className={"fab fa-twitter-square"} />
            </SocialLink>
          ) : null}
        </SocialBlock>
      );
    } else {
      return (
        <SocialBlock>
          {user.facebook && user.facebook.length && user.facebook !== "null" ? (
            <SocialIconContainer>
              <Icon
                className={"fab fa-facebook"}
                disabled={
                  !user.facebook ||
                  !user.facebook.length ||
                  user.facebook === "null"
                }
                onClick={() => this.handleOpenEditModal("social")}
              />
            </SocialIconContainer>
          ) : null}
          {user.linkedin && user.linkedin.length && user.linkedin !== "null" ? (
            <SocialIconContainer>
              <Icon
                className={"fab fa-linkedin"}
                disabled={
                  !user.linkedin ||
                  !user.linkedin.length ||
                  user.linkedin === "null"
                }
                onClick={() => this.handleOpenEditModal("social")}
              />
            </SocialIconContainer>
          ) : null}
          {user.twitter && user.twitter.length && user.twitter !== "null" ? (
            <SocialIconContainer>
              <Icon
                className={"fab fa-twitter-square"}
                disabled={
                  !user.twitter ||
                  !user.twitter.length ||
                  user.twitter === "null"
                }
                onClick={() => this.handleOpenEditModal("social")}
              />
            </SocialIconContainer>
          ) : null}
        </SocialBlock>
      );
    }
  };

  renderAvatar = () => {
    const { avatar: stateAvatar, newAvatar, isEditAvatar } = this.state;
    const { user } = this.props;
    let src;
    if (newAvatar) {
      src = newAvatar;
    } else if (stateAvatar && isEditAvatar) {
      src = stateAvatar;
    } else {
      src = getAvatarSrc(user.avatar);
    }

    return (
      <AvatarContainer>
        <Avatar src={src} />
        <FileInput onChange={this.handleChangeAvatar} />
      </AvatarContainer>
    );
  };

  renderLocation = () => {
    const { user } = this.props;
    return user.location
      .split(", ")
      .slice(-2)
      .join(", ");
  };

  renderContent = () => {
    const { user, isBigScreen } = this.props;
    const { isModalOpen } = this.state;

    const now = moment(moment.now(), "x").year();
    const born = moment(user.dob).year();
    const years = now - born;

    const isSocial =
      (user.facebook && user.facebook !== "null") ||
      (user.twitter && user.twitter !== "null") ||
      (user.linkedin && user.linkedin !== "null");

    const buttonValue =
      user.role === "company"
        ? "Download Portfolio"
        : isSocial
        ? "Add Links"
        : "Add Social Links";
    const buttonFunc =
      user.role === "company"
        ? () => {}
        : () => this.handleOpenEditModal("social");

    return (
      <Container isBigScreen={isBigScreen}>
        <Content>
          <Preferences
            className={"fas fa-cog"}
            onClick={() => this.handleOpenEditModal("password")}
          />
          {this.renderAvatar()}
          <UserName onClick={() => this.handleOpenEditModal("name")}>
            {user.name}
          </UserName>
          <UserInfoBlock>
            <InfoUserAge onClick={() => this.handleOpenEditModal("age")}>
              Age: {isNaN(years) ? "" : years}
              <EditIcon className={"fas fa-edit"} />
            </InfoUserAge>
            <InfoUserGender onClick={() => this.handleOpenEditModal("gender")}>
              Gender: {user.gender ? _.capitalize(user.gender) : null}
              <EditIcon className={"fas fa-edit"} />
            </InfoUserGender>
          </UserInfoBlock>
          <AddressContainer>
            <InfoText>
              {user.location !== "null"
                ? this.renderLocation()
                : "Add Location"}
            </InfoText>
            {/*<EditIcon className={'fas fa-edit'}/>*/}
          </AddressContainer>
          {/*<HR/>*/}
          {this.renderSocialIcons()}
          <DownloadProfileButtonContainer>
            <DownloadProfileButton value={buttonValue} onClick={buttonFunc} />
          </DownloadProfileButtonContainer>
        </Content>
        {isModalOpen ? this.renderChangeModal() : null}
      </Container>
    );
  };

  render() {
    const { isBigScreen } = this.props;

    if (isBigScreen) {
      return ReactDOM.createPortal(
        this.renderContent(),
        document.getElementById("sidebarContainer")
      );
    } else {
      return this.renderContent();
    }
  }
}

const mapSizeToProps = ({ width }) => ({
  isBigScreen: width >= 1600,
});

export default withSizes(mapSizeToProps)(UserInfo);
