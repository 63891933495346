import { GET_CONTACTS } from '../actions/contacts'
import { createReducer } from 'Helpers/redux'

const initialState = {
  contacts: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const reducer = {
  [GET_CONTACTS.REQUEST]: (state) => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        error: null
      }
    }
  },
  [GET_CONTACTS.SUCCESS]: (state, { payload }) => {
    return {
      contacts: payload.data,
      loadInfo: {
        isLoading: false,
        isLoaded: true
      }
    }
  }
}
export default createReducer(initialState, reducer)
