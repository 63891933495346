import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { headerCatsInfo } from '../../../Redux/selectors/main'
import { deleteHeaderCat } from '../../../Redux/actions/main'

import {
  loadUserJobs,
  setActiveVacancy,
  loadSavedVacancies,
  saveVacancy,
  deleteFromSavedVacancies,
  updateFilter,
  resetSearchJobs,
  changeJobTab,
  loadSalaryRange,
  getIpGeolocation
} from 'Redux/actions/jobs'
import { loadCompanies } from 'Redux/actions/companies'
import { loadJobTypes } from 'Redux/actions/jobTypes'
// import { loadCities } from 'Redux/actions/cities'
import { loadOptions } from 'Redux/actions/options'
import { loadExpertises } from 'Redux/actions/expertises'
import {
  unfollowIndustry,
  loadIndustries,
  followIndustry
} from 'Redux/actions/industries'
import { switchModal } from '../../../Redux/actions/utils'
import { loadIndustriesMetaTags } from 'Redux/actions/metaTags'

import {
  getUserJobs,
  getJobsLoadInfo,
  getJobsFilter,
  getTab,
  getSalaryRange,
  getIpLocation
} from 'Redux/selectors/jobs'
import { getCompanies, getCompaniesLoadInfo } from 'Redux/selectors/companies'
import { getIndustries } from 'Redux/selectors/industries'
import { getJobTypes, getJobTypesLoadInfo } from 'Redux/selectors/jobTypes'
import { getCities, getCitiesLoadInfo } from 'Redux/selectors/cities'
import { getOptions, getOptionsLoadInfo } from 'Redux/selectors/options'
import { isAuthenticate } from 'Redux/selectors/auth'
import {
  getFollowedIndustries,
  getUserSavedVacancies,
  getUser
} from 'Redux/selectors/user'
import {
  getPredExpertises,
  getExpertisesLoadInfo
} from 'Redux/selectors/expertises'
import { getIndustriesMetaTags } from 'Redux/selectors/metaTags'
//

import Component from './Jobs'

const selectors = createStructuredSelector({
  headerCatsInfo: headerCatsInfo,
  userJobs: getUserJobs,
  jobsLoadInfo: getJobsLoadInfo,
  companies: getCompanies,
  companiesLoadInfo: getCompaniesLoadInfo,
  jobTypes: getJobTypes,
  jobTypesLoadInfo: getJobTypesLoadInfo,
  cities: getCities,
  citiesLoadInfo: getCitiesLoadInfo,
  options: getOptions,
  optionsLoadInfo: getOptionsLoadInfo,
  followedIndustries: getFollowedIndustries,
  expertises: getPredExpertises,
  expertisesLoadInfo: getExpertisesLoadInfo,
  savedVacancies: getUserSavedVacancies,
  isAuthenticate: isAuthenticate,
  filter: getJobsFilter,
  tab: getTab,
  salaryRange: getSalaryRange,
  user: getUser,
  getIpLocation,
  getIndustries,
  metaTags: getIndustriesMetaTags
})

const actions = {
  onUserJobsLoad: loadUserJobs,
  onSetActiveVacancy: setActiveVacancy,
  onDeleteHeaderCat: deleteHeaderCat,
  onCompaniesLoad: loadCompanies,
  onJobTypesLoad: loadJobTypes,
  //onCitiesLoad: loadCities,
  onOptionsLoad: loadOptions,
  onExpertisesLoad: loadExpertises,
  onSavedVacanciesLoad: loadSavedVacancies,
  onSaveVacancy: saveVacancy,
  onDeteFromSavedVacancy: deleteFromSavedVacancies,
  onFilterUpdate: updateFilter,
  onResetSearchJobs: resetSearchJobs,
  onChangeJobTab: changeJobTab,
  onIndustryUnfollow: unfollowIndustry,
  onSalaryRangeLoad: loadSalaryRange,
  onOpenModal: switchModal,
  onGetIpGeolocation: getIpGeolocation,
  onLoadIndustries: loadIndustries,
  onFollowIndustry: followIndustry,
  onLoadMeta: loadIndustriesMetaTags
}

export default connect(
  selectors,
  actions
)(Component)
