import { calcPosition } from './mountFilter'

export const unmountFilter = filterId => {
  const desktopYScrollContainer = document.getElementById(
    'contentScrollContainer'
  )
  const mobileYScrollContainer = document.getElementById('Push')
  const scrollXContainer = document.getElementsByClassName(
    'filtersContainer'
  )[0]
  scrollXContainer.removeEventListener('ps-scroll-x', calcPosition)
  if (desktopYScrollContainer) {
    desktopYScrollContainer.removeEventListener('ps-scroll-y', calcPosition)
  }
  if (mobileYScrollContainer) {
    mobileYScrollContainer.removeEventListener('ps-scroll-y', calcPosition)
  }
}
