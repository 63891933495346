import {
  GOOGLE_PLACES_KEY,
  googlePlaces,
  proxyUrl,
  googleGeocode
} from './constants'

export const googlePlacesRequest = (place, location, radius) =>
  new Promise((resolve, reject) => {
    const requestUrl = `${proxyUrl}${googlePlaces}?input=${place}&language=en&type=address${
      location ? '&location=' + location : ''
    }${radius ? 'radius' + radius : ''}&key=${GOOGLE_PLACES_KEY}`

    fetch(requestUrl)
      .then(resp => resp.json())
      .then(json => {
        resolve(json)
      })
      .catch(err => reject(err))
  })
export const getCoordsByPlaceId = placeId => {
  return new Promise((resolve, reject) => {
    fetch(
      `${proxyUrl}${googleGeocode}?place_id=${placeId}&key=${GOOGLE_PLACES_KEY}`
    )
      .then(resp => resp.json())
      .then(result => {
        const loc = result.results[0]
        if (loc) {
          const { lat, lng } = loc.geometry.location

          resolve({
            lat,
            lng
          })
        }
      })
  })
}

export const getCoordsByLatLon = (lat, lon) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${proxyUrl}https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat}%2C${lon}&language=en&key=${GOOGLE_PLACES_KEY}`
    )
      .then(resp => resp.json())
      .then(result => {
        let city
        const cityName1 = result.results.find(
          el => el.types[0] === 'administrative_area_level_2'
        )
        if (cityName1) {
          city = cityName1.formatted_address
        } else {
          city = result.results.find(el => el.types[0] === 'postal_code')
            .formatted_address
        }
        resolve({
          name: city,
          lat: lat,
          lng: lon
        })
      })
  })
}
