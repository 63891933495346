import React, { PureComponent } from "react";
import $ from "jquery";
import Spiner from "react-spinkit";
import { Window, IconBlock, SearchPanel } from "Components/Blocks";
import { search, building, uploadCloud } from "Assets/icons";
import * as icons from "../../../../../Assets/images/Filters";
import loading from "../../../../../images/loading.gif";

import { Description, Logo, List, ListItem, BlueButton } from "../blocks";
import {
  Content,
  ItemText,
  BottomBlock,
  CvContainer,
  UploadedPath,
  CvIconContainer,
  FileInput,
  SpinerContainer,
  CvBlock,
  BottomBlockBig,
  ContentMob,
} from "./styles";
import MediaQuery from "react-responsive";

import {
  ACCEPTED_MIME_TYPES,
  INPUT_ACCEPTED_FILE_TYPES,
} from "./acceptedFileTypes";

export default class CvWindow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSearchPanelVisible: false,
      // offset for SearchPanel
      top: 0,
      left: 0,
      uploadedPath: props.registerData.uploadedPath,
      companies: this.parseCompanies(props.companies),
      warnings: {
        companies: false,
        file: false,
      },
      selectedCompanies: [],
      searchStr: "",
      cvFile: props.registerData.cvFile,
      cvFileType: props.registerData.cvFileType,
    };
  }

  //eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.loadCompanies();
  }
  //eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { companies, registerData } = nextProps;
    const { cvFile, uploadedPath, cvFileType } = this.state;
    //console.log('registerData', registerData)
    this.setState({
      companies: this.parseCompanies(companies),
      cvFile: registerData.cvFile ? registerData.cvFile : cvFile,
      uploadedPath: registerData.uploadedPath
        ? registerData.uploadedPath
        : uploadedPath,
      cvFileType: registerData.cvFileType
        ? registerData.cvFileType
        : cvFileType,
    });
  }

  componentDidMount() {
    this.calcSearchPanelOffset();
    window.addEventListener("resize", this.calcSearchPanelOffset);

    //validate redux data
    const { companies } = this.props.registerData;
    const warnings = {
      companies: false,
      file: false,
    };

    if (companies.length) {
      const newSelectedCompanies = companies.map((item) => item.id);
      warnings.companies = false;
      this.setState({
        warnings: warnings,
        selectedCompanies: newSelectedCompanies,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcSearchPanelOffset);
  }

  renderSearchListItems = () => {
    const { selectedCompanies } = this.state;

    return this.state.companies.map((el, i) => {
      const selected = selectedCompanies.indexOf(el.id) >= 0;
      return (
        <ListItem key={i} onClick={() => this.handleCheckCategory(el.id)}>
          <IconBlock
            isCloseBtn={selected}
            borderColor="#bcbaba"
            checked={selected}
            iconName={icons.company}
          />
          <ItemText>{el.name}</ItemText>
        </ListItem>
      );
    });
  };

  selectAllHandler = () => {
    const { companies, warnings } = this.state;

    const checkedAll = companies.map((item) => {
      return item.id && this.state.selectedCompanies[item.id];
    });

    const checkedType = checkedAll.indexOf(undefined);

    if (checkedType >= 1) {
      this.setState({
        selectedCompanies: [],
        warnings: {
          ...warnings,
          companies: false,
        },
      });
    } else {
      const selectedCompanies = companies.map((el) => el.id);
      this.setState({
        selectedCompanies,
        warnings: {
          ...warnings,
          companies: false,
        },
      });
    }

    // const companies = this.state.companies.splice(0)
    // for (let i = 0; i < companies.length; i++) {
    //   companies[i].checked = !this.selectAllChecked
    // }
    // this.selectAllChecked = !this.selectAllChecked
    // this.setState({ companies })
  };

  handleCheckCategory = (id) => {
    const { selectedCompanies, warnings } = this.state;
    const findSelected = selectedCompanies.indexOf(id);
    if (findSelected !== -1) {
      const newSelectedArr = selectedCompanies.filter((el) => el !== id);
      this.setState({ selectedCompanies: newSelectedArr });

      if (newSelectedArr.length) {
        this.setState({
          warnings: {
            ...warnings,
            companies: false,
          },
        });
      } else {
        this.setState({
          warnings: {
            ...warnings,
            companies: false,
          },
        });
      }
    } else {
      const newArr = [...selectedCompanies, id];
      this.setState({
        selectedCompanies: newArr,
        warnings: {
          ...warnings,
          companies: false,
        },
      });
    }
  };

  handleChangeFile = ({ target }) => {
    const file = target.files[0];
    const { warnings } = this.state;

    if (ACCEPTED_MIME_TYPES.indexOf(file.type) === -1) {
      this.setState({
        uploadedPath:
          "Not valid file. Please select one of these: .pdf, .doc, .docx",
        warnings: {
          ...warnings,
          file: true,
        },
      });
      return false;
    } else {
      const { name } = file;
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.setState({
          warnings: {
            ...warnings,
            file: false,
          },
          uploadedPath: name,
          cvFile: Buffer.from(reader.result),
          cvFileType: file.type,
        });

        target.value = "";
      });

      reader.readAsArrayBuffer(file);
    }
  };

  handleToggleSearchPanel = () => {
    this.calcSearchPanelOffset();
    this.setState({ isSearchPanelVisible: !this.state.isSearchPanelVisible });
  };

  calcSearchPanelOffset = () => {
    if (!this.$searchItem) return;
    const { top, left } = this.$searchItem.position();
    this.setState({
      top: top + this.$searchItem.height(),
      left,
    });
  };

  parseCompanies = (items) => {
    return items.map((el) => ({
      name: el.name,
      id: el.id,
      checked: false,
    }));
  };

  handleNextBtn = () => {
    const checkedCompanies = [];
    const {
      selectedCompanies,
      companies,
      warnings,
      cvFile,
      cvFileType,
      uploadedPath,
    } = this.state;

    const {
      changeWindow,
      innerRoutes,
      completeCurrent,
      registerData,
      // cvParseState
    } = this.props;

    companies.forEach((item) => {
      if (selectedCompanies.indexOf(item.id) >= 0) {
        checkedCompanies.push({
          id: item.id,
          name: item.name,
        });
      }
    });

    if (registerData.oauthUser !== null) {
      const {
        categories,
        jobTypes,
        location,
        kms,
        lat,
        lng,
      } = this.props.registerData;

      const registObg = {
        userId: registerData.oauthUser.id,
        categories,
        jobTypes,
        companies,
        location,
        kms,
        lat,
        lng,
        cvFile,
        cvFileMimeType: cvFileType,
      };

      Promise.resolve(this.props.onRegister(registObg)).then((resp) => {
        if (resp.hasError) {
        } else {
          this.props.onClose();

          // if (cvParseState.request || cvParseState.success) {
          //   this.props.history.push('/editcv')
          // }
        }
      });

      return;
    }

    if (!warnings.companies) {
      completeCurrent({
        companies: checkedCompanies,
        cvFile,
        cvFileMimeType: cvFileType,
        uploadedPath,
      });
      // onParseCv(cvFile)
      changeWindow(innerRoutes.details);
    } else {
      console.warn("check categories or selected file");
    }
  };

  render() {
    const { changeWindow, innerRoutes } = this.props;
    const { warnings } = this.state;

    //const isNextDisabled = !selectedCompanies.length || !cvFile
    //validate check only categories

    return (
      <Window step={3} onClose={this.props.onClose}>
        <MediaQuery minDeviceWidth={1224} device={{ deviceWidth: 1600 }}>
          <Content>
            <Logo />
            <Description>
              In which companies are you interested to join
            </Description>
            {this.state.companies.length ? (
              <List name="scrollContainer">
                <div ref={(el) => (this.$searchItem = $(el))}>
                  <ListItem onClick={this.handleToggleSearchPanel}>
                    <IconBlock
                      borderColor="#bcbaba"
                      checked={this.state.isSearchPanelVisible}
                    >
                      {search}
                    </IconBlock>
                    <ItemText>Search Companies</ItemText>
                  </ListItem>
                </div>
                <SearchPanel
                  items={this.state.companies}
                  searchStr={this.state.searchStr}
                  selectAll={this.selectAllHandler}
                  selectedCatigories={this.state.selectedCompanies}
                  visible={this.state.isSearchPanelVisible}
                  companies
                  onCheck={this.handleCheckCategory}
                  onSearchChange={(e) =>
                    this.setState({ searchStr: e.target.value })
                  }
                />
                {this.renderSearchListItems()}
              </List>
            ) : (
              <SpinerContainer>
                {/* <Spiner name="ball-spin-fade-loader" /> */}
                <img
                  src={loading}
                  style={{ height: "80px", width: "80px" }}
                  alt="MrJOBS"
                />
              </SpinerContainer>
            )}
            {/* <CvBlock>
              <Description>Do you have a CV to upload</Description>
              <CvContainer>
                <CvIconContainer>{uploadCloud}</CvIconContainer>
                <FileInput
                  accept={INPUT_ACCEPTED_FILE_TYPES}
                  max={1}
                  name="foo"
                  type="file"
                  onChange={this.handleChangeFile}
                />
              </CvContainer>
            </CvBlock> */}
            <UploadedPath error={this.state.warnings.file}>
              {this.state.uploadedPath}
            </UploadedPath>
            <BottomBlockBig>
              <BlueButton onClick={() => changeWindow(innerRoutes.location)}>
                Back
              </BlueButton>
              <BlueButton
                disabled={warnings.companies}
                onClick={this.handleNextBtn}
              >
                Next
              </BlueButton>
            </BottomBlockBig>
          </Content>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <ContentMob>
            <Logo />
            <Description>
              In which companies are you interested to join
            </Description>
            {this.state.companies.length ? (
              <List name="scrollContainer">
                <div ref={(el) => (this.$searchItem = $(el))}>
                  <ListItem onClick={this.handleToggleSearchPanel}>
                    <IconBlock
                      borderColor="#bcbaba"
                      checked={this.state.isSearchPanelVisible}
                    >
                      {search}
                    </IconBlock>
                    <ItemText>Search Companies</ItemText>
                  </ListItem>
                </div>
                <SearchPanel
                  items={this.state.companies}
                  searchStr={this.state.searchStr}
                  selectAll={this.selectAllHandler}
                  selectedCatigories={this.state.selectedCompanies}
                  visible={this.state.isSearchPanelVisible}
                  companies
                  onCheck={this.handleCheckCategory}
                  onSearchChange={(e) =>
                    this.setState({ searchStr: e.target.value })
                  }
                />
                {this.renderSearchListItems()}
              </List>
            ) : (
              <SpinerContainer>
                {/* <Spiner name="ball-spin-fade-loader" /> */}
                <img
                  src={loading}
                  style={{ height: "80px", width: "80px" }}
                  alt="MrJOBS"
                />
              </SpinerContainer>
            )}
            {/* <CvBlock>
              <Description>Do you have a CV to upload</Description>
              <CvContainer>
                <CvIconContainer>{uploadCloud}</CvIconContainer>
                <FileInput
                  accept={INPUT_ACCEPTED_FILE_TYPES}
                  max={1}
                  name="foo"
                  type="file"
                  onChange={this.handleChangeFile}
                />
              </CvContainer>
            </CvBlock> */}
            <UploadedPath error={this.state.warnings.file}>
              {this.state.uploadedPath}
            </UploadedPath>
            <BottomBlock>
              <BlueButton onClick={() => changeWindow(innerRoutes.location)}>
                Back
              </BlueButton>
              <BlueButton
                disabled={warnings.companies}
                onClick={this.handleNextBtn}
              >
                Next
              </BlueButton>
            </BottomBlock>
          </ContentMob>
        </MediaQuery>
      </Window>
    );
  }
}
