import React, { Component, Fragment } from "react";
//import PropTypes from 'prop-types'

import { Element } from "react-scroll";

import { AddButton, ChangeModal, Card } from "../blocks";
import { getAcademQualifImage } from "../../../../../Helpers/getImages";

import { Container, CardsList } from "./styled";
import swal from "sweetalert";

export default class MyAcademicQualifications extends Component {
  static propTypes = {};

  constructor(props) {
    super();
    this.state = {
      isModalOpen: false,
      isDraft: false,
      changeItemId: null,
      acadmQualifId: null,
    };

    if (!props.academQulifList.length) props.onLoadAcademQualifList();
  }

  handleOpenModal = ({ id }) => {
    this.setState({
      isModalOpen: true,
      changeItemId: id || null,
    });
  };

  handleCloseModal = () => {
    const { isDraft } = this.state;
    this.setState({
      isModalOpen: false,
      changeItemId: null,
    });
    // if (!isDraft) {
    //   this.confirmModal();
    // } else {
    //   this.setState({
    //     isModalOpen: false,
    //     changeItemId: null,
    //   });
    // }
  };
  closeMainModal = () => {
    this.setState({
      isModalOpen: false,
      changeItemId: null,
    });
  };
  confirmModal = () => {
    swal({
      icon: "warning",
      text: "Are you sure you want to discard changes to this ?",
      buttons: {
        confirm: {
          text: "Discard",
          value: true,
          visible: true,
        },
        cancel: {
          text: "No Thanks",
          value: false,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) this.closeMainModal();
    });
  };
  handleSaveClick = (result) => {
    const { onEditAcademQuelif, onAddAcademQualif } = this.props;
    const { changeItemId } = this.state;

    const {
      title,
      subTitle,
      description,
      startDate,
      endDate,
      autocompleateItemId,
      currently,
    } = result;

    const reqObj = {
      acadmQualifId: autocompleateItemId,
      title,
      subTitle,
      description,
      startDate: `${startDate}-01`,
      endDate: `${endDate}-01`,
      id: changeItemId,
      currently,
    };

    if (changeItemId !== null) {
      onEditAcademQuelif({ ...reqObj });
    } else {
      onAddAcademQualif({ ...reqObj });
    }

    this.handleCloseModal();
  };

  handleDeleteClick = (id) => {
    const { onRemoveAcademQualif } = this.props;
    onRemoveAcademQualif(id);
  };

  handleLoadCompanyImage = (imageSrc) => {
    return getAcademQualifImage(imageSrc);
  };

  renderChangeModal = () => {
    const { userAcademQualif, academQulifList } = this.props;
    const { changeItemId } = this.state;

    const modalTitle =
      changeItemId === null
        ? "Add Academic Qualification"
        : "Edit Academic Qualification";
    const changedItem = userAcademQualif.find(
      (item) => item.id === changeItemId
    );

    const academQualif =
      changedItem &&
      academQulifList.find((el) => el.name === changedItem.title);

    let parsedItem = undefined;
    if (changeItemId !== null) {
      parsedItem = {
        title: changedItem.title,
        subTitle: changedItem.sub_title,
        description: changedItem.description,
        startDate: changedItem.start_date,
        endDate: changedItem.end_date,
        image: academQualif ? getAcademQualifImage(academQualif.image) : null,
        currently: changedItem.currently,
      };
    }
    // console.log("parsedItem================>", parsedItem);
    return (
      <ChangeModal
        autocompleateItems={academQulifList}
        faIcon={"fas fa-user-graduate"}
        item={parsedItem}
        modalTitle={modalTitle}
        titleInputPlaceholder={"Your School or University"}
        subTitleInputPlaceholder={"Your academic qualification"}
        withAutocompleate={"subTitle"}
        onClose={this.handleCloseModal}
        onLoadAutocompleateItemImage={this.handleLoadCompanyImage}
        onSave={this.handleSaveClick}
        changeItemId={this.state.changeItemId}
        onDelete={() => this.handleDeleteClick(this.state.changeItemId)}
        isDelete={true}
        isBigModalWindow={false}
      />
    );
  };

  renderCards = () => {
    const { userAcademQualif, academQulifList } = this.props;

    if (userAcademQualif && userAcademQualif.length) {
      return userAcademQualif.map((item) => {
        const element = academQulifList.find(
          (el) => el.id === item.acadm_qualf_id
        );
        return (
          <Element key={item.id} name={`academQualifId${item.id}`}>
            <Card
              description={item.description}
              endDate={item.end_date}
              faIcon={"fas fa-user-graduate"}
              id={item.id}
              image={element && getAcademQualifImage(element.image)}
              item={item}
              startDate={item.start_date}
              subTitle={item.sub_title}
              title={item.title}
              type={"academic"}
              onDelete={() => this.handleDeleteClick(item.id)}
              onModalOpen={() => this.handleOpenModal({ id: item.id })}
            />
          </Element>
        );
      });
    }
  };

  render() {
    const { isModalOpen } = this.state;
    return (
      <Container>
        <AddButton
          value={"Add Academic Qualification"}
          onClickAdd={this.handleOpenModal}
        />
        <CardsList>{this.renderCards()}</CardsList>
        {isModalOpen ? this.renderChangeModal() : null}
      </Container>
    );
  }
}
