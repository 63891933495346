import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const APPLY = createAsyncAction('applications/APPLY')
export const applyForVacancy = data => {
  return apiCall({
    endpoint: '/applications/user/apply',
    method: 'POST',
    query: data,
    types: APPLY
  })
}

export const ACCEPT = createAsyncAction('appications/ACCEPT')
export const acceptApplication = data => {
  return apiCall({
    endpoint: '/applications/user/accept',
    method: 'POST',
    query: data,
    types: ACCEPT
  })
}

export const DECLINE = createAsyncAction('appications/DECLINE')
export const declineApplication = (data) => {
  // console.log('data', data)
  return apiCall({
    endpoint: '/applications/user/decline',
    method: 'POST',
    query: data,
    types: DECLINE
  })
}

export const NO_INTEREST = createAsyncAction('appications/NO_INTEREST')
export const noInterestApplication = data => {
  return apiCall({
    endpoint: '/applications/user/no-interest',
    method: 'POST',
    query: data,
    types: NO_INTEREST
  })
}

export const LOAD = createAsyncAction('applications/LOAD')
export const loadApplications = () => {
  return apiCall({
    endpoint: '/applications/user',
    method: 'GET',
    types: LOAD
  })
}
