import { createSelector } from 'reselect'

const registerData = store => store.register

export const getRegisterData = createSelector(registerData, data => data)

export const getParsedCV = createSelector(registerData, data => {
  const { cvId, parsedCV } = data

  return {
    id: cvId,
    cv: parsedCV
  }
})

export const getExpiredStatus = createSelector(
  registerData,
  data => data.expired
)
export const getResendLoadInfo = createSelector(
  registerData,
  data => data.resendLoadInfo
)

export const getCVParseState = createSelector(
  registerData,
  data => data.cvParseStatus
)

export const isEmailConfirmed = createSelector(
  registerData,
  data => data.isEmailConfirmed
)