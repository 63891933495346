import React, { PureComponent } from 'react'

class ImageWithLoad extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      src: null
    }
  }

  UNSAFE_componentWillMount() {
    const { src: propsSrc, defaultImage } = this.props

    const img = new Image()

    img.onload = () => {
      this.setState({ src: propsSrc })
    }

    img.onerror = () => {
      this.setState({ src: defaultImage })
    }

    if (propsSrc) {
      img.src = propsSrc
    }
  }

  render() {
    const { src } = this.state
    const { alt, defaultImage } = this.props

    return <img {...this.props} alt={alt} src={src || defaultImage} />
  }
}

export default ImageWithLoad
