import { createReducer } from 'Helpers/redux'

import { GET_LANG_LIST } from '../actions/languages'

const initialState = {
  languages: [],
  languagesLoadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const handlers = {
  [GET_LANG_LIST.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        ...state.loadInfo,
        isLoading: true,
        error: null
      }
    }
  },
  [GET_LANG_LIST.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        ...state.loadInfo,
        isLoading: false,
        error: payload.data
      }
    }
  },
  [GET_LANG_LIST.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      languages: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
