import { createSelector } from 'reselect'

const personalitiesData = store => store.personalities

export const getPredictionsPersonalities = createSelector(
  personalitiesData,
  data => data.personalities
)

export const getPersonalitiesPredictionsLoadInfo = createSelector(
  personalitiesData,
  data => data.loadInfo
)
