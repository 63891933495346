import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_ACADEMIC_QUALIFICATIONS = createAsyncAction(
  'academicQualifications/LOAD'
)
export const loadAcademicQualifications = text => {
  return apiCall({
    endpoint: '/academicqualifications',
    types: LOAD_ACADEMIC_QUALIFICATIONS,
    query: {
      text
    },
    method: 'GET'
  })
}
export const REMOVE_ACADEMIC_QUALIFICATIONS = createAsyncAction(
  'academicQualifications/REMOVE'
)
export const removeAcademicQualifications = id => {
  return apiCall({
    endpoint: '/academicqualifications/id',
    types: REMOVE_ACADEMIC_QUALIFICATIONS,
    method: 'DELETE',
    query: { id }
  })
}
export const EDIT_ACADEMIC_QUALIFICATIONS = createAsyncAction(
  'academicQualifications/EDIT'
)
export const editAcademicQualifications = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/academicqualifications',
    types: EDIT_ACADEMIC_QUALIFICATIONS,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}

export const CHANGE_DATA_ACADEMIC_QUALIFICATIONS = createAsyncAction(
  'academicQualifications/CHANGE_DATA'
)
export const changeDataAcademicQualifications = ({
  id,
  acadmQualifId,
  title,
  subTitle,
  startDate,
  endDate,
  description,
  currently
}) => {
  return apiCall({
    endpoint: '/academicqualifications',
    types: CHANGE_DATA_ACADEMIC_QUALIFICATIONS,
    method: 'PUT',
    query: {
      id,
      acadmQualifId,
      title,
      subTitle,
      startDate,
      endDate,
      description,
      currently
    }
  })
}
