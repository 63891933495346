import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const ADD_EXPERIENCE = createAsyncAction('experience/ADD')
export const addExperience = data => {
  return apiCall({
    endpoint: '/experience',
    types: ADD_EXPERIENCE,
    method: 'PUT',
    query: data
  })
}
export const REMOVE_EXPERIENCE = createAsyncAction('experience/REMOVE')
export const removeExperience = id => {
  return apiCall({
    endpoint: '/experience',
    types: REMOVE_EXPERIENCE,
    method: 'DELETE',
    query: {
      id
    }
  })
}
export const EDIT_EXPERIENCE = createAsyncAction('experience/EDIT')
export const editExperience = ({
  description,
  startDate,
  endDate,
  position,
  //address,
  expId,
  company,
  currently
}) => {
  return apiCall({
    endpoint: '/experience',
    types: EDIT_EXPERIENCE,
    method: 'POST',
    query: {
      description,
      startDate,
      endDate,
      position,
      expId,
      //address,
      company,
      currently
    }
  })
}
