import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'

const DetailLoader = ({ type }) => {
  if (type === 'desktop') {
    return (
      <ContentLoader
        height={500}
        width={600}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="15" ry="15" width="220" height="120" />
        <rect x="230" y="5" rx="5" ry="5" width="200" height="17" />
        <rect x="230" y="35" rx="5" ry="5" width="70" height="10" />
        <rect x="380" y="35" rx="5" ry="5" width="15" height="15" />
        <rect x="400" y="35" rx="5" ry="5" width="15" height="15" />
        <rect x="235" y="55" rx="50" ry="50" width="30" height="30" />
        <rect x="235" y="100" rx="5" ry="5" width="30" height="7" />
        <rect x="285" y="55" rx="5" ry="5" width="30" height="30" />
        <rect x="285" y="100" rx="5" ry="5" width="30" height="7" />
        <rect x="335" y="55" rx="5" ry="5" width="30" height="30" />
        <rect x="335" y="100" rx="5" ry="5" width="30" height="7" />
        <rect x="385" y="55" rx="5" ry="5" width="30" height="30" />
        <rect x="385" y="100" rx="5" ry="5" width="30" height="7" />

        <rect x="0" y="135" rx="5" ry="5" width="120" height="13" />
        <rect x="0" y="155" rx="13" ry="13" width="50" height="20" />
        <rect x="55" y="155" rx="13" ry="13" width="50" height="20" />
        <rect x="110" y="155" rx="13" ry="13" width="50" height="20" />
        <rect x="165" y="155" rx="13" ry="13" width="50" height="20" />
        <rect x="220" y="155" rx="13" ry="13" width="50" height="20" />

        <rect x="25" y="185" rx="5" ry="5" width="110" height="15" />
        <rect x="160" y="185" rx="5" ry="5" width="90" height="15" />
        <rect x="300" y="185" rx="5" ry="5" width="80" height="15" />
        <rect x="400" y="185" rx="5" ry="5" width="80" height="15" />
        <rect x="25" y="210" rx="5" ry="5" width="110" height="15" />
        <rect x="160" y="210" rx="5" ry="5" width="90" height="15" />
        <rect x="300" y="210" rx="5" ry="5" width="80" height="15" />
        <rect x="400" y="210" rx="5" ry="5" width="80" height="15" />
        <rect x="515" y="190" rx="7" ry="7" width="75" height="25" />

        <rect x="0" y="255" rx="5" ry="5" width="75" height="13" />
        <rect x="0" y="285" rx="5" ry="5" width="600" height="7" />
        <rect x="0" y="295" rx="5" ry="5" width="600" height="7" />
        <rect x="0" y="305" rx="5" ry="5" width="600" height="7" />
        <rect x="0" y="315" rx="5" ry="5" width="600" height="7" />
        <rect x="0" y="325" rx="5" ry="5" width="600" height="7" />
      </ContentLoader>
    )
  } else if (type === 'mobile') {
    return (
      <ContentLoader
        height={1600}
        width={600}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="5" y="0" rx="15" ry="15" width="580" height="220" />

        <rect x="5" y="240" rx="10" ry="10" width="540" height="50" />

        <rect x="5" y="330" rx="5" ry="5" width="120" height="15" />
        <rect x="500" y="320" rx="20" ry="20" width="30" height="30" />
        <rect x="550" y="320" rx="20" ry="20" width="30" height="30" />

        <rect x="30" y="390" rx="50" ry="50" width="80" height="80" />
        <rect x="30" y="495" rx="10" ry="10" width="80" height="20" />
        <rect x="180" y="393" rx="10" ry="10" width="75" height="75" />
        <rect x="180" y="495" rx="5" ry="5" width="75" height="10" />
        <rect x="325" y="393" rx="10" ry="10" width="75" height="75" />
        <rect x="325" y="495" rx="5" ry="5" width="75" height="10" />
        <rect x="475" y="393" rx="10" ry="10" width="75" height="75" />
        <rect x="475" y="495" rx="5" ry="5" width="75" height="10" />

        <rect x="200" y="600" rx="10" ry="10" width="200" height="30" />
        <rect x="210" y="660" rx="10" ry="10" width="180" height="25" />

        <rect x="200" y="730" rx="10" ry="10" width="200" height="30" />
        <rect x="210" y="790" rx="10" ry="10" width="180" height="50" />

        <rect x="200" y="880" rx="10" ry="10" width="200" height="30" />
        <rect x="210" y="940" rx="10" ry="10" width="180" height="25" />

        <rect x="5" y="1100" rx="10" ry="10" width="200" height="25" />
        <rect x="5" y="1135" rx="10" ry="10" width="570" height="10" />
        <rect x="5" y="1155" rx="10" ry="10" width="540" height="10" />
        <rect x="5" y="1175" rx="10" ry="10" width="580" height="10" />
        <rect x="5" y="1195" rx="10" ry="10" width="590" height="10" />
        <rect x="5" y="1215" rx="10" ry="10" width="530" height="10" />
        <rect x="5" y="1235" rx="10" ry="10" width="520" height="10" />
        <rect x="5" y="1255" rx="10" ry="10" width="500" height="10" />
        <rect x="5" y="1275" rx="10" ry="10" width="570" height="10" />
        <rect x="5" y="1295" rx="10" ry="10" width="590" height="10" />
      </ContentLoader>
    )
  }
}

DetailLoader.propTypes = {
  type: PropTypes.string.isRequired
}

export { DetailLoader }
