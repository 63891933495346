import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './ImGoodAt'
import {
  loadExpertises,
  removeExpertises,
  editExpertises
} from 'Redux/actions/expertises'
import { getPredExpertises } from 'Redux/selectors/expertises'
import { getUserExpertises, getUserSubExpertises } from 'Redux/selectors/user'
import {
  loadSubExpertises,
  removeSubExpertises,
  editSubExpertises,
  resetPredSubExperties,
  updateSubExpertises
} from 'Redux/actions/subExpertises'
import { getPredSubExpertises } from 'Redux/selectors/subExpertises'

const actions = {
  onLoadExpertisesList: loadExpertises,
  //onRemoveExpertises: removeExpertises,
  onEditExpertises: editExpertises,
  onLoadSubExpertisesList: loadSubExpertises,
  //onRemoveSubExpertises: removeSubExpertises,
  onEditSubExpertises: editSubExpertises,
  //onResetPredSubExperties: resetPredSubExperties,
  onUpdateSubExpertises: updateSubExpertises
}
const selector = createStructuredSelector({
  expertises: getPredExpertises,
  userExpertises: getUserExpertises,
  subExpertises: getPredSubExpertises,
  userSubExpertises: getUserSubExpertises
})

export const ImGoodAt = connect(
  selector,
  actions
)(Component)
