import { createAsyncAction } from "Helpers/redux";
import { apiCall } from "./api";

export const LOAD = createAsyncAction("industries/LOAD");
export const loadIndustries = () => {
  return apiCall({
    endpoint: "/industries",
    types: LOAD,
  });
};

export const FOLLOW = createAsyncAction("industries/FOLLOW");
export const followIndustry = (industry, offline) => {
  // console.log("hello from redu :tongue:");
  if (offline) {
    return {
      type: FOLLOW.SUCCESS,
      payload: {
        data: {
          industry_id: industry.id,
          industry_name: industry.title,
          // industry_id: 61,
          // industry_name: "Computer & IT",
        },
      },
    };
  }

  return apiCall({
    endpoint: "/candidates/follow-industry",
    method: "POST",
    query: { industryId: industry.id },
    types: FOLLOW,
  });
};

export const UNFOLLOW = createAsyncAction("industries/UNFOLLOW");
export const unfollowIndustry = (industry, offline) => {
  if (offline) {
    return {
      type: UNFOLLOW.SUCCESS,
      payload: {
        data: {
          industry_id: industry.id,
        },
      },
    };
  }

  return apiCall({
    endpoint: "/candidates/unfollow-industry",
    method: "POST",
    query: { industryId: industry.id },
    types: UNFOLLOW,
  });
};
