import styled, { keyframes } from 'styled-components'
import { pulse } from 'react-animations'
import * as colors from '../../../Theme/colors'

const notificationsAnim = keyframes`${pulse}`
export const Notifications = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  background-color: ${colors.notificationRed};
  position: absolute;
  top: 0;
  right: 0;
  line-height: 30px;
  font-family: 'Nunito';
  font-weight: bold;
  transition: all 0.5s;
  &:hover {
    animation: 0.5s ${notificationsAnim} infinite;
  }
`
