import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import withSizes from 'react-sizes'

import {
  TopBar,
  NavigationContainer,
  TopItemCircle,
  TopItemContainer
} from './styled'

class CareerNavigationPublic extends PureComponent {
  state = {
    activeLabel: null
  }

  renderContent = () => {
    const {
      activeCategory,
      onCategoryClick,
      isBigScreen,
      isShow,
      feedbacks,
      experience,
      userExpertises,
      academQualif,
      profQualif,
      activities
    } = this.props
    return (
      <NavigationContainer isBigScreen={isBigScreen} show={isShow}>
        <TopBar id={'careerTopScrollNavigation'}>
          <TopItemContainer onClick={() => onCategoryClick('whoAmI')}>
            <TopItemCircle
              active={activeCategory === 'whoAmI'}
              className={'fas fa-user'}
            />
          </TopItemContainer>
          {experience && experience.length ? (
            <TopItemContainer onClick={() => onCategoryClick('positions')}>
              <TopItemCircle
                active={activeCategory === 'positions'}
                className={'fas fa-building'}
              />
            </TopItemContainer>
          ) : null}
          {(academQualif && academQualif.length) ||
          (profQualif && profQualif.length) ? (
            <TopItemContainer onClick={() => onCategoryClick('qualifications')}>
              <TopItemCircle
                active={activeCategory === 'qualifications'}
                className={'fas fa-user-graduate'}
              />
            </TopItemContainer>
          ) : null}
          {userExpertises && userExpertises.length ? (
            <TopItemContainer onClick={() => onCategoryClick('imGoodAt')}>
              <TopItemCircle
                active={activeCategory === 'imGoodAt'}
                className={'fas fa-chess-knight'}
              />
            </TopItemContainer>
          ) : null}
          {feedbacks && feedbacks.length ? (
            <TopItemContainer onClick={() => onCategoryClick('feedbacks')}>
              <TopItemCircle
                active={activeCategory === 'feedbacks'}
                className={'fas fa-users'}
              />
            </TopItemContainer>
          ) : null}
          {activities && activities.length ? (
            <TopItemContainer onClick={() => onCategoryClick('activities')}>
              <TopItemCircle
                active={activeCategory === 'activities'}
                className={'fas fa-trophy'}
              />
            </TopItemContainer>
          ) : null}
        </TopBar>
      </NavigationContainer>
    )
  }

  render() {
    const { isBigScreen } = this.props
    if (isBigScreen) {
      return ReactDOM.createPortal(
        this.renderContent(),
        document.getElementById('sidebarContainer')
      )
    } else {
      return this.renderContent()
    }
  }
}

CareerNavigationPublic.propTypes = {
  activeCategory: PropTypes.string,
  onCategoryClick: PropTypes.func.isRequired
}

const mapSizeToProps = ({ width }) => ({
  isBigScreen: width > 1600
})

export default withSizes(mapSizeToProps)(CareerNavigationPublic)
