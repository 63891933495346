import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import {Element} from 'react-scroll'

import {
  CardImage,
  CardTitle,
  CardTimeComponent,
  CardSubTitle,
  CardEditLabel
} from '../../blocks'

import {Container, CardContent, CardDescription, Wrap, EditIcon} from './styled'

class ActivitiesCard extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    endDate: PropTypes.string,
    faIcon: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    startDate: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onModalOpen: PropTypes.func.isRequired
  }

  state = {
    isShowEditLabel: false
  }

  handleShowEditLabel = () => this.setState({isShowEditLabel: true})

  handleHideEditLabel = () => this.setState({isShowEditLabel: false})

  handleClickCard = () => {
    this.setState(prevState => ({
      isShowEditLabel: !prevState.isShowEditLabel
    }))
  }

  render() {
    const {
      title,
      subTitle,
      id,
      startDate,
      endDate,
      description,
      type,
      faIcon,
      image,
      onDelete,
      onModalOpen
    } = this.props
    const {isShowEditLabel} = this.state

    const link = `${type}${id}`

    return (
      <Element name={link}>
        <Container
          onClick={this.handleClickCard}
          // onMouseOut={this.handleHideEditLabel}
          // onMouseOver={this.handleShowEditLabel}
        >
          <CardImage faIcon={faIcon}/>
          <CardContent>
            <Wrap>
              <CardTimeComponent endDate={endDate} startDate={startDate}/>
              <EditIcon
                className={'fas fa-edit'}
                onClick={onModalOpen}
              />
            </Wrap>
            <CardTitle text={title}/>
            <CardSubTitle text={subTitle}/>
            <CardDescription>{ReactHtmlParser(description)}</CardDescription>
           {/* <CardEditLabel
              isOpen={isShowEditLabel}
              onFirstButtonClick={onModalOpen}
              onSecondButtonClick={onDelete}
            />*/}
          </CardContent>
        </Container>
      </Element>
    )
  }
}

export {ActivitiesCard}
