import React, { Component } from 'react'
import {
  LineConteiner,
  CircleBorder,
  Circle,
  VerticalLine,
  SimpleCircle
} from './styled'
import map from 'lodash/map'

class Line extends Component {
  renderLenghtLine() {
    return map(this.props.lenght, (value, key) => {
      return (
        <div key={value.time}>
          <VerticalLine />
          <SimpleCircle />
        </div>
      )
    })
  }

  render() {
    return (
      <LineConteiner>
        <CircleBorder>
          <Circle />
        </CircleBorder>
        {this.renderLenghtLine()}
      </LineConteiner>
    )
  }
}

export { Line }
