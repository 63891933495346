export default {
  init: 0,
  switch: 1,
  login: 2,
  search: 3,
  location: 4,
  cv: 5,
  details: 6,
  email: 7,
  success: 8,
  parsed: 9
}
