import styled from 'styled-components'
import { notificationRed } from '../../../../../Theme/colors'

export const modalStyle = {
  content: {
    border: 'none',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
export const Content = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
  width: 450px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`
// export const Content = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   flex: 1;
// `
export const Icon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.success ? '#0acd00' : notificationRed)};
  font-size: 60px;
`
export const BigText = styled.div`
  color: ${props => (props.success ? '#0acd00' : notificationRed)};
  font-size: 48px;
  margin: 15px 0;
`
export const SmallText = styled.div`
  color: ${props => (props.success ? '#0acd00' : notificationRed)};
  font-size: 16px;
  margin: 11px 0;
  text-align: center;
`
export const CloseButton = styled.div`
  position: absolute;
  right: -15px;
  top: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: white;
  color: ${props => (props.success ? '#0acd00' : notificationRed)};
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => (props.success ? '#0acd00' : notificationRed)};
  cursor: pointer;
`
