import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const GET_LANG_LIST = createAsyncAction('languages/GET_LIST')
export const getAllLanguages = () => {
  return apiCall({
    endpoint: '/languages',
    types: GET_LANG_LIST
  })
}

export const UPDATE_LANG = createAsyncAction('languages/ADD')
export const updateLanguages = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/languages',
    types: UPDATE_LANG,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}

export const EDIT_LANG_LEVEL = createAsyncAction('languages/EDIT_LEVEL')
export const editLanguageLevel = ({ lanId, userId, value }) => {
  return apiCall({
    endpoint: '/languages',
    types: EDIT_LANG_LEVEL,
    method: 'PUT',
    query: {
      lanId,
      userId,
      value
    }
  })
}
