import styled from 'styled-components'
import { notificationRed } from '../../../../../Theme/colors'

export const Content = styled.div`
  margin: 24px 16px 16px;
`

export const Title = styled.h3`
  text-align: center;
`
export const Text = styled.label``
export const Container = styled.form`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 30vh; */
  width: 300px;
  justify-content: space-around;
`
export const InnerPassword = styled.input`
  border: solid 1px ${props => (props.error ? notificationRed : 'silver')};
  width: 100%;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`
export const ButtonSubmit = styled.button`
  background: none;
  border: solid 1px silver;
  border-radius: 5px;
  font-weight: bold;
  ${props => props.disabled && 'color: silver'};
`
export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  position: relative;
`
export const Form = styled.div`
  margin-top: 40px;
`

export const InputContainer = styled.div`
  margin-top: 24px;
`

export const BottomBlock = styled.div`
  display: flex;
  margin: 16px 0 24px;
  align-items: center;
`
