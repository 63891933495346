import styled from "styled-components";
import * as colors from "Theme/colors";

export const Container = styled.div`
  height: 80px;
  min-width: 80px;
  max-width: 80px;
  width: 80px;
  border: 3px solid
    ${(props) => (props.checked ? "#267ee2" : props.borderColor)};
  box-shadow: 2px 2px 2px 1px
    ${(props) => (props.checked ? "0.5rem" : "0.5rem")} rgba(0, 0, 0, 0.2);
  &:hover {
    border: 2px solid ${colors.mainBlue};
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  border-radius: 15px;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  [hidden] {
    display: flex;
  }
`;
export const Content = styled.div`
  color: purple;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  margin-top: -15px;
  flex: 1;
`;
export const Close = styled.div`
  width: 15px;
  height: 15px;
  position: relative;
  align-self: flex-end;
  top: -5px;
  right: -5px;
  border-radius: 7.5px;
  color: white;
  background-color: ${colors.chatPurple};
  visibility: ${(props) => (props.showed ? "visible" : "hidden")};
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Icon = styled.img``;
