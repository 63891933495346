import styled from "styled-components";
import { PropagateLoader } from "react-spinners";

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
export const Loader = styled(PropagateLoader).attrs({ color: "silver" })``;

export const ModalHeader = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    color: #00b4ff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Nunito";
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  align-self: center;
`;
export const ModalButton = styled.button`
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  width: 90px;
  height: 30px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  padding: 0 10px;
  background: rgb(52, 224, 156);
  cursor: pointer;
  & > span {
    font-family: "Nunito";
  }
`;
