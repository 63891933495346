import { createReducer } from 'Helpers/redux'

import { FETCH_USER_BY_ID } from 'Redux/actions/user'

const initialState = {
  user: {},
  experience: [],
  education: [],
  followedIndustries: [],
  expectations: [],
  personalities: [],
  highSchoolEd: [],
  academicQualifications: [],
  profQualf: [],
  expertises: [],
  subExpertises: [],
  jobTypes: [],
  companies: [],
  languages: [],
  feedbacks: [],
  activities: [],
  fetchUserLoadInfo: {
    isLoading: false,
    isLoaded: false,
    error: null
  }
}

const handlers = {
  [FETCH_USER_BY_ID.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      fetchUserLoadInfo: {
        isLoading: true,
        isLoaded: false,
        error: null
      }
    }
  },
  [FETCH_USER_BY_ID.SUCCESS]: (state, { payload }) => {
    const {
      user,
      experience,
      education,
      followedIndustries,
      expectations,
      personalities,
      highSchoolEd,
      academicQualifications,
      profQualf,
      expertises,
      subExpertises,
      jobTypes,
      companies,
      languages,
      feedbacks,
      activities
    } = payload.data

    return {
      ...state,
      user,
      experience,
      education,
      followedIndustries,
      expectations,
      personalities,
      highSchoolEd,
      academicQualifications,
      profQualf,
      expertises,
      subExpertises,
      jobTypes,
      companies,
      languages,
      feedbacks,
      activities,
      fetchUserLoadInfo: {
        isLoading: false,
        isLoaded: true,
        error: null
      }
    }
  },
  [FETCH_USER_BY_ID.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      fetchUserLoadInfo: {
        isLoading: false,
        isLoaded: true,
        error: payload.data
      }
    }
  }
}

export default createReducer(initialState, handlers)
