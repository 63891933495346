import React, { PureComponent } from "react";
import { getCurrentPosition } from "Helpers/geo";
import { googlePlacesRequest, getCoordsByPlaceId } from "Api/googlePlaces";
import {
  Container,
  Input,
  List,
  Item,
  ListContainer,
  InputIconContainer,
  LoadingIcon,
  InputContainer,
} from "./styles";

export class Autocomplete extends PureComponent {
  state = {
    isOpenResultList: false,
    coords: "",
    dataSource: [],
    isLocLoading: false,
    coordsLoading: false,
    locText: "",
  };

  componentDidMount() {
    //autolocation
    // getCurrentPosition()
    //   .then(location => {
    //     this.setState({
    //       coords: location
    //     })
    //   })
    //   .catch(err => {
    //     console.error(err)
    //   })
  }

  handleChange = (e) => {
    this.setState(
      {
        isOpenResultList: true,
        locText: e.target.value,
      },
      () => this.handleLocationTextChange()
    );
  };

  handleLocationItemSelect = async (el) => {
    const { onSetLocation } = this.props;
    this.setState({
      coordsLoading: true,
      isOpenResultList: false,
    });
    const coords = await getCoordsByPlaceId(el.placeId);

    onSetLocation({
      coords,
      locText: el.text,
    });
    setTimeout(() => {
      this.setState({
        coords,
        locText: el.text,
        dataSource: [],
        coordsLoading: false,
      });
    }, 2000);
  };

  handleLocationTextChange = async () => {
    const { locText } = this.state;

    if (!locText.length) {
      getCurrentPosition()
        .then((location) => {
          this.setState({
            coords: location,
          });
        })
        .catch((err) => {
          this.setState({
            coords: null,
          });
          console.error(err);
        });

      return this.setState({
        locText: locText,
        isLocLoading: false,
      });
    }

    this.setState({
      locText: locText,
      isLocLoading: true,
    });

    const results = await googlePlacesRequest(locText);
    this.setState({
      dataSource: results.predictions.map((el) => ({
        text: el.description,
        placeId: el.place_id,
      })),
      isLocLoading: false,
    });
    //todo list is empty???
  };

  render() {
    const { dataSource, isLocLoading, coordsLoading, locText } = this.state;

    return (
      <Container>
        <InputContainer>
          <Input
            disabled={coordsLoading}
            placeholder="my home location"
            type="text"
            value={locText}
            onChange={(e) => this.handleChange(e)}
          />
          <InputIconContainer>
            <LoadingIcon active={coordsLoading || isLocLoading} />
          </InputIconContainer>
        </InputContainer>
        <List open={dataSource.length && !coordsLoading}>
          {dataSource.length
            ? dataSource.map((el, i) => (
                <Item key={i} onClick={() => this.handleLocationItemSelect(el)}>
                  {el.text}
                </Item>
              ))
            : null}
        </List>
      </Container>
    );
  }
}
