import Component from './Detail'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  loadUserJobs,
  deleteActiveVacancy,
  loadCurrentVacancy,
  loadSalaryRange,
  saveVacancy
} from 'Redux/actions/jobs'
import { applyForVacancy } from 'Redux/actions/applications'
import { deleteHeaderCat } from 'Redux/actions/main'
import {
  setActiveVacancy,
  deleteFromSavedVacancies,
  changeJobTab
} from 'Redux/actions/jobs'
import { switchModal } from 'Redux/actions/utils'
import { loadNotifications } from 'Redux/actions/notifications'
import { loadExpertises } from 'Redux/actions/expertises'

import { getApplyState } from 'Redux/selectors/applications'
import {
  getActiveVacancy,
  getActiveVacLoadInfo,
  getSalaryRange,
  getUserJobs,
  getTab
} from 'Redux/selectors/jobs'
import { isAuthenticate } from 'Redux/selectors/auth'
import {
  getFollowedIndustries,
  getUserSavedVacancies
  //getProfLoadInfo
} from 'Redux/selectors/user'
import {
  getPredExpertises
} from 'Redux/selectors/expertises'

const actions = {
  onUserJobsLoad: loadUserJobs,
  onDeleteActiveVacancy: deleteActiveVacancy,
  onApplyForVacancy: applyForVacancy,
  onLoadCurrentVacancy: loadCurrentVacancy,
  onDeleteHeaderCat: deleteHeaderCat,
  onSalaryRangeLoad: loadSalaryRange,
  onSetActiveVacancy: setActiveVacancy,
  onSaveVacancy: saveVacancy,
  onDeteFromSavedVacancy: deleteFromSavedVacancies,
  onOpenModal: switchModal,
  onChangeJobTab: changeJobTab,
  onUpdateNotifications: loadNotifications,
  onExpertisesLoad: loadExpertises,
}

const selector = createStructuredSelector({
  vacancy: getActiveVacancy,
  followedIndustries: getFollowedIndustries,
  isAuthenticate,
  activeVacLoadInfo: getActiveVacLoadInfo,
  applyState: getApplyState,
  salaryRange: getSalaryRange,
  userJobs: getUserJobs,
  savedVacancies: getUserSavedVacancies,
  tab: getTab,
  expertises: getPredExpertises,
  //userDataLoadingInfo: getProfLoadInfo
})

export default connect(
  selector,
  actions
)(Component)
