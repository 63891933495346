import Component from './UserInfo'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { udpdateCandidate, udpdateCandidatePassword } from 'Redux/actions/user'

import { getUser } from 'Redux/selectors/user'

const selectors = createStructuredSelector({
  user: getUser
})

const actions = {
  onUpdateCandidate: udpdateCandidate,
  onUpdateCandidatePassword: udpdateCandidatePassword
}

export const UserInfo = connect(
  selectors,
  actions
)(Component)
