import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 10px;
`

export const TextBlock = styled.div`
  display: flex;
  align-items: center;
`

export const Circle = styled.div`
  width: 5px;
  height: 5px;
  min-width: 5px;
  min-height: 5px;
  border-radius: 50%;
  background: #757575;
`

export const Title = styled.h2`
  font-weight: normal;
  font-size: 15px;
  margin: 0;
  color: #757575;
  padding: 0 0 0 10px;
`

export const RatingBlock = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 185px;
  align-items: center;
  @media screen and (max-width: 400px) {
    min-width: 149px;
  }
`
