import { put } from 'redux-saga/effects'
import { fetchUser } from 'Redux/actions/user'
import { loadSavedVacancies, loadUserJobs } from 'Redux/actions/jobs'
import { loadNotifications } from 'Redux/actions/notifications'
import Cookies from 'universal-cookie';

function* onLoginSuccess(action) {
  try {
    const { token, user } = action.payload.data
    localStorage.setItem('user_token', token)
    localStorage.setItem('candidate_id', user.id)
    const cookies = new Cookies();
    cookies.set('user_token', token, { path: '/' });
    console.log('hello token',cookies.get('user_token')); // Pacman
    const email = localStorage.getItem('saved_emails')
    const avatar = localStorage.getItem('saved_avatars')

    if (email && email.indexOf(user.email) === -1) {
      const emails = email.split(', ')

      emails.push(user.email)

      localStorage.setItem('saved_emails', emails.join(', '))
      if (avatar) {
        const avatars = avatar.split(', ')
        avatars.push(user.avatar.length > 0 ? user.avatar : '')
        localStorage.setItem('saved_avatars', avatars.join(', '))
      }
    } else if (email === null) {
      localStorage.setItem('saved_emails', user.email)
      localStorage.setItem('saved_avatars', user.avatar || '')
    }
    yield put(fetchUser())
    yield put(loadNotifications())
    yield put(loadSavedVacancies())
    yield put(loadUserJobs())
  } catch (e) {
    console.error(e)
  }
}

export default onLoginSuccess
