import React, { PureComponent } from "react";
import MediaQuery from "react-responsive";
import moment from "moment";
import Layout from "../../Blocks/LayOut";
import { Line } from "./innerBlocks/Line/Line";
import { RightBlock } from "./innerBlocks/Blocks/RightBlock";
import { LeftBlock } from "./innerBlocks/Blocks/LeftBlock";
import {
  MainContainer,
  RightSide,
  LeftSide,
  SideContainer,
  EmptyListWrapper,
  EmptyListIcon,
  EmptyListText,
} from "./styled";
import { Helmet } from "react-helmet";

import { categoreis } from "./data";

class Notification extends PureComponent {
  state = {
    activeCategory: {
      title: "notification",
      id: 0,
    },
  };

  componentWillMount() {
    const { onLoadNotifications } = this.props;
    onLoadNotifications();
  }

  componentDidMount() {
    const { onUpdateDate, userEmail, isAuthenticate } = this.props;
    if (isAuthenticate) {
      onUpdateDate(userEmail);
    }
    console.log("🚯", this.props);
  }

  render() {
    const { notifications, history } = this.props;
    console.log(
      "🚀 ~ file: Notification.js ~ line 44 ~ Notification ~ render ~ this.props",
      this.props
    );
    const { activeCategory } = this.state;
    const NotEven = [];
    const Even = [];
    // console.log("notifications ", notifications);

    notifications.forEach((element, id) => {
      id++;
      if (id & 1) {
        NotEven.push(element);
      } else {
        Even.push(element);
      }
    });

    return (
      <Layout
        activeHeaderCategory={activeCategory}
        header={categoreis}
        history={history}
        onHeaderCategoryClick={() => {}}
      >
        <Helmet>
          {/* <title>{meta.title} | Mr.JOBS</title> */}
          <title>{"Notifications"} | Mr.JOBS</title>
          {/* <meta content={} name={"description"} /> */}
          {/* <meta content={} name={"keywords"} /> */}
        </Helmet>
        {notifications.length ? (
          <React.Fragment>
            <MediaQuery maxDeviceWidth={899}>
              <MainContainer>
                <SideContainer>
                  <LeftSide>
                    <LeftBlock left={notifications} />
                  </LeftSide>
                </SideContainer>
                <Line lenght={notifications} />
              </MainContainer>
            </MediaQuery>
            <MediaQuery minDeviceWidth={900}>
              <MainContainer>
                <LeftSide>
                  <LeftBlock left={NotEven.reverse()} />
                </LeftSide>
                <Line lenght={notifications} />
                <RightSide>
                  <RightBlock right={Even.reverse()} />
                </RightSide>
              </MainContainer>
            </MediaQuery>
          </React.Fragment>
        ) : (
          <EmptyListWrapper>
            <EmptyListIcon />
            <EmptyListText>Notification list is empty</EmptyListText>
          </EmptyListWrapper>
        )}
      </Layout>
    );
  }
}

export default Notification;
