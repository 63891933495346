import styled, { keyframes } from "styled-components";
import { notificationRed } from "../../../Theme/colors";
import { rubberBand } from "react-animations";

export const percentStyles = {
  root: {
    width: "50px",
    height: "50px",
  },
  path: {
    stroke: "#583ea1",
  },
  text: {
    fill: "#857575",
    fontSize: "25px",
  },
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  background-color: #fff;
  width: 100%;
  margin-bottom: 50px;
  @media screen and (max-width: 500px) {
    padding-right: 0;
  }
`;
export const TopContent = styled.div`
  display: flex;
  margin-bottom: 30px;
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    flex-direction: column;
  }
  @media all and (max-width: 800px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;
export const TitleContainer = styled.div`
  margin: 0 0 4%;
  padding-left: 6px;
  padding-top: 4px;
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    text-align: center;
  }
`;
export const TitleText = styled.h1`
  margin-right: 20px;
  color: #545454;
  font-weight: bold;
  font-family: "Nunito";
  font-size: 30px;
`;
export const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4%;
  padding: 0 6px;
  width: 100%;
  max-width: 450px;
`;
const subTitleAnimation = keyframes`${rubberBand}`;
const _subTitleButton = styled.div`
  font-size: 20px;
  transition: color 0.3s;
  color: #757575;
  cursor: pointer;
  &:hover {
    animation: 0.5s ${subTitleAnimation};
  }
`;
export const Company = styled.h2`
  color: #757575;
  font-size: 15px;
`;
export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const SaveButton = styled(_subTitleButton).attrs({
  className: "fas fa-bookmark",
})`
  color: ${(p) => p.active && "#FE1948"};
  margin-right: 20px;
`;
export const ShareButton = styled(_subTitleButton).attrs({
  className: "fas fa-share",
})`
  &:hover {
    color: #395eff;
  }
`;
export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 17px;
  width: 100%;
  max-width: 450px;
`;
export const Icon = styled.div`
  text-align: center;
  width: 80px;
  margin: 0 10px 0 10px;
  ${(p) => p.matchRate && "padding-top: 5px"};
  flex: 1;
`;

export const IconText = styled.p`
  font-size: 13px;
  color: #757575;
  font-family: "Nunito", sans-serif;
  margin-top: ${(p) => (p.matchRate ? 15 : 20)}px;
`;

export const TopImgText = styled.p`
  font-size: 45px;
  text-align: center;
  color: white;
  padding-top: 85px;
  font-family: "Nunito";
  @media all and (min-width: 1300px) {
  }
`;
export const MiddleContent = styled.div`
  padding: 0 10px;
`;
export const BottomContent = styled.div`
  @media screen and (max-width: 700px) {
    padding-bottom: 20%;
  }
`;
export const Options = styled.div`
  display: flex;
  flex-direction: column;
`;
export const OptionList = styled.div`
  display: flex;
  margin-top: 10px;
  @media all and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
export const Option = styled.div`
  font-family: 'Nunito', sans-serif;
  text-align: center;
  border: 2px solid #00b7fe;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  margin-right: 1%;
  font-size: 15px;
  padding: 6px;
  cursor: default;
  background: #00b7fe;
  // :hover {
  //   color: white;
  //   background: #f3f;
  // }
  @media all and (max-width: 700px) {
    margin-top: 20px;
  }
`;
export const AdditionalInfo = styled.div`
  display: flex;
  width: 100%;
  color: #757575;
  justify-content: space-between;
  margin-top: 20px;
  @media all and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    // padding-left: 20px;
    font-size: 20px;
  }
  @media screen and (max-width: 1100px) and (min-width: 600px) {
    margin-left: 10px;
    padding-right: 30px;
    flex-wrap: wrap;
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    flex-direction: row;
    margin-left: 10px;
    padding-right: 30px;
    flex-wrap: wrap;
    font-size: 15px !important;
  }
`;
export const AdditionalInfoBlock = styled.div`
  margin-bottom: 25px;
  font-size: 20px;
  max-height: 75px;
  @media screen and (max-width: 1100px) and (min-width: 600px) {
    flex-basis: 40%;
  }
  @media screen and (max-width: 700px) {
    text-align: left;
  }
`;
export const AdditionalInfoText = styled.h3`
  @media screen and (max-width: 1100px) and (min-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 700px) {
    text-align: center;
    font-size: 15px;
  }
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  font-size: 20px;
  margin: 0 0 15px;
  color: #757575;
`;
export const JobDescription = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 10px;
`;
export const Apply = styled.button`
  font-family: 'Nunito', sans-serif;
  color: white;
  background-color: #32e09c;
  height: 45px;
  width: 170px;
  border: none;
  border-radius: 15px;
  margin-top: 12px;
  margin-right: 20px;
  :disabled {
    background-color: #593ea1;
  }
  @media screen and (max-width: 500px) {
    margin-right: 0;
  }
`;
export const ContactBlock = styled.div`
  height: 35px;
  background-color: #f5f5f5;
  color: #757575;
  border-radius: 23px;
  margin-top: 10px;
  margin-left: 35px;
  text-align: right;
  font-size: 15px;
  @media screen and (max-width: 1100px) and (min-width: 700px) {
    font-size: 17px;
    margin-left: 5px;
  }
  @media screen and (max-width: 700px) {
    font-size: 15px;
    padding: 2px 1px 1px 2px;
    text-align: left;
    margin-left: 0px;
  }
  padding: 6px 10px 6px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 155px;
`;
export const ContactBlockImg = styled.div`
  width: 50px;
  height: 50px;
  background-color: #757575;
  position: relative;
  border-radius: 50px;
  bottom: 42px;
  @media screen and (max-width: 700px) {
    width: 0px;
    height: 0px;
  }
`;
export const TopImg = styled.img`
  height: 220px;
  border-radius: 25px;
  width: 530px;
  margin-right: 20px;
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    width: initial;
    margin: 0 auto;
  }
  @media all and (max-width: 800px) {
    width: 97%;
    height: initial;
    margin: 0 auto;
  }
  @media all and (max-width: 470px) {
    width: 314px;
    height: 130px;
  }
`;
export const LoaderContainer = styled.div`
  width: 100%;
  margin: 0;
  height: calc(100vh - 70px);
`;
export const OptionTitleContainer = styled.div`
  margin-top: 10px;
  font-size: 20px;
  color: #757575;
  font-family: "Nunito", sans-serif;
`;
export const OptionTitle = styled.h3`
  @media screen and (max-width: 1100px) and (min-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 700px) {
    font-size: 17px;
  }
  color: #757575;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  font-weight: normal;
`;
export const OptionTitle2 = styled.h3`
  @media screen and (max-width: 1100px) and (min-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 700px) {
    font-size: 17px;
    margin-right: 30px;
  }
  color: #757575;
  font-weight: normal;
  font-size: 20px;
  font-family: "Nunito", sans-serif;
`;
export const SliderContainer = styled.div`
  height: 270px;
  position: relative;
`;
export const SocialIconItem = styled.i.attrs({ className: "fas fa-bookmark" })`
  font-size: 18px;
  color: ${(p) => (p.active ? "#395eff" : "#757575")};
  transition: color 0.2s;
  &:hover {
    animation: 0.5s ${subTitleAnimation};
  }
`;
export const SliderTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 20px;
  position: absolute;
  top: -18px;
  left: 40px;
  color: #757575;
`;
export const SocialIcon = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #757575;
`;
export const SalaryContainer = styled.div`
  display: flex;
  color: #757575;
  font-size: 17px;
  width: max-content;
`;
export const SalaryItem = styled.span`
  margin: 0 3px;
  font-family: "Nunito", sans-serif;
`;
export const MatchRateContainer = styled.div`
  cursor: ${(p) => (p.isAuth ? "defaut" : "pointer")};
`;
export const DistanceSubText = styled.p`
  color: #00b7fe;
  margin-top: 10px;
  font-size: 17px;
  font-family: 'Nunito', sans-serif;
`;
export const OptionSubText = styled.p`
  color: ${notificationRed};
  margin-top: 10px;
  font-size: 15px;
  font-family: 'Nunito', sans-serif;
`;
export const JobDescriptionTitle = styled.h3`
  font-size: 20px;
  font-weight: normal;
  color: #757575;
  margin: 0;
  font-family: 'Nunito', sans-serif;
`;
export const JobDescriptionText = styled.p`
  font-size: 17px;
  color: #757575;
  font-family: 'Nunito', sans-serif;
`;
export const ContactBlockImgItem = styled.img`
  width: 45px;
  position: relative;
  left: 7%;
  top: 23%;
  @media screen and (max-width: 700px) {
    width: 0px;
    left: 0%;
    height: 0px;
  }
`;
export const IconImg = styled.img`
  width: 60px;
  @media screen and (max-width: 500px) {
    width: 60px;
  }
`;
export const IconTextEllipsis = IconText.extend`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  max-width: 100px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 500px) {
    width: 85px;
  }
  @media screen and (max-width: 340px) {
    width: 60px;
  }
`;
export const DownloadBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DownloadItem = styled.div`
  font-size: 17px;
  color: #757575;
  display: flex;
`;
export const DownloadItemBlock = styled.div`
  display: flex;
  margin-right: 10px;
`;
export const PreviewImg = styled.img`
  height: 95px;
  width: 100%;
`;

export const PreviewImgBlock = styled.div`
  width: 100px;
  height: 100px;
  padding: 3px;
  border: 1px solid #222;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
