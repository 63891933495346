import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './MyPositions'
import {
  addExperience,
  removeExperience,
  editExperience
} from 'Redux/actions/experience'
import { loadCompanies } from 'Redux/actions/companies'

import { getUserExperience } from 'Redux/selectors/user'
import { getCompanies } from 'Redux/selectors/companies'

const actions = {
  onEditExperience: editExperience,
  onAddExperience: addExperience,
  onRemoveExperience: removeExperience,
  onLoadCompanies: loadCompanies
}

const selector = createStructuredSelector({
  experience: getUserExperience,
  companies: getCompanies
})

export const MyPositions = connect(
  selector,
  actions
)(Component)
