export const SET_HEADER_CAT = 'main/SET_HEADER_CAT'
export const setHeaderCat = catID => {
    return {
        type:SET_HEADER_CAT,
        payload:catID
    }
}
export const DELETE_HEADER_CAT = 'main/DELETE_HEADER_CAT'
export const deleteHeaderCat = catID => {
    return {
        type:DELETE_HEADER_CAT,
        payload:catID
    }
}