import styled from 'styled-components'
import * as colors from 'Theme/colors'

export const Container = styled.div`
  float: left;
`
export const TextBox = styled.input`
    padding: ${props => props.padding || '13px'};
    font-size: ${props => props.fontSize || '16px'}
    border: 1px solid ${props =>
      props.error ? colors.notificationRed : colors.stepGrey};
    border-radius: ${props => props.borderRadius || '9.6px'};
    max-height: ${props => props.maxHeight};
    outline: none;
`
export const Items = styled.ul`
  position: absolute;
  top: ${props => props.top || 0};
  left: ${props => props.left || 0};
  padding: 8px 0;
  border-radius: ${props => props.borderRadius || '9.6px'};
  list-style: none;
  margin: 3.2px 0;
  background-color: white;
  border: 1px solid ${colors.stepGrey};
  max-height: 300px;
  overflow: auto;
  z-index: 2;
  &::-webkit-scrollbar {
    background-color: #dbd9d9;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
`
export const Item = styled.li`
  display: flex;
  padding: 3.2px 13px;
  align-items: center;
  &:hover {
    background-color: ${props => props.hoverColor || 'pink'};
  }
  cursor: pointer;
`
export const Icon = styled.div`
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
`
export const PrimaryText = styled.span`
  color: black;
  font-size: 15px;
`
export const SecondaryText = styled.span`
  color: grey;
  font-size: 13px;
  margin-left: 8px;
`
export const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
`
export const SpinnerContainer = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
`
