import styled from 'styled-components'
import * as colors from 'Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const Container = styled.div`
  min-width: 220px;
  display: ${props => (props.visible ? 'block' : 'none')};
  color: ${colors.darkGreyFont};
  cursor: pointer;
  @media (max-width: 460px) {
    min-width: 150px;
  }
`
export const Header = styled.div`
  display: flex;
  padding: 8px;
  margin-top: 15px;
  background-color: white;
  border: 1px solid #bcbaba;
  cursor: pointer;
`
export const Arrow = styled.span`
  font-size: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`
export const HeaderText = styled.span`
  flex: 1;
  align-items: center;
  display: flex;
  font-size: 12px;
  color: grey;
`
export const List = styled(PerfectScrollbar).attrs({
  component: 'ul'
})`
  overflow: auto;
  background-color: white;
  border: 1px solid #bcbaba;
  margin: 3.2px 0 0 0;
  padding: 8px 0;
  max-height: 180px;
  width: 220px;
`
export const ListItem = styled.li`
  padding: 1.6px 7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid ${props => (props.bordered ? '#bcbaba' : 'transparent')};
  border-bottom: 1px solid
    ${props => (props.bordered ? '#bcbaba' : 'transparent')};
`
export const ListText = styled.div`
  font-size: 13px;
  color: grey;
  margin-left: 16px;
  padding: 5px 0;
`
export const CheckBox = styled.input`
  height: 15px;
  width: 15px;
  margin: 0;
  padding: 0;
`
export const SearchIconContainer = styled.div`
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: grey;
`
export const SearchIcon = styled.i.attrs({ className: 'fas fa-search' })``
export const TextBox = styled.input`
  font-size: 13px;
  flex: 1;
  border: none;
  outline: none;
  color: grey;
  margin-left: 16px;
  padding: 1.6px 0;
  color: ${colors.darkGreyFont};
`
export const ListContainer = styled.div`
  display: none;
  position: fixed;
  left: calc(50% - 94px) !important;
  z-index: 2;
`
export const ArrowIcon = styled.i.attrs({ className: 'fas fa-chevron-down' })`
  transition: 0.2s transform;
`
