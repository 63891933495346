import styled from 'styled-components'

export const LineConteiner = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 60px; 
  @media (max-width: 799px) {
    margin-right: 0;
    margin-top: 0;
  }
  @media (max-width: 799px) {
    margin-left: 10px;
  }
`
export const CircleBorder = styled.div`
  position: relative;
  right: 2px;
  background: white;
  border: 2px solid #583ea1;
  border-radius: 50px;
  width: 51px;
  min-height: 51px;
`
export const Circle = styled.div`
  background: #583ea1;
  height: 25px;
  border-radius: 20px;
  width: 25px;
  margin: 0 auto;
  margin-top: 11px;
  -moz-transition: all 3s ease-out;
  -o-transition: all 3s ease-out;
  -webkit-transition: all 3s ease-out;
  :hover {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
  }
`
export const SimpleCircle = styled.div`
  background: #583ea1;
  height: 25px;
  border-radius: 20px;
  width: 25px;
  margin-left: 11px;
  :hover {
    box-shadow: 0 0 35px 0 #583ea1;
  }
`
export const VerticalLine = styled.div`
  background: #583ea1;
  height: 170px;
  width: 3px;
  margin-left: 22px;
`
