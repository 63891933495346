import Component from './ParsedWindow'
import { connect } from 'react-redux'
import { register } from 'Redux/actions/auth'
import { createStructuredSelector } from 'reselect'
import { isAuthenticate } from 'Redux/selectors/auth'
import { getRegisterData } from 'Redux/selectors/register';

const actions = {
  onRegister: register
}
const selector = createStructuredSelector({
  isAuthenticate,
  getRegisterData
})

export default connect(
  selector,
  actions
)(Component)
