import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to top,
    rgba(0, 101, 255, 0.7),
    rgba(0, 101, 255, 0.7) 35%,
    rgba(95, 12, 211, 0.7)
  );
`
