import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import * as routes from "Constants/routes";
import MediaQuery from "react-responsive";

import { isOverflow } from "Helpers/htmlElements";

import { SearchModal } from "./innerBlocks";
import HeaderCategoriesList from "../HeaderCategoriesList";
import "./style.css";
import {
  Container,
  SiteLogo,
  LittleLogoContainer,
  LittleLogo,
  Menu,
  NotificationsLink,
  LinkUnauthorized,
} from "./slyled";

import modalRoutes from "Constants/modal_routes";
import Notification from "./innerBlocks/Notification/Notification";

import logo from "images/header/MrJobsLogo.svg";
import home from "images/header/mrJobs_home.png";
import lupe from "images/header/lupeLogo.png";

class HeaderMenu extends Component {
  state = {
    isSearchModalOpen: false,
    viewButton: true,
    overflow: false,
    notificationsLength: 0,
    activeCategory: {
      title: "notification",
      id: 0,
    },
  };

  static getDerivedStateFromProps(props, state) {
    const { notifications, viewingDate } = props;
    const nextState = state;
    const notViewed = notifications.filter((item) => {
      return item.time > viewingDate;
    });
    nextState.notificationsLength = notViewed.length;

    return nextState;
  }

  componentWillMount() {
    const { onLoadNotifications ,onLoadCompanies} = this.props;
    onLoadNotifications();
    onLoadCompanies();
    
  }

  componentDidMount() {
    const { header, onUpdateDate, userEmail, isAuthenticate,predCompanies } = this.props;
    if (
      typeof header === "string" ||
      (_.isArray(header) && header.length === 1)
    ) {
      this.setState({ viewButton: false });
    }
    if (isAuthenticate) {
      onUpdateDate(userEmail);
    }
  }

  //eslint-disable-next-line
  UNSAFE_componentWillReceiveProps() {
    const { header } = this.props;

    if (
      typeof header === "string" ||
      (_.isArray(header) && header.length === 1)
    ) {
      this.setState({ viewButton: false });
    } else {
      this.setState({ viewButton: true });
    }
  }

  componentDidUpdate() {
    const overflow = isOverflow(document.getElementById("page_active"));

    if (overflow && !this.state.overflow) {
      this.setState({ overflow });
    }
  }

  handleSearch = (text) => {
    const { onSearchJobs, history } = this.props;
    this.setState({ isSearchModalOpen: false });
    onSearchJobs(text);
    history.push(routes.jobs);
  };

  handleSearchByParams = (params) => {
    const { onSearchJobsByParams, history } = this.props;
    //this.setState({ isSearchModalOpen: false })
    onSearchJobsByParams(params);
    //history.push(routes.jobs)
  };

  toggleViewSearchModal = () => {
    this.setState((prevState) => ({
      isSearchModalOpen: !prevState.isSearchModalOpen,
    }));
  };

  handleDeleteBtnClick = (event, value) => {
    const { history } = this.props;

    history.push(routes.jobs);
    event.stopPropagation();

    if (typeof value.onDelete === "function") value.onDelete(value);
  };

  handleLinkWithAuthClick = () => {
    const { isAuthenticate } = this.props;
    if (!isAuthenticate) {
      this.props.onSwitchModal(modalRoutes.login);
    }
  };

  render() {
    const {
      onHeaderCategoryClick,
      history,
      header,
      activeHeaderCategory,
      userJobs,
      userJobsInfo,
      withHeaderNavigate,
      isAuthenticate,
      settingsList,
      notifications,
      viewingDate,predCompanies,
      user,
    } = this.props;
    const { notificationsLength } = this.state;


    const { viewButton, overflow, isSearchModalOpen } = this.state;
    const ContainerWithAuth = isAuthenticate ? Link : LinkUnauthorized;
    return (
      <MediaQuery minDeviceWidth={500}>
        <Container>
          <Menu>
            <Link to={routes.initial}>
              <SiteLogo>
                <img alt="Mr.JOBS" src={logo} />
              </SiteLogo>
            </Link>
            {/* <LittleLogoContainer>
              <Link to={routes.initial}>
                <LittleLogo  alt="Mr.JOBS Home" src={home} />
              </Link>
            </LittleLogoContainer> */}
            {/* <LittleLogoContainer
            // onClick={this.toggleViewSearchModal}
            >
              <LittleLogo alt="Jobs Search" src={lupe} />
            </LittleLogoContainer> */}
            <LittleLogoContainer>
              <Notification
                notificationsLen={notificationsLength}
                notification={notifications}
                userData={user}
                companies={predCompanies}
              />
              {/* <ContainerWithAuth
                to={routes.notification}
                onClick={this.handleLinkWithAuthClick}
              >
                <NotificationsLink />
              </ContainerWithAuth> */}
            </LittleLogoContainer>
            <div
              style={{
                width: "1px",
                height: "25px",
                backgroundColor: "#757575",
                marginLeft: "5px",
              }}
            ></div>
          </Menu>
          <HeaderCategoriesList
            activeHeaderCategory={activeHeaderCategory}
            header={header}
            history={history}
            onDeleteBtnClick={this.handleDeleteBtnClick}
            onHeaderCategoryClick={onHeaderCategoryClick}
            overflow={overflow}
            settingsList={settingsList}
            viewButton={viewButton}
            withHeaderNavigate={withHeaderNavigate}
          />
          <div />
          <SearchModal
            history={history}
            isOpen={isSearchModalOpen}
            searchResult={userJobs.Search}
            userJobsInfo={userJobsInfo}
            onClose={this.toggleViewSearchModal}
            onSearch={this.handleSearch}
            onSearchByParams={this.handleSearchByParams}
          />
        </Container>
      </MediaQuery>
    );
  }
}
export default HeaderMenu;
