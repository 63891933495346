import styled from "styled-components";

export const Content = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10%;
`;
export const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0 8px;
  position: fixed;
  bottom: 5%;
  width: 87vw;
`;
export const BottomBlockBig = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 8px;
`;
export const ContentMob = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90vh;
`;
export const AddAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0 15px;
  margin: 7px 0 0 15px;
`;
export const GreyText = styled.div`
  padding-left: 25px;
  color: grey;
  font-size: 15px;
`;
export const Form = styled.div`
  margin: 0 24px 0 0;
`;
export const InfoText = styled.p`
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
`;
export const InfoLink = styled.a`
  font-size: 10px;
  font-weight: bold;
`;
export const AvatarChooseContainer = styled.div``;
export const IconBlockContainer = styled.div`
  position: absolute;
`;
export const FileInput = styled.input`
  width: 80px;
  height: 80px;
  border-radius: 15px;
  opacity: 0;
  cursor: pointer;
`;
export const Icon = styled.img`
  width: 80%;
  height: 80%;
`;
export const GenderContainer = styled.div`
  display: flex;
`;
export const styles = (theme) => ({
  group: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    fontSize: "20px",
  },
  radioBtn: {
    fontSize: "14px",
  },
});

export const ButtonFix = styled.div`
  position: relative;
  top: 1px;
`;
