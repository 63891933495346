import React, { PureComponent } from 'react'
import * as date from 'Helpers/date'
import moment from 'moment'
import MediaQuery from 'react-responsive'
import {
  Block,
  DateText,
  Title,
  InfoColumn,
  Text,
  Button,
  BornIcon,
  MainContainer,
  RightSide,
  LeftSide,
  SideContainer,
  SimpleCircle,
  VerticalLine,
  LineConteiner,
  CircleBorder,
  Circle,
  ContentContainer,
  TabletSide,
  CircleContainer,
  DescriptionDesctop,
  DescriptionTablet,
  DescriptionMobile
} from './styled'

import button from 'images/careerTimeLine/button.png'
import born from 'images/careerTimeLine/born.png'
//todo оптимизация компонента
export default class TimeLine extends PureComponent {
  handleHoverItem = event => {
    //todo hover
  }

  renderLine = () => {
    return (
      <LineConteiner>
        <CircleBorder>
          <Circle />
        </CircleBorder>
        {this.props.data.map(value => {
          return (
            <CircleContainer key={value.time}>
              <VerticalLine />
              <SimpleCircle />
            </CircleContainer>
          )
        })}
      </LineConteiner>
    )
  }
  renderDesktopView = position => {
    const leftContent = []
    const rightContent = []

    rightContent.push(
      <Block>
        <BornIcon src={born} />
        <Title>Born - 08 December 1990</Title>
      </Block>
    )

    this.props.data.forEach((item, index) => {
      if (index % 2 === 0) {
        leftContent.push(
          <React.Fragment key={item.id}>
            <ContentContainer left={true}>
              <DescriptionDesctop
                style={{ flexDirection: 'row-reverse' }}
                onMouseOver={this.handleHoverItem}
              >
                <DateText border="left">
                  {date.formatForTimeLine(item.start_date)} <br /> to <br />
                  {date.formatForTimeLine(item.end_date)}
                </DateText>
                <Button src={button} />
                <InfoColumn>
                  <Title>{item.company || item.university}</Title>
                  <Text>{item.position || item.profession}</Text>
                </InfoColumn>
              </DescriptionDesctop>
            </ContentContainer>
          </React.Fragment>
        )
      } else {
        rightContent.push(
          <React.Fragment key={item.id}>
            <ContentContainer first={index === 1} right={true}>
              <DescriptionDesctop>
                <DateText border="right">
                  {date.formatForTimeLine(item.start_date)} <br /> to <br />
                  {date.formatForTimeLine(item.end_date)}
                </DateText>
                <InfoColumn>
                  <Title>{item.company || item.university}</Title>
                  <Text>{item.position || item.profession}</Text>
                </InfoColumn>
                <Button src={button} />
              </DescriptionDesctop>
            </ContentContainer>
          </React.Fragment>
        )
      }
    })
    if (position === 'left') return leftContent
    if (position === 'right') return rightContent
  }
  renderTabletView = () => {
    return (
      <React.Fragment>
        <Block>
          <BornIcon src={born} />
          <Title>Born - 08 December 1990</Title>
        </Block>
        {this.props.data.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <ContentContainer
                style={{
                  marginBottom: `${index === 0 ? '125' : '95'}px`,
                  paddingLeft: '15px'
                }}
              >
                <DescriptionTablet>
                  <DateText border="left">
                    {date.formatForTimeLine(item.start_date)}
                    <br /> to <br />
                    {date.formatForTimeLine(item.end_date)}
                  </DateText>
                  <Button src={button} />
                  <InfoColumn>
                    <Title>{item.company || item.university}</Title>
                    <Text>{item.position || item.profession}</Text>
                  </InfoColumn>
                </DescriptionTablet>
              </ContentContainer>
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }
  renderMobilView = () => {
    return (
      <React.Fragment>
        <Block>
          <BornIcon src={born} />
          <Title>Born - 08 December 1990</Title>
        </Block>
        {this.props.data.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <ContentContainer
                style={{
                  marginBottom: `${index === 0 ? '125' : '95'}px`,
                  marginTop: '0'
                }}
              >
                <DescriptionMobile>
                  <DateText mobile>
                    {moment(item.start_date).format('YYYY-MM-DD')} to{' '}
                    {moment(item.end_date).format('YYYY-MM-DD')}
                  </DateText>
                  <InfoColumn>
                    <Title>{item.position || item.profession}</Title>
                  </InfoColumn>
                </DescriptionMobile>
              </ContentContainer>
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }
  render() {
    return (
      <React.Fragment>
        <MediaQuery maxDeviceWidth={499}>
          <MainContainer>
            <SideContainer>
              <LeftSide>{this.renderMobilView()}</LeftSide>
            </SideContainer>
            {this.renderLine()}
          </MainContainer>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1099} minDeviceWidth={500}>
          <MainContainer>
            <SideContainer>
              <TabletSide>{this.renderTabletView()}</TabletSide>
            </SideContainer>
            {this.renderLine()}
          </MainContainer>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1100}>
          <MainContainer>
            <LeftSide>{this.renderDesktopView('left')}</LeftSide>
            {this.renderLine()}
            <RightSide>{this.renderDesktopView('right')}</RightSide>
          </MainContainer>
        </MediaQuery>
      </React.Fragment>
    )
  }
}
