import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { FilterItem } from '../../Blocks/FilterItem/FilterItem'
import { mountFilter } from '../../../Helpers/mountFilter'
import { unmountFilter } from '../../../Helpers/unmountFilter'

import {
  Button,
  CloseBtn,
  FilterContainer,JobForYouFilterTitle,
  FilterTitle,
  Container
} from './styled'

export default class DropdownJobType extends PureComponent {
  componentDidMount() {
    mountFilter('JobType')
  }

  componentWillUnmount() {
    unmountFilter('JobType')
  }

  render() {
    const {
      onFilterClose,
      onJobTypeFilterItemClick,
      onJobTypesFilterOkBtnClick,
      jobTypes,
      isUnmount
    } = this.props

    return ReactDOM.createPortal(
      <OutsideClickHandler onOutsideClick={onFilterClose}>
        <Container id="JobType" isUnmount={isUnmount}>
          <FilterContainer>
            <FilterTitle>JOB TYPE</FilterTitle>
            <CloseBtn onClick={onFilterClose}>
              <i className="fas fa-times" />
            </CloseBtn>
          </FilterContainer>
          <JobForYouFilterTitle>Selected Job Types: </JobForYouFilterTitle>

          <FilterItem items={jobTypes} onItemClick={onJobTypeFilterItemClick} />

          <Button onClick={onJobTypesFilterOkBtnClick}>
            <i className="fas fa-check-circle" /> Okay!
          </Button>
        </Container>
      </OutsideClickHandler>,
      document.getElementById('root-modal')
    )
  }
}
