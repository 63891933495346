import React from 'react'
import { Container, Content, Close } from './styles'

export default ({
  iconName,
  borderColor,
  isCloseBtn = false,
  // isCloseBtn2 = false,
  checked = false,
  children,
  customStyle,
  onClose
}) => {
  return (
    <Container borderColor={borderColor} checked={checked}>
      <Close onClick={onClose} showed={isCloseBtn}>
        <i className="fas fa-times" />
      </Close>
      <Content>
        {iconName ? (
          <img src={iconName} style={{ ...customStyle }} />
        ) : (
          children
        )}
      </Content>
    </Container>
  )
}
