import { createReducer } from 'Helpers/redux'
import _ from 'lodash'

import {
  LOAD_PROFILE,
  FETCH_USER,
  UPDATE_CANDIDATE,
  UPDATE_NOTIFICATION_DATA
} from 'Redux/actions/user'
import { LOG_IN, LOG_OUT, REGISTER } from 'Redux/actions/auth'
import { FOLLOW, UNFOLLOW } from 'Redux/actions/industries'
import {
  REMOVE_PERSONALITY,
  EDIT_PERSONALITIES
} from 'Redux/actions/personalities'
import {
  REMOVE_EXPECTATION,
  EDIT_EXPECTATIONS
} from 'Redux/actions/expectations'
import {
  REMOVE_HIGH_SCHOOL_ED,
  EDIT_HIGH_SCHOOL_ED
} from 'Redux/actions/highSchoolEd'
import {
  REMOVE_ACADEMIC_QUALIFICATIONS,
  EDIT_ACADEMIC_QUALIFICATIONS,
  CHANGE_DATA_ACADEMIC_QUALIFICATIONS
} from 'Redux/actions/academicQualifications'
import {
  REMOVE_PROFESSIONAL_QUALIFICATIONS,
  EDIT_PROFESSIONAL_QUALIFICATIONS,
  CHANGE_DATA_PROFESSIONAL_QUALIFICATIONS
} from 'Redux/actions/professionalQualifications'
import { REMOVE_EXPERTISES, EDIT_EXPERTISES } from 'Redux/actions/expertises'
import {
  REMOVE_SUB_EXPERTISES,
  EDIT_SUB_EXPERTISES,
  UPDATE_SUB_EXPERTISES
} from 'Redux/actions/subExpertises'
import {
  LOAD_SAVED_VACANCIES,
  SAVE_VACANCY,
  DELETE_FROM_SAVED_VACANCIES
} from 'Redux/actions/jobs'
import {
  REMOVE_EXPERIENCE,
  ADD_EXPERIENCE,
  EDIT_EXPERIENCE
} from 'Redux/actions/experience'
import { REMOVE_JOB_TYPE, EDIT_JOB_TYPES } from 'Redux/actions/jobTypes'
import { LOAD_NOTIFICATIONS } from '../actions/notifications'
import { REMOVE_COMPANY, EDIT_COMPANIES } from 'Redux/actions/companies'
import {
  LOAD_INVITED_EMAILS,
  LOAD_SAVED_EMAILS,
  ADD_SUP_INVITE_EMAIL,
  ADD_SAVED_EMAIL,
  DELETE_SUP_INVITE_EMAIL,
  REMOVE_SAVED_EMAIL
} from 'Redux/actions/friendSupport'
import {
  ADD_EDUCTIONS,
  EDIT_EDUCATIONS,
  REMOVE_EDUCATIONS
} from 'Redux/actions/eductions'
import { UPDATE_LANG, EDIT_LANG_LEVEL } from '../actions/languages'
import {
  ADD_ACTIVITIES,
  EDIT_ACTIVITIES,
  REMOVE_ACTIVITIES
} from '../actions/activities'
import { ADD_REFEREE, EDIT_REFEREE, REMOVE_REFEREE } from '../actions/referee'
import { UPDATE_FEEDBACK_STATE } from '../actions/utils'
import { notificationType } from '../../Constants/socketEventTypes'

const initialState = {
  user: {},
  education: [],
  experience: [],
  expectations: [],
  personalities: [],
  followedIndustries: [],
  highSchoolEd: [],
  academicQualifications: [],
  profQualf: [],
  expertises: [],
  subExpertises: {},
  savedVacancies: [],
  notifications: [],
  jobTypes: [],
  companies: [],
  languages: [],
  activities: [],
  feedbacks: [],
  referee: [],
  supportEmails: {
    supportEmails: [],
    loadInfo: {
      isLoading: false,
      isLoaded: false,
      error: false
    }
  },
  savedEmails: [],
  profLoadInfo: {
    isLoading: false,
    isLoaded: false,
    error: null
  },
  fetchUserLoadInfo: {
    isLoading: false,
    isLoaded: false,
    error: null
  },
  confirmedData: {
    notCorfimed: false,
    email: null,
    id: null
  }
}

const handlers = {
  [LOG_IN.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      confirmedData: {
        notCorfimed: payload.notCorfimed ? true : false,
        email: payload.email ? payload.email : null,
        id: payload.id ? payload.id : null
      }
    }
  },
  [LOG_IN.SUCCESS]: (state, { payload }) => {
    const {
      user,
      education,
      experience,
      followedIndustries,
      personalities,
      expectations,
      highSchoolEd,
      expertises,
      subExpertises,
      notifications
    } = payload.data

    return {
      ...state,
      user,
      education,
      experience,
      personalities,
      expectations,
      followedIndustries,
      highSchoolEd,
      expertises,
      subExpertises,
      notifications
    }
  },
  [LOG_OUT.SUCCESS]: (state, { payload }) => {
    return initialState
  },
  [FOLLOW.SUCCESS]: (state, { payload }) => {
    const existingFollowedIndustry = _.find(
      state.followedIndustries,
      ind => ind.industry_id === payload.data.industry_id
    )
    if (existingFollowedIndustry) return state

    return {
      ...state,
      followedIndustries: [...state.followedIndustries, payload.data]
    }
  },
  [UNFOLLOW.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      followedIndustries: state.followedIndustries.filter(
        ind => ind.industry_id !== payload.data.industry_id
      )
    }
  },
  [REGISTER.SUCCESS]: (state, { payload }) => {
    const {
      user,
      education,
      experience,
      personalities,
      expectations,
      followedIndustries,
      highSchoolEd,
      academicQualifications
    } = payload.data

    return {
      ...state,
      user,
      education,
      experience,
      personalities,
      expectations,
      followedIndustries,
      highSchoolEd,
      academicQualifications
    }
  },
  [REMOVE_PERSONALITY.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      personalities: data
    }
  },
  [REMOVE_EXPECTATION.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      expectations: data
    }
  },
  [REMOVE_HIGH_SCHOOL_ED.REQUEST]: (state, { query }) => {
    const id = query.id[0]
    const highSchoolEd = state.highSchoolEd.filter(el => el.id !== id)
    return {
      ...state,
      highSchoolEd
    }
  },
  [REMOVE_ACADEMIC_QUALIFICATIONS.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      academicQualifications: data
    }
  },
  [REMOVE_PROFESSIONAL_QUALIFICATIONS.SUCCESS]: (
    state,
    { payload: { data } }
  ) => {
    return {
      ...state,
      profQualf: data
    }
  },
  [REMOVE_EXPERTISES.REQUEST]: (state, { query }) => {
    const id = query.id[0]
    const expertises = state.expertises.filter(el => el.id !== id)
    // const subExpertises = state.subExpertises
    // delete subExpertises[id]
    return {
      ...state,
      expertises
    }
  },
  [REMOVE_SUB_EXPERTISES.REQUEST]: (state, { query }) => {
    const id = query.id[0]
    const subExpertises = state.subExpertises.filter(el => el.id !== id)
    return {
      ...state,
      subExpertises
    }
  },
  [LOAD_PROFILE.REQUEST]: state => {
    return {
      ...state,
      profLoadInfo: {
        isLoading: true,
        isLoaded: false,
        error: null
      }
    }
  },
  [LOAD_PROFILE.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      expectations: data.expectations,
      personalities: data.personalities,
      highSchoolEd: data.highSchoolEd,
      academicQualifications: data.academicQualifications,
      expertises: data.expertises,
      profQualf: data.profQualf,
      experties: data.experties,
      subExpertises: data.subExpertises,
      jobTypes: data.jobTypes,
      companies: data.companies,
      feedbacks: data.feedbacks,
      activities: data.activities,
      referee: data.referee,
      profLoadInfo: {
        isLoading: false,
        isLoaded: true,
        error: null
      }
    }
  },
  [LOAD_PROFILE.FAILURE]: state => {
    return {
      ...state,
      profLoadInfo: {
        isLoading: false,
        isLoaded: true,
        error: true
      }
    }
  },
  [EDIT_EXPECTATIONS.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      expectations: data.data
    }
  },
  [EDIT_PERSONALITIES.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      personalities: data.data
    }
  },
  [EDIT_HIGH_SCHOOL_ED.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      highSchoolEd: data
    }
  },
  [EDIT_ACADEMIC_QUALIFICATIONS.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      academicQualifications: data
    }
  },
  [CHANGE_DATA_ACADEMIC_QUALIFICATIONS.SUCCESS]: (
    state,
    { payload: { data } }
  ) => {
    return {
      ...state,
      academicQualifications: data
    }
  },
  [EDIT_PROFESSIONAL_QUALIFICATIONS.SUCCESS]: (
    state,
    { payload: { data } }
  ) => {
    return {
      ...state,
      profQualf: data
    }
  },
  [CHANGE_DATA_PROFESSIONAL_QUALIFICATIONS.SUCCESS]: (
    state,
    { payload: { data } }
  ) => {
    return {
      ...state,
      profQualf: data
    }
  },
  [EDIT_EXPERTISES.SUCCESS]: (state, { payload: { data } }) => {
    const { expertises, deleted } = data
    const subExpertises = Object.assign({}, state.subExpertises)

    deleted.forEach(el => delete subExpertises[el])
    return {
      ...state,
      expertises,
      subExpertises
    }
  },
  [EDIT_SUB_EXPERTISES.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      subExpertises: data
    }
  },
  [UPDATE_SUB_EXPERTISES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      subExpertises: payload.data
    }
  },
  [FETCH_USER.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      fetchUserLoadInfo: {
        isLoading: true,
        isLoaded: false,
        error: null
      }
    }
  },
  [FETCH_USER.SUCCESS]: (state, { payload }) => {
    const {
      user,
      education,
      experience,
      followedIndustries,
      personalities,
      expectations,
      highSchoolEd,
      expertises,
      subExpertises,
      notifications,
      jobTypes,
      companies,
      languages
    } = payload.data

    return {
      ...state,
      user,
      education,
      experience,
      personalities,
      expectations,
      followedIndustries,
      highSchoolEd,
      expertises,
      subExpertises,
      notifications,
      jobTypes,
      companies,
      languages,
      fetchUserLoadInfo: {
        isLoading: false,
        isLoaded: true,
        error: null
      }
    }
  },
  [FETCH_USER.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      fetchUserLoadInfo: {
        isLoading: false,
        isLoaded: true,
        error: payload.data
      }
    }
  },
  [LOAD_SAVED_VACANCIES.SUCCESS]: (state, { payload }) => {
    if (_.isArray(payload.data)) {
      return {
        ...state,
        savedVacancies: payload.data
      }
    }

    return state
  },
  [SAVE_VACANCY.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      savedVacancies: [...state.savedVacancies, payload.data]
    }
  },
  [DELETE_FROM_SAVED_VACANCIES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      savedVacancies: state.savedVacancies.filter(
        vac => vac.id !== payload.data.vacancyId
      )
    }
  },
  [LOAD_SAVED_VACANCIES.SUCCESS]: (state, { payload }) => {
    if (_.isArray(payload.data)) {
      return {
        ...state,
        savedVacancies: payload.data
      }
    }

    return state
  },
  [SAVE_VACANCY.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      savedVacancies: [...state.savedVacancies, payload.data]
    }
  },
  [DELETE_FROM_SAVED_VACANCIES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      savedVacancies: state.savedVacancies.filter(
        vac => vac.id !== payload.data.vacancyId
      )
    }
  },
  [REMOVE_EXPERIENCE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      experience: payload.data
    }
  },
  [ADD_EXPERIENCE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      experience: payload.data[0]
    }
  },
  [ADD_EDUCTIONS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      education: [...state.education, ...payload.data]
    }
  },
  [EDIT_EDUCATIONS.SUCCESS]: (state, { payload }) => {
    const { data } = payload.data
    const education = state.education.map(item =>
      item.id === data.id ? data : item
    )
    return {
      ...state,
      education
    }
  },
  [REMOVE_EDUCATIONS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      education: state.education.filter(item => item.id !== payload.data.id)
    }
  },
  [EDIT_EXPERIENCE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      experience: payload.data
    }
  },
  [LOAD_NOTIFICATIONS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      notifications: payload.data
    }
  },
  [REMOVE_JOB_TYPE.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      jobTypes: data
    }
  },
  [EDIT_JOB_TYPES.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      jobTypes: data.data
    }
  },
  [REMOVE_COMPANY.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      companies: data
    }
  },
  [EDIT_COMPANIES.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      companies: data.data
    }
  },
  [LOAD_INVITED_EMAILS.REQUEST]: state => {
    return {
      ...state,
      supportEmails: {
        supportEmails: state.supportEmails.supportEmails,
        loadInfo: {
          isLoading: true,
          isUploaded: false,
          error: false
        }
      }
    }
  },
  [LOAD_INVITED_EMAILS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      supportEmails: {
        supportEmails: payload.data,
        loadInfo: {
          isLoading: false,
          isUploaded: false,
          error: null
        }
      }
    }
  },
  [LOAD_INVITED_EMAILS.FAILURE]: state => {
    return {
      ...state,
      supportEmails: {
        supportEmails: state.supportEmails.supportEmails,
        loadInfo: {
          isLoading: false,
          isUploaded: false,
          error: true
        }
      }
    }
  },
  [ADD_SUP_INVITE_EMAIL.REQUEST]: state => {
    return {
      ...state,
      supportEmails: {
        supportEmails: state.supportEmails.supportEmails,
        loadInfo: {
          isLoading: true,
          isUploaded: false
        }
      }
    }
  },
  [ADD_SUP_INVITE_EMAIL.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      supportEmails: {
        supportEmails: payload.data,
        loadInfo: {
          isLoading: false,
          isUploaded: true
        }
      }
    }
  },
  [ADD_SUP_INVITE_EMAIL.FAILURE]: state => {
    return {
      ...state,
      supportEmails: {
        supportEmails: state.supportEmails.supportEmails,
        loadInfo: {
          isLoading: false,
          isUploaded: false,
          error: true
        }
      }
    }
  },
  [DELETE_SUP_INVITE_EMAIL.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      supportEmails: payload.data
    }
  },
  [UPDATE_CANDIDATE.SUCCESS]: (state, { payload: { data } }) => {
    // console.log('payload UPDATE_CANDIDATE', data)
    return {
      ...state,
      user: { ...state.user, ...data.updatedData }
    }
  },
  [UPDATE_CANDIDATE.FAILURE]: (state, { payload }) => {
    // console.log('payload UPDATE_CANDIDATE FAILURE', payload)
    return {
      ...state
    }
  },
  [LOAD_SAVED_EMAILS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      savedEmails: payload.data
    }
  },
  [ADD_SAVED_EMAIL.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      savedEmails: payload.data
    }
  },

  [REMOVE_SAVED_EMAIL.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      savedEmails: payload.data
    }
  },
  [UPDATE_NOTIFICATION_DATA.SUCCESS]: (state, { payload }) => {
    const { notification_data } = payload.data
    return {
      ...state,
      user: {
        ...state.user,
        notification_data
      }
    }
  },
  [UPDATE_LANG.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      languages: payload.data.result
    }
  },
  [EDIT_LANG_LEVEL.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      languages: payload.data
    }
  },
  [ADD_ACTIVITIES.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      activities: data
    }
  },
  [EDIT_ACTIVITIES.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      activities: data
    }
  },
  [REMOVE_ACTIVITIES.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      activities: data
    }
  },
  [UPDATE_FEEDBACK_STATE.SUCCESS]: (state, { payload: { data } }) => {
    const { feedbacks } = state
    return {
      ...state,
      feedbacks: data.map((item, i) => ({
        ...feedbacks[i],
        isActive: item.isActive
      }))
    }
  },
  [ADD_REFEREE.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      referee: data
    }
  },
  [EDIT_REFEREE.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      referee: data
    }
  },
  [REMOVE_REFEREE.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      referee: data
    }
  },
  [notificationType]: (state, { payload: { data } }) => {
    return {
      ...state,
      notifications: data
    }
  }
}

export default createReducer(initialState, handlers)
