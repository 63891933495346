import { createReducer } from 'Helpers/redux'

import { LOAD_ACADEMIC_QUALIFICATIONS } from 'Redux/actions/academicQualifications'

const initialState = {
  academicQualifications: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const handlers = {
  [LOAD_ACADEMIC_QUALIFICATIONS.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD_ACADEMIC_QUALIFICATIONS.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: payload.data
      }
    }
  },
  [LOAD_ACADEMIC_QUALIFICATIONS.SUCCESS]: (state, { payload }) => {
    return {
      academicQualifications: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
