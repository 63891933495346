import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './WhoAmI'
import {
  loadPersonalities,
  addPersonality,
  removePersonality,
  resetPersonalities,
  editPersonalities
} from 'Redux/actions/personalities'
import {
  loadExpectations,
  addExpectation,
  removeExpectation,
  resetExpectations,
  editExpectations
} from 'Redux/actions/expectations'
import { udpdateCandidate } from 'Redux/actions/user'
import {
  loadJobTypes,
  removeJobType,
  editJobTypes
} from 'Redux/actions/jobTypes'
import {
  loadCompanies,
  removeCompany,
  editCompanies
} from 'Redux/actions/companies'
import {
  updateLanguages,
  editLanguageLevel,
  getAllLanguages
} from '../../../../../Redux/actions/languages'

import { getJobTypes } from 'Redux/selectors/jobTypes'
import { getCompanies } from 'Redux/selectors/companies'
import { getPredictionsPersonalities } from 'Redux/selectors/personalities'
import { getExpectationsPredictions } from 'Redux/selectors/expectations'
import {
  getPersonalities,
  getExpectations,
  getUserJobTypes,
  getUserCompanies,
  getUser,
  getUserLanguages,
  getUserExperience,
  getUserAcademicQualifications,
  getUserActivities
} from 'Redux/selectors/user'

import { getLanguagesList } from '../../../../../Redux/selectors/languagesList'

const selector = createStructuredSelector({
  user: getUser,
  personalities: getPredictionsPersonalities,
  userPersonalities: getPersonalities,
  expectations: getExpectationsPredictions,
  userExpectations: getExpectations,
  languagesList: getLanguagesList,
  userLanguages: getUserLanguages,
  jobTypesList: getJobTypes,
  userJobTypes: getUserJobTypes,
  companiesList: getCompanies,
  userCompanies: getUserCompanies,
  userExperience: getUserExperience,
  userUniversity: getUserAcademicQualifications,
  userActivities: getUserActivities
})
const actionCreators = {
  //personalities
  onLoadUserPersonalities: loadPersonalities,
  onEditPersonalities: editPersonalities,
  onAddPersonality: addPersonality,
  onRemovePersonality: removePersonality,
  //onResetPersonalities: resetPersonalities,
  //expectations
  onLoadUserExpectations: loadExpectations,
  onUpdateUserExpectations: editExpectations,
  onAddExpectation: addExpectation,
  onRemoveExpectation: removeExpectation,
  // onResetExpectations: resetExpectations,
  //candidate
  onUpdateCandidate: udpdateCandidate,
  //languages
  onUpdateLanguages: updateLanguages,
  onEditLanguages: editLanguageLevel,
  onLoadLanguagesList: getAllLanguages,
  //job type
  onLoadJobTypes: loadJobTypes,
  onEditJobTypes: editJobTypes,
  onRemoveJobType: removeJobType,
  //companies
  onLoadCompanies: loadCompanies,
  onEditCompanies: editCompanies,
  onRemoveCompany: removeCompany
}

export const WhoAmI = connect(
  selector,
  actionCreators
)(Component)
