import React, { PureComponent } from 'react'
import _ from 'lodash'
import withSizes from 'react-sizes'
import htmlParser from 'react-html-parser'

import CircularProgressbar from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { Layout, CategoriesLoader } from '../../Blocks'

import { Container, LoaderContainer, Loader, Text } from './styled'

class Privacy extends PureComponent {
  constructor(props) {
    super()

    const { privacyList, onLoadPrivacy } = props

    onLoadPrivacy()
  }

  renderText = () => {
    const { privacyList } = this.props
    let text = ''
    if (privacyList.length) {
      text = privacyList.length && htmlParser(privacyList[0].text)
      return text
    }
    return text
  }

  render() {
    const { privacyLoadInfo } = this.props
    return (
      <Layout header={'Privacy'} history={this.props.history} notScroll={true}>
        {!privacyLoadInfo.loading && (
          <Container>
            <Text>{this.renderText()}</Text>
          </Container>
        )}
        {privacyLoadInfo.loading && <CategoriesLoader />}
      </Layout>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 600
})

export default withSizes(mapSizeToProps)(Privacy)
