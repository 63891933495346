import React from 'react'
import PropTypes from 'prop-types'

import { Link, Icon, Container } from './styled'

const SocialBlock = ({ facebook, linkedin, twitter }) => {
  return (
    <Container>
      {facebook && facebook !== 'null' ? (
        <Link href={facebook}>
          <Icon className={'fab fa-facebook'} />
        </Link>
      ) : null}
      {linkedin && linkedin !== 'null' ? (
        <Link href={linkedin}>
          <Icon className={'fab fa-linkedin'} />
        </Link>
      ) : null}
      {twitter && twitter !== 'null' ? (
        <Link href={twitter}>
          <Icon className={'fab fa-twitter-square'} />
        </Link>
      ) : null}
    </Container>
  )
}

SocialBlock.propTypes = {
  facebook: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string
}

export { SocialBlock }
