import Component from './Registration'
import { connect } from 'react-redux'
//import { createStructuredSelector } from 'reselect'

import { switchModal } from '../../../Redux/actions/utils'

const actions = {
  onSwitchWindow: switchModal
}

//const selector = createStructuredSelector({})

export default connect(
  null,
  actions
)(Component)
