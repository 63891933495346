import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_EXPERTISES = createAsyncAction('epxertises/LOAD')
export const loadExpertises = text => {
  return apiCall({
    endpoint: '/expertises',
    types: LOAD_EXPERTISES,
    query: {
      text
    },
    method: 'GET'
  })
}
export const REMOVE_EXPERTISES = createAsyncAction('epxertises/REMOVE')
export const removeExpertises = id => {
  return apiCall({
    endpoint: '/expertises/id',
    types: REMOVE_EXPERTISES,
    method: 'DELETE',
    query: {
      id: [id]
    }
  })
}
export const EDIT_EXPERTISES = createAsyncAction('epxertises/EDIT')
export const editExpertises = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/expertises',
    types: EDIT_EXPERTISES,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}
