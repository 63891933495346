import { createSelector } from 'reselect'

const expertisesData = store => store.expertises

export const getPredExpertises = createSelector(
  expertisesData,
  data => data.expertises
)

export const getExpertisesLoadInfo = createSelector(
  expertisesData,
  data => data.loadInfo
)
