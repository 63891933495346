import React, { PureComponent, Fragment } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import {
  OppBlock,
  FullWidthTabs,
  Layout,
  DeclineConfirmModal,
  EmptyList,
} from "Components/Blocks";
import { initial, messages, oppdoor } from "Constants/routes";

import { TABS } from "./data";
import { CardWrapper, TabsContainer, Loader } from "./styled";
import { EmailsModal } from "./innerBlocks";

import "./style.css";

class Opportunity extends PureComponent {
  state = {
    activeTabIndex: 0,
    isDeclineConfirmModalOpen: false,
    isNoInterestConfirmNodalOpen: false,
    vacancyToDeclineId: null,
    isDeclineNow: false,
    currentAppId: null,
    isEmailsModalOpen: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isAuthenticate, history, isLoaded } = nextProps;
    if (isLoaded && !isAuthenticate) {
      history.push(initial);
      return null;
    }
    return null;
  }

  componentDidMount() {
    const {
      onLoadApplications,
      onLoadIndustriesMetaTags,
      industriesMetaTags,
    } = this.props;

    onLoadApplications();
    if (!industriesMetaTags.length) onLoadIndustriesMetaTags();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      declineState,
      onUpdateNotifications,
      noInterestReqState,
    } = this.props;
    if (
      prevProps.declineState.success !== declineState.success ||
      prevProps.noInterestReqState.success !== noInterestReqState.success
    ) {
      onUpdateNotifications();
    }
  }

  getMeta = (tab = {}) => {
    const { industriesMetaTags } = this.props;

    if (_.isEmpty(industriesMetaTags)) {
      return { description: "", keywords: "" };
    } else {
      // console.log("tab.route", tab.route, industriesMetaTags);
      switch (tab.route) {
        case "pitched":
          return industriesMetaTags.find((el) => el.type === "pitched");
        case "in-progress":
          return industriesMetaTags.find((el) => el.type === "in_progress");
        case "turned-down":
          return industriesMetaTags.find((el) => el.type === "turned_down");
        default:
          return industriesMetaTags.find((el) => el.type === "accepted");
      }
    }
  };

  handleHeaderCategoryClick = (category, index) => {
    const route = `${category.route}`;
    const subRoute = category.subTitles ? category.subTitles[0].route : "";
    const { history } = this.props;
    history.push(`${oppdoor}/${route}/${subRoute}`);
    this.setState({ activeTabIndex: index });
  };

  handleDeclineBtnClick = (vacancyId, type, reason) => {
    if (type === "noInterest") {
      this.setState({
        isNoInterestConfirmNodalOpen: true,
        vacancyToDeclineId: vacancyId,
      });
    } else {
      this.setState({
        isDeclineConfirmModalOpen: true,
        vacancyToDeclineId: vacancyId,
        reason,
      });
      this.handleDeclineConfirm();
    }
  };

  handleAcceptBtnClick = (applicationId) => {
    this.props.onApplicationAccept({ applicationId });
  };

  handleDeclineConfirm = () => {
    const { onApplicationDecline } = this.props;
    const { vacancyToDeclineId, reason } = this.state;

    if (vacancyToDeclineId !== null) {
      onApplicationDecline({ applicationId: vacancyToDeclineId, reason });
    }

    this.setState({
      isDeclineConfirmModalOpen: false,
      isNoInterestConfirmNodalOpen: false,
      //vacancyToDeclineId: null
      isDeclineNow: true,
    });
  };

  handleNoInterestApplicationClick = (appId) => {
    this.handleDeclineBtnClick(appId, "noInterest");
  };

  handleOpenEmailsModal = (appId) => {
    this.setState({
      isEmailsModalOpen: true,
      currentAppId: appId,
    });
  };

  handleDeclineCancel = () => {
    this.setState({
      isNoInterestConfirmNodalOpen: false,
      isDeclineConfirmModalOpen: false,
      vacancyToDeclineId: null,
    });
  };

  handleMessageClick = (canId, contactPersId) => {
    this.props.onCreateChat(canId, contactPersId);
    this.props.history.push(messages, { contactPersId });
  };

  renderTabContents = (tabData) => {
    // console.log("tabData>>>>", tabData);

    const { applications, user } = this.props;

    const { subTitles, key } = tabData;

    if (_.isArray(subTitles)) {
      const applicationsByTab = _.get(applications, key);

      const applicationsByTabSorted = applicationsByTab;
      if (applicationsByTabSorted && applicationsByTab.user) {
        applicationsByTabSorted.user = applicationsByTab.user.sort(function(
          a,
          b
        ) {
          return b.id - a.id;
        });
      }
      // console.log("hello", applicationsByTabSorted);
      return (
        <FullWidthTabs
          applications={applicationsByTabSorted}
          tabs={subTitles}
          user={user}
          onApplicationAccept={this.handleAcceptBtnClick}
          onApplicationDecline={this.handleDeclineBtnClick}
          onFriendsSupportClick={this.handleOpenEmailsModal}
          onMessageClick={this.handleMessageClick}
          onNoInterestApplicationClick={this.handleNoInterestApplicationClick}
        />
      );
    }
    const activeCategoryApplications = _.get(applications, key);

    if (!_.isArray(activeCategoryApplications)) {
      return <EmptyList height="75vh" />;
    }

    // console.log("hello", applications.Accepted.length);
    // console.log("hello", applications["In progress"]);
    // console.log("hello", activeCategoryApplications.length);

    if (activeCategoryApplications.length == 0) {
      return <EmptyList height="75vh" />;
    }

    return (
      <CardWrapper>
        {activeCategoryApplications.map((application, index) => {
          return (
            <OppBlock
              card={application}
              key={index}
              user={user}
              onApplicationAccept={this.handleAcceptBtnClick}
              onApplicationDecline={this.handleDeclineBtnClick}
              onFriendsSupportClick={() =>
                this.handleOpenEmailsModal(application.id)
              }
              onMessageClick={this.handleMessageClick}
              onNoInterestApplicationClick={
                this.handleNoInterestApplicationClick
              }
            />
          );
        })}
      </CardWrapper>
    );
  };

  render() {
    const { history, loadInfo, match } = this.props;
    const {
      activeTabIndex,
      isDeclineConfirmModalOpen,
      isEmailsModalOpen,
      currentAppId,
      isNoInterestConfirmNodalOpen,
    } = this.state;

    // const activeTab = TABS[activeTabIndex]
    const activeTabAlt = TABS.find((el) => el.route === match.params.tab);
    const meta = this.getMeta(activeTabAlt);
    return (
      <Layout
        activeHeaderCategory={activeTabAlt}
        header={TABS}
        history={history}
        onHeaderCategoryClick={this.handleHeaderCategoryClick}
      >
        {meta && (
          <Helmet>
            <title id="meta-title">
              {meta.title || "Opportunity door"} | Mr.JOBS
            </title>
            <meta
              name="description"
              id="meta-description"
              content={meta.description}
            />
            <meta name="keywords" id="meta-keywords" content={meta.keywords} />
          </Helmet>
        )}
        {loadInfo.isLoading && <Loader />}
        {loadInfo.isLoaded && !loadInfo.isLoading && (
          <TabsContainer>{this.renderTabContents(activeTabAlt)}</TabsContainer>
        )}
        <DeclineConfirmModal
          isNoInterestContent={isNoInterestConfirmNodalOpen}
          isOpen={isDeclineConfirmModalOpen || isNoInterestConfirmNodalOpen}
          onCancel={this.handleDeclineCancel}
          onConfirm={this.handleDeclineConfirm}
          onRequestClose={this.handleDeclineCancel}
        />
        <EmailsModal
          appId={currentAppId}
          isOpen={isEmailsModalOpen}
          onClose={() => this.setState({ isEmailsModalOpen: false })}
        />
      </Layout>
    );
  }
}

export default Opportunity;
