import { createSelector } from 'reselect'

const privacyData = store => store.privacy

export const getPrivacy = createSelector(
  privacyData,
  data => data.privacy
)

export const getPrivacyLoadInfo = createSelector(
  privacyData,
  data => data.loadInfo
)
