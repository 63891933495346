import React, { PureComponent } from 'react'

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'

import { CareerTimeLineBlock, UserEditInfoModal } from '../../../../Blocks'
import {
  InterestsBlock,
  InterestsModal,
  SkillBlock,
  UserContactBlock,
  UserInfo,
  UserLanguagesBlock
} from './blocks'

import SkillsModal from '../SkillsModal'
//style google api
import {
  EditableField,
  IconContainer,
  Item,
  Items,
  LocationIcon,
  LocationIconContainer,
  PrimaryText
} from '../UserInfo/style'

import {
  ButtonContainer,
  ChangeModalInput,
  ChangeModalTextarea,
  ContactContainer,
  Container,
  Description,
  DescriptionWorlds,
  SkillContainer,
  Title,
  UserInfoContainer
} from './styles'
import { AddButton } from '../blocks/AddButton'

export default class WhoAmI extends PureComponent {
  constructor(props) {
    super()

    const state = {
      isModalOpen: false,
      changeModalType: null,
      phone: '',
      address: '',
      lat: null,
      lng: null,
      quality: '',
      university: '',
      experience: '',
      award: '',
      description: '',
      interests: '',
      addressValue: ''
    }

    if (props.user.phone) state.phone = props.user.phone
    if (props.user.location) {
      state.address = props.user.location
      state.addressValue = props.user.location
      state.lng = props.user.lon
      state.lat = props.user.lat
    }
    if (props.user.quality_info) state.quality = props.user.quality_info
    if (props.user.info) state.description = props.user.info
    if (props.user.experience_info)
      state.experience = props.user.experience_info
    if (props.user.award_info) state.award = props.user.award_info
    if (props.user.university_info)
      state.university = props.user.university_info
    if (props.user.interests_info) state.interests = props.user.interests_info

    this.state = state
  }

  renderLastInfo = () => {
    const { userExperience, userUniversity, userActivities } = this.props

    //experience
    const sortQualify = userExperience.sort(
      (a, b) => moment(b.start_date).year() - moment(a.start_date).year()
    )
    const lastQualify = userExperience.length ? sortQualify[0].position : ''
    // ---
    //university
    const sortUniversity = userUniversity.sort(
      (a, b) => moment(b.start_date).year() - moment(a.start_date).year()
    )
    const lastUniversity = userUniversity.length ? sortUniversity[0].title : ''
    // ---
    //experience years
    const experienceYearsArr = userExperience.map(item => {
      return moment(item.end_date).year() - moment(item.start_date).year()
    })
    let experienceYears = 0
    experienceYearsArr.forEach(item => (experienceYears += item))
    // ---
    //activities
    const sortActivities = userActivities.sort(
      (a, b) => moment(b.start_date).year() - moment(a.start_date).year()
    )
    const lastActivities = userActivities.length ? sortActivities[0].title : ''
    // ---

    return (
      <UserInfoContainer>
        <UserInfo
          defaultIconName={'fas fa-user'}
          emptyButtonText={'Add Your Quality'}
          text={lastQualify}
        />
        <UserInfo
          defaultIconName={'fas fa-graduation-cap'}
          emptyButtonText={'Add Your University'}
          text={lastUniversity}
        />
        <UserInfo
          defaultIconName={'fas fa-briefcase'}
          emptyButtonText={'Add Your Experience'}
          text={`${experienceYears} years of experience`}
        />
        <UserInfo
          defaultIconName={'fas fa-trophy'}
          emptyButtonText={'Add Your Award'}
          text={lastActivities}
        />
      </UserInfoContainer>
    )
  }

  render() {
    const {
      user,
      userLanguages,
      userExpectations,
      userPersonalities,
      userJobTypes,
      userCompanies
    } = this.props

    return (
      <CareerTimeLineBlock icon={false} title={'Who I Am'} type={'whoiam'}>
        <Container>
          <Title>Hello {user.name}</Title>
          <Description>{user.info}</Description>
          {this.renderLastInfo()}
          <ContactContainer>
            <UserContactBlock address={user.location} />
            <UserLanguagesBlock languages={userLanguages} />
          </ContactContainer>
          <SkillContainer>
            {userPersonalities && userPersonalities.length ? (
              <SkillBlock items={userPersonalities} title={'My Personality'} />
            ) : null}
            {userExpectations && userExpectations.length ? (
              <SkillBlock items={userExpectations} title={'My Expectation'} />
            ) : null}
            {userCompanies && userCompanies.length ? (
              <SkillBlock items={userCompanies} title={'My Companies'} />
            ) : null}
            {userJobTypes && userJobTypes.length ? (
              <SkillBlock items={userJobTypes} title={'My Job Type'} />
            ) : null}
            {user.interests_info && user.interests_info.length ? (
              <SkillBlock
                items={
                  user.interests_info ? user.interests_info.split(',') : []
                }
                title={'My Interests'}
              />
            ) : null}
          </SkillContainer>
        </Container>
      </CareerTimeLineBlock>
    )
  }
}
