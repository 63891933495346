import { createReducer } from 'Helpers/redux'
import { SWITCH_MODAL, CLOSE_MODAL } from '../actions/utils'

const initialState = {
  isModalOpen: false,
  window: 1
}

const handlers = {
  [SWITCH_MODAL]: (state, payload) => {
    return {
      isModalOpen: true,
      window: payload.window ? payload.window : 1
    }
  },
  [CLOSE_MODAL]: () => initialState
}

export default createReducer(initialState, handlers)
