import React from 'react'
import PropTypes from 'prop-types'

import { AddButton, SkillItem } from '../../../blocks'

import { Container, Title, List } from './styled'

const SkillBlock = ({
  buttonValue,
  title,
  items,
  onClickAdd,
  onDeleteItem,
  styles
}) => {
  return (
    <Container>
      <Title>{`${title}:`}</Title>
      <List>
        {items.map(item => (
          <SkillItem
            key={title === 'My Interests' ? item : item.id}
            name={title === 'My Interests' ? item : item.name}
            onDelete={() =>
              onDeleteItem(title === 'My Interests' ? item : item.id)
            }
          />
        ))}
        <AddButton styles={styles} value={buttonValue} onClickAdd={onClickAdd} />
      </List>
    </Container>
  )
}

SkillBlock.propTypes = {
  buttonValue: PropTypes.string.isRequired,
  items: PropTypes.array,
  title: PropTypes.string.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired
}

export { SkillBlock }
