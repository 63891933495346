import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 100px;
  width: 80%;
  @media (max-width: 412px) {
    width: 100%;
  }
`

export const Block = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    position: relative;
    top: 5px;
    justify-content: flex-end;
  }
  @media (max-width: 799px) {
    width: 100%;
    justify-content: flex-end;
  }
`
export const DateText = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-family: 'Nunito';
  color: rgb(88, 62, 161);
  margin: 0;
  min-width: 80px;
  padding: 0 10px;
  text-align: center;
  ${props => props.border && `border-${props.border}: solid 1px lightgrey`};
  @media screen and (min-width: 413px) and (max-width: 799px) {
    flex-basis: 20%;
  }
  @media screen and (max-width: 412px) {
    max-width: 100%;
    padding-bottom: 0;
    height: auto;
  }
`
export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 15px;
  padding: 10px 0;
  max-height: 150px;
  @media screen and (min-width: 413px) and (max-width: 799px) {
    flex-basis: 70%;
    justify-content: center;
    height: 100%;
    margin-left: 0;
    padding: 10px 0 10px 5px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 330px) {
    width: 100%;
  }
`

export const Title = styled.p`
  font-size: 13px;
  font-family: 'Nunito';
  color: rgb(88, 62, 161);
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 5px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const Text = styled.p`
  font-size: 12px;
  font-family: 'Nunito';
  color: rgb(117, 120, 125);
  line-height: 1.3;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Button = styled.img`
  min-width: 38px;
  min-height: 38px;
  max-width: 38px;
  max-height: 38px;
  cursor: pointer;
  margin-right: 3%;
`
export const BornIcon = styled.img`
  width: 52px;
  height: 60px;
  margin-right: 10px;
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 18px;
  margin-top: 100px;
  margin-bottom: 40px;
  max-width: 1200px;
  @media (max-width: 799px) {
    justify-content: flex-end;
  }
  @media screen and (min-width: 1350px) {
    margin: 100px auto 40px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 50px;
  }
`
export const RightSide = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 1000px) {
    width: 100%;
    margin-right: 10px;
  }
`
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 1000px) {
    width: 100%;
  }
`
export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 500px) and (max-width: 1099px) {
    width: 400px;
    min-width: 200px;
  }
  @media (max-width: 799px) {
    width: 80%;
  }
`
export const SimpleCircle = styled.div`
  background: #583ea1;
  height: 25px;
  border-radius: 20px;
  width: 25px;
  margin-left: 11px;
  :hover {
    box-shadow: 0 0 35px 0 #583ea1;
  }
`
export const VerticalLine = styled.div`
  background: #583ea1;
  height: 170px;
  width: 3px;
  margin-left: 22px;
`
export const LineConteiner = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 799px) {
    margin-right: 5px;
  }
  @media screen and (min-width: 331px) and (max-width: 412px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 330px) {
    margin-left: 0;
  }
`
export const CircleBorder = styled.div`
  background: white;
  border: 2px solid #583ea1;
  border-radius: 50px;
  width: 50px;
  height: 50px;
`
export const Circle = styled.div`
  background: #583ea1;
  height: 25px;
  border-radius: 20px;
  width: 25px;
  margin: 0 auto;
  margin-top: 10px;
  -moz-transition: all 3s ease-out;
  -o-transition: all 3s ease-out;
  -webkit-transition: all 3s ease-out;
  :hover {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
  }
`

export const ContentContainer = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 12px;
  height: 150px;
  font-size: 10px;
  cursor: pointer;
  min-width: 350px;
  @media (max-width: 1099px) {
    margin-bottom: 0;
    min-width: auto;
    height: 100px;
  }
  @media screen and (min-width: 1100px) {
    ${props => props.left && `margin: 80px 0 160px`};
    ${props => props.right && `margin-bottom: ${props.first ? 295 : 240}px`};
  }
`
export const Company = styled.div`
  padding: 10px;
  @media (max-width: 799px) {
  }
`
export const DescriptionDesctop = styled.div`
  font-family: 'Nunito';
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`
export const DescriptionTablet = styled.div`
  font-family: 'Nunito';
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: row-reverse;
`
export const TabletSide = styled.div`
  display: flex;
  flex-direction: column-reverse;
`
export const CircleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`
export const DescriptionMobile = styled.div`
  font-family: 'Nunito';
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`
