import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadCurrentVacancy } from 'Redux/actions/jobs'

import { getActiveVacancy } from 'Redux/selectors/jobs'
import { isAuthenticate } from 'Redux/selectors/auth'

import Component from './LinkReducer'

const actions = {
  onLoadCurrentVacancy: loadCurrentVacancy
}

const selector = createStructuredSelector({
  vacancy: getActiveVacancy,
  isAuthenticate
})

export default connect(
  selector,
  actions
)(Component)
