import styled from 'styled-components'
import { justifyContent, alignContent } from 'styled-system'

export const ChatContainer = styled.div`
  position: relative;
  border: 1px solid lightgrey;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: ${props => props.display || 'flex'};
  @media all and (max-width: 899px) {
    min-height: 100%;
    border: none;
    max-height: 93vh;
  }
`

export const Title = styled.div`
  width: 100%;
  border-bottom: 1px solid lightgrey;
  font-size: 20px;
  text-align: center;
  font-family: 'Nunito';
`
export const ContactCloud = styled.div`
  background: #87cefa;
  color: black;
  border-radius: 20px;
  min-height: 30px;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 20px;
  padding-left: 16px;
  font-family: 'Nunito';
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  width: 50%;
`
export const YourCloud = styled.div`
  background: #e6e6fa;
  color: black;
  border-radius: 20px;
  min-height: 30px;
  font-size: 20px;
  margin-top: 20px;
  padding: 15px;
  font-family: 'Nunito';
  margin-right: 10px;
  width: 50%;
`
export const Message = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.content};
`
export const ProfilePhoto = styled.img`
  border-radius: 40px;
  width: 50px;
  height: 50px;
  margin-top: 27px;
  background-color: grey;
`
export const TextInputContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid lightgray;
  border-left: none;
  border-right: none;
  display: flex;
  @media all and (max-width: 899px) {
    position: fixed;
    bottom: 0px;
    top: initial;
  }
  @media all and (min-width: 500px) and (max-width: 850px) {
    width: calc(100% - 180px);
  }
`
export const StyledInput = styled.input`
  height: 50px;
  font-size: 15px;
  border: none;
  padding-left: 10px;
  outline: none;
  width: 90%;
  font-family: 'Nunito';
`
export const IconsContainer = styled.div`
  padding-right: 20px;
  height: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SendButton = styled.div`
  font-size: 19px;
  align-self: center;
  padding: 0 10px;
  cursor: pointer;
  background: white;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  ${justifyContent};
  ${alignContent};
`

export const Nickname = styled.div`
  font-size: 16px;
`

export const Date = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
  font-size: 12px;
`

export const MessageText = styled.div`
  width: 90%;
  word-break: break-word;
  font-size: 14px;
`

export const MessagesContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 55px;
  @media screen and (max-width: 899px) {
  }
`
export const MobileBackBtn = styled.i.attrs({ className: 'fas fa-angle-left' })`
  position: absolute;
  font-size: 30px;
  left: 5px;
  z-index: 10;
`
