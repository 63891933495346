import React from 'react'
import PropTypes from 'prop-types'

import { Container, Icon, Title, Text, ContactBlock } from './styled'

const UserContactBlock = ({ address }) => {
  return (
    <Container>
      <Title>Contact Info: </Title>
      {/* <ContactBlock disable>
        <Icon className={'fas fa-envelope'} />
        <Text> </Text>
      </ContactBlock>
      <ContactBlock>
        <Icon className={'fas fa-phone'} />
        <Text> </Text>
      </ContactBlock> */}
      <ContactBlock>
        <Icon className={'fas fa-map-marker-alt'} />
        <Text>{address}</Text>
      </ContactBlock>
    </Container>
  )
}

UserContactBlock.propTypes = {
  address: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  onOpenAddressModal: PropTypes.func.isRequired,
  onOpenPhoneModal: PropTypes.func.isRequired
}

export { UserContactBlock }
