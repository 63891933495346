import React from 'react'
import PropTypes from 'prop-types'

import { Container, IconContainer, Icon, DefaultIcon, Text } from './style'

const UserInfo = ({ icon, text, defaultIconName, onChangeInfo }) => {
  return (
    <Container onClick={onChangeInfo}>
      <IconContainer>
        {icon ? (
          <Icon src={icon} />
        ) : (
          <DefaultIcon className={defaultIconName} />
        )}
      </IconContainer>
      {text.length && text !== 'null' ? <Text>{text}</Text> : null}
    </Container>
  )
}

UserInfo.propTypes = {
  defaultIconName: PropTypes.string,
  emptyButtonText: PropTypes.string,
  icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  onChangeInfo: PropTypes.func
}

export { UserInfo }
