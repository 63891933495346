import React from 'react'
import { Link } from 'react-router-dom'
import { Container, BackButton, Image } from './styles'
import mrjobs from 'Assets/images/1mrjobs.svg'

export default ({ button = false, onClick, onClose }) => (
  <Container>
    <h1>
      <Link to="/" onClick={onClose}>
        <Image src={mrjobs} />
      </Link>
    </h1>
  </Container>
)
