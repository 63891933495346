import { connect } from 'react-redux'
import Component from './EmailsModal'
import { createStructuredSelector } from 'reselect'
import {
  loadInvitedEmails,
  loadSavedEmails,
  addSupInviteEmail,
  addSavedEmail,
  deleteSupInviteEmail,
  removeSavedEmail,
  resetInvitedEmails
} from 'Redux/actions/friendSupport'
import {
  getSupportEmails,
  getSavedEmails,
  getSuppEmLoadInf
} from 'Redux/selectors/user'

const selector = createStructuredSelector({
  invitedEmails: getSupportEmails,
  savedEmails: getSavedEmails,
  invEmLoadInfo: getSuppEmLoadInf
})
const actionCreators = {
  onLoadEmails: loadSavedEmails,
  onLoadInvEmails: loadInvitedEmails,
  onAddSavedEmail: addSavedEmail,
  onAddEmailInvite: addSupInviteEmail,
  onDeleteEmailInvite: deleteSupInviteEmail,
  onDeleteSavedEmail: removeSavedEmail,
  onResetInvEmails: resetInvitedEmails
}
export const EmailsModal = connect(
  selector,
  actionCreators
)(Component)
