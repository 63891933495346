import React from 'react'
import PropTypes from 'prop-types'

import { CareerTimeLineBlock } from '../../../../Blocks/CareerTimeLineBlock'

import { MyAcademicQualifications } from '../MyAcademicQualifications'
import { MyProfessionalQualifications } from '../MyProffessionalQualifications'

const MyQualifications = () => {
  return (
    <CareerTimeLineBlock title={'My Qualifications'} type={'qualification'}>
      <MyAcademicQualifications />
      <MyProfessionalQualifications />
    </CareerTimeLineBlock>
  )
}

MyQualifications.propTypes = {}

export { MyQualifications }
