export const { REACT_APP_HOME } = process.env

const url = REACT_APP_HOME

export const initial = `${url}/`
export const categories = `${url}/`
export const jobs = `${url}/jobs-for-you`
export const profile = `${url}/profile`
export const detail = `${url}/detail`
export const careerTimeLine = `${url}/career-timeline`
export const oppdoor = `${url}/opportunity-door`
export const notification = `${url}/notifications`
export const messages = `${url}/messages`
export const friendSupport = `${url}/friend-support`
export const link_reducer = `${url}/link`
export const timeline = `${url}/timeline`
export const emailConfirmation = `${url}/email-confirmation`
export const passwordreset = `${url}/passwordreset`
export const editcv = `${url}/editcv`
export const login = `${url}/login`
export const restorePassword = `${url}/forgot-password`
export const registration = `${url}/signup`
export const redirectPage = `${url}/redirect-page`
export const contactUs = `${url}/contact-us`
export const cookies = `${url}/cookies`
export const privacy = `${url}/privacy`
export const settings = `${url}/page`
export const candidateProfile = `${url}/candidate-profile`
