import React, { Component } from "react";
import map from "lodash/map";
import { Usual, Active, Container } from "./styled";

class FilterItem extends Component {
  generateItems() {
    const { items, onItemClick } = this.props;
    return map(items, (value, key) => {
      let Container = Usual;
      let className = "fa-plus-circle";
      if (value.active) {
        Container = Active;
        className = "fa-check-circle";
      }

      return (
        <Container key={key} onClick={() => onItemClick(value, key)}>
          {value.text}
          <i
            className={"fas icon-margin-left " + className}
            style={{ marginLeft: "5px" }}
          />
        </Container>
      );
    });
  }

  render() {
    return <Container>{this.generateItems()}</Container>;
  }
}
export { FilterItem };
