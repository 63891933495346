import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_PERSONALITIES = createAsyncAction('personalities/LOAD')
export const loadPersonalities = text => {
  return apiCall({
    endpoint: '/personalities',
    types: LOAD_PERSONALITIES,
    query: {
      text
    },
    method: 'GET'
  })
}
export const resetPersonalities = () => ({
  type: LOAD_PERSONALITIES.SUCCESS,
  payload: {
    data: []
  }
})
export const ADD_PERSONALITY = createAsyncAction('user/personalities/ADD')
export const addPersonality = name => {
  return apiCall({
    endpoint: '/candidates/personalities',
    types: ADD_PERSONALITY,
    method: 'POST',
    query: {
      name
    }
  })
}
export const REMOVE_PERSONALITY = createAsyncAction('personalities/REMOVE')
export const removePersonality = id => {
  return apiCall({
    endpoint: '/personalities',
    types: REMOVE_PERSONALITY,
    method: 'DELETE',
    query: {
      id: [id]
    }
  })
}
export const EDIT_PERSONALITIES = createAsyncAction('personalities/EDIT')
export const editPersonalities = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/personalities',
    types: EDIT_PERSONALITIES,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}
