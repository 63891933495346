import React from 'react'
import PropTypes from 'prop-types'
import {
  CardImageContainer,
  CardImageIcon,
  CardImageIconDefault
} from './styled'

const CardImage = ({ image, faIcon }) => {
  return (
    <CardImageContainer>
      {image ? (
        <CardImageIcon image={image} />
      ) : (
        <CardImageIconDefault className={faIcon} />
      )}
    </CardImageContainer>
  )
}

CardImage.propTypes = {
  faIcon: PropTypes.string,
  image: PropTypes.string
}

CardImage.defaultProps = {
  faIcon: 'fas fa-briefcase'
}

export { CardImage }
