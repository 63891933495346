import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { updateNotificationData } from 'Redux/actions/user'
import { loadNotifications } from 'Redux/actions/notifications'

import { isAuthenticate } from 'Redux/selectors/auth'
import { getUserNotifications, getUserEmail } from 'Redux/selectors/user'

import Component from './Notification'

const selectors = createStructuredSelector({
  isAuthenticate,
  notifications: getUserNotifications,
  userEmail: getUserEmail
})

const actions = {
  onLoadNotifications: loadNotifications,
  onUpdateDate: updateNotificationData
}

export default connect(
  selectors,
  actions
)(Component)
