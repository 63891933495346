import Component from './NotificationsIcon'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getUserNotifications,
  getUserNotificationData
} from 'Redux/selectors/user'

const selectors = createStructuredSelector({
  notifications: getUserNotifications,
  viewingDate: getUserNotificationData
})

const actions = {}

export default connect(
  selectors,
  actions
)(Component)
