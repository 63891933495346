import styled from 'styled-components'

export const percentStyles = {
  root: {
    width: '30px',
    height: '30px'
  },
  path: {
    stroke: '#583ea1'
  },
  text: {
    fill: '#757575',
    fontSize: '27px'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0 0 24px 1px rgba(61, 61, 61, 0.42);
  -moz-box-shadow: 0 0 24px 1px rgba(61, 61, 61, 0.42);
  box-shadow: 0 0 24px 1px rgba(61, 61, 61, 0.42);
  border-radius: 10px;
  padding-bottom: 10px;
  width: 250px;
  height: 220px;
  margin: 0 auto;
  cursor: pointer;
`
export const CardContainer = styled.div`
  text-decoration: none;
  color: black;
  margin-top: 50px;
  position: relative;
  cursor: pointer;
`
export const SocialMediaContainer = styled.div`
  text-align: center;
  font-family: 'Nunito';
  height: 90px;
  width: 250px;
  color: white;
  position: relative;
`
export const CardImage = styled.img`
  text-align: center;
  font-family: 'Nunito';
  height: 90px;
  width: 250px;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`
export const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 94px;
  position: absolute;
  bottom: -16px;
  right: 0;
`
export const SocialIcon = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #757575;
`
export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`
export const Title = styled.h3`
  color: #757575;
  font-family: 'Nunito';
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 4% 0;
  padding-left: 6px;
  padding-top: 4px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
  width: 100%;
`
export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
export const Icon = styled.div``
export const IconImage = styled.img`
  width: 30px;
  height: 30px;
`
export const IconText = styled.p`
  font-size: 7px;
  color: #757575;
  margin-top: 5px;
  text-align: center;
  ${props =>
    props.ellipsis &&
    ` white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
     width: 100%; max-width: 40px;`};
`
export const MatchRateContainer = styled.div`
  corsor: ${p => (p.isAuth ? 'defaut' : 'pointer')};
`
export const SubInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4%;
  padding: 0 6px;
  color: #545454;
  font-size: 13px;
`
export const Company = styled.div`
  color: #757575;
`
export const CreateDate = styled.span`
  color: #757575;
`
export const TimeIcon = styled.i.attrs({ className: 'far fa-clock' })`
  margin-right: 5px;
`
