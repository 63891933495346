import styled from 'styled-components'
import * as colors from '../../../../../../Theme/colors'

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  border: solid 1px ${colors.chatPurple};
  border-radius: 10px;
`

export const Text = styled.span`
  margin: 5px 0;
  line-height: 14px;
`
export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  color: #757575;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${colors.chatPurple};
  }
`

export const Icon = styled.i`
  margin: 0 10px 0 0;
`
