import moment from 'moment'

export const formatForNotification = date => {
  const parsed = moment(date)
  const now = moment(moment.now(), 'x')
  const parsedYear = parsed.year()
  const parsedDay = parsed.dayOfYear()
  const nowYear = now.year()
  const nowDay = now.dayOfYear()
  if (parsedYear === nowYear && parsedDay === nowDay) {
    return parsed.format('hh:mm A')
  }
  return parsed.format('hh:mm A - MMM Do YYYY')
}
export const formatForTimeLine = date => {
  const parsed = moment(date)
  if (parsed.isValid()) {
    return parsed.format('MMM YYYY')
  }
}

export const getChatMessageDate = date => {
  const parsed = moment(date)

  if (moment().isSame(parsed, 'day')) {
    return parsed.format('h:mm a')
  } else if (
    moment()
      .subtract({ day: 1 })
      .isSame(parsed, 'day')
  ) {
    return 'Yesterday'
  } else if (moment().isSame(parsed, 'year')) {
    return parsed.format('DD.MM')
  } else {
    return parsed.format('DD.MM.YY')
  }
}
