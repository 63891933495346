import React from 'react'
import PropTypes from 'prop-types'

import { Container, Title, Input } from './styled'

const UserInfoBlock = ({ error, title, value, name, onChange }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Input error={error} name={name} value={value} onChange={onChange} />
    </Container>
  )
}

UserInfoBlock.propTypes = {
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export { UserInfoBlock }
