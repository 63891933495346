import styled, { keyframes } from "styled-components";
import Spiner from "react-spinkit";
import PerfectScrollbar from "react-perfect-scrollbar";
import { bounce, rubberBand } from "react-animations";

export const percentStyles = {
  root: {
    width: "50px",
    height: "50px",
  },
  path: {
    stroke: "#583ea1",
  },
  text: {
    fill: "#757575",
    fontSize: "25px",
  },
};

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  position: relative;
  @media screen and (max-width: 799px) {
    flex: 1;
  }
  @media screen and (max-width: 700px) {
    justify-content: center;
  }
  @media screen and (min-width: 1700px) {
    transform: translateX(-30px);
  }
  @media screen and (max-width: 950px) {
    justify-content: center;
  }
  @media screen and (min-width: 500px) and (max-width: 800px) {
    align-items: center;
    justify-content: flex-start;
  }
  @media screen and (max-width: 500px) {
    overflow-y: auto;
  }
`;
export const SocialMediaContainer = styled.div`
  text-align: center;
  font-family: "Nunito";
  height: 130px;
  width: 314px;
  color: white;
  position: relative;
`;
export const CardImage = styled.img`
  text-align: center;
  font-family: "Nunito";
  height: 130px;
  width: 314px;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 94px;
  position: absolute;
  bottom: -16px;
  right: 0;
`;
export const SocialIcon = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  background-color: #583ea1;
  border-radius: 50%;
`;
const socialIconAnim = keyframes`${rubberBand}`;
export const SocialIconItem = styled.i.attrs({ className: "fas fa-bookmark" })`
  font-size: 18px;
  color: ${(p) => (p.active ? "#FE1948" : "#fff")};
  transition: color 0.2s;
  &:hover {
    animation: 0.5s ${socialIconAnim};
  }
`;
export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 314px;
`;
export const Title = styled.h1`
  color: #757575;
  font-family: "Nunito";
  font-size: 23px;
  font-weight: bold;
  margin: 0 0 4% 0;
  padding-left: 6px;
  padding-top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 72%;
`;
export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const Icon = styled.div`
  ${(props) =>
    props.link &&
    `padding-bottom: 10px;
    padding-top: 10px;`};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-bottom: 20px;
  box-shadow: 0 0 15px 1px rgba(61, 61, 61, 0.42);
  height: initial;
  max-height: 340px;
  border-radius: 10px;
  padding-bottom: 10px;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    margin-bottom: ${(props) => (props.last ? "100" : "20")}px;
    margin-left: 0;
  }
`;
export const CardContainer = styled.div`
  text-decoration: none;
  color: black;
  margin-top: 30px;
  position: relative;
`;
export const Social = styled.p`
  font-size: 30px;
  position: relative;
  top: 40px;
`;
export const Filter = styled.div`
  min-width: ${(p) => (p.small ? 95 : 116)}px;
  ${(p) => p.smallReset && "min-width: 90px"};
  max-width: 129px;
  height: 33px;
  border: 1px solid ${(props) => (props.isActive ? "#583ea1" : "#757575")}
    ${(props) => (props.reset ? `border-color: #583ea1;` : "")};
  border-radius: 30px;
  margin-right: 10px;
  font-size: 14px;
  padding-top: 5px;
  color: lightgrey;
  display: flex;
  justify-content: flex-start;
  position: relative;
  user-select: none;
  cursor: pointer;
  ${'' /* :hover {
    border: 2px solid rgb(90, 64, 162);
  } */}
`;
export const FilterTextOverflow = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-right: 6px;
  color: ${(props) => (props.isActive ? "#0000B8;" : "#757575;")};
`;
export const FiltScrollContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;
export const FiltContainer = styled(PerfectScrollbar).attrs({
  className: "filtersContainer",
})`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  margin-top: 10px;
  z-index: 5;
  padding-right: 10px;
  width: calc(100vw - 270px);
  height: 50px !important;
  pointer-events: ${(p) => (p.disabled ? "none" : "all")};
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  @media screen and (max-width: 799px) {
    padding: 10px 10px 0;
  }
  @media screen and (max-width: 576px) {
    width: 100vw;
  }
  @media screen and (min-width: 100px) {
    width: 100%;
  }
`;
export const FiltImg = styled.img`
  height: 20px;
  margin-left: 7px;
  margin-right: 5px;
`;
export const Button = styled.button`
  background: #32e09c;
  width: 150px;
  height: 35px;
  border-radius: 15px;
  position: absolute;
  bottom: 10px;
  right: 50px;
  color: white;
  outline: none;
`;
export const JobType = styled.div`
  height: 35px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 30px;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 7px;
  :hover {
    background: rgb(90, 64, 162);
    color: white;
  }
`;
export const Search = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  height: 30px;
  border-radius: 30px;
  margin-bottom: 10px;
`;

export const IconText = styled.p`
  font-size: 13px;
  color: #757575;
  margin-top: 5px;
  width: 50px;
  text-align: center;
`;

export const IconImage = styled.img`
  width: 50px;
  height: 50px;
`;

export const StartYourSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 200px);
  width: 100%;
  @media screen and (max-width: 500px) {
    top: 50%;
    left: 0;
    transform: none;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const StartYourSearchIcon = styled.i.attrs({
  className: "fas fa-search-plus",
})`
  font-size: 30px;
  margin-bottom: 5px;
  color: #6d71f7;
`;

export const StartYourSearchText = styled.p`
  font-size: 18px;
`;
export const LittleLogo = styled.img`
  width: 100px;
  height: 100px;
  margin: 0 5px 20px;
`;

export const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  flex: 1;
  @media screen and (min-width: 500px) {
    margin-left: 20px;
  }
`;
export const FilterResetText = styled.p`
  margin-right: auto;
  color: ${(props) => (props.reset ? "#0000B8;" : "#757575;")}
  width: 100%;
  &:hover {
    color: #0000B8;
  }
`;
export const Spinner = styled(Spiner)`
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  @media screen and (max-width: 500px) {
    height: 100vh;
  }
`;
export const DropdownIcon = styled.i.attrs({
  className: "fas fa-chevron-down",
})`
  font-size: 11px;
  padding-top: 5px;
  margin-right: 7px;
  margin-left: auto;
  color: ${(props) => (props.isActive ? "#0000B8" : "#757575")};
`;
export const SubInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4%;
  padding: 0 6px;
  color: #757575;
`;
export const CreateDate = styled.span`
  color: #757575;
`;
export const Company = styled.span``;
export const FiltersIcon = styled.i.attrs({ className: "fas fa-sliders-h" })`
  font-size: 20px;
  margin: 6px 10px 6px 0;
  color: #757575;
`;
export const TimeIcon = styled.i.attrs({ className: "far fa-clock" })`
  margin-right: 5px;
`;
const subTitleAnimation = keyframes`${rubberBand}`;
export const ShareBtn = styled.i.attrs({ className: "fas fa-share" })`
  color: #fff;
  font-size: 18px;
  &:hover {
    animation: 0.5s ${subTitleAnimation};
  }
`;
export const ShareBtnContainer = styled.div`
  position: relative;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  ${"" /* border: solid 1px #757575; */}
  background-color: #583ea1;
`;
export const MatchRateContainer = styled.div`
  cursor: ${(p) => (p.isAuth ? "defaut" : "pointer")};
`;
