import Component from './ExpirePasswordWindow'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { resendConfirmEmail } from 'Redux/actions/user'
import { getResendLoadInfo } from 'Redux/selectors/register'
import { getPasswordUserData, getSendMailPasswordResetInfo } from 'Redux/selectors/passwordReset'
import { checkPassClearData } from 'Redux/actions/passwordReset'
import { switchModal } from 'Redux/actions/utils'
import { confirmEmailClean } from 'Redux/actions/emailConfirmation'
import { sendPasswordReset } from 'Redux/actions/passwordReset'

const selectors = createStructuredSelector({
  resendLoadInfo: getResendLoadInfo,
  userData: getPasswordUserData,
  sendMailLoadInfo: getSendMailPasswordResetInfo
})

const actions = {
  // resend
  resendConfirmEmail,
  onSwitchModal: switchModal,
  onConfirmEmailClean: confirmEmailClean,
  onCheckPassClearData: checkPassClearData,
  onSendEmail: sendPasswordReset
}

export default connect(
  selectors,
  actions
)(Component)
