import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px #757575;
  margin: 0 0 50px 0;
  @media screen and (max-width: 350px) {
    border-radius: 0;
  }
`
export const Header = styled.header`
  display: flex;
  padding: 20px 25px 0 20px;
`

export const Icon = styled.img`
  width: 30px;
  margin: 0 5px 0 0;
  align-self: center;
`
export const DefaultIcon = styled.i.attrs({ className: 'fas fa-book' })`
  color: #757575;
  font-size: 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 350px) {
    font-size: 17px;
  }
`
export const Title = styled.div`
  font-size: 25px;
  text-transform: uppercase;
  color: #757575;
  font-family: Nunito;
  margin: 0 10px;
  white-space: nowrap;
  font-weight: 700;
  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 350px) {
    font-size: 17px;
  }
`
export const Line = styled.div`
  height: 2px;
  width: 85%;
  background: linear-gradient(
    to right,
    rgba(88, 56, 175, 0.7),
    rgba(0, 177, 255, 0.7)
  );
  align-self: flex-end;
  margin: 0 0 5px 0;
`
export const Content = styled.div`
  padding: 10px;
`
