import styled from 'styled-components'
import AntdIcon from 'antd/lib/icon'

import green from '@material-ui/core/colors/green'

import * as colors from '../../../../../../../Theme/colors'

export const Container = styled.div`
  padding: 5px 10px;
  margin: 10px 0;
  display: flex;
`
export const FeedbackUserName = styled.div`
  width: 70px;
  min-width: 70px;
  height: 70px;
  min-height: 70px;
  border-radius: 50%;
  border: solid 1px ${colors.chatPurple};
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.chatPurple};
  &:hover {
    color: ${colors.chatPurple};
  }
  @media screen and (max-width: 700px) {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    font-size: 20px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
`
export const Header = styled.div`
  display: flex;
  margin: 0 0 10px 0;
`
export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.h1`
  margin: 0 0 5px 0;
  font-weight: normal;
  font-size: 20px;
  color: ${colors.careerBlue};
`
export const Position = styled.h2`
  font-weight: bold;
  font-size: 15px;
  color: #757575;
`
export const Company = styled.h3`
  font-weight: normal;
  font-size: 15px;
  color: #757575;
`

export const Feedback = styled.p`
  font-size: 15px;
  color: #757575;
  margin: 0 0 10px 0;
`
export const FeedbackMoreButton = styled.span`
  text-transform: uppercase;
  color: ${colors.careerBlue};
  text-decoration: underline;
  cursor: pointer;
`
export const PersonalityBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const PersonalityMoreButton = styled.i.attrs({
  className: 'fas fa-ellipsis-h'
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  border: solid 1px ${colors.chatPurple};
  border-radius: 50%;
  color: ${colors.chatPurple};
  background-color: #fff;
  cursor: pointer;
  transition: color 0.3s, background-color 0.5s;
  &:hover {
    background-color: ${colors.chatPurple};
    color: #fff;
  }
`
export const FeedbackUserNameBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
export const IsActiveIcon = styled.i.attrs({
  className: p => (p.isActive ? 'fas fa-circle' : 'far fa-circle')
})`
  font-size: 15px;
  position: absolute;
  top: -5px;
  right: -5px;
  color: ${colors.buttonGreen};
`
export const switchStyle = theme => ({
  colorSwitchBase: {
    color: '#757575',
    '&$colorChecked': {
      color: green[600],
      '& + $colorBar': {
        backgroundColor: green[600]
      }
    }
  },
  colorBar: {},
  colorChecked: {}
})
