import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './SearchOptions'
import {
  loadJobTypes,
  removeJobType,
  editJobTypes
} from 'Redux/actions/jobTypes'
import {
  loadCompanies,
  removeCompany,
  editCompanies
} from 'Redux/actions/companies'
import { getJobTypes } from 'Redux/selectors/jobTypes'
import { getCompanies } from 'Redux/selectors/companies'
import { getUserJobTypes, getUserCompanies } from 'Redux/selectors/user'

const actions = {
  onLoadJobTypes: loadJobTypes,
  onLoadCompanies: loadCompanies,
  onEditJobTypes: editJobTypes,
  onEditCompanies: editCompanies,
  onRemoveJobType: removeJobType,
  onRemoveCompany: removeCompany
}
const selector = createStructuredSelector({
  predJobTypes: getJobTypes,
  predCompanies: getCompanies,
  companies: getUserCompanies,
  jobTypes: getUserJobTypes
})

export const SearchOptions = connect(
  selector,
  actions
)(Component)
