import React, { PureComponent } from 'react'
import {
  ActivePageText,
  DeleteBtn,
  HeaderCategory,
  Page,
  PagesContainer,
  HeaderCategoryTitle,
  ArrowIcon,
  NextArrow,
  PrevArrow
} from './styled'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import withSizes from 'react-sizes'
import PerfectScrollbar from 'perfect-scrollbar'
import { perfectScrollbarHideAll } from '../../../Helpers/perfectScrollbarConfig'

class HeaderCategoriesList extends PureComponent {
  constructor(props) {
    super()
    const state = {
      isDisableNextBtn: false,
      isDisablePrevBtn: false
    }
    if (props.withHeaderNavigate) {
      props.header.forEach((item, index) => {
        if (item.id === props.activeHeaderCategory.id) {
          state.isDisableNextBtn = !props.header[index + 1]
          state.isDisablePrevBtn = !props.header[index - 1]
        }
      })
    }

    this.state = state
  }

  static getDerivedStateFromProps(props, state) {
    const { header, activeHeaderCategory, history, withHeaderNavigate } = props
    if (withHeaderNavigate) {
      let nextElement = false
      let prevElement = false

      header.forEach((item, index) => {
        if (item.id === activeHeaderCategory.id) {
          nextElement = !header[index + 1]
          prevElement = !header[index - 1]
        }
      })

      return {
        ...state,
        isDisableNextBtn: nextElement,
        isDisablePrevBtn: prevElement
      }
    }
  }

  componentDidMount() {
    const { isMobile, withHeaderNavigate } = this.props
    new PerfectScrollbar('#page_active')
    if (isMobile) {
      perfectScrollbarHideAll(document.getElementById('page_active'), false)
    }
    if (withHeaderNavigate) {
      this.scrollToActiveElement()
    }
  }

  scrollToActiveElement = (event, customElement) => {
    const { activeHeaderCategory } = this.props

    let element = {}
    if (customElement) {
      element = customElement
    } else if (event) {
      element = event.target
    } else if (this.refs[`elementId${activeHeaderCategory.id}`]) {
      element = this.refs[`elementId${activeHeaderCategory.id}`]
    } else {
      element = this[`elementId${activeHeaderCategory.id}`]
    }

    const parent = document.getElementById('page_active')
    const isElHidden = parent.offsetLeft > element.getBoundingClientRect().left
    let parentNotScroll = parent.scrollLeft
    const scroll = setInterval(() => {
      parentNotScroll = parent.scrollLeft
      parent.scrollBy(isElHidden ? -5 : 5, 0)
      if (isElHidden) {
        if (
          parent.scrollLeft <= element.offsetLeft - 10 ||
          parent.scrollLeft === parentNotScroll
        )
          clearInterval(scroll)
      } else {
        if (
          parent.scrollLeft >= element.offsetLeft - 10 ||
          parent.scrollLeft === parentNotScroll
        )
          clearInterval(scroll)
      }
    }, 5)
  }

  handleHeaderCategoryClick = (event, value, key) => {
    const { onHeaderCategoryClick } = this.props
    // console.log('event, value, key>>>', event, value, key)
    onHeaderCategoryClick(value, key, event.target)
    this.scrollToActiveElement(event)
  }

  handleClickNavBtn = direction => {
    const { header, activeHeaderCategory, onHeaderCategoryClick } = this.props
    let nextElementObg = {}
    switch (direction) {
      case 'left':
        header.forEach((el, index) => {
          if (el.id === activeHeaderCategory.id) {
            nextElementObg = header[index - 1]
          }
        })
        break
      case 'right':
        header.forEach((el, index) => {
          if (el.id === activeHeaderCategory.id) {
            nextElementObg = header[index + 1]
          }
        })
        break
      default:
        console.warn('direction is not valid')
        break
    }

    if (nextElementObg) {
      const nextNodeElement = activeHeaderCategory
        ? this[`elementId${nextElementObg.id}`]
        : this.refs[`elementId${nextElementObg.id}`]

      onHeaderCategoryClick(nextElementObg, nextElementObg.id, nextNodeElement)
      this.scrollToActiveElement(false, nextNodeElement)
    }
  }

  title = header => {
    let titleTrue = false
    const { settingsList } = this.props
    let list = ''
    list = settingsList
      ? settingsList.filter(el => el.sub_title === header)
      : ''
    if (list.length !== 0) {
      titleTrue = true
    }
    return titleTrue
  }

  renderListItems = () => {
    const {
      header,
      activeHeaderCategory,
      onDeleteBtnClick
    } = this.props
    if (
      header === 'JOB INDUSTRIES' ||
      header === 'EDIT CV' ||
      header === 'MESSAGES' ||
      header === 'Contact Us' ||
      header === 'Settings' ||
      header === 'Candidate Profile' ||
      this.title(header)
    ) {
      return (
        <PagesContainer id="page_active">
          <Page>
            <ActivePageText className="active-page">
              <HeaderCategory>
                <HeaderCategoryTitle isActive={true}>
                  {header}
                </HeaderCategoryTitle>
              </HeaderCategory>
            </ActivePageText>
          </Page>
        </PagesContainer>
      )
    } else {
      return (
        <PagesContainer
          id="page_active"
          innerRef={el => (this.$header = $(el))}
        >
          {_.map(header, (value, key) => {
            const isActive = value.id === activeHeaderCategory.id

            if (value.type === 'link') {
              return (
                <Page>
                  <ActivePageText className="active-page">
                    <HeaderCategory>
                      <Link
                        innerRef={ref => (this[`elementId${value.id}`] = ref)}
                        key={value.id}
                        to={`${value.route}`}
                      >
                        <HeaderCategoryTitle isActive={isActive}>
                          {value.title}
                        </HeaderCategoryTitle>
                      </Link>
                      {value.withDelete && (
                        <DeleteBtn
                          onClick={event => onDeleteBtnClick(event, value)}
                        />
                      )}
                    </HeaderCategory>
                  </ActivePageText>
                </Page>
              )
            } else {
              const isActive = value.id === activeHeaderCategory.id

              return (
                <div ref={`elementId${value.id}`}>
                  <Page key={value.id}>
                    <ActivePageText className="active-page">
                      <HeaderCategory
                        onClick={e =>
                          this.handleHeaderCategoryClick(e, value, key)
                        }
                      >
                        <HeaderCategoryTitle isActive={isActive}>
                          {value.title}
                        </HeaderCategoryTitle>
                        {value.withDelete && (
                          <DeleteBtn
                            onClick={event => onDeleteBtnClick(event, value)}
                          />
                        )}
                      </HeaderCategory>
                    </ActivePageText>
                  </Page>
                </div>
              )
            }
          })}
        </PagesContainer>
      )
    }
  }

  render() {
    const { viewButton, overflow } = this.props
    const { isDisablePrevBtn, isDisableNextBtn } = this.state
    return (
      <React.Fragment>
        {viewButton && overflow && (
          <PrevArrow
            disabled={isDisablePrevBtn}
            onClick={() => this.handleClickNavBtn('left')}
          >
            <ArrowIcon aria-hidden="true" className={`fa fa-angle-left `} />
          </PrevArrow>
        )}
        {this.renderListItems()}
        {viewButton && overflow && (
          <NextArrow
            disabled={isDisableNextBtn}
            onClick={() => this.handleClickNavBtn('right')}
          >
            <ArrowIcon aria-hidden="true" className={`fa fa-angle-right `} />
          </NextArrow>
        )}
      </React.Fragment>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500
})

export default withSizes(mapSizeToProps)(HeaderCategoriesList)
