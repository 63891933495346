import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { TABS } from './data'
// import Component from './index'
import * as routes from 'Constants/routes'

import Component from './Opportunity'
import {
  loadApplications,
  acceptApplication,
  declineApplication,
  noInterestApplication
} from 'Redux/actions/applications'
import { createChat } from 'Redux/actions/chat'
import { loadNotifications } from 'Redux/actions/notifications'
import { loadIndustriesMetaTags } from 'Redux/actions/metaTags'

import {
  getApplications,
  getApplicationsLoadInfo,
  getDeclineState,
  getNoInterestReqState
} from 'Redux/selectors/applications'
import { isAuthenticate, isLoaded } from 'Redux/selectors/auth'
import { getUser } from 'Redux/selectors/user'
import {
  getIndustriesMetaTags
} from 'Redux/selectors/metaTags'

const actions = {
  onLoadApplications: loadApplications,
  onApplicationAccept: acceptApplication,
  onApplicationDecline: declineApplication,
  onCreateChat: createChat,
  onUpdateNotifications: loadNotifications,
  onNoInterestApplication: noInterestApplication,
  onLoadIndustriesMetaTags: loadIndustriesMetaTags
}

const selector = createStructuredSelector({
  applications: getApplications,
  loadInfo: getApplicationsLoadInfo,
  isAuthenticate,
  user: getUser,
  declineState: getDeclineState,
  noInterestReqState: getNoInterestReqState,
  isLoaded: isLoaded,
  industriesMetaTags: getIndustriesMetaTags
})

const ComponentWithRedux = connect(
  selector,
  actions
)(Component)

const getRoute = (currTab, tabs = TABS) => {
  const tab = tabs.find(el => el.route === currTab)
  if (tab) {
    let route = `/${currTab}`
    if (tab.subTitles) {
      return (route += getRoute(tab.subTitles[0].route, tab.subTitles))
    } else {
      return route
    }
  } else return ''
}

export default () => {
  return (
    <Switch>
      <Route
        component={ComponentWithRedux}
        exact
        path={`${routes.oppdoor}/:tab/:subtab`}
      />
      <Route
        exact
        path={`${routes.oppdoor}/:tab`}
        // component={Component}
        render={props => {
          const { tab } = props.match.params
          if (TABS.find(el => el.route === tab).subTitles) {
            return <Redirect to={`${routes.oppdoor}${getRoute(tab)}`} />
          } else {
            return <ComponentWithRedux {...props} />
          }
        }}
      />

      <Route
        path={`${routes.oppdoor}`}
        render={() => {
          return <Redirect to={`${routes.oppdoor}${getRoute('pitched')}`} />
        }}
      />
    </Switch>
  )
}
