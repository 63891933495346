import styled from 'styled-components'
import * as colors from '../../../../../../Theme/colors'
import { isMobile } from 'react-device-detect'
import {ContactBlock} from "../../WhoAmI/blocks/UserContactBlock/styled";

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  margin: 10px 0 0;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`

export const CardContent = styled.div`
  padding: 0 0 15px 0;
  border-bottom: solid 1px silver;
  margin: 0 0 0 30px;
  width: 100%;
  position: relative;
  @media screen and (max-width: 700px) {
    margin: 0 0 0 15px;
  }
  @media screen and (max-width: 400px) {
    margin: 5px 0 0;
  }
`

export const CardDesctiption = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const EditIcon = styled.div`
  color: #fff;
  cursor: pointer;
  color: ${isMobile ? colors.chatPurple : 0};
  ${Container}:hover & {
    color: ${colors.chatPurple};
  }
`
