import _ from 'lodash'
import { createReducer } from 'Helpers/redux'

import { APPLY } from 'Redux/actions/applications'
import {
  LOAD_USER_JOBS,
  SET_ACTIVE,
  DELETE_ACTIVE,
  LOAD_CURRENT_VACANCY,
  UPDATE_FILTER,
  SEARCH_JOBS,
  CHANGE_JOB_TAB,
  RESET_JOBS,
  GET_SALARY_RANGE,
  GET_IP_LOCATION
} from 'Redux/actions/jobs'
import { UNFOLLOW } from 'Redux/actions/industries'

import * as jobTabs from 'Constants/jobTabs'

const initialState = {
  jobs: [],
  activeVacancy: null,
  loadInfo: {
    requestsCount: 0,
    isLoaded: false,
    isLoadings: false,
    error: null,
    tab: null
  },
  activeVacLoadInfo: {
    isLoaded: false,
    isLoadings: false,
    error: null
  },
  filter: {},
  tab: jobTabs.CATEGORY_ALL,
  salaryRange: null
}

const handlers = {
  [LOAD_USER_JOBS.REQUEST]: (state, { payload }) => {
    const nextReqestCount = state.loadInfo.requestsCount + 1
    return {
      ...state,
      loadInfo: {
        ...state.loadInfo,
        requestsCount: nextReqestCount,
        isLoading: true,
        error: null,
        tab: state.tab
      }
    }
  },
  [LOAD_USER_JOBS.FAILURE]: (state, { payload }) => {
    const nextReqestCount = state.loadInfo.requestsCount - 1
    return {
      ...state,
      loadInfo: {
        ...state.loadInfo,
        requestsCount: nextReqestCount,
        isLoading: false,
        error: payload.data,
        tab: nextReqestCount === 0 ? null : state.loadInfo.tab
      }
    }
  },
  [LOAD_USER_JOBS.SUCCESS]: (state, { payload }) => {
    const nextReqestCount = state.loadInfo.requestsCount - 1
    // const jobs = _.assign(state.jobs, payload.data)
    const jobs = payload.data
    // console.log('server responce', payload.data)
    // console.log('compleate obj', jobs)
    return {
      ...state,
      filter: state.filter,
      jobs,
      loadInfo: {
        requestsCount: nextReqestCount,
        isLoading: false,
        isLoaded: true,
        error: null,
        tab: nextReqestCount === 0 ? null : state.loadInfo.tab
      }
    }
  },
  [GET_IP_LOCATION.REQUEST]: (state, { payload }) => {
    return {
      ...state
    }
  },
  [GET_IP_LOCATION.FAILURE]: (state, { payload }) => {
    return {
      ...state
    }
  },
  [GET_IP_LOCATION.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ipLocation: payload.data
    }
  },
  [SET_ACTIVE]: (state, { payload }) => {
    return {
      ...state,
      activeVacancy: payload.data
    }
  },
  [DELETE_ACTIVE]: state => {
    return {
      ...state,
      activeVacancy: null
    }
  },
  [APPLY.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      activeVacancy: {
        ...state.activeVacancy,
        applied: 1
      }
    }
  },
  [LOAD_CURRENT_VACANCY.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      activeVacancy: payload.data || null,
      activeVacLoadInfo: {
        isLoaded: true,
        isLoadings: false,
        error: null
      }
    }
  },
  [LOAD_CURRENT_VACANCY.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      activeVacancy: null,
      activeVacLoadInfo: {
        isLoaded: false,
        isLoadings: false,
        error: true
      }
    }
  },
  [LOAD_CURRENT_VACANCY.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      activeVacancy: null,
      activeVacLoadInfo: {
        isLoaded: false,
        isLoadings: true,
        error: null
      }
    }
  },
  [UPDATE_FILTER]: (state, { payload }) => {
    const { data } = payload
    const nextFilters = {}
    if (data.jobTypes && data.jobTypes.length) {
      nextFilters.jobTypes = data.jobTypes
    }
    if (data.companies && data.companies.length) {
      nextFilters.companies = data.companies
    }
    if (data.options && data.options.length) {
      nextFilters.options = data.options
    }
    if (data.expertises && data.expertises.length) {
      nextFilters.expertises = data.expertises
    }
    return {
      ...state,
      filter: {
        ...data,
        ...nextFilters
      }
    }
  },
  [SEARCH_JOBS.REQUEST]: state => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        isLoaded: false,
        error: null
      }
    }
  },
  [SEARCH_JOBS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      jobs: {
        ...state.jobs,
        Search: payload.data
      },
      loadInfo: {
        isLoading: false,
        isLoaded: true,
        error: null,
        tab: null
      }
    }
  },
  [SEARCH_JOBS.FAILURE]: state => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: true,
        tab: null
      }
    }
  },
  [CHANGE_JOB_TAB]: (state, { tab }) => {
    return {
      ...state,
      tab
    }
  },
  [RESET_JOBS]: state => {
    return {
      ...initialState,
      salaryRange: state.salaryRange,
      activeVacancy: state.activeVacancy
    }
  },
  [UNFOLLOW.SUCCESS]: (state, { payload }) => {
    if (state.tab && state.tab.id === payload.data.industry_id) {
      return {
        ...state,
        tab: jobTabs.CATEGORY_ALL
      }
    } else {
      return state
    }
  },
  [GET_SALARY_RANGE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      salaryRange: payload.data
    }
  }
}

export default createReducer(initialState, handlers)
