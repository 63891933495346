import React, { PureComponent } from 'react'
import * as routes from 'Constants/routes'
import * as links from 'Constants/links'
import logo from 'Assets/images/1mrjobs.png'
import { Container, Spinner, Content, Logo, BigText } from './styles'

export default class LinkReducer extends PureComponent {
  constructor() {
    super()
    const url = window.location.href
    const index = url.indexOf('/link')
    const pathes = url.substring(index + 6).split('/')
    this.state = {
      directory: pathes[0] || null,
      id: pathes[1] || null,
      success: true,
      isLoading: true
    }
  }
  componentWillReceiveProps(nextProps) {
    const { history } = this.props
    const { isLoading, directory } = this.state
    if (directory === links.vacancy && nextProps.vacancy) {
      history.push(routes.detail)
    } else if (!isLoading) {
      // history.push(routes.categories)
      this.setState({ success: false })
    }
  }
  componentWillMount() {
    const { directory, id } = this.state
    const { history } = this.props
    if (!directory || !id) {
      return history.push(routes.initial)
    }
    switch (directory) {
      case links.vacancy:
        this.reduceVacancy()
        break
      default: {
      }
    }
  }
  reduceVacancy() {
    const { onLoadCurrentVacancy } = this.props
    const { id } = this.state

    onLoadCurrentVacancy(id)
  }
  render() {
    return (
      <Container>
        <Content>
          <Logo src={logo} />
          <BigText>...Redirection</BigText>
          {!this.state.success ? 'Such link does not exist' : <Spinner />}
        </Content>
      </Container>
    )
  }
}
