import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'

export const Container = styled.div`
  color: white;
  background-color: ${props =>
    props.disabled ? colors.stepGrey : colors.mainBlue};
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
`

const loadAnim = keyframes`
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(359deg)
    }
`
export const Loader = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${colors.stepGrey};
  border-bottom: 1px solid ${colors.mainBlue};
  border-right: 1px solid ${colors.mainBlue};
  animation: 2s infinite ease-in-out ${loadAnim};
  border-radius: 50%;
`
