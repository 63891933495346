import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const ADD_EDUCTIONS = createAsyncAction('eductions/ADD')
export const addEductions = ({
  description,
  start_date, // eslint-disable-line camelcase
  end_date, // eslint-disable-line camelcase
  university,
  level,
  profession,
  title
}) => {
  return apiCall({
    endpoint: '/education',
    types: ADD_EDUCTIONS,
    method: 'POST',
    query: [
      {
        description,
        start_date, // eslint-disable-line camelcase
        end_date, // eslint-disable-line camelcase
        university,
        level,
        profession,
        title
      }
    ]
  })
}
export const REMOVE_EDUCATIONS = createAsyncAction('educations/REMOVE')
export const removeEductions = id => {
  return apiCall({
    endpoint: '/education',
    types: REMOVE_EDUCATIONS,
    method: 'DELETE',
    query: {
      id
    }
  })
}
export const EDIT_EDUCATIONS = createAsyncAction('educations/EDIT')
export const editEductions = ({
  description,
  start_date, // eslint-disable-line camelcase
  end_date, // eslint-disable-line camelcase
  university,
  level,
  profession,
  edcId,
  title
}) => {
  return apiCall({
    endpoint: '/education',
    types: EDIT_EDUCATIONS,
    method: 'PUT',
    query: {
      description,
      start_date, // eslint-disable-line camelcase
      end_date, // eslint-disable-line camelcase
      university,
      level,
      profession,
      edcId,
      title
    }
  })
}
