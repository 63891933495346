import Component from './RestorePassword'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { switchModal, closeModal } from '../../../Redux/actions/utils'
import { isAuthenticate, isLoaded } from 'Redux/selectors/auth'

const actions = {
  onSwitchModal: switchModal,
  onCloseModal: closeModal
}

const selector = createStructuredSelector({
  isLoaded,
  isAuthenticate
})

export default connect(
  selector,
  actions
)(Component)
