import React from "react";
import { Wrapper, Text } from "./styles";
import nodata from "images/header/mrJobs_noData.png";

export default ({ height }) => (
  <Wrapper height={height}>
    <img
      style={{
        width: "100px",
        height: "100px",
        marginBottom:"20px"
      }}
      src={nodata}
    />
    <Text>List is empty</Text>
  </Wrapper>
);
