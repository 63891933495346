import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { mountFilter } from '../../../Helpers/mountFilter'
import { unmountFilter } from '../../../Helpers/unmountFilter'
import moment from 'moment'
import PerfectScrollbar from 'perfect-scrollbar'

import {
  SalaryOption,
  Button,
  CloseBtn,
  DateSinceContainer,
  FilterContainer,
  FilterTitle,
  Container,
  SalaryIcon,
  CloseBtnIcon,
  OkayBtnIcon,
  SalaryOptionIcon,
  DateContentContainer,
  Calendar,
  ButtonContainer
} from './styled'
import './style.css'

export default class DropdownDate extends PureComponent {
  componentDidMount() {
    mountFilter('Date', true)
    if (this.props.isCalendarOpen) this.calcHeightFilterContainer()
    const container = this.scrollContainer
    this.scrollbar = new PerfectScrollbar(container, {
      suppressScrollX: true
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCalendarOpen !== this.props.isCalendarOpen) {
      this.calcHeightFilterContainer()
    }
  }

  componentWillUnmount() {
    unmountFilter('Date')
  }

  calcHeightFilterContainer = () => {
    const container = document.getElementById('Date')
    if (container) {
      const { top } = container.getBoundingClientRect()
      const containerHeight = container.clientHeight
      const { clientHeight } = document.documentElement
      if (top + containerHeight > clientHeight) {
        container.style.height = `${clientHeight - top}px`
      } else {
        container.style.height = 'auto'
      }
    }
  }

  render() {
    const {
      onFilterClose,
      POSTED_OPTIONS,
      activeDateOption,
      onDateOptionClick,
      isCalendarOpen,
      dateSince,
      isDateSincePrint,
      onCalendarChange,
      onDateOkBtnClick,
      isUnmount
    } = this.props
    return ReactDOM.createPortal(
      <OutsideClickHandler onOutsideClick={onFilterClose}>
        <Container date id="Date" isUnmount={isUnmount} withScroll>
          <DateContentContainer innerRef={el => (this.scrollContainer = el)}>
            <FilterContainer>
              <FilterTitle>DATE POSTED</FilterTitle>
              <CloseBtn onClick={onFilterClose}>
                <CloseBtnIcon />
              </CloseBtn>
            </FilterContainer>

            {Object.keys(POSTED_OPTIONS).map((key, index) => {
              const item = POSTED_OPTIONS[key]
              const isActive = key === activeDateOption

              if (item.title === 'Since' && isDateSincePrint) {
                return (
                  <DateSinceContainer>
                    <SalaryOption
                      active={isActive}
                      key={item.id}
                      onClick={() => onDateOptionClick(key)}
                    >
                      <SalaryOptionIcon />
                      {item.title}
                    </SalaryOption>
                    {dateSince && <span>{moment(dateSince).format('ll')}</span>}
                  </DateSinceContainer>
                )
              } else {
                return (
                  <SalaryOption
                    active={isActive}
                    key={item.id}
                    onClick={() => onDateOptionClick(key)}
                  >
                    <SalaryIcon />
                    {item.title}
                  </SalaryOption>
                )
              }
            })}

            {isCalendarOpen && (
              <Calendar
                maxDate={new Date()}
                value={new Date(dateSince)}
                onChange={onCalendarChange}
              />
            )}
            <ButtonContainer>
              <Button onClick={onDateOkBtnClick}>
                <OkayBtnIcon /> Okay!
              </Button>
            </ButtonContainer>
          </DateContentContainer>
        </Container>
      </OutsideClickHandler>,
      document.getElementById('root-modal')
    )
  }
}
