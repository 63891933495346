import React from 'react'
import {
  Content,
  Icon,
  BigText,
  SmallText,
  CloseButton,
  modalStyle
} from './styles'
import Modal from 'react-modal'

export const ResultModal = ({ onClose, isOpen, success }) => (
  <Modal isOpen={isOpen} style={modalStyle}>
    <Content>
      <CloseButton success={success} onClick={onClose}>
        <i class="fas fa-times" />
      </CloseButton>
      <Icon success={success}>
        {success ? (
          <i class="far fa-check-square" />
        ) : (
          <i class="fas fa-exclamation-triangle" />
        )}
      </Icon>
      <BigText success={success}>{success ? 'Success !' : 'Failure !'}</BigText>
      <SmallText success={success}>
        {success ? 'Thanks for help' : 'Some error occured'}
      </SmallText>
    </Content>
  </Modal>
)
