import React, { PureComponent } from 'react'
import $ from 'jquery'
import OutsideClickHandler from 'react-outside-click-handler'
import {
  Container,
  Header,
  Arrow,
  HeaderText,
  List,
  ListItem,
  ListText,
  CheckBox,
  SearchIconContainer,
  SearchIcon,
  TextBox,
  ListContainer,
  ArrowIcon
} from './styles'

export default class SearchPanel extends PureComponent {
  componentDidMount() {
    const scrollEvent = document.getElementById('scrollContainer')

    scrollEvent &&
      scrollEvent.addEventListener('ps-scroll-x', this.calcListPosition)
  }

  componentWillUnmount() {
    const scrollEvent = document.getElementById('scrollContainer')
    scrollEvent &&
      scrollEvent.removeEventListener('ps-scroll-x', this.calcListPosition)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.toggleExpand()
    }
  }

  isExpanded = true

  toggleExpand = () => {
    this.calcListPosition()
    // console.log('this.isExpanded---animate>', this.isExpanded)
    this.isExpanded = !this.isExpanded
    if (this.isExpanded) {
      this.$headerArrow.css({ transform: 'rotate(180deg)' })
    } else {
      this.$headerArrow.css({ transform: 'rotate(0deg)' })
    }
    this.$list.slideToggle(100)
  }

  calcListPosition = event => {
    const parentEl = this.container
    const element = this.$list
    if (event) {
      const scrollContainer = event.target
      element.css({
        left: parentEl.getBoundingClientRect().left,
        opacity: (120 / scrollContainer.scrollLeft).toFixed(2) - 1,
        height: scrollContainer.scrollLeft >= 70 ? '65px' : '100%'
      })
    } else {
      element.css({
        left: parentEl.getBoundingClientRect().left
      })
    }
  }

  checkedAll = () => {
    const { items, selectedCatigories } = this.props
    if (!selectedCatigories) return
    const selectedAll = items
      .map(elem => {
        return selectedCatigories.indexOf(elem.id)
      })
      .indexOf(-1)
    return selectedAll < 0
  }

  renderItems = () => {
    const { searchStr, items, onCheck, selectedCatigories } = this.props
    let filtered = items

    if (searchStr && searchStr.length) {
      try {
        filtered = items.filter(
          el =>
            el.name.toUpperCase().search(searchStr.toString().toUpperCase()) !==
            -1
        )
      } catch (e) {
        return false
      }
    }

    return (
      filtered &&
      filtered.map((el, i) => {
        let selected = false
        if (selectedCatigories && selectedCatigories.indexOf(el.id) !== -1) {
          selected = true
        }

        return (
          <ListItem key={i} onClick={() => onCheck(el.id)}>
            <CheckBox checked={selected} type="checkbox" />
            <ListText>{el.name}</ListText>
          </ListItem>
        )
      })
    )
  }

  handleOutsideClick = () => {
    const { visible } = this.props
    if (!visible) return

    this.calcListPosition()
    if (this.$list.css('display') === 'none') {
      return
    }

    this.isExpanded = true
    this.$headerArrow.css({ transform: 'rotate(180deg)' })
    this.$list.slideToggle(100)
  }

  render() {
    const { visible, selectAll, searchStr, onSearchChange } = this.props
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <Container innerRef={el => (this.container = el)} visible={visible}>
          <Header onClick={this.toggleExpand}>
            <HeaderText>
              {this.props.companies
                ? 'Select companies'
                : 'Select job сategories'}
            </HeaderText>
            <Arrow>
              <ArrowIcon innerRef={el => (this.$headerArrow = $(el))} />
            </Arrow>
          </Header>
          <ListContainer
            innerRef={el => (this.$list = $(el))}
            visible={visible}
          >
            <List>
              <ListItem onClick={selectAll}>
                <CheckBox checked={this.checkedAll()} type="checkbox" />
                <ListText>Select All</ListText>
              </ListItem>
              <ListItem bordered={true}>
                <SearchIconContainer>
                  <SearchIcon />
                </SearchIconContainer>
                {/*<TextBox*/}
                <input
                  style={{
                    fontSize: 13,
                    flex: 1,
                    border: 'none',
                    outline: 'none',
                    marginLeft: 16,
                    padding: '1.6px 0',
                    color: '#545454'
                  }}
                  ref={input => input && input.focus()}
                  placeholder="Search"
                  value={searchStr}
                  onChange={onSearchChange}
                />
              </ListItem>
              {this.renderItems()}
            </List>
          </ListContainer>
        </Container>
      </OutsideClickHandler>
    )
  }
}
