import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { CareerTimeLineBlock } from '../../../../Blocks/CareerTimeLineBlock'
import born from '../../../../../images/careerTimeLine/born.png'

import {
  Container,
  ItemBlock,
  DateBlock,
  DateText,
  DateLine,
  IconBlock,
  Icon,
  InfoBlock,
  VerticalLine,
  InfoContent,
  Type,
  Title,
  SubTitle,
  Description,
  InfoButtonContainer,
  InfoButtonIcon,
  BornIcon,
  BornText
} from './styled'

const TimeLite = ({
  positions,
  academQualif,
  profQualif,
  activities,
  onItemClick,
  userAge
}) => {
  const allItems = positions.concat(academQualif, profQualif, activities)
  const allItemsParsed = allItems.map((item, i) => {
    const itemKeys = Object.keys(item)
    let type = null
//    console.log('academQualif', academQualif)
//    console.log('profQualif', profQualif)
    if (itemKeys.indexOf('address') > -1) {
      type = 'experiences'
    } else if (itemKeys.indexOf('acadm_qualf_id') > -1) {
      type = 'academic qualification'
    } else if (itemKeys.indexOf('prof_qualf_id') > -1) {
      type = 'professional qualification'
    } else {
      type = 'activities'
    }

    return {
      type,
      title: item.title || item.position,
      subTitle: item.sub_title || item.company,
      description: item.description
        .replace(/(<(\/?[^>]+)>)/g, '')
        .replace(/&nbsp;/g, ''),
      id: i,
      elemId: item.id,
      startDate: moment(item.start_date).year(),
      endDate: moment(item.end_date).year(),
      endDateSort: moment(item.end_date),
      isCurrently: !!item.currently
    }
  })

  const present = allItemsParsed.filter(el => el.isCurrently).sort((a, b) => a.endDateSort - b.endDateSort)
  const notPresent = allItemsParsed.filter(el => !el.isCurrently).sort((a, b) => a.endDateSort - b.endDateSort)
  const sortItems = [...notPresent, ...present.reverse()]

  const renderItems = () => {
    return sortItems.map((item, i) => {
      const link = `${item.type.split(' ')[0]}${item.elemId}`
      let icon = null
      switch (item.type.split(' ')[0]) {
        case 'experiences':
          icon = 'fas fa-building'
          break
        case 'academic':
          icon = 'fas fa-user-graduate'
          break
        case 'professional':
          icon = 'fas fa-user-tie'
          break
        case 'activities':
          icon = 'fas fa-trophy'
          break
        default:
          icon = 'fas fa-briefcase'
          break
      }
      return (
        <ItemBlock
          isFirstElement={!userAge && i === 0}
          onClick={() => onItemClick(link)}
        >
          <DateBlock>
            {/*<span style={{fontSize: 15}}>Present</span>*/}
            <DateText>{item.isCurrently ? 'Present' : item.endDate}</DateText>
            <DateLine />
            <DateText>{item.startDate}</DateText>
          </DateBlock>
          <IconBlock>
            <Icon className={icon} />
            {sortItems[i + 1] ? <VerticalLine /> : null}
          </IconBlock>
          <InfoBlock>
            <InfoContent>
              <Type>{item.type}</Type>
              <Title>{item.title}</Title>
              <SubTitle>{item.subTitle}</SubTitle>
              <Description>{item.description}</Description>
            </InfoContent>
            <InfoButtonContainer>
              <InfoButtonIcon />
            </InfoButtonContainer>
          </InfoBlock>
        </ItemBlock>
      )
    })
  }

  return (
    <CareerTimeLineBlock title={'Time Line'} type={'timeLine'}>
      <Container>
        {userAge ? (
          <ItemBlock
            bornElement
            isFirstElement={true}
            onClick={() => onItemClick('whoAmI')}
          >
            <IconBlock>
              <Icon className={'far fa-dot-circle'} />
              <VerticalLine />
            </IconBlock>
            <BornIcon src={born} />
            <BornText>Born: {moment(userAge).format('DD MMMM YYYY')}</BornText>
          </ItemBlock>
        ) : null}
        {renderItems()}
      </Container>
    </CareerTimeLineBlock>
  )
}

TimeLite.propTypes = {
  academQualif: PropTypes.array,
  activities: PropTypes.array,
  positions: PropTypes.array,
  profQualif: PropTypes.array,
  onItemClick: PropTypes.func
}

TimeLite.defaultProps = {
  academQualif: [],
  activities: [],
  positions: [],
  profQualif: [],
  onItemClick: () => console.warn('not scroll function')
}

export default TimeLite
