import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD = createAsyncAction('job_types/LOAD')
export const loadJobTypes = text => {
  return apiCall({
    endpoint: '/job-types',
    types: LOAD,
    query: {
      text
    }
  })
}
export const REMOVE_JOB_TYPE = createAsyncAction('job_types/REMOVE')
export const removeJobType = id => {
  return apiCall({
    endpoint: '/job-types',
    types: REMOVE_JOB_TYPE,
    method: 'DELETE',
    query: {
      id: [id]
    }
  })
}
export const EDIT_JOB_TYPES = createAsyncAction('job_types/EDIT')
export const editJobTypes = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/job-types',
    types: EDIT_JOB_TYPES,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}
