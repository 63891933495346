import React, { Component } from 'react'
import Spiner from 'react-spinkit'
import { Logo } from '../blocks'

import { Window, IconButton } from 'Components/Blocks'
import { Content, BottomBlock, ContentConfirmed, Icon, BigText, SmallText, Link } from './styled'
import { innerRoutes } from '../../../../../Constants/modalRoutes'
import verify from 'Assets/images/new-post-100.png'
import verifyExpired from 'Assets/images/icons8-id-not-verified-100.png'
import alreadyVerified from 'Assets/images/icons8-id-verified-100.png'
import modalRoutes from 'Constants/modal_routes'

export default class ResendWindow extends Component {
  //eslint-disable-next-line
  componentDidMount() {
    const { loadInfo, data, isEmailConfirmed } = this.props
    if (!loadInfo.loading && !data.expired && !data.email && !isEmailConfirmed) {
      this.props.onClose()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loadInfo, data, resendLoadInfo } = nextProps
    if (!loadInfo.loading && !data.expired && !data.email) {
      this.props.onClose()
    }
    if (resendLoadInfo.success) {
      this.props.onSwitchModal(innerRoutes.login)
    }
  }

  handleSubmit = () => {
    const { resendConfirmEmail, data } = this.props
    resendConfirmEmail(data.id, data.email)
  }

  renderString = () => {
    return (
      <React.Fragment>
        There may be a problem with current verification link. To get verify click
        &nbsp;<Link onClick={() => this.props.onSwitchModal(modalRoutes.login)}>login</Link>&nbsp;
        and get verify link. Follow the directions in the email to get verify.
      </React.Fragment>
    )
  }

  onModalClose = () => {
    const { onClose, onConfirmEmailClean } = this.props;
    onConfirmEmailClean();
    onClose()
  }

  renderEmailAlreadyConfirmed = () => {
    return ( <Window onClose={this.onModalClose}>
      <Content>
        <div style={{ alignSelf: 'flex-start' }}>
          <Logo />
        </div>
          <React.Fragment>
            <ContentConfirmed>
              <Icon src={alreadyVerified} />
              <BigText>Email already verified</BigText>
              <BottomBlock>
                <IconButton
                    icon="fas fa-check-circle"
                    title="Click here to login"
                    width={'300'}
                    onClick={() => this.props.onSwitchModal(modalRoutes.login)}
                  />
              </BottomBlock>
            </ContentConfirmed>
          </React.Fragment>
      </Content>
    </Window>)
  }

  render() {
    const { data, loadInfo, onClose, resendLoadInfo, isEmailConfirmed } = this.props
    let newEmailArr, newEmail
    if (data.email) {
      newEmailArr = data.email.split('@')
      newEmail = `${newEmailArr[0][0]}...@${newEmailArr[1]}`
    }

    if (isEmailConfirmed) return this.renderEmailAlreadyConfirmed();
    return (
      <Window onClose={onClose}>
        <Content>
          <div style={{ alignSelf: 'flex-start' }}>
            <Logo />
          </div>
          {loadInfo.loading && <Spiner />}
          {loadInfo.success && (
            <React.Fragment>
              <Content>
                <Icon src={data.expired
                  ? verifyExpired
                  : verify} />
                <BigText>
                  {data.expired
                    ? 'Unable To Verify Profile'
                    : 'Verify Email Address'}
                </BigText>
                <SmallText>
                  {data.expired
                    ? this.renderString()
                    : `Your Mr.JOBS ID email address must be verified before you can sign in.
                  Verify the “${newEmail}” with in 12 Hours.`
                  }
                </SmallText>
              </Content>
              {/*<Title>
                Your input email
                <br />
                <br />
                {data.email}
                <br />
                <br />
                not verified
              </Title>*/}
              <BottomBlock>
                <IconButton
                  icon="fas fa-check-circle"
                  loading={resendLoadInfo.loading}
                  title="Resend email verification"
                  width={'300'}
                  onClick={this.handleSubmit}
                />
              </BottomBlock>
            </React.Fragment>
          )}
        </Content>
      </Window>
    )
  }
}
