import styled from 'styled-components'
import * as colors from 'Theme/colors'

export const Content = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: #dbd9d9;
    width: 5px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
`
export const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 5px 0;
  padding-bottom: 8px;
`
export const GreyText = styled.div`
  padding-left: 16px;
  color: grey;
  font-size: 15px;
`
export const Form = styled.div`
  margin-top: 24px;
  flex: 1;
`
export const IconBlockContainer = styled.div`
  position: absolute;
`
export const Icon = styled.img`
  width: 80%;
  height: 80%;
`
export const AgeContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const AgeText = styled.div`
  color: ${colors.lightGreyFont};
  margin: 0 10px;
  font-size: 20px;
`
export const AgePicker = styled.div`
  flex: 1;
`
export const SkillsContainer = styled.div`
`
export const AddContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.lightGreyFont};
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
`
export const panelStyle = {
  background: 'white;'
}
