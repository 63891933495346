import { createSelector } from 'reselect'

const jobTypesData = store => store.jobTypes

export const getJobTypes = createSelector(jobTypesData, data => data.jobTypes)

export const getJobTypesLoadInfo = createSelector(
  jobTypesData,
  data => data.loadInfo
)
