import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const ADD_REFEREE = createAsyncAction('referee/ADD')
export const addReferee = data => {
  return apiCall({
    endpoint: '/referee',
    types: ADD_REFEREE,
    method: 'POST',
    query: {
      props: data
    }
  })
}
export const REMOVE_REFEREE = createAsyncAction('referee/REMOVE')
export const removeReferee = id => {
  return apiCall({
    endpoint: '/referee',
    types: REMOVE_REFEREE,
    method: 'DELETE',
    query: id
  })
}
export const EDIT_REFEREE = createAsyncAction('referee/EDIT')
export const editReferee = ({
  id,
  name,
  company,
  designation,
  companyAddress,
  email,
  phoneNumber
}) => {
  return apiCall({
    endpoint: '/referee',
    types: EDIT_REFEREE,
    method: 'PUT',
    query: {
      id,
      props: {
        name,
        company,
        designation,
        companyAddress,
        email,
        phoneNumber
      }
    }
  })
}
