import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollBar from 'perfect-scrollbar'

export default class WithCustomScrollbar extends Component {
  componentDidMount() {
    const { id } = this.props
    new PerfectScrollBar(`#${id}`)
  }
  render() {
    const { id, children } = this.props

    if (id) {
      return <div id={id}>{children}</div>
    } else {
      return <div>{children}</div>
    }
  }
}

WithCustomScrollbar.propTypes = {
  id: PropTypes.string.isRequired
}
