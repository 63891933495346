import Component from './ContactUs'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadContactUs } from 'Redux/actions/contactUs'

import { getContactUs, getContactUsLoadInfo } from 'Redux/selectors/contactUs'

const actions = {
  onLoadContactUs: loadContactUs
}

const selector = createStructuredSelector({
  contactUsList: getContactUs,
  contactUsLoadInfo: getContactUsLoadInfo
})

export default connect(
  selector,
  actions
)(Component)
