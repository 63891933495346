import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD = createAsyncAction('options/LOAD')
export const loadOptions = () => {
  return apiCall({
    endpoint: '/options',
    types: LOAD
  })
}
