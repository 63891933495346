import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import { Element } from 'react-scroll'

import {
  CardImage,
  CardTimeComponent,
  CardTitle,
  CardSubTitle,
  CardEditLabel
} from '../../blocks'

import { CardContent, CardDesctiption, Container } from './styled'

export default class Card extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    startDate: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onModalOpen: PropTypes.func.isRequired
  }

  state = {
    isShowEditLabel: false
  }

  render() {
    const {
      title,
      subTitle,
      id,
      startDate,
      endDate,
      description,
      type,
      image,
      faIcon,
      onDelete,
      onModalOpen,
      currently
    } = this.props
    const { isShowEditLabel } = this.state

    const elementLink = `${type}${id}`

    return (
      <Element name={elementLink}>
        <Container>
          <CardImage faIcon={faIcon} image={image} />
          <CardContent>
            <CardTimeComponent endDate={endDate} startDate={startDate} currently={currently}/>
            <CardTitle text={title} />
            <CardSubTitle text={subTitle} />
            <CardDesctiption>{ReactHtmlParser(description)}</CardDesctiption>
          </CardContent>
        </Container>
      </Element>
    )
  }
}
