import Component from './EmailWindow'
import { connect } from 'react-redux'
import { sendPasswordReset } from '../../../../../Redux/actions/passwordReset'
//import { getSendMailPasswordResetInfo } from 'Redux/selectors/passwordReset'

const mapStateToProps = state => ({
  sendMailInfo: state.passwordReset
})
const actions = {
  onSendEmail: sendPasswordReset
}

export default connect(
  mapStateToProps,
  actions
)(Component)
