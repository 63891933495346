import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { CareerTimeLineBlock } from '../../../../Blocks/CareerTimeLineBlock'
import { ExpertiseBlock } from './blocks'

import { Container, Content } from './styles'

class ImGoodAt extends PureComponent {
  static propTypes = {
    expertises: PropTypes.array,
    subExpertises: PropTypes.array,
    userExpertises: PropTypes.array,
    userSubExpertises: PropTypes.array
  }

  state = {
    isModalOpen: false,
    openedModalType: null,
    changedExpertiseId: null
  }

  renderSections = () => {
    const { userExpertises, userSubExpertises } = this.props

    return userExpertises.map(item => {
      const itemSubExp = Object.keys(userSubExpertises).find(
        key => Number(key) === Number(item.id)
      )
      return (
        <ExpertiseBlock
          items={userSubExpertises[itemSubExp]}
          key={item.id}
          title={item.name}
        />
      )
    })
  }

  render() {
    return (
      <CareerTimeLineBlock title={"i'm good at"}>
        <Container>
          <Content>{this.renderSections()}</Content>
        </Container>
      </CareerTimeLineBlock>
    )
  }
}

export default ImGoodAt
