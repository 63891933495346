import styled from 'styled-components'
import * as colors from 'Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const Container = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
  width: 460px;
  height: 650px;
  max-height: 100%;
  border-radius: 1rem;
  flex-direction: column;
  @media screen and (max-width: 460px) {
    height: 100vh;
    width: 100%;
    border-radius: 0;
  }
  @media screen and (max-height: ${props => props.height || '620px'}) {
    height: 100vh;
  }
`
export const ContainerWithScrollbar = styled(PerfectScrollbar)`
  display: flex;
  background-color: white;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
  width: 460px;
  height: ${props => props.height || '620px'};
  max-height: 100%;
  border-radius: 1rem;
  flex-direction: column;
  @media screen and (max-width: 460px) {
    height: 100vh !important;
    width: 100%;
    border-radius: 0;
  }
  @media screen and (max-height: 650px) {
    height: 100vh !important;
  }
`
export const CloseButton = styled.div`
  align-self: flex-end;
  width: 30px;
  height: 30px;
  position: relative;
  top: -15px;
  right: -15px;
  border-radius: 15px;
  background-color: #552a7a;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 460px) {
    top: 5px;
    right: 5px;
  }
  @media screen and (max-height: 650px) {
    top: 5px;
    right: 5px;
  }
`
export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  height: 7px;
  margin: -15px 1.5rem 0;
`
export const Step = styled.div`
  height: 7px;
  width: 22%;
  background-color: ${props =>
    props.active ? colors.buttonGreen : colors.cardViewGrey};
  border-radius: 0.4rem;
`
export const Content = styled.div`
  padding: 0 1rem;
  margin-top: -22px;
  flex: 1;
  display: flex;
  flex-direction: column;
`
