import styled from 'styled-components'
import * as colors from 'Theme/colors'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 20px;
`
export const Icon = styled.img`
  width: 80px;
  height: 80px;
`
export const BigGreenText = styled.div`
  //color: #0acd00;
  color: ${colors.blackColor};
  font-size: 28px;
  margin: 15px 0;
  text-align: center;
`
export const SmallGreenText = styled.div`
  //color: #0acd00;
  color: ${colors.blackColor};
  font-size: 16px;
  margin: 11px 4px;
  text-align: center;
`
export const Link = styled.a`
  color: ${colors.blackColor};
  text-decoration: underline;
`
export const SmallTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
`
