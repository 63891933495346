import { createReducer } from 'Helpers/redux'

import { LOAD_PERSONALITIES } from 'Redux/actions/personalities'

const initialState = {
  personalities: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const handlers = {
  [LOAD_PERSONALITIES.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD_PERSONALITIES.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: payload.data
      }
    }
  },
  [LOAD_PERSONALITIES.SUCCESS]: (state, { payload }) => {
    return {
      personalities: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
