import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const CONFIRM_EMAIL = createAsyncAction('emailConf/CONFIRM_EMAIL')
export const confirmEmail = identity => {
  return apiCall({
    types: CONFIRM_EMAIL,
    endpoint: '/registration/email-confirmation',
    query: {
      identity
    },
    method: 'POST'
  })
}

export const CONFIRM_EMAIL_CLEAN = 'emailConf/CONFIRM_EMAIL_CLEAN'
export const confirmEmailClean = () => {
  return {
    type: CONFIRM_EMAIL_CLEAN,
  }
}