import Component from './LocationWindow'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { setLocation } from 'Redux/actions/register'
import { getRegisterData } from 'Redux/selectors/register'

const actions = {
  onComplete: setLocation
}
const selectors = createStructuredSelector({
  registerData: getRegisterData
})

export default connect(
  selectors,
  actions
)(Component)
