import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'
// import cookie from 'react-cookies'

export const LOAD_PROFILE = createAsyncAction('user/LOAD_PROFILE')
export const loadProfile = () =>
  apiCall({
    endpoint: '/candidates/profile',
    types: LOAD_PROFILE
  })

export const FETCH_USER = createAsyncAction('auth/FETCH_USER')
export const fetchUser = () => {
  return apiCall({
    endpoint: '/candidates/user-data',
    types: FETCH_USER
  })
}

export const UPDATE_CANDIDATE = createAsyncAction('candidates/UPDATE_CANDIDATE')
export const udpdateCandidate = data =>
  apiCall({
    endpoint: `/candidates`,
    method: 'PUT',
    types: UPDATE_CANDIDATE,
    query: data
  })
export const UPDATE_CANDIDATE_PASSWORD = createAsyncAction('candidates/UPDATE_CANDIDATE_PASSWORD')
export const udpdateCandidatePassword = data =>
  apiCall({
    endpoint: `/candidates/update-password`,
    method: 'POST',
    types: UPDATE_CANDIDATE_PASSWORD,
    query: data
  })

export const UPDATE_NOTIFICATION_DATA = createAsyncAction(
  'user/UPDATE_NOTIFICATION_DATA'
)
export const updateNotificationData = email =>
  apiCall({
    endpoint: '/candidates/update-notification-data',
    method: 'POST',
    types: UPDATE_NOTIFICATION_DATA,
    query: { email }
  })

export const FETCH_USER_BY_ID = createAsyncAction('auth/FETCH_USER_BY_ID')
export const fetchUserById = id => {
  return apiCall({
    endpoint: `/candidates/user-data/${id}`,
    types: FETCH_USER_BY_ID
  })
}

export const RESEND_CONFIRM_EMAIL = createAsyncAction(
  'user/RESEND_CONFIRM_EMAIL'
)
export const resendConfirmEmail = (id, email) =>
  apiCall({
    endpoint: '/registration/resend-confirm-email',
    method: 'POST',
    types: RESEND_CONFIRM_EMAIL,
    query: { id, email }
  })

export const MARK_LOGIN = createAsyncAction(
  'user/MARK_LOGIN'
)
export const markLogin = () =>
  apiCall({
    endpoint: '/activeUsers',
    method: 'POST',
    types: MARK_LOGIN
  })
