import styled from "styled-components";

export const Usual = styled.div`
  height: 35px;
  text-align: center;
  border: 1px solid #545454;
  border-radius: 30px;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 7px;
  user-select: none;
  cursor: pointer;
  color: #545454;
  :hover {
    background: rgb(90, 64, 162);
    color: white;
  }
`;
export const Active = Usual.extend`
  background: rgb(90, 64, 162);
  color: white;
`;
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 45%;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: flex-start;
`;
