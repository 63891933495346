import styled from 'styled-components'
import * as colors from '../../../../../../Theme/colors'

export const Title = styled.h3`
  font-weight: normal;
  margin: 0 0 5px 0;
  font-size: 25px;
  color: ${colors.careerBlue};
  overflow: hidden;
  text-overflow: ellipsis;
`
