import React from 'react'

export const search = (
  <img
    alt=""
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6
        QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAgZSURBVGhD7VgLUJTXFSamNam1JY06JsXR4Y0
        rLo8FebOwwrK85bHI+7XsA/CRKIwoZlaNiIqPYDJQn0QlJhGTCDoqQkI0tkMTm+k4te1oYzuNo43vGcAKke/03PU3G
        mLtVLuwneGb+eac851z73/v3vvf///XbhSjGIWVoaVnpxjh4FCGgCll0PyyHEEO8zHFzkw/kipsGfTM1HIkTC1FE/P
        atDKiHxI3ppWieVo5UnhSY6SGtgPnUkQ6ltJpp1Iip1L0OZpw0NmICkcjcliPcjIiy9mARU4mtDib0OtiInIx4QxrG
        qmLEQZvITcD6tyMRG5GXGG//GUDjZOydp46THYxQiYro5fEigltWgE9P90InbselzwMRB56NCgM9GNLg5GATEtjp+v
        RJtMTsW3mwY4X22WGHmkcfyDTo3cG5+5TVoLbrLXN1CNb1PHgx3HtVk+RL0GniKWuhxfyEuySlxB56VAt4pklpGD/S
        y8da8W4IS/GXpHzKiGDXIel7O9m7aq3yOtw1luPYNHOW4cFTPjoaP/9VRs2+Ogwz7eYyKcIb4jYtwgZvsW4zfYq27K
        Ht4rh9ANfyaeWXyGKFUW47FeEfmaR0Nm+7l9ExLmllsLhQKAOL/oX4sasQpzS8j3CVhVQgAHm7xV5NNVMNMZ8ErnmE
        +hk27viJGqlpt/Bv4BeCixAN7e5G5CPJLESHLcHFaA3LAcvS2XWRXAe6oLzAbZB/nmYEJKH6+x/FVqESW90YvLmLpz
        a3EX0HT/54UQEogxkH5KPP4bk0S0x+PBszAzLxWBoLhqlEutBrEB4Hr4Jy8NREbO/SZkL8ACCt3aQ/Y7jOLOzg+hhs
        vbIiQio8iFX5mAwIhfbRRyZixZmj2Y+nrMUWAuR2QhX5RCpsqFT8jGqykEP86DItRzFlgPHiIaS9X87EYHZ2dgblYW
        BKC3ZR2eTlkkcx0lp60CdCbM6iyeSickxmYgXvoYv3sGrcfww+o8fJhrK9kOPnwj3qdFkcj8ZyExKws80mbirmYsNU
        to6iM3AjrgM9Aqf7fK4uUSJPKnftpKWSY9id+vjJ5Kejp8kcD8JWqwTcUIGLjHftyStheQMHGaek/y3kjLQL/w/fYCK
        P39I9Chy7rETEUhJx1XmXuHPSccXKWn4zJKwFtLS0ZaahvMWPw1b2B/4XzzE0lNxXZuK3cLXptJp9rstCWthbiq2zU
        3BbYufgmVzU4n4JOP3qCdHbjR+mpVClJlyb+WyUvAP5hFL0lrIScZrOXOI8uPhkDMHauFnJ/O701MgNxmJeclEbNMK
        kumF3CQMsr9RSlsHBYkI5ItRQRJMvBJj85PoVmHSvWfKk6IwES3cx50yLY0vjkdOcSJRURJFSWnrwMxvrcUJuKRLwC
        ciZltTwhfWP+GF9XEI0CcAJfGoF7EhAa36eFwzKIbhtd4Yh1XGeB58HCLna/BzYzwum+LxdYmGP2X/C3DbSaZYXCiN
        xVVDAk0s1cC/LA5gWl5ErQ4x+PJYfFMWi9+ZeXvxhQM4vlOuwbmFMXCXyh6LedFwnB+DM9zXwLxYqMSrz4IYnFqgwb
        Xy2ZgglVkfC2OocKGGaKEGu0T8qhoa1m69EoOehWpUV/IT2lI4BIv5hOLaxa/G4CbX9rKfJvRX1KhfpCZizWQpHE4s
        4osv5ovz4OrEvVPJq1ERja4Koanxz8poHK6IwvrKKCypiKa1bNs47quMJmL76woV5OIZxL5ZaFzfIHU9vBDbYUkUmq
        uiiNgeWqSkiUJfEg0VxzuqZuPi0tlED4jLVSrsXqZCrKirCqVfcPy+JafC56I/oY8Q6JlqFZZVR2KQbQ/b5WZpQgJ8
        DzzHA3dg7XlJsjOr8eJrkahYHombyyMwyP7f2f/WHAmVVDJyWBUOnxUR6FgRQWRW4u4KJU6sVGL1ygiUrVSSln0T+6
        tY72T77Uqu4/rPzSrMqgnFJNb/tkqJ69zWRerSzmxHY14PQ/hq/iEkafhQE4GQ1eHYtFqJCzXhREPJua/WhGEnD9Dy
        x8N91IZj5ppw9DHPro0i+xoVJteE48QablMbip41oQiQSocfdXxC1QSRx1oedF0QPDc8tOUehXWhNGddKLA+hD5le5
        E5sD4U1XUh+Jp5ZWPgg9WyeWwMwsFNwUSbgnF5QwiFCm1TEFVtZm1zMM7X8za0FNoq6vlQqA/Eti2BRPUB+A3/+g77
        +RRjrXZLIPBmIM4x+9n/YquC7KVmtoeGWWhqDCBqCMCvxKTe9MeExgC0C63RHwfeUtJ4rslrnGWJP94vo7FSU9vCVj
        86vd0PF/nXnrjNn7zZv7DND3e3+1MV8RHf5EUvsHaIY7LQD81Cl5rbDnb4InKXAv1NCpxt8kXfLl9ce9sXapHb6Q0Z
        6+eYg6ybOd/QpCB62+c/f0KPCPZ4IWePD7DHG7fZWk4otmnMnr0+uNnsiwShiXuHa1qbfYje8Uap0GwO73pTFZPe9U
        LjPi/U7vMG3pPjDzxgV6nEgjYFjXvPC92cG9gnh48k2xZaZqKhRU4kcT/f2OOl1PfA+lSuvXPAE9skybYgts6Hnmj9
        yJOIba4kPxIHZfjLRzOe7lPbqhBbp02G7kPT0d82495NPxRH3eF+WIZB5vB8UT4pjrhg0hEPnD/mjr726d9/12qXkX
        e7B/7azjmuc5Zk28UxGVw63XClwxU9na5YdtwV8R1uWM1a38euuNbhThFSqe2jy408ulzx5aeuRA+Ioyfc4CiV/H/h
        pCPkp1yg/syVnCRpFKMYxVPBzu5ffutVSUt4f1AAAAAASUVORK5CYII="
  />
)

export const identificationCard = (
  <img
    alt=""
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6Q
        AAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAZzSURBVGhD7Zh9TJVlGMZZpau1NFu13JopYCn41TkK
        CEcP8iXyih8IYoAKKEchlZKVTi20cH6lkTPMJD9S+zB0mkxbfqRTG2uttTadfzXL/CP/MFdurZXX3X2/3HwcfB/OgcxO2/
        lt187zXs99P891Hw94NCJMmDBhwjxZgVLWEdbxDhKvVMtCmwHlKBpQTtR/Pq70L6ev/cSe7A2Yh0It/4/Jo3sH+pAzsJx8
        t2k+vhw4D9e91XSfVrcinuzZNR36ouZjZvRC9NLSu0PMXNTElBGZNHgufpC6F89ieNU5vCOStXiy59TTLHwuNXeNYXPQOH
        wOLg0tociO4r1Tw0qbB6k9iS21p4hs8dru5T2p6djH5x1m/xepuaO4ijF0ZAmlOclViiZXCb7vZM8e5JNjqGv4jEgka/tc
        3pOajn3uUpxk3ezot5dkkjOCJq4YO+OLibqv5kGaDqGu6TCRLV6LJ3vOPcEpbjZ2yDkB8cwmt2cWUdIsvO+ZSWldFfc1eW
        Y1D3K1gQb91EBpIlnb5/OeXePQG0h2Js42phAuOatTkgthjSsi8s4kj1pdYlwRGpOLcDW1gCKdJHtSo+VdQjJJNsmolpnU
        GbDSC4jSnuveIOkFaJT+ztW9QSST9EtGtcxkcVHWDKLMbg7C/ZlWPvk6k9RoeZeQTJJNMqplZlIurMnTiabkdW+QfxPJJN
        kko1pmpnLRtFyivBAcRDJJNsmolpkZU2Hl5/AgUwIPkpuLaK715eegcnoOpVVX0z261Sn5UxCVPxVLgtH0HGRrW4RkkmyS
        US0zhVxUNIVoZieD8DvTs3AytrJuSW2b8F3hJDyjZUZa7ghO2KdtEZJJPOlXy0zpJFglk4hKJpoHKcnGdrsmGx/OzkbSrG
        zE8vqF4mz8yrrim0iPaqkjPh/1KLCoTzAqSseD2hYhmeReyaiWmbIsWGUTiTiM4yDsDyqbCLD2qtUKexmyN9dCjVqOlI1H
        X59FecGI88Rrm9ztkWySUS0z5VxUkUVUkek8SMUELJD95ydgpFp+VGThAusrfXSk9Y6g1PbRkkziSb9aZhaMh7Uok4jlOA
        j7S2W/cjI9rJYfizLRuDCz+SuKCV8a9V6UQe5gtCAdA7RN7vbI3ZJRLTNVXFSVQbQ4zXmQxRm0VPYrvc6DLE5HI+uyPjqy
        hAfhM9yBxL9U7tUWG8nEPklGtcy8nAqLLyKW4yAvpaJC9pemYpRafixJxUU+o0kfHWl3RyD11hYbfvaIL/1qmVmWDGtFCt
        FywyArxiHW3k9p++y28Eoyxq9IAVgb1HJk2Rj0XZ5CeYHkc1MPbbGRTHK3ZFTLzCouWpVMVO29fZDqdDy+0otzsm/Li4+k
        7jUebmUyqrj3N/FXeulGtdf8fWobB1zjoT4mbUzAA1rqh9zVfHcQg9SMgbV6LNHrHQbhQ+6vGYtvV4/FH/xawdrC61tS2y
        Lu/aa5H5f49a/VHiRpux8td5jEZ6/RUj8kk73P/WqZ2cBF6z1Ea5P8B1mXhI3ir0tEvloR/O5FrvegjPcq2R9XHdH8FYUH
        eIz9q+s8uLyh3V9oLaxPRBT3LDHKA6+W+iGZJINkVMvMptGwNiUSvdFukLVu6s3+TVaDWgHZlIjJcs6bo+/cf8hJJjlTMq
        plZnMcrM0JRLXxbYPUJmC2eLyXolZA5E/nrQT8uDkeJ9T6x0gmzRF4kLe5aGsc0bZ2g9TFoUa8+kQ8pFZQcN+BulG4oo+t
        8A97v7p48m0ZRU9sT8Aj9joB0ftjqKest7oRJ3VbR1Exn9H65kkmySEZ1TJT74JVP5LoPXfbIPy8q96NP1nbuqLtblyoH4
        lbekwr7e/Y4cJQWW93oYAH7C1r7rF/2Hl9g9etv+al3t7nfrXM7OSi3S6i3e0G2fUs3t3lwvXuSo9phb0M8fmuhD0jEGPX
        jUDunjj0kvVuF17Vustyt93ESCbJJhnVMvPBcFj7RhDtG9Y2SKggmSSbZFTLzMdctH8YESvkBpFMkk0yqmXmwBBYB4cQ8W
        uJWiGDZNJsgQc5xEWfxhIdjgUOD6EbISXOJNkko8Y1c2wQrGODiQ5Ow7X9ZfwRCyFJJskmGTWumRPRSD/xNNHRalw8cpQo
        lCSZJJtk1LhmzvajPqejcO3MQPx+JgYXQ0qc6XQ0fv7iKed/1N3G+UgMOR+FveeicDyUxLn2nI1CrMYMEyZMmP8tERF/A4
        V9T3JFHgn8AAAAAElFTkSuQmCC"
    style={{ width: '63px', height: '63px' }}
  />
)

export const building = (
  <img
    alt=""
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAA
        RnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAVCSURBVGhD7VNdSFxnEBXal0LABvoUE0FjBNd/XX9ai7pmoxtj
        k2hcrVttamo0bhsDEWqI0CUkZQtNi0JdFBpooBoUIjSkFQqJL1LoQx56KX1rRSiUIoU8FJpCmemc736u6/VW2/W692UPHO43c2
        bmzMBuRhpppLH/OHSZyrPe5WAyPBTmMj3GXRwJU9eRd5iTJxFm6HHuIfcSTQn/yBlmfzJEL2boce7h2BDF8of4qQ7/N9CLGTp0
        D54BinkGth7iCfMByQ3aUjRdpiC5p5ihQ/dQLEsUv205ZJCzSwaY7QhNlymgFzN06B4qLlCssn/rIQ0Rft7bz7l2hKbLFNCLGT
        p0D9XnKVZzPvn/CHoxQ4fuoa6PJl/poz/r3qSxZIhe4YQe5x7q36Cqxl5ab+xlTo607gtxpR7nLoJBfu5UiA8eD9ENf4hZvsWI
        7QhN19xAjF49Zv/R3EWlLT00EAjx4E6UmsVAD7Pwmp2uCE1qVK2dnkDl+TqV6DX2hrYuqjjVTX+3dTO7QeUtO+h1ksfZIF1qDz
        J3dFL32SBXppLwhDd20Oskj85OGgqeYw6eo4tdHexPJeEJb+yg10keXe1U1NNOz3ramd0hPcMOep29IdTBub2nabXvDD3pO83B
        lFC84Nn7GuXoNZzBhTYy+tvooQ73HfCCpw6dw0UZKkzZIfCCpw6dw3ArGeGT5iHhVn4rfJI/3MZWCkC/coZftNWF0MwZFLDTMV
        vp4gVPvB3FSAsZIwHzEHxHAsxWXm6hqNL9nG2nK4qGGtTa6oke4om3oxhtJuPqCdMEX8RKEIz5OXO0mXn0hHmIxNmIrzbTsCoQ
        4I0cNMSo1XGmKhDs5OEY3jtOhlCZ4ItYCQIsI2TJxQ/RcfwQvJGDpuOojuOHYKbQ1sMxXPeRMd5kmuCLWAkCLDPexHy9afMQxO
        O+zUPwRm7jENTqOH7ITh6OISJDhcoE3/cb6S/hT4o+Wo34mOWtDonIsir20XpCzTpy0FCDWl2zGq+RmYke8MTbUdxqIONmvWly
        s4Gv3WrgBSs/qKcQ9EgDv2Sng9BQg1o7HbOhwwueeDuK6KtkCNUhqQC84KlD5/BRHRm361J3CLzgqUPnMPEyGRO15iEf11LeZA
        1XWin5LFUsuF1F+VYdOS1jRpZVBzEbOrzgqYqdxFQ1GZ/WmIfgO1XDvI3V5p9dvpO2uiJ9omuidvoWD/HE21FMV5Ex7TVN8J3x
        0i/TXh4E5X1lpopZ8uoQiVdnqui7DT1eJzloekYUPeiN62rmpgc88XYUn3nJuKNN8EWsBMFMJWfe8TJLTh0i+ppwVokJQA4a3q
        hFD3qVKNjJwzHcLSdDqEzwRawEAZa5W8H8ebl5iHzXhNsOQQ6afkfRk3jITh6OYa6MjNky0wTf2VL69YtSGgNFi8yVMc+VmoeI
        tiaMH4Kae6XUjBw05FCreqR3Yw5mJnrAE29HMV9CxnyxaYLvQgmzlZJXh0jtmjB+iNZiyEFDDrXWfl0X94An3o7ifiEZi4WmyY
        KHDzws5oNWztfSC9AXi2hNauOHLBYx3y+iGHLQkEOt3QzMVj3iBU+8HcUDDxnglx4e3JWF9LswfsiDQpqR3hBySrPrsXDDT49w
        DksFZCwVMP9Xfl2w/c+OnF3tv3MfDvkmX35aHfT9xBL9uBu/qqbfpH7bIchBs+uxEl7w1K17x3IeHV4+xv7HefTzciv98OgePd
        mNjytoXfoeoW8LJQfNrsdKeClPs++wXid5rOTQ8MpRZlcpO+h1ksdKHh39NoeDbhI76HXSSCONpJCR8Q+zZUewfM/YwAAAAABJ
        RU5ErkJggg=="
    style={{ width: '63px', height: '63px' }}
  />
)

export const uploadCloud = (
  <img
    alt=""
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU
        1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAWnSURBVGhD7Zd7UJRVGMYtJ7uXTrcpb6mMygosN7m5sHFf3F2Qy7KysCws
        soB3TMU0RWdUTEvJ1AmdCTOpERUbQMVEvGVaOo79kWmOZjOljpajFo6kPW/vWc8YpG7LCupM+5t55pzved7zvef72P32o4sbN27cuH
        Hjxk1bXh+Nvr2LMKFPEWr6jMZ+Ho+wtvYtotn9iuAjyx5ePMbhJY8CVAwowHWPQiKen2XtG1CI3TyesHsszrf1G4WBctnDxaB8eHva
        cMozH3952mj14Hz4y+gWChv14byMs2bW74NHQSejh4MhOejtk4fzSit+Y0UKT1mAnl42muaVj099CvGyvVDibcUgHyuOKvPQ4jMKUd
        J+0NAj/jk4wGr2syJQOHwxU3ytuOpnJfLLxTn2X7OXtiI4D68E5OC0fy7OKi3UXdp2Amz0mDIbPQP5BvHH9XFpdy4h2UgPtRCFWjBe
        HAfloCw4hyjIgs2BVnjZi+5CWDYi5dr54oaEWZAaYkFDaDaab/r2DOwd5PFNvsCn5NKOJ8KMHeFZ+FlhoG4qM8WosolUZnzcpZQelS
        UOsa834wJrZ4SZSMzZqww30zSeT4zIwhI+/1GRsX8mgi9eLu04NJl4LtqEP1kfiONIE3ZGmXBG1447x/WF0ZlEUZm4xusnqy30hIza
        wHUJ0Zn4UfSLyUS6tDuGBBO8NRlEmpGwqg30TFwGbsRnYLGMnYJvhoLPcWm4CRHSugWf60jcSBzj886KzcLTugx6kb1vWc3DTeQry+
        4dfRpi9elEunTotSbqoUunBfp0DJOxUxgM1DXJiDB52AatkWbrjHSIRVojvtOmoacmGb30BlzWGdAky+4d3oQqNY0oJQ1GaXUK4vys
        5pRU2iUeCqmpmCn6Jiff/lvlEsYRGGhMITImo0RaDpn2NV6Y/CUahcRc2k5hTMEE0WtkMqJMKegr5ukpKJWxa9j4OZ+ViOCsJBphTs
        RVcxK+kZFDZu7Bxll7iOzajRppO4Ulibqbk4i43zxxzD3PsT6zh+3FZqDnc3W0IFePi1Y90T8CchOhkGV3pGwn5ZTtImoj9mTsFLk6
        FOfpobHP9TjK2mYP2kORlvrbtDhWoCWyDcdmmw65hVqEsx8gVBjb9jWkNUt3o1/5DlwpbyJqI/ZEJsvaBe/hYoEWn8hD55iSiGfHJO
        DYGA2u8NiuF73qaur6YSP2VjQS3UkiEzWy3CnGaaAYm0DE+3Hqu3mLifGYWxxPNCkeidJymjXb8NaaL4gcimtkuVMUx+EdsZ/xsY5f
        gdognvMlMbgwNbr9n8eNDVCub0DL+gYix0KLqJXL/pOSaGwsiSHisbFUfec3gduYribfGVFEM6JQIC2nqduKxLrNOF+3BRd5RP0Wot
        YSnszO126BXi5zihnRmCT2NT0KZdJyTKkamjlvEJVGIk5aLtFUh6lN9USttaMeU2TsErPVqJ+jxuVSnRPvdvNUUM+P4EelmgzScomv
        amHaX0vUWsKTsUvw3rLE3uaqESKtu1Omoh4Lh+HGIhWWScslDm+C6cjnRK0lPBm7xEIVNItURO+GI15ajikPRS3rj8XheFVa7eaHGg
        w4vgF7j2+gQzdln/eXsUssCcXb5WFE74XAQ1qOWRoE5bJgtCwPwoEV/BeS9gPl/SD0WhZMl5YH46C0nGPlUMqpGAqwTq4KhJHvwpMy
        uu+sCkBsRSBO8V5aVgY58f34N5V+0Hzkj58qA4gqA3CNdbrSHyfvp7j/r/b+/riwOhAJcmvtp1pB3ap8yVClxIm1Slxnbb8f4n77uS
        +t9cVhno+t8u6gj/g6H0yq9iFa541iaXUa4uZxvyrRj6WSdscgTl7jhX2bvIg2DcH3NUOwvdPkhV/sfbywQrbvWMTF1Hliar0CjTye
        7BQpcJzHOu6RQfxvrmztxo0bN27cuPkf0aXL3y4WxPw+TAocAAAAAElFTkSuQmCC"
  />
)

export const cursor = (
  <img
    alt=""
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAA
        Cxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAVgSURBVGhD7Zh9TNR1HMfpceVqbK2tWg2URwUPkAc55Xi8Bw44OG+KInCQIOd5KQio
        oTIx04x8mA91aKXkREWnZg1sYNnUNGG6Nntarc0W5j8t8/IP52LvT5/v8a11ZMvtfr/bsfHa3vv9Pu/v9/e932u7PzhCxhlnnMDyrAvhzy
        9FbtgyPCersUVcKT0a5sK+cBcQ7iKayAlfjF3ZbfSw3DI2iHBiQ6STiK87o5ywRC2i96J4jnSiWW4ZG8Q68FPsIpySI0MPxNbh0mQHvpFF
        8BPnoifi64g4r8vKS3wdOjjDcgx+UhwUmlRLlFiDTbLywrNb9HIMfoRI6gKi1Bd9RXh2i16OwY8Q0VYTaat8RbTVcM/gXo7Bj4FFdFVEul
        EimXa4M+1jSaSUQnMqiXIqfUVyKuDO5V6OwY8QMZQT6ct9RYzlcBu5l2PwI0TMZUT5Zb4iBWVwF3Avx+BHiFjmERXP9RWxzIW7eO4YEill
        EdscIttsXxGe3aKXY/AjREpnE80ZJcKzW/RyDH6EyHwbUZnNV4Rnt+jLZ6G13IaB+bMwyFnhcNAjvJY334ZTvPYVrx2ptGGqw0ITKqx4lf
        NZxSycKbeiVh4VGISI3UpUafUVsZfAXcW9NyX40m7F9+Ker4M8D9tL6BZfL1RZcYfzO+fbal6vLsEA5zt5v1kepz4OFqkpJqop9hWpscDt
        7S1oFzMLP8R79otugQU/LzTjBe++EqTWFuNO7che+z/2Hqy1YHhhMSaJTnUcBgrlrwXVFfmK1BVgoaOIjvKLPimrEN4XJrq6wpEX/gtHIb
        U4CnG4rY0elFWI0wyts4jIWYQyWamLEHEVEi0u8BXxl5cKoFlSQOQyY7Gs/GdJPoqW5uMA5/36fDS08U9bueQVqTcT8do9RRoKKYnXL48E
        PaKrN2FJA88i3Ld4u3x0i3lZvrfTNbII31ODSSGRRiNam0xEfL3ZZMJ1cd9kxJX6PDwj1l9mkWbumo33FllupCRevyzCz3lFmlnk7840Is
        LPdy/nWaTJwCJ6aPielishwi8ZttKAYc6JRi0eF90KEypWGHB3pRFfr2KZRhOe4n20Uo+N3ocUgs/TtIyc67/IGj3mrdYTtegxQ1ZeVuth
        ZYm7vH6N73vX8B6xVy4rQiuLtObxubkKiLTlIm9tLtHanH+/ZFs2zGtzcY1zm/dtEf9wkEuKsD4LmnU5ROuylRBJoQmvZOEHznU+OFrWAU
        GIbMgm2qiEiGBDJqa/lgXPxkwMBVJmE4u064g26RQSEbyhQ0a7Dp7NGRhqD5DMVi00WzOItsxUUESwTYuMbTPh4QxtT1dfZheL7JhBtCNd
        YRHBTpbhD/BwVJcRIm+lE72phoigIwUZHWnwuNMw1KGizO5kaPakEe1OU0lE8A7LvJ0Kz7spGNqnksw+FtmbSrQ3WUURQSfLdE6DpzOZZZ
        KUlxEi+5OJ9k+DU1bq0ZWIjK4keLqmYeigwjKHEzDvUBIRn1soK3U5nEg53YlE3YnK/Rl/JBHTj2jwI+dGZzg9Jmt1OZpCocc0RMc0uHNs
        Km7eT47fI9z/djyBbp1IwO0TfB53Hj4zS36M+giRk/FEJ+PxBWfP/+XD/8ghO250OYgOcE4m4PoHcRiUHxEYTkdQaE8cUe8U/75aB/pwta
        ufSKRHi8GPpuCqXAoMQqRvMlFfrH8ifT242t9L5E06BvtjAiwi+Dgav3wSgyunY2A+HU2G0fn0PjKwBr0D63FlYBUunYnGr5x+eXzgOBuF
        2rOR+ONsFNHonLuPnB+Vc5HwnI8gnTw+sHweThMvRsB2cRKV+pMLk2C5HENPy2PHGWccvwkJ+RNSOw0nbZNFZgAAAABJRU5ErkJggg=="
    style={{ width: '63px', height: '63px' }}
  />
)
