import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setHeaderCat, deleteHeaderCat } from 'Redux/actions/main'
import { changeJobTab } from 'Redux/actions/jobs'
import {
  loadIndustries,
  followIndustry,
  unfollowIndustry
} from 'Redux/actions/industries'

import {
  loadIndustriesMetaTags
} from 'Redux/actions/metaTags'

import {
  getIndustriesMetaTags
} from 'Redux/selectors/metaTags'

import {
  getIndustries,
  getIndustriesLoadInfo
} from 'Redux/selectors/industries'

import { isAuthenticate } from 'Redux/selectors/auth'
import { getFollowedIndustries } from 'Redux/selectors/user'

import Component from './Categories'

const selector = createStructuredSelector({
  industries: getIndustries,
  loadInfo: getIndustriesLoadInfo,
  isAuthenticate,
  userFollowedIndustries: getFollowedIndustries,
  industriesMetaTags: getIndustriesMetaTags
})

const actions = {
  onSetHeaderCat: setHeaderCat,
  onDeleteHeaderCat: deleteHeaderCat,
  onIndustriesLoad: loadIndustries,
  onFollowIndustry: followIndustry,
  onUnfollowIndustry: unfollowIndustry,
  onChangeJobTab: changeJobTab,
  onLoadIndustriesMetaTags: loadIndustriesMetaTags
}

export default connect(
  selector,
  actions
)(Component)
