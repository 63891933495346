import styled from 'styled-components'

import * as colors from '../../../../../Theme/colors'

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  font-family: Nunito;
`

export const Card = styled.div`
  display: flex;
  position: relative;
`

export const CardImageContainer = styled.div`
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  border: solid 1px #757575;
  margin: 15px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CardImageIconDefault = styled.i.attrs({
  className: 'fas fa-building'
})`
  font-size: 25px;
`

export const CardImageIcon = styled.img``

export const CardsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0 0 0;
`
export const CardTime = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: ${colors.chatPurple};
  padding: 0 0 5px 0;
  border-bottom: solid 2px ${colors.chatPurple};
  width: max-content;
`

export const CardTitle = styled.h3`
  font-weight: normal;
  margin: 0 0 5px 0;
  font-size: 25px;
  text-transform: uppercase;
  color: ${colors.careerBlue};
`

export const CardContent = styled.div`
  padding: 0 0 30px 0;
  border-bottom: solid 1px silver;
  margin: 0 0 10px 20px;
  width: 100%;
  cursor: pointer;
  transition: border-bottom-color 0.3s;
  &:hover {
    border-bottom-color: ${colors.careerBlue};
  }
`

export const CardAddress = styled.p`
  font-size: 18px;
  color: #757575;
`

export const CardDesctiptionList = styled.div``
export const CardDesctiptionItem = styled.p`
  margin: 0;
  font-size: 15px;
  color: #757575;
  display: flex;
  align-items: center;
`
export const ListItemCircle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #757575;
  margin: 0 10px 0 5px;
`
export const CardDesctiption = styled.div``
export const CardDeleteIcon = styled.i.attrs({ className: 'fas fa-trash' })`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #757575;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${colors.careerBlue};
  }
`
