import { imagesDinamicDir } from 'Config/App'

const getDinamicLink = (path, filename) => {
  return `${imagesDinamicDir.url}/${path}/${filename}`
}

export const getIndustryImageLink = filename => {
  return getDinamicLink('industries', filename)
}

export const getVacancyImage = filename => {
  return getDinamicLink('job-post-images', filename)
}

export const getPostFiles = filename => {
  return getDinamicLink('job-post-files', filename)
}
