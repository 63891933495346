import { createSelector } from 'reselect'

const subExpertisesData = store => store.subExpertises

export const getPredSubExpertises = createSelector(
  subExpertisesData,
  data => data.subExpertises
)

export const getSubExpertisesLoadInfo = createSelector(
  subExpertisesData,
  data => data.loadInfo
)
