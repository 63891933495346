import React from 'react'
import PropTypes from 'prop-types'

import { List, Text, Icon, Item } from './styled'

const Autocompleate = ({ items, faIcon, onSelect, isOpen }) => {
  const renderItems = items.slice(0, 5)
  return (
    <List idOpen={isOpen}>
      {renderItems.map(item => (
        <Item onClick={() => onSelect({ value: item.name, id: item.id })}>
          <Icon className={faIcon} />
          <Text key={item.id}>{item.name}</Text>
        </Item>
      ))}
    </List>
  )
}

Autocompleate.propTypes = {
  faIcon: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.array,
  onSelect: PropTypes.func.isRequired
}

Autocompleate.defaultProps = {
  faIcon: 'far fa-check-circle'
}

export { Autocompleate }
