import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Header,
  Icon,
  DefaultIcon,
  Title,
  Line,
  Content
} from './style'

const CareerTimeLineBlock = ({ icon, type, title, children }) => {
  return (
    <Container>
      <Header>
        {icon ? <Icon icon={icon} /> : <DefaultIcon />}
        <Title>{title}</Title>
        <Line />
      </Header>
      <Content>{children}</Content>
    </Container>
  )
}

CareerTimeLineBlock.propTypes = {
  children: PropTypes.object.isRequired,
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  type: PropTypes.string
}

export { CareerTimeLineBlock }
