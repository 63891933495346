import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { CardTime } from './styled'

const CardTimeComponent = ({ startDate, endDate, currently }) => {
  return (
    <CardTime>{`${moment(startDate).year()} TO ${currently ? 'Present' : moment(endDate).year()}`}</CardTime>
  )
}

CardTimeComponent.propTypes = {
  endDate: PropTypes.string,
  startDate: PropTypes.string
}

export { CardTimeComponent }
