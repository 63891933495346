import { put } from 'redux-saga/effects'

import { fetchUser } from 'Redux/actions/user'
import { loadSavedVacancies, loadUserJobs } from 'Redux/actions/jobs'
import { loadNotifications } from 'Redux/actions/notifications'

function* onRehydrateToken(action) {
  try {
    yield put(fetchUser())
    yield put(loadNotifications())
    yield put(loadSavedVacancies())
    // yield put(loadUserJobs())
  } catch (e) {
    console.error(e)
  }
}

export default onRehydrateToken
