import React from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'

import { Container } from './styled'

const CardsList = ({ items, onChangeItem, onDelete }) => {
  return (
    <Container>
      {items.map(item => (
        <Card {...item} onChangeItem={onChangeItem} onDelete={onDelete} />
      ))}
    </Container>
  )
}

CardsList.propTypes = {
  items: PropTypes.array.isRequired,
  onChangeItem: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export { CardsList }
