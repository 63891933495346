import React, { PureComponent } from 'react'
import {
  Container,
  CloseButton,
  Content,
  Steps,
  Step,
  ContainerWithScrollbar
} from './styles'

export default class Window extends PureComponent {
  state = {
    isMobileView: false
  }
  componentDidMount() {
    window.addEventListener('resize', this.reRender)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.reRender)
  }
  reRender = () => {
    const { clientHeight, clientWidth } = document.documentElement
    if (clientWidth <= 499 || clientHeight < 650) {
      this.setState(prevState => {
        if (!prevState.isMobileView) {
          return {
            isMobileView: true
          }
        }
      })
    } else if (clientWidth >= 500 || clientHeight > 650) {
      this.setState(prevState => {
        if (prevState.isMobileView) {
          return {
            isMobileView: false
          }
        }
      })
    }
  }
  renderSteps = () => {
    const { step } = this.props
    if (step) {
      const steps = []
      for (let i = 0; i < 4; i++) {
        steps.push(<Step key={i} active={i < step} />)
      }
      return steps
    }
  }

  render() {
    const {onClose} = this.props
    const { clientWidth, clientHeight } = document.documentElement
    if (clientWidth > 500 && clientHeight > 650) {
      return (
        <Container height={this.props.height}>
          <CloseButton height={this.props.height} onClick={() => {console.log('here----------'); onClose()}}>
            <i className="fas fa-times" />
          </CloseButton>
          <Steps visible={this.props.step}>{this.renderSteps()}</Steps>
          <Content>{this.props.children}</Content>
        </Container>
      )
    } else {
      return (
        <ContainerWithScrollbar>
          <CloseButton height={this.props.height} onClick={onClose}>
            <i className="fas fa-times" />
          </CloseButton>
          <Steps visible={this.props.step}>{this.renderSteps()}</Steps>
          <Content>{this.props.children}</Content>
        </ContainerWithScrollbar>
      )
    }
  }
}
