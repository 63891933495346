import Component from './MyProfessionalQualifications'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  loadProfQualf,
  editProfQualf,
  changeDataProfessionalQualifications,
  removeProfQualf
} from 'Redux/actions/professionalQualifications'

import { getUserProfQualf } from 'Redux/selectors/user'
import { getPredProfQualf } from 'Redux/selectors/professionalQualifications'

const actions = {
  onLoadProfQualifList: loadProfQualf,
  onAddProfQualif: props => editProfQualf({ toAdd: { ...props } }),
  onRemoveProfQulif: removeProfQualf,
  onEditProfQualif: changeDataProfessionalQualifications
}

const selector = createStructuredSelector({
  profQualifList: getPredProfQualf,
  userProfQualif: getUserProfQualf
})

export const MyProfessionalQualifications = connect(
  selector,
  actions
)(Component)
