import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_EXPECTATIONS = createAsyncAction('expectations/LOAD')
export const loadExpectations = text => {
  return apiCall({
    endpoint: '/expectations',
    types: LOAD_EXPECTATIONS,
    query: {
      text
    }
  })
}

export const resetExpectations = () => ({
  type: LOAD_EXPECTATIONS.SUCCESS,
  payload: {
    data: []
  }
})
export const ADD_EXPECTATIONS = createAsyncAction('user/expectations/ADD')
export const addExpectation = name => {
  return apiCall({
    endpoint: '/candidates/expectations',
    types: ADD_EXPECTATIONS,
    method: 'POST',
    query: {
      name
    }
  })
}
export const REMOVE_EXPECTATION = createAsyncAction('user/expectations/REMOVE')
export const removeExpectation = id => {
  return apiCall({
    endpoint: '/expectations',
    types: REMOVE_EXPECTATION,
    method: 'DELETE',
    query: {
      id: [id]
    }
  })
}
export const EDIT_EXPECTATIONS = createAsyncAction('expectations/EDIT')
export const editExpectations = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/expectations',
    types: EDIT_EXPECTATIONS,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}
