import { createSelector } from 'reselect'

const authData = store => store.auth

export const isAuthenticate = createSelector(
  authData,
  data => data.isAuthenticate
)

export const isAuthLoading = createSelector(authData, data => data.isLoading)

export const getToken = createSelector(authData, data => data.token)
export const isLoaded = createSelector(authData, data => data.isLoaded)

export default {}
