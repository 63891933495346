import styled, { keyframes } from 'styled-components'
import * as colors from '../../../../../Theme/colors'

export const animLoad = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)
  }
`
export const starStyle = {
  fontSize: '30px'
}
export const Loader = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1.5px solid ${colors.stepGreen};
  border-bottom: 1.5px solid white;
  border-left: 1.5px solid white;
  animation: 2s infinite ${animLoad} ease-in-out;
  margin: 0 5px;
`
export const Container = styled.div`
  display: flex;
  background-color: white;
  border-radius: 16px;
  flex-direction: column;
  height: 100%;
`
export const Header = styled.div`
  display: flex;
  margin: 15px 27px 0;
  align-items: center;
`
export const Title = styled.div`
  font-size: 14px;
  color: ${colors.mainBlue};
  flex: 1;
  text-transform: uppercase;
  font-family: 'Nunito';
`
export const RateContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 27px;
  justify-content: space-between;
`
export const ButtonsContainer = styled.div`
  display: flex;
`
export const IconButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 50%;
  border: 1px solid ${colors.mainBlue};
  color: ${colors.mainBlue};
  margin: 0 5px;
`
export const Input = styled.textarea`
  border: solid 1px
    ${p => (p.error ? colors.notificationRed : colors.selectedColor)};
  color: ${colors.lightGreyFont};
  background: transparent;
  font-size: 14px;
  flex: 1;
  padding: 10px;
  outline: none;
  margin: 20px 27px;
  resize: none;
`
export const Button = styled.div`
  background-color: ${colors.buttonGreen};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  font-size: 18px;
  align-self: center;
  padding: 3px 0;
  border-radius: 15px;
  margin: 10px 0;
  cursor: pointer;
`
export const Icon = styled.div`
  font-size: 20px;
  margin: 0 5px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`
