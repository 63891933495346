import cookie from 'react-cookies'

import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'
import Cookies from 'universal-cookie';

export const LOG_IN = createAsyncAction('auth/LOG_IN')
export const logIn = authData =>
  apiCall({
    endpoint: '/login',
    method: 'POST',
    types: LOG_IN,
    query: {
      email: authData.email,
      password: authData.password
    },
    meta: {
      remember: authData.remember
    }
  })

export const LOG_OUT = createAsyncAction('auth/LOG_OUT')
export const logOut = () => {
  const cookies = new Cookies();
  // localStorage.removeItem('user_token')
  cookies.remove("user_token", {path: "/"})
  sessionStorage.clear()
  cookie.remove('session.sig')

  return apiCall({
    endpoint: '/logout',
    method: 'POST',
    types: LOG_OUT
  })
}

export const SET_USER_ID = 'auth/SET_USER_ID'
export const setUserId = userId => ({
  type: SET_USER_ID,
  payload: {
    data: {
      userId
    }
  }
})

export const REGISTER = createAsyncAction('auth/REGISTER')
export const register = (data, callback) => {
  return apiCall({
    endpoint: '/registration',
    method: 'POST',
    types: REGISTER,
    query: data,
    callback: callback
  })
}

export const REHYDRATE_TOKEN = createAsyncAction('auth/REHYDRATE_TOKEN')
export const rehydrateToken = () => {
  const cookies = new Cookies();

  const sessionStorageId = cookies.get('user_token')
  const localStorageId = cookies.get('user_token')
  //  const sessionStorageId = sessionStorage.getItem('user_token')
  // const localStorageId = localStorage.getItem('user_token')
  const token = sessionStorageId ? sessionStorageId : localStorageId

  if (typeof token !== 'undefined' && token !== null) {
    return {
      type: REHYDRATE_TOKEN.SUCCESS,
      payload: {
        data: token
      }
    }
  } else {
    return {
      type: REHYDRATE_TOKEN.FAILURE,
      payload: {
        data: null
      }
    }
  }
}

export const setUserData = data => {
  return {
    type: REGISTER.SUCCESS,
    payload: {
      data
    }
  }
}
