import styled from 'styled-components'
import * as colors from 'Theme/colors'

export const Container = styled.div`
  display: flex;
  background-color: white;
  border-radius: 16px;
  flex-direction: column;
  height: 100%;
`
export const Header = styled.div`
  display: flex;
  margin: 15px 27px;
  align-items: center;
`
export const Title = styled.div`
  font-size: 14px;
  color: ${colors.mainBlue};
  flex: 1;
  text-transform: uppercase;
  font-family: 'Nunito';
`
export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  color: ${colors.stepGrey};
  border: 1px solid ${colors.stepGrey};
  border-radius: 15px;
`
export const TextBoxContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.selectedColor};
  align-items: center;
  border-radius: 15px;
  height: 40px;
  margin: 0 27px;
  color: ${colors.lightGreyFont};
`
export const Icon = styled.div`
  margin: 10px;
  font-size: 20px;
`
export const Input = styled.input`
  outline: none;
  background: transparent;
  font-size: 14px;
  flex: 1;
  border: none;
`
export const ListTitle = styled.div`
  color: ${colors.lightGreyFont};
  margin: 15px 27px;
  font-size: 14px;
  text-transform: capitalize;
`
export const ListContainer = styled.div`
  height: 40vh;
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 10px;
  }
  overflow: auto;
  &::-webkit-scrollbar {
    background-color: #dbd9d9;
    width: 5px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
`
export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 20px;
  font-size: 14px;
  color: ${colors.lightGreyFont};
  &::-webkit-scrollbar {
    background-color: #dbd9d9;
    width: 5px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
`
export const ListItem = styled.div`
  padding: 3px 8px;
  border-radius: 20px;
  border: 1px solid
    ${props => (props.selected ? colors.selectedColor : colors.selectedColor)};
  color: ${props => (props.selected ? 'white' : colors.lightGreyFont)};
  background-color: ${props =>
    props.selected ? colors.selectedColor : 'transparent'};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px;
`
export const ItemTitle = styled.div`
  flex: 1;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ItemIcon = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 0 3px;
`
export const Button = styled.div`
  background-color: ${colors.stepGreen};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  font-size: 24px;
  align-self: center;
  padding: 3px 0;
  border-radius: 15px;
  margin: 10px 0;
`
export const IconButton = styled.div`
  margin: 0 10px;
`
