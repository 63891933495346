import { createSelector } from "reselect";

const expectationsData = store => store.expectations;

export const getExpectationsPredictions = createSelector(
  expectationsData,
  data => data.expectations
);

export const getExpectationsPredictionsLoadInfo = createSelector(
  expectationsData,
  data => data.loadInfo
);
