import styled from 'styled-components'
import { notificationRed, buttonGreen } from '../../../Theme/colors'

export const percentStyles = {
  root: {
    width: '80px',
    height: '80px'
  },
  path: {
    stroke: '#583ea1'
  },
  text: {
    fill: '#757575',
    fontSize: '25px'
  }
}
export const Modal = styled.div`
  position: absolute;
  bottom: -310px;
  height: 300px;
  width: 250px;
  right: -40px;
  z-index: 5;
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
`
export const InputContainer = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  height: 30px;
  border-radius: 30px;
  margin-bottom: 10px;
`
export const Input = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
`
export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 300px;
  max-height: 440px;
  border-radius: 20px;
  font-family: 'Nunito';
  box-shadow: 0 0 15px 1px rgba(61, 61, 61, 0.42);
  margin: 10px;
  @media screen and (max-width: 1000px) {
    margin: 8px auto;
  }
`
export const TopContent = styled.div`
  display: flex;
  justify-content: space-around;
  background: #fafafa;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: 'Nunito';
  height: 150px;
`
export const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  font-family: 'Nunito';
`
export const BottomContent = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  font-family: 'Nunito';
`
export const TitleCompany = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`
export const Title = styled.div`
  font-family: 'Nunito';
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 10px;
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
`
export const Company = styled.p`
  font-size: 18px;
  color: #757575;
  margin-bottom: 0;
`
export const Icons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
`
export const Icon = styled.div`
  text-align: center;
  font-size: 15px;
  max-width: 65px;
`
export const IconText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #757575;
`
export const Interest = styled.button`
  width: 135px;
  height: 30px;
  border-radius: 10px;
  color: ${buttonGreen};
  background: #fff;
  font-size: 15px;
  outline: none;
  border: solid 1px ${buttonGreen};
  transition: all 0.3s;
  :hover {
    background: ${buttonGreen};
    color: #fff;
  }
`
export const NoInterest = Interest.extend`
  background: white;
  color: #757575;
  border: 1px solid #757575;
  outline: none;
  :hover {
    background: #757575;
    color: white;
    border: none;
  }
`
export const Decline = styled.button`
  width: ${props => props.width || '100px'};
  height: 30px;
  color: ${notificationRed};
  background: #fff;
  border-radius: 10px;
  border: 1px solid ${notificationRed};
  font-size: 15px;
  outline: none;
  transition: all 0.3s;
  :hover {
    color: #fff;
    background: ${notificationRed};
  }
`

export const ApplyBtn = styled.button`
  width: ${props => props.width || '100px'};
  height: 30px;
  color: ${buttonGreen};
  background: #fff;
  border-radius: 10px;
  font-size: 15px;
  outline: none;
  border: 1px solid ${buttonGreen};
  transition: all 0.3s;
  :hover {
    color: #fff;
    background: ${buttonGreen};
  }
`

export const FriedsSupport = styled.button`
  width: 160px;
  height: 30px;
  color: #583ea1;
  border: 1px solid #583ea1;
  background: white;
  border-radius: 10px;
  font-size: 15px;
  outline: none;
  transition: all 0.3s;
  :hover {
    background: #583ea1;
    color: white;
  }
`
export const FindJobs = styled.button`
  width: 260px;
  height: 30px;
  color: #00b7fe;
  font-size: 15px;
  background: #fff;
  border-radius: 10px;
  outline: none;
  border: 1px solid #00b7fe;
  transition: all 0.3s;
  :hover {
    color: #fff;
    background: #00b7fe;
  }
`
export const Logo = styled.img`
  min-width: 150px;
  height: 130px;
  margin-top: 10px;
  margin-left: 8px;
  border-radius: 5px;
`
export const PercentConteiner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const PercentText = styled.p`
  margin-top: 10px;
  font-size: 13px;
  color: #757575;
`
export const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 15px;
`
export const TimeIcon = styled.i.attrs({ className: 'far fa-clock' })`
  margin-right: 5px;
`
export const ChangeTimeBlock = styled.p``
