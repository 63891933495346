import { createSelector } from 'reselect'

const userData = store => store.user

export const getUser = createSelector(
  userData,
  data => data.user
)

export const getUserEducation = createSelector(
  userData,
  data => data.education
)

export const getUserExperience = createSelector(
  userData,
  data => data.experience
)

export const getFollowedIndustries = createSelector(
  userData,
  data => data.followedIndustries
)
export const getUserAvatar = createSelector(
  getUser,
  data => data.avatar
)

export const getPersonalities = createSelector(
  userData,
  data => data.personalities
)

export const getExpectations = createSelector(
  userData,
  data => data.expectations
)
export const isAuthenticate = createSelector(
  getUser,
  data => !!data.id
)
export const getToken = createSelector(
  getUser,
  data => data.token
)
export const getUserAge = createSelector(
  getUser,
  data => data.dob
)
export const getUserName = createSelector(
  getUser,
  data => data.name
)
export const getUserInfo = createSelector(
  getUser,
  data => data.info
)
export const getUserAddress = createSelector(
  getUser,
  data => data.address
)
export const getUserLinkedin = createSelector(
  getUser,
  data => data.linkedin
)
export const getUserEmail = createSelector(
  getUser,
  data => data.email
)
export const getUserStatus = createSelector(
  getUser,
  data => data.status
)
export const getUserNotificationData = createSelector(
  getUser,
  data => data.notification_data
)
export const getUserHighSchoolEd = createSelector(
  userData,
  data => data.highSchoolEd
)
export const getUserAcademicQualifications = createSelector(
  userData,
  data => data.academicQualifications
)
export const getUserProfQualf = createSelector(
  userData,
  data => data.profQualf
)
export const getUserExpertises = createSelector(
  userData,
  data => data.expertises
)
export const getUserSubExpertises = createSelector(
  userData,
  data => data.subExpertises
)

export const getUserSavedVacancies = createSelector(
  userData,
  data => data.savedVacancies
)
export const getUserNotifications = createSelector(
  userData,
  data => data.notifications
)
export const getUserJobTypes = createSelector(
  userData,
  data => data.jobTypes
)
export const getUserCompanies = createSelector(
  userData,
  data => data.companies
)
export const getSupportEmails = createSelector(
  userData,
  data => data.supportEmails.supportEmails
)
export const getSavedEmails = createSelector(
  userData,
  data => data.savedEmails
)
export const getSuppEmLoadInf = createSelector(
  userData,
  data => data.supportEmails.loadInfo
)
export const getProfLoadInfo = createSelector(
  userData,
  data => data.profLoadInfo
)
export const getFetchUserLoadInfo = createSelector(
  userData,
  data => data.fetchUserLoadInfo
)
export const getUserLanguages = createSelector(
  userData,
  data => data.languages
)
export const getUserFeedbacks = createSelector(
  userData,
  data => data.feedbacks
)

export const getUserActivities = createSelector(
  userData,
  data => data.activities
)
export const getUserReferee = createSelector(
  userData,
  data => data.referee
)

export const getUserconfirmedData = createSelector(
  userData,
  data => data.confirmedData
)
