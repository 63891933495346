import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD = createAsyncAction('companies/LOAD')
export const loadCompanies = text => {
  return apiCall({
    endpoint: '/companies',
    types: LOAD,
    query: {
      text
    }
  })
}
export const REMOVE_COMPANY = createAsyncAction('companies/REMOVE')
export const removeCompany = id => {
  return apiCall({
    endpoint: '/companies',
    types: REMOVE_COMPANY,
    method: 'DELETE',
    query: {
      id: [id]
    }
  })
}
export const EDIT_COMPANIES = createAsyncAction('companies/EDIT')
export const editCompanies = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/companies',
    types: EDIT_COMPANIES,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}
