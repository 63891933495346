import Component from './MyEduction'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getUserEducation } from 'Redux/selectors/user'
import {
  addEductions,
  removeEductions,
  editEductions
} from 'Redux/actions/eductions'

const actions = {
  onEditEducations: editEductions,
  onAddEducations: addEductions,
  onRemoveEducations: removeEductions
}
const selector = createStructuredSelector({
  education: getUserEducation
})

export const MyEduction = connect(
  selector,
  actions
)(Component)
