import React from "react";
import moment from "moment";

import { Container, FollowBtn, Follow, RatingLevelContainer } from "./styled";
import {
  StarIcon,
  RatingText,
} from "../../../Components/Pages/CareerTimeLine/innerBlocks/blocks";

export const FilterPreview = ({
  isAuthenticate,
  jobCategory,
  cities,
  distanceFromHome,
  jobTypes,
  companies,
  salary,
  options,
  expertises,
  dateSince,
  dateOption,
  onFollowIndustry,
  followedIndustries,
  onIndustryUnfollow,
}) => {
  const jobCategoryTitle = jobCategory.title === "All" ? "" : jobCategory.title;
  const citiesName = cities.map((item) => item.name).join(", ");
  // const citiesName = cities.map((item) => item.name).join(", ");
  const jobTypesName = jobTypes.map((item) => item.name).join(", ");
  const companiesName = companies.map((item) => item.text).join(", ");
  const optionsName = options.map((item) => item.name).join(", ");
  const expertisesName = expertises.map((item) => item.name).join(", ");
  const dateSinceRender = moment(dateSince).format("LL");
  let salaryOption = false;
  if (salary.negotiable && salary.withoutRange) {
    salaryOption = "negotiable, without range";
  } else if (salary.negotiable) {
    salaryOption = "negotiable";
  } else if (salary.withoutRange) {
    salaryOption = "without range";
  }
  const isSalaryOptionRender = salary.negotiable || salary.withoutRange;
  const salaryRangeMin = salary.range && salary.range.min;
  const salaryRangeMax = salary.range && salary.range.max;
  
  // followButton = (jobCategory, e) => {
  //   if (!proff.isFollowed) {
  //     this.props.onFollowIndustry(jobCategory);
  //   } else {
  //     this.props.onUnfollowIndustry(jobCategory);
  //   }
  // };
  const follow = () => {
    console.log("hello");
    onFollowIndustry(jobCategory);
  };
  console.log("this.props", jobCategory);
  return (
    <Container>
      <div>
        <FollowBtn
          // onClick={(e) => {
          //   follow();
          // }}
          disabled
          follow={false}
          id={1}
        >
          Following
        </FollowBtn>
      </div>
      {jobCategoryTitle && (
        <RatingLevelContainer>{`${jobCategoryTitle}`}</RatingLevelContainer>
      )}
      {jobTypesName && (
        <RatingLevelContainer>
          {`
        ${jobTypesName && `${jobTypesName} jobs`}
     `}
        </RatingLevelContainer>
      )}
      {citiesName && (
        <RatingLevelContainer>
          {`
${citiesName && ` in ${citiesName}`}
     `}
        </RatingLevelContainer>
      )}
      {distanceFromHome && (
        <RatingLevelContainer>
          {`
${distanceFromHome ? `distance from home: ${distanceFromHome}km` : ""} 
     `}
        </RatingLevelContainer>
      )}
      {companiesName && (
        <RatingLevelContainer>
          {`
        ${companiesName && `companies: ${companiesName}`}
        `}
        </RatingLevelContainer>
      )}
      {optionsName && (
        <RatingLevelContainer>
          {`
${optionsName && `options: ${optionsName}`}
`}
        </RatingLevelContainer>
      )}
      {expertisesName && (
        <RatingLevelContainer>
          {`
       ${expertisesName && `expertises: ${expertisesName}`}
        `}
        </RatingLevelContainer>
      )}
      {salary.range && (
        <RatingLevelContainer>
          {`
 ${
   salary.range
     ? `salary min: ${salaryRangeMin} and max: ${salaryRangeMax}`
     : ""
 }
${isSalaryOptionRender ? `and salary option: ${salaryOption}` : ""}

  `}
        </RatingLevelContainer>
      )}
      {dateOption && (
        <RatingLevelContainer>
          {`
       
       ${
         dateOption
           ? dateOption === "since"
             ? `time: ${dateSinceRender}`
             : `past ${dateOption}`
           : ``
       }`}
        </RatingLevelContainer>
      )}

      {/* {`${jobCategoryTitle} ${jobTypesName &&
        `${jobTypesName} jobs`} ${citiesName && ` in ${citiesName}`} ${
        distanceFromHome ? `distance from home: ${distanceFromHome}km` : ""
      } ${companiesName && `and companies: ${companiesName}`} ${optionsName &&
        `and options: ${optionsName}`} ${expertisesName &&
        `and expertises: ${expertisesName}`}
        ${
          salary.range
            ? `salary min: ${salaryRangeMin} and max: ${salaryRangeMax}`
            : ""
        }
        ${isSalaryOptionRender ? `and salary option: ${salaryOption}` : ""}
        ${
          dateOption
            ? dateOption === "since"
              ? `time: ${dateSinceRender}`
              : `past ${dateOption}`
            : ``
        }`} */}
    </Container>
  );
};
