import React, { PureComponent } from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import { CareerTimeLineBlock, UserEditInfoModal } from "../../../../Blocks";
import {
  InterestsBlock,
  SkillBlock,
  UserContactBlock,
  UserInfo,
  UserLanguagesBlock,
} from "./blocks";

import InterestsModal from "./blocks/InterestsModal";
import SkillsModal from "../SkillsModal";
//style google api
import {
  EditableField,
  IconContainer,
  Item,
  Items,
  LocationIcon,
  LocationIconContainer,
  PrimaryText,
} from "../UserInfo/style";

import {
  ButtonContainer,
  ChangeModalInput,
  ChangeModalTextarea,
  ContactContainer,
  Container,
  Description,
  DescriptionWorlds,
  SkillContainer,
  Title,
  UserInfoContainer,
  DescriptionBlock,
  Icon,
} from "./styles";
import { AddButton } from "../blocks/AddButton";
import * as colors from "../../../../../Theme/colors";

export default class WhoAmI extends PureComponent {
  constructor(props) {
    super();

    const state = {
      isModalOpen: false,
      changeModalType: null,
      phone: "",
      address: "",
      lat: null,
      lng: null,
      quality: "",
      university: "",
      experience: "",
      award: "",
      description: "",
      interests: "",
      addressValue: "",
    };

    if (props.user.phone) state.phone = props.user.phone;
    if (props.user.location) {
      state.address = props.user.location;
      state.addressValue = props.user.location;
      state.lng = props.user.lon;
      state.lat = props.user.lat;
    }
    if (props.user.quality_info) state.quality = props.user.quality_info;
    if (props.user.info) state.description = props.user.info;
    if (props.user.experience_info)
      state.experience = props.user.experience_info;
    if (props.user.award_info) state.award = props.user.award_info;
    if (props.user.university_info)
      state.university = props.user.university_info;
    if (props.user.interests_info) state.interests = props.user.interests_info;

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        phone: this.props.user.phone,
        address: this.props.user.location,
        lat: this.props.user.lat,
        lng: this.props.user.lon,
        quality: this.props.user.quality_info,
        experience: this.props.user.experience_info,
        award: this.props.user.award_info,
        university: this.props.user.university_info,
        description: this.props.user.info,
        addressValue: this.props.user.location,
      });
    }
  }

  handleStarClick = (nextValue, prevValue, id) => {
    const { onEditLanguages, user } = this.props;

    onEditLanguages({ lanId: id, userId: user.id, value: nextValue });
  };

  handleChangeStateByName = ({ target }) => {
    const { value, name } = target;
    if (name === "description" && !value) {
      // document.querySelectorAll('textarea.sc-ekQYnd')[0].style.borderColor =  'red'
    } else if (name === "description" && value) {
      // document.querySelectorAll('textarea.sc-ekQYnd')[0].style.borderColor =  '#583ea1'
    }
    this.setState({
      [name]: name === "phone" ? value.replace(/[^-0-9]/gim, "") : value,
    });
  };

  handleLocationTextChange = (address) =>
    this.setState({ addressValue: address });

  handleLocationSelect = async (address) => {
    const { location: prevLocation } = this.state;
    if (prevLocation !== address) {
      const geocode = await geocodeByAddress(address);
      const latLng = await getLatLng(geocode[0]);
      const { lat, lng } = latLng;
      this.setState({ addressValue: address, address, lat, lng });
    }
  };

  handleOpenChangeModal = (type) => {
    this.setState({
      isModalOpen: true,
      changeModalType: type,
    });
  };

  handleCloseChangeModal = () => {
    // console.log("handle close here !!!");
    this.setState({
      isModalOpen: false,
      changeModalType: null,
      phone: this.props.user.phone,
      address: this.props.user.location,
      lat: this.props.user.lat,
      lng: this.props.user.lon,
      quality: this.props.user.quality_info,
      experience: this.props.user.experience_info,
      award: this.props.user.award_info,
      university: this.props.user.university_info,
      description: this.props.user.info || "",
      addressValue: this.props.user.location,
    });
  };

  handleSaveClick = ({ type, toAdd, toDelete }) => {
    const { onUpdateCandidate, user } = this.props;
    switch (type) {
      case "phone":
        const { phone } = this.state;
        if (phone) {
          onUpdateCandidate({ phone: phone });
        }
        if (!phone) {
          toast.error(`Contact Number can't be empty`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }

        break;
      case "address":
        const { address, lan, lng } = this.state;
        onUpdateCandidate({ location: address, lan, lon: lng });
        if (!address) {
          toast.error(`Address can't be empty`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        break;
      case "quality":
        const { quality } = this.state;
        onUpdateCandidate({ quality });
        break;
      case "experience":
        const { experience } = this.state;
        onUpdateCandidate({ experience });
        break;
      case "award":
        const { award } = this.state;
        onUpdateCandidate({ award });
        break;
      case "university":
        const { university } = this.state;
        onUpdateCandidate({ university });
        break;
      case "description":
        const { description } = this.state;
        if (isEmpty(description.split(" ").join("")) && !isEmpty(user.info)) {
          toast.error(`Field can't be empty`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          onUpdateCandidate({ info: description });
        }
        break;
      case "personality":
        const { onEditPersonalities } = this.props;
        onEditPersonalities({ toAdd, toDelete });
        break;
      case "languages":
        const { onUpdateLanguages } = this.props;
        onUpdateLanguages({ toAdd, toDelete });
        break;
      case "expectation":
        const { onUpdateUserExpectations } = this.props;
        onUpdateUserExpectations({ toAdd, toDelete });
        break;
      case "companies":
        const { onEditCompanies } = this.props;
        onEditCompanies({ toAdd, toDelete });
        break;
      case "jobTypes":
        const { onEditJobTypes } = this.props;
        onEditJobTypes({ toAdd, toDelete });
        break;
      default:
        console.warn("save type is not valid! type: ", type);
        break;
    }

    if (
      (!this.state.phone && type === "phone") ||
      (!this.state.description && type === "description")
    )
      return;
    this.handleCloseChangeModal();
  };

  handleSaveInterests = (interests) => {
    const { onUpdateCandidate } = this.props;
    onUpdateCandidate({ interests });

    this.handleCloseChangeModal();
  };

  handleDeleteSkill = ({ type, id }) => {
    switch (type) {
      case "expectation":
        const { onRemoveExpectation } = this.props;
        onRemoveExpectation(id);
        break;
      case "personality":
        const { onRemovePersonality } = this.props;
        onRemovePersonality(id);
        break;
      case "jobTypes":
        const { onRemoveJobType } = this.props;
        onRemoveJobType(id);
        break;
      case "companies":
        const { onRemoveCompany } = this.props;
        onRemoveCompany(id);
        break;
      case "interests":
        const { onUpdateCandidate, user } = this.props;
        const delArr = user.interests_info.split(",").filter((el) => el !== id);
        const delString = delArr.join(",");
        onUpdateCandidate({ interests: delString });
        break;
      default:
        console.warn("delete skill type is not valid! type: ", type);
    }
  };

  renderChangeModal = () => {
    const { isModalOpen, changeModalType } = this.state;

    if (isModalOpen) {
      switch (changeModalType) {
        case "phone":
          const { phone } = this.state;
          const { user } = this.props;
          return (
            <UserEditInfoModal
              title={"Edit Phone"}
              type={"phone"}
              width={400}
              onCloseModal={this.handleCloseChangeModal}
              onSaveClick={() => this.handleSaveClick({ type: "phone" })}
              isWhoAmI
              dataState={phone}
              dataProps={user.phone}
              changeItemId
            >
              {/*<ChangeModalInput*/}
              <input
                style={{
                  outline: "none",
                  border: phone ? "solid 1px #583ea1" : "solid 1px red",
                  borderRadius: 10,
                  width: "100%",
                  padding: "5px 0 5px 20px",
                  margin: "10px 0 20px",
                }}
                ref={(input) => input && input.focus()}
                name={"phone"}
                value={phone}
                onChange={this.handleChangeStateByName}
                placeholder="0094 71 234 5678"
              />
            </UserEditInfoModal>
          );
        case "address":
          const { addressValue } = this.state;
          return (
            <UserEditInfoModal
              title={"Edit Location"}
              type={"address"}
              width={400}
              onCloseModal={this.handleCloseChangeModal}
              onSaveClick={() => this.handleSaveClick({ type: "address" })}
              isWhoAmI
              changeItemId
              dataState={addressValue}
              dataProps={this.props.user.location}
            >
              <PlacesAutocomplete
                value={addressValue}
                onChange={this.handleLocationTextChange}
                onSelect={this.handleLocationSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <React.Fragment>
                    <EditableField
                      {...getInputProps({
                        placeholder: "Enter address",
                      })}
                    />
                    <LocationIconContainer>
                      <LocationIcon />
                    </LocationIconContainer>
                    {suggestions.length ? (
                      <Items>
                        {suggestions.map((location) => {
                          return (
                            <Item
                              {...getSuggestionItemProps(location, {
                                key: location.placeId,
                                title: location.description,
                              })}
                            >
                              <IconContainer>
                                <i className="fas fa-map-marker-alt" />
                              </IconContainer>
                              <PrimaryText>{location.description}</PrimaryText>
                            </Item>
                          );
                        })}
                      </Items>
                    ) : null}
                  </React.Fragment>
                )}
              </PlacesAutocomplete>
            </UserEditInfoModal>
          );
        case "description":
          const { description } = this.state;
          const info = this.props.user.info;
          return (
            <UserEditInfoModal
              title={"Edit Summary"}
              width={500}
              onCloseModal={this.handleCloseChangeModal}
              onSaveClick={() => this.handleSaveClick({ type: "description" })}
              isWhoAmI
              changeItemId
              dataState={description}
              dataProps={info ? info : ""}
            >
              <DescriptionWorlds>
                max length: 500 letters! Remaining{" "}
                {description ? 500 - description.length : 500} letters
              </DescriptionWorlds>
              <ChangeModalTextarea
                name={"description"}
                value={description}
                onChange={this.handleChangeStateByName}
              />
            </UserEditInfoModal>
          );
        case "languages":
          const {
            onLoadLanguagesList,
            languagesList,
            userLanguages,
          } = this.props;
          const allLangParsed = languagesList.map((item) => ({
            name: item.language,
            id: item.id,
          }));
          const userLangParsed = userLanguages.map((item) => ({
            name: item.name,
            id: item.lan_id,
          }));
          return (
            <SkillsModal
              allItems={allLangParsed}
              isOpen={true}
              placeholder="Search For Languages"
              subTitle="selected languages"
              title="Add New Languages"
              userItems={userLangParsed}
              onEditComplete={({ toAdd, toDelete }) =>
                this.handleSaveClick({ type: "languages", toAdd, toDelete })
              }
              onLoad={onLoadLanguagesList}
              onRequestClose={this.handleCloseChangeModal}
            />
          );
        case "personality":
          const {
            personalities,
            onLoadUserPersonalities,
            userPersonalities,
          } = this.props;
          return (
            <SkillsModal
              allItems={personalities}
              isOpen={true}
              placeholder={"Search For Personality"}
              subTitle={"selected personality"}
              title={"Edit Personality"}
              userItems={userPersonalities}
              onEditComplete={({ toAdd, toDelete }) =>
                this.handleSaveClick({ type: "personality", toAdd, toDelete })
              }
              onLoad={onLoadUserPersonalities}
              onRequestClose={this.handleCloseChangeModal}
            />
          );
        case "expectation":
          const {
            expectations,
            userExpectations,
            onLoadUserExpectations,
          } = this.props;
          return (
            <SkillsModal
              allItems={expectations}
              isOpen={true}
              placeholder={"Search For Expectation"}
              subTitle={"selected expectation"}
              title={"Edit Expectation"}
              userItems={userExpectations}
              onEditComplete={({ toAdd, toDelete }) =>
                this.handleSaveClick({ type: "expectation", toAdd, toDelete })
              }
              onLoad={onLoadUserExpectations}
              onRequestClose={this.handleCloseChangeModal}
            />
          );
        case "companies":
          const { onLoadCompanies, companiesList, userCompanies } = this.props;
          return (
            <SkillsModal
              allItems={companiesList}
              isOpen={true}
              placeholder={"Search For Companies"}
              subTitle={"selected companies"}
              title={"Edit Companies"}
              userItems={userCompanies}
              onEditComplete={({ toAdd, toDelete }) =>
                this.handleSaveClick({ type: "companies", toAdd, toDelete })
              }
              onLoad={onLoadCompanies}
              onRequestClose={this.handleCloseChangeModal}
            />
          );
        case "jobTypes":
          const { jobTypesList, userJobTypes, onLoadJobTypes } = this.props;
          return (
            <SkillsModal
              allItems={jobTypesList}
              isOpen={true}
              placeholder={"Search For Job Types"}
              subTitle={"selected job types"}
              title={"Edit Job Types"}
              userItems={userJobTypes}
              onEditComplete={({ toAdd, toDelete }) =>
                this.handleSaveClick({ type: "jobTypes", toAdd, toDelete })
              }
              onLoad={onLoadJobTypes}
              onRequestClose={this.handleCloseChangeModal}
            />
          );
        case "interests":
          return (
            <InterestsModal
              allItems={
                this.props.user.interests_info
                  ? this.props.user.interests_info.split(",")
                  : []
              }
              isOpen={true}
              onEditComplete={(interests) =>
                this.handleSaveInterests(interests)
              }
              placeholder={"Add Interest"}
              subTitle={"Your Interests"}
              title={"Add Interests"}
              onRequestClose={this.handleCloseChangeModal}
            />
          );
        default:
          console.warn("not opened modal! modal type: ", changeModalType);
      }
    }
  };

  renderLastInfo = () => {
    const { userExperience, userUniversity, userActivities } = this.props;

    //experience
    const sortQualify = userExperience.sort(
      (a, b) => moment(b.start_date).year() - moment(a.start_date).year()
    );
    const lastQualify = userExperience.length ? sortQualify[0].position : "";
    // ---
    //university
    const sortUniversity = userUniversity.sort(
      (a, b) => moment(b.start_date).year() - moment(a.start_date).year()
    );
    const lastUniversity = userUniversity.length ? sortUniversity[0].title : "";
    // ---
    //experience years
    const experienceYearsArr = userExperience.map((item) => {
      return moment(item.end_date).year() - moment(item.start_date).year();
    });
    let experienceYears = 0;
    experienceYearsArr.forEach((item) => (experienceYears += item));
    // ---
    //activities
    const sortActivities = userActivities.sort(
      (a, b) => moment(b.start_date).year() - moment(a.start_date).year()
    );
    const lastActivities = userActivities.length ? sortActivities[0].title : "";
    // ---
    const experienceYear =
      experienceYears === 0 ? "" : `${experienceYears} years of experience`;

    return (
      <UserInfoContainer>
        <UserInfo
          defaultIconName={"fas fa-user"}
          emptyButtonText={"Add Your Quality"}
          text={lastQualify}
        />
        <UserInfo
          defaultIconName={"fas fa-graduation-cap"}
          emptyButtonText={"Add Your University"}
          text={lastUniversity}
        />
        <UserInfo
          defaultIconName={"fas fa-briefcase"}
          emptyButtonText={"Add Your Experience"}
          text={experienceYear}
        />
        <UserInfo
          defaultIconName={"fas fa-trophy"}
          emptyButtonText={"Add Your Award"}
          text={lastActivities}
        />
      </UserInfoContainer>
    );
  };

  render() {
    const {
      user,
      userLanguages,
      userExpectations,
      userPersonalities,
      userJobTypes,
      userCompanies,
    } = this.props;

    return (
      <CareerTimeLineBlock icon={false} title={"Who I Am"} type={"whoiam"}>
        <Container>
          <Title>Hello, I'm {user.name}</Title>
          {user.info && user.info.length ? (
            <DescriptionBlock>
              <Icon
                marginTop="-15px"
                className={"fas fa-edit"}
                onClick={() => this.handleOpenChangeModal("description")}
              />
              <Description
                onClick={() => this.handleOpenChangeModal("description")}
              >
                {user.info}
              </Description>
            </DescriptionBlock>
          ) : (
            <ButtonContainer>
              <AddButton
                value={"Add Summary"}
                onClickAdd={() => this.handleOpenChangeModal("description")}
              />
            </ButtonContainer>
          )}
          {this.renderLastInfo()}
          <ContactContainer>
            <UserContactBlock
              address={user.location}
              email={user.email}
              phone={user.phone}
              onOpenAddressModal={() => this.handleOpenChangeModal("address")}
              onOpenPhoneModal={() => this.handleOpenChangeModal("phone")}
            />
            <UserLanguagesBlock
              languages={userLanguages}
              onClickAdd={() => this.handleOpenChangeModal("languages")}
              onStarClick={this.handleStarClick}
            />
          </ContactContainer>
          <SkillContainer>
            <SkillBlock
              buttonValue={"Add New Personality"}
              items={userPersonalities}
              title={"My Personality"}
              onClickAdd={() => this.handleOpenChangeModal("personality")}
              onDeleteItem={(id) =>
                this.handleDeleteSkill({ type: "personality", id })
              }
            />
            <SkillBlock
              buttonValue={"Add New Expectation"}
              items={userExpectations}
              title={"My Expectation"}
              onClickAdd={() => this.handleOpenChangeModal("expectation")}
              onDeleteItem={(id) =>
                this.handleDeleteSkill({ type: "expectation", id })
              }
            />
            <SkillBlock
              buttonValue={"Add New Companies"}
              items={userCompanies}
              title={"My Companies"}
              onClickAdd={() => this.handleOpenChangeModal("companies")}
              onDeleteItem={(id) =>
                this.handleDeleteSkill({ type: "companies", id })
              }
            />
            <SkillBlock
              buttonValue={"Add New Job Type"}
              items={userJobTypes}
              title={"My Job Type"}
              onClickAdd={() => this.handleOpenChangeModal("jobTypes")}
              onDeleteItem={(id) =>
                this.handleDeleteSkill({ type: "jobTypes", id })
              }
            />
            <SkillBlock
              buttonValue={"Add Interests"}
              items={user.interests_info ? user.interests_info.split(",") : []}
              title={"My Interests"}
              onClickAdd={() => this.handleOpenChangeModal("interests")}
              onDeleteItem={(id) =>
                this.handleDeleteSkill({ type: "interests", id })
              }
              styles={"margin-bottom:10px;"}
            />
          </SkillContainer>
        </Container>
        {this.renderChangeModal()}
      </CareerTimeLineBlock>
    );
  }
}
