import React, { PureComponent } from 'react'
import $ from 'jquery'
import Spiner from 'react-spinkit'
import {
  Container,
  TextBox,
  Items,
  Item,
  Icon,
  PrimaryText,
  TextInputContainer,
  SpinnerContainer
  //SecondaryText
} from './styles'

export default class Autocomplete extends PureComponent {
  state = {
    top: 0,
    left: 0,
    isExpanded: false
  }

  componentDidMount() {
    this.calculateLocation()
    window.addEventListener('resize', this.calculateLocation)
    window.addEventListener('click', this.hidePredictions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateLocation)
    window.removeEventListener('click', this.hidePredictions)
  }

  renderItems = () => {
    const { items } = this.props
    const parsed = []
    if (!items.length) return []
    for (let i = 0; i < items.length; i++) {
      const el = items[i]
      parsed.push(
        <Item key={el.id} onClick={() => this.onChooseHandler(el.id)}>
          <Icon>
            <i className={this.props.icon} />
          </Icon>
          <PrimaryText>{el.name}</PrimaryText>
        </Item>
      )
    }
    return parsed
  }

  calculateLocation = () => {
    const { top, left } = this.$txtBox.offset()
    this.setState({
      top: top + this.$txtBox.height() + 5,
      left
    })
  }

  toggleFocus = () => {
    this.setState({ focused: !this.state.focused })
  }

  onChooseHandler = i => {
    this.setState({ isExpanded: false })
    this.props.onChoose(i)
  }

  handleFocus = () => {
    this.setState({ isExpanded: true })
    this.props.onFocus && this.props.onFocus()
  }

  hidePredictions = e => {
    const { left, top } = this.$container.offset()
    const width = this.$container.width()
    const height = this.$container.height()
    if (
      !(
        e.clientX > left &&
        e.clientX < left + width &&
        e.clientY > top &&
        e.clientY < top + height
      )
    ) {
      this.setState({ isExpanded: false })
    }
  }

  render() {
    const items = this.renderItems()
    const loadingIconConfig = {
      // marginLeft: '15px',
      position: 'absolute',
      left: '15px',
      marginBottom: '10px'
    }
    return (
      <div ref={el => (this.$container = $(el))}>
        <Container onClick={this.handleFocus}>
          <TextInputContainer innerRef={el => (this.$txtBox = $(el))}>
            <TextBox
              {...this.props.style}
              disabled={this.props.disabled}
              error={this.props.errorLocation}
              placeholder={this.props.placeholder}
              value={this.props.value}
              onChange={this.props.onValueChange}
              onFocus={this.handleFocus}
            />
            {(this.props.isLoading || this.props.disabled) && (
              <SpinnerContainer>
                <Spiner name="circle" style={loadingIconConfig} />
              </SpinnerContainer>
            )}
          </TextInputContainer>
          {this.state.isExpanded && items.length && !this.props.isLoading ? (
            <Items left={this.state.left} top={this.state.top}>
              {items}
            </Items>
          ) : (
            undefined
          )}
        </Container>
      </div>
    )
  }
}
