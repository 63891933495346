import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'perfect-scrollbar'
import { Container } from './styles'

export default class extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onScrollX: PropTypes.func
  }

  static defaultProps = {
    name: 'element',
    onScrollX: null
  }

  componentDidMount() {
    const { name, onScrollX } = this.props
    new PerfectScrollbar(`#${name}`, {
      suppressScrollY: true
    })
    if (onScrollX) {
      document.getElementById(name).addEventListener('ps-scroll-x', onScrollX)
    }
  }

  componentWillUnmount() {
    const { name, onScrollX } = this.props
    if (onScrollX) {
      document
        .getElementById(name)
        .removeEventListener('ps-scroll-x', onScrollX)
    }
  }

  render() {
    const { name, children, onScrollX } = this.props
    return (
      <Container id={name} onScrollX={onScrollX}>
        {children}
      </Container>
    )
  }
}
