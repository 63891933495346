import React, { Component, Fragment } from "react";
//import PropTypes from 'prop-types'

import { AddButton, Card, ChangeModal } from "../blocks";
import { Element } from "react-scroll";

import { getProfQualifImage } from "../../../../../Helpers/getImages";

import { Container, CardsList } from "./styled";
import swal from "sweetalert";

export default class MyProfessionalQualifications extends Component {
  constructor(props) {
    super();
    this.state = {
      isModalOpen: false,
      isDraft: false,
      changeItemId: null,
    };

    if (!props.profQualifList.length) props.onLoadProfQualifList();
  }

  handleOpenModal = ({ id }) => {
    this.setState({
      isModalOpen: true,
      changeItemId: id || null,
    });
  };

  handleCloseModal = () => {
    const { isDraft } = this.state;
    // if (!isDraft) {
    //   this.confirmModal();
    // } else {
    this.setState({
      isModalOpen: false,
      changeItemId: null,
    });
    // }
  };
  closeMainModal = () => {
    this.setState({
      isModalOpen: false,
      changeItemId: null,
    });
  };
  confirmModa = () => {
    swal({
      icon: "warning",
      text: "Are you sure you want to discard changes to this ?",
      buttons: {
        confirm: {
          text: "Discard",
          value: true,
          visible: true,
        },
        cancel: {
          text: "No Thanks",
          value: false,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) this.closeMainModal();
    });
  };
  handleSaveClick = (result) => {
    const { onEditProfQualif, onAddProfQualif } = this.props;
    const { changeItemId } = this.state;

    const {
      title,
      subTitle,
      description,
      startDate,
      endDate,
      autocompleateItemId,
      currently,
    } = result;

    const reqObj = {
      profQualifId: autocompleateItemId,
      title,
      subTitle,
      description,
      startDate: `${startDate}-01`,
      endDate: `${endDate}-01`,
      currently,
    };

    if (changeItemId !== null) {
      reqObj.id = changeItemId;
      onEditProfQualif({ ...reqObj });
    } else {
      onAddProfQualif(reqObj);
    }

    this.handleCloseModal();
  };

  handleDeleteClick = (id) => {
    const { onRemoveProfQulif } = this.props;
    onRemoveProfQulif(id);
  };

  handleLoadProfQualifImage = (imageSrc) => {
    return getProfQualifImage(imageSrc);
  };

  renderChangeModal = () => {
    const { userProfQualif, profQualifList } = this.props;
    const { changeItemId } = this.state;

    const modalTitle =
      changeItemId === null
        ? "Add Professional Qualification"
        : "Edit Professional Qualification";
    const changedItem = userProfQualif.find((item) => item.id === changeItemId);

    const profQualif =
      changedItem &&
      profQualifList.find((el) => el.id === changedItem.prof_qualf_id);

    let parsedItem;
    if (changeItemId !== null) {
      parsedItem = {
        title: changedItem.title,
        subTitle: changedItem.sub_title,
        description: changedItem.description,
        startDate: changedItem.start_date,
        endDate: changedItem.end_date,
        image: profQualif ? getProfQualifImage(profQualif.image) : null,
        currently: changedItem.currently,
      };
    }

    return (
      <ChangeModal
        autocompleateItems={profQualifList}
        checkBoxTitle={" This certificate does not expire"}
        faIcon={"fas fa-user-tie"}
        item={parsedItem}
        modalTitle={modalTitle}
        titleInputPlaceholder={"Issued organization Ex: Microsoft"}
        subTitleInputPlaceholder={"Your Qualification  Ex: CCNA"}
        withAutocompleate={"subTitle"}
        withMonthDatePicker={true}
        onClose={this.handleCloseModal}
        onLoadAutocompleateItemImage={this.handleLoadProfQualifImage}
        onSave={this.handleSaveClick}
        changeItemId={this.state.changeItemId}
        onDelete={() => this.handleDeleteClick(this.state.changeItemId)}
        isDelete={true}
        isBigModalWindow={false}
      />
    );
  };

  renderCards = () => {
    const { userProfQualif, profQualifList } = this.props;

    if (userProfQualif && userProfQualif.length) {
      return userProfQualif.map((item) => {
        const profQualif = profQualifList.find(
          (el) => el.id === item.prof_qualf_id
        );
        return (
          <Element key={item.id} name={`academQualifId${item.id}`}>
            <Card
              description={item.description}
              endDate={item.end_date}
              faIcon={"fas fa-user-tie"}
              id={item.id}
              image={profQualif && getProfQualifImage(profQualif.image)}
              item={item}
              startDate={item.start_date}
              subTitle={item.sub_title}
              title={item.title}
              type={"professional"}
              onDelete={() => this.handleDeleteClick(item.id)}
              onModalOpen={() => this.handleOpenModal({ id: item.id })}
              currently={item.currently}
            />
          </Element>
        );
      });
    }
  };
  render() {
    const { isModalOpen } = this.state;
    return (
      <Container>
        <AddButton
          value={"Add Professional Qualification"}
          onClickAdd={this.handleOpenModal}
        />
        <CardsList>{this.renderCards()}</CardsList>
        {isModalOpen ? this.renderChangeModal() : null}
      </Container>
    );
  }
}
