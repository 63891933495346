import styled from 'styled-components'
import * as colors from 'Theme/colors'

export const Content = styled.div`
  margin: 24px 16px 0;
`
export const ItemText = styled.div`
  color: grey;
  font-size: 11px;
  text-align: center;
  margin: 11px 0;
  max-width: 60px;
`
export const BottomBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 8px;
`

export const BlueText = styled.a`
  padding-left: 16px;
  color: ${colors.mainBlue};
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
`
export const Form = styled.div`
  margin: 32px 24px 24px 0;
`