import styled from "styled-components";
import * as colors from "../../../Theme/colors";

export const Container = styled.div`
  margin: 0 0 0 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 500px) {
    width: 90%;
    margin: 0 auto;
  }
`;
export const Follow = styled.button`
  min-width: 70px;
  min-height: 10px;
  color: white;
  background: ${(props) => (props.follow ? notificationRed : "#32e09c")};
  ${"" /* margin: 10px auto 0; */}
  border: none;
  border-radius: 13px;
  outline: none;
  @media only screen and (max-width: 500px) {
    height: 30px;
  }
`;
export const RatingLevelContainer = styled.div`
  margin: 0 0 5px 10px;
  padding: 5px 10px;
  font-size: 1.4vh;
  border-radius: 50px;
  background-color: #583ea1;
  height: 2vh;
  display: flex;
  font-weight: bold;
  color: #fff;
  justify-content: center;
  align-items: center;
`;
export const FollowBtn = styled.div`
  margin: 0 0 5px 10px;
  padding: 5px 10px;
  font-size: 1.4vh;
  border: none;
  border-radius: 13px;
  outline: none;
  background-color: #32e09c;
  height: 2vh;
  display: flex;
  font-weight: bold;
  color: #fff;
  justify-content: center;
  align-items: center;
`;
export const RatingLevelText = styled.span`
  font-family: Nunito;
  color: #00b4ff;
  padding: 10px,
  margin:10px,
`;
