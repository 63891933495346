import Component from './WhatOthersSay'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUserFeedbacks } from '../../../../../Redux/selectors/publicUser'

const selectors = createStructuredSelector({
  userFeedbacks: getUserFeedbacks
})

const actions = {}

export const WhatOthersSay = connect(
  selectors,
  actions
)(Component)
