import Component from './ResendWindow'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { resendConfirmEmail } from 'Redux/actions/user'
import { getResendLoadInfo } from 'Redux/selectors/register'
import { switchModal } from 'Redux/actions/utils'
import { confirmEmailClean } from 'Redux/actions/emailConfirmation'

const selectors = createStructuredSelector({
  resendLoadInfo: getResendLoadInfo
})

const actions = {
  // resend
  resendConfirmEmail,
  onSwitchModal: switchModal,
  onConfirmEmailClean: confirmEmailClean,
}

export default connect(
  selectors,
  actions
)(Component)
