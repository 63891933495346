import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { CareerTimeLineBlock } from '../../../../Blocks'
import { ActivitiesCard } from '../blocks'

import { Container, Content } from './styled'

export default class Activities extends PureComponent {
  static propTypes = {
    activities: PropTypes.array,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func
  }

  render() {
    const { activities } = this.props
    return (
      <CareerTimeLineBlock
        title={'achievements & activities'}
        type={'activities'}
      >
        <Container>
          <Content>
            {activities && activities.length
              ? activities.map(item => (
                  <ActivitiesCard
                    description={item.description}
                    endDate={item.end_date}
                    faIcon={'fas fa-trophy'}
                    id={item.id}
                    startDate={item.start_date}
                    subTitle={item.sub_title}
                    title={item.title}
                    type={'activities'}
                  />
                ))
              : null}
          </Content>
        </Container>
      </CareerTimeLineBlock>
    )
  }
}
