import { createReducer } from 'Helpers/redux'

import {
  LOAD,
  APPLY,
  ACCEPT,
  DECLINE,
  NO_INTEREST
} from 'Redux/actions/applications'

const initialState = {
  applications: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  },
  applyState: {
    success: false,
    request: false,
    error: false
  },
  declineState: {
    success: false,
    request: false,
    error: false
  },
  noInterestReqState: {
    success: false,
    request: false,
    error: false
  }
}

const handlers = {
  [LOAD.REQUEST]: state => {
    return {
      ...state,
      loadInfo: {
        ...state.loadInfo,
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: payload.data
      }
    }
  },
  [LOAD.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      },
      applications: payload.data
    }
  },
  [APPLY.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      applications: [...state.applications, payload.data],
      applyState: {
        request: false,
        success: true,
        error: false
      }
    }
  },
  [APPLY.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      applyState: {
        request: true,
        success: false,
        error: false
      }
    }
  },
  [APPLY.FAILURE]: state => {
    return {
      ...state,
      applyState: {
        request: false,
        success: false,
        error: true
      }
    }
  },
  [DECLINE.REQUEST]: state => {
    return {
      ...state,
      declineState: {
        success: false,
        request: true,
        error: false
      }
    }
  },
  [DECLINE.FAILURE]: state => {
    return {
      ...state,
      declineState: {
        success: false,
        request: false,
        error: true
      }
    }
  },
  [DECLINE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      applications: payload.data,
      declineState: {
        success: true,
        request: false,
        error: false
      }
    }
  },
  [ACCEPT.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      applications: payload.data
    }
  },
  [NO_INTEREST.REQUEST]: state => ({
    ...state,
    noInterestReqState: {
      success: false,
      request: true,
      error: false
    }
  }),
  [NO_INTEREST.SUCCESS]: state => ({
    ...state,
    noInterestReqState: {
      success: true,
      request: false,
      error: false
    }
  }),
  [NO_INTEREST.FAILURE]: state => ({
    ...state,
    noInterestReqState: {
      success: false,
      request: false,
      error: true
    }
  })
}

export default createReducer(initialState, handlers)
