import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

import * as colors from '../../../../../Theme/colors'

export const Container = styled.div`
  padding: 10px 0 20px 0;
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`
