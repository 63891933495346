import styled from "styled-components";

export const Content = styled.div`
  max-height: calc(100vh - 35px);
  margin: 0;
  position: relative;
  @media screen and (max-width: 500px) {
    overflow-y: auto;
    max-height: initial;
  }
`;
export const Title = styled.h1`
  background-color: rgb(90, 64, 162);
  width: 215px;
  padding: 10px;
  color: #fff;
  border-radius: 20px;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 15px;
  &:first-child {
    margin-top: 10px;
  }
`;
