import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { mountFilter } from "../../../Helpers/mountFilter";
import { unmountFilter } from "../../../Helpers/unmountFilter";
import { FilterItem } from "../../Blocks/FilterItem/FilterItem";

import {
  Search,
  SearchInput,
  Button,
  CloseBtn,
  FilterContainer,
  FilterTitle,
  SearchIcon,
  Container,
  JobForYouFilterTitle,
} from "./styled";

export default class DropdownExpertises extends PureComponent {
  componentDidMount() {
    mountFilter("Expertises", true);
  }

  componentWillUnmount() {
    unmountFilter("Expertises");
  }

  render() {
    const {
      onFilterClose,
      expertiseNameFilter,
      onExperisesNameFilterTextChange,
      expertisesToRender,
      onExperisesFilterItemClick,
      onExperisesFilterOkBtnClick,
      isUnmount,
    } = this.props;
    return ReactDOM.createPortal(
      <OutsideClickHandler onOutsideClick={onFilterClose}>
        <Container
          id="Expertises"
          isUnmount={isUnmount}
          right
          style={{ height: "300px" }}
        >
          <FilterContainer>
            <FilterTitle>EXPERTISES</FilterTitle>
            <CloseBtn onClick={onFilterClose}>
              <i className="fas fa-times" />
            </CloseBtn>
          </FilterContainer>
          <Search>
            <SearchIcon className="fas fa-search" />
            <SearchInput
              placeholder="Search for Expertises"
              value={expertiseNameFilter}
              onChange={onExperisesNameFilterTextChange}
            />
          </Search>
          <JobForYouFilterTitle>Selected Expertises:</JobForYouFilterTitle>
          <FilterItem
            items={expertisesToRender}
            onItemClick={onExperisesFilterItemClick}
          />
          <Button onClick={onExperisesFilterOkBtnClick}>
            <i className="fas fa-check-circle" /> Okay!
          </Button>
        </Container>
      </OutsideClickHandler>,
      document.getElementById("root-modal")
    );
  }
}
