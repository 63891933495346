import React, { PureComponent } from 'react'

import { Card } from '../blocks'
import { CareerTimeLineBlock } from '../../../../Blocks'

import { Element } from 'react-scroll'

import { Container, CardsList } from './styles'
import { getCompanyImage } from '../../../../../Helpers/getImages'

export default class MyPositions extends PureComponent {
  constructor(props) {
    super()

    if (!props.companies.length) props.onLoadCompanies()
  }

  renderCards = () => {
    const { experience, companies } = this.props
    if (experience && experience.length) {
      return experience.map(item => {
        const company = companies.find(el => el.name === item.company)
        return (
          <Element key={item.id} name={`experience${item.id}`}>
            <Card
              description={item.description}
              endDate={item.end_date}
              faIcon={'fas fa-building'}
              id={item.id}
              image={company ? getCompanyImage(company.logo) : null}
              item={item}
              startDate={item.start_date}
              subTitle={item.company}
              title={item.position}
              type={'experiences'}
              onDelete={() => this.handleDeleteClick(item.id)}
              onModalOpen={() => this.handleOpenModal({ id: item.id })}
            />
          </Element>
        )
      })
    }
  }

  render() {
    return (
      <CareerTimeLineBlock title={'My Position'} type={'experience'}>
        <Container>
          <CardsList>{this.renderCards()}</CardsList>
        </Container>
      </CareerTimeLineBlock>
    )
  }
}
