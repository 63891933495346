import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PerfectScrollbar from "perfect-scrollbar";
import withSizes from "react-sizes";

import _ from "lodash";

import OppBlock from "../OppBlock/OppBlock";
import EmptyList from "../EmptyList";

import {
  FullWidthTabsContainerDesktop,
  EmptyListContainer,
  AppBarComponent,
} from "./styles";
import "./style.css";

class TabContainerComponent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  };
  componentDidMount() {
    const { isMobile } = this.props;
    if (!isMobile) {
      new PerfectScrollbar("#oppScrollContainer");
    }
  }
  render() {
    const { clientWidth } = document.documentElement;
    const { dir, children } = this.props;
    return (
      <Typography
        component="div"
        dir={dir}
        id="oppScrollContainer"
        style={{
          padding: `${clientWidth < 321 ? "10px 0 0" : "24px 0 0"}`,
          display: `${clientWidth > 1023 ? "flex" : "block"}`,
          flexWrap: "wrap",
          width: "100%",
          maxHeight: `${clientWidth < 499 ? "100%" : "calc(100vh - 120px)"}`,
          overflow: "hidden",
          flexDirection: `${clientWidth < 1001 ? "column !important" : "row"}`,
        }}
      >
        {children}
      </Typography>
    );
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500,
});

const TabContainer = withSizes(mapSizeToProps)(TabContainerComponent);

const styles = (theme) => ({
  root: {
    backgroundColor: "white",
    color: "black",
    width: "100%",
    boxShadow: "none",
  },
  tab: {
    textTransform: "initial",
  },
});

class FullWidthTabs extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };

  state = {
    activeTabIndex: 0,
    activeSubtabIndex: 0,
  };

  handleTabIndexChange = (event, value) => {
    this.setState({ activeTabIndex: value, activeSubtabIndex: 0 });
  };

  handleSubtabIndexChange = (event, value) => {
    this.setState({ activeSubtabIndex: value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  renderTabs = (tabs, activeIndex, onChange) => {
    const { classes, theme } = this.props;

    const titles = [];
    const contents = [];

    tabs.forEach((tab, index) => {
      titles.push(
        <Tab
          className="size tab-width"
          classes={{ root: classes.tab }}
          key={index}
          label={tab.title}
        />
      );
      contents.push(this.renderTabContent(tab));
    });

    return (
      <div>
        <AppBarComponent className={classes.root} position="absolute">
          <Tabs
            className="border_color"
            fullWidth
            indicatorColor="primary"
            value={activeIndex}
            onChange={onChange}
          >
            {titles}
          </Tabs>
        </AppBarComponent>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeIndex}
          slideStyle={{
            // height: '1000px',
            overflow: "visible",
            marginTop: "50px",
          }}
          style={{ overflow: "visible" }}
          onChangeIndex={onChange}
        >
          {contents}
        </SwipeableViews>
      </div>
    );
  };

  renderTabContent = (tab) => {
    const {
      theme,
      applications,
      onApplicationAccept,
      onApplicationDecline,
      user,
      onFriendsSupportClick,
      onMessageClick,
      onNoInterestApplicationClick,
    } = this.props;
    const { activeSubtabIndex } = this.state;

    const { subTitles } = tab;

    if (_.isArray(subTitles)) {
      return this.renderTabs(
        subTitles,
        activeSubtabIndex,
        this.handleSubtabIndexChange
      );
    }

    const activeCategoryApplications = _.get(applications, tab.key);

    if (!_.isArray(activeCategoryApplications)) {
      return (
        <EmptyListContainer>
          <EmptyList height="75vh" />
        </EmptyListContainer>
      );
    }
    // console.log(_.isArray);
    return (
      <TabContainer dir={theme.direction}>
        {activeCategoryApplications.map((application, index) => {
          return (
            <OppBlock
              card={application}
              key={index}
              user={user}
              onApplicationAccept={onApplicationAccept}
              onApplicationDecline={onApplicationDecline}
              onFriendsSupportClick={() =>
                onFriendsSupportClick(application.id)
              }
              onMessageClick={onMessageClick}
              onNoInterestApplicationClick={onNoInterestApplicationClick}
            />
          );
        })}
      </TabContainer>
    );
  };

  render() {
    const { classes, tabs, applications } = this.props;

    if (!_.isObject(applications)) {
      return null;
    }

    const { activeTabIndex } = this.state;

    return (
      <FullWidthTabsContainerDesktop className={classes.root}>
        {this.renderTabs(tabs, activeTabIndex, this.handleTabIndexChange)}
      </FullWidthTabsContainerDesktop>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FullWidthTabs);
