import styled from 'styled-components'
import SpinerComponent from 'react-spinkit'

export const Container = styled.section`
  padding: 0 60px 0 300px;
  position: relative;
  @media screen and (max-width: 1000px) {
    padding: 0 60px 0 20px;
  }
  @media screen and (max-width: 500px) {
    padding: 40px 10px 0;
  }
  @media screen and (max-width: 350px) {
    padding: 40px 0 0;
  }
`
export const TopItemIcon = styled.img`
  width: 27px;
  height: 27px;
`
export const ContentWrapperStyled = styled.div`
  height: 100%;
  padding-top: 20px;
  max-width: 1400px;
  font-family: Nunito;
  @media screen and (max-width: 800px) {
    position: static;
  }
  @media screen and (min-width: 500px) {
    width: 100%;
  }
`
export const Loader = styled(SpinerComponent).attrs({ name: 'ball-beat' })`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
`
