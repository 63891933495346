import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
//import TypographyElement from '@material-ui/core/Typography'

export const FullWidthTabsContainerDesktop = styled.div`
  margin-right: 150px;
  @media screen and (max-width: 500px) {
    margin: 0;
  }
  @media screen and (min-width: 1000px) {
  }
`
export const EmptyListContainer = styled.div`
  margin: 0 auto;
  
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`
export const AppBarComponent = styled(AppBar)`
    @media screen and (max-width: 500px) {
      top: 35px !important;
    }
`
// export const Typography = styled(TypographyElement).attrs({
//   component: 'div',
//   dir: this.props.dir
// })``
