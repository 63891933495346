import { createSelector } from "reselect";

const companiseData = store => store.companies;

export const getCompanies = createSelector(
  companiseData,
  data => data.companies
);

export const getCompaniesLoadInfo = createSelector(
  companiseData,
  data => data.loadInfo
);
