import styled from "styled-components";
import { Link } from "react-router-dom";

export const ActivePageText = styled.li`
  text-align: center;
  font-size: 20px;
  padding: 0;
`;
export const DeleteBtn = styled.i.attrs({ className: "fas fa-times" })`
  font-size: 15px;
  margin-left: 10px;
`;
export const HeaderCategory = styled.div`
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: black;

  :hover {
    color: #395eff !important;
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
export const HeaderCategoryTitle = styled.p`
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  color: #656565;
  font-family: "Nunito";
  text-transform: uppercase;
  ${(props) =>
    props.isActive &&
    `
    color: #000 !important;
    border-bottom: 2px solid #583ea1;
  `};
`;
export const Page = styled.ul`
  padding: 0 10px;
  margin-bottom: 0;
  @media (max-width: 799px) {
    padding-right: 10px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 10px 0 10px;
  }
`;
export const PagesContainer = styled.div`
  display: flex;
  height: 35px !important;
  position: relative;
  align-items: center;
  @media screen and (max-width: 500px) {
    width: 80%;
    overflow: hidden;
  }
  & > a:focus {
    text-decoration: none;
  }
`;
export const ArrowIcon = styled.i``;
const _iconArrow = styled.div`
  font-size: 16px;
  color: ${(p) => (p.disabled ? "#dddada" : "#fff")};
  min-width: 30px;
  min-height: 30px;
  border: 1px solid ${(p) => (p.disabled ? "#dddada" : "#fff")};
  border-radius: 50%;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background-color: ${(p) => (p.disabled ? "#757575" : "#00b7fe")};
  @media all and (max-width: 399px) {
    display: none;
  }
`;
export const NextArrow = styled(_iconArrow)`
  ${_iconArrow};
  margin-right: 2px;
  @media screen and (max-width: 799px) {
    margin-left: auto;
    margin-right: 5px;
  }
`;
export const PrevArrow = styled(_iconArrow)`
  ${_iconArrow};
  @media screen and (max-width: 799px) {
    margin-left: 5px;
  }
`;
