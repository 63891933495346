// import { put } from 'redux-saga/effects'

function* onLoginSuccess(action) {
  try {
    localStorage.removeItem('user_token')
    localStorage.removeItem('candidate_id')
    yield action
  } catch (e) {
    console.error(e)
  }
}

export default onLoginSuccess
