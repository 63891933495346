import { takeLatest } from 'redux-saga/effects'

// sagas
import onRehydrateToken from './rehydrateToken'
import onLoginSuccess from './login_success'
import onLogoutSuccess from './logout_success'
import linkedinRedirect from './linkedinRedirect'

// action types
import { REHYDRATE_TOKEN, LOG_IN, LOG_OUT, REGISTER } from 'Redux/actions/auth'
import { LINKEDIN_REGISTER } from 'Redux/actions/register'

function* mySaga() {
  yield takeLatest(REHYDRATE_TOKEN.SUCCESS, onRehydrateToken)
  yield takeLatest(REGISTER.SUCCESS, onLoginSuccess)
  yield takeLatest(LOG_IN.SUCCESS, onLoginSuccess)
  yield takeLatest(LOG_OUT.SUCCESS, onLogoutSuccess)
  yield takeLatest(LINKEDIN_REGISTER.SUCCESS, linkedinRedirect)
}

export default mySaga
