import { mrJobsServerUrl } from '../Config/App'

export const getAcademQualifImage = imageFileName => {
  if (imageFileName) {
    return `${mrJobsServerUrl}/images/academicQualifications/${imageFileName}`
  } else {
    return null
  }
}

export const getCompanyImage = companyImageFileName => {
  if (companyImageFileName) {
    return `${mrJobsServerUrl}/images/company-avatars/${companyImageFileName}`
  } else {
    return null
  }
}

export const getProfQualifImage = imageFileName => {
  if (imageFileName) {
    return `${mrJobsServerUrl}/images/professionalQualifications/${imageFileName}`
  } else {
    return null
  }
}
