import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import { Element } from 'react-scroll'

import {
  CardImage,
  CardTimeComponent,
  CardTitle,
  CardSubTitle,
  CardEditLabel
} from '../../blocks'

import { CardContent, CardDesctiption, Container, Wrap, EditIcon } from './styled'

export default class Card extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    startDate: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onModalOpen: PropTypes.func.isRequired
  }

  state = {
    isShowEditLabel: false
  }

  handleShowEditLabel = () => this.setState({ isShowEditLabel: true })

  handleHideEditLabel = () => this.setState({ isShowEditLabel: false })

  handleClickCard = () => {
    this.setState(prevState => ({
      isShowEditLabel: !prevState.isShowEditLabel
    }))
  }

  render() {
    const {
      title,
      subTitle,
      id,
      startDate,
      endDate,
      description,
      type,
      image,
      faIcon,
      onDelete,
      onModalOpen,
      currently
    } = this.props
    const { isShowEditLabel } = this.state

    const elementLink = `${type}${id}`

    return (
      <Element name={elementLink}>
        <Container
          onClick={this.handleClickCard}
          // onMouseOut={this.handleHideEditLabel}
          // onMouseOver={this.handleShowEditLabel}
        >
          <CardImage faIcon={faIcon} image={image} />
          <CardContent>
            <Wrap>
              <CardTimeComponent endDate={endDate} startDate={startDate} currently={currently}/>
              <EditIcon
                className={'fas fa-edit'}
                onClick={onModalOpen}
              />
            </Wrap>
            <CardTitle text={title} />
            <CardSubTitle text={subTitle} />
            <CardDesctiption>{ReactHtmlParser(description)}</CardDesctiption>
            {/*<CardEditLabel
              isOpen={isShowEditLabel}
              onFirstButtonClick={onModalOpen}
              onSecondButtonClick={onDelete}
            />*/}
          </CardContent>
        </Container>
      </Element>
    )
  }
}
