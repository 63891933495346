import styled from 'styled-components'

export const Container = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  min-width: 92px;
  min-height: 42px;
  background-color: rgb(255, 255, 255);
  position: relative;
  width: 100%;
`
export const InputEditable = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  border-color: rgb(0, 183, 254);
  min-width: 92px;
  min-height: 42px;
  background-color: rgb(255, 255, 255);
`
export const InputEditableField = styled.input`
  font-size: 16px;
  height: 16px;
  font-family: 'Nunito';
  color: rgb(0, 183, 254);
  flex: 1;
  margin: 5px 5px;
  overflow: hidden;
  margin-bottom: 0;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
`
export const List = styled.ul`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: ${props => props.top}px;
  width: 100%;
  display: ${props => (props.visible ? 'block' : 'none')};
  background: white;
  z-index: 7;
`
export const Item = styled.li`
  padding: 10px 20px;
  &:hover {
    background-color: grey;
  }
`
