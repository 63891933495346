import React, { PureComponent } from 'react'
import {
  Container,
  InputEditable,
  InputEditableField,
  List,
  Item
} from './styles'
import $ from 'jquery'

export class Autocomplete extends PureComponent {
  state = {
    autocompleteMargin: 0
  }
  componentDidMount() {
    this.calcAutocompletePos()
    window.addEventListener('resize', this.calcAutocompletePos)
    window.addEventListener('click', this.onBlurHandler)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.calcAutocompletePos)
    window.removeEventListener('click', this.onBlurHandler)
  }
  calcAutocompletePos = () => {
    this.setState({
      autocompleteMargin: this.$input.height()
    })
  }
  onBlurHandler = e => {
    const { top, left } = this.$container.offset()
    const height = this.$container.height()
    const width = this.$container.width()
    if (
      !(
        e.clientX > left &&
        e.clientX < left + width &&
        e.clientY > top &&
        e.clientY < top + height
      )
    ) {
      this.props.onBlur()
    }
  }
  renderItems = () => {
    return this.props.items.map(el => (
      <Item onClick={() => this.props.onItemClick(el)}>{el}</Item>
    ))
  }
  render() {
    return (
      <div ref={el => (this.$container = $(el))}>
        <Container>
          <div ref={el => (this.$input = $(el))}>
            <InputEditable>
              <InputEditableField
                value={this.props.value}
                onChange={this.props.onChange}
                size={this.props.value.length + 1}
                // onBlur={this.props.onBlur}
                autoFocus
              />
            </InputEditable>
          </div>
          <List
            visible={this.props.items.length && this.props.value}
            top={this.state.autocompleteMargin}
          >
            {this.renderItems()}
          </List>
        </Container>
      </div>
    )
  }
}
