import Component from './Settings'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadSettings } from 'Redux/actions/settings'

import { getSettings, getSettingsLoadInfo } from 'Redux/selectors/settings'

const actions = {
  onLoadSettings: loadSettings
}

const selector = createStructuredSelector({
  settingsList: getSettings,
  settingsLoadInfo: getSettingsLoadInfo
})

export default connect(
  selector,
  actions
)(Component)
