import { createReducer } from 'Helpers/redux'

import { LOAD_SUB_EXPERTISES } from 'Redux/actions/subExpertises'

const initialState = {
  subExpertises: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const handlers = {
  [LOAD_SUB_EXPERTISES.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD_SUB_EXPERTISES.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: payload.data
      }
    }
  },
  [LOAD_SUB_EXPERTISES.SUCCESS]: (state, { payload }) => {
    return {
      subExpertises: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
