import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './WhoAmI'
import { loadPersonalities } from 'Redux/actions/personalities'
import { loadExpectations } from 'Redux/actions/expectations'
import { loadJobTypes } from 'Redux/actions/jobTypes'
import { loadCompanies } from 'Redux/actions/companies'
import { getAllLanguages } from '../../../../../Redux/actions/languages'

import { getJobTypes } from 'Redux/selectors/jobTypes'
import { getCompanies } from 'Redux/selectors/companies'
import { getPredictionsPersonalities } from 'Redux/selectors/personalities'
import { getExpectationsPredictions } from 'Redux/selectors/expectations'
import {
  getPersonalities,
  getExpectations,
  getUserJobTypes,
  getUserCompanies,
  getUser,
  getUserLanguages,
  getUserExperience,
  getUserAcademicQualifications,
  getUserActivities
} from 'Redux/selectors/publicUser'

import { getLanguagesList } from '../../../../../Redux/selectors/languagesList'

const selector = createStructuredSelector({
  user: getUser,
  personalities: getPredictionsPersonalities,
  userPersonalities: getPersonalities,
  expectations: getExpectationsPredictions,
  userExpectations: getExpectations,
  languagesList: getLanguagesList,
  userLanguages: getUserLanguages,
  jobTypesList: getJobTypes,
  userJobTypes: getUserJobTypes,
  companiesList: getCompanies,
  userCompanies: getUserCompanies,
  userExperience: getUserExperience,
  userUniversity: getUserAcademicQualifications,
  userActivities: getUserActivities
})
const actionCreators = {
  //personalities
  onLoadUserPersonalities: loadPersonalities,
  //expectations
  onLoadUserExpectations: loadExpectations,
  //languages
  onLoadLanguagesList: getAllLanguages,
  //job type
  onLoadJobTypes: loadJobTypes,
  //companies
  onLoadCompanies: loadCompanies
}

export const WhoAmI = connect(
  selector,
  actionCreators
)(Component)
