import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_PROFILE = createAsyncAction('friend-support/LOAD_PROFILE')
export const loadProfile = identity => {
  return apiCall({
    endpoint: `/candidates/identity/${identity}`,
    types: LOAD_PROFILE
  })
}

export const UPLOAD_FEEDBACK = createAsyncAction(
  'friend-support/UPLOAD_FEEDBACK'
)
export const uploadFeedback = data => {
  return apiCall({
    endpoint: '/candidates/feedback',
    method: 'POST',
    query: data,
    types: UPLOAD_FEEDBACK
  })
}

export const LOAD_INVITED_EMAILS = createAsyncAction(
  'friends-support-invites/LOAD_INVITED_EMAILS'
)
export const loadInvitedEmails = appId => {
  return apiCall({
    endpoint: '/feedback/friends-support-invites',
    types: LOAD_INVITED_EMAILS,
    query: {
      appId
    }
  })
}

export const resetInvitedEmails = () => {
  return {
    type: LOAD_INVITED_EMAILS.REQUEST,
    payload: {
      data: []
    }
  }
}

export const ADD_SUP_INVITE_EMAIL = createAsyncAction(
  'friends-support-invites/ADD_SUP_INVITE_EMAIL'
)
export const addSupInviteEmail = (appId, emails) => {
  return apiCall({
    endpoint: '/feedback/friends-support-invites',
    types: ADD_SUP_INVITE_EMAIL,
    method: 'PUT',
    query: {
      appId,
      emails
    }
  })
}

export const DELETE_SUP_INVITE_EMAIL = createAsyncAction(
  'friends-support-invites/DELETE_SUP_INVITE_EMAIL'
)
export const deleteSupInviteEmail = (id, appId) => {
  return apiCall({
    endpoint: '/feedback/friends-support-invites',
    types: DELETE_SUP_INVITE_EMAIL,
    method: 'DELETE',
    query: {
      id,
      appId
    }
  })
}

export const LOAD_SAVED_EMAILS = createAsyncAction(
  'friends-support-emails/LOAD_SAVE_EMAILS'
)
export const loadSavedEmails = () => {
  return apiCall({
    endpoint: '/feedback/friends-support-emails',
    types: LOAD_SAVED_EMAILS
  })
}

export const ADD_SAVED_EMAIL = createAsyncAction(
  'friends-support-emails/ADD_SAVE_EMAILS'
)
export const addSavedEmail = email => {
  return apiCall({
    endpoint: '/feedback/friends-support-emails',
    types: ADD_SAVED_EMAIL,
    method: 'PUT',
    query: {
      email
    }
  })
}

export const REMOVE_SAVED_EMAIL = createAsyncAction(
  'friends-support-emails/REMOVE_SAVE_EMAILS'
)
export const removeSavedEmail = id => {
  return apiCall({
    endpoint: '/feedback/friends-support-emails',
    types: REMOVE_SAVED_EMAIL,
    method: 'DELETE',
    query: {
      id
    }
  })
}
