import { createReducer } from 'Helpers/redux'
import { LOAD_PRIVACY } from '../actions/privacy'

const initialState = {
  privacy: [],
  loadInfo: {
    success: false,
    loading: false,
    error: null
  }
}

const handlers = {
  [LOAD_PRIVACY.REQUEST]: state => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: true,
        error: null
      }
    }
  },
  [LOAD_PRIVACY.FAILURE]: (state, { payload: { data } }) => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: false,
        error: data
      }
    }
  },
  [LOAD_PRIVACY.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      privacy: data,
      loadInfo: {
        success: true,
        loading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
