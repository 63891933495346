import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import WarningModal from '../../Blocks/WarningModal'
import swal from 'sweetalert'
import {
  ButtonBlock,
  CancelButton,
  CloseButton,
  ModalEditInfoContainerBackground,
  ModalEditInfoContent,
  ModalEditInfoTitle,
  ModalEditInfoContainer,
  SaveButton,
  DeleteButton,
  Wrap
} from './style'
import moment from 'moment/moment'

class UserEditInfoModal extends React.Component {
  // modal in use >>>>>
  constructor(props) {
    super(props)
    this.state = {
      opernWarningModal: false
    }
  }

  deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  closeBigModalWindow = () => {
    const {
      onCloseModal,
      dataProps,
      dataState,
      changeItemId,
      isReferee,
      isWhoAmI,
      isUserInfo,
      idSocial,
      isPassword,
      isName
    } = this.props

    console.log('dataProps >>', dataProps, 'dataState >>', dataState)
    let dataPropsObj = {}
    let dataStateObj = {}
    if (isReferee) {
      dataPropsObj = {
        company: dataProps.company,
        companyAddress: dataProps.companyAddress,
        designation: dataProps.designation,
        email: dataProps.email,
        name: dataProps.name,
        phoneNumber: dataProps.phoneNumber
      }
      dataStateObj = {
        company: dataState.company,
        companyAddress: dataState.companyAddress,
        designation: dataState.designation,
        email: dataState.email,
        name: dataState.name,
        phoneNumber: dataState.phoneNumber
      }
    } else if (isWhoAmI || idSocial || isName || isPassword) {
      dataPropsObj = dataProps
      dataStateObj = dataState
    } else if (isUserInfo) {
      dataPropsObj = {
        day: dataProps.dob && moment(dataProps.dob, 'YYYY/MM/DD').format('D'),
        month:
          dataProps.dob && moment(dataProps.dob, 'YYYY/MM/DD').format('MM'),
        year:
          dataProps.dob && moment(dataProps.dob, 'YYYY/MM/DD').format('YYYY')
      }
      dataStateObj = {
        day: dataState.day,
        month: dataState.month,
        year: dataState.year
      }
    } else {
      dataPropsObj = {
        title: dataProps.title,
        subTitle: dataProps.subTitle,
        description: dataProps.description,
        startDate: moment(dataProps.startDate).format('YYYY-MM'),
        endDate: moment(dataProps.endDate).format('YYYY-MM'),
        image: dataProps.image,
        currently: dataProps.currently
      }
      dataStateObj = {
        title: dataState.title,
        subTitle: dataState.subTitle,
        description: dataState.description.toString('html'),
        startDate: dataState.startDate,
        endDate: dataState.endDate,
        image: dataState.image,
        currently: dataState.curentlyCheck
      }
    }
    console.log(
      'dataPropsObj === dataStateObj',
      dataPropsObj,
      dataStateObj,
      dataPropsObj === dataStateObj
    )
    const isChange = this.deepEqual(dataPropsObj, dataStateObj)

    if (changeItemId) {
      if (isChange) {
        onCloseModal()
      } else {
        this.confirmModal()
      }
    } else {
      // this.setState({ opernWarningModal: !this.state.opernWarningModal })
      onCloseModal()
    }
  }

  closeMainModal = () => {
    const { onCloseModal } = this.props
    this.setState({ opernWarningModal: false })
    onCloseModal()
  }

  onDeletePosition = () => {
    this.props.onDelete()
    this.props.onCloseModal()
  }

  confirmModal = isDeleted => {
    swal({
      icon: 'warning',
      text: isDeleted
        ? 'Are you sure you want delete ?'
        : 'Are you sure you want to discard changes to this ?',
      buttons: {
        confirm: {
          text: isDeleted ? 'Delete' : 'Discard',
          value: true,
          visible: true,
          className: isDeleted ? 'deleted' : 'swal-button--confirm'
        },
        cancel: {
          text: 'No Thanks',
          value: false,
          visible: true
        }
      }
    }).then(value => {
      if (value && isDeleted) this.onDeletePosition()
      if (value) this.closeMainModal()
    })
  }

  render() {
    const {
      children,
      title,
      type,
      width,
      onSaveClick,
      onDelete,
      onCloseModal,
      changeItemId,
      isMyReferee,
      isDelete,
      isBigModalWindow,
      noSaveBtn,
      isCenteredTitle
    } = this.props

    return ReactDOM.createPortal(
      <ModalEditInfoContainerBackground>
        <Wrap onClick={e => e.stopPropagation()}>
          <ModalEditInfoContainer
            width={width}
            onClick={e => e.stopPropagation()}
          >
            <ModalEditInfoContent>
              <ModalEditInfoTitle isCenteredTitle={isCenteredTitle}>
                {title}
              </ModalEditInfoTitle>
              {/*<CloseButton onClick={this.toogleWarnModal} />*/}

              {children}

              <ButtonBlock
                changeItemId={changeItemId !== null && isDelete}
                isMyReferee={isMyReferee}
              >
                {changeItemId !== null && isDelete ? (
                  <DeleteButton onClick={() => this.confirmModal(true)} />
                ) : null}
                {!noSaveBtn && <SaveButton onClick={onSaveClick} />}
                {/*<WarningModal
                onCloseMainModal={this.closeMainModal}
                onCloseWarnMobal={this.toogleWarnModal}
              />*/}
              </ButtonBlock>
            </ModalEditInfoContent>
          </ModalEditInfoContainer>
          <CloseButton
            onClick={this.closeBigModalWindow}
            isBigModalWindow={isBigModalWindow}
            isMyReferee={isMyReferee}
          />
        </Wrap>
      </ModalEditInfoContainerBackground>,
      document.getElementById('root-modal')
    )
  }
}

export { UserEditInfoModal }
