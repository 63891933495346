import React, { PureComponent, Fragment } from 'react'

import { innerRoutes } from '../../../Constants/modalRoutes'

export default class Registration extends PureComponent {
  static propTypes = {}

  constructor(props) {
    super()
    props.onSwitchWindow(innerRoutes.search)
  }

  render() {
    return <Fragment />
  }
}
