import Immutable from 'seamless-immutable'
import { routerReducer, LOCATION_CHANGE } from 'react-router-redux'

import get from 'lodash/get'

import { createReducer } from 'Helpers/redux'

const initialState = Immutable({
  location: null,
  prevLocation: null
})

const handlers = {
  [LOCATION_CHANGE]: (state, action) => {
    const nextState = Immutable(routerReducer(state, action))

    if (nextState === state || nextState.location === null) {
      return nextState
    }

    return nextState.merge({
      prevLocation: get(state, 'location'),
      location: nextState.location
    })
  }
}

export default createReducer(initialState, handlers)