import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './ImGoodAt'
import { loadExpertises } from 'Redux/actions/expertises'
import { getPredExpertises } from 'Redux/selectors/expertises'
import {
  getUserExpertises,
  getUserSubExpertises
} from 'Redux/selectors/publicUser'
import { loadSubExpertises } from 'Redux/actions/subExpertises'
import { getPredSubExpertises } from 'Redux/selectors/subExpertises'

const actions = {
  onLoadExpertisesList: loadExpertises,
  onLoadSubExpertisesList: loadSubExpertises
}
const selector = createStructuredSelector({
  expertises: getPredExpertises,
  userExpertises: getUserExpertises,
  subExpertises: getPredSubExpertises,
  userSubExpertises: getUserSubExpertises
})

export const ImGoodAt = connect(
  selector,
  actions
)(Component)
