import _ from 'lodash'

import defaultBackground from 'images/defaultVacancyImage.png'

import { getVacancyImage } from './getImageLink'

export const getCompanyBackground = CompanyBackgroundField => {
  if (_.isString(CompanyBackgroundField) && CompanyBackgroundField.length > 0) {
    return getVacancyImage(CompanyBackgroundField)
  } else {
    return defaultBackground
  }
}
