import React from 'react'
import { Container, Icon, Text, Loader } from './styles'
import * as colors from 'Theme/colors'

export default ({
  color = colors.mainBlue,
  fontColor = 'white',
  title,
  icon,
  disabled,
  loading,
  onClick,
  width
}) => {
  let iconToRender
  if (icon) {
    iconToRender = loading ? (
      <Loader />
    ) : (
      <Icon>
        <i className={icon} />
      </Icon>
    )
  }

  return (
    <Container
      color={color}
      disabled={disabled || loading}
      fontColor={fontColor}
      onClick={onClick}
      buttonWidth={width}
    >
      {iconToRender}
      <Text>{title}</Text>
    </Container>
  )
}
