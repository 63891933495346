import { createReducer } from 'Helpers/redux'
import {
  SET_CHOOSEN_CATEGOREIS,
  SET_LOCATION,
  // UPDATE_CANDIDATE,
  OAUTH_UESR_SET,
  RESET_OAUTH_USER,
  // PARSE_CV
} from 'Redux/actions/register'
import { REGISTER } from 'Redux/actions/auth'
import { CONFIRM_EMAIL, CONFIRM_EMAIL_CLEAN } from 'Redux/actions/emailConfirmation'

import { RESEND_CONFIRM_EMAIL } from 'Redux/actions/user'

const initialState = {
  categories: [],
  jobTypes: [],
  companies: [],
  location: null,
  kms: null,
  lat: null,
  lng: null,
  cvFile: null,
  cvFileMimeType: null,
  uploadedPath: null,
  isOauthRegisterInProcess: false,
  oauthUser: null,
  cvParseStatus: {
    request: false,
    success: false,
    error: null
  },
  expiriedData: {
    expired: false,
    email: null,
    id: null
  },
  isEmailConfirmed: false,
  loadInfo: {
    success: false,
    loading: false,
    error: null
  },
  resendLoadInfo: {
    success: false,
    loading: false,
    error: null
  }
}

const handlers = {
  [SET_CHOOSEN_CATEGOREIS]: (state, action) => {
    const categories = action.categories || state.categories
    const jobTypes = action.jobTypes || state.jobTypes
    const companies = action.companies || state.companies
    const location = action.location || state.location
    const kms = action.kms || state.kms
    const lat = action.lat || state.lat
    const lng = action.lng || state.lng
    const cvFile = action.cvFile || state.cvFile
    const cvFileMimeType = action.cvFileMimeType || state.cvFileMimeType
    const uploadedPath = action.uploadedPath || state.uploadedPath

    return Object.assign({}, state, {
      categories,
      jobTypes,
      companies,
      location,
      kms,
      lat,
      lng,
      cvFile,
      cvFileMimeType,
      uploadedPath
    })
  },
  [SET_LOCATION]: (state, action) => {
    return Object.assign({}, state, {
      kms: action.kms,
      location: action.location,
      lat: action.lat,
      lng: action.lng
    })
  },
  [OAUTH_UESR_SET]: (state, { payload }) => {
    return {
      ...state,
      isOauthRegisterInProcess: true,
      oauthUser: payload.data
    }
  },
  [REGISTER.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isOauthRegisterInProcess: false,
      oauthUser: null
    }
  },
  [RESET_OAUTH_USER]: state => {
    return {
      ...state,
      isOauthRegisterInProcess: false,
      oauthUser: null
    }
  },
  [CONFIRM_EMAIL.REQUEST]: state => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: true,
        error: null
      }
    }
  },
  [CONFIRM_EMAIL.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: false,
        error: payload.data
      },
      isEmailConfirmed: payload.status === 'confirmed' ? true : false,
    }
  },
  [CONFIRM_EMAIL_CLEAN]: (state, { payload }) => {
    return {
      ...state,
      isEmailConfirmed: false,
    }
  },
  [CONFIRM_EMAIL.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      expiriedData: {
        expired: data.expired ? true : false,
        email: data.email ? data.email : null,
        id: data.id ? data.id : null
      },
      loadInfo: {
        success: true,
        loading: false,
        error: null
      }
    }
  },
  [RESEND_CONFIRM_EMAIL.REQUEST]: state => {
    return {
      ...state,
      resendLoadInfo: {
        success: false,
        loading: true,
        error: null
      }
    }
  },
  [RESEND_CONFIRM_EMAIL.FAILURE]: (state, { payload: { data } }) => {
    return {
      ...state,
      resendLoadInfo: {
        success: false,
        loading: false,
        error: data
      }
    }
  },
  [RESEND_CONFIRM_EMAIL.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      resendLoadInfo: {
        success: true,
        loading: false,
        error: null
      }
    }
  }
  // [PARSE_CV.REQUEST]: state => {
  //   return {
  //     ...state,
  //     cvId: null,
  //     parsedCV: null,
  //     cvParseStatus: {
  //       request: true,
  //       success: false,
  //       error: null
  //     }
  //   }
  // },
  // [PARSE_CV.SUCCESS]: (state, { payload }) => {
  //   console.log(PARSE_CV.SUCCESS, payload)
  //   return {
  //     ...state,
  //     cvId: payload.data.id,
  //     parsedCV: payload.data.parsed,
  //     cvParseStatus: {
  //       request: false,
  //       success: true,
  //       error: null
  //     }
  //   }
  // },
  // [PARSE_CV.FAILURE]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     cvParseStatus: {
  //       request: false,
  //       success: false,
  //       error: payload.data
  //     }
  //   }
  // }
}

export default createReducer(initialState, handlers)
