import { createSelector } from 'reselect'

const applicationsData = store => store.applications

export const getApplications = createSelector(
  applicationsData,
  data => data.applications
)

export const getApplicationsLoadInfo = createSelector(
  applicationsData,
  data => data.loadInfo
)

export const getApplyState = createSelector(
  applicationsData,
  data => data.applyState
)
export const getDeclineState = createSelector(
  applicationsData,
  data => data.declineState
)
export const getNoInterestReqState = createSelector(
  applicationsData,
  data => data.noInterestReqState
)
