import styled from "styled-components";
import * as colors from "Theme/colors";

export const Content = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const ContentMob = styled.div`
  margin: 24px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90vh;
`;
export const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0 8px;
  position: fixed;
  bottom: 5%;
  width: 87vw;
`;
export const BottomBlockBig = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 8px;
`;
export const LocationContainer = styled.div`
  display: flex;
  margin: 24px 15px;
`;
export const IconBlock = styled.div`
  color: white;
  background-color: ${colors.buttonGreen};
  width: 50px;
  height: 50px;
  font-size: 30px;
  box-shadow: 0 0 9.6px rgba(0, 0, 0, 0.4);
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
`;
export const DistanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;
export const DistanceText = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  margin: 11px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
`;
export const SideText = styled.div`
  font-size: 19.6px;
  color: grey;
  margin: 0 16px;
`;
export const DistanceCounter = styled.div`
  color: ${colors.mainBlue};
  font-size: 24px;
`;
export const DistanceButton = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  color: ${(props) => (props.disabled ? "grey" : "white")};
  background-color: ${(props) =>
    props.disabled ? "transparent" : colors.mainBlue};
  border: 1px solid ${(props) => (props.disabled ? "grey" : colors.mainBlue)};
  border-radius: 15px;
  cursor: pointer;
`;
