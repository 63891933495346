import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const Container = styled.div`
  font-size: 3em;
  margin: 5px;
  border-bottom: 1px dashed #fff;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  padding: 0 0 5px;
  color: white;
  @media screen and (max-width: 700px) {
    font-size: 1em;
  }
`
export const List = styled.div`
  opacity: ${props => (props.open ? 1 : 0)};
  max-height: 200px;
  display: flex;
  width: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 0;
  flex-direction: column;
  background-color: white;
  z-index: 5;
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transform: translate(-50%, ${props => (props.open ? '-50%' : '-40%')})
    scale(${props => (props.open ? '1' : '0.9')});
  transition: visibility 0s ${props => (props.open ? '0s' : '0.3s')},
    opacity 0.3s, transform 0.3s;
  @media screen and (max-width: 750px) {
    width: 150px;
    position: fixed;
    top: 5%;
    transform: translate(-50%, ${props => (props.open ? '0' : '10%')});
  }
`
export const ListContainer = styled(PerfectScrollbar).attrs({
  component: 'ul'
})`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`
export const Item = styled.li`
  padding: 5px 10px;
  font-size: 24px;
  width: 100%;
  min-height: max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.lightGreyFont};
  &:hover {
    background-color: ${colors.mainBlue};
    color: white;
  }
  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`
export const InputList = styled.div`
  opacity: ${props => (props.open ? 1 : 0)};
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  width: ${props => (props.isKmsInput ? 200 : 400)}px;
  padding: 0;
  background-color: white;
  z-index: 5;
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transform: translate(-50%, ${props => (props.open ? '-50%' : '-40%')})
    scale(${props => (props.open ? '1' : '0.9')});
  transition: visibility 0s ${props => (props.open ? '0s' : '0.3s')},
    opacity 0.3s, transform 0.3s;
  @media screen and (max-width: 750px) {
    width: 300px;
    position: fixed;
    top: 15%;
    transform: translate(-50%, ${props => (props.open ? '0' : '10%')});
  }
`

export const InputItem = styled.form`
  font-size: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: max-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.lightGreyFont};
  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`
export const Text = styled.div`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  color: black;
  @media screen and (max-width: 500px) {
    max-width: 250px;
  }
`
export const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;
  color: #757575;
  font-size: 2em;
  height: 65px;
  line-height: 60px;
  width: 100%;
  @media screen and (max-width: 500px) {
    height: 30px;
    font-size: 1em;
    line-height: initial;
    padding: 5px;
  }
`
export const InputIconContainer = styled.button`
  border: none;
  border-left: 1px dashed silver;
  outline: none;
  width: 80px;
  height: 100%;
  background: #fff;
  color: silver;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(0, 101, 255, 0.7);
    color: #fff;
  }
  @media screen and (max-width: 500px) {
    width: 40px;
  }
`
export const InputIcon = styled.i.attrs({ className: 'fas fa-arrow-right' })``
/*
  display: ${props => (props.open ? 'flex' : 'none')}
  height: 200px;

 */
