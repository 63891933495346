import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px 0 40px;
  flex-wrap: wrap;
  @media screen and (max-width: 500px) {
    margin-top: 50px;
  }
`
