import React, { PureComponent } from 'react'
import {
  Container,
  Title,
  Button,
  ButtonText,
  Content,
  ItemContainer,
  PlusContainer
} from './styles'
import { Item } from './innerBlocks'

export class WhoAmICard extends PureComponent {
  state = {
    isAddingMode: false,
    addText: ''
  }

  render() {
    return (
      <Container>
        <Content>
          <Title>{this.props.title || 'title'}</Title>
          <Button onClick={this.props.onAddClick}>
            <PlusContainer>+</PlusContainer>
            <ButtonText> Add</ButtonText>
          </Button>
          <ItemContainer>
            {this.props.data &&
              this.props.data.map((item, index) => {
                return (
                  <Item
                    key={index}
                    text={item.name || 'name'}
                    onRemove={() => this.props.onRemoveItem(item.id)}
                  />
                )
              })}
          </ItemContainer>
        </Content>
      </Container>
    )
  }
}
