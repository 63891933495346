import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'

export const Container = styled.div`
  color: ${props => props.fontColor};
  background-color: ${props =>
    props.disabled ? colors.stepGrey : props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  cursor: pointer;
  min-width: 112px;
  height: 42px;
  ${props => (props.buttonWidth ? `width: ${props.buttonWidth}px` : null)};
`
export const Icon = styled.div`
  font-size: 19px;
  width: 20px;
  height: 25px
  position: relative;
  left: -5px;
`
export const Text = styled.div`
  margin-left: 8px;
  font-size: 19px;
`
const loadAnim = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)
  }
`
export const Loader = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  border-right: 1px solid ${colors.mainBlue};
  border-bottom: 1px solid white ${colors.mainBlue};
  border-radius: 50%;
  animation: 2s infinite linear ${loadAnim};
`
