import React, { PureComponent } from 'react'
import _ from 'lodash'
import withSizes from 'react-sizes'

import { Layout } from '../../Blocks'

import { Container, TextBlock, Text, Loader, Title } from './styled'

class ContactUs extends PureComponent {
  constructor(props) {
    super()

    const { onLoadContactUs } = props

    onLoadContactUs()
  }

  renderText = () => {
    const { contactUsList } = this.props
    if (contactUsList.length) {
      const { email, phone, address, fb, ln, tw, map_link } = contactUsList[0]
      return (
        <div>
          <Text>Email: {email}</Text>
          <Text>Phone: {phone}</Text>
          <Text>Address: {address}</Text>
          <Text>Facebook: {fb}</Text>
          <Text>LinkedIn: {ln}</Text>
          <Text>Twitter: {tw}</Text>
          <Text>Google Link: {map_link}</Text>
        </div>
      )
    }
  }

  render() {
    const { contactUsLoadInfo, contactUsList } = this.props
    return (
      <Layout
        header={'Contact Us'}
        history={this.props.history}
        notScroll={true}
      >
        {!contactUsLoadInfo.loading && (
          <Container>
            <Title>Contact Us</Title>
            <TextBlock>{this.renderText()}</TextBlock>
          </Container>
        )}
        {contactUsLoadInfo.loading && <Loader />}
      </Layout>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 600
})

export default withSizes(mapSizeToProps)(ContactUs)
