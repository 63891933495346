import React, { Component } from "react";
import "antd/dist/antd.css";
import { Modal, Button } from "antd";
import { FaIcon } from "../../../FaIcon";
import { Badge } from "antd";
import { Divider } from "antd";
import modalRoutes from "../../../../../Constants/modal_routes";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { updateNotificationData } from "Redux/actions/user";
import { loadNotifications } from "Redux/actions/notifications";
import { loadCompanies } from "Redux/actions/companies";

import { isAuthenticate } from "Redux/selectors/auth";
import { getUserNotifications, getUserEmail } from "Redux/selectors/user";

import { tz } from "moment-timezone";
import moment from "moment";

import company from "../../../../../images/Notification/company.png";
import user from "../../../../../images/Notification/star.png";
import alerts from "../../../../../images/menu/alerts.png";
import { getAvatarSrc, getCompanyImage } from "Helpers/avatar";

import "./style.css";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export default class Notification extends Component {
  state = { visible: false };

  showModal = () => {
    const { userData } = this.props;
    console.log(
      "🚀 ~ file: Notification.js ~ line 35 ~ Notification ~ isAuthenticate",
      userData
    );

    if (userData.id) {
      this.setState({
        visible: true,
      });
    } else {
      // this.props.onSwitchModal(modalRoutes.login);
      history.push("/seeker/login");
      // window.location.reload();
    }
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  componentWillMount() {
    const actions = {
      onLoadNotifications: loadNotifications,
      onUpdateDate: updateNotificationData,
      onLoadCompanies: loadCompanies,
    };
    actions.onLoadNotifications();
    actions.onLoadCompanies();
  }

  componentDidMount() {
    // console.log("🚯", this.props);
  }
  renderAvatar = (item) => {
    const { userData, companies } = this.props;
    let src;
    const compAvatar = item.avatar;
    const typeCheck = item.type;
    let position = typeCheck.search("company");
    src = getAvatarSrc(userData.avatar);
    return (
      <img
        className="imgAvatar"
        alt=""
        src={
          position === -1
            ? getAvatarSrc(userData.avatar)
            : getCompanyImage(compAvatar)
        }
      />
    );
    // src = getCompanyImage(company.image);
  };
  render() {
    const { notification, notificationsLen } = this.props;

    let durationBody = notification.map((item, i) => {
      moment.tz.setDefault("Asia/Colombo");
      const start_date_greenwich = tz(item.time, "Asia/Colombo").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      var data = item.text;
      console.log("names", data.split(" ").splice(-1));
      return (
        <div key={i} value={item}>
          <div className="row">
            <div style={{ minWidth: "15%" }}>{this.renderAvatar(item)}</div>
            <div style={{ minWidth: "60%" }}>
              <div>{item.text}</div>
            </div>
            <div style={{ minWidth: "25%" }}>
              <p
                style={{
                  textAlign: "right",
                }}
              >
                {moment(start_date_greenwich).fromNow()}
              </p>
            </div>
          </div>
          <Divider />
        </div>
      );
    });
    return (
      <div>
        <>
          <Badge
            style={{ marginRight: "20px" }}
            count={notificationsLen}
            overflowCount={99}
          >
            {/* <Button style={{ border: "none" }}> */}
            {/* <FaIcon name={"bell"} /> */}
            <img
              onClick={this.showModal}
              className="imgNotif"
              alt=""
              src={alerts}
            />
            {/* </Button> */}
          </Badge>
          <Modal
            title="Notifications"
            style={{ top: 20, left: 20 }}
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            {notification && <div>{durationBody}</div>}
          </Modal>
        </>
      </div>
    );
  }
}
const selector = createStructuredSelector({
  isAuthenticate,
});
