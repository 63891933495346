import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { FilterItem } from "../../Blocks/FilterItem/FilterItem";
import { mountFilter } from "../../../Helpers/mountFilter";
import { unmountFilter } from "../../../Helpers/unmountFilter";
import "./style.css";
import {
  SearchInput,
  Search,
  Button,
  CloseBtn,
  FilterContainer,
  FilterTitle,
  SearchIcon,
  JobForYouFilterTitle,
  Container,
  CloseBtnIcon,
  OkayBtnIcon,
} from "./styled";

export default class DropdownCompanies extends PureComponent {
  componentDidMount() {
    mountFilter("Companies");
  }

  componentWillUnmount() {
    unmountFilter("Companies");
  }

  render() {
    const {
      onFilterClose,
      companyNameFilter,
      onCompanyNameFilterTextChange,
      companiesToRender,
      onCompaniesFilterItemClick,
      onCompaniesFilterOkBtnClick,
      isUnmount,
    } = this.props;
    return ReactDOM.createPortal(
      <OutsideClickHandler onOutsideClick={onFilterClose}>
        <Container
          id="Companies"
          isUnmount={isUnmount}
          style={{ height: "300px" }}
        >
          <FilterContainer>
            <FilterTitle>COMPANIES</FilterTitle>
            <CloseBtn onClick={onFilterClose}>
              <CloseBtnIcon />
            </CloseBtn>
          </FilterContainer>
          <Search>
            <SearchIcon />
            <SearchInput
              placeholder="Search for Companies"
              value={companyNameFilter}
              onChange={onCompanyNameFilterTextChange}
            />
          </Search>
          <JobForYouFilterTitle>Selected Companies: </JobForYouFilterTitle>
          <FilterItem
            items={companiesToRender}
            onItemClick={onCompaniesFilterItemClick}
          />
          <Button onClick={onCompaniesFilterOkBtnClick}>
            <OkayBtnIcon /> Okay!
          </Button>
        </Container>
      </OutsideClickHandler>,
      document.getElementById("root-modal")
    );
  }
}
