import Component from './WhatOthersSay'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUserFeedbacks } from '../../../../../Redux/selectors/user'
import { updateFeedbackState } from '../../../../../Redux/actions/utils'

const selectors = createStructuredSelector({
  userFeedbacks: getUserFeedbacks
})

const actions = {
  onUpdateFeedbackState: updateFeedbackState
}

export const WhatOthersSay = connect(
  selectors,
  actions
)(Component)
