import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Spiner from 'react-spinkit'
import { Logo, Description } from '../blocks'
import {innerRoutes} from "../../../../../Constants/modalRoutes";

import { Window, TextBox, IconButton } from 'Components/Blocks'
import { Content, Form, InputContainer, BottomBlock } from './styled'

import * as validation from 'Constants/validation'
import {switchModal} from "../../../../../Redux/actions/utils";


export default class PasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      passwordWarning: false,
      cPasswordWarning: false
    }
  }

  componentDidMount() {
    const { onGetUserID } = this.props
    const splitPath = this.props.history.location.pathname.split('/')
    const id = splitPath[splitPath.length - 1]

    onGetUserID(id)
  }

  //eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { passwordChangeInfo } = nextProps
    if (passwordChangeInfo.isLoaded) {
      this.props.onClose()
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      passwordWarning: false,
      cPasswordWarning: false
    })

    const firstPassword = this.state.password
    const confirmPassword = this.state.confirmPassword

    const passValidated = validation.passwordValidate(
      firstPassword,
      confirmPassword
    )
    switch (passValidated) {
      case 'lengthError':
        toast.error('Password should include at lease 8 characters')
        this.setState({ passwordWarning: true })
        break
      case 'numberError':
        this.setState({ passwordWarning: true })
        toast.error('Password should include Number')
        break
      case 'loverCaseError':
        this.setState({ passwordWarning: true })
        toast.error('Password should include lover case letter')
        break
      case 'upperCaseError':
        this.setState({ passwordWarning: true })
        toast.error('Password should include Upper case character')
        break
      case 'passwordsNoMatch':
        this.setState({ cPasswordWarning: true })
        toast.error('Passwords does not match')
        break
      case 'Success':
        console.log('Success')
        this.setState({
          isLoading: true,
          passwordWarning: false,
          cPasswordWarning: false
        })

        const splitPath = this.props.history.location.pathname.split('/')
        const id = splitPath[splitPath.length - 1]

        const obgReq = {
          password: firstPassword,
          userIdentity: this.props.userId,
          identity: id
        }
        this.props.onChangePassword(obgReq)
        this.props.onSwitchModal(innerRoutes.confirmResetPassword)
        break
      default:
        break
    }
  }

  render() {
    const { passwordChangeInfo, onClose } = this.props
    const { passwordWarning, cPasswordWarning } = this.state

    return (
      <Window onClose={onClose}>
        <Content>
          <Logo onClose={onClose} />
          <Description>
            Nearly there! Just choose a new password and you’ll be on your way. Remember your new 
            password must be at least 8 characters long
          </Description>
          <Form>
            <InputContainer>
              <TextBox
                placeholder="$tr0ng password please"
                tabIndex="0"
                type="password"
                value={this.state.password}
                warning={passwordWarning}
                onChange={e => this.setState({ password: e.target.value })}
                onEnterPress={this.handleSubmit}
              />
            </InputContainer>
            <InputContainer>
              <TextBox
                placeholder="Confirm password please"
                type="password"
                value={this.state.confirmPassword}
                warning={cPasswordWarning}
                onChange={e =>
                  this.setState({ confirmPassword: e.target.value })
                }
                onEnterPress={this.handleSubmit}
              />
            </InputContainer>
          </Form>
          <BottomBlock>
            <IconButton
              icon="fas fa-check-circle"
              loading={passwordChangeInfo.isLoading}
              title="Okay"
              onClick={this.handleSubmit}
            />
          </BottomBlock>
        </Content>
      </Window>
    )
  }
}
