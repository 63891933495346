import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'

import { Element } from 'react-scroll'

import { Card } from '../blocks'
import { getAcademQualifImage } from '../../../../../Helpers/getImages'

import { Container, CardsList } from './styled'

export default class MyAcademicQualifications extends Component {
  static propTypes = {}

  constructor(props) {
    super()

    if (!props.academQulifList.length) props.onLoadAcademQualifList()
  }

  handleLoadCompanyImage = imageSrc => {
    return getAcademQualifImage(imageSrc)
  }

  renderCards = () => {
    const { userAcademQualif, academQulifList } = this.props

    if (userAcademQualif && userAcademQualif.length) {
      return userAcademQualif.map(item => {
        const element = academQulifList.find(
          el => el.id === item.acadm_qualf_id
        )
        return (
          <Element key={item.id} name={`academQualifId${item.id}`}>
            <Card
              description={item.description}
              endDate={item.end_date}
              faIcon={'fas fa-user-graduate'}
              id={item.id}
              image={element && getAcademQualifImage(element.image)}
              item={item}
              startDate={item.start_date}
              subTitle={item.sub_title}
              title={item.title}
              type={'academic'}
            />
          </Element>
        )
      })
    }
  }

  render() {
    return (
      <Container>
        <CardsList>{this.renderCards()}</CardsList>
      </Container>
    )
  }
}
