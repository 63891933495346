import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { CareerTimeLineBlock } from "../../../../Blocks/CareerTimeLineBlock";
import { UserEditInfoModal } from "../../../../Blocks/UserEditInfoModal";
import { AddButton } from "../blocks";
import { CardsList } from "./blocks/CardsList";
import { Input } from "antd";
import { isEmpty } from "lodash";
import swal from "sweetalert";

import { Container, ModalContainer, Icon, StyledInput } from "./styled";

export default class MyReferee extends PureComponent {
  static propTypes = {
    referee: PropTypes.array,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
  };

  state = {
    isDraft: false,
    name: "",
    company: "",
    designation: "",
    companyAddress: "",
    email: "",
    phoneNumber: "",
    isModalOpen: false,
    changeItemId: null,
    companyAddressError: false,
    designationError: false,
    companyError: false,
    phoneNumberError: false,
    nameError: false,
    emailError: false,
  };

  disableError = () => {
    this.setState({
      nameError: false,
      companyError: false,
      designationError: false,
      companyAddressError: false,
      emailError: false,
      phoneNumberError: false,
    });
  };

  handleCloseModal = () => {
    const { isDraft } = this.state;
    if (!isDraft) {
      this.confirmModal();
    } else {
      this.setState({
        name: "",
        company: "",
        designation: "",
        companyAddress: "",
        email: "",
        phoneNumber: "",
        isModalOpen: false,
        changeItemId: null,
      });
    }
  };
  closeMainModal = () => {
    this.setState({
      isModalOpen: false,
      changeItemId: null,
    });
  };
  confirmModal = () => {
    swal({
      icon: "warning",
      text: "Are you sure you want to discard changes to this ?",
      buttons: {
        confirm: {
          text: "Discard",
          value: true,
          visible: true,
        },
        cancel: {
          text: "No Thanks",
          value: false,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) this.closeMainModal();
    });
  };
  handleOpenModal = ({ id }) => {
    this.disableError();
    if (id) {
      const { referee } = this.props;

      const changedItem = referee.find((el) => el.id === id);
      this.setState({
        name: changedItem.name,
        company: changedItem.company,
        designation: changedItem.designation,
        companyAddress: changedItem.companyAddress,
        email: changedItem.email,
        phoneNumber: changedItem.phoneNumber,
        isModalOpen: true,
        changeItemId: id,
      });
    } else {
      this.setState({ isModalOpen: true });
    }
  };

  handleSaveClick = () => {
    const { onAdd, onEdit } = this.props;
    const {
      name,
      company,
      designation,
      companyAddress,
      email,
      phoneNumber,
      changeItemId,
    } = this.state;

    if (changeItemId) {
      if (
        !isEmpty(name) &&
        !isEmpty(email) &&
        !isEmpty(phoneNumber) &&
        !isEmpty(designation) &&
        !isEmpty(company) &&
        !isEmpty(companyAddress)
      ) {
        onEdit({
          id: changeItemId,
          name,
          company,
          designation,
          companyAddress,
          email,
          phoneNumber,
        });
        this.handleCloseModal();
      } else {
        if (isEmpty(name)) {
          this.setState({ nameError: true });
        }
        if (isEmpty(company)) {
          this.setState({ companyError: true });
        }
        if (isEmpty(designation)) {
          this.setState({ designationError: true });
        }
        if (isEmpty(companyAddress)) {
          this.setState({ companyAddressError: true });
        }
        if (isEmpty(email)) {
          this.setState({ emailError: true });
        }
        if (isEmpty(phoneNumber)) {
          this.setState({ phoneNumberError: true });
        }
      }
    } else {
      if (
        !isEmpty(name) &&
        !isEmpty(email) &&
        !isEmpty(phoneNumber) &&
        !isEmpty(designation) &&
        !isEmpty(company) &&
        !isEmpty(companyAddress)
      ) {
        onAdd({
          name,
          company,
          designation,
          companyAddress,
          email,
          phoneNumber,
        });
        this.handleCloseModal();
      } else {
        if (isEmpty(name)) {
          this.setState({ nameError: true });
        }
        if (isEmpty(company)) {
          this.setState({ companyError: true });
        }
        if (isEmpty(designation)) {
          this.setState({ designationError: true });
        }
        if (isEmpty(companyAddress)) {
          this.setState({ companyAddressError: true });
        }
        if (isEmpty(email)) {
          this.setState({ emailError: true });
        }
        if (isEmpty(phoneNumber)) {
          this.setState({ phoneNumberError: true });
        }
      }
    }
  };

  handleChangeStateByName = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value, [`${name}Error`]: false });
  };

  handleDeleteReferee = (id) => this.props.onDelete({ id });

  renderModal = () => {
    const { referee } = this.props;
    const {
      changeItemId,
      isModalOpen,
      name,
      company,
      designation,
      companyAddress,
      email,
      phoneNumber,
      companyError,
      companyAddressError,
      designationError,
      nameError,
      emailError,
      phoneNumberError,
    } = this.state;
    const item = referee.find((el) => el.id === changeItemId);

    if (isModalOpen) {
      return (
        <UserEditInfoModal
          title={`${changeItemId ? "Edit My" : "Add"} Referee`}
          type={"referee"}
          width={500}
          onCloseModal={this.handleCloseModal}
          onSaveClick={this.handleSaveClick}
          changeItemId={changeItemId}
          isMyReferee
          onDelete={() => this.handleDeleteReferee(changeItemId)}
          isDelete={true}
          dataState={this.state}
          dataProps={item ? item : {}}
          isReferee
        >
          <ModalContainer>
            <Input.Group>
              <StyledInput
                name={"name"}
                placeholder={"Name Ex. Jhon"}
                prefix={<Icon className={"fas fa-user"} />}
                value={name}
                onChange={this.handleChangeStateByName}
                error={nameError}
              />
              <StyledInput
                name={"company"}
                placeholder={"Organization Ex. Microsoft"}
                prefix={<Icon className={"fas fa-building"} />}
                value={company}
                onChange={this.handleChangeStateByName}
                error={companyError}
              />
              <StyledInput
                name={"designation"}
                placeholder={"Designation Ex.Project Manager"}
                prefix={<Icon className={"fas fa-briefcase"} />}
                value={designation}
                onChange={this.handleChangeStateByName}
                error={designationError}
              />
              <StyledInput
                name={"companyAddress"}
                placeholder={"Company Address Ex. 254 Galle Road Colombo 04"}
                prefix={<Icon className={"fas fa-map-marker-alt"} />}
                value={companyAddress}
                onChange={this.handleChangeStateByName}
                error={companyAddressError}
              />
              <StyledInput
                name={"email"}
                placeholder={"Email Ex. Jhon@microsoft.com"}
                prefix={<Icon className={"fas fa-envelope"} />}
                value={email}
                onChange={this.handleChangeStateByName}
                error={emailError}
              />
              <StyledInput
                maxLength={30}
                name={"phoneNumber"}
                placeholder={"Phone Number Ex.+94 0775378952"}
                prefix={<Icon className={"fas fa-phone"} />}
                value={phoneNumber}
                onChange={this.handleChangeStateByName}
                error={phoneNumberError}
              />
            </Input.Group>
          </ModalContainer>
        </UserEditInfoModal>
      );
    }
  };

  renderContent = () => {
    const { referee } = this.props;

    if (referee && referee.length) {
      return (
        <CardsList
          items={referee}
          onChangeItem={this.handleOpenModal}
          onDelete={this.handleDeleteReferee}
        />
      );
    }
  };

  render() {
    return (
      <CareerTimeLineBlock title={"My Referee"} type={"referee"}>
        <Container>
          <AddButton value={"Add Referee"} onClickAdd={this.handleOpenModal} />
          {this.renderContent()}
        </Container>
        {this.renderModal()}
      </CareerTimeLineBlock>
    );
  }
}
