import styled from 'styled-components'
import * as colors from '../../../../../../../Theme/colors'

import PerfectScrollbar from 'react-perfect-scrollbar'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  max-width: 400px;
  padding: 0 0 0 20px;
  @media screen and (max-width: 1200px) {
    padding: 0;
  }
`
export const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  color: ${colors.careerBlue};
`
export const List = styled(PerfectScrollbar)`
  margin: 5px 0 10px;
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 110px !important;
`
export const Item = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  color: #757575;
`
export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Circle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #757575;
`
export const Text = styled.span`
  margin: 0;
  padding: 0 0 0 10px;
  text-transform: capitalize;
`
export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`
