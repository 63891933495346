import { createAsyncAction } from "Helpers/redux";
import { tz } from "moment-timezone";
import { apiCall } from "./api";

export const LOAD_USER_JOBS = createAsyncAction("jobs/LOAD_USER_JOBS");
export const loadUserJobs = (filters) => {
  const timeZone = tz.guess();
  return apiCall({
    endpoint: `/jobs?filters=${JSON.stringify(
      filters || {}
    )}&timeZone=${timeZone}`,
    types: LOAD_USER_JOBS,
  });
};
export const LOAD_CURRENT_VACANCY = createAsyncAction(
  "jobs/LOAD_CURRENT_VACANCY"
);
export const loadCurrentVacancy = (url) => {
  return apiCall({
    endpoint: `/vacancies`,
    method: "GET",
    query: { url },
    types: LOAD_CURRENT_VACANCY,
  });
};

export const SET_ACTIVE = "jobs/SET_ACTIVE";
export const setActiveVacancy = (vacancy) => {
  return {
    type: SET_ACTIVE,
    payload: {
      data: vacancy,
    },
  };
};

export const DELETE_ACTIVE = "jobs/DELETE_ACTIVE";
export const deleteActiveVacancy = () => {
  return {
    type: DELETE_ACTIVE,
  };
};

export const LOAD_SAVED_VACANCIES = createAsyncAction(
  "jobs/LOAD_SAVED_VACANCIES"
);
export const loadSavedVacancies = () => {
  return apiCall({
    endpoint: "/vacancies/saved-vacancies",
    types: LOAD_SAVED_VACANCIES,
  });
};

export const SAVE_VACANCY = createAsyncAction("jobs/SAVE_VACANCY");
export const saveVacancy = (vacancyId, type) => {
  return apiCall({
    endpoint: "/vacancies/save-vacancy",
    method: "POST",
    query: {
      vacancyId,
      type,
    },
    types: SAVE_VACANCY,
  });
};

export const DELETE_FROM_SAVED_VACANCIES = createAsyncAction(
  "jobs/DELETE_FROM_SAVED_VACANCIES"
);
export const deleteFromSavedVacancies = (vacancyId) => {
  return apiCall({
    endpoint: "/vacancies/delete-from-saved",
    method: "POST",
    query: {
      vacancyId,
    },
    types: DELETE_FROM_SAVED_VACANCIES,
  });
};

export const UPDATE_FILTER = "jobs/UPDATE_FILTER";
export const updateFilter = (nextFilter) => {
  return {
    type: UPDATE_FILTER,
    payload: {
      data: nextFilter,
    },
  };
};

export const SEARCH_JOBS = createAsyncAction("jobs/SEARCH_JOBS");
export const searchJobs = (text) => {
  return apiCall({
    types: SEARCH_JOBS,
    endpoint: "/jobs/search",
    query: {
      text,
    },
  });
};
export const searchJobsByParams = (query) => {
  return apiCall({
    types: SEARCH_JOBS,
    endpoint: "/jobs/search/params",
    query,
    method: "POST",
  });
};

export const resetSearchJobs = () => {
  return {
    type: SEARCH_JOBS.SUCCESS,
    payload: {
      data: [],
    },
  };
};

export const CHANGE_JOB_TAB = "jobs/CHANGE_JOB_TAB";
export const changeJobTab = (tab) => ({
  type: CHANGE_JOB_TAB,
  tab,
});

export const RESET_JOBS = "jobs/RESET_JOBS";
export const resetJobs = () => ({
  type: RESET_JOBS,
});

export const GET_SALARY_RANGE = createAsyncAction("jobs/GET_SALARY_RANGE");
export const loadSalaryRange = () => {
  return apiCall({
    endpoint: "/jobs/salary-range",
    types: GET_SALARY_RANGE,
  });
};

export const GET_IP_LOCATION = createAsyncAction("jobs/GET_IP_LOCATION");

export const getIpGeolocation = () => {
  return apiCall({
    endpoint: `/jobs/ip-location`,
    types: GET_IP_LOCATION,
  });
};
