import { createSelector } from 'reselect'

const contactUsData = store => store.contactUs

export const getContactUs = createSelector(
  contactUsData,
  data => data.contactUs
)

export const getContactUsLoadInfo = createSelector(
  contactUsData,
  data => data.loadInfo
)