import styled from 'styled-components'

import * as colors from '../../../../../../Theme/colors'

export const AddButtonContainer = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  background: none;
  background-color: ${colors.careerBlue};
  color: #fff;
  border: none;
  border-radius: 20px;
  align-self: flex-start;
  padding: 5px 10px;
  margin: 0;
`
export const AddButtonIcon = styled.i.attrs({
  className: 'fas fa-plus-circle'
})`
  margin: 0 5px 0 0;
`
