import React from "react";
import { hydrate, render } from "react-dom";
import "./seeker/index.css";
import App from "./seeker/Components/App";

import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

// import { PersistGate } from 'redux-persist/integration/react'
// import createBrowserHistory from 'history/createBrowserHistory'
import { createBrowserHistory } from "history";
import CreateStore from "./seeker/Redux";

const { store } = CreateStore();
const history = createBrowserHistory();
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history} basename={"/uat007"}>
        <App />
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history} basename={"/uat007"}>
        <App />
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
}

// import registerServiceWorker from './registerServiceWorker'
// registerServiceWorker();
