import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import moment from "moment";
import swal from "sweetalert";
import { getCompanyBackground } from "Helpers/companyBackground";
import { Link } from "react-router-dom";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { jobs } from "../../../Constants/routes";
import { statuses } from "Constants/application";
import { friendSupport } from "Constants/routes";
import { detail, initial, redirectPage } from "../../../Constants/routes";

import {
  Container,
  TopContent,
  MiddleContent,
  BottomContent,
  TitleCompany,
  Title,
  Company,
  Icons,
  Icon,
  IconText,
  Interest,
  NoInterest,
  Logo,
  PercentConteiner,
  PercentText,
  Decline,
  FriedsSupport,
  FindJobs,
  percentStyles,
  ApplyBtn,
  SubTitleContainer,
  ChangeTimeBlock,
  TimeIcon,
} from "./styled";

import folder from "../../../images/type.png";
import location from "../../../images/location.png";
import finger from "../../../images/OppDoor/finger.png";
import bag from "../../../images/OppDoor/bag.png";

import "./style.css";
import "./load.js";

class OppBlock extends Component {
  static propTypes = {
    history: PropTypes.func.isRequired,
  };

  state = {
    showModal: false,
    friendList: [],
    email: "",
    reason: "",
  };

  onChangeEmail = (event) => this.setState({ email: event.target.value });

  handleDeclineBtnClick = () => {
    const { card, onApplicationDecline } = this.props;
    onApplicationDecline(card.id, null, this.state.reason);
  };

  handleApplyBtnClick = () => {
    const { card, onApplicationAccept } = this.props;

    onApplicationAccept(card.id);
  };

  handleFindJobsBtnClick = () => {
    // console.log('handleFindJobsBtnClick')
  };

  handleGetFriendSupportBtnClick = () => {
    // const { user, history } = this.props

    // history.push(`${friendSupport}/${user.identity}`)

    const { onFriendsSupportClick, card } = this.props;

    onFriendsSupportClick(card.id);
  };

  handleNoInterestBtnClick = () => {
    const { onNoInterestApplicationClick, card } = this.props;
    onNoInterestApplicationClick(card.id);
  };

  handleMessageBtnClick = () => {
    const { onMessageClick, user, card } = this.props;

    onMessageClick(user.id, card.contact_person_id);
  };

  handleCardClick = (card) => {
    // const { history, onSetActiveVacancy } = this.props;
    if (card.type === "post") {
      // onSetActiveVacancy(card);
      console.log(
        "`${detail}/${card.associated_link}`>>>",
        `${detail}/${card.associated_link}`
      );
      // history.push(`${detail}/${card.associated_link}`)
      window.open(`${detail}/${card.associated_link}`, "_blank");
    }
    if (card.type === "link") {
      const redirectData = {
        name: card.name,
        image: card.image,
        id: card.id,
        link: card.link,
      };
      sessionStorage.setItem("redirect_data", JSON.stringify(redirectData));
      // console.log("`${redirectPage}/${card.associated_link}`>>>", `${redirectPage}/${card.associated_link}`)
      window.open(`${redirectPage}/${card.associated_link}`, "_blank");
    }
  };
  // sweetFormAccess = () => {
  //   var x = document.getElementsByClassName("swal-text")[0];
  //   if(x) {
  //     x.style.backgroundColor = "red"
  //     console.log('sweetFormAccess')
  //   }
  //   swal({
  //     title: 'What is the reason?',
  //     content: 'input',
  //     button: {
  //       text: 'Decline!'
  //     },
  //     dangerMode: true
  //   }).then(willDelete => {
  //     if (willDelete) {
  //       this.setState({ reason: willDelete })
  //       this.handleDeclineBtnClick()
  //     } else {
  //       this.sweetFormDesible()
  //     }
  //   })
  // }

  sweetFormDesible = () => {
    var x = document.getElementsByClassName("swal-text")[0];
    if (x) {
      x.style.backgroundColor = "red";
      // console.log("sweetFormAccess");
    }
    swal({
      title: "What is the reason?",
      text: "Please, enter reason",
      className: "validation-text",
      content: "input",
      button: {
        text: "Decline!",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ reason: willDelete });
        this.handleDeclineBtnClick();
      } else {
        this.sweetFormDesible();
      }
    });
  };

  confirmModal = () => {
    swal({
      icon: "warning",
      text: "Are you sure you want to decline?",
      content: {
        element: "input",
        attributes: {
          placeholder: "Reason for decline",
          type: "text",
        },
      },
      buttons: {
        confirm: {
          text: "Decline",
          visible: true,
        },
        cancel: {
          text: "No thanks",
          value: "Cancel",
          visible: true,
        },
      },
    }).then((value) => {
      // if (value) {
      //   this.sweetFormAccess()
      // }
      if (value === "Cancel") {
      } else if (value) {
        this.setState({ reason: value });
        this.handleDeclineBtnClick();
      } else {
        this.sweetFormDesible();
      }
    });
  };

  confirmModalNotInterest = () => {
    // console.log("props ", this.props);
    swal({
      icon: "warning",
      text: "Are you sure?",
      buttons: {
        confirm: {
          text: "Confirm",
          value: true,
          visible: true,
        },
        cancel: {
          text: "No thanks",
          value: false,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) {
        this.handleDeclineBtnClick();
      }
    });
  };

  renderPitchedButtons = (vacancy) => {
    if (vacancy.applied_by === "company") {
      return (
        <BottomContent>
          <ApplyBtn width="auto" onClick={this.handleApplyBtnClick}>
            <i
              className="fas fa-check"
              style={{ width: "15px", height: "15px" }}
            />
          </ApplyBtn>
          <FriedsSupport onClick={this.handleGetFriendSupportBtnClick}>
            Get Friends Support{" "}
          </FriedsSupport>
          {/* <Decline width="auto" onClick={this.handleDeclineBtnClick}> */}
          <Decline width="auto" onClick={this.confirmModal}>
            <i
              className="fas fa-times"
              style={{ width: "15px", height: "15px" }}
            />
          </Decline>
        </BottomContent>
      );
    }

    return (
      <BottomContent>
        {/*<Decline onClick={this.handleDeclineBtnClick}>Decline</Decline>*/}
        <Decline onClick={this.confirmModal}>Decline</Decline>
        <FriedsSupport onClick={this.handleGetFriendSupportBtnClick}>
          Get Friends Support{" "}
        </FriedsSupport>
      </BottomContent>
    );
  };

  render() {
    const { card } = this.props;
    const logoSrc = getCompanyBackground(card.image);
    // console.log("card", card);
    // const logoSrc =
    //   _.isString(card.image) && card.image.length > 0
    //     ? card.image
    //     : defaultVacancyImageImg
    // console.log('card, card', card)
    // console.log('card=============>', card)
    return (
      <Container>
        <TopContent>
          <Logo src={logoSrc} />
          <PercentConteiner>
            <CircularProgressbar
              percentage={card.matchRate || 0}
              styles={percentStyles}
              text={`${card.matchRate || 0}%`}
            />
            <PercentText>Matching Score</PercentText>
          </PercentConteiner>
        </TopContent>
        <MiddleContent>
          <TitleCompany>
            <Title>{card.title}</Title>
            <SubTitleContainer>
              <Company>{card.company_name}</Company>
              <ChangeTimeBlock>
                <TimeIcon />
                {moment(card.created_at).format("MMM Do YY")}
              </ChangeTimeBlock>
            </SubTitleContainer>
          </TitleCompany>
          <Icons>
            <Icon>
              <img alt="indystry icon" src={bag} />
              <IconText title={card.industry_name}>
                {card.industry_name}
              </IconText>
            </Icon>
            <Icon>
              <img style={{ width: "65px" }} alt="job type icon" src={folder} />
              <IconText title={card.job_types ? card.job_types[0].name : ""}>
                {card.job_types[0].name}
              </IconText>
            </Icon>
            <Icon>
              <img alt="Location icon" src={location} />
              <IconText title={card.address}>
                {card.address}
              </IconText>
            </Icon>
            <Icon onClick={(e) => this.handleCardClick(card)}>
              <a>
                <img alt="View icon" src={finger} />
                <IconText title={card.company_website}>View</IconText>
              </a>
            </Icon>
          </Icons>
        </MiddleContent>
        {card.status === statuses.pitched && this.renderPitchedButtons(card)}
        {card.status === statuses.accepted && (
          <BottomContent>
            {/* <Interest onClick={this.handleMessageBtnClick}>
              <i className="fas fa-comments" /> Message
            </Interest> */}
            <NoInterest onClick={this.confirmModalNotInterest}>
              Not Interest
            </NoInterest>
          </BottomContent>
        )}
        {(card.status === statuses.inProgress ||
          card.status === statuses.reconsider ||
          card.status === statuses.shortListed) && (
          <BottomContent>
            {/* <Decline width={'65px'} onClick={this.handleDeclineBtnClick}> */}
            <Decline onClick={this.confirmModal}>Decline</Decline>
            <FriedsSupport onClick={this.handleGetFriendSupportBtnClick}>
              Get Friends Support{" "}
            </FriedsSupport>
            {/* <Interest
              onClick={this.handleMessageBtnClick}
              style={{ width: '35px' }}
            >
              <i className="fas fa-comments" />
            </Interest> */}
          </BottomContent>
        )}
        {card.status === statuses.declined && (
          <Link to={jobs}>
            <BottomContent>
              <FindJobs onClick={this.handleFindJobsBtnClick}>
                Find More Jobs Like This{" "}
                <i class="fas fa-chevron-circle-right" />
              </FindJobs>
            </BottomContent>
          </Link>
        )}
      </Container>
    );
  }
}

export default withRouter(OppBlock);
