import styled from 'styled-components'
import * as colors from '../../../../../../../Theme/colors'

export const Container = styled.section`
  margin: 10px 0;
  padding: 0 10px;
  min-height: 100px;
`

export const TextContainer = styled.p`
  margin: 0;
  cursor: pointer;
`

export const Title = styled.h2.attrs({ title: 'editing' })`
  font-size: 25px;
  color: ${colors.careerBlue};
`

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
