import Component from './MyReferee'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUserReferee } from '../../../../../Redux/selectors/user'
import {
  addReferee,
  editReferee,
  removeReferee
} from '../../../../../Redux/actions/referee'

const actions = {
  onAdd: addReferee,
  onEdit: editReferee,
  onDelete: removeReferee
}

const selectors = createStructuredSelector({
  referee: getUserReferee
})

export const MyReferee = connect(
  selectors,
  actions
)(Component)
