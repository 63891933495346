import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  @media screen and (max-width: 500px) {
  }
`
export const Input = styled.input`
  outline: none;
  border: none;
  background: none;
  font-size: 2em;
  width: 100%;
  max-width: 350px;
  height: 65px;
  color: ${colors.lightGreyFont};
  white-space: nowrap;
  text-overflow: ellipsis;
  &::placeholder {
    color: ${colors.lightGreyFont};
  }
  &:disabled {
    background: silver;
  }
  @media screen and (max-width: 500px) {
    font-size: 1em;
    width: 100%;
    height: 30px;
    padding: 5px;
  }
`
export const List = styled.ul`
  opacity: ${props => (props.open ? 1 : 0)};
  transform: translateY(${props => (props.open ? 0 : '20%')});
  transition: all 0.3s;
  list-style: none;
  margin: 5px 0 0;
  position: absolute;
  max-height: 200px;
  width: 320px;
  background-color: white;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 10px 0;
  border-radius: 5px;
  z-index: 2;
  &::-webkit-scrollbar {
    background-color: #dbd9d9;
    width: 3px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
`
export const Item = styled.li`
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.mainBlue};
    color: white;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const LoaderContainer = styled.li`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
const anim = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)
  }
`
export const Loader = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  margin-top: 20px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${colors.mainBlue};
  border-right: 1px solid ${colors.mainBlue};
  animation: 2s ${anim} infinite ease-in-out;
`
export const InputContainer = styled.div`
  display: flex;
  height: 100%;
`
export const InputIconContainer = styled.div`
  border-left: 1px dashed silver;
  width: 80px;
  height: 100%;
  background: #fff;
  color: ${colors.mainBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  @media screen and (max-width: 500px) {
    font-size: 1em;
    width: 40px;
  }
`
export const LoadingIcon = styled.i.attrs({
  className: 'far fa-compass'
})`
  ${props => {
    if (props.active) {
      return `animation: 2s ${anim} infinite ease-in-out; color: silver`
    }
  }};
`
