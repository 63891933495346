import styled from 'styled-components'
import { notificationRed } from '../../../Theme/colors'
import { justifyContent, flexDirection, alignItems } from 'styled-system'

export const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '999999'
  },
  content: {
    top: 'calc((100vh - 250px) / 2)',
    left: 'calc((100vw - 600px) / 2)',
    background: '#fff',
    color: '#000',
    border: 'none',
    height: '250px',
    width: '600px',
    zIndex: '999999',
    padding: '0'
  }
}
export const modalStyleMobile = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '999999'
  },
  content: {
    top: 'calc((100vh - 250px) / 2)',
    background: '#fff',
    color: '#000',
    border: 'none',
    height: '250px',
    zIndex: '999999',
    padding: '0',
    width: '100%',
    left: 0,
    right: 0,
    bottom: 0
  }
}

export const Header = styled.div`
  height: 85px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  font-size: 22px;
  font-weight: bold;
`

export const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: ${props => props.padding || '0 56px 0 50px'};
  margin-top: 85px;
  margin-bottom: 106px;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1 0;
  padding: 0 56px 25px 50px;
  background-color: #fff;
  z-index: 2;

  height: ${props => props.height}px;

  ${justifyContent} ${flexDirection} ${alignItems};
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  outline: none;
  background-color: #fff;
  color: #000;
`

export const StyledClose = styled.img`
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 16px;
  padding: 18px;
  height: 50px;
  width: 50px;

  &:hover {
    color: #23527c;
  }
`

export const Text = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
`

export const Button = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  overflow: visible;
  text-transform: none;
  box-shadow: none;

  background: ${props => (props.outline ? '#fff' : notificationRed)};
  border: 2px solid ${props => (props.outline ? '#000' : notificationRed)};
  color: ${props => (props.outline ? '#000' : '#fff')};
`
