import React, { PureComponent, Fragment } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import OutsideClickHandler from "react-outside-click-handler";
import { mountFilter } from "../../../Helpers/mountFilter";
import { unmountFilter } from "../../../Helpers/unmountFilter";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { distanceValue } from "../../../Constants/distanceValue";
import {
  Search,
  SearchInput,
  LocationList,
  CityOption,
  Button,
  Loader,
  CloseBtn,
  FilterContainer,
  SearchIcon,
  FilterTitle,
  CheckedCitiesIcon,
  Container,
  CloseBtnIcon,
  OkayBtnIcon,
  ButtonDisableLocation,
  DistanceFilter,
  DistanceIcon,
  KmsButtonContainer,
  KmsButtonTitle,
  Kms,
  disableLocation,
  DisableLocationText,
} from "./styled";
import Switch from "react-switch";
export default class DropdownLocation extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      address: "",
      locations: [],
      modalInitWarning: {
        view: false,
        enable: false,
      },
    };
  }

  componentDidMount() {
    mountFilter("Location");
  }

  componentWillUnmount() {
    unmountFilter("Location");
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { locations } = this.state;

    onSubmit(locations);
  };

  handleChangeSearch = (address) => {
    this.setState({ address });
  };

  handleDeleteLocation = (placeId) => {
    const { locations: prevLocations } = this.state;
    const locations = prevLocations.filter((item) => item.placeId !== placeId);
    if (!locations.length) {
      this.props.handeleDisableLocation(true);
    }
    this.setState({ locations });
  };

  handleChangeDisable = () => {
    this.setState({ disableLocation: !this.state.disableLocation });
  };

  handleSelect = (address, placeId) => {
    const { modalInitWarning } = this.state;
    const { toggleDisableOutsideClick } = this.props;
    this.props.handeleDisableLocation(false);
    if (modalInitWarning.enable) {
      toggleDisableOutsideClick();
      this.setState({
        modalInitWarning: {
          view: true,
          enable: true,
        },
      });
      swal({
        icon: "warning",
        text: "we use your location and radius 20km for better results",
        timer: 4000,
        button: true,
      }).then(() => {
        toggleDisableOutsideClick();
        this.setState({
          modalInitWarning: {
            view: false,
            enable: false,
          },
        });
      });
    }
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((coords) => {
        const locations = this.state.locations.concat({
          name: address,
          placeId: placeId,
          lat: coords.lat,
          lng: coords.lng,
        });
        this.setState({ locations, address: "" });
      })
      .catch((error) => console.error("Error", error));
  };

  renderSearch = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }) => {
    const { locations } = this.state;

    return (
      <Fragment>
        <Search>
          {loading ? <Loader /> : <SearchIcon />}
          <SearchInput
            {...getInputProps({
              placeholder: "Town, City, Country",
            })}
          />
        </Search>
        {(suggestions.length && !loading) || locations.length ? (
          <LocationList maxHeight={locations.length > 2 && !suggestions.length}>
            <ul style={{ padding: 5, marginBottom: "10px" }}>
              {locations.length
                ? locations
                    .filter((item) => item.name != "null")
                    .map((city) => {
                      return (
                        <CityOption
                          active
                          key={city.placeId}
                          title={city.name}
                          onClick={() =>
                            this.handleDeleteLocation(city.placeId)
                          }
                        >
                          <CheckedCitiesIcon />
                          {city.name}
                        </CityOption>
                      );
                    })
                : null}
              {suggestions.map((city, index) => {
                return (
                  <CityOption
                    {...getSuggestionItemProps(city)}
                    key={city.placeId}
                    title={city.description}
                  >
                    <CheckedCitiesIcon />
                    {city.description}
                  </CityOption>
                );
              })}
            </ul>
          </LocationList>
        ) : null}
      </Fragment>
    );
  };

  render() {
    const {
      isUnmount,
      onFilterClose,
      handeleDisableLocation,
      distanceFromLocation,
      onDistanceDecrementBtnClick,
      onDistanceIncrementBtnClick,
      disableLocation,
    } = this.props;
    const { modalInitWarning } = this.state;
    return ReactDOM.createPortal(
      <OutsideClickHandler
        disabled={modalInitWarning.view}
        onOutsideClick={onFilterClose}
      >
        <Container id="Location" isUnmount={isUnmount} location>
          <FilterContainer>
            <FilterTitle>LOCATION</FilterTitle>
            <CloseBtn onClick={onFilterClose}>
              <CloseBtnIcon />
            </CloseBtn>
          </FilterContainer>
          <PlacesAutocomplete
            value={this.state.address}
            // value={this.state.address}
            onChange={this.handleChangeSearch}
            onSelect={this.handleSelect}
          > 
            {this.renderSearch}
          </PlacesAutocomplete>
          {/* <DistanceFilter>
            <DistanceIcon
              className="fas fa-chevron-circle-up"
              disabled={disableLocation}
              onClick={onDistanceIncrementBtnClick}
            />
            <KmsButtonContainer>
              <KmsButtonTitle className="dist_p">Within</KmsButtonTitle>
              <Kms>{distanceValue[distanceFromLocation]}</Kms>{" "}
              <KmsButtonTitle>Kms</KmsButtonTitle>
            </KmsButtonContainer>
            <DistanceIcon
              className="fas fa-chevron-circle-down"
              disabled={disableLocation}
              onClick={onDistanceDecrementBtnClick}
            />
          </DistanceFilter> */}
          {/* <ButtonDisableLocation>
            <Switch
              width={40}
              height={20}
              onColor="#583ea1"
              onChange={() => handeleDisableLocation(!disableLocation)}
              checked={disableLocation}
            />
            <DisableLocationText>Disable Location</DisableLocationText>
          </ButtonDisableLocation> */}
          <Button onClick={this.handleSubmit}>
            <OkayBtnIcon /> Okay!
          </Button>
        </Container>
      </OutsideClickHandler>,
      document.getElementById("root-modal")
    );
  }
}
