import React from 'react'
//import PropTypes from 'prop-types'

import { Icon } from './styled'

const StarIcon = () => {
  return <Icon />
}

//StarIcon.propTypes = {}

export { StarIcon }
