import React, { PureComponent } from 'react'
import FeedbackTimeLine from '../../Blocks/FeedbackTimeLine'
import * as routes from 'Constants/routes'

export default class UserCareerTimeLine extends PureComponent {
  constructor(props) {
    super()
    const { match, history, onTimelineLoad } = props
    const { userIdentity } = match.params
    if (!userIdentity) {
      history.push(routes.initial)
      return
    }

    onTimelineLoad(userIdentity)
  }
  render() {
    const { timeline } = this.props

    return <FeedbackTimeLine data={timeline.experience} />
  }
}
