import React, { PureComponent, Fragment } from 'react'

import { innerRoutes } from '../../../Constants/modalRoutes'

export default class ResetPassword extends PureComponent {
  constructor(props) {
    super()

    const {
      match: {
        params: { id }
      },
      onCheckPasswordLink
    } = props

    if (id) {
      onCheckPasswordLink(id)
    }
   // props.onSwitchModal(innerRoutes.resetPassword)
  }

  componentDidUpdate(prevProps){
    const {isChecked, isExpiredLink, onSwitchModal, onCheckPassClearData} = this.props
    if(isChecked && !prevProps.isChecked){
      if(isExpiredLink){
        onSwitchModal(innerRoutes.expirePassword)
      } else {
        onSwitchModal(innerRoutes.resetPassword)
      }
    }
  }

  render() {
    return <Fragment />
  }
}
