import React from "react";
import moment from "moment";
import {
  Company,
  Container,
  Icon,
  IconsContainer,
  IconText,
  percentStyles,
  CardContainer,
  CardImage,
  DescriptionContainer,
  IconImage,
  SocialMediaContainer,
  SocialMediaIcons,
  Title,
  MatchRateContainer,
  SubInfo,
  TimeIcon,
  CreateDate,
} from "./styled";

import CircularProgressbar from "react-circular-progressbar";

import type from "../../../images/type.png";
import location from "../../../images/location.png";
import topImg from "../../../images/OppDoor/finger.png";

export const DetailCarouselCard = ({
  isAuthenticate,
  value,
  background,
  renderShare,
  onClickLink,
  onClickMatchRate,
}) => {
  // console.log("test", value);
  return (
    <CardContainer key={value.id}>
      <Container>
        <SocialMediaContainer>
          <CardImage
            alt={`vacancy ${value.title} image`}
            src={background}
            onClick={onClickLink}
          />
          <SocialMediaIcons onClick={(e) => e.stopPropagation()}>
            {renderShare(value)}
          </SocialMediaIcons>
        </SocialMediaContainer>
        <DescriptionContainer>
          <Title title={value.title} onClick={onClickLink}>
            {value.title}
          </Title>
          <SubInfo onClick={onClickLink}>
            <Company>{value.company_name || value.link_company_name}</Company>
            <CreateDate>
              <TimeIcon />
              {moment(value.start_date).fromNow()}
            </CreateDate>
          </SubInfo>
          {value.type === "post" ? (
            <IconsContainer>
              <MatchRateContainer
                isAuth={isAuthenticate}
                onClick={onClickMatchRate}
              >
                <CircularProgressbar
                  percentage={value.matchRate || 0}
                  styles={percentStyles}
                  text={`${value.matchRate || 0}%`}
                />
                <div style={{ color: "#757575", fontSize: "8px" }}>
                  Suitable
                  <br />
                  To You
                </div>
              </MatchRateContainer>
              <Icon onClick={onClickLink}>
                <IconImage
                  alt={`${
                    value.job_types.length
                      ? value.job_types[0].name
                      : "job type"
                  } icon`}
                  src={type}
                />
                <IconText>
                  {value.job_types.length ? value.job_types[0].name : ""}
                </IconText>
              </Icon>
              <Icon
                title={
                  value.address.length ? value.address : value.company_address
                }
                onClick={onClickLink}
              >
                <IconImage
                  alt={`location ${
                    value.address.length ? value.address : value.company_address
                  } icon`}
                  src={location}
                />
                <IconText ellipsis>
                  {value.address.length ? value.address : value.company_address}
                </IconText>
              </Icon>
              <a href={`${value.company_website}`} target="_blank">
                <Icon>
                  <IconImage alt="company website icon" src={topImg} />
                  <IconText>View</IconText>
                </Icon>
              </a>
            </IconsContainer>
          ) : (
            <IconsContainer>
              <Icon>
                <IconImage alt="company website icon" src={topImg} />
                <IconText>View</IconText>
              </Icon>
            </IconsContainer>
          )}
        </DescriptionContainer>
      </Container>
    </CardContainer>
  );
};
