import Component from './Privacy'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadPrivacy } from 'Redux/actions/privacy'

import { getPrivacy, getPrivacyLoadInfo } from 'Redux/selectors/privacy'

const actions = {
  onLoadPrivacy: loadPrivacy
}

const selector = createStructuredSelector({
  privacyList: getPrivacy,
  privacyLoadInfo: getPrivacyLoadInfo
})

export default connect(
  selector,
  actions
)(Component)
