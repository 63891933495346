import { apiCall } from './api'
import { createAsyncAction } from 'Helpers/redux'

export const SET_CHOOSEN_CATEGOREIS = 'SET_CHOOSEN_CATEGOREIS'
export const setChoosenCategories = data => ({
  type: SET_CHOOSEN_CATEGOREIS,
  ...data
})

export const SET_LOCATION = 'SET_LOCATION'
export const setLocation = ({ location, kms, lat, lng }) => ({
  type: SET_LOCATION,
  location,
  kms,
  lat,
  lng
})

export const LINKEDIN_REGISTER = createAsyncAction('register/LINKEDIN')
export const linkedinRegister = () => {
  return apiCall({
    endpoint: `/auth/linkedin`,
    types: LINKEDIN_REGISTER
  })
}

export const RESET_OAUTH_USER = 'register/RESET_OAUTH_USER'
export const resetOauthUser = () => {
  return {
    type: RESET_OAUTH_USER
  }
}

export const OAUTH_UESR_SET = 'register/OAUTH_UESR_SET'
export const oauthUserSet = user => {
  return {
    type: OAUTH_UESR_SET,
    payload: {
      data: user
    }
  }
}

export const PARSE_CV = createAsyncAction('register/PARSE_CV')
export const parseCV = data => {
  return apiCall({
    endpoint: '/registration/parse-cv',
    method: 'POST',
    query: data,
    types: PARSE_CV
  })
}

export const SAVE_CV_INFO = createAsyncAction('register/SAVE_CV_INFO')
export const saveCVInfo = data => {
  return apiCall({
    endpoint: '/education',
    method: 'POST',
    query: data,
    types: SAVE_CV_INFO
  })
}
