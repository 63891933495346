import styled from 'styled-components'
import * as colors from 'Theme/colors'

export const Container = styled.div`
  border-radius: 15px;
  border: 1px solid ${colors.stepGrey};
  margin: 10px 5px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  position: relative;
`
export const TextInfo = styled.textarea`
  border: none;
  outline: none;
  flex: 1 200px;
  padding: 10px;
`
export const RangepickerContainer = styled.div`
  margin: 10px 0;
`
export const TextBoxContainer = styled.div`
  margin: 5px 5px 0 5px;
`
export const CloseBtn = styled.div`
  color: ${colors.lightGreyFont};
  font-size: 18px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
`