import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'

import {CardEditLabel, CardImage} from '../../../blocks'

import {Container, CardContent, Title, Block, Text, Wrap, EditIcon } from './styled'

import referenceIcon from '../../../../../../../images/reference-icon.jpg'

export default class Card extends PureComponent {
  static propTypes = {
    company: PropTypes.string,
    companyAddress: PropTypes.string,
    designation: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    onChangeItem: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
  }

  state = {
    isShowEditLabel: false
  }

  handleShowEditLabel = () => this.setState({isShowEditLabel: true})

  handleHideEditLabel = () => this.setState({isShowEditLabel: false})

  handleClickCard = () => {
    this.setState(prevState => ({
      isShowEditLabel: !prevState.isShowEditLabel
    }))
  }

  render() {
    const {
      name,
      company,
      designation,
      companyAddress,
      email,
      phoneNumber,
      id,
      onDelete,
      onChangeItem
    } = this.props
    const {isShowEditLabel} = this.state

    return (
      <Container
        onClick={this.handleClickCard}
        // onMouseOut={this.handleHideEditLabel}
        // onMouseOver={this.handleShowEditLabel}
      >
        <CardImage image={referenceIcon}/>
        <CardContent>
          <Wrap>
            <Title>{name}</Title>
            <EditIcon
              className={'fas fa-edit'}
              onClick={() => onChangeItem({ id })}
            />
          </Wrap>
          <Block>
            <Text>{company}</Text>
          </Block>
          <Block>
            <Text>{designation}</Text>
          </Block>
          <Block>
            <Text>{companyAddress}</Text>
          </Block>
          <Block>
            <Text>{email}</Text>
          </Block>
          <Block>
            <Text>{phoneNumber}</Text>
          </Block>
          {/*<CardEditLabel
            isOpen={isShowEditLabel}
            onFirstButtonClick={() => onChangeItem({ id })}
            onSecondButtonClick={() => onDelete({ id })}
          />*/}
        </CardContent>
      </Container>
    )
  }
}
