import React, { PureComponent } from 'react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { postJobLink } from '../../../Config/App'
import success from 'Assets/images/success1.png'
import resetPassword from 'Assets/images/password-reset-100.png'
import confirmResPas from 'Assets/images/icons8-show-password-100.png'

import {
  SwitchWindow,
  LoginWindow,
  SearchWindow,
  LocationWindow,
  CvWindow,
  DetailsWindow,
  EmailWindow,
  SuccessWindow,
  ParsedWindow,
  PasswordReset,
  ResendWindow,
  ExpirePasswordWindow
} from './inner_pages'

import {
  Container,
  Content,
  ActionsListContainer,
  ActionsListLogo,
  ActionsListHeader,
  ActionsList,
  ActionListItem,
  ActionListButtons,
  ActionListButton
} from './styles'
import qs from 'qs'

import { innerRoutes } from '../../../Constants/modalRoutes'
import { initial, login, registration } from '../../../Constants/routes'

const url = process.env.PUBLIC_URL
export default class Initial extends PureComponent {
  //eslint-disable-next-line
  componentDidMount() {
    const {
      location,
      onTokenRehydrate,
      onOauthUserSet,
      onSetUserData
    } = this.props

    onTokenRehydrate()

    const query = qs.parse(location.search)

    if (query['?user']) {
      const data = JSON.parse(query['?user'])

      if (data && data.status && data.status === 'register') {
        onOauthUserSet(data.user)
      } else {
        onSetUserData(data)
      }
    } else if (query['?error_message']) {
      const error = JSON.parse(query['?error_message'])

      if (error.message) {
        toast.error(error.message)
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState

    const { register } = nextProps

    if (register.isOauthRegisterInProcess) {
      // if (!prevState.isModalOpen) {
      //   nextProps.utils.window = 3
      // }

      if (!register.isOauthRegisterInProcess) {
        nextProps.onCloseModal()
      }
    }

    return nextState
  }

  closeModal = () => {
    const { register, onResetOauthUser, location, history } = this.props
    if (register.oauthUser) {
      onResetOauthUser()
    }
    this.props.onCloseModal()

    const ext = window.location.href.match(
      /(login)|(signup)|(passwordreset)|(restore-password)|(forgot-password)/gi
    )
    if (ext) {
      history.push(initial)
    }
  }

  renderActionList = () => {
    return (
      <ActionsListContainer>
        <ActionsListLogo>LOGO</ActionsListLogo>
        <ActionsListHeader> Hi, Welcome to MrJOBS</ActionsListHeader>
        <ActionsListHeader> What would you like to do?</ActionsListHeader>
        <ActionsList>
          <ActionListItem
            onClick={() => this.props.onSwitchModal(innerRoutes.switch)}
          >
            Show suitable Jobs For Me
          </ActionListItem>
          <ActionListItem>Let companies Bid For me</ActionListItem>
          <ActionListItem>Show Me All Job Categories</ActionListItem>
          <ActionListItem>Search MrJOBS</ActionListItem>
          <ActionListItem>My Opportunity Door</ActionListItem>
          <ActionListItem>Create/edit my Career Timeline</ActionListItem>
          <ActionListItem>Read Company Reviews</ActionListItem>
        </ActionsList>
        <ActionListButtons>
          <ActionListButton>
            <i className="fas fa-chevron-up" />
          </ActionListButton>
          <ActionListButton>
            <i className="fas fa-chevron-down" />
          </ActionListButton>
        </ActionListButtons>
      </ActionsListContainer>
    )
  }

  renderWindow = () => {
    const {
      utils,
      onSwitchModal,
      history,
      onLinkedinAuth,
      resetUserPassword
    } = this.props

    switch (utils.window) {
      case innerRoutes.init:
        return this.renderActionList()
      case innerRoutes.switch:
        return (
          <SwitchWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
            onLinkedinAuthClick={onLinkedinAuth}
          />
        )
      case innerRoutes.login:
        return (
          <LoginWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.search:
        return (
          <SearchWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.location:
        return (
          <LocationWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.cv:
        return (
          <CvWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.details:
        return (
          <DetailsWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.email:
        return (
          <EmailWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.success:
        return (
          <SuccessWindow
            source={success}
            bigText="Registration completed successfully"
            smallText={
              'Please check your registered email for email verification.'
            }
            history={history}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.successResetPassword:
        const email = localStorage.getItem('email')
        let newEmailArr, newEmail
        if (email) {
          newEmailArr = email.split('@')
          newEmail = `${newEmailArr[0][0]}...@${newEmailArr[1]}`
        }
        return (
          <SuccessWindow
            source={resetPassword}
            bigText="Password Reset Email Sent"
            smallText={`An email has been sent to your rescue email address, “${newEmail}”. Follow the directions in the email to rest your password`}
            history={history}
            onClose={this.closeModal}
            resetUserPassword={resetUserPassword}
            isResetPassword={true}
          />
        )
      case innerRoutes.confirmResetPassword:
        return (
          <SuccessWindow
            source={confirmResPas}
            bigText="Password Reset Successful"
            smallText={
              'Awesome. You’ve successfully updated your password. Now that you’re yourself again, check if your profile data is correct.'
            }
            link={'Login'}
            onSwitchModal={onSwitchModal}
            history={history}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.parsed:
        return (
          <ParsedWindow
            changeWindow={onSwitchModal}
            history={history}
            innerRoutes={innerRoutes}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.resetPassword:
        return <PasswordReset history={history} onClose={this.closeModal} />
      case innerRoutes.resendEmail:
        return (
          <ResendWindow
            data={this.props.register.expiriedData}
            history={history}
            loadInfo={this.props.register.loadInfo}
            onClose={this.closeModal}
            isEmailConfirmed={this.props.register.isEmailConfirmed}
          />
        )
      case innerRoutes.resendLogiEmail:
        return (
          <ResendWindow
            data={this.props.user.confirmedData}
            history={history}
            loadInfo={{ loading: false, success: true }}
            onClose={this.closeModal}
          />
        )
      case innerRoutes.expirePassword:
        return (
          <ExpirePasswordWindow
            bigText="Password Reset Link Expired"
            link={'Login'}
            onSwitchModal={onSwitchModal}
            history={history}
            onClose={this.closeModal}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { children, utils } = this.props
    return (
      <Container id="rootScrollContainer">
        <ToastContainer style={{ zIndex: 99999 }} />
        {children}
        {utils.isModalOpen && (
          <Content isOpen={utils.isModalOpen}>{this.renderWindow()}</Content>
        )}
      </Container>
    )
  }
}
