import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { createLink } from "Helpers/links";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { detail, redirectPage } from "../../../Constants/routes";
import { UserEditInfoModal } from "../../Blocks/UserEditInfoModal";
import {
  ModalBackground,
  Container,
  Header,
  Title,
  CloseBtn,
  Content,
  ButtonsBlock,
  UrlBlock,
  UrlBlockLeftContent,
  UrlBlockTitle,
  UrlBlockInput,
  UrlBlockCopyIcon,
  ShareIcon,
  FacebookLink,
  LinkedinLink,
  TwitterLink,
  WhatsappLink,
  EmailLink,
} from "./styled";

export default class ShareModal extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    id: PropTypes.any.isRequired,
    onCloseModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    description: "",
  };

  constructor(props) {
    super();
    this.state = {
      value: window.location.href,
      isCopied: false,
      shareUrl: sessionStorage.getItem("redirect_data"),
    };
  }
  componentDidMount() {
    const { onCloseModal, description, id, card, associated_link } = this.props;
    var url = window.location.origin;
    const { shareUrl } = this.state;
    var x = sessionStorage.getItem("redirect_data");
    this.setState({ shareUrl: url + x });
  }
  handleChangeLink = (e) => {
    const { value } = e.target;
    this.setState({ value, isCopied: false });
  };

  renderContent = () => {
    const { onCloseModal, description, id, card, associated_link } = this.props;
    const { value, isCopied, shareUrl } = this.state;
    const shareLink = `${detail}/${associated_link}`;

    return (
      <UserEditInfoModal
        title="Share with friends"
        isCenteredTitle
        width={430}
        onCloseModal={onCloseModal}
        noSaveBtn
        idSocial
        dataProps={{}}
        dataState={{}}
      >
        <Container onClick={(e) => e.stopPropagation()}>
          <Content>
            <ButtonsBlock>
              <FacebookLink quote={description} url={shareUrl}>
                <ShareIcon className="fab fa-facebook-f" />
              </FacebookLink>
              <LinkedinLink url={shareUrl}>
                <ShareIcon className="fab fa-linkedin-in" />
              </LinkedinLink>
              <WhatsappLink url={shareUrl}>
                <ShareIcon className="fab fa-whatsapp" />
              </WhatsappLink>
              <TwitterLink url={shareUrl}>
                <ShareIcon className="fab fa-twitter" />
              </TwitterLink>
              <EmailLink url={shareUrl}>
                <ShareIcon className="fas fa-envelope-open" />
              </EmailLink>
            </ButtonsBlock>
            <UrlBlock>
              <UrlBlockLeftContent onClick={() => this.linkInput.focus()}>
                <UrlBlockTitle>Job post URL</UrlBlockTitle>
                <UrlBlockInput
                  innerRef={(el) => (this.linkInput = el)}
                  type="text"
                  disabled={true}
                  value={shareUrl}
                  onChange={this.handleChangeLink}
                />
              </UrlBlockLeftContent>
              <CopyToClipboard
                text={shareUrl}
                onCopy={() => this.setState({ isCopied: true })}
              >
                <UrlBlockCopyIcon
                  isCopied={isCopied}
                  title={isCopied ? "copied" : "copy"}
                />
              </CopyToClipboard>
            </UrlBlock>
          </Content>
        </Container>
      </UserEditInfoModal>
    );
  };

  render() {
    return ReactDOM.createPortal(
      this.renderContent(),
      document.getElementById("root-modal")
    );
  }
}
