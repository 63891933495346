import Component from './Messages'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getUserMessages,
  sendMessage,
  changeMessageStatus,
  connectRoom
} from 'Redux/actions/chat'

import { getMessages, getChannels } from 'Redux/selectors/chat'
import { getUserEmail } from 'Redux/selectors/user'

const selectors = createStructuredSelector({
  messages: getMessages,
  userEmail: getUserEmail,
  channels: getChannels
})

const actions = {
  onGetUserMessage: getUserMessages,
  onSendMessage: sendMessage,
  onChangeRoom: connectRoom,
  onChangeMessageStatus: changeMessageStatus
}

export default connect(
  selectors,
  actions
)(Component)
