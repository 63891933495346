import React, { Component } from "react";

import _ from "lodash";
import map from "lodash/map";
import MediaQuery from "react-responsive";
import PerfectScrollbar from "perfect-scrollbar";
import withSizes from "react-sizes";

import { jobs } from "Constants/routes";

import {
  Container,
  IconImg,
  TitleContainer,
  Jobs,
  Wraper,
  Follow,
  CategoryImg,
  BlankFollow,
  EmptyCard,
  Title,
} from "./styled";

import "./style.css";

import { Content } from "../../Pages/Categories/style";

class Profession extends Component {
  change(proff, e) {
    console.log("🚀 ~ file: Profession.js ~ line 30 ~ Profession ~ change ~ proff, e", proff, e)
    const {
      isAuthenticate,
      onFollowIndustry,
      onChangeJobTab,
      history,
    } = this.props;

    if (!isAuthenticate) {
      onFollowIndustry(proff, true);
      onChangeJobTab(proff, true);

      const route = proff.title
        .split(/[\s/]+/)
        .join("_")
        .toLowerCase();

      history.push(`${jobs}/${route}`);
      return;
    }

    if (!proff.isFollowed) {
      this.props.onFollowIndustry(proff);
    } else {
      this.props.onUnfollowIndustry(proff);
    }
  }
  navi(proff, e) {
    const {
      isAuthenticate,
      onFollowIndustry,
      onChangeJobTab,
      history,
    } = this.props;

    const route = proff.title
      .split(/[\s/]+/)
      .join("_")
      .toLowerCase();

    history.push(`${jobs}/${route}`);
    return;
  }

  generateProff() {
    const { profession, isAuthenticate, userFollowedIndustries } = this.props;

    const newProf = {
      icon: "http://localhost:3000/images/industries/1623783131748-all.png",
      title: "All",
      jobs: "10 Jobs Available",
      id: 999,
    };
    const newArray = [newProf].concat(profession);

    return map(newArray, (value, key) => {
      let followClassname = false;
      let followBtnText = "Follow";

      const isFollowed =
        _.find(
          userFollowedIndustries,
          (ind) => ind.industry_id === value.id
        ) !== undefined;

      if (isFollowed) {
        followClassname = true;
        followBtnText = "Unfollow";
      }

      return (
        <React.Fragment>
          <Wraper>
            <IconImg
              style={{ cursor: "pointer" }}
              key={value.id}
              onClick={(e) => {
                this.navi({ ...value, isFollowed }, e);
              }}
            >
              <CategoryImg alt={value.title} src={value.icon} />
              <div>
                <TitleContainer>
                  {value.title === "All" ? (
                    <Title>{value.title} Industries </Title>
                  ) : (
                    <Title>{value.title}</Title>
                  )}
                </TitleContainer>
              </div>
            </IconImg>
            <div style={{ cursor: "pointer" }} key={value.id}>
              <Jobs>{value.jobs}</Jobs>
              {isAuthenticate ? (
                <div>
                  {value.title === "All" ? (
                    ""
                  ) : (
                    <Follow
                      onClick={(e) => {
                        this.change({ ...value, isFollowed }, e);
                      }}
                      follow={followClassname}
                      id={value.id}
                    >
                      {value.title === "All Industries" ? "" : followBtnText}
                      {/* {followBtnText} */}
                    </Follow>
                  )}
                </div>
              ) : (
                <BlankFollow />
              )}
            </div>
          </Wraper>
          {profession.length % 3 === 0 && !profession[key + 1] && (
            <MediaQuery maxDeviceWidth={500} minDeviceWidth={321}>
              <EmptyCard />
            </MediaQuery>
          )}
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <Content id="categoriesScrollContainer">
        <Container>{this.generateProff()}</Container>
      </Content>
    );
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500,
});

export default withSizes(mapSizeToProps)(Profession);
