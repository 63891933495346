import { createReducer } from 'Helpers/redux'

import { LOAD_PROFILE, UPLOAD_FEEDBACK } from 'Redux/actions/friendSupport'

const initialState = {
  userProfile: {},
  loadInfo: {
    isLoading: false,
    isLoaded: false,
    error: null
  },
  uploadLoadInfo: {
    isLoading: false,
    isLoaded: false,
    error: null
  }
}

const handlers = {
  [LOAD_PROFILE.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoaded: false,
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD_PROFILE.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoaded: false,
        isLoading: false,
        error: payload.data
      }
    }
  },
  [LOAD_PROFILE.SUCCESS]: (state, { payload: { data } }) => {
    const { userProfile, timeLine } = data
    return {
      ...state,
      userProfile,
      timeLine,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  },
  [UPLOAD_FEEDBACK.REQUEST]: state => {
    return {
      ...state,
      uploadLoadInfo: {
        isLoaded: false,
        isLoading: true,
        error: null
      }
    }
  },
  [UPLOAD_FEEDBACK.SUCCESS]: state => {
    return {
      ...state,
      uploadLoadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  },
  [UPLOAD_FEEDBACK.FAILURE]: state => {
    return {
      ...state,
      uploadLoadInfo: {
        isLoaded: false,
        isLoading: false,
        error: true
      }
    }
  }
}

export default createReducer(initialState, handlers)
