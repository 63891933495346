import { createSelector } from "reselect";

const highSchoolEdData = store => store.highSchoolEd;

export const getPredictionsHighSchoolEd = createSelector(
  highSchoolEdData,
  data => data.highSchoolEd
);

export const getHighSchoolEdPredictionsLoadInfo = createSelector(
  highSchoolEdData,
  data => data.loadInfo
);
