import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Component from './MyPositions'
import { loadCompanies } from 'Redux/actions/companies'

import { getUserExperience } from 'Redux/selectors/publicUser'
import { getCompanies } from 'Redux/selectors/companies'

const actions = {
  onLoadCompanies: loadCompanies
}

const selector = createStructuredSelector({
  experience: getUserExperience,
  companies: getCompanies
})

export const MyPositions = connect(
  selector,
  actions
)(Component)
