import { careerTimeLine, notification } from 'Constants/routes'

export const categoreis = [
  // {
  //   title: 'NOTIFICATIONS',
  //   route: notification,
  //   id: 0,
  //   type: 'link'
  // },
  {
    title: 'CAREER TIMELINE',
    route: careerTimeLine,
    id: 1,
    type: 'link'
  }
]
