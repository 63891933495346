import React, { PureComponent, useEffect } from "react";
import { Window, IconBlock } from "Components/Blocks";
import { identificationCard, cursor } from "Assets/icons";
import linkedin from "Assets/images/linkedin.png";
import mrJobs_SignUp from "Assets/images/mrJobs_SignUp.png";
import mrJobs_signIn from "Assets/images/mrJobs_signIn.png";
import { Description, Logo } from "../blocks";
import { Content, List, ListItem, ListText } from "./styles";
import * as colors from "Theme/colors";
import { Loader, LoaderContainer } from "./styled";

export default class SwitchWindow extends PureComponent {
  componentDidMount() {
    window.addEventListener("load", console.log("hello"));
  }
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  handleImageLoaded() {
    setTimeout(() => this.setState({ loaded: true }), 1000);
  }

  render() {
    const {
      changeWindow,
      innerRoutes,
      onClose,
      onLinkedinAuthClick,
    } = this.props;
    const { paginLoadInfo } = this.state;
    // console.log(paginLoadInfo);
    // if (!paginLoadInfo.isLoading) {
    //   return (
    //     <LoaderContainer>
    //       <Loader />
    //     </LoaderContainer>
    //   );
    // }
    const { loaded } = this.state;
    // const imageStyle = !loaded ? { display: "none" } : {};

    return (
      <Window height="580px" onClose={onClose}>
        <Content>
          <Logo />
          <Description>Select your option</Description>
          <List>
            <ListItem onClick={() => changeWindow(innerRoutes.search)}>
              <IconBlock borderColor={"none"}>
                {/* {identificationCard} */}

                <img
                  style={{
                    height: "80px",
                    width: "80px",
                    fontSize: "10px",
                  }}
                  alt="MrJOBS SignUp"
                  // src="https://images.unsplash.com/photo-1628438273202-a26e785d044f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2382&q=80"
                  src={mrJobs_SignUp}
                />
              </IconBlock>
              <ListText>Sign up</ListText>
            </ListItem>
            <ListItem>
              {/* <ListItem onClick={onLinkedinAuthClick}> */}
              <IconBlock borderColor={"none"}>
                <img
                  style={{ height: "80px", fontSize: "10px", width: "80px" }}
                  alt="MrJOBS Linkedin"
                  src={linkedin}
                />
              </IconBlock>
              <ListText>Coming Soon </ListText>
              {/* <ListText>I will use my Linkedin profile</ListText> */}
            </ListItem>
            <ListItem onClick={() => changeWindow(innerRoutes.login)}>
              <IconBlock borderColor={"none"}>
                {/* {cursor} */}

                <img
                  style={{ height: "80px", fontSize: "10px", width: "80px" }}
                  alt="MrJOBS SignIn"
                  src={mrJobs_signIn}
                />
              </IconBlock>
              <ListText>Sign in</ListText>
            </ListItem>
          </List>
        </Content>
      </Window>
    );
  }
}
