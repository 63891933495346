import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import {
  TopBar,
  NavigationContainer,
  TopItemCircle,
  TopItemContainer,
} from "./styled";
import ReactTooltip from "react-tooltip";

class CareerNavigation extends PureComponent {
  state = {
    activeLabel: null,
  };

  renderContent = () => {
    const {
      activeCategory,
      onCategoryClick,
      isBigScreen,
      isShow,
      feedbacks,
    } = this.props;

    return (
      <NavigationContainer isBigScreen={isBigScreen} show={isShow}>
        <TopBar id={"careerTopScrollNavigation"}>
          <TopItemContainer onClick={() => onCategoryClick("whoAmI")}>
            <TopItemCircle
              data-tip
              data-for="whoAmI"
              active={activeCategory === "whoAmI"}
              className={"fas fa-user"}
            />
            <ReactTooltip id="whoAmI" backgroundColor="#583ea1" place="left">
              <span>WHO I AM</span>
            </ReactTooltip>
          </TopItemContainer>
          <TopItemContainer onClick={() => onCategoryClick("positions")}>
            <TopItemCircle
              data-tip
              data-for="positions"
              active={activeCategory === "positions"}
              className={"fas fa-building"}
            />
            <ReactTooltip id="positions" backgroundColor="#583ea1" place="left">
              <span>MY EXPERIENCE</span>
            </ReactTooltip>
          </TopItemContainer>

          <TopItemContainer onClick={() => onCategoryClick("qualifications")}>
            <TopItemCircle
              data-tip
              data-for="qualifications"
              active={activeCategory === "qualifications"}
              className={"fas fa-user-graduate"}
            />
            <ReactTooltip id="qualifications" backgroundColor="#583ea1" place="left">
              <span>MY QUALIFICATIONS</span>
            </ReactTooltip>
          </TopItemContainer>
          <TopItemContainer onClick={() => onCategoryClick("imGoodAt")}>
            <TopItemCircle
              data-tip
              data-for="imGoodAt"
              active={activeCategory === "imGoodAt"}
              className={"fas fa-chess-knight"}
            />
            <ReactTooltip id="imGoodAt" backgroundColor="#583ea1" place="left">
              <span>I'M GOOD AT</span>
            </ReactTooltip>
          </TopItemContainer>
          {feedbacks && feedbacks.length ? (
            <TopItemContainer onClick={() => onCategoryClick("feedbacks")}>
              <TopItemCircle
                data-tip
                data-for="feedbacks"
                active={activeCategory === "feedbacks"}
                className={"fas fa-users"}
              />
              <ReactTooltip id="feedbacks" backgroundColor="#583ea1" place="left">
                <span>feedbacks</span>
              </ReactTooltip>
            </TopItemContainer>
          ) : null}
          <TopItemContainer onClick={() => onCategoryClick("activities")}>
            <TopItemCircle
              active={activeCategory === "activities"}
              className={"fas fa-trophy"}
              data-tip
              data-for="activities"
            />
            <ReactTooltip id="activities" backgroundColor="#583ea1" place="left">
              <span>ACHIEVEMENTS & ACTIVITIES</span>
            </ReactTooltip>
          </TopItemContainer>
          <TopItemContainer onClick={() => onCategoryClick("referee")}>
            <TopItemCircle
              data-tip
              data-for="referee"
              active={activeCategory === "referee"}
              className={"fas fa-sync-alt"}
            />
            <ReactTooltip id="referee" backgroundColor="#583ea1" place="left">
              <span>MY REFEREE</span>
            </ReactTooltip>
          </TopItemContainer>
          <TopItemContainer onClick={() => onCategoryClick("timeLine")}>
            <TopItemCircle
              data-tip
              data-for="timeLine"
              active={activeCategory === "timeLine"}
              className={"fas fa-clock"}
            />
            <ReactTooltip id="timeLine" backgroundColor="#583ea1" place="left">
              <span>TIME LINE</span>
            </ReactTooltip>
          </TopItemContainer>
        </TopBar>
      </NavigationContainer>
    );
  };

  render() {
    const { isBigScreen } = this.props;
    if (isBigScreen) {
      return ReactDOM.createPortal(
        this.renderContent(),
        document.getElementById("sidebarContainer")
      );
    } else {
      return this.renderContent();
    }
  }
}

CareerNavigation.propTypes = {
  activeCategory: PropTypes.string,
  onCategoryClick: PropTypes.func.isRequired,
};

const mapSizeToProps = ({ width }) => ({
  isBigScreen: width > 1600,
});

export default withSizes(mapSizeToProps)(CareerNavigation);
