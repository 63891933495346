import React, { Component } from "react";
import ReactDOM from "react-dom";
import PerfectScrollbar from "perfect-scrollbar";
import {
  MenuImgContainer,
  MenuImg,
  SliderBarLink,
  IconTitle,
  Circle,
  ProfileName,
  ProfileMessage,
  LinkUnauthorized,
  AvatarImage,
  ImgMobile,
  MenuItem,
  SidebarPushable,
  Sidebar,
  SidebarPusher,
  Navbar,
  NavbarHeader,
  NavbarToggle,
  SidebarBackground,
  ContentContainer,
  SliderBarLinkBlock,
} from "../styled";
import * as routes from "Constants/routes";
import { SearchModal } from "../../Header/innerBlocks/SearchModal";

import { Link } from "react-router-dom";

import { HeaderCategoriesList } from "../../../Blocks";

import show from "../../../../images/menu/show.png";
import jobs from "../../../../images/menu/jobs.png";
import opp from "../../../../images/menu/opp.png";
import mess from "../../../../images/menu/mess.png";
import notUserAvatar from "../../../../Assets/images/notUserAvatar.jpg";
import { NotificationsIcon } from "../../../Blocks";

import "../style.css";
import modalRoutes from "Constants/modal_routes";
import { api } from "Config/App";
import { careerTimeLine, notification } from "../../../../Constants/routes";
import { getAvatarSrc } from "../../../../Helpers/avatar";
import { oppdoor } from "Constants/routes";

export class LayoutMobile extends Component {
  componentDidMount() {
    const { notScroll } = this.props;
    new PerfectScrollbar("#sidebarScrollContainer--mobile");

    if (!notScroll) {
      new PerfectScrollbar("#Push");
    }
  }

  handleAvatarClick = () => {
    const { history, openModal, isAuthenticate } = this.props;
    if (isAuthenticate) {
      history.push(careerTimeLine);
    } else {
      openModal(modalRoutes.login);
    }
  };

  handleClickSungUp = () => {
    const { openModal, onToggleVisibility } = this.props;
    onToggleVisibility();
    openModal(modalRoutes.switch);
  };

  handleClickAvatar = () => {
    const { onToggleVisibility, openModal } = this.props;
    onToggleVisibility();
    openModal(modalRoutes.login);
  };

  handleLinkClick = () => {
    const { onToggleVisibility, onLinkWithAuthClick } = this.props;

    onToggleVisibility();
    onLinkWithAuthClick();
  };

  handleClickNotifications = (event) => {
    event && event.stopPropagation();
    this.props.history.push(notification);
  };

  renderSettingsButtons = () => {
    const { settingsList, history } = this.props;
    const { pathname: currentPath } = history.location;
    const arr = settingsList.map((el) => {
      const link = `${routes.settings}${el.url}`;
      return (
        <Link
          key={el.url}
          style={{ marginBottom: "10px", textDecoration: "none" }}
          to={link}
        >
          <SliderBarLink selected={currentPath === link}>
            {el.title.toUpperCase()}
          </SliderBarLink>
        </Link>
      );
    });
    return arr;
  };

  renderSidebar = () => {
    const {
      visible,
      openModal,
      history,
      user,
      isAuthenticate,
      onOpenSearch,
      unreadChatsCount,
      renderLogInLogOut,
      onToggleVisibility,
      settingsLoadInfo,
      postJob,
    } = this.props;
    const ContainerWithAuth = isAuthenticate ? Link : LinkUnauthorized;

    const avatar =
      this.props.user.avatar && this.props.user.avatar.length > 0
        ? getAvatarSrc(this.props.user.avatar)
        : notUserAvatar;

    // console.log(avatar);

    const { pathname: currentPath } = history.location;

    return (
      <Sidebar
        id="sidebarScrollContainer--mobile"
        visible={visible}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem>
          <MenuImgContainer>
            <Circle size={75} onClick={this.handleAvatarClick}>
              <AvatarImage alt="avatar" size={75} src={avatar} />
              <NotificationsIcon history={history} />
            </Circle>
            <ProfileName>{isAuthenticate ? user.name : "Log In"}</ProfileName>
          </MenuImgContainer>
        </MenuItem>
        <MenuItem className="first-menu-block">
          {/* <MenuItem>
            <MenuImgContainer onClick={onOpenSearch}>
              <MenuImg>
                {" "}
                <i
                  className="fas fa-search-plus"
                  style={{ fontSize: "40px", color: "#6d71f7" }}
                />
              </MenuImg>
              <IconTitle>Search</IconTitle>
            </MenuImgContainer>
          </MenuItem> */}

          <MenuItem>
            <Link to={routes.initial}>
              <MenuImgContainer>
                <MenuImg selected={currentPath === routes.initial}>
                  <ImgMobile alt="Show All Industry" src={show} />
                </MenuImg>
                <IconTitle selected={currentPath === routes.initial}>
                  All Industries
                </IconTitle>
              </MenuImgContainer>
            </Link>
          </MenuItem>

          <MenuItem>
            <Link to={routes.jobs}>
              <MenuImgContainer>
                <MenuImg
                  selected={
                    currentPath === routes.jobs ||
                    currentPath.match(routes.detail) ||
                    currentPath.match(routes.jobs)
                  }
                >
                  <ImgMobile alt="Jobs For You" src={jobs} />{" "}
                </MenuImg>
                <IconTitle
                  selected={
                    currentPath === routes.jobs ||
                    currentPath.match(routes.detail) ||
                    currentPath.match(routes.jobs)
                  }
                >
                  Jobs For You
                </IconTitle>
              </MenuImgContainer>
            </Link>
          </MenuItem>
          <MenuItem>
            <ContainerWithAuth
              to={routes.oppdoor}
              onClick={this.handleLinkClick}
            >
              <MenuImgContainer>
                <MenuImg
                  selected={
                    currentPath.match(oppdoor) ||
                    currentPath.match(routes.oppdoor)
                  }
                >
                  {" "}
                  <ImgMobile alt="Opportunity Door" src={opp} />{" "}
                </MenuImg>
                <IconTitle
                  selected={
                    currentPath.match(oppdoor) ||
                    currentPath.match(routes.oppdoor)
                  }
                >
                  Opportunity Door
                </IconTitle>
              </MenuImgContainer>
            </ContainerWithAuth>
          </MenuItem>
          {/* <MenuItem>
            <ContainerWithAuth
              to={routes.messages}
              onClick={this.handleLinkClick}
            >
              <MenuImgContainer>
                <MenuImg selected={currentPath === routes.messages}>
                  {' '}
                  <ImgMobile alt="Messages" src={mess} />{' '}
                </MenuImg>
                <IconTitle>Messages</IconTitle>
                {unreadChatsCount > 0 && isAuthenticate ? (
                  <ProfileMessage mobile style={{ bottom: '107px' }}>
                    <p style={{ position: 'relative', top: 6 }}>
                      {unreadChatsCount}
                    </p>
                  </ProfileMessage>
                ) : null}
              </MenuImgContainer>
            </ContainerWithAuth>
          </MenuItem> */}

          <MenuItem>
            <SliderBarLinkBlock>
              <Link style={{ marginBottom: "10px" }} to={routes.contactUs}>
                <SliderBarLink selected={currentPath === routes.contactUs}>
                  CONTACT US
                </SliderBarLink>
              </Link>
              <SliderBarLink onClick={() => postJob(isAuthenticate)}>
                POST A JOB
              </SliderBarLink>

              {!isAuthenticate && (
                <SliderBarLink onClick={this.handleClickSungUp}>
                  SIGN UP
                </SliderBarLink>
              )}
              {renderLogInLogOut()}
              {!settingsLoadInfo.loading && this.renderSettingsButtons()}
            </SliderBarLinkBlock>
          </MenuItem>
          <MenuItem>
            <div style={{ color: "black" }}>
              <p>Mr.Jobs 2018</p>
            </div>
          </MenuItem>
        </MenuItem>
      </Sidebar>
    );
  };

  render() {
    const {
      children,
      history,
      isSearchModalOpen,
      userJobsInfo,
      userJobs,
      onCloseSearch,
      notScroll,
      handleScroll,
      onToggleVisibility,
      onSearchByParams,
      onSearch,
      visible,
      settingsList,
    } = this.props;
    return (
      <React.Fragment>
        <Navbar disabled={visible}>
          <NavbarHeader>
            <HeaderCategoriesList
              activeHeaderCategory={this.props.activeHeaderCategory}
              header={this.props.header}
              history={this.props.history}
              onDeleteBtnClick={this.props.onDeleteBtnClick}
              onHeaderCategoryClick={this.props.onHeaderCategoryClick}
              settingsList={settingsList}
            />
            <NavbarToggle disabled={visible} onClick={onToggleVisibility} />
          </NavbarHeader>
        </Navbar>
        <ContentContainer>
          <SidebarPushable>
            {ReactDOM.createPortal(
              this.renderSidebar(),
              document.getElementById("root-modal")
            )}
            <SidebarPusher
              id="Push"
              notScroll={notScroll}
              onScroll={handleScroll}
            >
              <SearchModal
                history={history}
                isOpen={isSearchModalOpen}
                searchResult={userJobs.Search}
                userJobsInfo={userJobsInfo}
                onClose={onCloseSearch}
                onSearch={onSearch}
                onSearchByParams={onSearchByParams}
              />
              {children}
            </SidebarPusher>
          </SidebarPushable>
          {visible
            ? ReactDOM.createPortal(
                <SidebarBackground onClick={onToggleVisibility} />,
                document.getElementById("root-modal")
              )
            : null}
        </ContentContainer>
      </React.Fragment>
    );
  }
}
