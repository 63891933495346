import React, { PureComponent } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import { rehydrateToken, setUserData } from "Redux/actions/auth";
import { mountChannels } from "Redux/actions/chat";
import { loadNotifications } from "Redux/actions/notifications";
import { oauthUserSet } from "Redux/actions/register";

import { location } from "Redux/selectors/router";
import { isAuthenticate } from "Redux/selectors/auth";
import { isChannelsMounted } from "Redux/selectors/chat";

import * as routes from "Constants/routes";

import Initial from "./Pages/Initial";
import Categories from "./Pages/Categories";
import Jobs from "./Pages/Jobs";
import Detail from "./Pages/DetailPage";
import CareerTimeLine from "./Pages/CareerTimeLine";
import Opportunity from "./Pages/Opportunity";
import Notification from "./Pages/Notification";
import Messages from "./Pages/Messages";
import FriendSupport from "./Pages/FeedbackOnCandidate";
import LinkReducer from "./Pages/LinkReducer";
import UserCareerTimeLine from "./Pages/UserCareerTimeLine";
import ResetPassword from "./Pages/ResetPassword";
import EmailConfirmation from "./Pages/EmailConfirmation";
import LoginPage from "./Pages/Login";
import RestorePassword from "./Pages/RestorePassword";

import RegistrationPage from "./Pages/Registration";
// import EditCV from './Pages/EditCV'
import RedirectPage from "./Pages/RedirectPage";
import ContactUs from "./Pages/ContactUs";
import Settings from "./Pages/Settings";
import Privacy from "./Pages/Privacy";
import PublicCareerTimeLine from "./Pages/PublicCareerTimeLine";

const trackingId = "UA-164052201-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
const history = createBrowserHistory();

history.listen((location) => {
  // console.log("location>>>>", location);
  ReactGA.set({ page: location.pathname });
  var Item = localStorage.getItem("candidate_id");
  ReactGA.pageview(location.pathname);
  // console.log("hey saveed", Item);

  if (window.location.pathname === "/seeker/jobs-for-you/saved" && !Item) {
    // history.push("/seeker/login");
    window.location.reload();
  }
});

ReactPixel.init("229053031520756", {}, { debug: true, autoConfig: false });
ReactPixel.pageView();
ReactPixel.fbq("track", "PageView");
class App extends PureComponent {
  constructor(props) {
    super(props);

    const { onTokenRehydrate } = props;

    onTokenRehydrate();
  }

  // componentDidMount() {
  //   const {
  //     location,
  //     onTokenRehydrate,
  //     onOauthUserSet,
  //     onSetUserData
  //   } = this.props
  //
  //   onTokenRehydrate()
  //
  //   const query = qs.parse(location.search)
  //
  //   if (query['?user']) {
  //     const data = JSON.parse(query['?user'])
  //
  //     if (data && data.status && data.status === 'register') {
  //       onOauthUserSet(data.user)
  //     } else {
  //       onSetUserData(data)
  //     }
  //   } else if (query['?error_message']) {
  //     const error = JSON.parse(query['?error_message'])
  //
  //     if (error.message) {
  //       toast.error(error.message)
  //     }
  //   }
  // }

  //eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isAuthenticate, isChannelsMounted } = nextProps;
    if (isAuthenticate && !isChannelsMounted) {
      this.props.onMountChannels();
    }
  }

  componentWillUnmount() {
    clearInterval(this.notificationsLoad);
  }

  componentDidMount() {
    const { isAuthenticate } = this.props;
    const channel = new BroadcastChannel("timer_sync");
    // channel.postMessage("timeNow");
    const script = document.createElement("script");

    script.src = "//code-eu1.jivosite.com/widget/KEz97aE1hv";
    script.async = true;

    document.body.appendChild(script);
 
    channel.addEventListener("message", (event) => {
  
      if (event.data === "timeNow") {
        history.push("/seeker/");
        window.location.reload();
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (
      window.location.pathname === "/seeker/login" ||
      window.location.pathname === "/seeker/signup"
    ) {
      if (!isAuthenticate) {
        history.push("/seeker/");
        window.location.reload();
      }
    }
  }

  render() {
    const { location } = this.props;
    return (
      <Initial {...this.props}>
        <Router history={history}>
          {/* <Switch location={location}> */}
          <Switch>
            <Route
              component={ResetPassword}
              path={`${routes.passwordreset}/:id`}
            />
            <Route component={RedirectPage} path={`${routes.redirectPage}`} />
            <Route component={ContactUs} exact path={routes.contactUs} />
            <Route component={Settings} path={routes.settings} />
            <Route component={LoginPage} path={routes.login} />
            <Route component={RestorePassword} path={routes.restorePassword} />
            <Route component={RegistrationPage} path={routes.registration} />
            <Route component={Jobs} path={`${routes.jobs}`} />
            <Route component={Categories} exact path={routes.categories} />
            <Route component={Detail} path={`${routes.detail}/:url`} />
            <Route component={Opportunity} path={`${routes.oppdoor}`} />
            <Route component={Notification} exact path={routes.notification} />
            <Route
              component={FriendSupport}
              exact
              path={`${routes.friendSupport}/:userIdentity`}
            />
            <Route component={LinkReducer} path={routes.link_reducer} />
            <Route
              component={UserCareerTimeLine}
              exact
              path={`${routes.timeline}/:userIdentity`}
            />
            <Route
              component={EmailConfirmation}
              exact
              path={`${routes.emailConfirmation}/:identity`}
            />
            <Route
              component={PublicCareerTimeLine}
              exact
              path={`${routes.candidateProfile}/:canId`}
            />
            <Route
              component={CareerTimeLine}
              exact
              path={routes.careerTimeLine}
            />
            <Route component={Messages} exact path={routes.messages} />
            <Redirect to="/seeker" />
          </Switch>
        </Router>
      </Initial>
    );
  }
}

const actions = {
  onTokenRehydrate: rehydrateToken,
  onMountChannels: mountChannels,
  onLoadNotifications: loadNotifications,
  onOauthUserSet: oauthUserSet,
  onSetUserData: setUserData,
};

const selector = createStructuredSelector({
  location,
  isAuthenticate,
  isChannelsMounted,
});

export default connect(selector, actions)(App);
