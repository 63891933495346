import styled, { keyframes } from 'styled-components'
import * as colors from '../../../Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'
export const Input = styled.input.attrs({ type: 'text', autofocus: 'autofocus' })`
  padding: 13px;
  font-size: 16px;
  border: 1px solid ${colors.stepGrey};
  border-radius: 9px;
  outline: none;
  width: 100%;
`
export const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`
export const Container = styled.div`
  position: relative;
  width: 80%;
`
export const LocationList = styled.div`
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 2;
  width: 250px;
  background-color: #fff;
  border: solid 1px ${colors.stepGrey};
  border-radius: 9px;
`

export const CityItem = styled.li`
  margin: 2px 0 0;
  padding: 2px 0 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    //todo hover color items
  }
`
export const ScrollbarContainer = styled(PerfectScrollbar).attrs({
  component: 'ul'
})`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-left: 10px;
`
const animation = keyframes`
  to {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
`
export const Loader = styled.i.attrs({ className: 'far fa-compass' })`
  animation: 2s infinite ease-in-out ${animation};
  animation-play-state: ${props => (props.active ? 'running' : 'paused')};
  color: ${props => (props.active ? 'silver' : '#00B7FE')};
`
export const InputSubText = styled.p`
  width: 85%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -27px;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`
