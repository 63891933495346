import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import RichTextEditor from 'react-rte'
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'

import { UserEditInfoModal } from '../../../../../Blocks/UserEditInfoModal'
import { Autocompleate, CardImage } from '../../blocks'

import {
  Container,
  Image,
  FieldsContainer,
  Input,
  TextEditor,
  AutocompleteContainer,
  WithAutocompleate,
  RangeContainer,
  RangeText,
  RangeButton,
  RangeButtonContainer,
  RangeButtonText
} from './styled'
import './styles.css'

class ChangeModal extends PureComponent {
  constructor(props) {
    super()
    const {
      title,
      subTitle,
      description: itemDescription,
      startDate,
      endDate,
      image
    } = props.item

    const description = itemDescription
      ? RichTextEditor.createValueFromString(itemDescription, 'html')
      : RichTextEditor.createEmptyValue()

    this.state = {
      title,
      subTitle,
      description,
      startDate: moment(startDate).format('YYYY-MM'),
      endDate: moment(endDate).format('YYYY-MM'),
      image,
      isAutocompleateShow: false,
      autocompleateItemId: null
    }
  }

  handleAMonthChange = value => {
    const { from, to } = value

    const startDate = `${moment([from.year, from.month]).format('YYYY-MM')}`
    const endDate = `${moment([to.year, to.month]).format('YYYY-MM')}`

    this.setState({ startDate, endDate })
  }

  handleSetNowDate = () => {
    this.setState({ endDate: moment().format('YYYY-MM') })
  }

  handleChangeStateByName = ({ target }) => {
    const { name, value } = target
    const { withAutocompleate } = this.props
    switch (withAutocompleate) {
      case 'title':
        if (name === 'title') {
          this.setState({
            [name]: value,
            isAutocompleateShow: true,
            autocompleateItemId: null,
            image: null
          })
        } else {
          this.setState({ [name]: value })
        }
        break
      case 'subTitle':
        if (name === 'subTitle') {
          this.setState({
            [name]: value,
            isAutocompleateShow: true,
            autocompleateItemId: null,
            image: null
          })
        } else {
          this.setState({ [name]: value })
        }
        break
      default:
        this.setState({ [name]: value })
    }
  }

  handleDescriptionChange = description => this.setState({ description })

  handleSaveClick = () => {
    const { onSave } = this.props
    const {
      title,
      subTitle,
      description,
      startDate,
      endDate,
      autocompleateItemId
    } = this.state

    const saveObj = {
      title,
      subTitle,
      description: description.toString('html'),
      startDate,
      endDate,
      autocompleateItemId
    }

    onSave(saveObj)
  }

  handleAutocompleateSelect = ({ value, id }) => {
    const {
      onLoadAutocompleateItemImage,
      autocompleateItems,
      withAutocompleate
    } = this.props

    const item = autocompleateItems.find(el => el.id === id)

    this.setState(prevState => ({
      title: withAutocompleate === 'title' ? value : prevState.title,
      subTitle: withAutocompleate === 'subTitle' ? value : prevState.subTitle,
      autocompleateItemId: id,
      isAutocompleateShow: false,
      image: onLoadAutocompleateItemImage
        ? onLoadAutocompleateItemImage(item.image)
        : null
    }))
  }

  renderComponentsWithAutocompleate = () => {
    const {
      titleInputPlaceholder,
      subTitleInputPlaceholder,
      withAutocompleate
    } = this.props
    const { title, subTitle } = this.state

    switch (withAutocompleate) {
      case 'title':
        return (
          <Fragment>
            <WithAutocompleate>
              <Input
                name={'title'}
                placeholder={titleInputPlaceholder}
                value={title}
                onChange={this.handleChangeStateByName}
              />
              {this.renderAutocompleate()}
            </WithAutocompleate>
            <Input
              name={'subTitle'}
              placeholder={subTitleInputPlaceholder}
              value={subTitle}
              onChange={this.handleChangeStateByName}
            />
          </Fragment>
        )
      case 'subTitle':
        return (
          <Fragment>
            <Input
              name={'title'}
              placeholder={titleInputPlaceholder}
              value={title}
              onChange={this.handleChangeStateByName}
            />
            <WithAutocompleate>
              <Input
                name={'subTitle'}
                placeholder={subTitleInputPlaceholder}
                value={subTitle}
                onChange={this.handleChangeStateByName}
              />
              {this.renderAutocompleate()}
            </WithAutocompleate>
          </Fragment>
        )
      default:
        return (
          <Fragment>
            <Input
              name={'title'}
              placeholder={titleInputPlaceholder}
              value={title}
              onChange={this.handleChangeStateByName}
            />
            <Input
              name={'subTitle'}
              placeholder={subTitleInputPlaceholder}
              value={subTitle}
              onChange={this.handleChangeStateByName}
            />
          </Fragment>
        )
    }
  }

  renderAutocompleate = () => {
    const { autocompleateItems, withAutocompleate } = this.props
    if (!autocompleateItems || !autocompleateItems.length) return

    const { title, subTitle, isAutocompleateShow } = this.state

    const autocompleateField = withAutocompleate === 'title' ? title : subTitle

    const parsedCompanies = autocompleateItems.filter(
      item =>
        item.name.toLowerCase().indexOf(autocompleateField.toLowerCase()) > -1
    )
    if (
      autocompleateField.length &&
      isAutocompleateShow &&
      parsedCompanies.length
    ) {
      return (
        <AutocompleteContainer>
          <Autocompleate
            isOpen={true}
            items={parsedCompanies}
            onSelect={this.handleAutocompleateSelect}
          />
        </AutocompleteContainer>
      )
    }
  }

  renderDatePicker = () => {
    const { startDate, endDate } = this.state
    const pickerLang = {
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Spr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      from: 'From',
      to: 'To'
    }
    const mrange = {
      from: {
        year: Number(moment(startDate).format('YYYY')),
        month: Number(moment(startDate).format('M'))
      },
      to: {
        year: Number(moment(endDate).format('YYYY')),
        month: Number(moment(endDate).format('M'))
      }
    }

    const now = {
      year: Number(moment().format('YYYY')),
      month: Number(moment().format('M'))
    }
    const isChecked =
      mrange.to.year === now.year && mrange.to.month === now.month

    const makeText = m => {
      if (m && m.year && m.month)
        return pickerLang.months[m.month - 1] + '. ' + m.year
      return '?'
    }
    return (
      <Picker
        lang={pickerLang.months}
        range={mrange}
        ref="pickRange"
        years={{ min: 1930 }}
        //onChange={this.handleAMonthChange}
        onDismiss={this.handleAMonthChange}
      >
        <RangeContainer>
          <RangeText onClick={() => this.refs.pickRange.show()}>
            {makeText(mrange.from) + ' ~ ' + makeText(mrange.to)}
          </RangeText>
          <RangeButtonContainer onClick={this.handleSetNowDate}>
            <RangeButtonText>present</RangeButtonText>
            <RangeButton checked={isChecked} />
          </RangeButtonContainer>
        </RangeContainer>
      </Picker>
    )
  }

  render() {
    const { modalTitle, modalWidth, onClose, faIcon } = this.props

    const { description, image } = this.state

    return (
      <UserEditInfoModal
        title={modalTitle}
        width={600}
        onCloseModal={onClose}
        onSaveClick={this.handleSaveClick}
      >
        <Container>
          <CardImage faIcon={faIcon} image={image} />
          <FieldsContainer>
            {this.renderDatePicker()}
            {this.renderComponentsWithAutocompleate()}
            <TextEditor
              id={'textEditor'}
              value={description}
              onChange={this.handleDescriptionChange}
            />
          </FieldsContainer>
        </Container>
      </UserEditInfoModal>
    )
  }
}

ChangeModal.propTypes = {
  autocompleateItems: PropTypes.array,
  faIcon: PropTypes.string,
  item: PropTypes.object,
  modalTitle: PropTypes.string.isRequired,
  modalWidth: PropTypes.number,
  subTitleInputPlaceholder: PropTypes.string,
  titleInputPlaceholder: PropTypes.string,
  type: PropTypes.string,
  withAutocompleate: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onLoadAutocompleateItemImage: PropTypes.func,
  onSave: PropTypes.func.isRequired
}

ChangeModal.defaultProps = {
  item: {
    title: '',
    subTitle: '',
    description: null,
    subTitleInputPlaceholder: 'enter data',
    titleInputPlaceholder: 'enter data',
    startDate: moment().format('YYYY-MM'),
    endDate: moment().format('YYYY-MM'),
    image: null
  }
}

export { ChangeModal }
