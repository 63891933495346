import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { confirmEmail } from 'Redux/actions/emailConfirmation'
import { isAuthenticate } from 'Redux/selectors/auth'
import { isEmailConfirmed } from 'Redux/selectors/register'

import Component from './EmailConfirmation'
import { switchModal } from 'Redux/actions/utils'

const selector = createStructuredSelector({
  isAuthenticate,
  isEmailConfirmed
})

const actions = {
  onConfirm: confirmEmail,
  onSwitchModal: switchModal
}

export default connect(
  selector,
  actions
)(Component)
