import { connect } from 'react-redux'
import Component from './CareerTimeLine'
import { createStructuredSelector } from 'reselect'

import {
  loadProfile,
  udpdateCandidate,
  fetchUserById,
  resendConfirmEmail
} from 'Redux/actions/user'
import { isAuthenticate } from 'Redux/selectors/auth'

import {
  getUserAge,
  getUserName,
  getUserInfo,
  getUserAddress,
  getUserLinkedin,
  getUserExperience,
  // time line
  getUserAcademicQualifications,
  getUserProfQualf,
  getUserActivities,
  // --
  getProfLoadInfo,
  getUserEducation,
  getUserFeedbacks,
  getUserStatus,
  getUserEmail,
  getUser
} from 'Redux/selectors/user'

const selector = createStructuredSelector({
  userAge: getUserAge,
  userName: getUserName,
  userInfor: getUserInfo,
  userAddress: getUserAddress,
  userLinkedIn: getUserLinkedin,
  userEducation: getUserEducation,
  experience: getUserExperience,
  academQualif: getUserAcademicQualifications,
  profQualif: getUserProfQualf,
  activities: getUserActivities,
  loadInfo: getProfLoadInfo,
  userFeedbacks: getUserFeedbacks,
  isAuthenticate,
  userStatus: getUserStatus,
  userEmail: getUserEmail,
  userData: getUser
})

const actionCreators = {
  onUpdateCandidate: udpdateCandidate,
  onLoadProfile: loadProfile,
  fetchUserById,
  resendConfirmEmail
}

export default connect(
  selector,
  actionCreators
)(Component)
