import Component from './LoginPage'
import { connect } from 'react-redux'
import { logIn } from 'Redux/actions/auth'
import { createStructuredSelector } from 'reselect'
import { isAuthenticate, isAuthLoading } from 'Redux/selectors/auth'
import { getUserconfirmedData } from 'Redux/selectors/user'


const actions = {
  onLogIn: logIn
}
const selector = createStructuredSelector({
  isAuthenticate,
  isAuthLoading,
  getUserconfirmedData
})

export default connect(
  selector,
  actions
)(Component)
