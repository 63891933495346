import React from 'react'
import PropTypes from 'prop-types'

import { Title } from './styled'

const CardTitle = ({ text }) => {
  return <Title>{text}</Title>
}

CardTitle.propTypes = {
  text: PropTypes.string.isRequired
}

export { CardTitle }
