import { createSelector } from 'reselect'

const profQualfData = store => store.profQualf

export const getPredProfQualf = createSelector(
  profQualfData,
  data => data.profQualf
)

export const getProfQualfLoadInfo = createSelector(
  profQualfData,
  data => data.loadInfo
)
