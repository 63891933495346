import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadIndustries } from 'Redux/actions/industries'
import { loadJobTypes } from 'Redux/actions/jobTypes'
import { changeData } from 'Redux/actions/searchData'
import { setActiveVacancy } from 'Redux/actions/jobs'

import { getIndustries } from 'Redux/selectors/industries'
import { getSearchData } from 'Redux/selectors/searchData'
import { getJobTypes } from 'Redux/selectors/jobTypes'
import { isAuthenticate } from 'Redux/selectors/auth'
import { getUser } from 'Redux/selectors/user'

import Component from './SearchModal'

const selectors = createStructuredSelector({
  industries: getIndustries,
  searchData: getSearchData,
  jobTypes: getJobTypes,
  isAuth: isAuthenticate,
  user: getUser
})

const actions = {
  onLoadIndustries: loadIndustries,
  onChangeData: changeData,
  onSetActiveVacancy: setActiveVacancy,
  onLoadJobTypes: loadJobTypes
}

export const SearchModal = connect(
  selectors,
  actions
)(Component)
