import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'

const animation = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)
  }
`
export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Logo = styled.img`
  width: 300px;
`
export const BigText = styled.div`
  font-size: 50px;
  font-family: Lobster;
  margin: 50px 0;
`
export const Spinner = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 5px solid #f4f4f4;
  border-bottom: 5px solid ${colors.mainBlue};
  border-top: 5px solid ${colors.mainBlue};
  animation: 2s ${animation} infinite ease-in-out;
`
