import styled, { keyframes } from "styled-components";
import * as colors from "../../../Theme/colors";
import { headShake, fadeIn, bounceIn } from "react-animations";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

const backgroundAnim = keyframes`${fadeIn}`;
export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  background: ${colors.bluePurpleGradient};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 0.3s ${backgroundAnim};
`;
const containerAnim = keyframes`${bounceIn}`;
export const Container = styled.div`
  width: 400px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 0 40px 0;
  font-size: 17px;
  align-items: center;
  border-radius: 4px;
  animation: 0.3s ${containerAnim};
  @media screen and (max-width: 500px) {
    width: 95%;
  }
`;
export const Header = styled.div`
  border-bottom: solid 1px #757575;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  font-size: 1em;
  color: #757575;
  position: relative;
  margin: 0 0 60px;
  align-self: stretch;
`;
export const Title = styled.h1`
  font-size: 1.2em;
  font-weight: normal;
  margin: 0;
  color: #757575;
`;
const closeBtnAnim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const CloseBtn = styled.i.attrs({ className: "fas fa-times" })`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;
export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-bottom: 40px;
  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
export const UrlBlock = styled.div`
  display: flex;
  padding: 5px 0 1px 5px;
  // border: solid 1px #757575;
  // overflow: hidden;
`;
export const UrlBlockLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const UrlBlockTitle = styled.h2`
  font-size: 0.9em;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #757575;
`;
export const UrlBlockInput = styled.input`
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  color: #0645ad;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: none;
  }
`;
const copyAnim = keyframes`${headShake}`;
export const UrlBlockCopyIcon = styled.i.attrs({
  className: (p) => (p.isCopied ? "fas fa-check-circle" : "far fa-clone"),
})`
  padding: 0 20px;
  align-self: center;
  transition: 0.3s color;
  cursor: ${(p) => (p.isCopied ? "default" : "pointer")};
  font-size: 1.3em;
  color: ${(p) => (p.isCopied ? "#0645ad" : "inherit")}
    ${(p) => p.isCopied && `animation: 0.5s ${copyAnim}`};
  &:hover {
    color: #0645ad;
  }
`;
const _linkStyle = `
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  font-size: 2.2em;
  // &:hover {
  //   background-color: rgb(90, 64, 162);
  //   color: white;
  // }
  &:focus {
    outline: none;
  }  
`;
export const FacebookLink = styled(FacebookShareButton)`
  ${_linkStyle};
  color: #4267b2;
`;
export const LinkedinLink = styled(LinkedinShareButton)`
  ${_linkStyle};
  color: #0077b5;
`;
export const TwitterLink = styled(TwitterShareButton)`
  ${_linkStyle};
  color: #1da1f2;
`;
export const WhatsappLink = styled(WhatsappShareButton)`
  ${_linkStyle}
  color: #25D366;
`;
export const EmailLink = styled(EmailShareButton)`
  ${_linkStyle}
  color: rgb(90, 64, 162);
`;
const _ShareIcon = styled.i``;
export const ShareIcon = styled(_ShareIcon)``;
