import styled from 'styled-components'

import StackGrid from 'react-stack-grid'

import * as colors from '../../../../../Theme/colors'

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  font-family: Nunito;
`

export const CardsList = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`
