import styled from "styled-components";
import * as colors from "../../../Theme/colors";

const size = "25px";

export const Container = styled.div`
  width: 100%;
  height: 55px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 6;
  background: white;
  @media screen and (max-width: 500px) {
    display: none;
  }
  @media screen and (min-width: 1600px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 1400px;
  }
`;

export const NotificationsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0 0 0;
  position: relative;
`;

export const IconContainer = styled.div`
  font-size: 2em;
  cursor: pointer;
  transition: color 0.3s;
  color: ${(p) =>
    p.active ? colors.bluePurpleGradient : colors.buttonOkayGreen};
  &:hover {
    color: ${colors.notificationRed};
  }
`;
export const ContainerNotification = styled.div`
  width: 40vw;
  height: 10vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
`;
export const ContentNotification = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 101, 255, 0.7);
  background: linear-gradient(
    to top,
    rgba(0, 101, 255, 0.7),
    rgba(0, 101, 255, 0.7) 35%,
    rgba(95, 12, 211, 0.7)
  );
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
  }
`;
export const SiteLogo = styled.div`
  margin: 0;
`;

export const LittleLogoContainer = styled.div`
  width: ${size};
  height: ${size};
  margin: 0 10px;
  cursor: pointer;
`;

export const LittleLogo = styled.img`
  width: ${size};
  height: ${size};
  margin: 0 5px;
`;

export const Menu = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  ${"" /* min-width: 305px; */}
  ${"" /* border-right: 1px solid gray; */}
  margin-right: 10px;
  @media screen and (max-width: 799px) {
    margin: 0 5px;
    ${"" /* min-width: 300px; */}
  }
`;
export const StyleFor = styled.div`
  display: flex;
`;

export const NotificationsLink = styled.i.attrs({
  className: "fas fa-bell",
  alt: "Notifications",
})`
  font-size: 25px;
  margin: 0 0 0 5px;
  color: ${(p) => (p.active ? colors.careerBlue : "#787878")};
`;
export const LinkUnauthorized = styled.div`
  cursor: pointer;
`;
