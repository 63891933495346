import styled from 'styled-components'

import * as colors from '../../../../../../../Theme/colors'

export const Container = styled.section`
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`
export const Title = styled.h2`
  font-size: 25px;
  color: ${colors.careerBlue};
`
export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  font-family: Nunito;
`
