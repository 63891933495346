import styled, { keyframes } from 'styled-components'
import { notificationRed } from '../../../Theme/colors'
import { rubberBand } from 'react-animations'

import Spiner from 'react-spinkit'

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`
export const Loader = styled(Spiner).attrs({ name: 'ball-beat' })`
  height: 85%;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    position: absolute;
    background-color: #fff;
    height: 100%;
  }
`

export const percentStyles = {
  root: {
    width: '60px',
    height: '60px'
  },
  path: {
    stroke: '#583ea1'
  },
  text: {
    fill: '#857575',
    fontSize: '25px'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1400px;
  background-color: #fff;
  width: 100%;
  margin-bottom: 50px;
  @media screen and (max-width: 500px) {
    padding-right: 0;
  }
`
export const Text = styled.div`
  width: 100%;
  padding: 0 20px;
`

export const Title = styled.h1`
  align-self: flex-start;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-top: 10px;
  font-size: 20px;
  &:first-child {
    margin-top: 10px;
  }
`
