import styled, { keyframes } from "styled-components";
import * as colors from "Theme/colors";
import Image from "../../Blocks/Image";
import { fadeIn } from "react-animations";
import { Navbar as BootstrapNavbar } from "react-bootstrap";
import { Link as ReactDOMLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;
export const SideBarContainer = styled.div`
  margin-top: 70px;
`;

export const MenuImgContainer = styled.div`
  margin-bottom: 10px;
`;
export const MenuImg = styled.div`
  filter: ${(props) => (props.selected ? "none" : "grayscale(1)")};

  &:hover {
    filter: none;
  }
`;
export const Img = styled.img`
  height: 75px;
  width: 75px;
`;
export const ImgBot = styled.img`
  height: 25px;
  margin-right: 10px;
  width: 25px;
`;

export const ImgMobile = styled.img`
  height: 65px;
  width: 65px;
`;

export const Page = styled.ul`
  padding-right: 30px;
  padding-left: 20px;
  @media (max-width: 799px) {
    padding: 0 10px;
  }
`;
export const ContainerNav = styled.div`
  display: flex;
  flex-direction: column;
  padding: 41px;
`;
export const SliderBarLink = styled.div`
  color: ${(props) => (props.selected ? "#fff" : "#656565")};
  margin-right: 5px;
  font-size: 12px;
  width: 100%;
  line-height: 15px;
  font-family: "Nunito";
  margin-bottom: 7px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  background-color: ${(props) => (props.selected ? "#656565" : "#fff")};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  text-align: left;
  padding-top: 6px;
  ${"" /* &:active,
  :focus {
    color: #fff;
    background-color: #ededed;
    border-color: #ededed;
    text-decoration: none;
  } */}
`;
export const SliderBarLinkTo = styled(ReactDOMLink)`
  color: ${(props) => (props.selected ? "#757575" : "#656565")};
  &:hover {
      color: #757575 !important;
    }
  margin-right: 5px;
  font-size: 12px;
  line-height: 15px;
  font-family: "Nunito";
  margin-bottom: 7px;
  border-radius: 12px;
  width:100%;
  ${"" /* border: 1px solid #656565; */}
  background-color: ${(props) => (props.selected ? "#ededed" : "#fff")};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  text-align: left;
  text-decoration: none;
  padding-top: 6px;
  :active{
    color: #757575;
    background-color: #ededed;
    border-color: #ededed;
    text-decoration: none;
  }
`;
export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
export const IconTitle = styled.p`
  color: ${(props) => (!props.selected ? "#656565" : "#111")};
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  font-family: "Nunito";
`;
export const Circle = styled.div`
  width: ${(props) => (props.size ? `${props.size}px` : "100px")};
  height: ${(props) => (props.size ? `${props.size}px` : "100px")};
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  background: gray;
  position: relative;
`;

export const AvatarImage = styled.img`
  width: ${(props) => (props.size ? `${props.size}px` : "100px")};
  height: ${(props) => (props.size ? `${props.size}px` : "100px")};
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  ${(props) => props.selected && "border: 3px solid #00b7fe;"}
`;

export const ProfileName = styled.div`
  font-size: 16px;
  color: #656565;
  font-weight: bold;
  font-family: "Nunito";
  cursor: pointer;
`;
export const ProfileMessage = styled.div`
  width: 35px;
  height: 35px;
  background: #fe1948;
  color: white;
  position: relative;
  border-radius: 40px;
  left: ${(props) => (props.mobile ? `69%` : "24%")};
  bottom: 114px;
  font-size: 14px;
  font-weight: 100;
`;

export const LinkUnauthorized = styled.div`
  cursor: pointer;
`;
export const ActivePageText = styled.li`
  text-align: center;
  font-size: 20px;
`;
export const HeaderCategory = styled.div`
  cursor: pointer;
  ${(props) =>
    props.active &&
    `
    color: #000 !important;
    border-bottom: 2px solid #19befe;
  `};
  @media screen and (min-width: 501px) {
    &:hover {
      color: #395eff !important;
    }import { cursor } from '../../../Assets/icons';

  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
export const GetCardsContainer = styled.ul`
  display: flex;
  margin: 0;
`;
export const MenuItem = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const SidebarPushable = styled.div`
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 34px);
  @media screen and (max-width: 500px) {
    height: 100%;
  }
`;
export const Sidebar = styled.div`
  transition: 0.5s;
  transform: translateX(${(props) => (props.visible ? 0 : "-190px")});
  width: 190px;
  height: calc(100vh - 34px);
  position: fixed;
  overflow: hidden;
  top: 34px;
  left: 0;
  background: #fff;
  z-index: 1101;
`;
const _sidebarBackAnim = keyframes`${fadeIn}`;
export const SidebarBackground = styled.div`
  z-index: 1099;
  background: ${colors.bluePurpleGradient};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 1s ${_sidebarBackAnim};
`;
export const SidebarPusher = styled.div`
  height: 100%;
  width: 100%;
  overflow: ${(props) => (props.notScroll ? "hidden" : "auto")};
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  background-color: #fff;
`;
export const Navbar = styled(BootstrapNavbar)`
  margin-bottom: 0;
  background-color: #fff;
  border: none;
  min-height: initial;
  width: 100%;
  position: fixed;
  z-index: 1100;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;
export const NavbarHeader = styled(BootstrapNavbar.Header)`
  display: flex;
  align-items: center;
  ${(p) => p.visible && "pointer-events: none"};
`;
export const NavbarToggle = styled(BootstrapNavbar.Toggle)`
  margin: 0 15px 3px 30px;
  border: none;
`;
export const Link = styled(ReactDOMLink)`
  color: black;
`;
export const Notifications = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  background-color: ${colors.notificationRed};
  position: absolute;
  top: -7px;
  right: -7px;
  line-height: 30px;
  font-family: "Nunito";
  font-weight: bold;
  transition: all 0.5s;
`;
export const ContentContainer = styled.div``;

export const SliderBarLinkBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
