import Component from './Activities'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUserActivities } from '../../../../../Redux/selectors/user'

import {
  addActivities,
  editActivities,
  removeActivities
} from '../../../../../Redux/actions/activities'

const selectors = createStructuredSelector({
  activities: getUserActivities
})

const actions = {
  onAdd: addActivities,
  onEdit: editActivities,
  onDelete: removeActivities
}

export const Activities = connect(
  selectors,
  actions
)(Component)
