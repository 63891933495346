import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const LOAD_HIGH_SCHOOL_ED = createAsyncAction('highSchoolEd/LOAD')
export const loadHighSchoolEd = text => {
  return apiCall({
    endpoint: '/highschooled',
    types: LOAD_HIGH_SCHOOL_ED,
    query: {
      text
    },
    method: 'GET'
  })
}
export const REMOVE_HIGH_SCHOOL_ED = createAsyncAction('highSchoolEd/REMOVE')
export const removeHighSchoolEd = id => {
  return apiCall({
    endpoint: '/highschooled/id',
    types: REMOVE_HIGH_SCHOOL_ED,
    method: 'DELETE',
    query: {
      id: [id]
    }
  })
}
export const EDIT_HIGH_SCHOOL_ED = createAsyncAction('highSchoolEd/EDIT')
export const editHighSchoolEd = ({ toAdd, toDelete }) => {
  return apiCall({
    endpoint: '/highschooled',
    types: EDIT_HIGH_SCHOOL_ED,
    method: 'POST',
    query: {
      toAdd,
      toDelete
    }
  })
}
