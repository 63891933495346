import React from 'react'
import PropTypes from 'prop-types'

import { CareerTimeLineBlock } from '../../../../Blocks/CareerTimeLineBlock'
import FeedbackItem from './blocks/FeedbackItem'

import { Container } from './styled'

const WhatOthersSay = ({ userFeedbacks, onUpdateFeedbackState }) => {
  return (
    <CareerTimeLineBlock title={'What Others Say'} type={'whatOthersSay'}>
      <Container>
        {userFeedbacks && userFeedbacks.length
          ? userFeedbacks.map(item => (
              <FeedbackItem
                company={item.company}
                feedback={item.feedback}
                firstName={item.first_name}
                isActive={item.isActive}
                itemId={item.id}
                key={item.id}
                lastName={item.last_name}
                personality={item.personalities}
                position={item.position}
                rate={item.rate}
                onUpdateFeedbackState={onUpdateFeedbackState}
              />
            ))
          : null}
      </Container>
    </CareerTimeLineBlock>
  )
}

WhatOthersSay.propTypes = {
  userFeedbacks: PropTypes.array,
  onUpdateFeedbackState: PropTypes.func.isRequired
}

export default WhatOthersSay
