import _ from 'lodash'

import {
  GET_MESSAGES,
  MOUNT_CHANNELS,
  SEND_MESSAGE,
  RECIEVE_MESSAGE,
  RECIEVE_CHATS_DATA,
  CHANGE_MESSAGE_STATUS,
  NEW_CHAT
} from '../actions/chat'
import { LOG_OUT } from 'Redux/actions/auth'
import { createReducer } from 'Helpers/redux'

const initialState = {
  channels: [],
  messages: {},
  isChannelsMounted: false,
  unreadChats: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const reducer = {
  [GET_MESSAGES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      messages: payload.data,
      isLoading: false
    }
  },
  [GET_MESSAGES.REQUEST]: (state, { query }) => {
    return {
      ...state,
      isLoading: true
    }
  },
  [LOG_OUT.SUCCESS]: (state, { payload }) => {
    return initialState
  },
  [MOUNT_CHANNELS]: (state, payload) => {
    return {
      ...state,
      isChannelsMounted: true
    }
  },
  [SEND_MESSAGE.SUCCESS]: (state, { payload }) => {
    const { chat_id, message } = payload.data

    const messages = state.messages
    if (messages[chat_id]) {
      messages[chat_id].push(message)
    } else {
      messages[chat_id] = [message]
    }

    return {
      ...state,
      messages
    }
  },
  [RECIEVE_MESSAGE]: (state, { payload }) => {
    const { unreadChats } = state
    const { chat_id, message } = payload.data

    const nextUnreadChats = unreadChats

    if (
      message.status !== 'Seen' &&
      message.writter !== 'candidate' &&
      unreadChats.indexOf(chat_id) === -1
    ) {
      nextUnreadChats.push(chat_id)
    }

    const messages = state.messages
    if (messages[chat_id]) {
      messages[chat_id].push(message)
    } else {
      messages[chat_id] = [message]
    }

    return {
      ...state,
      messages,
      unreadChats: nextUnreadChats
    }
  },
  [RECIEVE_CHATS_DATA]: (state, { payload }) => {
    const unreadChats = []

    const { messages, chats } = payload.data

    for (let i = 0; i < chats.length; i++) {
      if (
        _.find(
          messages[chats[i].id] || [],
          msg => msg.status !== 'Seen' && msg.writter !== 'candidate'
        )
      ) {
        unreadChats.push(chats[i].id)
      }
    }

    return {
      ...state,
      channels: chats,
      messages,
      unreadChats
    }
  },
  [GET_MESSAGES.REQUEST]: state => {
    return {
      ...state,
      loadInfo: {
        isLoaded: false,
        isLoading: true,
        error: null
      }
    }
  },
  [GET_MESSAGES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      messages: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false
      }
    }
  },
  [CHANGE_MESSAGE_STATUS.SUCCESS]: (state, { payload }) => {
    const { messages, chatId, status, writter } = payload.data
    const nextMessages = state.messages
    let nextUnreadChats = state.unreadChats

    nextMessages[chatId] = nextMessages[chatId].map(
      msg => (messages.indexOf(msg.id) > -1 ? { ...msg, status } : msg)
    )

    if (status === 'Seen' && writter !== 'candidate') {
      nextUnreadChats = state.unreadChats.filter(id => id !== chatId)
    }

    return {
      ...state,
      messages: nextMessages,
      unreadChats: nextUnreadChats
    }
  },
  [NEW_CHAT]: (state, { payload }) => {
    const { chat, msg } = payload.data
    const channels = state.channels.splice(0)

    const isDuplicate = _.find(channels, el => el.id === chat.id)
    if (isDuplicate) {
      return {
        ...state,
        channels
      }
    }

    channels.push(chat)

    const messages = Object.assign({}, state.messages)
    messages[chat.id] = msg || []

    return {
      ...state,
      channels,
      messages
    }
  }
}
export default createReducer(initialState, reducer)
