import React, { PureComponent } from 'react'
import find from 'lodash/find'
import Rate from 'antd/lib/rate'
import AntdIcon from 'antd/lib/icon'
import 'antd/lib/rate/style/css'
import 'antd/lib/icon/style/css'
import {
  Container,
  Header,
  Title,
  Icon,
  Input,
  ButtonsContainer,
  IconButton,
  RateContainer,
  Button,
  Loader,
  starStyle
} from './styles'

export class OverallReview extends PureComponent {
  render() {
    const {
      rate,
      onStarsChange,
      feedbackText,
      onFeedbackTexChange,
      onSubmit,
      isLoading,
      error
    } = this.props

    let sStyle = starStyle
    if (window.innerWidth < 500) {
      sStyle = {
        fontSize: '24px'
      }
    }

    return (
      <Container>
        <Header>
          <Title>OVERALL REVIEW</Title>
        </Header>
        <RateContainer>
          <Rate
            allowHalf
            character={<AntdIcon style={sStyle} type="star" />}
            value={rate}
            onChange={onStarsChange}
          />
          <ButtonsContainer>
            <IconButton onClick={() => onStarsChange(rate - 0.5)}>
              <i className="fas fa-chevron-down" />
            </IconButton>
            <IconButton onClick={() => onStarsChange(rate + 0.5)}>
              <i className="fas fa-chevron-up" />
            </IconButton>
          </ButtonsContainer>
        </RateContainer>
        <Input
          error={error}
          placeholder="Please type-in your overall comment about this candidate and his/her suitability to this job"
          value={feedbackText}
          onChange={onFeedbackTexChange}
        />
        <Button onClick={onSubmit}>
          {isLoading ? (
            <Loader />
          ) : (
            <Icon>
              <i className="fas fa-check-circle" />
            </Icon>
          )}
          Save
        </Button>
      </Container>
    )
  }
}
