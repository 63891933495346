import styled, { keyframes } from "styled-components";
import { BarLoader } from "react-spinners";
import { pulse } from "react-animations";
import * as colors from "../../../../Theme/colors";

export const SideBarContainer = styled.div`
  padding-top: 70px;
  display: flex;
  background-color: #fff;
  @media screen and (min-width: 1600px) {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    box-shadow: 0 0 24px 1px rgba(61, 61, 61, 0.2);
    position: relative;
  }
`;

export const MenuContainer = styled.div`
  width: 230px;
  height: calc(-70px + 100vh) !important;
  background-color: white;import { height } from 'styled-system';

  position: relative;
`;

export const MenuItem = styled.div`
  padding: 0.92857143em 1.14285714em;
`;

export const MenuImgContainer = styled.div`
  ${'' /* margin-bottom: 10px; */}
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 28px;
  border: ${(props) => (!props.selected ? "none" : "1px solid #ededed")};
  background-color: ${(props) => (!props.selected ? "none" : "#ededed")};
  height: 160px;
  width: 160px;
  justify-content: center;
  text-align: center;
  position: relative;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  @media screen and (min-width: 1600px) {
    width: 100%;
    max-width: 1400px;
  }
`;
export const Loader = styled(BarLoader)``;
export const LoaderContainer = styled.div`
  margin-top: 15px;
`;
export const SliderBarLinkBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
