import { createSelector } from 'reselect'

const data = store => store.friendSupport

export const getProfile = createSelector(data, data => data.userProfile)

export const getTimeLineElements = createSelector(data, data => data.timeLine)

export const getLoadInfo = createSelector(data, data => data.loadInfo)

export const getUploadLoadInfo = createSelector(
  data,
  data => data.uploadLoadInfo
)
