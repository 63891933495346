import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Component from './UserCareerTimeLine'

import { getTimeline } from 'Redux/selectors/timeline'
import { loadTimeline } from 'Redux/actions/timeline'

const actions = {
  onTimelineLoad: loadTimeline
}

const selector = createStructuredSelector({
  timeline: getTimeline
})

export default connect(
  selector,
  actions
)(Component)
