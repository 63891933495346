import React, { Component } from "react";
import { Container, SliderBarLink, ImgBot } from "./styled";
import "react-perfect-scrollbar/dist/css/styles.css";
import * as routes from "Constants/routes";
import withSizes from "react-sizes";

import defaultAvatar from "../../../images/defaultAvatar.png";

import "./style.css";
import modalRoutes from "Constants/modal_routes";
import { api } from "Config/App";

import { headerMobileScroll } from "../../../Helpers/headerMobileScroll";
import { postJobLink } from "../../../Config/App";

import { LayoutMobile } from "./mobile";
import { LayoutDesktop } from "./desktop";
import logout from "../../../images/menu/logout.png";
import login from "../../../images/menu/login.png";

class Layout extends Component {
  state = {
    visible: false,
    avatar: defaultAvatar,
    isSearchModalOpen: false,
    activeCategoryId: 0,
  };

  componentDidMount() {
    this.loadAvatar();
    const { settingsList, onLoadSettings } = this.props;
    if (!settingsList.length) {
      onLoadSettings();
    }
    const date = new Date();
    const stringDate = date.toLocaleString();
    if (this.props.isAuthenticate) {
      const oldDate = localStorage.getItem("login_date");
      if (!oldDate) {
        localStorage.setItem("login_date", stringDate);
      }
      const timeDifference = date - new Date(stringDate);
      if (86400000 > timeDifference) {
        this.props.onMarkLogin();
      }
    }
  }
  //eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isAuthenticate) {
      this.setState({ avatar: defaultAvatar });
    } else {
      this.loadAvatar();
    }
  }

  handleLinkWithAuthClick = () => {
    const { isAuthenticate } = this.props;
    if (!isAuthenticate) {
      this.props.onSwitchModal(modalRoutes.login);
    }
  };

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible });
    //todo toggle visible sidebar menu
  };

  handleLogOut = () => {
    const channel = new BroadcastChannel("timer_sync");
    channel.postMessage("timeNow");
    this.props.onLogOut();
    this.props.onResetJobs();
    this.props.history.push(routes.initial);
  };

  loadAvatar = () => {
    if (this.props.avatar) {
      const img = new Image();
      const avatarPath = `${api.url}/images/avatars/${this.props.avatar}`;
      img.src = avatarPath;
      img.onload = () => {
        this.setState({ avatar: avatarPath });
      };
      img.onerror = () => {
        this.setState({ avatar: defaultAvatar });
      };
    }
  };

  renderLogInLogOut = () => {
    if (this.props.isAuthenticate) {
      return (
        <SliderBarLink onClick={this.handleLogOut}>
          <ImgBot alt="Jobs For You" src={logout} />
          LOG OUT
        </SliderBarLink>
      );
    }
    return (
      <SliderBarLink
        onClick={() => {
          this.setState({ visible: false });
          this.props.onSwitchModal(modalRoutes.login);
        }}
      >
        <ImgBot alt="Jobs For You" src={login} />
        LOG IN
      </SliderBarLink>
    );
  };

  handleHeaderCategoryClick = (value, key, child) => {
    const { activeCategoryId } = this.state;
    const { clientWidth } = document.documentElement;
    if (clientWidth < 500 && activeCategoryId !== value.id) {
      const scrollParrent = document.getElementById("page_active");
      headerMobileScroll({ parent: scrollParrent, child });
      this.setState({ activeCategoryId: value.id });
    }
    this.props.onHeaderCategoryClick(value, key);
  };

  handleDeleteBtnClick = (event, value) => {
    const { history } = this.props;

    event.stopPropagation();
    history.push(routes.jobs);

    if (typeof value.onDelete === "function") value.onDelete(value);
  };

  handleSearch = (text) => {
    const { onSearchJobs, history } = this.props;

    this.setState({
      isSearchModalOpen: false,
    });
    onSearchJobs(text);
    history.push(routes.jobs);
  };

  handleSearchByParams = (params) => {
    const { onSearchJobsByParams } = this.props;
    onSearchJobsByParams(params);
  };

  handlerOpenSearchMobile = () => {
    this.setState({
      isSearchModalOpen: true,
      visible: false,
    });
  };

  handlerCloseSearchMobile = () => {
    this.setState({ isSearchModalOpen: false });
  };

  postJob = (isAuthenticate) => {
    //const { isAuthenticate } = this.props;
    // let url;
    // let win;

    const url = `${postJobLink.url}`;
    const win = window.open(url, "_blank");
    win.focus();

    // https://mrjobs.co/company/jobs
    // if (isAuthenticate) {
    //   url = `${postJobLink.url}/jobs`;
    //   win = window.open(url, "_blank");
    //   win.focus();
    // } else if (!isAuthenticate) {
    //   url = `${postJobLink.url}/registration`;
    //   win = window.open(url, "_blank");
    //   win.focus();
    // }
  };

  render() {
    const {
      children,
      isAuthenticate,
      user,
      unreadChatsCount,
      history,
      userJobs,
      userJobsInfo,
      notScroll,
      handleScroll,
      activeHeaderCategory,
      header,
      onHeaderCategoryClick,
      DeleteHeaderCat,
      userDataLoadInfo,
      isMobile,
      withHeaderNavigate,
      settingsList,
      settingsLoadInfo,
    } = this.props;
    const { visible, isSearchModalOpen } = this.state;

    if (isMobile) {
      return (
        <Container>
          <LayoutMobile
            activeHeaderCategory={activeHeaderCategory}
            user={user}
            header={header}
            unreadChatsCount={unreadChatsCount}
            visible={visible}
            history={history}
            isAuthenticate={isAuthenticate}
            getCats={this.getCats}
            renderLogInLogOut={this.renderLogInLogOut}
            isSearchModalOpen={isSearchModalOpen}
            userJobs={userJobs}
            userJobsInfo={userJobsInfo}
            onOpenSearch={this.handlerOpenSearchMobile}
            onCloseSearch={this.handlerCloseSearchMobile}
            handleScroll={handleScroll}
            notScroll={notScroll}
            onToggleVisibility={this.toggleVisibility}
            openModal={(params) => this.props.onSwitchModal(params)}
            onHeaderCategoryClick={onHeaderCategoryClick}
            onLinkWithAuthClick={this.handleLinkWithAuthClick}
            onSearchByParams={this.handleSearchByParams}
            onSearch={this.handleSearch}
            // onHeaderCategoryClick={this.handleHeaderCategoryClick}
            onDeleteBtnClick={this.handleDeleteBtnClick}
            settingsList={settingsList}
            settingsLoadInfo={settingsLoadInfo}
            postJob={this.postJob}
          >
            {children}
          </LayoutMobile>
        </Container>
      );
    }
    return (
      <Container>
        <LayoutDesktop
          user={user}
          unreadChatsCount={unreadChatsCount}
          visible={visible}
          history={history}
          isAuthenticate={isAuthenticate}
          renderLogInLogOut={this.renderLogInLogOut}
          activeHeaderCategory={activeHeaderCategory}
          header={header}
          onHeaderCategoryClick={onHeaderCategoryClick}
          deleteHeaderCat={DeleteHeaderCat}
          handleScroll={handleScroll}
          notScroll={notScroll}
          userDataLoadInfo={userDataLoadInfo}
          withHeaderNavigate={withHeaderNavigate}
          onLinkWithAuthClick={this.handleLinkWithAuthClick}
          openModal={(params) => this.props.onSwitchModal(params)}
          settingsList={settingsList}
          settingsLoadInfo={settingsLoadInfo}
          postJob={this.postJob}
        >
          {children}
        </LayoutDesktop>
      </Container>
    );
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500,
});

export default withSizes(mapSizeToProps)(Layout);
