import Component from './Activities'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUserActivities } from '../../../../../Redux/selectors/publicUser'

const selectors = createStructuredSelector({
  activities: getUserActivities
})

const actions = {}

export const Activities = connect(
  selectors,
  actions
)(Component)
