import { combineReducers } from 'redux'

import auth from './auth'
import main from './main'
import router from './router'
import jobs from './jobs'
import industries from './industries'
import register from './register'
import companies from './companies'
import user from './user'
import jobTypes from './jobTypes'
import personalities from './personalities'
import expectations from './expectations'
import cities from './cities'
import options from './options'
import highSchoolEd from './highSchoolEd'
import academicQualifications from './academicQualifications'
import profQualf from './professionalQualifications'
import expertises from './expertises'
import subExpertises from './subExpertises'
import applications from './applications'
import chat from './chat'
import contacts from './contacts'
import friendSupport from './friendSupport'
import timeline from './timeline'
import passwordReset from './passwordReset'
import searchData from './searchData'
import languages from './languages'
import utils from './utils'
import contactUs from './contactUs'
import settings from './settings'
import privacy from './privacy'
import publicUser from './publicUser'

const rootReducer = combineReducers({
  auth,
  main,
  router,
  jobs,
  register,
  industries,
  companies,
  user,
  jobTypes,
  personalities,
  expectations,
  cities,
  options,
  highSchoolEd,
  academicQualifications,
  profQualf,
  expertises,
  subExpertises,
  applications,
  chat,
  contacts,
  friendSupport,
  timeline,
  passwordReset,
  searchData,
  languages,
  utils,
  contactUs,
  settings,
  privacy,
  publicUser
})

export default rootReducer
