import styled, { keyframes } from 'styled-components'
import * as colors from 'Theme/colors'

export const modalStyle = {
  content: {
    border: 'none',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
export const modalStyleMobile = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
const loadingAnim = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)   
  }
`
export const Loader = styled.div`
  width: 20px;
  height: 20px;
  margin: 10px;
  border-radius: 50%;
  border: 1.5px solid transparent;
  border-bottom: 1.5px solid ${colors.mainBlue};
  border-left: 1.5px solid ${colors.mainBlue};
  animation: 2s infinite ${loadingAnim} ease-in-out;
`
export const Content = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
  width: 450px;
  border-radius: 16px;
  flex-direction: column;
`
export const Header = styled.div`
  display: flex;
  margin: 15px 27px;
  align-items: center;
`
export const Title = styled.div`
  font-size: 14px;
  color: ${colors.mainBlue};
  flex: 1;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Nunito';
`
export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  margin-top: -50px;
  margin-right: -40px;
  border-radius: 15px;
  background-color: #552a7a;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export const TextBoxContainer = styled.div`
  display: flex;
  height: 40px;
  margin: 0 27px;
  color: ${colors.lightGreyFont};
`
export const InputContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.chatPurple};
  border-right: none;
  align-items: center;
  border-radius: 15px 0 0 15px;
  flex: 1;
`
export const Icon = styled.div`
  margin: 10px;
  font-size: 20px;
  color: ${props => (props.warning ? colors.notificationRed : 'inherit')};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  flex: 1;
`
export const ListTitle = styled.div`
  color: ${colors.lightGreyFont};
  margin: 15px 27px;
  font-size: 14px;
  text-transform: capitalize;
`
export const ListContainer = styled.div`
  height: 40vh;
  overflow: auto;
  &::-webkit-scrollbar {
    background-color: #dbd9d9;
    width: 5px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
`
export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 20px;
  font-size: 14px;
  color: ${colors.lightGreyFont};
  &::-webkit-scrollbar {
    background-color: #dbd9d9;
    width: 5px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }
`
export const ListItem = styled.div`
  padding: 3px 8px;
  border-radius: 20px;
  border: 1px solid
    ${props =>
      props.invited
        ? colors.buttonOkayGreen
        : colors.selectedColor};
  color: ${props =>
    props.invited
      ? colors.buttonOkayGreen
      : props.selected
      ? 'white'
      : colors.lightGreyFont};
  background-color: ${props =>
    props.invited
      ? 'transparent'
      : props.selected
      ? colors.selectedColor
      : 'transparent'};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  user-select: none;
`
export const ItemTitle = styled.div`
  flex: 1;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ItemIcon = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 0 3px;
`
export const Button = styled.div`
  background-color: ${props =>
    props.disabled ? colors.stepGrey : colors.buttonOkayGreen};
  color: ${props => (props.disabled ? colors.lightGreyFont : 'white')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  font-size: 14px;
  align-self: center;
  padding: 3px 0;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`
export const IconAdd = styled.div`
  padding: 5px 10px;
  color: ${colors.buttonGreen};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 0 15px 15px 0;
  border: 1px solid ${colors.chatPurple};
  border-left: none;
  cursor: pointer;
`
export const IconSend = styled.div`
  margin: 0 5px;
`
