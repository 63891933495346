import Component from './SearchWindow'
import { connect } from 'react-redux'
import { setChoosenCategories } from 'Redux/actions/register'
import { loadJobTypes } from 'Redux/actions/jobTypes'
import { loadIndustries } from 'Redux/actions/industries'
import { createStructuredSelector } from 'reselect'
import { getIndustries } from 'Redux/selectors/industries'
import { getJobTypes } from 'Redux/selectors/jobTypes'
import { getRegisterData } from 'Redux/selectors/register';

const actions = {
  completeCurrent: setChoosenCategories,
  onJobTypesLoad: loadJobTypes,
  onIndustriesLoad: loadIndustries
}
const selector = createStructuredSelector({
  industries: getIndustries,
  jobTypes: getJobTypes,
  registerData: getRegisterData
})

export default connect(
  selector,
  actions
)(Component)
