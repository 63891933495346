import React from 'react'
import PropTypes from 'prop-types'
import { RatingLevelContainer, RatingLevelText } from './styled'

const RatingText = ({ value }) => {
  return (
    <RatingLevelContainer>
      <RatingLevelText>{value}</RatingLevelText>
    </RatingLevelContainer>
  )
}

RatingText.propTypes = {
  value: PropTypes.string.isRequired
}

export { RatingText }
