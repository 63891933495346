import React, { PureComponent } from 'react'
import moment from 'moment'

import { Element } from 'react-scroll/modules'
import ReactHtmlParser from 'react-html-parser'

import { AddButton } from '../blocks/AddButton'
import { CareerTimeLineBlock } from '../../../../Blocks'
import { ChangeModal } from '../blocks'

import {
  Container,
  CardsList,
  Card,
  CardImageContainer,
  CardImageIconDefault,
  CardImageIcon,
  CardContent,
  CardTime,
  CardTitle,
  CardAddress,
  CardDesctiption,
  CardDeleteIcon
} from './styled'

export default class MyEduction extends PureComponent {
  state = {
    isModalOpen: false,
    changeItemId: null
  }

  handleOpenModal = ({ id }) => {
    this.setState({
      isModalOpen: true,
      changeItemId: id || null
    })
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
      changeItemId: null
    })
  }

  handleSaveClick = result => {
    const { onEditEducations, onAddEducations } = this.props
    const { changeItemId } = this.state

    const { title, subTitle, description, startDate, endDate, image } = result

    const reqObj = {
      profession: title,
      university: subTitle,
      description,
      start_date: startDate,
      end_date: endDate,
      image,
      edcId: changeItemId
    }

    if (changeItemId !== null) {
      onEditEducations({ ...reqObj })
    } else {
      onAddEducations({ ...reqObj })
    }

    this.handleCloseModal()
  }

  handleDeleteClick = (event, id) => {
    event.stopPropagation()
    const { onRemoveEducations } = this.props
    onRemoveEducations(id)
  }

  renderChangeModal = () => {
    const { education } = this.props
    const { changeItemId } = this.state

    const modalTitle = changeItemId === null ? 'Add Position' : 'Edit Position'
    const changedItem = education.find(item => item.id === changeItemId)

    let parsedItem = undefined
    if (changeItemId !== null) {
      parsedItem = {
        title: changedItem.profession,
        subTitle: changedItem.university,
        description: changedItem.description,
        startDate: changedItem.start_date,
        endDate: changedItem.end_date,
        image: changedItem.image
      }
    }

    return (
      <ChangeModal
        item={parsedItem}
        modalTitle={modalTitle}
        subTitleInputPlaceholder={'Company Ex: Microsoft'}
        titleInputPlaceholder={'Title Ex: Web Programmer or Business Manager'}
        onChangeStateByName={this.handleChangeStateByName}
        onClose={this.handleCloseModal}
        onSave={this.handleSaveClick}
      />
    )
  }

  render() {
    const { education } = this.props
    const { isModalOpen } = this.state

    education.sort(
      (a, b) => moment(a.start_date).year() - moment(b.start_date).year()
    )

    return (
      <CareerTimeLineBlock title={'What i know'} type={'academicQualification'}>
        <Container>
          <AddButton
            value={'Add Academic Qualification'}
            onClickAdd={this.handleOpenModal}
          />
          <CardsList>
            {education.length
              ? education.map(item => (
                  <Element
                    key={item.id}
                    name={`academicQualification${item.id}`}
                  >
                    <Card onClick={() => this.handleOpenModal({ id: item.id })}>
                      <CardDeleteIcon
                        onClick={e => this.handleDeleteClick(e, item.id)}
                      />
                      <CardImageContainer>
                        {item.image ? (
                          <CardImageIcon />
                        ) : (
                          <CardImageIconDefault />
                        )}
                      </CardImageContainer>
                      <CardContent>
                        <CardTime>{`${moment(
                          item.start_date
                        ).year()} TO ${moment(
                          item.end_date
                        ).year()}`}</CardTime>
                        <CardTitle>{item.profession}</CardTitle>
                        <CardAddress>{item.university}</CardAddress>
                        <CardDesctiption>
                          {ReactHtmlParser(item.description)}
                        </CardDesctiption>
                      </CardContent>
                    </Card>
                  </Element>
                ))
              : null}
          </CardsList>
          {isModalOpen ? this.renderChangeModal() : null}
        </Container>
      </CareerTimeLineBlock>
    )
  }
}
