import React, { Component } from 'react'
import {
  // ContainerRight,
  ContainerLeft,
  ContainerRightBlock,
  Company,
  Attainment,
  Description,
  Wall,
  Time,
  TimeText,
  Image
} from './styled'
import map from 'lodash/map'
import * as date from 'Helpers/date'

import star from '../../../../../images/Notification/star.png'
import company from '../../../../../images/Notification/company.png'
//import mail from '../../../../../images/Notification/mail.png'

class RightBlock extends Component {
  render() {
    return map(this.props.right, (value, key) => {
      const RightId = value.id
      return (
        <ContainerRightBlock first={key === 0} key={RightId}>
          <Time content={'flex-start'}>
            <i className="fas fa-check-circle" />
            <TimeText>{date.formatForNotification(value.time)}</TimeText>
          </Time>
          <ContainerLeft>
            <Attainment>
              <Image active={value.type === 'accepted'} src={star} />
            </Attainment>
            <Description>
              <p>{value.text}</p>
            </Description>
            <Wall />
            <Company>
              <img alt="" src={company} />
            </Company>
          </ContainerLeft>
        </ContainerRightBlock>
      )
    })
  }
}

export { RightBlock }
