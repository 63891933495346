import styled from 'styled-components'
import { notificationRed, } from '../../../../../Theme/colors'
import * as colors from 'Theme/colors'

export const Content = styled.div`
  margin: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ContentConfirmed = styled.div`
  margin: 70px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70%;
`


export const Title = styled.h3`
  text-align: center;
  margin-top: 50px;
`
export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  justify-content: space-around;
`
export const BottomBlock = styled.div`
  display: flex;
  margin: 55px 0 24px;
  align-items: center;
`
export const Icon = styled.img`
  width: 80px;
  height: 80px;
`

export const BigText = styled.div`
  //color: #0acd00;
  color: ${colors.blackColor};
  font-size: 28px;
  margin: 15px 0;
  text-align: center;
`
export const SmallText = styled.div`
  //color: #0acd00;
  color: ${colors.blackColor};
  font-size: 16px;
  margin: 11px 0;
  text-align: center;
`

export const Link = styled.a`
  color: ${colors.blackColor};
  text-decoration: underline;
`
