import styled from 'styled-components'
import RichTextEditor from 'react-rte'

export const InterestsModalContainer = styled.div`
  margin: 5px 0 10px;
`

export const TextEditor = styled(RichTextEditor)`
  min-height: 370px;
  width: 550px;
  font-family: Nunito !important;
  button[title='Image'],
  button[title='Link'],
  button[title='Remove Link'],
  span[title='Normal'] {
    display: none;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`
