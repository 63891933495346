import Component from './UserInfo'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUser } from 'Redux/selectors/publicUser'

const selectors = createStructuredSelector({
  user: getUser
})

const actions = {}

export const UserInfo = connect(
  selectors,
  actions
)(Component)
