import styled from 'styled-components'

export const LoaderItem = styled.div`
  width: 180px;
  height: 206px;
  margin: 0 0 10px 10px;
  border-radius: 18px;
  box-shadow: 0 0 10px 1px rgba(61, 61, 61, 0.42);
  @media screen and (max-width: 500px) {
    width: 150px;
    margin-right: 10px;
  }
`
export const LoaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding-top: 50px;
  max-width: 1400px;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    padding: 30px 0 0;
    justify-content: center;
  }
`
