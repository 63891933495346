import { createSelector } from "reselect";

const academicQualificationsData = store => store.academicQualifications;

export const getPredictionsAcademicQualifications = createSelector(
  academicQualificationsData,
  data => data.academicQualifications
);

export const getHighSchoolEdPredictionsLoadInfo = createSelector(
  academicQualificationsData,
  data => data.loadInfo
);
