import { createSelector } from 'reselect'

const settingsData = store => store.settings

export const getSettings = createSelector(settingsData, data => data.settings)

export const getSettingsLoadInfo = createSelector(
  settingsData,
  data => data.loadInfo
)
