import Component from "./Header";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { searchJobs, searchJobsByParams } from "Redux/actions/jobs";
import { getUserJobs, getJobsLoadInfo } from "Redux/selectors/jobs";
import { isAuthenticate } from "Redux/selectors/auth";
import { switchModal } from "../../../Redux/actions/utils";
import { getSettings } from "Redux/selectors/settings";

import { getUserNotifications, getUserEmail } from "Redux/selectors/user";

import { updateNotificationData } from "Redux/actions/user";
import { loadNotifications } from "Redux/actions/notifications";
import {
  loadCompanies,
  removeCompany,
  editCompanies
} from 'Redux/actions/companies'
import { getUser } from "Redux/selectors/user";
import { getUserNotificationData } from "Redux/selectors/user";
import { getCompanies } from 'Redux/selectors/companies'
import { getUserCompanies } from 'Redux/selectors/user'

const actions = {
  onSearchJobs: searchJobs,
  onSearchJobsByParams: searchJobsByParams,
  onSwitchModal: switchModal,
  onLoadNotifications: loadNotifications,
  onLoadCompanies: loadCompanies,
  onUpdateDate: updateNotificationData,
};
const selectors = createStructuredSelector({
  userJobs: getUserJobs,
  userJobsInfo: getJobsLoadInfo,
  isAuthenticate,
  settingsList: getSettings,
  notifications: getUserNotifications,
  predCompanies: getCompanies,
  companies: getUserCompanies,
  userEmail: getUserEmail,
  user: getUser,
  viewingDate: getUserNotificationData,
});
export default connect(selectors, actions)(Component);
