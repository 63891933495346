import styled from 'styled-components'
import SpinerComponent from 'react-spinkit'

export const Container = styled.section`
  padding: 0 60px 0 300px;
  position: relative;
  @media screen and (max-width: 1000px) {
    padding: 0 60px 0 20px;
  }
  @media screen and (max-width: 500px) {
    padding: 40px 10px 0;
  }
  @media screen and (max-width: 350px) {
    padding: 40px 0 0;
  }
`
export const TopItemIcon = styled.img`
  width: 27px;
  height: 27px;
`
export const ContentWrapperStyled = styled.div`
  height: 100%;
  padding-top: 20px;
  max-width: 1400px;
  font-family: Nunito;
  @media screen and (max-width: 800px) {
    position: static;
  }
  @media screen and (min-width: 500px) {
    width: 100%;
  }
`
export const Loader = styled(SpinerComponent).attrs({ name: 'ball-beat' })`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
`
export const StatusNotification = styled.div`
  width: 100%;
  background: red;
  border-radius: 20px;
  box-shadow: 0 0 5px #757575;
  margin: 0 0 10px 0;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 350px) {
    border-radius: 0;
  }
`
export const VerifyTitle = styled.p`
  font-size: 25px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: Nunito;
  font-weight: 700;
  margin: 0;
`
export const VerifyButton = styled.p`
  font-size: 25px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #ffffff;
  font-family: Nunito;
  font-weight: 700;
  margin: 0;
  cursor: pointer;
  margin-left: 5px;
`
