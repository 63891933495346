import React, { PureComponent } from 'react'
import FloatingLabel, { inputStyles, labelStyles } from 'floating-label-react'

import {
  Container,
  TextBlock,
  RightIcon,
  TextBlockContainer,
  Placeholder,
  PlaceholderText,
  textBoxStyles,
  RedStarBox
} from './styles'
import * as colors from 'Theme/colors'

// export default ({
//   borderColor = colors.mainBlue,
//   placeholder,
//   warning = false,
//   value,
//   onChange,
//   onFocus,
//   onBlur,
//   type
// }) => {
//   return (
//     <Container borderColor={borderColor}>
//       <TextBlockContainer>
//         <TextBlock
//           type={type}
//           required
//           value={value}
//           onChange={onChange}
//           // placeholder={placeholder}
//           onFocus={onFocus}
//           onBlur={onBlur}
//         />
//         <Placeholder>{placeholder}</Placeholder>
//       </TextBlockContainer>
//       {/* <FloatingLabel
//         placeholder={placeholder}
//         required
//         styles={{
//           input: {
//             ...inputStyles,
//             ...textBoxStyles
//           },
//           label: {
//             ...labelStyles,
//             marginTop: '.5em',
//             width: '100%',
//             fontSize: '18px'
//           }
//         }}
//         type={type}
//         value={value}
//         onBlur={onBlur}
//         onChange={onChange}
//         onFocus={onFocus}
//       /> */}
//       <RightIcon warning={warning}>
//         <i className="fas fa-exclamation-triangle" />
//       </RightIcon>
//     </Container>
//   )
// }
export default class TextBox extends PureComponent {
  state = {
    isActive: false
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { value } = nextProps

    return {
      ...prevState,
      isActive: !!value
    }
  }

  componentDidMount() {
    this.txtBox.addEventListener('blur', this.handleBlurPlaceholder)

    const width = this.txtBox.offsetHeight
    this.setState({
      offset: (width - 27) / 2
    })
  }

  componentWillUnmount() {
    this.txtBox.removeEventListener('blur', this.handleBlurPlaceholder)
  }

  handleBlur = e => {
    this.setState({ isActive: true })
    this.props.onBlur && this.props.onBlur(e)
  }

  handlePlaceholderClick = () => {
    this.txtBox.focus()

    this.setState({
      isActive: true
    })
  }

  render() {
    const {
      borderColor = colors.mainBlue,
      placeholder,
      warning = false,
      value,
      onChange,
      onFocus,
      type,
      onEnterPress,
      name,
      redStar
    } = this.props
    const { isActive, offset } = this.state

    return (
      <Container>
        <TextBlockContainer borderColor={borderColor}>
          <input
            onBlur={this.handleBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={e => e.keyCode === 13 && onEnterPress()}
            ref={el => (this.txtBox = el)}
            required
            style={textBoxStyles}
            // placeholder={placeholder}
            autoFocus={name}
            type={type}
            value={value}
          />
          <Placeholder
            active={isActive}
            top={offset}
            onClick={this.handlePlaceholderClick}
          >
            <PlaceholderText active={isActive}>
              {placeholder}
              {redStar ? <RedStarBox> *</RedStarBox> : null}
            </PlaceholderText>
          </Placeholder>
        </TextBlockContainer>
        {/* <FloatingLabel
        placeholder={placeholder}
        required
        styles={{
          input: {
            ...inputStyles,
            ...textBoxStyles
          },
          label: {
            ...labelStyles,
            marginTop: '.5em',
            width: '100%',
            fontSize: '18px'
          }
        }}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      /> */}
        <RightIcon warning={warning}>
          <i className="fas fa-exclamation-triangle" />
        </RightIcon>
      </Container>
    )
  }
}
