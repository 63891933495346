import { createReducer } from 'Helpers/redux'

import { LOAD } from 'Redux/actions/cities'

const initialState = {
  cities: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
}

const handlers = {
  [LOAD.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        ...state.loadInfo,
        isLoading: true,
        error: null
      }
    }
  },
  [LOAD.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        ...state.loadInfo,
        isLoading: false,
        error: payload.data
      }
    }
  },
  [LOAD.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      cities: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
