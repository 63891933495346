import { createReducer } from "Helpers/redux";

import { LOAD_EXPECTATIONS } from "Redux/actions/expectations";

const initialState = {
    expectations: [],
  loadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  }
};

const handlers = {
  [LOAD_EXPECTATIONS.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: true,
        error: null
      }
    };
  },
  [LOAD_EXPECTATIONS.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      loadInfo: {
        isLoading: false,
        isLoaded: false,
        error: payload.data
      }
    };
  },
  [LOAD_EXPECTATIONS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      expectations: payload.data,
      loadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    };
  }
};

export default createReducer(initialState, handlers);
