import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { notification } from '../../../Constants/routes'

import { Notifications } from './styled'

export default class NotificationsIcon extends PureComponent {
  static propTypes = {
    notifications: PropTypes.array
  }

  state = {
    notificationsLength: 0
  }

  static getDerivedStateFromProps(props, state) {
    const { notifications, viewingDate } = props
    const nextState = state
    const notViewed = notifications.filter(item => {
      return item.time > viewingDate
    })
    nextState.notificationsLength = notViewed.length

    return nextState
  }

  handleClick = event => {
    event.stopPropagation()
    const { history } = this.props
    history.push(notification)
  }

  render() {
    const { notificationsLength } = this.state

    if (notificationsLength) {
      return (
        <Notifications onClick={e => this.handleClick(e)}>
          {notificationsLength}
        </Notifications>
      )
    } else {
      return <React.Fragment />
    }
  }
}
