import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { getPostFiles } from 'Helpers/getImageLink'
import { ModalBackground, Container, Image } from './styled'

export default class ImagePreviewModal extends PureComponent {
  static propTypes = {
    onCloseModal: PropTypes.func.isRequired
  }

  renderContent = () => {
    const { onCloseModal, image } = this.props
    return (
      <ModalBackground onClick={onCloseModal}>
        <Container onClick={e => e.stopPropagation()}>
          <Image alt={image} src={getPostFiles(image)} />
        </Container>
      </ModalBackground>
    )
  }

  render() {
    return ReactDOM.createPortal(
      this.renderContent(),
      document.getElementById('root-modal')
    )
  }
}
