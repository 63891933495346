import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const MainContainer = styled(PerfectScrollbar)`
  display: flex;
  justify-content: center;
  padding: 18px 0 20px;
  position: relative;
  height: 100%;
  width: 100%;
  @media (max-width: 799px) {
    justify-content: flex-end;
  }
  @media (max-width: 500px) {
    height: 100%;
    margin-bottom: 60px;
  }
`
export const RightSide = styled.div``
export const LeftSide = styled.div``

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 799px) {
    width: 80%;
  }
`

export const EmptyListWrapper = styled.div`
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const EmptyListIcon = styled.i.attrs({ className: 'fas fa-bell' })`
  font-size: 30px;
  margin-bottom: 5px;
  color: #6d71f7;
`

export const EmptyListText = styled.p`
  font-size: 15px;
`
