import { connect } from 'react-redux'
import Component from './Initial'
import { withRouter } from 'react-router-dom'

import { linkedinRegister, resetOauthUser } from 'Redux/actions/register'
import { switchModal, closeModal } from '../../../Redux/actions/utils'
import {resetUserPassword} from '../../../Redux/actions/passwordReset'

const mapStateToProps = state => ({
  ...state
})

const actionCreators = {
  onResetOauthUser: resetOauthUser,
  onLinkedinAuth: linkedinRegister,
  onSwitchModal: switchModal,
  onCloseModal: closeModal,
  resetUserPassword: resetUserPassword
}

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(Component))
