import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  CloseButton,
  Content,
  Header,
  Icon,
  IconButton,
  Input,
  ItemIcon,
  ItemTitle,
  List,
  ListContainer,
  ListItem,
  ListTitle,
  modalStyle,
  modalStyleMobile,
  TextBoxContainer,
  Title
} from './styles'
import './style.css'
import Modal from 'react-modal'
import _ from 'lodash'
import withSizes from 'react-sizes'

/*
* items: array!
*   name: item.name
*   id: item.id
*   */
class SkillsModal extends PureComponent {
  static propTypes = {
    allItems: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    userItems: PropTypes.array,
    onEditComplete: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
    onRequestClose: PropTypes.func.isRequired
  }

  static defaultProps = {
    allItoms: [],
    userItems: []
  }

  constructor(props) {
    super()
    const state = {
      text: '',
      items: []
    }

    if (props.allItems.length) {
      const { allItems, userItems } = props
      state.items = this.setCheckedItems({ allItems, userItems })
    } else if (props.onLoad) {
      props.onLoad()
    }

    this.state = state
  }

  // //eslint-disable-next-line
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const { allItems, userItems } = nextProps
  //   const parsedItems = this.setCheckedItems({ allItems, userItems })
  //
  //   this.setState({
  //     items: parsedItems
  //   })
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.allItems !== this.props.allItems) {
      const { userItems, allItems } = this.props
      this.setState({
        items: this.setCheckedItems({
          allItems,
          userItems
        })
      })
    }
  }

  setCheckedItems = ({ allItems, userItems }) => {
    const parsed = allItems.map(item => {
      const isChecked = userItems.some(el => el.name === item.name)
      return {
        name: item.name,
        id: item.id,
        isChecked
      }
    })

    parsed.sort((a, b) => b.isChecked - a.isChecked)

    return _.uniqBy(parsed, 'id')
  }

  handleTextChange = ({ target }) => {
    const { allItems, userItems } = this.props
    const { value } = target
    if (value.length) {
      const filteredItems = allItems.filter(
        item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
      this.setState({
        text: value,
        items: this.setCheckedItems({ allItems: filteredItems, userItems })
      })
    } else {
      this.setState({
        text: value,
        items: this.setCheckedItems({ allItems, userItems })
      })
    }
  }

  handleItemClick = id => {
    const { items } = this.state
    const nextItems = items.map(item => {
      if (item.id === id) {
        if (item.isChecked) {
          return {
            ...item,
            isChecked: false
          }
        } else {
          return {
            ...item,
            isChecked: true
          }
        }
      } else {
        return {
          ...item
        }
      }
    })
    this.setState(
      prevState => nextItems !== prevState.items && { items: nextItems }
    )
  }

  renderItems = () => {
    const { items } = this.state
    return items.map((el, i) => (
      <ListItem
        key={i}
        selected={el.isChecked}
        onClick={() => this.handleItemClick(el.id)}
      >
        <ItemTitle>{el.name}</ItemTitle>
        <ItemIcon>
          <i
            className={
              el.isChecked ? 'fas fa-check-circle' : 'fas fa-plus-circle'
            }
          />
        </ItemIcon>
      </ListItem>
    ))
  }

  makeRequestObject = () => {
    const toDelete = []
    const toAdd = []
    outer: for (let i = 0; i < this.state.items.length; i++) {
      const item = this.state.items[i]
      for (let j = 0; j < this.props.userItems.length; j++) {
        const userItem = this.props.userItems[j]
        if (userItem.id === item.id) {
          if (!item.isChecked) {
            toDelete.push(item.id)
          }
          continue outer
        }
      }
      if (item.isChecked) toAdd.push(item.id)
    }
    return {
      toDelete,
      toAdd
    }
  }

  handleOkayClick = () => {
    const { onEditComplete, onRequestClose } = this.props
    const reqObj = this.makeRequestObject()

    onEditComplete(reqObj)
    onRequestClose()
  }

  render() {
    const {
      isMobile,
      onRequestClose,
      placeholder,
      title,
      isOpen,
      subTitle
    } = this.props
    const { text } = this.state
    return (
      <React.Fragment>
        {isMobile ? (
          <Modal ariaHideApp={false} isOpen={isOpen} style={modalStyleMobile}>
            <Content>
              <Header>
                <Title>{title}</Title>
                <CloseButton onClick={onRequestClose}>
                  <i className="fas fa-times" />
                </CloseButton>
              </Header>
              <TextBoxContainer>
                <Icon>
                  <i className="fas fa-search" />
                </Icon>
                <Input
                  placeholder={placeholder}
                  value={text}
                  onChange={this.handleTextChange}
                />
              </TextBoxContainer>
              <ListTitle>{subTitle}</ListTitle>
              <ListContainer>
                <List>{this.renderItems()}</List>
              </ListContainer>
              <Button onClick={this.handleOkayClick}>
                <IconButton>
                  <i className="fas fa-check-circle" />
                </IconButton>
                Okay!
              </Button>
            </Content>
          </Modal>
        ) : (
          <Modal ariaHideApp={false} isOpen={isOpen} style={modalStyle}>
            <Content>
              <Header>
                <Title>{title}</Title>
                <CloseButton onClick={onRequestClose}>
                  <i className="fas fa-times" />
                </CloseButton>
              </Header>
              <TextBoxContainer>
                <Icon>
                  <i className="fas fa-search" />
                </Icon>
                <Input
                  placeholder={placeholder}
                  value={text}
                  onChange={this.handleTextChange}
                />
              </TextBoxContainer>
              <ListTitle>{subTitle}</ListTitle>
              <ListContainer>
                <List>{this.renderItems()}</List>
              </ListContainer>
              <Button onClick={this.handleOkayClick}>
                <IconButton>
                  <i className="fas fa-check-circle" />
                </IconButton>
                Okay!
              </Button>
            </Content>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500
})

export default withSizes(mapSizeToProps)(SkillsModal)
