import styled from 'styled-components'
import { notificationRed } from '../../../Theme/colors'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const Container = styled.div`
  padding: 20px 10px;
  border-radius: 15px;
  background-color: rgb(253, 253, 253);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  width: 343px;
  @media screen and (max-width: 323px) {
    width: 308px;
  }
  margin: 20px;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(253, 253, 253);
  height: 471px;
  width: 323px;
  overflow-y: hidden;

  @media screen and (max-width: 323px) {
    width: 288px;
  }
`
export const ItemContainer = styled(PerfectScrollbar)`
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
`
export const Title = styled.h1`
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(0, 183, 254);
  text-transform: uppercase;
  line-height: 1.1;
  text-align: left;
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(0, 183, 254);
  border-radius: 40px;
  background-color: rgb(0, 183, 254);
  min-width: 92px;
  min-height: 42px;
  margin: 10px 0;
  cursor: pointer;
`
export const ButtonText = styled.p`
  font-size: 12px;
  font-family: 'Nunito';
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.75;
`
export const ItemEditableContaner = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 92px;
  min-height: 42px;
  background-color: rgb(255, 255, 255);
`
export const ItemEditable = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  border-color: rgb(0, 183, 254);
  min-width: 92px;
  min-height: 42px;
  background-color: rgb(255, 255, 255);
`
export const ItemEditableField = styled.input`
  font-size: 12px;
  height: 16px;
  font-family: 'Nunito';
  color: rgb(0, 183, 254);
  flex: 1;
  margin: 5px 5px;
  overflow: hidden;
  margin-bottom: 0;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
`

export const ItemText = styled.p`
  font-size: 12px;
  font-family: 'Nunito';
  color: rgb(0, 183, 254);
  line-height: 1.75;
  flex: 1;
`
export const RemoveButton = styled.span`
  position: relative;
  top: 0;
  right: ${props => (props.closed ? -15 : 0)};
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${notificationRed};
  transition: all 0.2s ease-in-out;
`
export const EditItemText = styled.p`
  font-size: 12px;
  font-family: 'Nunito';
  color: rgb(0, 183, 254);
  line-height: 1.75;
  font-style: italic;
`
export const editItemTextStyle = {
  fontSize: '16px',
  fontFamily: 'Nunito',
  color: 'rgb(0, 183, 254)',
  lineHeight: 1.75,
  fontStyle: 'italic'
}
export const PlusContainer = styled.span`
  font-size: 19px;
  padding-bottom: 5px;
  margin-right: 3px;
  color: #fff;
`
