import styled from "styled-components";

export const Container = styled.ul`
  width: 100%;
  height: initial !important;
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  overflow-y: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  
`;
