import Component from './PaswordReset'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getUserId, changeUserPassword } from 'Redux/actions/passwordReset'
import {
  getPasswordResetUserInfo,
  getPasswordResetUserId,
  getPasswordChangeInfo
} from 'Redux/selectors/passwordReset'
import {switchModal} from "../../../../../Redux/actions/utils";

const selectors = createStructuredSelector({
  userIdInfo: getPasswordResetUserInfo,
  userId: getPasswordResetUserId,
  passwordChangeInfo: getPasswordChangeInfo
})

const actions = {
  onGetUserID: getUserId,
  onChangePassword: changeUserPassword,
  onSwitchModal: switchModal
}

export default connect(
  selectors,
  actions
)(Component)
