import styled from 'styled-components'

import * as colors from '../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 60px 0 20px;
`
export const ItemBlock = styled.div`
  display: flex;
  align-items: center;
  margin: ${p => (p.isFirstElement ? '0 0 20px 0' : '5px 0 65px')};
  ${p => (p.bornElement ? 'width: 100%; max-width: 360px;' : null)};
  min-height: 87px;
  max-height: 87px;
  cursor: pointer;
  font-size: 25px;
  @media screen and (max-width: 730px) {
    ${p => (p.bornElement ? 'max-width: 333px;' : null)};
    font-size: 23px;
  }
  @media screen and (max-width: 700px) {
    ${p => (p.bornElement ? 'max-width: 260px;' : null)};
    font-size: 23px;
  }
  @media screen and (max-width: 620px) {
    ${p => (p.bornElement ? 'max-width: 237px;' : null)};
    font-size: 23px;
  }
  @media screen and (max-width: 600px) {
    ${p => (p.bornElement ? 'max-width: 225px;' : null)};
    font-size: 23px;
  }
  @media screen and (max-width: 580px) {
    ${p => (p.bornElement ? 'max-width: 185px;' : null)};
    font-size: 23px;
  }
  @media screen and (max-width: 550px) {
    ${p => (p.bornElement ? 'max-width: 167px;' : null)};
    font-size: 23px;
  }
  @media screen and (max-width: 520px) {
    ${p => (p.bornElement ? 'max-width: 157px;' : null)};
    font-size: 23px;
  }
  @media screen and (min-width: 400px) and (max-width: 500px) {
    ${p => (p.bornElement ? 'max-width: 207px;' : null)};
    font-size: 23px;
  }
  @media screen and (max-width: 400px) {
    ${p => (p.bornElement ? 'max-width: 175px;' : null)};
    font-size: 23px;
  }
`
export const DateBlock = styled.div`
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const DateText = styled.span`
  color: ${colors.chatPurple};
  font-size: 0.8em;
`
export const DateLine = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(0, 101, 255, 0.7),
    rgba(0, 101, 255, 0.7) 35%,
    rgba(95, 12, 211, 0.7)
  );
`
export const IconBlock = styled.div`
  margin: 0 15px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  min-height: 45px;
  border: solid 2px ${colors.chatPurple};
  border-radius: 50%;
  position: relative;
  font-size: 0.9em;
  color: ${colors.chatPurple};
`
export const VerticalLine = styled.div`
  position: absolute;
  width: 2px;
  height: 85px;
  bottom: 53px;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.chatPurple};
`
export const Icon = styled.i``
export const InfoBlock = styled.div`
  position: relative;
  display: flex;
  padding: 10px;
  width: 350px;
  justify-content: space-between;
  border: solid 1px ${colors.chatPurple};
  border-radius: 15px;
  @media screen and (max-width: 730px) {
    width: 325px;
  }
  @media screen and (max-width: 700px) {
    width: 250px;
  }
  @media screen and (max-width: 620px) {
    width: 230px;
  }
  @media screen and (max-width: 600px) {
    width: 220px;
  }
  @media screen and (max-width: 580px) {
    width: 180px;
  }
  @media screen and (max-width: 550px) {
    width: 160px;
  }
  @media screen and (max-width: 520px) {
    width: 150px;
  }
  @media screen and (min-width: 400px) and (max-width: 500px) {
    width: 200px;
  }
  @media screen and (max-width: 400px) {
    width: 170px;
  }
`
export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #757575;
  max-width: 88%;
  @media screen and (max-width: 400px) {
    max-width: 100%;
  }
`
export const Type = styled.h1`
  font-weight: normal;
  font-size: 0.9em;
  text-transform: uppercase;
  color: ${colors.careerBlue};
  position: absolute;
  top: -35px;
  left: 0;
  white-space: nowrap;
  @media screen and (max-width: 700px) {
    top: -25px;
    font-size: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`
export const Title = styled.h2`
  font-size: 0.8em;
  color: ${colors.chatPurple};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 3px 0;
  text-transform: capitalize;
`
export const SubTitle = styled.h4`
  font-weight: normal;
  font-size: 0.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  text-transform: capitalize;
`
export const Description = styled.p`
  margin: 5px 0 0;
  font-size: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const InfoButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border: solid 1px ${colors.chatPurple};
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  color: ${colors.chatPurple};
  @media screen and (min-width: 500px) and (max-width: 600px) {
    display: none;
  }
  @media screen and (max-width: 400px) {
    display: none;
  }
`
export const InfoButtonIcon = styled.i.attrs({
  className: 'fas fa-angle-right'
})``

export const BornIcon = styled.img`
  width: 35px;
  margin-right: 10px;
`
export const BornText = styled.p`
  font-size: 0.8em;
  color: #583ea1;
`
