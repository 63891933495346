import { createSelector } from 'reselect'

const getChat = store => store.chat

export const getMessages = createSelector(getChat, data => data.messages)

export const isChannelsMounted = createSelector(
  getChat,
  data => data.isChannelsMounted
)

export const getChannels = createSelector(getChat, data => data.channels)

export const getUnreadChatsCount = createSelector(
  getChat,
  ({ unreadChats }) => unreadChats && unreadChats.length
)
