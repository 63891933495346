import styled from 'styled-components'
import * as colors from '../../../../../../Theme/colors'

export const EditLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: rgba(117, 117, 117, 0.79);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(${p => (p.show ? 0 : '80px')});
  transition: transform 0.5s;
`
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Button = styled.input.attrs({ type: 'button' })`
  background: none;
  padding: 5px 0;
  border: none;
  text-transform: uppercase;
  color: #fff;
  margin: 0 15px;
  border-bottom: solid 1px
    ${p => (p.disable ? colors.careerBlue : 'rgba(117, 117, 117, 0.79)')};
  transition: border-bottom-color 0.3s;
  outline: none;
  &:hover {
    border-bottom-color: ${colors.careerBlue};
  }
`
