import React from 'react'
import { LoaderItem, LoaderContainer } from './styled'
import ContentLoader from 'react-content-loader'

export const SearchModalLoader = ({ searchLoaderWidth }) => {
  const renderItems = length => {
    const result = []
    for (let i = 0; i < length; i++) {
      result.push(
        <LoaderItem className="searchLoader" key={i} title="loading...">
          <ContentLoader
            height={50}
            width={searchLoaderWidth}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect
              x="5"
              y="8"
              rx="5"
              ry="5"
              width={searchLoaderWidth - 100}
              height="35"
            />
            <circle cx={searchLoaderWidth - 50} cy="25" r="20" />
          </ContentLoader>
        </LoaderItem>
      )
    }
    return result
  }

  return <LoaderContainer>{renderItems(10)}</LoaderContainer>
}
