import React, { PureComponent } from "react";
import { Window, IconBlock, TextBox, IconButton } from "Components/Blocks";
import { getAvatarSrc } from "../../../../../Helpers/avatar";
import isEmpty from "lodash/isEmpty";
import user from "Assets/images/user.png";
import * as colors from "Theme/colors";
import { Description, Logo } from "../blocks";
import { toast } from "react-toastify";
import {
  Content,
  List,
  ListItem,
  Form,
  InputContainer,
  BottomBlock,
  Link,
  Icon,
  BlankList,
} from "./styles";
import * as validation from "Constants/validation";

export default class LoginWindow extends PureComponent {
  state = {
    email: "",
    password: "",
    emailWarning: false,
    passwordWarning: false,
    recentUsers: [],
  };

  componentDidMount() {
    const emails = localStorage.getItem("saved_emails");
    const parsedEmail = emails ? emails.split(", ") : [];
    const avatars = localStorage.getItem("saved_avatars");
    const parsedAvatars = avatars ? avatars.split(", ") : [];
    const users = parsedEmail.map((email, i) => {
      return {
        email,
        avatar: parsedAvatars[i] ? getAvatarSrc(parsedAvatars[i]) : null,
      };
    });
    this.setState({ recentUsers: users });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { onClose, innerRoutes, changeWindow } = this.props;
    if (nextProps.isAuthenticate) {
      onClose();
    }
    if (nextProps.getUserconfirmedData.notCorfimed) {
      changeWindow(innerRoutes.resendLogiEmail);
    }
  }

  handleLogin = () => {
    if (!this.validate()) {
      this.props.onLogIn({
        email: this.state.email,
        password: this.state.password,
      });
    }
  };

  removeUserFromLocalsorage = (email) => {
    const recentUsers = this.state.recentUsers.filter(
      (el) => el.email !== email
    );
    const avatars = [],
      emails = [];
    recentUsers.forEach((el) => {
      avatars.push(el.avatar);
      emails.push(el.email);
    });
    if (!isEmpty(avatars) && !isEmpty(avatars)) {
      localStorage.setItem("saved_emails", emails.join(", "));
      localStorage.setItem("saved_avatars", avatars.join(", "));
    } else {
      localStorage.removeItem("saved_emails");
      localStorage.removeItem("saved_avatars");
    }
    this.setState({ recentUsers });
  };

  renderRecentUsers = () => {
    if (this.state.recentUsers.length) {
      return this.state.recentUsers.map((el, i) => {
        const avatar = el.avatar ? el.avatar : user;
        return (
          <ListItem key={i} title={el.email}>
            <IconBlock
              borderColor={colors.chatPurple}
              isCloseBtn
              onClose={() => this.removeUserFromLocalsorage(el.email)}
            >
              <Icon
                src={avatar}
                onClick={() => this.setState({ email: el.email })}
              />
            </IconBlock>
          </ListItem>
        );
      });
    } else {
      return <BlankList />;
    }
  };

  validate = () => {
    const { email } = this.state;
    if (!email && !email.length) {
      this.setState({ emailWarning: true });
      toast.error("Email is empty");
      return true;
    } else if (!validation.email.test(email)) {
      this.setState({ emailWarning: true });
      toast.error("Email is not valid");
      return true;
    } else if (!this.state.password && !this.state.password.length) {
      this.setState({ passwordWarning: true, emailWarning: false });
      toast.error("Password is empty");
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { changeWindow, innerRoutes, isAuthLoading, onClose } = this.props;
    return (
      <Window onClose={onClose}>
        <Content>
          <Logo onClose={onClose} />
          <Description>
            Hey.. to use this feature we need to know more about you..
          </Description>
          <List>{this.renderRecentUsers()}</List>
          <Form>
            <InputContainer>
              <TextBox
                placeholder="Your Email Address here"
                tabIndex="0"
                type="email"
                value={this.state.email}
                warning={this.state.emailWarning}
                onChange={(e) => this.setState({ email: e.target.value })}
                onEnterPress={this.handleLogin}
              />
            </InputContainer>
            <InputContainer>
              <TextBox
                placeholder="Your $tr0ng Password here"
                type="password"
                value={this.state.password}
                warning={this.state.passwordWarning}
                onChange={(e) => this.setState({ password: e.target.value })}
                onEnterPress={this.handleLogin}
              />
            </InputContainer>
          </Form>
          <BottomBlock>
            <IconButton
              icon="fas fa-check-circle"
              loading={isAuthLoading}
              title="Okay"
              onClick={this.handleLogin}
            />
            <Link
              marginLeft="0.8rem"
              onClick={() => changeWindow(innerRoutes.email)}
            >
              Oops.. I don't remember my login details
            </Link>
          </BottomBlock>
          <Link onClick={() => changeWindow(innerRoutes.switch)}>
            I don't have an account
          </Link>
        </Content>
      </Window>
    );
  }
}
