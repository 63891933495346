import styled, { keyframes } from "styled-components";
import * as colors from "../../../Theme/colors";
import { fadeIn, zoomIn } from "react-animations";
import { height } from "styled-system";
import { PreviewImg } from "../../Pages/DetailPage/styled";

const backgroundAnim = keyframes`${fadeIn}`;
export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  background: ${colors.bluePurpleGradient};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 0.3s ${backgroundAnim};
`;
const containerAnim = keyframes`${zoomIn}`;
//PreviewImg
// export const Container = styled.div`
//   width: 100%;
//   max-width: 500px;
//   background-color: #fff;
//   display: flex;
//   padding: 0;
//   align-items: center;
//   justify-content: center;
//   border-radius: 4px;
//   animation: 0.3s ${containerAnim};
//   @media screen and (max-width: 500px) {
//     width: 95%;
//   }
// `;
export const Container = styled.div`
  width: 100%;
  height: 80vh;
  max-width: 500px;
  background-color: #fff;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  animation: 0.3s ${containerAnim};
  @media screen and (max-width: 500px) {
    width: 95%;
  }
`;
export const Image = styled.img`
  width: 100%;
`;
