import React, { PureComponent } from 'react'
import _ from 'lodash'

import PerfectScrollbar from 'perfect-scrollbar'

import { api } from 'Config/App'
import * as routes from 'Constants/routes'
import defaultAvatar from 'images/defaultAvatar.png'

import { Element as ScrollElement, Link as ScrollLink } from 'react-scroll'

import { getAvatarSrc } from '../../../Helpers/avatar'

import {
  Personalities,
  OverallReview,
  ResultModal,
  UserInfoBlock,
  SocialBlock,
  ButtonViewTimeLine
} from './innerBlocks'
import { TimeLine } from '../CareerTimeLine/innerBlocks'

import {
  CandidateFeedbackPageContainer,
  Row,
  Block,
  CardContainer,
  AvatarContainer,
  Avatar,
  Name,
  Info,
  InfoText,
  MainInfo,
  NotificationContainer,
  Notification,
  IconButton,
  UserInfoContainer,
  CardHeader,
  TimeLineContainer,
  LoadingContainer,
  Loader
} from './styles'
import moment from 'moment'

class FeedbackOnCandidate extends PureComponent {
  constructor(props) {
    super()

    this.state = {
      personalities: [],
      selectedPers: [],
      persText: '',
      rate: 1,
      feedbackText: '',
      firstName: '',
      lastName: '',
      position: '',
      company: '',
      isNotfDisplayed: true,
      isResultModalWasOpened: false,
      userId: null,
      avatar: null,
      errors: {
        firstName: false,
        lastName: false,
        company: false,
        position: false,
        feedbackText: false,
        selectedPers: false
      }
    }

    this.scrollBar = null

    const img = new Image()
    img.onload = e => this.setState({ avatar: props.profile.avatar })
    img.src = props.profile.avatar
  }

  componentDidMount() {
    const { match, history, onProfileLoad, onPersonalitiesLoad } = this.props
    const { userIdentity } = match.params
    if (!userIdentity) {
      history.push(routes.initial)
    } else {
      onProfileLoad(userIdentity)
      onPersonalitiesLoad()
      this.setState({ userId: userIdentity })
    }
  }

  componentDidUpdate(prevProps) {
    const { history, profileLoadInfo } = this.props
    if (
      prevProps.uploadLoadInfo.isLoading &&
      this.props.uploadLoadInfo.isLoaded
    ) {
      history.push(routes.initial)
    }
    if (!this.scrollBar && profileLoadInfo.isLoaded) {
      this.scrollBar = new PerfectScrollbar('#feedbackScrollContainer', {
        suppressScrollX: true
      })
    }
  }

  handleOkayBtnClick = () => {
    // query looks must have array of personalities id, rate, feedback
    const {
      rate,
      feedbackText,
      selectedPers,
      firstName,
      lastName,
      position,
      company
    } = this.state

    if (
      !firstName.length ||
      !lastName.length ||
      !position.length ||
      !company.length ||
      !feedbackText.length ||
      !selectedPers.length
    ) {
      this.setState({
        errors: {
          firstName: !firstName.length,
          lastName: !lastName.length,
          position: !position.length,
          company: !company.length,
          feedbackText: !feedbackText.length,
          selectedPers: !selectedPers.length
        }
      })
    } else {
      const { profile, onFeedbackUpload } = this.props

      const query = {
        candidateId: profile.id,
        personalities: selectedPers,
        rate,
        feedbackText,
        inviteId: profile.invite_id,
        firstName,
        lastName,
        position,
        company
      }
      onFeedbackUpload(query)
    }
  }

  handlePersTextChange = e => {
    this.props.onPersonalitiesLoad(e.target.value)
    this.setState({
      persText: e.target.value
    })
  }

  handleRateChange = rate => {
    if (rate > 5 || rate < 0) return
    this.setState({ rate })
  }

  handleItemClick = i => {
    const selectedPers = this.state.selectedPers.splice(0)
    const isSelected = _.find(selectedPers, el => el === i)
    if (isSelected === undefined) {
      selectedPers.push(i)
      this.setState({ selectedPers })
    } else {
      const deletedSelected = selectedPers.filter(el => el !== i)
      this.setState({ selectedPers: deletedSelected })
    }
  }

  handleModalClose = () => {
    this.setState({ isResultModalWasOpened: true })
    //this.props.history.push(routes.initial)
  }

  handleChangeStateByName = ({ target }) => {
    const { name, value } = target
    this.setState({ [name]: value })
  }

  renderAvatar = () => {
    const { avatar: userAvatar } = this.props.profile

    const avatar = userAvatar ? getAvatarSrc(userAvatar) : defaultAvatar

    return <Avatar image={avatar} />
  }

  render() {
    const {
      profileLoadInfo,
      profile,
      personalities,
      uploadLoadInfo,
      timeLine
    } = this.props

    const {
      feedbackText,
      persText,
      selectedPers,
      rate,
      firstName,
      lastName,
      position,
      company,
      isNotfDisplayed,
      isResultModalWasOpened,
      errors
    } = this.state

    const now = moment(moment.now(), 'x').year()
    const born = moment(profile.dob).year()
    const years = now - born

    if (!profileLoadInfo.isLoaded) {
      return (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )
    }
    return (
      <CandidateFeedbackPageContainer id={'feedbackScrollContainer'}>
        {isNotfDisplayed && (
          <NotificationContainer>
            <Notification>
              {`Hi.. Welcome to MrJOBS! Please help ${
                profile.name
              } to get his new job by reviewing and reating him/her. Thank you`}
            </Notification>
            <IconButton
              onClick={() => this.setState({ isNotfDisplayed: false })}
            >
              <i className="fas fa-times" />
            </IconButton>
          </NotificationContainer>
        )}
        <UserInfoContainer>
          <UserInfoBlock
            error={errors.firstName}
            name={'firstName'}
            title={'Your First Name'}
            value={firstName}
            onChange={this.handleChangeStateByName}
          />
          <UserInfoBlock
            error={errors.lastName}
            name={'lastName'}
            title={'Your Last Name'}
            value={lastName}
            onChange={this.handleChangeStateByName}
          />
          <UserInfoBlock
            error={errors.company}
            name={'company'}
            title={'Your Company'}
            value={company}
            onChange={this.handleChangeStateByName}
          />
          <UserInfoBlock
            error={errors.position}
            name={'position'}
            title={'Your Position'}
            value={position}
            onChange={this.handleChangeStateByName}
          />
        </UserInfoContainer>
        <Row>
          <Block>
            <CardContainer>
              <CardHeader>
                <AvatarContainer>{this.renderAvatar()}</AvatarContainer>
                <Name>{profile.name}</Name>
                <Info>
                  {profile.dob ? <InfoText>Age: {years}</InfoText> : null}
                  {profile.location ? (
                    <InfoText>{profile.location}</InfoText>
                  ) : null}
                </Info>
                <SocialBlock
                  facebook={profile.facebook}
                  linkedin={profile.linkedin}
                  twitter={profile.twitter}
                />
              </CardHeader>
              <MainInfo>{profile.info}</MainInfo>
              <ScrollLink
                containerId={'feedbackScrollContainer'}
                duration={1000}
                smooth={true}
                to={'timeLine'}
              >
                <ButtonViewTimeLine
                  value={'View Career Time Line'}
                  onClick={() => {}}
                />
              </ScrollLink>
            </CardContainer>
          </Block>
          <Block error={errors.selectedPers}>
            <Personalities
              checked
              items={personalities}
              persText={persText}
              selected={selectedPers}
              onItemClick={this.handleItemClick}
              onTextChange={this.handlePersTextChange}
            />
          </Block>
          <Block>
            <OverallReview
              error={errors.feedbackText}
              feedbackText={feedbackText}
              isLoading={uploadLoadInfo.isLoading}
              rate={rate}
              onFeedbackTexChange={e =>
                this.setState({ feedbackText: e.target.value })
              }
              onStarsChange={this.handleRateChange}
              onSubmit={this.handleOkayBtnClick}
            />
          </Block>
        </Row>
        <ScrollElement name={'timeLine'}>
          <TimeLineContainer>
            <TimeLine
              academQualif={timeLine.academicQualifications}
              activities={timeLine.activities}
              positions={timeLine.experience}
              profQualif={timeLine.profQualf}
            />
          </TimeLineContainer>
        </ScrollElement>
        <ResultModal
          isOpen={
            (uploadLoadInfo.isLoaded || uploadLoadInfo.error) &&
            !isResultModalWasOpened
          }
          success={!uploadLoadInfo.error}
          onClose={this.handleModalClose}
        />
      </CandidateFeedbackPageContainer>
    )
  }
}

export default FeedbackOnCandidate
