import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import Component from './connectRedux'
import * as routes from 'Constants/routes'

export default () => {
  return (
    <Switch>
      <Route
        component={Component}
        path={`${routes.jobs}/:job`}
      />
      <Route
        exact
        path={`${routes.jobs}`}
        render={() => {
          return <Redirect to={`${routes.jobs}/all`} />
        }}
      />
    </Switch>
  )
  // return <Component {...props} />
}
