import React, { Component, Fragment } from "react";
import { isEmpty } from "lodash";
import {
  MenuImg,
  SliderBarLink,
  IconTitle,
  Circle,
  ProfileName,
  ProfileMessage,
  LinkUnauthorized,
  AvatarImage,
  Img,
  ImgBot,
  SliderBarLinkTo,
} from "../styled";
import {
  SideBarContainer,
  MenuContainer,
  MenuItem,
  MenuImgContainer,
  ContentWrapper,
  LoaderContainer,
  Loader,
  SliderBarLinkBlock,
} from "./styles";
import HeaderMenu from "../../Header";
import { Link, Redirect } from "react-router-dom";
import { getAvatarSrc } from "Helpers/avatar";

import show from "../../../../images/menu/show.png";
import jobsImg from "../../../../images/menu/jobs.png";
import contactImg from "../../../../images/menu/contactus.png";
import jobBot from "../../../../images/menu/jobBot.png";
import login from "../../../../images/menu/login.png";
import privacyImg from "../../../../images/menu/privacy.png";
import cookiesImg from "../../../../images/menu/cookies.png";
import sigup from "../../../../images/menu/Sigup.png";
import term from "../../../../images/menu/term.png";
import opp from "../../../../images/menu/opp.png";
import mess from "../../../../images/menu/mess.png";
import notUserAvatar from "../../../../Assets/images/notUserAvatar.jpg";

import { NotificationsIcon, Placeholder } from "Components/Blocks";
import "../style.css";
import modalRoutes from "Constants/modal_routes";
import { api } from "Config/App";
import PerfectScrollbar from "perfect-scrollbar";
import {
  initial,
  jobs,
  careerTimeLine,
  oppdoor,
  messages,
  notification,
  privacy,
  cookies,
  contactUs,
  settings,
  detail,
} from "Constants/routes";
import {
  Container,
  LittleLogo,
  LittleLogoContainer,
  Menu,
  NotificationsLink,
  SiteLogo,
} from "../../Header/slyled";
import logo from "images/header/MrJobsLogo.svg";
import home from "images/header/mrJobs_home.png";
import lupe from "images/header/lupeLogo.png";
import { information } from "../../../../../server/Constants/routes";

export class LayoutDesktop extends Component {
  componentDidMount() {
    const sidebar = document.getElementById("sidebarScrollContainer");
    const content = document.getElementById("contentScrollContainer");
    sidebar && new PerfectScrollbar(sidebar, { suppressScrollX: true });
    content && new PerfectScrollbar(content, { suppressScrollX: true });
  }

  handleAvatarClick = () => {
    const { history, openModal, isAuthenticate } = this.props;
    if (isAuthenticate) {
      history.push(careerTimeLine);
    } else {
      openModal(modalRoutes.login);
    }
  };

  handleClickNotifications = (event) => {
    event && event.stopPropagation();
    this.props.history.push(notification);
  };

  renderSettingsButtons = () => {
    const { history } = this.props;
    const { pathname: currentPath } = history.location;
    const { settingsList } = this.props;
    console.log(
      "🚀 ~ file: index.js ~ line 95 ~ LayoutDesktop ~ settingsList",
      settingsList
    );
    const arr = settingsList.map((el) => {
      const link = `${settings}${el.url}`;
      return (
        <SliderBarLinkTo key={el.url} selected={currentPath === link} to={link}>
          {el.title === "Cookie Policy" && (
            <ImgBot alt="Jobs For You" src={cookiesImg} />
          )}
          {el.title === "Privacy Policy" && (
            <ImgBot alt="Jobs For You" src={privacyImg} />
          )}
          {el.title === "T  &  C  " && <ImgBot alt="Jobs For You" src={term} />}
          {el.title.toUpperCase()}
        </SliderBarLinkTo>
      );
    });
    return arr;
  };

  renderHeader = () => {
    const {
      history,
      activeHeaderCategory,
      onHeaderCategoryClick,
      deleteHeaderCat,
      header,
      withHeaderNavigate,
      isAuthenticate,
    } = this.props;

    if (!isEmpty(header)) {
      return (
        <HeaderMenu
          DeleteHeaderCat={deleteHeaderCat}
          activeHeaderCategory={activeHeaderCategory}
          header={header}
          history={history}
          withHeaderNavigate={withHeaderNavigate}
          onHeaderCategoryClick={onHeaderCategoryClick}
        />
      );
    } else {
      const ContainerWithAuth = isAuthenticate ? Link : LinkUnauthorized;
      return (
        <Container>
          <Menu>
            <Link to={initial}>
              <SiteLogo>
                <img alt="Mr.JOBS" src={logo} />
              </SiteLogo>
            </Link>
            <LittleLogoContainer>
              <Link to={initial}>
                <LittleLogo alt="Mr.JOBS Home" src={home} />
              </Link>
            </LittleLogoContainer>
            <LittleLogoContainer onClick={this.toggleViewSearchModal}>
              <LittleLogo alt="Jobs Search" src={lupe} />
            </LittleLogoContainer>
            <LittleLogoContainer>
              <ContainerWithAuth
                to={notification}
                onClick={this.handleLinkWithAuthClick}
              >
                <NotificationsLink />
              </ContainerWithAuth>
            </LittleLogoContainer>
          </Menu>
          <Placeholder
            height={18}
            innerRef={(ref) => (this.mockElement = ref)}
            width={80}
          />
          <Placeholder height={18} width={80} />
          <Placeholder height={18} width={80} />
        </Container>
      );
    }
  };

  render() {
    const {
      children,
      isAuthenticate,
      user,
      history,
      renderLogInLogOut,
      handleScroll,
      onLinkWithAuthClick,
      openModal,
      userDataLoadInfo,
      settingsLoadInfo,
      postJob,
      unreadChatsCount,
    } = this.props;
    const { pathname: currentPath } = history.location;
    const ContainerWithAuth = isAuthenticate ? Link : LinkUnauthorized;
    const avatar = user.avatar ? getAvatarSrc(user.avatar) : notUserAvatar;
    const text = user.name;
    const getText = () => {
      if (text.length <= 30) return text;
      if (text.length > 30) {
        return (
          <>
            <p>{text.slice(0, 34)}...</p>
          </>
        );
      }
    };
    return (
      <React.Fragment>
        {this.renderHeader()}
        <SideBarContainer id={"sidebarContainer"}>
          <MenuContainer id="sidebarScrollContainer">
            <MenuItem>
              {isAuthenticate ? (
                <MenuImgContainer>
                  <Circle onClick={this.handleAvatarClick}>
                    <AvatarImage
                      alt="avatar"
                      selected={currentPath === careerTimeLine}
                      src={avatar}
                    />
                    <NotificationsIcon history={history} />
                  </Circle>
                  <ProfileName
                    // style={{
                    //   textOverflow: "ellipsis",
                    //   whiteSpace: "nowrap",
                    //   overflow: "hidden",
                    //   height: "100px",
                    //   width: "150px",
                    // }}
                    onClick={this.handleAvatarClick}
                  >
                    {getText()}
                  </ProfileName>
                </MenuImgContainer>
              ) : (
                <MenuImgContainer>
                  <Circle onClick={this.handleAvatarClick}>
                    <AvatarImage alt="avatar" src={notUserAvatar} />
                  </Circle>
                  {userDataLoadInfo.isLoading ? (
                    <LoaderContainer>
                      <Loader />
                    </LoaderContainer>
                  ) : (
                    <ProfileName onClick={this.handleAvatarClick}>
                      Log In
                    </ProfileName>
                  )}
                </MenuImgContainer>
              )}
            </MenuItem>
            <MenuItem>
              <Link to={initial}>
                <MenuImgContainer selected={currentPath === initial}>
                  <MenuImg selected={currentPath === initial}>
                    <Img alt="Show All Industry" src={show} />
                  </MenuImg>
                  <IconTitle selected={currentPath === initial}>
                    All Industries
                  </IconTitle>
                </MenuImgContainer>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={jobs}>
                <MenuImgContainer
                  selected={
                    currentPath === jobs ||
                    currentPath.match(detail) ||
                    currentPath.match(jobs)
                  }
                >
                  <MenuImg
                    selected={
                      currentPath === jobs ||
                      currentPath.match(detail) ||
                      currentPath.match(jobs)
                    }
                  >
                    <Img alt="Jobs For You" src={jobsImg} />{" "}
                  </MenuImg>
                  <IconTitle
                    selected={
                      currentPath === jobs ||
                      currentPath.match(detail) ||
                      currentPath.match(jobs)
                    }
                  >
                    Jobs For You
                  </IconTitle>
                </MenuImgContainer>
              </Link>
            </MenuItem>
            <MenuItem>
              <ContainerWithAuth to={oppdoor} onClick={onLinkWithAuthClick}>
                <MenuImgContainer selected={currentPath.match(oppdoor)}>
                  <MenuImg selected={currentPath.match(oppdoor)}>
                    <Img alt="Opportunity Door" src={opp} />{" "}
                  </MenuImg>
                  <IconTitle selected={currentPath.match(oppdoor)}>
                    Opportunity Door
                  </IconTitle>
                </MenuImgContainer>
              </ContainerWithAuth>
            </MenuItem>
            {/* <MenuItem>
              <ContainerWithAuth to={messages} onClick={onLinkWithAuthClick}>
                <MenuImgContainer>
                  <MenuImg selected={currentPath === messages}>
                    {" "}
                    <Img alt="Messages" src={mess} />{" "}
                  </MenuImg>
                  <IconTitle>Messages</IconTitle>
                  {unreadChatsCount > 0 && isAuthenticate ? (
                    <ProfileMessage>
                      <p style={{ position: "relative", top: 6 }}>
                        {unreadChatsCount}
                      </p>
                    </ProfileMessage>
                  ) : null}
                </MenuImgContainer>
              </ContainerWithAuth>
            </MenuItem> */}

            <MenuItem>
              <SliderBarLinkBlock>
                <SliderBarLink onClick={() => postJob(isAuthenticate)}>
                  <ImgBot alt="Jobs For You" src={jobBot} />
                  POST A JOB
                </SliderBarLink>
                {!isAuthenticate && (
                  <SliderBarLink onClick={() => openModal(modalRoutes.switch)}>
                    <ImgBot alt="Jobs For You" src={sigup} />
                    SIGN UP
                  </SliderBarLink>
                )}
                {!settingsLoadInfo.loading && this.renderSettingsButtons()}
                {/* <SliderBarLinkTo to={privacy}>PRIVACY</SliderBarLinkTo>
                <SliderBarLinkTo to={cookies}>COOKIES</SliderBarLinkTo> */}

                {/* <SliderBarLinkTo
                  selected={currentPath === contactUs}
                  to={contactUs}
                >
                  <ImgBot alt="Jobs For You" src={contactImg} />
                  CONTACT US
                </SliderBarLinkTo> */}

                {renderLogInLogOut()}
              </SliderBarLinkBlock>
            </MenuItem>
            <MenuItem>
              <p style={{ color: "#656565", textAlign: "center" }}>
                Mr.JOBS {new Date().getFullYear()}
              </p>
            </MenuItem>
          </MenuContainer>
          <ContentWrapper id="contentScrollContainer" onScroll={handleScroll}>
            {children}
          </ContentWrapper>
        </SideBarContainer>
      </React.Fragment>
    );
  }
}
