import React from 'react'
import PropTypes from 'prop-types'

import { Button, Icon } from 'antd'

const ButtonViewTimeLine = ({ value, onClick }) => {
  return (
    <Button
      htmlType={'button'}
      icon={<Icon type="caret-down" />}
      type={'normal'}
      onClick={onClick}
    >
      {value}
    </Button>
  )
}

ButtonViewTimeLine.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export { ButtonViewTimeLine }
