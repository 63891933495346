import React, {PureComponent} from 'react'

import {SelectBlock, SelectTitle, Select, SelectOption} from './styled'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

class SelectDatePicker extends PureComponent {
  render() {
    const {
      withMonthDatePicker,
      data,
      title,
      selectMonthName,
      selectYearName,
      onHandleChange,
      inputError,
      monthValue,
      yearValue,
      curentlyCheck
    } = this.props
    return (
      <SelectBlock>
        <SelectTitle>{title}</SelectTitle>
        {withMonthDatePicker && (
          <Select
            inputError={inputError}
            name={selectMonthName}
            placeholder="Month"
            style={{marginBottom: '5px'}}
            value={monthValue}
            onChange={onHandleChange}
          >
            <option disabled hidden selected value="">
              Month
            </option>
            {
              !curentlyCheck
                ? months.map(e => {
                  return (
                    <SelectOption key={`from${e}`} value={e}>
                      {e}
                    </SelectOption>
                  )
                })
                : <option disabled hidden selected value="">
                  Current month
                </option>
            }
          </Select>
        )}
        <Select
          inputError={inputError}
          name={selectYearName}
          placeholder="Year"
          value={yearValue}
          onChange={onHandleChange}
        >
          <option disabled hidden selected value="">
            Year
          </option>
          {
            !curentlyCheck
              ? data.map(e => {
                return (
                  <SelectOption key={`from${e}`} value={e}>
                    {e}
                  </SelectOption>
                )
              })
              : <option disabled hidden selected value="">
                Current year
              </option>
          }
        </Select>
      </SelectBlock>
    )
  }
}

export default SelectDatePicker
