import styled from "styled-components";

import StackGrid from "react-stack-grid";

import * as colors from "../../../../../Theme/colors";

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  font-family: Nunito;
`;
export const ContainerMod = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: red;
  position: fixed;
`;

export const CardsList = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;
