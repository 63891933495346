import styled, { keyframes } from 'styled-components'

const animArrow = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  10% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(76px);
  }
`

export const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SiteLogo = styled.div`
  margin: 0 10px;
`

export const ButtonContainer = styled.div``

export const LinkContainer = styled.div``

export const Image = styled.img`
  width: 100px;
`
export const AnimationBlock = styled.div`
  width: 66px;
  margin: 0 35px;
  display: flex;
  position: relative;
`
export const ArrowSpan = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  width: 10px;
  height: 30px;
  animation: ${animArrow} 2.7s linear infinite ${props => props.delay};
  &:after {
    content: '';
    position: absolute;
    top: 9px;
    height: 1px;
    width: 15px;
    transform: rotate(45deg);
    background-color: #3d3c3c;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 9px;
    height: 1px;
    width: 15px;
    transform: rotate(135deg);
    background-color: #3d3c3c;
  }
`
export const TextContainer = styled.p`
  font-size: 20px;
`