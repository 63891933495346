import React, { PureComponent } from "react";
import moment from "moment";
import _ from "lodash";
import withSizes from "react-sizes";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { getCompanyBackground } from "Helpers/companyBackground";
import { getCurrentPosition } from "Helpers/geo";
import modalRoutes from "Constants/modal_routes";
import { careerTimeLine } from "Constants/routes";
import htmlParser from "react-html-parser";

import { getPostFiles } from "Helpers/getImageLink";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Tooltip } from "antd";

import {
  Layout,
  DetailCarouselCard,
  ShareModal,
  DetailLoader,
  ImagePreviewModal,
} from "../../Blocks";
import top from "../../../images/OppDoor/finger.png";
import bird from "../../../images/detailsPage/bird.png";
import man from "../../../images/detailsPage/man.png";
import location from "../../../images/location.png";
import type from "../../../images/type.png";
import { createLink } from "Helpers/links";
import { initial, detail, jobs, redirectPage } from "../../../Constants/routes";

import {
  Container,
  TopContent,
  TitleContainer,
  Company,
  SubTitleContainer,
  IconsContainer,
  ButtonBlock,
  SaveButton,
  ShareButton,
  Icon,
  IconText,
  MiddleContent,
  BottomContent,
  Options,
  OptionList,
  OptionTitle2,
  Option,
  AdditionalInfo,
  JobDescription,
  Apply,
  ContactBlock,
  ContactBlockImg,
  TopImg,
  percentStyles,
  LoaderContainer,
  AdditionalInfoBlock,
  TitleText,
  AdditionalInfoText,
  OptionTitle,
  OptionTitleContainer,
  SliderContainer,
  SliderTitle,
  SocialIconItem,
  SocialIcon,
  SalaryContainer,
  SalaryItem,
  MatchRateContainer,
  DistanceSubText,
  OptionSubText,
  JobDescriptionTitle,
  JobDescriptionText,
  ContactBlockImgItem,
  IconImg,
  IconTextEllipsis,
  DownloadBlock,
  DownloadItem,
  DownloadItemBlock,
  PreviewImg,
  PreviewImgBlock,
} from "./styled";

import "../../Blocks/Cards/style.css";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import map from "lodash/map";

const CATEGORY_ALL = {
  id: 0,
  title: "All",
};

const CATEGORY_SAVED = {
  id: -1,
  title: "SAVED",
};

class Detail extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      applied: false,
      isShareModalOpen: false,
      isAppliedNow: false,
      previewImage: null,
    };

    const { vacancy, match, history, onLoadCurrentVacancy } = props;
    const { url } = match.params;

    if (vacancy === null) {
      if (url !== undefined) {
        onLoadCurrentVacancy(url);
      } else {
        history.push(careerTimeLine);
      }
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.isAppliedNow && nextProps.applyState.success) {
      nextProps.onUpdateNotifications();
      return {
        ...prevState,
        isAppliedNow: false,
      };
    }
  }

  // componentWillMount() {
  //   const { match, history, onLoadCurrentVacancy } = this.props
  //   const { urlId } = match.params
  //   if (urlId !== undefined) {
  //     onLoadCurrentVacancy(urlId)
  //   } else {
  //     history.goBack()
  //   }
  // }

  componentDidMount() {
    const {
      vacancy,
      salaryRange,
      onSalaryRangeLoad,
      userJobs,
      filter,
      onUserJobsLoad,
      onExpertisesLoad,
      expertises,
    } = this.props;

    if (!(userJobs.All && userJobs.All.length)) {
      // getCurrentPosition()
      //   .then((userLocation) => {
      //     onUserJobsLoad({
      //       ...filter,
      //       location: {
      //         userLocation,
      //       },
      //     });
      //   })
      //   .catch((err) => {
      //     onUserJobsLoad(filter);
      //   });
    }

    if (vacancy) {
      this.setState({ applied: vacancy.applied });
    }

    if (!salaryRange) {
      onSalaryRangeLoad();
    }

    if (!expertises.length) {
      onExpertisesLoad();
    }

    //align slider title
    const sliderTitle = document.getElementById("sliderTitle");
    const sliderButtons = document.getElementsByClassName("slick-arrow");
    const sliderContainer = document.getElementsByClassName("slick-track");
    if (!sliderButtons.length && sliderTitle) {
      sliderTitle.style.left = "10px";
      if (sliderContainer) {
        sliderContainer[0].style.marginLeft = 0;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeVacLoadInfo,
      history,
      onLoadCurrentVacancy,
      match,
    } = this.props;
    const { url } = match.params;

    if (!prevProps.isAuthenticate && this.props.isAuthenticate) {
      onLoadCurrentVacancy(url);
    }

    if (activeVacLoadInfo.error) {
      history.push(initial);
    }
  }

  componentWillUnmount() {
    this.props.onDeleteActiveVacancy();
  }

  // componentDidCatch(error, info) {
  //   console.error('Detail component have error: ', info)
  //   console.log('redirect to cards')
  //   return <Redirect to={jobs} />
  // }

  getContactPersonAvatarSource = (link) => {
    if (_.isString(link) && link.length > 0) return link;
    return man;
  };

  getVacancyImageSource = (img) => {
    return getCompanyBackground(img);
  };

  getIndustriesList = (industriesArray) => {
    if (!_.isArray(industriesArray)) return [];

    const list = industriesArray.map((ind) => {
      return {
        id: ind.industry_id,
        title: ind.industry_name,
      };
    });

    const fullList = [CATEGORY_ALL, ...list];

    const { savedVacancies } = this.props;

    if (_.isArray(savedVacancies) && savedVacancies.length > 0) {
      fullList.splice(0, 0, CATEGORY_SAVED);
    }

    return fullList;
  };

  handleApplyBtnClick = () => {
    const { vacancy, onApplyForVacancy } = this.props;

    onApplyForVacancy({ vacancyId: vacancy.id });

    this.setState({
      applied: true,
      isAppliedNow: true,
    });
  };

  renderApplyBtn = () => {
    const { applyState, vacancy, isAuthenticate, onOpenModal } = this.props;
    const { applied } = vacancy;

    const text = applied === 1 ? "Applied" : "Apply Now";
    const hanaldeClick = applyState.request
      ? () => {}
      : this.handleApplyBtnClick;
    const handleOpenSignIn = () => {
      onOpenModal(modalRoutes.login);
    };

    return (
      <Apply
        disabled={applied === 1}
        onClick={isAuthenticate ? hanaldeClick : handleOpenSignIn}
      >
        <img style={{ fontSize: "20px" }} alt="Apply button icon" src={bird} />{" "}
        {text}
      </Apply>
    );
  };

  handleHeaderCategoryClick = (category) => {
    const { onChangeJobTab, history } = this.props;
    onChangeJobTab(category);
    const headerRoute = (category.title || "")
      .split(/[\s/]+/)
      .join("_")
      .toLowerCase();
    history.push(`${jobs}/${headerRoute}`);
  };

  handleCardClick = (card) => {
    const { history, onSetActiveVacancy, isMobile } = this.props;
    if (card.type === "post") {
      if (isMobile) {
        document.getElementById("rootScrollContainer").scrollTo(0, 0);
      } else {
        document.getElementById("contentScrollContainer").scrollTo(0, 0);
      }
      onSetActiveVacancy(card);
      // console.log("card", card);
      history.push(`${detail}/${card.associated_link}`);
    }
    if (card.type === "link") {
      const redirectData = {
        name: card.name,
        image: card.image,
        id: card.id,
        link: card.link,
      };
      sessionStorage.setItem("redirect_data", JSON.stringify(redirectData));
      window.open(`${redirectPage}/${card.id}`, "_blank");
    }
  };

  handleSaveVacancy = (event, vacancy) => {
    event && event.stopPropagation();

    this.props.onSaveVacancy(vacancy.id, vacancy.type);
  };

  handleDeleteFromSaveVacancy = (event, vacancy) => {
    event && event.stopPropagation();

    this.props.onDeteFromSavedVacancy(vacancy.id);
  };

  handleCheckAuth = (event, value) => {
    event && event.stopPropagation();
    const { onOpenModal, isAuthenticate } = this.props;
    if (!isAuthenticate) {
      onOpenModal(modalRoutes.login);
    } else if (value) {
      this.handleCardClick(value);
    }
  };

  handleSaveClick = () => {
    const { vacancy, savedVacancies, isAuthenticate, onOpenModal } = this.props;
    const isVacancySaved = Boolean(
      savedVacancies.find((item) => item.id === vacancy.id)
    );

    if (isAuthenticate) {
      if (isVacancySaved) {
        this.handleDeleteFromSaveVacancy(false, vacancy);
      } else {
        this.handleSaveVacancy(false, vacancy);
      }
    } else {
      onOpenModal(modalRoutes.login);
    }
  };

  handleShareBtnClick = () => {
    const { vacancy } = this.props;
    this.setState({ isShareModalOpen: true });
    const urlForShare = vacancy.associated_link;
    sessionStorage.setItem("redirect_data", "/seeker/detail/" + urlForShare);
  };

  handleCloseShareModal = () => {
    this.setState({ isShareModalOpen: false });
  };

  getFormatSalary = (number) => {
    if (number !== undefined) return number;
    return null;
  };

  renderShare = (card) => {
    //old method
    const { savedVacancies, isAuthenticate, onOpenModal } = this.props;

    if (_.find(savedVacancies, (vac) => vac.id === card.id) === undefined) {
      return (
        <SocialIcon
          onClick={(event) =>
            isAuthenticate
              ? this.handleSaveVacancy(event, card)
              : onOpenModal(modalRoutes.login)
          }
        >
          <SocialIconItem />
        </SocialIcon>
      );
    } else {
      return (
        <SocialIcon
          onClick={(event) =>
            isAuthenticate
              ? this.handleDeleteFromSaveVacancy(event, card)
              : onOpenModal(modalRoutes.login)
          }
        >
          <SocialIconItem active />
        </SocialIcon>
      );
    }
  };

  renderBottomCards = () => {
    const {
      userJobs,
      savedVacancies,
      tab,
      isAuthenticate,
      vacancy,
    } = this.props;
     
    let cards = [];
    //-1
    if (tab.id === 0) {
      cards = savedVacancies;
    } else if (_.isArray(userJobs[tab.title])) {
      cards = userJobs[tab.title];
    } else {
      cards = (userJobs[CATEGORY_ALL.title] || []).filter(
        (job) => job.industry_id === tab.id
      );
    }

    let renderCards = map(cards, (value) => {
      const handleClick = () => this.handleCardClick(value);
      const background = getCompanyBackground(value.image);
      const link = createLink(detail, value.id);

      if (vacancy.id !== value.id) {
        return (
          <DetailCarouselCard
            background={background}
            isAuthenticate={isAuthenticate}
            link={link}
            renderShare={this.renderShare}
            value={value}
            onClickLink={handleClick}
            onClickMatchRate={(e) => this.handleCheckAuth(e, value)}
          />
        );
      }
    });

    renderCards = renderCards.filter((item) => !!item);
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    if (renderCards.length) {
      return (
        <SliderContainer>
          <SliderTitle id="sliderTitle">Related jobs:</SliderTitle>
          <Slider {...sliderSettings} className="slider-container">
            {renderCards}
          </Slider>
        </SliderContainer>
      );
    }
    return null;
  };

  renderDownloadrResource = () => {
    const { vacancy } = this.props;
    if (!vacancy.files) return;
    // console.log('vacancy.files', vacancy.files)
    const downloadArr = vacancy.files.split(",");
    return downloadArr.map((e, i) => (
      <DownloadItemBlock key={e}>
        <PreviewImgBlock onClick={() => this.handleOpenImagePreview(e)}>
          <PreviewImg alt={e} src={getPostFiles(e)} />
        </PreviewImgBlock>
      </DownloadItemBlock>
    ));
  };

  handleCloseImagePreview = () => {
    this.setState({
      previewImage: null,
    });
  };

  handleOpenImagePreview = (image) => {
    this.setState({
      previewImage: image,
    });
  };

  render() {
    const {
      vacancy,
      isAuthenticate,
      followedIndustries,
      activeVacLoadInfo,
      salaryRange,
      history,
      savedVacancies,
      tab,
      onDeleteHeaderCat,
      isMobile,
      expertises,
    } = this.props;

    const categories = this.getIndustriesList(followedIndustries);
    const { isShareModalOpen, previewImage } = this.state;
    if (activeVacLoadInfo.isLoading || !vacancy || !salaryRange)
      return (
        <Layout
          activeHeaderCategory={tab}
          DeleteHeaderCat={onDeleteHeaderCat}
          header={categories}
          history={history}
          onHeaderCategoryClick={this.handleHeaderCategoryClick}
        >
          <LoaderContainer>
            {isMobile ? (
              <DetailLoader type="mobile" />
            ) : (
              <DetailLoader type="desktop" />
            )}
          </LoaderContainer>
        </Layout>
      );
    const {
      distance,
      end_date,
      matchRate,
      job_types,
      company_name,
      associated_link,
      company_website,
      company_address,
      image,
      title,
      matchingTags,
      id: vacancyId,
      experience,
      files,
      address,
    } = vacancy;
    let experienceText = "";
    if (Number(experience) === 1) {
      experienceText = "1 year";
    } else if (Number(experience) > 1) {
      experienceText = `${experience} years`;
    }
    const isVacancySaved = Boolean(
      savedVacancies.find((item) => item.id === vacancyId)
    );

    const endDate = moment(end_date).format("Do MMM YYYY");
    const keywords = [];
    const jobTypes = job_types
      ? job_types.map((e) => {
          return e.name;
        })
      : [];
    const optionArr = vacancy.options.map((e) => {
      return e.name;
    });
    const isexpertisesArr = expertises.find(
      (e) => (e.id = vacancy.expertise_id)
    );
    const expertisesArr = isexpertisesArr ? isexpertisesArr.name : "";

    const keywordsArr = keywords
      .concat(jobTypes, optionArr, company_name, expertisesArr)
      .join(",");
    console.log(matchingTags, "matchingTags");
    return (
      <Layout
        DeleteHeaderCat={onDeleteHeaderCat}
        activeHeaderCategory={tab}
        header={categories}
        history={history}
        notScroll={true}
        withHeaderNavigate={true}
        onHeaderCategoryClick={this.handleHeaderCategoryClick}
      >
        <Helmet>
          <meta content="MrJOBS" property="og:title" />
          <meta content="article" property="og:type" />
          <meta
            content={this.getVacancyImageSource(image)}
            property="og:image"
          />
          <meta content={title} property="og:description" />
          <title>{title} | Mr.JOBS</title>
          <meta content={keywordsArr} name="keywords" />
          <meta content={vacancy.description} name="description" />
        </Helmet>
        <Container>
          <TopContent>
            <TopImg
              alt={`vacancy ${title} image`}
              src={this.getVacancyImageSource(image)}
            />
            <div>
              <TitleContainer>
                <TitleText>{title}</TitleText>
              </TitleContainer>
              <SubTitleContainer>
                <Company>{company_name}</Company>
                <ButtonBlock>
                  <Tooltip title={`Save `}>
                    <SaveButton
                      active={isVacancySaved}
                      onClick={this.handleSaveClick}
                    />
                  </Tooltip>
                  <Tooltip title={`Share `}>
                    <ShareButton onClick={this.handleShareBtnClick} />
                  </Tooltip>
                </ButtonBlock>
              </SubTitleContainer>
              <IconsContainer>
                <Icon matchRate>
                  <MatchRateContainer
                    isAuth={isAuthenticate}
                    onClick={(e) => this.handleCheckAuth(e)}
                  >
                    <CircularProgressbar
                      percentage={isAuthenticate ? matchRate || 0 : 0}
                      styles={percentStyles}
                      text={`${isAuthenticate ? matchRate || 0 : 0}%`}
                    />
                    <IconText matchRate>
                      Suitable
                      <br />
                      To You
                    </IconText>
                  </MatchRateContainer>
                </Icon>

                <Icon>
                  <IconImg alt={`${type} icon`} src={type} />
                  <IconText>
                    {job_types && job_types.length ? job_types[0].name : ""}
                  </IconText>
                </Icon>
                <Icon title={address.length ? address : company_address}>
                  <IconImg alt={`${location} icon`} src={location} />
                  <IconTextEllipsis>
                    {address.length ? address : company_address}
                  </IconTextEllipsis>
                </Icon>
                <a
                  href={company_website}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Icon>
                    <IconImg alt="View icon" src={top} />
                    <IconText>View</IconText>
                  </Icon>
                </a>
              </IconsContainer>
            </div>
          </TopContent>
          <MiddleContent>
            {job_types && job_types.length && (
              <Options>
                <OptionTitleContainer>
                  <OptionTitle>{job_types.length} Job Types:</OptionTitle>
                </OptionTitleContainer>
                <OptionList>
                  {job_types.map((tag, index) => (
                    <Option key={index * index}>{tag.name}</Option>
                  ))}
                </OptionList>
              </Options>
            )}
            {matchingTags && matchingTags.length > 0 && (
              <Options>
                <OptionTitleContainer>
                  {matchingTags.length > 0 ? (
                    <OptionTitle>
                      {matchingTags.length} Matching Tags:
                    </OptionTitle>
                  ) : null}
                </OptionTitleContainer>
                <OptionList>
                  {matchingTags.map((tag, index) => (
                    <Option key={index * index}>{tag}</Option>
                  ))}
                </OptionList>
              </Options>
            )}
            <AdditionalInfo>
              <AdditionalInfoBlock>
                <AdditionalInfoText>Salary Range (LKR):</AdditionalInfoText>

                {vacancy.salary_negotiable === "1" ? (
                  <SalaryContainer>
                    <SalaryItem style={{ fontSize: "15px" }}>
                      Negotiable
                    </SalaryItem>
                  </SalaryContainer>
                ) : (
                  <SalaryContainer>
                    <SalaryItem style={{ fontSize: "15px" }}>
                      {this.getFormatSalary(vacancy.salary_min)}
                    </SalaryItem>
                    -
                    <SalaryItem style={{ fontSize: "15px" }}>
                      {this.getFormatSalary(vacancy.salary_max)}
                    </SalaryItem>
                  </SalaryContainer>
                )}
              </AdditionalInfoBlock>

              {experience > 0 && (
                <AdditionalInfoBlock>
                  <AdditionalInfoText>Years of experience</AdditionalInfoText>
                  <SalaryContainer>
                    <SalaryItem>{experienceText}</SalaryItem>
                  </SalaryContainer>
                </AdditionalInfoBlock>
              )}

              <AdditionalInfoBlock>
                <OptionTitle>Contact:</OptionTitle>
                <ContactBlock>{vacancy.contact_person_name}</ContactBlock>
                <ContactBlockImg>
                  <ContactBlockImgItem
                    alt="contact person icon"
                    src={this.getContactPersonAvatarSource(
                      vacancy.contact_person_avatar
                    )}
                  />
                </ContactBlockImg>
              </AdditionalInfoBlock>

              {!(job_types.length === 1 && job_types[0].type === "remote") &&
                distance && (
                  <AdditionalInfoBlock>
                    <OptionTitle>Distance:</OptionTitle>
                    <DistanceSubText>
                      {distance.dist}{" "}
                      {distance.units ? distance.units.toUpperCase() : "KM"}
                    </DistanceSubText>
                  </AdditionalInfoBlock>
                )}

              <AdditionalInfoBlock>
                <OptionTitle2>Closing Date:</OptionTitle2>
                <OptionSubText>{endDate}</OptionSubText>
              </AdditionalInfoBlock>

              <AdditionalInfoBlock>{this.renderApplyBtn()}</AdditionalInfoBlock>
            </AdditionalInfo>
          </MiddleContent>

          <BottomContent>
            <JobDescription>
              <JobDescriptionTitle>Job Description:</JobDescriptionTitle>
              <JobDescriptionText>
                {htmlParser(vacancy.description)}
              </JobDescriptionText>
              <DownloadBlock>
                {files && (
                  <h4 style={{ fontSize: "20px", color: "rgb(117,117,117)" }}>
                    Attachments
                  </h4>
                )}
                <DownloadItem>{this.renderDownloadrResource()}</DownloadItem>
              </DownloadBlock>
            </JobDescription>
            {this.renderBottomCards()}
          </BottomContent>
          {isShareModalOpen ? (
            <ShareModal
              description={vacancy.description}
              id={vacancy.id}
              onCloseModal={this.handleCloseShareModal}
            />
          ) : null}
          {previewImage ? (
            <ImagePreviewModal
              image={previewImage}
              onCloseModal={this.handleCloseImagePreview}
            />
          ) : null}
        </Container>
      </Layout>
    );
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 600,
});

export default withSizes(mapSizeToProps)(Detail);
