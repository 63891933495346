import React, { PureComponent, Fragment } from 'react'

import { innerRoutes } from 'Constants/modalRoutes'
import { initial } from 'Constants/routes'

export default class Login extends PureComponent {
  constructor(props) {
    super()
    if (localStorage.getItem('candidate_id')) {
      props.history.push(initial)
    } else {
      props.onSwitchModal(innerRoutes.email)
    }
  }

  render() {
    return <Fragment />
  }
}
