import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import {
  Button,
  CloseButton,
  Content,
  Header,
  Icon,
  IconButton,
  Input,
  ItemIcon,
  ItemTitle,
  List,
  ListContainer,
  ListItem,
  ListTitle,
  modalStyle,
  modalStyleMobile,
  TextBoxContainer,
  Title
} from './styled'
import '../../../SkillsModal/style.css'
import Modal from 'react-modal'
import _ from 'lodash'
import withSizes from 'react-sizes'
import WarningModal from '../../../../../../Blocks/WarningModal'
import swal from 'sweetalert'

class InterestsModal extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      text: '',
      items: props.allItems,
      opernWarningModal: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allItems !== this.props.allItems) {
      const { allItems } = this.props
      this.setState({
        items: allItems
      })
    }
  }

  handleTextChange = ({ target }) => {
    const { value } = target

    this.setState({
      text: value
    })
  }

  handleAddInterest = () => {
    const { text } = this.state
    if (/\S/.test(text)) {
      const addArr = [...this.state.items, text]
      this.setState({ items: addArr, text: '' })
    }
  }

  handleItemClick = value => {
    const { items } = this.state
    const nextItems = items.filter(el => el !== value)
    this.setState(
      prevState => nextItems !== prevState.items && { items: nextItems }
    )
  }

  renderItems = () => {
    const { items } = this.state
    return items.map((el, i) => (
      <ListItem key={el} onClick={() => this.handleItemClick(el)}>
        <ItemTitle>{el}</ItemTitle>
        <ItemIcon>
          <i className={'fas fa-times'} />
        </ItemIcon>
      </ListItem>
    ))
  }

  handleOkayClick = () => {
    const { onEditComplete, onRequestClose } = this.props
    const interests = this.state.items.join(',')
    onEditComplete(interests)
    onRequestClose()
  }

  toogleWarnModal = () => {
    const { items } = this.state
    const { allItems, onRequestClose } = this.props;
    // console.log('items=====>', items, this.props.allItems);
    if(isEqual(items, allItems)){
      onRequestClose()
    } else {
      this.setState({ opernWarningModal: !this.state.opernWarningModal })
    }
  }

  closeMainModal = () => {
    const { onRequestClose } = this.props
    this.setState({ opernWarningModal: false })
    onRequestClose()
  }

  confirmModal = () => {
    swal({
      icon: 'warning',
      text: 'Are you sure you want to discard changes to this ?',
      buttons: {   
        confirm: {
          text: 'Discard',
          value: true,
          visible: true
        },
        cancel: {
          text: 'No Thanks',
          value: false,
          visible: true
        }
      }
    })
      .then(value => {
        if (value) this.closeMainModal()
      })
  }

  render() {
    const {
      isMobile,
      onRequestClose,
      placeholder,
      title,
      isOpen,
      subTitle
    } = this.props
    const { text, opernWarningModal } = this.state

    return (
      <React.Fragment>
        {isMobile ? (
          <Modal ariaHideApp={false} isOpen={isOpen} style={modalStyleMobile}>
            <Content>
              <Header>
                <Title>{title}</Title>
                <CloseButton onClick={this.toogleWarnModal}>
                  <i className="fas fa-times" />
                </CloseButton>
              </Header>
              <TextBoxContainer>
                <Input
                  placeholder={placeholder}
                  value={text}
                  onChange={this.handleTextChange}
                />
                <Icon onClick={this.handleAddInterest}>
                  <i className="fas fa-plus-circle" />
                </Icon>
              </TextBoxContainer>
              <ListTitle>{subTitle}</ListTitle>
              <ListContainer>
                <List>{this.renderItems()}</List>
              </ListContainer>
              <Button onClick={this.handleOkayClick}>
                <IconButton>
                  <i className="fas fa-check-circle" />
                </IconButton>
                Okay!
              </Button>
            </Content>
          </Modal>
        ) : (
          <Modal ariaHideApp={false} isOpen={isOpen} style={modalStyle}>
            <Content>
              <Header>
                <Title>{title}</Title>
                <CloseButton onClick={this.toogleWarnModal}>
                  <i className="fas fa-times" />
                </CloseButton>
              </Header>
              <TextBoxContainer>
                <Input
                  placeholder={placeholder}
                  value={text}
                  onChange={this.handleTextChange}
                />
                <Icon onClick={this.handleAddInterest}>
                  <i className="fas fa-plus-circle" />
                </Icon>
              </TextBoxContainer>
              <ListTitle>{subTitle}</ListTitle>
              <ListContainer>
                <List>{this.renderItems()}</List>
              </ListContainer>
              <Button onClick={this.handleOkayClick}>
                <IconButton>
                  <i className="fas fa-check-circle" />
                </IconButton>
                Save
              </Button>
            </Content>
          </Modal>
        )}
        {/*<WarningModal
          onCloseMainModal={this.closeMainModal}
          onCloseWarnMobal={this.toogleWarnModal}
        />*/}
        {opernWarningModal ? (
          this.confirmModal()
        ) : null}
      </React.Fragment>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500
})

export default withSizes(mapSizeToProps)(InterestsModal)
