import React, { PureComponent } from 'react'
import { Container, ItemText, RemoveButton } from './styles'

export class Item extends PureComponent {
  state = {
    closeBtnVisible: false
  }

  render() {
    return (
      <Container
        onMouseEnter={() => this.setState({ closeBtnVisible: true })}
        onMouseLeave={() => this.setState({ closeBtnVisible: false })}
      >
        <ItemText>{this.props.text}</ItemText>
        <RemoveButton
          closed={this.state.closeBtnVisible}
          onClick={this.props.onRemove}
        >
          <i className="fas fa-times" />
        </RemoveButton>
      </Container>
    )
  }
}
