import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-height: 100vh;
  background-color: #f7f7f7;
  @media screen and (max-width: 500px) {
    overflow-y: auto;
  }
`

export const Content = styled.div`
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to top,
    rgba(0, 101, 255, 0.7),
    rgba(0, 101, 255, 0.7) 35%,
    rgba(95, 12, 211, 0.7)
  );
  font-family: Nunito;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
`
export const ActionsListContainer = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25%;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
`
export const ActionsListLogo = styled.div`
  font-size: 48px;
  margin: 16px 0;
`
export const ActionsListHeader = styled.div`
  font-size: 27px;
  margin: 8px 0;
`
export const ActionsList = styled.ul`
  list-style: none;
  margin: 16px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`
export const ActionListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 9.6px;
  font-size: 21px;
  cursor: pointer;
`
export const ActionListButtons = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ActionListButton = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid white;
  color: white;
  border-radius: 20px;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
`
