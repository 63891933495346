import styled from 'styled-components'

import * as colors from '../../../../../../Theme/colors'

export const CardImageIconDefault = styled.i``

export const CardImageIcon = styled.div`
  background: url("${p => p.image}") no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

export const CardImageContainer = styled.div`
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  border: solid 1px ${colors.chatPurple};
  color: ${colors.chatPurple};
  margin: 15px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  @media screen and (max-width: 700px) {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    margin: 0;
  }
`
