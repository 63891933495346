import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import { Picker } from 'emoji-mart'
import onClickOutside from 'react-onclickoutside'
import ReactHtmlParser from 'react-html-parser'
import PerfectScrollbar from 'perfect-scrollbar'

import { getChatMessageDate } from 'Helpers/date'
import { getAvatarSrc } from 'Helpers/avatar'
import emojify from 'Helpers/emoji'

import {
  ContactCloud,
  YourCloud,
  Message,
  TextInputContainer,
  StyledInput,
  IconsContainer,
  SendButton,
  ChatContainer,
  Row,
  Nickname,
  Date,
  MessageText,
  MessagesContainer,
  MobileBackBtn
} from './styled'

import 'emoji-mart/css/emoji-mart.css'

class Content extends Component {
  constructor(props) {
    super(props)

    this.messagesContainerRef = React.createRef()
  }

  state = {
    emoji: 'none',
    isEmojiShown: false,
    message: '',
    inputWidth: 0
  }

  componentDidMount() {
    this.scrollbar = new PerfectScrollbar('#messagesScrollContainer')
    const { messages, onMessageRead } = this.props
    this.setState({ inputWidth: document.getElementById('chat').offsetWidth })
    window.addEventListener('resize', this.updateInputWidth)
    this.markMessagesAsRead(messages, onMessageRead)

    this.scrollToBottom()
  }

  //eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { messages, onMessageRead } = nextProps

    this.markMessagesAsRead(messages, onMessageRead)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeChat !== this.props.activeChat ||
      prevProps.messages.length !== this.props.messages.lenght
    ) {
      this.scrollbar.update()
      this.scrollToBottom()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateInputWidth)
  }

  updateInputWidth = () => {
    this.setState({ inputWidth: document.getElementById('chat').offsetWidth })
  }

  scrollToBottom = smooth => {
    if (this.messagesContainerRef.current) {
      const { current } = this.messagesContainerRef
      this.messagesContainerRef.current.scrollTop = current.scrollHeight
    }
    if (smooth) {
      //todo add animated scroll when add message
    }
  }

  markMessagesAsRead = (messages, onMessageRead) => {
    // you can add scroll to the first unread here

    const unreadMessages = messages.filter(
      msg => msg.status !== 'Seen' && msg.writter === 'contact_person'
    )
    onMessageRead(unreadMessages)
  }

  emojiVisible = () => {
    this.setState({ emoji: 'block' })
  }

  handleClickOutside = () => {
    this.setState({ emoji: 'none' })
  }

  handleAddEmoji = (emoji, event) => {
    const nextMsg = this.state.message + emoji.colons

    this.setState({
      message: nextMsg
    })
  }

  handleToogleEmojiShown = () => {
    this.setState({
      isEmojiShown: !this.state.isEmojiShown
    })
  }

  handleChangeMessageText = ({ target }) =>
    this.setState({ message: target.value })

  handleSendMessage = () => {
    const { onSendMessage } = this.props
    const { message } = this.state
    if (message.trim().length) {
      onSendMessage(message)

      this.setState({ message: '' }, () => this.messageInputRef.focus())
    }
  }

  renderMessages = (messages, activeChat) => {
    const avatarSrc = getAvatarSrc(activeChat.companion_avatar)
    return messages.map(el => {
      const msgDate = getChatMessageDate(el.created_at)

      if (el.writter === 'candidate') {
        return (
          <Message content="flex-end">
            <YourCloud>
              <Row alignContent="flex-end" justifyContent="space-between">
                <MessageText>
                  {ReactHtmlParser(emojify(unescape(el.message)))}
                </MessageText>
                <Date>{msgDate}</Date>
                <Date>
                  {el.status === 'Seen' ? (
                    <i class="fas fa-check-double" />
                  ) : (
                    <i class="fas fa-check" />
                  )}
                </Date>
              </Row>
            </YourCloud>
          </Message>
        )
      } else {
        return (
          <Message content="flex-start">
            <ContactCloud>
              <Row justifyContent="space-between">
                <Nickname>{activeChat.companion_name}</Nickname>
                <Date>{msgDate}</Date>
              </Row>
              <Row alignContent="center" justifyContent="space-between">
                <MessageText>
                  {ReactHtmlParser(emojify(unescape(el.message)))}
                </MessageText>
                <Date>
                  {el.status === 'Seen' ? (
                    <i class="fas fa-check-double" />
                  ) : (
                    <i class="fas fa-check" />
                  )}
                </Date>
              </Row>
            </ContactCloud>
          </Message>
        )
      }
    })
  }

  handleKeyPress = target => {
    if (target.charCode === 13) {
      this.handleSendMessage()
    }
  }

  render() {
    const { isEmojiShown, message } = this.state
    const { activeChat, messages, onBackClick } = this.props
    const { clientWidth } = document.documentElement

    return (
      <ChatContainer id="chat">
        <MediaQuery maxDeviceWidth={899}>
          <MobileBackBtn onClick={onBackClick} />
        </MediaQuery>
        {/* {this.props.user.messages.name} */}
        <MessagesContainer
          id="messagesScrollContainer"
          innerRef={this.messagesContainerRef}
        >
          {this.renderMessages(messages, activeChat)}
        </MessagesContainer>
        <TextInputContainer width={this.state.inputWidth}>
          <SendButton onClick={this.handleSendMessage}>Send</SendButton>
          <StyledInput
            id="message_string"
            placeholder="Enter your message..."
            innerRef={el => (this.messageInputRef = el)}
            value={message}
            wrap="soft"
            onChange={this.handleChangeMessageText}
            onKeyPress={this.handleKeyPress}
          />
          <IconsContainer>
            <i
              className="far fa-smile"
              style={{ fontSize: 25, cursor: 'pointer' }}
              onClick={this.handleToogleEmojiShown}
            />
            <Picker
              set="apple"
              style={{
                display: isEmojiShown ? 'block' : 'none',
                position: 'absolute',
                bottom: clientWidth > 500 ? '40px' : '50px',
                right: clientWidth > 500 ? '20px' : 0
              }}
              onClick={(emoji, event) => {
                this.handleAddEmoji(emoji, event)
              }}
            />
          </IconsContainer>
        </TextInputContainer>
      </ChatContainer>
    )
  }
}

export default onClickOutside(Content)
