import styled from 'styled-components'
import * as colors from 'Theme/colors'

export const Container = styled.div`
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  border-color: rgb(0, 183, 254);
  min-width: 92px;
  min-height: 42px;
  background-color: rgb(255, 255, 255);
  position: relative;
  cursor: pointer;
  padding: 0 10px;
  color: ${colors.mainBlue};
  &:hover {
    background-color: ${colors.mainBlue};
    color: white;
  }
`

export const ItemText = styled.p`
  font-size: 16px;
  font-family: 'Nunito';
  line-height: 1.75;
  flex: 1;
  overflow: hidden;
  margin-bottom: 0;
  text-align: center;
`
export const RemoveButton = styled.span`
  margin-left: 10px;
  font-size: 16px;
`
