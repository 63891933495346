// eslint-disable-next-line
export const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// eslint-disable-next-line
export const simple = /^[a-zA-Z0-9_\s]+$/
// eslint-disable-next-line
export const password = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/g

export const passwordValidate = (password, confirmPassword) => {
  const number = /(?=.*[0-9])/
  const loverCase = /(?=.*[a-z])/
  const upperCase = /(?=.*[A-Z])/
  const specialCharacters = /(?=.*[!@#$%^&*])/
  const lenght = /[0-9a-zA-Z!@#$%^&*]{8,}$/

  if (!lenght.test(password)) {
    return 'lengthError'
  } else if (!loverCase.test(password)) {
    return 'loverCaseError'
  } else if (!upperCase.test(password)) {
    return 'upperCaseError'
  } else if (!number.test(password)) {
    return 'numberError'
  } else if (!specialCharacters.test(password)) {
    return 'specialCharactersError'
  } else if (password !== confirmPassword) {
    return 'passwordsNoMatch'
  } else {
    return 'Success'
  }
}
