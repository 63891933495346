import React from 'react'
import PropTypes from 'prop-types'

import { AddButton } from '../../../blocks'
import { ExperienceItem } from '../../blocks'

import { Container, Title } from './styled'

const ExpertiseBlock = ({ title, items }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {items.map(item => (
        <ExperienceItem expId={item.id} level={item.level} name={item.name} />
      ))}
    </Container>
  )
}

ExpertiseBlock.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onRatingClick: PropTypes.func.isRequired
}

ExpertiseBlock.defaultProps = {
  items: []
}

export { ExpertiseBlock }
