import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import * as colors from '../../../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  margin: 10px 0 0;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`

export const CardContent = styled.div`
  padding: 0 0 15px 0;
  border-bottom: solid 1px silver;
  margin: 0 0 0 30px;
  width: 100%;
  position: relative;
  @media screen and (max-width: 700px) {
    margin: 0 0 0 15px;
  }
  @media screen and (max-width: 400px) {
    margin: 5px 0 0;
  }
`

export const Title = styled.h2`
  font-size: 20px;
  color: ${colors.careerBlue};
  font-weight: normal;
`

export const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

export const Text = styled.span`
  color: #757575;
  font-size: 18px;
`

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const EditIcon = styled.div`
  color: #fff;
  cursor: pointer;
  color: ${isMobile ? colors.chatPurple : 0};
  ${Container}:hover & {
    color: ${colors.chatPurple};
  }
`
