import React, { PureComponent } from 'react'

import { careerTimeLine } from 'Constants/routes'
import { innerRoutes } from 'Constants/modalRoutes'

import Window from 'Components/Pages/Initial/inner_pages/SuccesWindow'

import Img from 'Assets/images/icons8-id-verified-100.png'

import { Container } from './styles'

export default class EmailConfirmation extends PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { identity }
      },
      history,
      onConfirm
    } = this.props

    if (identity) {
      onConfirm(identity)
    }
    // history.push(initial)
    //fix blink-effect
    // setTimeout(() => {
    //   this.props.onSwitchModal(innerRoutes.login)
    // }, 1000)
  }

  render() {
    const { onSwitchModal, history, isAuthenticate, isEmailConfirmed } = this.props

    return (
      <Container>
        <Window
          isAuthenticate={isAuthenticate}
          bigText={'Email Successfully Verified'}
          link={'login'}
          smallText={'Click here to '}
          source={Img}
          onClose={() => history.push(careerTimeLine)}
          onSwitchModal={onSwitchModal}
          isHiddenAuto={false}
          smallTextInRow={true}
          isEmailConfirmed={isEmailConfirmed}
        />
      </Container>
    )
  }
}
