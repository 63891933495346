import { createSelector } from 'reselect'

const optionsData = store => store.options

export const getOptions = createSelector(optionsData, data => data.options)

export const getOptionsLoadInfo = createSelector(
  optionsData,
  data => data.loadInfo
)
