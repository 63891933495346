import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import 'antd/lib/input/style/css'

import { detail } from '../../../../../Constants/routes'
import _ from 'lodash'
import CircularProgressbar from 'react-circular-progressbar'

import { SearchModalLoader } from '../../../../Blocks'

import { Select } from '../Select'
import {
  Content,
  CloseButton,
  SearchTextContainer,
  SearchText,
  Button,
  IconButton,
  SearchBlock,
  ResultBlock,
  ResultList,
  ListItem,
  ResultItemTitle,
  ResultItemLocation,
  ResultItemButton,
  ResultTextContainer,
  ProgressbarContainer,
  percentStyles,
  ResultListEmptyText,
  SearchIsEmpty,
  StartSearchContainer,
  StartSearchIcon,
  IconButtonItemIcon,
  Container
} from './styles'
import './style.css'

export default class SearchModal extends PureComponent {
  constructor() {
    super()

    this.state = {
      text: '',
      industry: null,
      jobType: null,
      locText: '',
      coords: null,
      kms: '20',
      initScrollbar: false,
      searchLoaderWidth: null
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const nextState = prevState

    if (!prevState.coords && !_.isEmpty(nextProps.user)) {
      nextState.coords = { lat: nextProps.user.lat, lng: nextProps.user.lon }
      nextState.locText = nextProps.user.location || ''
      nextState.kms = nextProps.user.prefered_location_distance || 20
    }

    return nextState
  }

  componentDidMount() {
    const {
      industries,
      onLoadIndustries,
      jobTypes,
      onLoadJobTypes
    } = this.props

    if ((industries && !industries.length) || !industries) {
      onLoadIndustries()
    }

    if ((jobTypes && !jobTypes.length) || !jobTypes) {
      onLoadJobTypes()
    }
  }

  componentDidUpdate() {
    const container = this.resultContainer
    if (container) {
      window.addEventListener('resize', this.calcLoaderWidth)
    } else {
      window.removeEventListener('resize', this.calcLoaderWidth)
    }
    this.calcLoaderWidth()
  }

  calcLoaderWidth = () => {
    const container = this.resultContainer
    const elements = document.getElementsByClassName('searchLoader')
    if (elements && elements.length && container) {
      const containerWidth = container.clientWidth
      const elementWidth = containerWidth - 40
      this.setState({ searchLoaderWidth: elementWidth })
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.width = elementWidth
      }
    }
  }

  handleCloseSearchItm = e => {
    e.stopPropagation()
    this.setState({ isOpenResultList: false })
  }

  handleKeyDown = e => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      return
    }

    const char = String.fromCharCode(e.keyCode)

    if (isNaN(Number(char))) {
      e.preventDefault()
    }
  }

  handleOkayClick = () => {
    const { industry, coords, kms, jobType, text } = this.state
    const { onSearchByParams } = this.props

    const parsedKms = Number(kms)
    const trimmedText = _.trim(text)

    onSearchByParams({
      searchText: trimmedText,
      filter: {
        kms: parsedKms || 20,
        coords,
        industry: industry ? industry.id : null,
        jobType: jobType ? jobType.id : null
      }
    })
  }

  handleSetLocation = ({ coords, locText }) => {
    this.setState({ coords, locText })
  }

  handleSelectIndustry = industry => {
    this.setState({ industry })
  }

  handleJobTypeChange = jobType => {
    this.setState({ jobType })
  }

  handleCardClick = card => {
    const { history, onSetActiveVacancy } = this.props

    onSetActiveVacancy(card)
    history.push(`${detail}/${card.id}`)
    this.props.onClose()
  }

  handleSaveInputValue = ({ result, stateName }) => {
    this.setState({ [stateName]: result })
  }

  renderResultItems = () => {
    const { searchResult } = this.props

    if (searchResult) {
      return (
        <ResultList>
          {searchResult.map(item => {
            return (
              <ListItem onClick={() => this.handleCardClick(item)}>
                <ResultTextContainer>
                  <ResultItemTitle>{item.title}</ResultItemTitle>
                  <ResultItemLocation>
                    {item.company_address}
                  </ResultItemLocation>
                </ResultTextContainer>
                {item.matchRate && (
                  <ProgressbarContainer>
                    <CircularProgressbar
                      percentage={item.matchRate}
                      style={percentStyles}
                      text={`${item.matchRate}%`}
                    />
                  </ProgressbarContainer>
                )}
                <ResultItemButton>
                  <i className="fas fa-angle-right" />
                </ResultItemButton>
              </ListItem>
            )
          })}
          {!searchResult.length && (
            <ResultListEmptyText>
              <SearchIsEmpty />Result list is empty
            </ResultListEmptyText>
          )}
        </ResultList>
      )
    } else {
      return (
        <StartSearchContainer>
          <StartSearchIcon />start search your work
        </StartSearchContainer>
      )
    }
  }

  render() {
    const { industries, jobTypes, isOpen, onClose } = this.props
    const { isLoading, isLoaded } = this.props.userJobsInfo
    const { jobType, industry, kms, locText, text } = this.state

    if (isOpen) {
      return ReactDOM.createPortal(
        <Container id="SearchModal">
          <Content>
            <CloseButton onClick={onClose}>
              <i className="fas fa-times" />
            </CloseButton>
            <SearchBlock>
              <SearchTextContainer>
                <SearchText>I'm looking for a</SearchText>
                <Select
                  data={jobTypes}
                  placeholder="Job type"
                  renderType="select"
                  value={jobType && jobType.name}
                  onSelect={this.handleJobTypeChange}
                />
                <Select
                  inputValue={text}
                  placeholder="software"
                  renderType="defaultInput"
                  onSaveInputValue={result =>
                    this.handleSaveInputValue({
                      result: result,
                      stateName: 'text'
                    })
                  }
                />
                <SearchText>job in</SearchText>
                <Select
                  data={industries}
                  placeholder="choose"
                  renderType="select"
                  value={industry && industry.name}
                  onSelect={this.handleSelectIndustry}
                />
                <SearchText>industry</SearchText>
                <SearchText>within</SearchText>
                <Select
                  inputType="number"
                  inputValue={kms}
                  maxLength={3}
                  placeholder="20"
                  renderType="kmsInput"
                  tabIndex={0}
                  onSaveInputValue={result =>
                    this.handleSaveInputValue({
                      result: result,
                      stateName: 'kms'
                    })
                  }
                />
                <SearchText>km from</SearchText>
                <Select
                  inputValue={locText}
                  placeholder="my home location"
                  renderType="location"
                  onSetLocation={this.handleSetLocation}
                />
              </SearchTextContainer>
              <Button disabled={isLoading} onClick={this.handleOkayClick}>
                <IconButton>
                  <IconButtonItemIcon
                    className={
                      isLoading ? 'fas fa-circle-notch' : 'fas fa-check-circle'
                    }
                    isLoading={isLoading}
                  />
                </IconButton>
                {isLoading ? 'Loading...' : 'Okay!'}
              </Button>
            </SearchBlock>
            <ResultBlock innerRef={el => (this.resultContainer = el)}>
              {isLoading && !isLoaded ? (
                <SearchModalLoader
                  searchLoaderWidth={this.state.searchLoaderWidth}
                />
              ) : (
                this.renderResultItems()
              )}
            </ResultBlock>
          </Content>
        </Container>,
        document.getElementById('root-modal')
      )
    }
    return <React.Fragment />
  }
}
