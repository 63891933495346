import Component from './Layout'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getUserAvatar,
  getUser,
  getFetchUserLoadInfo
} from 'Redux/selectors/user'
import { isAuthenticate } from 'Redux/selectors/auth'
import { getUnreadChatsCount } from 'Redux/selectors/chat'
import { getUserJobs, getJobsLoadInfo } from 'Redux/selectors/jobs'
import { getUserNotifications } from 'Redux/selectors/user'

import { logOut } from 'Redux/actions/auth'
import { markLogin } from 'Redux/actions/user'
import { searchJobs, searchJobsByParams, resetJobs } from 'Redux/actions/jobs'
import { switchModal, closeModal } from '../../../Redux/actions/utils'
import { getModalInfo } from '../../../Redux/selectors/utils'

import { loadSettings } from 'Redux/actions/settings'
import { getSettings, getSettingsLoadInfo } from 'Redux/selectors/settings'

const selector = createStructuredSelector({
  avatar: getUserAvatar,
  isAuthenticate,
  user: getUser,
  unreadChatsCount: getUnreadChatsCount,
  userJobs: getUserJobs,
  userJobsInfo: getJobsLoadInfo,
  modalInfo: getModalInfo,
  userDataLoadInfo: getFetchUserLoadInfo,
  notifications: getUserNotifications,
  settingsList: getSettings,
  settingsLoadInfo: getSettingsLoadInfo
})
const actions = {
  onLogOut: logOut,
  onMarkLogin: markLogin,
  onResetJobs: resetJobs,
  onSearchJobs: searchJobs,
  onSearchJobsByParams: searchJobsByParams,
  onSwitchModal: switchModal,
  onCloseModal: closeModal,
  onLoadSettings: loadSettings
}
export default connect(
  selector,
  actions
)(Component)
