import React from 'react'
import PropTypes from 'prop-types'

import { AddButtonContainer, AddButtonIcon } from './styled'

const AddButton = ({ value, onClickAdd, styles }) => {
  return (
    <AddButtonContainer onClick={onClickAdd} styles={styles}>
      <AddButtonIcon />
      {value}
    </AddButtonContainer>
  )
}

AddButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClickAdd: PropTypes.func
}

export { AddButton }
