import styled from 'styled-components'

export const ContainerRight = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 12px;
  height: 100px;
  font-size: 16px;
  cursor: pointer;
  @media (max-width: 799px) {
    margin-bottom: 0;
  }
`
export const ContainerAll = styled.div`
  margin-bottom: ${p => (p.first ? 293 : 266)}px;
  @media screen and (max-width: 799px) {
    margin-bottom: ${props => (props.first ? 81 : 71)}px;
    ${props => props.first && 'margin-top: 15px'};
  }
`
export const ContainerRightBlock = styled.div`
  margin-top: ${p => (p.first ? 222 : 266)}px;
  @media (max-width: 799px) {
    margin-top: 95px;
  }
`
export const ContainerLeft = ContainerRight.extend``
export const Company = styled.div`
  padding: 10px;
  @media (max-width: 799px) {
    order: 1;
  }
`
export const Attainment = styled.div`
  padding: 18px 10px 10px;
  @media (max-width: 799px) {
    min-width: initial;
    order: 4;
  }
`
export const Description = styled.div`
  width: 235px;
  padding: 10px;
  font-family: 'Nunito';
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 200px;
  }
  @media (max-width: 799px) {
    order: 3;
  }
`
export const Wall = styled.div`
  background: lightgrey;
  width: 1px;
  height: 99px;
  margin-right: 10px;
  @media (max-width: 799px) {
    order: 2;
  }
`
export const Time = styled.div`
  font-size: 17px;
  font-family: 'Nunito';
  display: flex;
  justify-content: ${props => props.content};
  :hover {
    color: #00b7fe;
  }
  @media (max-width: 799px) {
    justify-content: flex-end;
  }
`
export const TimeText = styled.p`
  margin-right: 6px;
  margin-left: 6px;
  font-size: 17px;
  font-family: 'Nunito';
  position: ${props => props.position};
  bottom: ${props => props.bottom};
  :hover {
    color: #00b7fe;
  }
`
export const Image = styled.img`
  filter: ${p => (p.active ? 'none' : 'grayscale(1) opacity(0.4)')};
`
