import React, { PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import { Autocomplete } from '../Autocomplete'
import OutsideClickHandler from 'react-outside-click-handler'
import {
  Container,
  List,
  Item,
  Text,
  Input,
  InputItem,
  InputList,
  InputIcon,
  InputIconContainer,
  ListContainer
} from './styles'

export class Select extends PureComponent {
  state = {
    isOpen: false,
    inputText: ''
  }

  componentDidMount() {
    const { inputValue } = this.props
    if (inputValue && inputValue.length) {
      this.setState({ inputText: inputValue })
    }
  }

  handleOpenList = () => {
    this.setState({
      isOpen: true
    })
  }

  handleCloseList = () => {
    this.setState({
      isOpen: false
    })
  }

  handleSelectItemClick = el => {
    const { onSelect } = this.props
    this.handleCloseList()
    onSelect(el)
  }

  handleLocationItemClick = el => {
    const { onSetLocation } = this.props
    onSetLocation(el)
    this.handleCloseList()
  }

  handleTextChange = event => {
    const { value } = event.target
    this.setState({ inputText: value })
  }

  handleKeyPress = e => {
    if (e.keyCode === 13) {
      this.handleSubmit()
    }
  }

  handleSubmit = e => {
    e && e.preventDefault()
    const { inputText } = this.state
    const { onSaveInputValue } = this.props

    onSaveInputValue(inputText)
    this.handleCloseList()
  }

  handleOutsideInputClick = () => {
    const { inputValue } = this.props
    const { inputText } = this.state

    if (inputValue !== inputText) {
      this.setState({
        isOpen: false,
        inputText: inputValue
      })
    } else {
      this.handleCloseList()
    }
  }

  render() {
    const {
      inputValue,
      value,
      data,
      renderType,
      placeholder,
      inputType,
      maxLength,
      tabIndex
    } = this.props
    const { isOpen, inputText } = this.state

    switch (renderType) {
      case 'defaultInput':
        return (
          <OutsideClickHandler onOutsideClick={this.handleSubmit}>
            <Container>
              <Text onClick={this.handleOpenList}>
                {inputValue || placeholder}
              </Text>
              <InputList open={isOpen}>
                <InputItem onSubmit={e => this.handleSubmit(e)}>
                  <Input
                    name="input"
                    placeholder={placeholder}
                    type={inputType || 'text'}
                    value={inputText}
                    onChange={this.handleTextChange}
                    onKeyDown={e => e.keyCode === 27 && this.handleCloseList()}
                  />
                  <InputIconContainer>
                    <InputIcon />
                  </InputIconContainer>
                </InputItem>
              </InputList>
            </Container>
          </OutsideClickHandler>
        )
      case 'kmsInput':
        return (
          <OutsideClickHandler onOutsideClick={this.handleSubmit}>
            <Container>
              <Text onClick={this.handleOpenList}>
                {inputValue || placeholder}
              </Text>
              <InputList isKmsInput open={isOpen}>
                <InputItem onSubmit={e => this.handleSubmit(e)}>
                  <Input
                    maxLength={maxLength}
                    name="input"
                    placeholder={placeholder}
                    tabIndex={tabIndex}
                    type={inputType || 'text'}
                    value={inputText}
                    onChange={this.handleTextChange}
                    onKeyDown={e => e.keyCode === 27 && this.handleCloseList()}
                  />
                  <InputIconContainer>
                    <InputIcon />
                  </InputIconContainer>
                </InputItem>
              </InputList>
            </Container>
          </OutsideClickHandler>
        )
      case 'select':
        return (
          <OutsideClickHandler onOutsideClick={this.handleCloseList}>
            <Container>
              <Text onClick={this.handleOpenList}>{value || placeholder}</Text>
              <List open={isOpen}>
                <ListContainer>
                  {data.map((el, i) => (
                    <Item
                      key={i}
                      onClick={() => this.handleSelectItemClick(el)}
                    >
                      {el.name || el.title}
                    </Item>
                  ))}
                </ListContainer>
              </List>
            </Container>
          </OutsideClickHandler>
        )
      case 'location':
        return (
          <OutsideClickHandler onOutsideClick={this.handleCloseList}>
            <Container>
              <Text onClick={this.handleOpenList}>
                {inputValue || placeholder}
              </Text>
              <InputList open={isOpen} onSubmit={e => e.preventDefault()}>
                <InputItem>
                  <Autocomplete
                    value={inputValue}
                    onSetLocation={this.handleLocationItemClick}
                  />
                </InputItem>
              </InputList>
            </Container>
          </OutsideClickHandler>
        )
      default:
        return <Redirect to="/" />
    }
  }
}
