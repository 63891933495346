import styled from 'styled-components'
import * as colors from '../../../Theme/colors'

export const NavigationContainer = styled.div`
  position: ${p => (p.isBigScreen ? 'absolute' : 'fixed')};
  top: 85px;
  right: 15px;
  @media screen and (max-width: 500px) {
    top: ${p => (p.show ? 32 : -20)}px;
    left: 0;
    background: #fff;
    z-index: 20;
    transition: top 0.5s;
  }
`
export const TopItemContainer = styled.div`
  margin: 5px 0;
`
export const TopItemCircle = styled.i`
  border: solid 1px ${p => (p.active ? colors.chatPurple : '#757575')};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => (p.active ? colors.chatPurple : '#757575')};
  transition: all 0.5s;
  &:hover {
    border-color: ${colors.chatPurple};
    color: ${colors.chatPurple};
  }
`
export const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    flex-direction: row;
    overflow-x: auto;
    justify-content: space-evenly;
  }
`
