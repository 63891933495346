import styled from 'styled-components'

import * as colors from '../../../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  max-width: 600px;
  font-family: Nunito;
  margin: 20px 0 0;
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`
export const Title = styled.h1`
  font-weight: normal;
  margin: 0 0 10px 0;
  font-size: 25px;
  color: ${colors.careerBlue};
`
