import { createReducer } from 'Helpers/redux'

import { LOAD_SETTINGS } from '../actions/settings'

const initialState = {
  settings: [],
  loadInfo: {
    success: false,
    loading: false,
    error: null
  }
}

const handlers = {
  [LOAD_SETTINGS.REQUEST]: state => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: true,
        error: null
      }
    }
  },
  [LOAD_SETTINGS.FAILURE]: (state, { payload: { data } }) => {
    return {
      ...state,
      loadInfo: {
        success: false,
        loading: false,
        error: data
      }
    }
  },
  [LOAD_SETTINGS.SUCCESS]: (state, { payload: { data } }) => {
    return {
      ...state,
      settings: data,
      loadInfo: {
        success: true,
        loading: false,
        error: null
      }
    }
  }
}

export default createReducer(initialState, handlers)
