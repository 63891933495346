export const getFilterPosition = filterId => {
  const filter = document.getElementById(filterId)
  const { clientWidth } = document.documentElement
  const parent = document
    .getElementById(`${filterId}Btn`)
    .getBoundingClientRect()
  filter.style.top = `${parent.top + 35}px`
  //filter.style.left = `${parent.left}px`
  if (clientWidth >= 500) {
    if (
      filterId === 'Salary' ||
      filterId === 'Options' ||
      filterId === 'Expertises' ||
      filterId === 'Date'
    ) {
      filter.style.transform = 'translateX(-55%)'
    }
    filter.style.left = `${parent.left}px`
  }
  const filterRec = filter.getBoundingClientRect()
  if (filterRec.left + filter.offsetWidth > clientWidth) {
    filter.style.left = 'initial'
    filter.style.transform = 'translateX(0)'
    filter.style.right = '0'
    filter.style.right = '10px'
  } else if (filterRec.left < 260) {
    filter.style.left = '260px'
  }
  if (clientWidth < 500) {
    filter.style.left = '50%'
    filter.style.transform = 'translateX(-50%)'
  }
  const itemTop = filter.offsetTop
  if (itemTop > -5) {
    filter.style.opacity = (itemTop / 95).toFixed(2)
  }
  filter.style.pointerEvents = `${itemTop > 30 ? 'all' : 'none'}`
}
