import { createAsyncAction } from 'Helpers/redux'
// import { apiCall } from './api'
import { outSocketTypes, inSocketTypes } from 'Constants/socketEventTypes'

export const GET_MESSAGES = createAsyncAction('chat/GET_MESSAGE')
export const getUserMessages = chatId => ({
  types: GET_MESSAGES,
  payload: chatId,
  meta: {
    socket: {
      channel: outSocketTypes.getMessages
    }
  }
})

export const MOUNT_CHANNELS = 'chat/MOUNT_CHANNELS'
export const mountChannels = venueId => ({
  type: MOUNT_CHANNELS,
  payload: venueId
})

export const UNMOUNT_CHANNELS = createAsyncAction('chat/UNMOUNT_CHANNELS')
export const unmountChannels = message => ({
  types: UNMOUNT_CHANNELS,
  meta: {
    socket: {
      channel: outSocketTypes.leaveRoom
    }
  }
})

export const SEND_MESSAGE = createAsyncAction(
  'socket/' + outSocketTypes.sendMessage.toUpperCase()
)
export const sendMessage = (message, chatId) => ({
  types: SEND_MESSAGE,
  payload: {
    chatId,
    message,
    user: 'candidate'
  },
  meta: {
    socket: {
      channel: outSocketTypes.sendMessage
    }
  }
})

export const CHANGE_MESSAGE_STATUS = createAsyncAction(
  'socket/' + outSocketTypes.changeMessageStatus.toUpperCase()
)
export const changeMessageStatus = (msgs, chatId, status) => {
  return {
    types: CHANGE_MESSAGE_STATUS,
    payload: {
      messages: msgs,
      chatId,
      status
    },
    meta: {
      socket: {
        channel: outSocketTypes.changeMessageStatus
      }
    }
  }
}

export const CONNECT_ROOM = createAsyncAction(
  'socket/' + outSocketTypes.changeMessageStatus.toUpperCase()
)
export const connectRoom = chatId => ({
  types: CONNECT_ROOM,
  payload: {
    chatId
  },
  meta: {
    socket: {
      channel: outSocketTypes.roomConnect
    }
  }
})

export const CREATE_CHAT = createAsyncAction(
  'socket/' + outSocketTypes.createChat.toUpperCase()
)
export const createChat = (userId, contactPersId) => ({
  types: CREATE_CHAT,
  payload: {
    userId,
    contactPersId
  },
  meta: {
    socket: {
      channel: outSocketTypes.createChat
    }
  }
})

export const RECIEVE_MESSAGE =
  'socket/' + inSocketTypes.receiveMessage.toUpperCase()

export const RECIEVE_CHATS_DATA =
  'socket/' + inSocketTypes.recieveChatsData.toUpperCase()

export const RECIEVE_MESSAGES = createAsyncAction(
  'socket/' + inSocketTypes.recieveMessages.toUpperCase()
)
export const NEW_CHAT = 'socket/' + inSocketTypes.newChat.toUpperCase()
