import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import moment from "moment";
import twix from "twix";
import RichTextEditor from "react-rte";
import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { Checkbox } from "antd";
import { toast } from "react-toastify";

import { UserEditInfoModal } from "../../../../../Blocks/UserEditInfoModal";
import { Autocompleate, CardImage, SelectDatePicker } from "../../blocks";

import {
  Container,
  FieldsContainer,
  Input,
  TextEditor,
  AutocompleteContainer,
  WithAutocompleate,
  SelectDateBlock,
} from "./styled";
import "./styles.css";
import { getCompanyImage } from "../../../../../../Helpers/getImages";

class ChangeModal extends PureComponent {
  constructor(props) {
    super();
    const {
      title,
      subTitle,
      description,
      startDate,
      endDate,
      image,
      currently,
    } = props.item;

    const descriptionSt = description
      ? RichTextEditor.createValueFromString(description, "html")
      : RichTextEditor.createEmptyValue();
    console.log("currently==>", currently, currently === 0 ? currently : 1);
    this.state = {
      title,
      subTitle,
      description: descriptionSt,
      startDate: moment(startDate).format("YYYY-MM"),
      endDate: moment(endDate).format("YYYY-MM"),
      image,
      isAutocompleateShow: false,
      autocompleateItemId: null,
      fromYear: moment(startDate).format("YYYY"),
      toYear: moment(endDate).format("YYYY"),
      fromMonth: moment(startDate).format("MMMM"),
      toMonth: moment(endDate).format("MMMM"),
      inputError: false,
      curentlyCheck: currently ? currently : 0,
      isChange: true,
      titleError: false,
      subTitleError: false,
    };
  }

  handleAMonthChange = (value) => {
    const { from, to } = value;

    const startDate = `${moment([from.year, from.month]).format("YYYY-MM")}`;
    const endDate = `${moment([to.year, to.month]).format("YYYY-MM")}`;

    this.setState({ startDate, endDate });
  };

  handleSetNowDate = () => {
    this.setState({ endDate: moment().format("YYYY-MM") });
  };

  handleChangeStateByName = ({ target }) => {
    const { name, value } = target;
    this.setState({ inputError: false });
    const { withAutocompleate } = this.props;
    switch (withAutocompleate) {
      case "title":
        if (name === "title") {
          this.setState({
            [name]: value,
            isAutocompleateShow: true,
            autocompleateItemId: null,
            image: null,
            [`${name}Error`]: false,
          });
        } else {
          this.setState({ [name]: value, [`${name}Error`]: false });
        }
        break;
      case "subTitle":
        if (name === "subTitle") {
          this.setState({
            [name]: value,
            isAutocompleateShow: true,
            autocompleateItemId: null,
            image: null,
            [`${name}Error`]: false,
          });
        } else {
          this.setState({ [name]: value, [`${name}Error`]: false });
        }
        break;
      default:
        this.setState({ [name]: value, [`${name}Error`]: false });
    }
  };

  handleDescriptionChange = (description) => this.setState({ description });

  handleSaveClick = () => {
    const { onSave, withAutocompleate } = this.props;
    const {
      title,
      subTitle,
      description,
      fromYear,
      fromMonth,
      toYear,
      toMonth,
      autocompleateItemId,
      curentlyCheck,
    } = this.state;

    const startDate1 = moment(`${fromYear}-${fromMonth}`).format("YYYY-MMMM");
    const endDate1 = moment(`${toYear}-${toMonth}`).format("YYYY-MMMM");
    const dif = moment(endDate1).diff(moment(startDate1), "months");

    if (dif < 0 || !fromYear || !fromMonth) {
      this.setState({ inputError: true });
      return;
    }

    const endDateValue = curentlyCheck
      ? moment().format("YYYY-MM")
      : moment(endDate1).format("YYYY-MM");

    const saveObj = {
      title,
      subTitle,
      description: description.toString("html"),
      startDate: moment(startDate1).format("YYYY-MM"),
      endDate: endDateValue,
      autocompleateItemId,
      currently: curentlyCheck,
    };

    // console.log("saveObj====>", saveObj, withAutocompleate, title, subTitle);
    // console.log("saveObj=>", saveObj.title);
    // if (!saveObj.title) {
    //   alert("hey");
    // }
    // console.log(
    //   "(withAutocompleate ===  && !isEmpty(title)) || (withAutocompleate ===  && !isEmpty(subTitle))",
    //   withAutocompleate === "title" && !isEmpty(title),
    //   withAutocompleate === "subtitle" && !isEmpty(subTitle)
    // );
    // console.log("isEmpty(title)==>", isEmpty(title));
    if (
      (!withAutocompleate && !isEmpty(title)) ||
      (!isEmpty(title) && !isEmpty(subTitle))
    ) {
      onSave(saveObj);
    } else {
      if (isEmpty(title)) {
        this.setState({ titleError: true });
        toast.error("Title is empty");
      }

      if (isEmpty(subTitle)) {
        this.setState({ subTitleError: true });
        toast.error("Sub title is empty");
      }
      toast.error("Title is empty");
    }
  };

  handleAutocompleateSelect = ({ value, id }) => {
    const {
      onLoadAutocompleateItemImage,
      autocompleateItems,
      withAutocompleate,
    } = this.props;

    const item = autocompleateItems.find((el) => el.id === id);

    this.setState((prevState) => ({
      title: withAutocompleate === "title" ? value : prevState.title,
      subTitle: withAutocompleate === "subTitle" ? value : prevState.subTitle,
      autocompleateItemId: id,
      isAutocompleateShow: false,
      image: onLoadAutocompleateItemImage
        ? onLoadAutocompleateItemImage(item.image)
        : null,
    }));
  };

  renderComponentsWithAutocompleate = () => {
    const {
      titleInputPlaceholder,
      subTitleInputPlaceholder,
      withAutocompleate,
      isActivities,
    } = this.props;
    const { title, subTitle, titleError, subTitleError } = this.state;
    // console.log(" titleError, subTitleError==>", titleError, subTitleError);
    switch (withAutocompleate) {
      case "title":
        return (
          <Fragment>
            {isActivities ? null : (
              <Input
                name={"subTitle"}
                placeholder={subTitleInputPlaceholder}
                value={subTitle}
                onChange={this.handleChangeStateByName}
                error={subTitleError}
              />
            )}
            <WithAutocompleate>
              <Input
                name={"title"}
                placeholder={titleInputPlaceholder}
                value={title}
                onChange={this.handleChangeStateByName}
                error={titleError}
              />
              {this.renderAutocompleate()}
            </WithAutocompleate>
          </Fragment>
        );
      case "subTitle":
        return (
          <Fragment>
            <WithAutocompleate>
              {isActivities ? null : (
                <Input
                  name={"subTitle"}
                  placeholder={subTitleInputPlaceholder}
                  value={subTitle}
                  onChange={this.handleChangeStateByName}
                  error={subTitleError}
                />
              )}
              {this.renderAutocompleate()}
            </WithAutocompleate>
            <Input
              name={"title"}
              placeholder={titleInputPlaceholder}
              value={title}
              onChange={this.handleChangeStateByName}
              error={titleError}
            />
          </Fragment>
        );
      default:
        return (
          <Fragment>
            {isActivities ? null : (
              <Input
                name={"subTitle"}
                placeholder={subTitleInputPlaceholder}
                value={subTitle}
                onChange={this.handleChangeStateByName}
                error={subTitleError}
              />
            )}
            <Input
              name={"title"}
              placeholder={titleInputPlaceholder}
              value={title}
              onChange={this.handleChangeStateByName}
              error={titleError}
            />
          </Fragment>
        );
    }
  };

  renderAutocompleate = () => {
    const { autocompleateItems, withAutocompleate } = this.props;
    if (!autocompleateItems || !autocompleateItems.length) return;

    const { title, subTitle, isAutocompleateShow } = this.state;

    const autocompleateField = withAutocompleate === "title" ? title : subTitle;

    const parsedCompanies = autocompleateItems.filter(
      (item) =>
        item.name.toLowerCase().indexOf(autocompleateField.toLowerCase()) > -1
    );
    if (
      autocompleateField.length &&
      isAutocompleateShow &&
      parsedCompanies.length
    ) {
      return (
        <AutocompleteContainer>
          <Autocompleate
            isOpen={true}
            items={parsedCompanies}
            onSelect={this.handleAutocompleateSelect}
          />
        </AutocompleteContainer>
      );
    }
  };

  getDates = (startDate, endDate) => {
    const data = moment
      .twix(new Date(startDate), new Date(endDate))
      .iterate("years");
    const range = [];
    while (data.hasNext()) {
      range.push(data.next().format("YYYY"));
    }
    return range;
  };

  handleChangeCurentlyCheck = () => {
    this.setState({
      curentlyCheck: !this.state.curentlyCheck,
    });
  };

  renderYearPicker = () => {
    const {
      withMonthDatePicker,
      selectFromTitle,
      selectToTitle,
      checkBoxTitle,
    } = this.props;

    const curTime = moment().format("YYYY");
    const startTime = moment()
      .subtract(30, "years")
      .format("YYYY");
    const endTime = moment()
      .add(10, "years")
      .format("YYYY");
    const fromData = this.getDates(startTime, curTime);
    const endData = this.getDates(startTime, endTime);

    return (
      <React.Fragment>
        <SelectDateBlock>
          <SelectDatePicker
            data={fromData}
            inputError={this.state.inputError}
            monthValue={this.state.fromMonth}
            selectMonthName={"fromMonth"}
            selectYearName={"fromYear"}
            title={"From:"}
            withMonthDatePicker={withMonthDatePicker}
            yearValue={this.state.fromYear}
            onHandleChange={this.handleChangeStateByName}
          />
          <SelectDatePicker
            data={endData}
            inputError={this.state.inputError}
            monthValue={this.state.toMonth}
            selectMonthName={"toMonth"}
            selectYearName={"toYear"}
            title={"To:"}
            withMonthDatePicker={withMonthDatePicker}
            yearValue={this.state.toYear}
            onHandleChange={this.handleChangeStateByName}
            curentlyCheck={this.state.curentlyCheck}
          />
        </SelectDateBlock>
        {withMonthDatePicker ? (
          <Checkbox
            checked={this.state.curentlyCheck}
            name="curentlyCheck"
            onChange={this.handleChangeCurentlyCheck}
          >
            {checkBoxTitle}
          </Checkbox>
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    const {
      modalTitle,
      modalWidth,
      onClose,
      faIcon,
      changeItemId,
      onDelete,
      isActivities,
      isDelete,
      isBigModalWindow,
      item,
    } = this.props;
    const { description, image } = this.state;

    // console.log("isBigModalWindow", isBigModalWindow);
    return (
      <UserEditInfoModal
        title={modalTitle}
        width={600}
        onCloseModal={onClose}
        onSaveClick={this.handleSaveClick}
        changeItemId={changeItemId}
        onDelete={onDelete}
        isActivities={isActivities}
        isDelete={isDelete}
        isBigModalWindow={isBigModalWindow}
        dataProps={item}
        dataState={this.state}
      >
        <Container>
          <CardImage faIcon={faIcon} image={image} />
          <FieldsContainer>
            {/* {this.renderDatePicker()} */}
            {this.renderYearPicker()}
            {this.renderComponentsWithAutocompleate()}
            <TextEditor
              id={"textEditor"}
              value={description}
              onChange={this.handleDescriptionChange}
            />
          </FieldsContainer>
        </Container>
      </UserEditInfoModal>
    );
  }
}

ChangeModal.propTypes = {
  autocompleateItems: PropTypes.array,
  faIcon: PropTypes.string,
  item: PropTypes.object,
  modalTitle: PropTypes.string.isRequired,
  modalWidth: PropTypes.number,
  subTitleInputPlaceholder: PropTypes.string,
  titleInputPlaceholder: PropTypes.string,
  type: PropTypes.string,
  withAutocompleate: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onLoadAutocompleateItemImage: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

ChangeModal.defaultProps = {
  item: {
    title: "",
    subTitle: "",
    description: null,
    subTitleInputPlaceholder: "enter data",
    titleInputPlaceholder: "enter data",
    startDate: moment().format("YYYY-MM"),
    endDate: moment().format("YYYY-MM"),
    image: null,
  },
};

export { ChangeModal };
