import { createSelector } from 'reselect'

const routerData = state => state.router

export const location = createSelector(routerData, data => data.location)

export const getPrevLocation = createSelector(
  routerData,
  data => data.prevLocation
)
