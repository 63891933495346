export const CATEGORY_ALL = {
  id: 0,
  title: 'All',
  route: 'all',
  type: 'link'
}

export const CATEGORY_SAVED = {
  id: -1,
  title: 'SAVED',
  route: 'saved',
  type: 'link'
}
export const CATEGORY_SEARCH = {
  id: -2,
  title: 'Search'
}
