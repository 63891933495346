import React, { Component } from 'react'
import { Element, scroller } from 'react-scroll'
import withSizes from 'react-sizes'
import Waypoint from 'react-waypoint'

import { Layout, CareerNavigationPublic } from '../../Blocks'

import {
  ImGoodAt,
  WhoAmI,
  MyPositions,
  UserInfo,
  WhatOthersSay,
  Activities,
  MyQualifications
} from './innerBlocks'

import { ContentWrapperStyled, Loader, Container } from './styles'
import { log } from 'util'

class PublicCareerTimeLine extends Component {
  constructor(props) {
    super()
    this.state = {
      activeHeaderCategory: {
        title: 'career',
        id: 1
      },
      activeCategory: 'whoAmI',
      isScrollNavBarShow: true
    }
    this.prevScrollpos = null
  }

  //eslint-disable-next-line
  UNSAFE_componentWillMount() {
    const { canId } = this.props.match.params
    if (canId) {
      this.props.fetchUserById(canId)
    } else {
      console.log('Redirect')
    }
  }

  componentDidMount() {
    const { isMobile } = this.props

    if (isMobile) {
      const nodeElement = document.getElementById('rootScrollContainer')
      nodeElement.addEventListener('scroll', this.setNavigationPos)
      this.prevScrollpos = nodeElement.scrollTop
    }
  }

  componentWillUnmount() {
    const { isMobile } = this.props
    if (isMobile) {
      const nodeElement = document.getElementById('rootScrollContainer')
      nodeElement.removeEventListener('scroll', this.setNavigationPos)
    }
  }

  setNavigationPos = () => {
    const nodeElement = document.getElementById('rootScrollContainer')
    const currentScrollPos = nodeElement.scrollTop
    if (this.prevScrollpos > currentScrollPos) {
      this.setState(
        prevState =>
          !prevState.isScrollNavBarShow && { isScrollNavBarShow: true }
      )
    } else {
      this.setState(
        prevState =>
          prevState.isScrollNavBarShow && { isScrollNavBarShow: false }
      )
    }
    this.prevScrollpos = currentScrollPos
  }

  setActiveCategory = name => {
    this.setState(
      prevState => prevState.activeCategory !== name && { activeCategory: name }
    )
  }

  handleCategoryClick = category => {
    const { isMobile } = this.props
    const containerId = isMobile
      ? 'rootScrollContainer'
      : 'contentScrollContainer'

    scroller.scrollTo(category, {
      smooth: 'easeInOutQuint',
      containerId: containerId,
      duration: 1500,
      offset: isMobile ? -80 : -20
    })
  }

  handleScrollToTop = () => {
    const { isMobile } = this.props
    const containerId = isMobile
      ? 'rootScrollContainer'
      : 'contentScrollContainer'
    scroller.scrollTo(0, {
      smooth: 'easeInOutQuint',
      containerId: containerId,
      duration: 1500
    })
  }

  render() {
    const {
      activeCategory,
      activeHeaderCategory,
      isScrollNavBarShow
    } = this.state
    const {
      loadInfo,
      experience,
      academQualif,
      profQualif,
      activities,
      userFeedbacks,
      userExpertises
    } = this.props

    const { isMobile } = this.props
    const containerId = isMobile
      ? 'rootScrollContainer'
      : 'contentScrollContainer'
    const container = document.getElementById(containerId)

    return (
      <Layout
        activeHeaderCategory={activeHeaderCategory}
        header={'Candidate Profile'}
        history={this.props.history}
        notScroll={true}
      >
        {loadInfo.isLoading && <Loader />}
        {loadInfo.isLoaded && (
          <React.Fragment>
            <Container>
              <ContentWrapperStyled>
                <CareerNavigationPublic
                  activeCategory={activeCategory}
                  isShow={isScrollNavBarShow}
                  onCategoryClick={this.handleCategoryClick}
                  feedbacks={userFeedbacks}
                  experience={experience}
                  userExpertises={userExpertises}
                  academQualif={academQualif}
                  profQualif={profQualif}
                  activities={activities}
                />
                <UserInfo />
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory('whoAmI')}
                />
                <Element name={'whoAmI'}>
                  <WhoAmI />
                </Element>
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory('positions')}
                />
                {experience && experience.length ? (
                  <Element name={'positions'}>
                    <MyPositions />
                  </Element>
                ) : null}
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory('qualifications')}
                />
                {(academQualif && academQualif.length) ||
                (profQualif && profQualif.length) ? (
                  <Element name={'qualifications'}>
                    <MyQualifications />
                  </Element>
                ) : null}
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory('imGoodAt')}
                />
                {userExpertises && userExpertises.length ? (
                  <Element name={'imGoodAt'}>
                    <ImGoodAt />
                  </Element>
                ) : null}
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory('feedbacks')}
                />
                {userFeedbacks && userFeedbacks.length ? (
                  <Element name={'feedbacks'}>
                    <WhatOthersSay />
                  </Element>
                ) : null}
                <Waypoint
                  scrollableAncestor={container}
                  onEnter={() => this.setActiveCategory('activities')}
                />
                {activities && activities.length ? (
                  <Element name={'activities'}>
                    <Activities />
                  </Element>
                ) : null}
              </ContentWrapperStyled>
            </Container>
          </React.Fragment>
        )}
      </Layout>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobile: width < 500
})

export default withSizes(mapSizeToProps)(PublicCareerTimeLine)
