import styled from 'styled-components'
import RichTextEditor from 'react-rte'

import * as colors from '../../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  padding: 10px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`
export const Image = styled.div`
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  background: #757575;
  margin: 10px 0 0 0;
`
export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
  @media screen and (max-width: 500px) {
    margin: 10px 0;
  }
`
export const Input = styled.input.attrs({ type: 'text' })`
  background: none;
  border: solid 1px ${colors.chatPurple};
  border-radius: 10px;
  margin: 5px 0;
  padding: 5px 15px;
  width: 100%;
`
export const TextEditor = styled(RichTextEditor)`
  min-height: 250px;
  width: 450px;
  font-family: Nunito !important;
  button[title='Image'],
  button[title='Link'],
  button[title='Remove Link'],
  span[title='Normal'] {
    display: none;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`
export const AutocompleteContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  background-color: #fff;
  border-radius: 10px;
  z-index: 11;
`
export const WithAutocompleate = styled.div`
  position: relative;
`
export const RangeText = styled.div`
  cursor: pointer;
`
export const RangeContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  height: 33px;
  border: solid 1px ${colors.chatPurple};
  border-radius: 10px;
  @media screen and (max-width: 400px) {
    justify-content: flex-start;
    padding: 0 0 0 10px;
  }
`
export const RangeButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`
export const RangeButton = styled.input.attrs({ type: 'checkbox' })`
  margin: 0;
`
export const RangeButtonText = styled.span`
  margin: 0 5px 0 0;
  padding: 0;
  font-size: 13px;
  color: #757575;
`
