export const TABS = [
  {
    id: 0,
    title: 'PITCHED',
    key: 'Pitched',
    route: 'pitched',
    subTitles: [
      {
        id: 4,
        title: 'Pitched by me',
        key: 'user',
        route: 'pitched-by-me'
      }
      // {
      //   id: 1,
      //   title: 'Pitched by company',
      //   key: 'company'
      // }
    ]
  },
  {
    id: 1,
    title: 'IN PROGRESS',
    key: 'In progress',
    route: 'in-progress'
  },
  {
    id: 2,
    title: 'ACCEPTED',
    key: 'Accepted',
    route: 'accepted'
  },
  {
    id: 3,
    title: 'TURNED DOWN',
    key: 'Declined',
    route: 'turned-down',
    subTitles: [
      {
        id: 5,
        title: 'Turned down by me',
        key: 'user',
        route: 'by-me'
      },
      {
        id: 1,
        title: 'Turned down by company',
        key: 'company'
      }
    ]
  }
]
