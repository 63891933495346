import React, { Component } from 'react'
import withSizes from 'react-sizes'

import _ from 'lodash'

import { messageStatuses } from 'Constants/chat'

import LayOut from '../../Blocks/LayOut'

import ChatList from './innerBlocks/ChatList'
import Chat from './innerBlocks/Chat'

import {
  Container,
  Users,
  MessageContainer,
  SearchInput,
  ContainerSearch,
  Content
} from './styled'

class Messages extends Component {
  constructor(props) {
    super(props)

    const { channels, location } = props

    const state = {
      users: 'block',
      messages: 'none',
      activeChat: null,
      search: null,
      searchStr: '',
      isMobile: false
    }

    const clientWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    const isMobile = clientWidth < 500
    state.isMobile = isMobile

    if (channels.length > 0) {
      if (location.state && _.isNumber(location.state.contactPersId)) {
        const contactPersChat = _.find(
          channels,
          channel => channel.contact_person_id === location.state.contactPersId
        )

        if (!_.isEmpty(contactPersChat)) {
          state.activeChat = contactPersChat
        } else if (!isMobile) {
          state.activeChat = channels[0]

          state.users = 'none'
          state.messages = 'block'
        }
      } else if (!isMobile) {
        state.activeChat = channels[0]

        state.users = 'none'
        state.messages = 'block'
      }
    }

    this.state = state
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState

    const { channels, location } = nextProps

    if (prevState.activeChat === null && channels.length > 0) {
      if (location.state && _.isNumber(location.state.contactPersId)) {
        const contactPersChat = _.find(
          channels,
          channel =>
            channel.contact_person_id === nextProps.location.state.contactPersId
        )

        if (!_.isEmpty(contactPersChat)) {
          nextState.activeChat = contactPersChat
        } else if (!prevState.isMobile) {
          nextState.activeChat = channels[0]

          nextState.users = 'none'
          nextState.messages = 'block'
        }
      } else if (!prevState.isMobile) {
        nextState.activeChat = channels[0]

        nextState.users = 'none'
        nextState.messages = 'block'
      }
    }

    return nextState
  }

  changeOfState = () => {
    this.setState({ users: 'none', messages: 'flex' })
  }

  returnTheState = () => {
    this.setState({ users: 'flex', messages: 'none' })
  }

  handleChangeChat = chat => {
    this.setState({
      activeChat: chat
    })

    this.props.onChangeRoom(chat.id)
    this.changeOfState()
  }

  handleMessageRead = msgs => {
    const { channels } = this.props
    const { activeChat } = this.state

    if (channels.length && msgs.length && activeChat !== null) {
      const messageIds = msgs.map(msg => msg.id)

      this.props.onChangeMessageStatus(
        messageIds,
        activeChat.id,
        messageStatuses.seen
      )
    }
  }

  handleSendMessage = msg => {
    const { channels } = this.props
    const { activeChat } = this.state

    if (channels.length && activeChat !== null) {
      this.props.onSendMessage(msg, activeChat.id)
    }
  }

  handleSearchChange = e => {
    this.setState({
      searchStr: e.target.value
    })
  }

  render() {
    const { messages, channels, isMobileStyle, history } = this.props
    const { activeChat, searchStr } = this.state

    const reg = new RegExp(searchStr, 'gi')

    const activeChatMessages = activeChat ? messages[activeChat.id] || [] : []

    let filteredChannels = channels
    if (searchStr.length) {
      filteredChannels = channels.filter(el => reg.test(el.companion_name))
    }
    if (isMobileStyle) {
      return (
        <LayOut header="MESSAGES" history={history} notScroll={true}>
          <Content>
            <Container>
              <Users display={this.state.users}>
                <ContainerSearch>
                  <i
                    className="fas fa-search"
                    style={{ paddingTop: 15, marginLeft: 10 }}
                  />
                  <SearchInput
                    placeholder="Search in messages"
                    value={searchStr}
                    onChange={this.handleSearchChange}
                  />
                </ContainerSearch>
                <ChatList
                  activeChat={activeChat}
                  chats={filteredChannels}
                  messages={messages}
                  onChatClick={this.handleChangeChat}
                />
              </Users>
              <MessageContainer display={this.state.messages}>
                {activeChat && (
                  <Chat
                    activeChat={activeChat}
                    messages={activeChatMessages}
                    onBackClick={this.returnTheState}
                    onMessageRead={this.handleMessageRead}
                    onSendMessage={this.handleSendMessage}
                  />
                )}
              </MessageContainer>
            </Container>
          </Content>
        </LayOut>
      )
    }
    return (
      <LayOut header="MESSAGES" history={history}>
        <Container>
          <Container>
            <Users>
              <ContainerSearch>
                <i
                  className="fas fa-search"
                  style={{ paddingTop: 15, marginLeft: 10 }}
                />
                <SearchInput
                  placeholder="Search in messages"
                  value={searchStr}
                  onChange={this.handleSearchChange}
                />
              </ContainerSearch>
              <ChatList
                activeChat={activeChat}
                chats={filteredChannels}
                messages={messages}
                onChatClick={this.handleChangeChat}
              />
            </Users>
            <MessageContainer>
              {activeChat && (
                <Chat
                  activeChat={activeChat}
                  messages={activeChatMessages}
                  onMessageRead={this.handleMessageRead}
                  onSendMessage={this.handleSendMessage}
                />
              )}
            </MessageContainer>
          </Container>
        </Container>
      </LayOut>
    )
  }
}

const mapSizeToProps = ({ width }) => ({
  isMobileStyle: width < 900
})

export default withSizes(mapSizeToProps)(Messages)
