import styled from 'styled-components'
import { Input } from 'antd'
import * as colors from '../../../../../Theme/colors'

export const StyledInput = styled(Input)`
  & > input {
    margin: 2px 0;
    border-color: ${({ error }) => error ? 'red' : colors.chatPurple};
  }
`

export const Container = styled.div`
  padding: 10px 10px 20px;
`

export const ModalContainer = styled.div`
  padding: 5px 5px 10px 5px;
`
export const Icon = styled.i`
  color: #757575;
`
