import React, { PureComponent } from 'react'

import { Window, TextBox, IconButton } from 'Components/Blocks'
import * as validation from 'Constants/validation'

import { Description, Logo, BlueButton } from '../blocks'
import { Content, BottomBlock, Form } from './styles'

export default class EmailWindow extends PureComponent {
  state = {
    email: '',
    warning: false,
    loader: false,
  }

  validate = () => {
    const { email } = this.state
    this.setState({
      warning: false
    })

    let valid = true

    if (!validation.email.test(email)) {
      valid = false
      this.setState({
        warning: true
      })
    }

    return valid
  }

  handleSendIdentif = () => {
    const { onSendEmail } = this.props

    if (!this.validate()) return

    onSendEmail(this.state.email)
    this.setState({ loader: true})
    //this.props.changeWindow(this.props.innerRoutes.success)
  }

  render() {
    //todo loader???
    const { changeWindow, innerRoutes, sendMailInfo } = this.props
    const { loader } = this.state;
    if (sendMailInfo.sendMailLoadInfo.isLoaded) {
      changeWindow(innerRoutes.successResetPassword)
    }
    return (
      <Window onClose={this.props.onClose}>
        <Content>
          <Logo />
          <Description>
            Go ahead... type-in your email address to get your password
          </Description>
          <Form>
            <TextBox
              placeholder="Your Email Address here"
              required
              type="email"
              value={this.state.email}
              warning={this.state.warning}
              onChange={e => {
                localStorage.setItem('email', e.target.value)
                this.setState({ email: e.target.value })
              }}
              onEnterPress={this.handleSendIdentif}
            />
          </Form>
          <BottomBlock>
            <BlueButton
              onClick={() => changeWindow(innerRoutes.login)}
            >
              Back
            </BlueButton>
            <BlueButton
              icon="fas fa-check-circle"
              onClick={this.handleSendIdentif}
              loading={loader}
            >
              Okay
            </BlueButton>
          </BottomBlock>
        </Content>
      </Window>
    )
  }
}
