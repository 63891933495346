import styled from 'styled-components'
import Spiner from 'react-spinkit'

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
`
export const TabsContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    width: 100%;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
`
export const Loader = styled(Spiner).attrs({ name: 'ball-beat' })`
  height: 85%;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    position: absolute;
    background-color: #fff;
    height: 100%;
  }
`
