import styled from 'styled-components'
import * as colors from '../../../../../Theme/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.h1`
  font-size: 35px;
  font-weight: normal;
  text-align: center;
  margin: 10px 0;
  color: ${colors.careerBlue};
  font-family: Nunito;
`
export const Description = styled.p`
  font-family: Nunito;
  font-size: 15px;
  padding: 0 10px;
  cursor: pointer;
`
export const UserInfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 50px;
  margin: 20px 0;
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`
export const ContactContainer = styled.div`
  display: flex;
  align-content: space-between;
  padding: 0 10px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`
export const ChangeModalInput = styled.input.attrs({ type: 'text' })`
  outline: none;
  border: solid 1px ${colors.chatPurple};
  border-radius: 10px;
  width: 100%;
  padding: 5px 0 5px 20px;
  margin: 10px 0 20px;
`
export const ChangeModalTextarea = styled.textarea.attrs({
  resize: 'none',
  maxlength: 200
})`
  outline: none;
  border: solid 1px ${colors.chatPurple};
  border-radius: 10px;
  width: 100%;
  height: 100px;
  padding: 5px 10px;
  margin: 10px 0 20px;
`
export const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ButtonContainer = styled.div`
  align-self: center;
  margin: 15px 0 5px;
`
export const DescriptionWorlds = styled.p`
  font-size: 13px;
  color: #757575;
  margin: 0;
`
