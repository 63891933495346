import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { CareerTimeLineBlock } from "../../../../Blocks";
import { AddButton, ActivitiesCard, ChangeModal } from "../blocks";

import { Container, Content } from "./styled";
import swal from "sweetalert";

export default class Activities extends PureComponent {
  static propTypes = {
    activities: PropTypes.array,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
  };

  state = {
    isModalOpen: false,
    changeItemId: null,
    isDraft: false,
  };

  handleOpenModal = ({ id }) => {
    this.setState({
      isModalOpen: true,
      changeItemId: id || null,
    });
  };

  handleCloseModal = () => {
    const { isDraft } = this.state;
    if (!isDraft) {
      this.confirmModal();
    } else {
      this.setState({
        isModalOpen: false,
        changeItemId: null,
      });
    }
  };
  closeMainModal = () => {
    this.setState({
      isModalOpen: false,
      changeItemId: null,
    });
  };
  confirmModal = () => {
    swal({
      icon: "warning",
      text: "Are you sure you want to discard changes to this ?",
      buttons: {
        confirm: {
          text: "Discard",
          value: true,
          visible: true,
        },
        cancel: {
          text: "No Thanks",
          value: false,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) this.closeMainModal();
    });
  };
  handleSaveClick = (result) => {
    const { onEdit, onAdd } = this.props;
    const { changeItemId } = this.state;

    const { title, subTitle, description, startDate, endDate, image } = result;

    const reqObj = {
      title,
      subTitle,
      description,
      startDate,
      endDate,
      image,
      id: changeItemId,
    };

    if (changeItemId !== null) {
      onEdit({ ...reqObj });
    } else {
      onAdd({ ...reqObj });
    }

    this.handleCloseModal();
  };

  renderChangeModal = () => {
    const { activities, onDelete } = this.props;
    const { changeItemId } = this.state;

    const modalTitle =
      changeItemId === null ? "Add Activities" : "Edit Activities";
    const changedItem = activities.find((item) => item.id === changeItemId);

    let parsedItem = undefined;
    if (changeItemId !== null) {
      parsedItem = {
        title: changedItem.title,
        subTitle: changedItem.sub_title,
        description: changedItem.description,
        startDate: changedItem.start_date,
        endDate: changedItem.end_date,
        image: changedItem.image,
      };
    }

    return (
      <ChangeModal
        faIcon={"fas fa-trophy"}
        item={parsedItem}
        modalTitle={modalTitle}
        subTitleInput={false}
        // subTitleInputPlaceholder={'Your academic qualification'}
        isActivities={true}
        titleInputPlaceholder={"Ex Soccer team Captain"}
        type={"activities"}
        onClose={this.handleCloseModal}
        onSave={this.handleSaveClick}
        changeItemId={changeItemId}
        onDelete={() => onDelete(changeItemId)}
        isDelete={true}
        isBigModalWindow={false}
      />
    );
  };

  render() {
    const { activities, onDelete } = this.props;
    const { isModalOpen } = this.state;
    return (
      <CareerTimeLineBlock
        title={"achievements & activities"}
        type={"activities"}
      >
        <Container>
          <AddButton
            value={"Add Achievements & Activity"}
            onClickAdd={this.handleOpenModal}
          />
          <Content>
            {activities && activities.length
              ? activities.map((item) => (
                  <ActivitiesCard
                    description={item.description}
                    endDate={item.end_date}
                    faIcon={"fas fa-trophy"}
                    id={item.id}
                    startDate={item.start_date}
                    subTitle={item.sub_title}
                    title={item.title}
                    type={"activities"}
                    onDelete={() => onDelete(item.id)}
                    onModalOpen={() => this.handleOpenModal({ id: item.id })}
                  />
                ))
              : null}
          </Content>
          {isModalOpen ? this.renderChangeModal() : null}
        </Container>
      </CareerTimeLineBlock>
    );
  }
}
