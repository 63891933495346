import styled from 'styled-components'
import * as colors from '../../../../../../Theme/colors'

export const RatingLevelContainer = styled.div`
  margin: 0 0 5px 20px;
  font-size: 12px;
  border-radius: 10px;
  border: solid 1px ${colors.chatPurple};
  width: 60px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const RatingLevelText = styled.span`
  font-family: Nunito;
  color: ${colors.chatPurple};
`
