import React, { Component } from "react";
import $ from "jquery";
import Spiner from "react-spinkit";
import { Window, IconBlock, SearchPanel } from "Components/Blocks";
import { search, identificationCard } from "Assets/icons";
import { Description, List, ListItem, Logo, BlueButton } from "../blocks";
import { getIndustryImageLink } from "Helpers/getImageLink";
import {
  Content,
  ContentMob,
  ItemText,
  BottomBlock,
  SpinerContainer,
  BottomBlockBig,
} from "./styles";
import loading from "../../../../../images/loading.gif";
import * as icons from "../../../../../Assets/images/Filters";
import type from "../../../../../images/type.png";
import bag from "../../../../../images/OppDoor/bag.png";
import MediaQuery from "react-responsive";

export default class SearchWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchPanelVisible: false,
      // offset for SearchPanel
      top: 0,
      left: 0,
      warnings: {
        categories: true,
        jobTypes: true,
      },
      isLoading: {
        categories: true,
        jobTypes: true,
      },
      selectedCategories: [],
      selectedIndustries: [],
      searchStr: "",
    };
  }

  UNSAFE_componentWillMount() {
    const {
      onJobTypesLoad,
      jobTypes,
      industries,
      onIndustriesLoad,
    } = this.props;

    if (!jobTypes.length) onJobTypesLoad();
    if (!industries.length) onIndustriesLoad();
  }

  componentDidMount() {
    this.calcSearchPanelOffset();
    window.addEventListener("resize", this.calcSearchPanelOffset);
    this.props.completeCurrent([]);

    //validate redux data
    const { categories, jobTypes } = this.props.registerData;
    const warnings = {
      categories: true,
      jobTypes: true,
    };

    if (categories.length) {
      const newSelectedCategories = categories.map((item) => item.id);
      warnings.categories = false;
      this.setState({
        warnings: warnings,
        selectedCategories: newSelectedCategories,
      });
    }
    if (jobTypes.length) {
      const newSelectedIndustries = jobTypes.map((item) => item.id);
      warnings.jobTypes = false;
      this.setState({
        warnings: warnings,
        selectedIndustries: newSelectedIndustries,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcSearchPanelOffset);
  }

  onSearchChange = (e) => {};

  toggleSearchPanel = () => {
    this.calcSearchPanelOffset();
    this.setState({ isSearchPanelVisible: !this.state.isSearchPanelVisible });
  };

  calcSearchPanelOffset = () => {
    if (!this.$searchItem) return;
    const { top, left } = this.$searchItem.position();
    this.setState({
      top: top + this.$searchItem.height(),
      left,
    });
  };

  selectAllHandler = () => {
    const { industries } = this.props;
    const { warnings } = this.state;

    const checkedAll = industries.map((item) => {
      return item.id && this.state.selectedCategories[item.id];
    });
    const checkedType = checkedAll.indexOf(undefined);
    if (checkedType >= 1) {
      this.setState({
        selectedCategories: [],
        warnings: {
          ...warnings,
          categories: true,
        },
      });
    } else {
      const selectedCategories = industries.map((el) => el.id);
      this.setState({
        selectedCategories,
        warnings: {
          ...warnings,
          categories: false,
        },
      });
    }
  };

  nextBtnHandler = () => {
    const checkedCategs = [];
    const checkedJobTypes = [];
    const { selectedCategories, selectedIndustries, warnings } = this.state;
    const {
      changeWindow,
      innerRoutes,
      completeCurrent,
      industries,
      jobTypes,
    } = this.props;

    for (let i = 0; i < industries.length; i++) {
      if (selectedCategories.indexOf(industries[i].id) >= 0) {
        checkedCategs.push({
          id: industries[i].id,
          name: industries[i].name,
        });
      }
    }
    jobTypes.forEach((item) => {
      if (selectedIndustries.indexOf(item.id) >= 0) {
        checkedJobTypes.push({
          id: item.id,
          name: item.name,
        });
      }
    });

    if (!warnings.categories && !warnings.jobTypes) {
      completeCurrent({
        categories: checkedCategs,
        jobTypes: checkedJobTypes,
      });
      changeWindow(innerRoutes.location);
    } else {
      console.error("check categories!");
    }
  };

  handleCheckCategory = (id) => {
    const { selectedCategories, warnings } = this.state;
    const findSelected = selectedCategories.indexOf(id);
    if (findSelected !== -1) {
      const newSelectedArr = selectedCategories.filter((el) => el !== id);
      this.setState({ selectedCategories: newSelectedArr });

      if (newSelectedArr.length) {
        this.setState({
          warnings: {
            ...warnings,
            categories: false,
          },
        });
      } else {
        this.setState({
          warnings: {
            ...warnings,
            categories: true,
          },
        });
      }
    } else {
      const newArr = [...selectedCategories, id];
      this.setState({
        selectedCategories: newArr,
        warnings: {
          ...warnings,
          categories: false,
        },
      });
    }
  };

  handleCheckIndustries = (id) => {
    const { selectedIndustries, warnings } = this.state;

    const findSelected = selectedIndustries.indexOf(id);
    if (findSelected !== -1) {
      const newSelectedArr = selectedIndustries.filter((el) => el !== id);
      this.setState({ selectedIndustries: newSelectedArr });

      if (newSelectedArr.length) {
        this.setState({
          warnings: {
            ...warnings,
            jobTypes: false,
          },
        });
      } else {
        this.setState({
          warnings: {
            ...warnings,
            jobTypes: true,
          },
        });
      }
    } else {
      const newArr = [...selectedIndustries, id];
      this.setState({
        selectedIndustries: newArr,
        warnings: {
          ...warnings,
          jobTypes: false,
        },
      });
    }
  };

  renderIndustries = () => {
    const { industries } = this.props;
    const { selectedCategories } = this.state;

    return industries.map((el, i) => {
      const selected = selectedCategories.indexOf(el.id) >= 0;
      return (
        <ListItem onClick={() => this.handleCheckCategory(el.id)} key={i}>
          <IconBlock
            isCloseBtn={selected}
            borderColor="#bcbaba"
            checked={selected}
            customStyle={{ width: "64px", height: "64px" }}
            iconName={el.icon ? getIndustryImageLink(el.icon) : bag}
          />
          <ItemText>{el.name}</ItemText>
        </ListItem>
      );
    });
  };

  renderJobTypeListItems = () => {
    const { jobTypes } = this.props;
    const { selectedIndustries } = this.state;

    return jobTypes.map((el, i) => {
      const selected = selectedIndustries.indexOf(el.id) >= 0;
      return (
        <ListItem key={i} onClick={() => this.handleCheckIndustries(el.id)}>
          <IconBlock
            isCloseBtn={selected}
            borderColor="#bcbaba"
            checked={selected}
            iconName={type}
            customStyle={{ width: "64px", height: "64px" }}
          />
          <ItemText>{el.name}</ItemText>
        </ListItem>
      );
    });
  };

  handleOutsideClick = (event) => {
    event.stopPropagation();
    if (this.state.isSearchPanelVisible) {
      this.setState({ isSearchPanelVisible: false });
    }
  };

  render() {
    const {
      changeWindow,
      innerRoutes,
      industries,
      jobTypes,
      onClose,
    } = this.props;
    const { selectedCategories, selectedIndustries } = this.state;
    const isNextDisabled =
      !selectedCategories.length || !selectedIndustries.length;

    return (
      <Window onClose={onClose} step={1}>
        <MediaQuery minDeviceWidth={600} device={{ deviceWidth: 600 }}>
          <Content>
            <Logo onClose={onClose} />
            <Description>What Industries you are Interested in</Description>
            {industries.length ? (
              <List name="scrollContainer">
                <div ref={(el) => (this.$searchItem = $(el))}>
                  <ListItem onClick={this.toggleSearchPanel}>
                    <IconBlock
                      isCloseBtn={this.state.isSearchPanelVisible}
                      borderColor="#bcbaba"
                      checked={this.state.isSearchPanelVisible}
                    >
                      {search}
                    </IconBlock>
                    <ItemText>Search Job Categories</ItemText>
                  </ListItem>
                </div>
                <SearchPanel
                  items={industries}
                  searchStr={this.state.searchStr}
                  selectAll={this.selectAllHandler}
                  selectedCatigories={this.state.selectedCategories}
                  visible={this.state.isSearchPanelVisible}
                  onCheck={this.handleCheckCategory}
                  onSearchChange={(e) =>
                    this.setState({ searchStr: e.target.value })
                  }
                />
                {this.renderIndustries()}
              </List>
            ) : (
              <SpinerContainer>
                {/* <Spiner name="ball-spin-fade-loader" /> */}
                <img
                  src={loading}
                  style={{ height: "80px", width: "80px" }}
                  alt="MrJOBS"
                />
              </SpinerContainer>
            )}
            <Description>What job types are you looking for</Description>
            {jobTypes.length ? (
              <List id="JobTypeScrollContainer">
                {this.renderJobTypeListItems()}
              </List>
            ) : (
              <SpinerContainer>
                {/* <Spiner name="ball-spin-fade-loader" /> */}
                <img
                  src={loading}
                  style={{ height: "80px", width: "80px" }}
                  alt="MrJOBS"
                />
              </SpinerContainer>
            )}
            <BottomBlockBig>
              <BlueButton onClick={() => changeWindow(innerRoutes.switch)}>
                Back
              </BlueButton>
              <BlueButton
                disabled={isNextDisabled}
                onClick={() => this.nextBtnHandler()}
              >
                Next
              </BlueButton>
            </BottomBlockBig>
          </Content>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={600}>
          <ContentMob>
            <Logo onClose={onClose} />
            <Description>What Industries you are Interested in</Description>
            {industries.length ? (
              <List name="scrollContainer">
                <div ref={(el) => (this.$searchItem = $(el))}>
                  <ListItem onClick={this.toggleSearchPanel}>
                    <IconBlock
                      isCloseBtn={this.state.isSearchPanelVisible}
                      borderColor="#bcbaba"
                      checked={this.state.isSearchPanelVisible}
                    >
                      {search}
                    </IconBlock>
                    <ItemText>Search Job Categories</ItemText>
                  </ListItem>
                </div>
                <SearchPanel
                  items={industries}
                  searchStr={this.state.searchStr}
                  selectAll={this.selectAllHandler}
                  selectedCatigories={this.state.selectedCategories}
                  visible={this.state.isSearchPanelVisible}
                  onCheck={this.handleCheckCategory}
                  onSearchChange={(e) =>
                    this.setState({ searchStr: e.target.value })
                  }
                />
                {this.renderIndustries()}
              </List>
            ) : (
              <SpinerContainer>
                <img
                  src={loading}
                  style={{ height: "80px", width: "80px" }}
                  alt="MrJOBS"
                />
              </SpinerContainer>
            )}
            <Description>What job types are you looking for?</Description>
            {jobTypes.length ? (
              <List id="JobTypeScrollContainer">
                {this.renderJobTypeListItems()}
              </List>
            ) : (
              <SpinerContainer>
                {/* <Spiner name="ball-spin-fade-loader" /> */}
                <img
                  src={loading}
                  style={{ height: "80px", width: "80px" }}
                  alt="MrJOBS"
                />
              </SpinerContainer>
            )}
            <BottomBlock>
              <BlueButton onClick={() => changeWindow(innerRoutes.switch)}>
                Back
              </BlueButton>
              <BlueButton
                disabled={isNextDisabled}
                onClick={() => this.nextBtnHandler()}
              >
                Next
              </BlueButton>
            </BottomBlock>
          </ContentMob>
        </MediaQuery>
      </Window>
    );
  }
}
