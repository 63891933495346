import { createReducer } from 'Helpers/redux'

import {
  SEND,
  GET_USER_ID,
  CHECK_PASSWORD,
  CHANGE_USER_PASSWORD,
  FORGOT_USER_PASSWORD,
  CHECK_PASSWORD_CLEAR_DATA
} from 'Redux/actions/passwordReset'

const initialState = {
  sendMailLoadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  },
  userId: '',
  getUserIdLoadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  },
  passwordChangeLoadInfo: {
    isLoaded: false,
    isLoading: false,
    error: null
  },
  isExpiredLink: false,
  resendEmailUserData: {},
  isChecked: false
}

const handlers = {
  [SEND.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      sendMailLoadInfo: {
        ...state.loadInfo,
        isLoading: true,
        error: null
      }
    }
  },
  [SEND.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      sendMailLoadInfo: {
        ...state.loadInfo,
        isLoading: false,
        error: payload.data
      }
    }
  },
  [SEND.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      sendMailLoadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  },
  [GET_USER_ID.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      getUserIdLoadInfo: {
        ...state.loadInfo,
        isLoading: true,
        error: null
      }
    }
  },
  [GET_USER_ID.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      getUserIdLoadInfo: {
        ...state.loadInfo,
        isLoading: false,
        error: payload.data
      }
    }
  },
  [GET_USER_ID.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      getUserIdLoadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      },
      userId: payload.data.userId
    }
  },
  [CHECK_PASSWORD.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isExpiredLink: payload.data.expired,
      resendEmailUserData: {
         email: payload.data.email,
         id: payload.data.id,
      },
      isChecked: true
    }
  },
  [CHECK_PASSWORD_CLEAR_DATA]: (state, { payload }) => {
    return {
      ...state,
      isExpiredLink: false,
      resendEmailUserData: {},
      isChecked: false
    }
  },
  [CHANGE_USER_PASSWORD.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      passwordChangeLoadInfo: {
        ...state.loadInfo,
        isLoading: true,
        error: null
      }
    }
  },
  [CHANGE_USER_PASSWORD.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      passwordChangeLoadInfo: {
        ...state.loadInfo,
        isLoading: false,
        error: payload.data
      }
    }
  },
  [CHANGE_USER_PASSWORD.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      passwordChangeLoadInfo: {
        isLoaded: true,
        isLoading: false,
        error: null
      }
    }
  },
  [FORGOT_USER_PASSWORD]: (state, { payload }) => {
    return {
      ...state,
      sendMailLoadInfo: {
        isLoaded: false,
        isLoading: false,
        error: null
      }
    }
  },
}

export default createReducer(initialState, handlers)
