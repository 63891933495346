import React, {Component} from 'react'
import {Window} from 'Components/Blocks'
import {Content, Icon, BigGreenText, SmallGreenText, Link} from './styles'
import { innerRoutes } from 'Constants/modalRoutes'
import confirmResPas from 'Assets/images/icons8-privacy-100.png'

export default class ExpirePasswordWindow extends Component {

  componentDidMount() {
    // setTimeout(() => {
    //   this.onCloseModal()
    // }, 15000)
  }

  componentDidUpdate(prevProps) {
    const {resendLoadInfo, onCheckPassClearData} = this.props
    if (resendLoadInfo.success) {
      this.props.onSwitchModal(innerRoutes.login)
      onCheckPassClearData();
    }
  }


  onCloseModal = () => {
    const {onClose, onCheckPassClearData} = this.props
    onClose()
    localStorage.removeItem('email')
    onCheckPassClearData();
  }

  handleSubmit = () => {
    const { resendConfirmEmail, onSendEmail, userData } = this.props
   // resendConfirmEmail(userData.id, userData.email)
     onSendEmail(userData.email)
    // this.props.onSwitchModal(innerRoutes.successResetPassword)
  }

  render() {
    const {onClose, bigText, onSwitchModal, sendMailLoadInfo} = this.props
    if (sendMailLoadInfo.isLoaded) {
      onSwitchModal(innerRoutes.successResetPassword)
    }
    return (
      <Window onClose={onClose}>
        <Content>
          <Icon src={confirmResPas}/>
          <BigGreenText>{bigText}</BigGreenText>
          <SmallGreenText>
            We apologize for the inconvenience, but your password reset link has expired.
            Please click <Link onClick={this.handleSubmit}>Reset Password</Link> to request new link
          </SmallGreenText>
        </Content>
      </Window>
    )
  }

}
