import React from 'react'
import ReactModal from 'react-modal'
import MediaQuery from 'react-responsive'

import closeImg from 'Assets/images/cross.svg'

import {
  modalStyle,
  modalStyleMobile,
  Wrapper,
  Header,
  Content,
  Footer,
  StyledClose,
  Text,
  Button
} from './styles'
import {distanceValue} from "../../../Constants/distanceValue";


const DeclineConfirmModal = props => {
  const {
    isOpen,
    onConfirm,
    onCancel,
    onRequestClose,
    isNoInterestContent
  } = props

  const title = isNoInterestContent ? 'No interest' : 'Decline'
  const message = isNoInterestContent
    ? 'Are sure you not interest?'
    : ' Please tell us the reason for decline?'

  return (
    <React.Fragment>
      <MediaQuery maxDeviceWidth={499}>
        <ReactModal
          isOpen={isOpen}
          style={modalStyleMobile}
          onRequestClose={onRequestClose}
        >
          <Wrapper>
            <Header>
              {title}
              <StyledClose src={closeImg} onClick={onRequestClose} />
            </Header>
            <Content>
              <Text>{message}</Text>
            </Content>
            <Footer height={106}>
              <Button onClick={onConfirm}>Confirm</Button>
              <Button outline onClick={onCancel}>
                Cancel
              </Button>
            </Footer>
          </Wrapper>
        </ReactModal>
      </MediaQuery>
      <MediaQuery minDeviceWidth={500}>
        <ReactModal
          isOpen={isOpen}
          style={modalStyle}
          onRequestClose={onRequestClose}
        >
          <Wrapper>
            <Header>
              {title}
              <StyledClose src={closeImg} onClick={onRequestClose} />
            </Header>
            <Content>
              <Text>{message}</Text>
            </Content>
            <Footer height={106}>
              <Button onClick={onConfirm}>Confirm</Button>
              <Button outline onClick={onCancel}>
                Cancel
              </Button>
            </Footer>
          </Wrapper>
        </ReactModal>
      </MediaQuery>
    </React.Fragment>
  )
}

export default DeclineConfirmModal
