import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  @media all and (max-width: 899px) {
    flex-direction: column;
  }
`
export const Users = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  width: 345px;
  overflow: hidden auto;
  display: ${props => props.display || 'block'};
  @media all and (max-width: 899px) {
    width: 100%;
  }
`
export const MessageContainer = styled.div`
  border: 1px solid lightgrey;
  flex: 1;
  display: ${props => props.display || 'flex'};
  @media all and (max-width: 899px) {
    min-height: 100%;
    border: none;
    width: 100%;
  }
`
export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  overflow: hidden;
  @media screen and (max-width: 499px) {
    height: calc(100vh - 70px);
  }
`
export const SearchInput = styled.input`
  outline: none;
  border: none;
  background: #f5f6f7;
  margin-left: 20px;
  font-size: 15px;
  font-family: 'Nunito';
`
export const ContainerSearch = styled.div`
  display: flex;
  border-radius: 10px;
  background: #f5f6f7;
  height: 40px;
  margin-bottom: 20px;
`
