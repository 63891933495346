import Component from './MyProfessionalQualifications'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadProfQualf } from 'Redux/actions/professionalQualifications'

import { getUserProfQualf } from 'Redux/selectors/publicUser'
import { getPredProfQualf } from 'Redux/selectors/professionalQualifications'

const actions = {
  onLoadProfQualifList: loadProfQualf
}

const selector = createStructuredSelector({
  profQualifList: getPredProfQualf,
  userProfQualif: getUserProfQualf
})

export const MyProfessionalQualifications = connect(
  selector,
  actions
)(Component)
