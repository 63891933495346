import React, { PureComponent } from 'react'
import moment from 'moment'
import DatePicker from 'antd/lib/date-picker'
import Collapse from 'antd/lib/collapse'
import 'antd/lib/date-picker/style/css'
import 'antd/lib/collapse/style/css'
import { Window } from 'Components/Blocks'
import {
  BlueButton,
  SkillCard,
  Description,
  Logo,
  EducationCard
} from '../blocks'
import {
  Content,
  BottomBlock,
  Form,
  AgeContainer,
  AgeText,
  AddContainer,
  SkillsContainer,
  panelStyle
} from './styles'
import * as validation from 'Constants/validation'

const Panel = Collapse.Panel

export default class ParsedWindow extends PureComponent {
  state = {
    age: moment(moment.now(), 'x').subtract({ years: 20 }),
    positions: [],
    educations: []
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticate) {
      this.props.onClose()
    }
  }

  authHandler = () => {
    if (this.validate()) {
      // console.error('form is invalid')
    } else {
      const {
        categories,
        jobTypes,
        companies,
        location,
        kms,
        lat,
        lng,
        cvFile
      } = this.props.getRegisterData

      const registObg = {
        categories,
        jobTypes,
        companies,
        location,
        kms,
        lat,
        lng,
        cvFile,
        email: this.state.email,
        password: this.state.password,
        name: this.state.fullName,
        avatar: this.state.avatar
      }
      //// console.log('register data: ', registObg)
      Promise.resolve(this.props.onRegister(registObg)).then(resp => {
        // // console.log(resp)
        if (resp.hasError) {
          this.setState({
            emailWarning: true
          })
        }
      })
    }
  }

  validate = () => {
    const positions = this.state.positions.splice(0)
    const educations = this.state.educations.splice(0)
    let isValid = true

    positions.forEach(el => {
      if (!validation.simple.test(el.title)) {
        el.titleWarning = true
        isValid = false
      } else {
        el.titleWarning = false
      }
    })

    educations.forEach(el => {
      if (!validation.simple.test(el.school)) {
        el.schoolWarning = true
        isValid = false
      } else {
        el.schoolWarning = false
      }

      if (!validation.simple.test(el.degree)) {
        el.degreeWarning = true
        isValid = false
      } else {
        el.degreeWarning = false
      }
    })

    this.setState({
      positions,
      educations
    })

    return isValid
  }

  handleNext = () => {
    this.validate()
  }

  handleNewPos = () => {
    const positions = this.state.positions.splice(0)
    const now = moment(moment.now(), 'x')

    positions.push({
      title: '',
      startDate: now.subtract({ years: 1 }),
      endDate: now,
      info: '',
      titleWarning: false
    })

    this.setState({
      positions
    })
  }

  handleNewEdu = () => {
    const educations = this.state.educations.splice(0)
    const now = moment(moment.now(), 'x')

    educations.push({
      school: '',
      degree: '',
      startDate: now.subtract({ years: 1 }),
      endDate: now,
      info: '',
      schoolWarning: false,
      degreeWarning: false
    })

    this.setState({
      educations
    })
  }

  handlePosTitleChange = (text, i) => {
    const positions = this.state.positions.splice(0)

    positions[i].title = text

    this.setState({
      positions
    })
  }

  handleEduSchoolChange = (text, i) => {
    const educations = this.state.educations.splice(0)

    educations[i].school = text

    this.setState({
      educations
    })
  }

  handleEduDegreeChange = (text, i) => {
    const educations = this.state.educations.splice(0)

    educations[i].degree = text

    this.setState({
      educations
    })
  }

  handlePosRangeChange = (dates, i) => {
    const positions = this.state.positions.splice(0)

    positions[i].startDate = dates[0]
    positions[i].endDate = dates[1]

    this.setState({
      positions
    })
  }

  handleEduRangeChange = (dates, i) => {
    const educations = this.state.educations.splice(0)

    educations[i].startDate = dates[0]
    educations[i].endDate = dates[1]

    this.setState({
      educations
    })
  }

  handlePosInfoChange = (text, i) => {
    const positions = this.state.positions.splice(0)

    positions[i].info = text

    this.setState({
      positions
    })
  }

  handleEduInfoChange = (text, i) => {
    const educations = this.state.educations.splice(0)

    educations[i].info = text

    this.setState({
      educations
    })
  }

  handleDelete = (i, prop) => {
    const cards = this.state[prop].splice(0)

    delete cards[i]

    this.setState({
      [prop]: cards
    })
  }

  renderPositions = () => {
    const { positions } = this.state

    return positions.map((el, i) => (
      <SkillCard
        card={el}
        key={i}
        onDelete={() => this.handleDelete(i, 'positions')}
        onInfoChange={e => this.handlePosInfoChange(e.target.value, i)}
        onRangeChange={dates => this.handlePosRangeChange(dates, i)}
        onTitleChange={e => this.handlePosTitleChange(e.target.value, i)}
      />
    ))
  }

  renderEducations = () => {
    const { educations } = this.state

    return educations.map((el, i) => (
      <EducationCard
        card={el}
        key={i}
        onDegreeChange={e => this.handleEduDegreeChange(e.target.value, i)}
        onDelete={() => this.handleDelete(i, 'educations')}
        onInfoChange={e => this.handleEduInfoChange(e.target.value, i)}
        onRangeChange={dates => this.handleEduRangeChange(dates, i)}
        onSchoolChange={e => this.handleEduSchoolChange(e.target.value, i)}
      />
    ))
  }

  render() {
    const { changeWindow, innerRoutes } = this.props
    const { age } = this.state
    const now = moment(moment.now(), 'x')

    return (
      <Window onClose={this.props.onClose}>
        <Content>
          <Logo />
          <Description>More details</Description>
          <Form>
            <AgeContainer>
              <AgeText>Ages: </AgeText>
              <DatePicker
                disabledDate={date => date > now}
                popupStyle={{ zIndex: 99999 }}
                value={age}
                onChange={age => this.setState({ age })}
              />
            </AgeContainer>
            <SkillsContainer>
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                style={{ marginTop: 20 }}
              >
                <Panel header="My positions" key="1" style={panelStyle}>
                  {this.renderPositions()}
                  <AddContainer onClick={() => this.handleNewPos()}>
                    <i className="far fa-edit" /> add new
                  </AddContainer>
                </Panel>
                <Panel header="Education" key="2" style={panelStyle}>
                  {this.renderEducations()}
                  <AddContainer onClick={() => this.handleNewEdu()}>
                    <i className="far fa-edit" /> add new
                  </AddContainer>
                </Panel>
              </Collapse>
            </SkillsContainer>
          </Form>
          <BottomBlock>
            <BlueButton onClick={() => changeWindow(innerRoutes.cv)}>
              Back
            </BlueButton>
            <BlueButton onClick={this.handleNext}>Next</BlueButton>
          </BottomBlock>
        </Content>
      </Window>
    )
  }
}
