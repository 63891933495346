import styled from 'styled-components'

export const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const modalContentStyle = {
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  padding: '0px',
  boxShadow: '0px 0px 12px rgba(0,0,0,.6)',
  backgroundColor: 'white',
  borderRadius: '10px',
  border: 'none',
  fontFamily: 'Roboto',
  overflow: 'visible'
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const CloseButton = styled.i.attrs({ className: 'fas fa-times' })`
  position: absolute;
  width: 30px;
  height: 30px;
  top: -15px;
  right: -15px;
  border-radius: 15px;
  background-color: #552a7a;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    top: -15px;
    right: -15px;
  }
`
