export const CHANGE_DATA = 'CHANGE_DATA'
export const changeData = ({ searchStr, locStr, kms, industry }) => ({
  type: CHANGE_DATA,
  action: {
    searchStr,
    locStr,
    kms,
    industry
  }
})
