import _ from "lodash";

import { mrJobsServerUrl } from "Config/App";
import defaultAvatar from "images/defaultAvatar.png";

export const getAvatarSrc = (userAvatarField) => {
  if (_.isString(userAvatarField) && userAvatarField.length > 0) {
    return `${mrJobsServerUrl}/images/avatars/${userAvatarField}`;
  } else {
    return defaultAvatar;
  }
};
export const getCompanyImage = (companyImageFileName) => {
  if (companyImageFileName) {
    return `${mrJobsServerUrl}/images/company-avatars/${companyImageFileName}`;
  } else {
    return null;
  }
};
